export const FormatDateAPI = (dateValue) => {
  const date = new Date(dateValue)
  const year = String(date.getUTCFullYear()).padStart(4, '0')
  const month = String(date.getUTCMonth() + 1).padStart(2, '0')
  const day = String(date.getUTCDate()).padStart(2, '0')

  return `${year}-${month}-${day}` ?? null
}

export const FormatPayload = (payload) => {
  Object.keys(payload).forEach(key => {
    if (payload[key] === null || payload[key] === '') {
      delete payload[key]
    }
  })
  return payload
}
