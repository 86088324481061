import { Box, Button, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material'
import { useState } from 'react'

const ChartTypeSelection = ({ chartName, changeChartType, chartOptions, isTransitionAccount }) => {
  const [chartType, setChartType] = useState(chartName)

  const handleChangeChartType = (event) => {
    setChartType(event.target.value)
  }
  return (
    <>
      <Typography sx={{ fontSize: '14px', fontWeight: '600', marginBottom: '10px' }}>Select Chart</Typography>
      <Box id='chart-list' className='filter-data-list'>
        <FormControl>
          <RadioGroup
            aria-labelledby='demo-controlled-radio-buttons-group'
            name='controlled-radio-buttons-group'
            value={chartType}
            onChange={handleChangeChartType}
          >
            {
              Object.keys(chartOptions).filter(key => isTransitionAccount ? key === 'BENCHMARK_RETURNS' : true).map((option) => {
                return (
                  <FormControlLabel
                    key={option}
                    sx={{
                      '& .MuiRadio-root': {
                        padding: '2px 9px'
                      },
                      '.MuiFormControlLabel-label': {
                        fontSize: '14px'
                      }
                    }}
                    value={option}
                    control={<Radio sx={{
                      '& .MuiSvgIcon-root': {
                        fontSize: 16
                      }
                    }}
                             />}
                    label={chartOptions[option]}
                  />
                )
              })
              }
          </RadioGroup>
        </FormControl>
      </Box>
      <Box textAlign='right'>
        <Button
          variant='contained'
          size='small'
          sx={{
            marginTop: '15px'
          }}
          disabled={chartName === chartType}
          onClick={() => changeChartType(chartType)}
        >View
        </Button>
      </Box>
    </>
  )
}

export default ChartTypeSelection
