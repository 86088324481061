import { getUserRole } from '../../../utils/getUserRole'
import { GetAPI } from '../components/Actions'

const typeCodeData = async () => {
  const userRole = getUserRole()
  return GetAPI(
    'baseRequestMaintainUrl',
    `data-maintenance/v1/${userRole}/requests/type-code`
  )
}
const statusCode = async () => {
  const userRole = getUserRole()
  return GetAPI(
    'baseRequestMaintainUrl',
    `data-maintenance/v1/${userRole}/requests/status-code`
  )
}

export const GetRequestData = async () => {
  const apiCalls = [
    typeCodeData(),
    statusCode()
  ]
  const responses = await Promise.allSettled(apiCalls)

  const [
    typeCodeAPIData,
    statusCodeAPIData
  ] = responses

  // If any API is falling then try passing/returning [] instead of undefined
  return [
    typeCodeAPIData?.value,
    statusCodeAPIData?.value
  ]
}
