import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Box
} from '@mui/material'

function GroupedList ({ title, items, isFirst }) {
  return (
    <>
      <Box
        style={{
          paddingTop: isFirst ? '16px' : '8px',
          paddingLeft: '20px',
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: '16px'
        }}
      >
        {title}
      </Box>
      <>
        <List sx={{ '&.MuiList-root': { py: 0 } }}>
          {items.map((data, index) => {
            return (
              <ListItem
                key={index}
                sx={{
                  alignItems: 'baseline',
                  paddingLeft: '20px !important',
                  fontFamily: 'Open Sans',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  color: '#34475A',
                  '&.MuiListItem-root': { p: 0 }
                }}
              >
                <ListItemIcon className='list-item-icon' />
                <ListItemText className='list-item-text'>
                  <>
                    {data
                      ? <>
                        <div dangerouslySetInnerHTML={{ __html: data.statement || '' }} />
                        </>
                      : ''}
                  </>
                </ListItemText>
              </ListItem>
            )
          })}
        </List>
      </>

    </>

  )
}

export default GroupedList
