import React from 'react'

const ColorPicker = ({ color, onColorChange, font, onFontChange, padding, onPaddingChange }) => {
  const fontOptions = [
    { fontFamily: 'Lora', label: 'Lora' },
    { fontFamily: 'sans-serif', label: 'Sans Serif' },
    { fontFamily: 'Open Sans', label: 'Open Sans' },
  ]

  return (
    <div className='width-100 directionColumn'>
      <label className='flexOrAlign'>
        <span className='color_lable'>Color</span>
        <input
          type='color'
          id='color-input'
          className='color-picker-input color_dropdown_input'
          value={color}
          onChange={(e) => onColorChange(e.target.value)}
        />
      </label>
      <label className='flexOrAlign margin-y-2'>
        <span className='color_lable'>Font </span>
        <select
          style={{ fontFamily: font }}
          className='form-select form-select-sm font-select-box border-b width-75'
          aria-label='Font select'
          onChange={(e) => onFontChange(e.target.value)}
          defaultValue={font}
        >
          {fontOptions.map((option) => (
            <option
              key={option.fontFamily}
              className='.common-text-data'
              style={{ fontFamily: option.fontFamily }}
              value={option.fontFamily}
            >
              {option.label}
            </option>
          ))}
        </select>
      </label>

      <label className='flexOrAlign'>
        <span className='color_lable'>Padding</span>
        <input
          min={0}
          max={30}
          type='number'
          id='padding-input'
          className='number-input'
          value={padding}
          onChange={(e) => e.target.value >= 0 && e.target.value <= 30 ? onPaddingChange(e.target.value) : null}
        />&nbsp;px
      </label>
    </div>
  )
}

export default ColorPicker
