import { useState } from 'react'
import { Grid } from '@mui/material'
import DataMetricCard from './DataMetricCard'
import MetricAccordion from './MetricAccordion'

const DataMetricsTab = ({ data }) => {
  const [expandedMetric, setExpandedMetric] = useState('')

  // changes expanded panel
  const handleExpandedMetricChange = (panel) => (event, isExpanded) => {
    setExpandedMetric(isExpanded ? panel : '')
  }

  return (
    <Grid container>
      <Grid item xs={12} mt={1}>
        <Grid container item>
          {
            (data || []).map((processObj, index) => {
              return (
                <Grid
                  item xs={12} key={index}
                  sx={{ pb: '8px' }}
                >
                  {/* process metrics accordion with data metric card */}
                  <MetricAccordion
                    isExpanded={expandedMetric === processObj.processName}
                    handleChange={handleExpandedMetricChange}
                    panelName={processObj.processName}
                  >
                    <DataMetricCard
                      processData={processObj.processData}
                    />
                  </MetricAccordion>
                </Grid>
              )
            })
          }
        </Grid>
      </Grid>
    </Grid>
  )
}

export default DataMetricsTab
