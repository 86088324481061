import { FormatDateAPI, FormatPayload } from '../components/FormatAPIRequest'
import { UpdateAPI } from '../components/Actions'
import { getUserRole } from '../../../utils/getUserRole'

export const UpdateOptimizationData = async (updateRow, tabIndex) => {
  switch (tabIndex) {
    case 'opt-status-master':
      return await UpdateOptimizationStatusMaster(updateRow)
    case 'opt-scenario-list':
      return await UpdateOptimizationScenarioList(updateRow)
    case 'opt-scenario-properties':
      return await UpdateOptimizationScenarioProperty(updateRow)
    default:
      return {}
  }
}

const UpdateOptimizationStatusMaster = async (updatedRow) => {
  const userRole = getUserRole()
  let payload = {
    optStatusName: updatedRow?.optStatusName,
    optStatusDesc: updatedRow?.optStatusDesc
  }

  payload = FormatPayload(payload)

  return UpdateAPI(
    'baseUriOptimizationDataMaintenance',
    `data-maintenance/v1/${userRole}/optimizations/status-master/${updatedRow?.optStatusId}`,
    payload
  )
}

const UpdateOptimizationScenarioList = async (updatedRow) => {
  const userRole = getUserRole()
  let payload = {
    scenarioDescription: updatedRow?.scenarioDescription,
    isDefault: updatedRow?.isDefault
  }

  payload = FormatPayload(payload)

  return UpdateAPI(
    'baseUriOptimizationDataMaintenance',
    `data-maintenance/v1/${userRole}/optimizations/scenario-list/${updatedRow?.scenarioId}`,
    payload
  )
}

const UpdateOptimizationScenarioProperty = async (updatedRow) => {
  const userRole = getUserRole()
  const endDateValue = updatedRow?.endDate ? FormatDateAPI(updatedRow?.endDate) : null

  let payload = {
    propertyCode: updatedRow?.propertyCode?.toUpperCase(),
    propertyValue: updatedRow?.propertyValue,
    endDate: endDateValue
  }

  payload = FormatPayload(payload)

  return UpdateAPI(
    'baseUriOptimizationDataMaintenance',
    `data-maintenance/v1/${userRole}/optimizations/${updatedRow?.optScenarioPropertiesId}/scenario-properties`,
    payload
  )
}
