import { getUserRole } from '../../../utils/getUserRole'
import { GetAPI } from '../components/Actions'

export const optimizationDetailsAPI = async (parameter) => {
  const userRole = getUserRole()
  return GetAPI('baseUriOptimizationDataMaintenance', `data-maintenance/v1/${userRole}/optimizations/${parameter}`)
}

export const GetOptimizationData = async () => {
  const apiCalls = [
    optimizationDetailsAPI('all-status'),
    optimizationDetailsAPI('all-scenario-list'),
    optimizationDetailsAPI('scenario/properties')
  ]
  const response = await Promise.allSettled(apiCalls)
  const [optStatusMasterData, optScenarioListData, optScenarioPropertiesData] = response
  return [optStatusMasterData?.value, optScenarioListData?.value, optScenarioPropertiesData?.value]
}
