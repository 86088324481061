import { FormatDateAPI, FormatPayload } from '../components/FormatAPIRequest'
import { UpdateAPI } from '../components/Actions'
import { getUserRole } from '../../../utils/getUserRole'

export const UpdateSponsorData = async (updateRow, tabIndex) => {
  switch (tabIndex) {
    case 'sponsor':
      return await UpdateSponsor(updateRow)
    case 'strategy-owner':
      return await UpdateStrategyOwner(updateRow)
    case 'sponsor-financial-advisors':
      return await UpdateSponsorFinancialAdvisors(updateRow)
    default:
      return {}
  }
}

const UpdateSponsor = async (updateRow) => {
  const userRole = getUserRole()
  const startDateValue = updateRow.startDate ? FormatDateAPI(updateRow.startDate) : null
  const endDateValue = updateRow.endDate ? FormatDateAPI(updateRow.endDate) : null
  let payload = {
    sponsorCd: updateRow.sponsorCd?.toUpperCase(),
    sponsorName: updateRow.sponsorName,
    startDate: startDateValue,
    endDate: endDateValue
  }

  payload = FormatPayload(payload)
  return UpdateAPI(
    'baseSponserURL',
    `data-maintenance/v1/${userRole}/sponsors/${updateRow?.sponsorId}`,
    payload)
}

const UpdateStrategyOwner = async (updateRow) => {
  const userRole = getUserRole()
  const startDateValue = updateRow.startDate ? FormatDateAPI(updateRow.startDate) : null
  let payload = {
    strategyOwnerCd: updateRow.strategyOwnerCd?.toUpperCase(),
    strategyOwnerName: updateRow.strategyOwnerName,
    startDate: startDateValue
  }

  payload = FormatPayload(payload)
  return UpdateAPI(
    'baseSponserURL',
    `data-maintenance/v1/${userRole}/strategy-owners/${updateRow?.strategyOwnerId}`,
    payload)
}

const UpdateSponsorFinancialAdvisors = async (updateRow) => {
  const userRole = getUserRole()
  const startDateValue = updateRow.startDate ? FormatDateAPI(updateRow.startDate) : null
  const endDateValue = updateRow.endDate ? FormatDateAPI(updateRow.endDate) : null
  let payload = {
    faName: updateRow.faName,
    sponsorId: updateRow.sponsorId,
    faShortName: updateRow.faShortName,
    faEmail: updateRow.faEmail,
    startDate: startDateValue,
    endDate: endDateValue
  }

  payload = FormatPayload(payload)
  return UpdateAPI(
    'baseSponserURL',
    `data-maintenance/v1/${userRole}/sponsors/financial-advisor/${updateRow?.faId}`,
    payload)
}
