import { useDispatch } from 'react-redux'
import { IoIosExpand } from 'react-icons/io'
import ReactECharts from 'echarts-for-react'
import { Box, Card, CardContent, Divider, Grid, IconButton, Skeleton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { enableLegacyViewReducer } from '../../../../store/trade-reducer/trade-reducer'
import { formatCurrencyWithSymbol } from '../../../../utils/FormateCurrenyInMilion'
import SummaryTaxCostChart from './SummaryTaxCostChart'

const summaryCols = [
  {
    field: 'accountName',
    headerName: 'Accounts',
    flex: 1
  },
  {
    field: 'propTe',
    headerName: 'Tracking Error',
    type: 'number',
    flex: 1,
    align: 'right',
    headerAlign: 'right',
    valueGetter: (params) => params.value !== null && params.value !== undefined ? parseFloat((params.value * 100).toFixed(2)) : null,
    renderCell: (params) => params.value !== null && params.value !== undefined ? params.value : 'NA'
  },
  {
    field: 'securities',
    headerName: 'No. of Securities',
    type: 'number',
    flex: 1,
    align: 'right'
  },
  {
    field: 'transferredShares',
    headerName: 'Transfers',
    type: 'number',
    flex: 1,
    align: 'right'
  },
  {
    field: 'buyShares',
    headerName: 'Buy',
    type: 'number',
    flex: 1,
    align: 'right'
  }
]

const nodeTooltipFormatter = (params) => {
  if (params.data.type === 'TRANSITION') {
    return `<div style='background-color: white;font-family: Open Sans;padding: 10px;'>
    <div style='display: flex; justify-content: space-between;align-items: flex-start;'>
        <div>
            <div style='font-size: 14px; font-weight: 500; color: #74788D;'>Market Value</div>
            <div style='font-size: 18px; font-weight: 600; color: #2F2F2F;'>${formatCurrencyWithSymbol(params?.data?.portMv, 2, '$')}</div>
        </div>
        <div style='display: flex; justify-content: space-between;align-items: center;'>
            <div style='margin-left:50px;text-align: left;'>
                <div style='font-size: 12px; font-weight: 600; color: #3BBFA3;margin-bottom: 2px;'>${params?.data?.transferredShares}</div>
                <div style='width: 50px; height: 5px; border-radius: 5px; background-color: #3BBFA3;'></div>
                <div style='font-size: 12px; font-weight: 400; color: #74788D;'>Transfers</div>
            </div>
            <div style='text-align: right;'>
                <div style='font-size: 12px; font-weight: 600; color: #ff6161;margin-bottom: 2px;'>${params?.data?.sellShares}</div>
                <div style='width: 50px; height: 5px; border-radius: 5px; background-color: #ff6161;'></div>
                <div style='font-size: 12px; font-weight: 400; color: #74788D;'>Sell</div>
            </div>
        </div>
    </div>
    <div style='margin-top:20px;'>
        <div style='display: flex; justify-content: space-between;align-items: center;'>
            <div style='font-size: 12px; font-weight: 400; color: #74788D;'>Tax Cost</div>
            <div style='font-size: 12px; font-weight: 600; color: #2F2F2F;'>${formatCurrencyWithSymbol(params?.data?.taxCost, 2, '$')}</div>
        </div>
        <div style='display: flex; justify-content: space-between;align-items: center;'>
            <div style='font-size: 12px; font-weight: 400; color: #74788D;'>Realized Gain/Loss</div>
            <div style='font-size: 12px; font-weight: 600; color: #2F2F2F;'>${formatCurrencyWithSymbol(params?.data?.rgl, 2, '$')}</div>
        </div>
    </div>

</div>`
  } else if (params.data.type === 'SCENARIO_TRANSITION') {
    return `<div style='background-color: white;font-family: Open Sans;padding: 10px;'>
    <div style='display: flex; justify-content: space-between;align-items: flex-start;'>
        <div>
            <div style='font-size: 14px; font-weight: 500; color: #74788D;'>Market Value</div>
            <div style='font-size: 18px; font-weight: 600; color: #2F2F2F;'>${formatCurrencyWithSymbol(params?.data?.portMv, 2, '$')}</div>
        </div>
        <div style='display: flex; justify-content: space-between;align-items: center;'>
            <div style='margin-left:50px;text-align: left;'>
                <div style='font-size: 12px; font-weight: 600; color: #3BBFA3;margin-bottom: 2px;'>${params?.data?.transferredShares}</div>
                <div style='width: 50px; height: 5px; border-radius: 5px; background-color: #3BBFA3;'></div>
                <div style='font-size: 12px; font-weight: 400; color: #74788D;'>Transfers</div>
            </div>
            <div style='text-align: right;'>
                <div style='font-size: 12px; font-weight: 600; color: #6AA6EA;margin-bottom: 2px;'>${params?.data?.buyShares}</div>
                <div style='width: 50px; height: 5px; border-radius: 5px; background-color: #6AA6EA;'></div>
                <div style='font-size: 12px; font-weight: 400; color: #74788D;'>Buy</div>
            </div>
        </div>
    </div>
    <div style='margin-top:20px;'>
        <div style='display: flex; justify-content: space-between;align-items: center;'>
            <div style='font-size: 12px; font-weight: 400; color: #74788D;'>Tracking Error</div>
            <div style='font-size: 12px; font-weight: 600; color: #2F2F2F;'>${((params?.data?.propTe || 0) * 100)?.toFixed(2)}%</div>
        </div>
        <div style='display: flex; justify-content: space-between;align-items: center;'>
            <div style='font-size: 12px; font-weight: 400; color: #74788D;'>Unrealized Gain/Loss</div>
            <div style='font-size: 12px; font-weight: 600; color: #2F2F2F;'>${formatCurrencyWithSymbol(params?.data?.totalUrgl, 2, '$')}</div>
        </div>
    </div>

</div>`
  }
  return ''
}

const edgeTooltipFormatter = (params) => {
  return `<div style='background-color: white; width: 160px;'>
                    <div style='font-size: 12px; font-weight: 400; color: #74788D;'>Transferred Amount</div>
                    <div style='font-size: 12px; font-weight: 600; color: #34475A;'>${formatCurrencyWithSymbol((params?.data?.value), 2, '$')}</div>
                  </div>`
}

const SummaryTab = ({ data, umaType, setSelectedGroup, originalUMASummary, sankeyTransitionData, loading, toggleSummaryChartExpandView }) => {
  return (
    <>
      <Grid container justifyContent='center'>
        <Grid item xs={12} lg={12} margin='0px auto'>
          {
            umaType === 'TRANSITION'
              ? (
                <>
                  {
                    loading ? (
                      <Skeleton variant='rectangular' sx={{ mt: '10px', height: '450px' }} />
                    ) : (
                      <SummaryTaxCostChart scenarioData={originalUMASummary} />
                    )
                  }
                  <AllocationData scenarioListData={originalUMASummary} setSelectedGroup={setSelectedGroup} loading={loading} />
                </>
              )
              : umaType === 'SCENARIO_TRANSITION'
                ? (
                  <>
                    {
                      loading ? (
                        <Skeleton variant='rectangular' sx={{ mt: '10px', height: '450px' }} />
                      ) : (
                        !data?.length ? (
                          <Typography sx={{ fontSize: '14px', color: '#34475A', py: 2 }}>No data available.</Typography>
                        ) : (
                              <AccountSankeyTransitionChart summaryChartData={sankeyTransitionData} toggleSummaryChartExpandView={toggleSummaryChartExpandView} />
                        )
                      )
                    }
                    <AccountTable data={data} summaryCols={summaryCols} loading={loading} />
                  </>
                )
                : ''
          }
        </Grid>
      </Grid>
    </>
  )
}

const AllocationData = ({ scenarioListData, setSelectedGroup, loading }) => {
  const handleClick = (data) => {
    setSelectedGroup({
      groupType: 'UMA',
      umaType: 'SCENARIO_TRANSITION',
      aggGroupCd: data?.aggCd,
      aggGroupId: data?.aggId,
      aggGroupName: data?.aggName,
      groupLabel: data?.groupLabel
    })
  }

  return (
    <Grid container spacing={2} columns={16} my={2}>
      {
        loading ? (
          Array.from({ length: 4 }).map((_, index) => (
            <Grid item xs={4} key={index}>
              <Skeleton variant='rectangular' height={'400px'} />
            </Grid>
          ))
        ) : (
          <>
            <Grid item xs={4}>
              <Card sx={{
                color: '#002A59',
                cursor: 'pointer',
                boxShadow: 'none'
              }}
              >
                <CardContent>
                  <Stack direction='column' sx={{ color: '#031944' }}>
                    <Box sx={{ flexBasis: '15%' }}>
                      <Typography sx={{ fontSize: '16px', py: '20px', textAlign: 'center', fontWeight: 600 }}>
                        Scenario Name
                      </Typography>
                      <Divider sx={{ height: '2px', color: '#74788D' }} />
                    </Box>
                    <Box sx={{ flexBasis: '15%' }}>
                      <Typography sx={{ fontSize: '16px', py: '20px', textAlign: 'center', fontWeight: 600 }}>
                        Tracking Error
                      </Typography>
                      <Divider sx={{ height: '2px', color: '#74788D' }} />
                    </Box>
                    <Box sx={{ flexBasis: '15%' }}>
                      <Typography sx={{ fontSize: '16px', py: '20px', textAlign: 'center', fontWeight: 600 }}>
                        Unrealized Gain Loss
                      </Typography>
                      <Divider sx={{ height: '2px', color: '#74788D' }} />
                    </Box>
                    <Box sx={{ flexBasis: '15%' }}>
                      <Typography sx={{ fontSize: '16px', py: '20px', textAlign: 'center', fontWeight: 600 }}>
                        Realized Gain Loss
                      </Typography>
                      <Divider sx={{ height: '2px', color: '#74788D' }} />
                    </Box>
                    <Box sx={{ flexBasis: '15%' }}>
                      <Typography sx={{ fontSize: '16px', py: '20px', textAlign: 'center', fontWeight: 600 }}>
                        No. of Accounts
                      </Typography>
                      <Divider sx={{ height: '2px', color: '#74788D' }} />
                    </Box>
                    <Box sx={{ flexBasis: '15%' }}>
                      <Typography sx={{ fontSize: '16px', py: '20px', textAlign: 'center', fontWeight: 600 }}>
                        Tax Cost
                      </Typography>
                    </Box>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
            {
              (scenarioListData || []).map((data, index) => (
                <Grid item xs={4} key={index}>
                  <Card
                    onClick={() => handleClick(data)}
                    sx={{
                      color: '#002A59',
                      cursor: 'pointer',
                      boxShadow: 'none',
                      '&:hover': {
                        color: 'white',
                        backgroundColor: '#002A59',
                        transition: 'all 0.2s ease-in',
                        scale: '1.02',
                        '& .MuiDivider-root': {
                          borderColor: 'white'
                        }
                      }
                    }}
                  >
                    <CardContent>
                      <Stack direction='column'>
                        <Box sx={{ flexBasis: '10%' }}>
                          <Typography sx={{ fontSize: '16px', py: '20px', textAlign: 'center' }}>
                            {data?.aggName || '-'}
                          </Typography>
                          <Divider sx={{ height: '2px', color: '#74788D' }} />
                        </Box>
                        <Box sx={{ flexBasis: '15%' }}>
                          <Typography sx={{ fontSize: '16px', py: '20px', textAlign: 'center' }}>
                            {data?.propTe !== null && data?.propTe !== undefined ? (data?.propTe * 100)?.toFixed(2) + '%' : '-'}
                          </Typography>
                          <Divider sx={{ height: '2px', color: '#74788D' }} />
                        </Box>
                        <Box sx={{ flexBasis: '15%' }}>
                          <Typography sx={{ fontSize: '16px', py: '20px', textAlign: 'center' }}>
                            {data?.totalUrgl !== null && data?.totalUrgl !== undefined ? formatCurrencyWithSymbol(data?.totalUrgl, '2', '$') : '-'}
                          </Typography>
                          <Divider sx={{ height: '2px', color: '#74788D' }} />
                        </Box>
                        <Box sx={{ flexBasis: '15%' }}>
                          <Typography sx={{ fontSize: '16px', py: '20px', textAlign: 'center' }}>
                            {data?.totalRgl !== null && data?.totalRgl !== undefined ? formatCurrencyWithSymbol(data?.totalRgl, '2', '$') : '-'}
                          </Typography>
                          <Divider sx={{ height: '2px', color: '#74788D' }} />
                        </Box>
                        <Box sx={{ flexBasis: '15%' }}>
                          <Typography sx={{ fontSize: '16px', py: '20px', textAlign: 'center' }}>
                            {data?.accounts || '-'}
                          </Typography>
                          <Divider sx={{ height: '2px', color: '#74788D' }} />
                        </Box>
                        <Box sx={{ flexBasis: '15%' }}>
                          <Typography sx={{ fontSize: '16px', py: '20px', textAlign: 'center' }}>
                            {data?.taxCost !== null && data?.taxCost !== undefined ? formatCurrencyWithSymbol(data?.taxCost, '2', '$') : '-'}
                          </Typography>
                        </Box>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
              ))
            }
          </>
        )
      }
    </Grid>
  )
}

const AccountTable = ({ data, summaryCols, loading }) => {
  return (
    loading ? (
      <TableContainer mt={10}>
        <Table className='risk-page-table'>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              {summaryCols.map((item, index) => {
                return (
                  <TableCell key={index}>{item.headerName}</TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.from({ length: 5 }).map((_, i) => (
              <TableRow key={i}>
                {
                  Array.from({ length: summaryCols.length + 1 }).map((_, j) => (
                    <TableCell key={j}>
                      <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
                    </TableCell>
                  ))
                }
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    ) : (
      data?.length ? (
        <Grid container gap={1} justifyContent='space-between' mt={1}>
          <Grid item xs={12}>
            <DataGridPro
              autoHeight
              density='compact'
              rows={data || []}
              getRowId={(row) => row?.accountId}
              columns={summaryCols}
              hideFooter
            />
          </Grid>
        </Grid>
      ) : ''
    )
  )
}

const AccountSankeyTransitionChart = ({ summaryChartData, toggleSummaryChartExpandView }) => {
  const dispatch = useDispatch()

  const openExpandedView = () => {
    toggleSummaryChartExpandView()
    dispatch(enableLegacyViewReducer())
  }

  return (
    <Grid item xs={12} lg={10} margin='30px auto' justifyContent='center'>
      <Tooltip title='Expand'>
        <IconButton
          id='expand-button'
          onClick={() => openExpandedView()}
          sx={{ position: 'absolute', top: '0', right: '0' }}
        >
          <IoIosExpand size='18px' color='#74788D' />
        </IconButton>
      </Tooltip>
      <ReactECharts
        option={{
          tooltip: {
            trigger: 'item',
            triggerOn: 'mousemove',
            formatter: (params) => {
              if (params.dataType === 'node') {
                return nodeTooltipFormatter(params)
              } else if (params.dataType === 'edge') {
                return edgeTooltipFormatter(params)
              }
              return ''
            }
          },
          series: [
            {
              type: 'sankey',
              ...summaryChartData,
              levels: [
                {
                  depth: 0,
                  label: {
                    show: true,
                    formatter: (params) => {
                      return params?.data?.accountName
                    }
                  }
                },
                {
                  depth: 1,
                  label: {
                    show: true,
                    formatter: (params) => {
                      return params?.data?.accountName
                    }
                  }
                }
              ],
              emphasis: {
                focus: 'adjacency'
              },

              lineStyle: {
                color: 'source',
                curveness: 0.5
              },
              nodeWidth: 10
            }
          ]
        }}
        style={{ width: '100%', maxWidth: '850px', height: '400px' }}
        opts={{ renderer: 'svg' }}
      />
    </Grid>
  )
}

export default SummaryTab
