import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  userGroup: '',
  allowedSponsorList: [],
  selectedSponsor: '',
  expandedChartName: ''
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  // action as functions
  reducers: {
    storeUserGroup (state, action) {
      state.userGroup = action.payload
    },
    storeAclUserGrp (state, action) {
      state.aclUserGrp = action.payload
    },
    storeSelectedSponsor (state, action) {
      state.selectedSponsor = action.payload
    },
    storeSponsorList (state, action) {
      state.allowedSponsorList = action.payload
    },
    storeExpandedChartName (state, action) {
      state.expandedChartName = action.payload
    },
    clearUserStore (state, action) {
      if (action.payload === 'RESET') {
        state.userGroup = ''
        state.allowedSponsorList = []
        state.selectedSponsor = ''
        state.expandedChartName = ''
        state.aclUserGrp = ''
      }
    }
  }
})

export const { storeUserGroup, storeAclUserGrp, storeSelectedSponsor, storeSponsorList, clearUserStore, storeExpandedChartName } = userSlice.actions
export default userSlice.reducer
