import React from 'react'
import { TextField } from '@mui/material'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

export const FormikDate = ({ label, name, onHandleChange, fieldValues, disabled }) => {
  const showPastDate = ['purchaseDate']

  return (
    <>
      <TextField
        disabled={disabled}
        margin='dense'
        id={name}
        name={name}
        label={label}
        type='date'
        fullWidth
        variant='standard'
        value={dayjs.utc(fieldValues).format('YYYY-MM-DD')}
        onChange={onHandleChange}
        InputProps={{
          inputProps: {
            min: showPastDate?.includes(name) ? null : dayjs.utc().format('YYYY-MM-DD'),
            max: showPastDate?.includes(name) ? dayjs.utc().format('YYYY-MM-DD') : null
          }
        }}
        InputLabelProps={{ shrink: true }}
      />
    </>
  )
}
