import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  modelsData: [],
  summaryData: {},
  accountData: [],
  marketValueData: []
}

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  // action as functions
  reducers: {
    storeModelsData (state, action) {
      state.modelsData = action.payload
    },
    storeSummaryData (state, action) {
      state.summaryData = action.payload
    },
    storeAccountData (state, action) {
      state.accountData = action.payload
    },
    storeMarketValueData (state, action) {
      state.marketValueData = action.payload
    },
    clearStore (state, action) {
      if (action.payload === 'RESET') {
        state.modelsData = []
        state.summaryData = {}
        state.accountData = []
        state.marketValueData = []
      }
    }
  }
})

export const { storeModelsData, storeSummaryData, storeAccountData, storeMarketValueData, clearStore } = dashboardSlice.actions
export default dashboardSlice.reducer
