import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    showLegacyView: false
}

const tradeSlice = createSlice({
    name: 'trade',
    initialState,
    // action as functions
    reducers: {
        enableLegacyViewReducer(state, action) {
            state.showLegacyView = true
        },
        disableLegacyViewReducer(state, action) {
            state.showLegacyView = false
        },
        toggleLegacyViewReducer(state, action) {
            state.showLegacyView = !state.showLegacyView
        }
    }
})

export const { enableLegacyViewReducer, disableLegacyViewReducer, toggleLegacyViewReducer } = tradeSlice.actions
export default tradeSlice.reducer