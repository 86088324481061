import React from 'react'
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  registerables as registerablesJS
} from 'chart.js'

import { Box, Card, CardContent, Typography } from '@mui/material'
import { Bar } from 'react-chartjs-2'
ChartJS.register(...registerablesJS)

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement
)

const PortfolioModel = ({ data }) => {
  const options1 = {
    type: 'bar',
    responsive: true,
    bezierCurve: false,
    maintainAspectRatio: false,
    plugins: {
      filler: {
        propagate: false
      },
      legend: {
        display: false
      },
      datalabels: {
        display: false
      },
      hover: {
        mode: null
      },
      tooltip: {
        callbacks: {
          title: function (tooltipItems) {
            // Return value for title
            return data[tooltipItems[0].dataIndex].assetClassDesc
          }
        }
      }
    },
    scales: {
      x: {
        grid: {
          display: false,
          color: 'rgba(0,0,0,0)',
          drawOnChartArea: false,
          drawBorder: false
        },
        ticks: {
          font: {
            size: 10
          }
        }
      },
      y: {
        border: {
          display: false
        },
        ticks: {
          stepSize: 10,
          suggestedMin: 0,
          suggestedMax: 100,
          callback: function (value, index, ticks) {
            return value + '%'
          }
        },
        display: true,
        grid: {
          color: 'rgba(116, 120, 141, 0.2)',
          borderColor: 'rgba(0,0,0,0)',
          drawBorder: false
        }
      }
    }
  }

  const chartData1 = {
    labels: data.map((item) => item.assetClass),
    datasets: [
      {
        label: 'Current portfolio',
        data: data.map((item) => item.currentPortfolioWeight ? (Number(item.currentPortfolioWeight) * 100).toFixed(2) : 0),
        backgroundColor: 'rgba(116, 120, 141, 0.3)',
        borderRadius: { topLeft: 5, topRight: 5, bottomLeft: 0, bottomRight: 0 },
        barThickness: 20
      },
      {
        label: 'Target portfolio',
        data: data.map((item) => item.targetPortfolioWeight ? (Number(item.targetPortfolioWeight) * 100).toFixed(2) : 0),
        borderColor: data.map((item) => Number(item.difference) > 0 ? '#34C38F' : '#F46A6A'),
        // important settings
        borderWidth: 2,
        pointRadius: 18,
        hoverRadius: 18,
        hoverBorderWidth: 2,
        showLine: false,
        pointStyle: 'line',
        type: 'line'
      }
    ]
  }
  const chartData2 = {
    labels: data.map((item) =>
      Number(item.difference) > 0
        ? ((item.difference) === 1
            ? (`+${(Number(item.difference) * 100)}%`)
            : (`+${(Number(item.difference) * 100).toFixed(2)}%`))
        : (`${(Number(item.difference) * 100).toFixed(2)}%`)),
    datasets: [{
      data: data.map((item) => item.difference ? (Number(item.difference) * 100).toFixed(2) : 0),
      backgroundColor: data.map((item) => Number(item.difference) > 0 ? '#34C38F' : '#F46A6A'),
      borderRadius: { topLeft: 5, topRight: 5, bottomLeft: 5, bottomRight: 5 },
      barThickness: 20
    }]
  }

  const options2 = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      filler: {
        propagate: false
      },
      legend: {
        display: false
      },
      datalabels: {
        display: false
      },
      tooltip: {
        enabled: false
      },
      hover: {
        mode: null
      }
    },
    scales: {
      x: {
        border: {
          display: false
        },
        position: 'top',
        grid: {
          display: false
        }
      },
      y: {
        border: {
          display: false
        },
        ticks: {
          callback: function (value, index, ticks) {
            return ''
          }
        },
        display: true,
        grid: {
          drawBorder: false,
          zeroLineWidth: 0, // Set zeroLineWidth to 0 to remove the zero line
          color: context => context.tick.value === 0 ? 'rgba(116, 120, 141, 0.2)' : ''
        }
      }
    }
  }

  return (
    <Card sx={{ height: '100%' }} id='allocation-card'>
      <CardContent>
        <Box display='flex' justifyContent='space-between' flexWrap='wrap' className='d-flex justify-content-between flex-wrap'>
          <Typography component='h3' className='text-title header-text'>Allocation Chart</Typography>
          <Box display='flex' justifyContent='space-between' flexWrap='wrap' alignItems='center'>
            <div className='current-legend'>
              <span />
              <Typography component='div' sx={{ display: 'inline' }} className='text-title'>Account Portfolio</Typography>
            </div>
            <div className='target-legend'>
              <span />
              <Typography component='div' sx={{ display: 'inline' }} className='text-title d-inline'>Strategy Portfolio</Typography>
            </div>
          </Box>
        </Box>
        <Box sx={{ height: 92, padding: '0 1.4% 0 6%', mt: 2 }}>
          <Bar options={options2} data={chartData2} />
        </Box>
        <Box sx={{ height: 230, mt: 1 }}>
          <Bar options={options1} data={chartData1} />
        </Box>
      </CardContent>
    </Card>
  )
}

export default PortfolioModel
