import { API } from 'aws-amplify'
import { useAuth } from '../contexts/AuthContext'
import * as Sentry from '@sentry/react'

export default function useDownloadLogs () {
  const { user } = useAuth()

  const createCSVDownloadLog = (dataDefinition, dataId, dataDownloaded, additionalInfo = {}) => {
    API.post('baseDataValidation', `data-validation/v1/${user?.userGroup}/data/download/log`, {
      body: {
        dataDefinition: dataDefinition || '',
        dataId: dataId || '',
        dataDownloaded: dataDownloaded || '',
        additionalInfo: additionalInfo || {}
      }
    })
      .then(res => {
      })
      .catch((error) => {
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
  }

  return { createCSVDownloadLog }
}
