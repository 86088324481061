import { getUserRole } from '../../../utils/getUserRole'
import { DeleteAPI } from '../components/Actions'

export const DeleteReportData = async (deleteRow, tabIndex) => {
  switch (tabIndex) {
    case 'stats-level-code':
      return await DeleteStatusLevelCode(deleteRow.statsLevelCd)
    case 'stats-master':
      return await DeleteStatusMaster(deleteRow.statsCode)
    default:
      return {}
  }
}

const DeleteStatusLevelCode = async (statsLevelCd) => {
  const userRole = getUserRole()
  return DeleteAPI('baseReportingMaintainURL', `data-maintenance/v1/${userRole}/reports/stats-level-code/${statsLevelCd}`)
}

const DeleteStatusMaster = async (statsCode) => {
  const userRole = getUserRole()
  return DeleteAPI('baseReportingMaintainURL', `data-maintenance/v1/${userRole}/reports/stats-mast/${statsCode}`)
}
