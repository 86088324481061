import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { API } from 'aws-amplify'
import { Box, Checkbox } from '@mui/material'
import { GridRowModes } from '@mui/x-data-grid-pro'
import * as Sentry from '@sentry/react'
import { useAuth } from '../../contexts/AuthContext'
import { useErrorToast } from '../../hooks/useErrorToast'
import Loader from '../Loader'
import AddIndustryModal from './components/AddIndustry'
import CustomEditCheckComponent from './components/CustomCheckboxField'
import CustomTooltipIcon from './components/CustomTooltipIcon'
import { CustomEditZscoreField, CustomZscoreField } from './components/CustomZscoreField'
import DataTable from './components/DataTable'
import { moduleConfig } from '../../contexts/data'
import { ACCESS_LEVEL } from '../../contstants/constants'

const Industry = ({ data, getUpdatedData, addIndustryData, allIndustries }) => {
  const [industryData, setIndustryData] = useState(data?.data || [])
  const [rowModesModel, setRowModesModel] = useState({})
  const { checkAccess } = useAuth()
  const [isLoading, setIsLoading] = useState(false)

  const { showError } = useErrorToast()

  const { user } = useAuth()
  const params = useParams()

  const dataSecure = localStorage.getItem('object')
  const getStoreData = dataSecure ? JSON.parse(dataSecure) : []
  const filterSecureData = getStoreData?.filter((item) => item?.accountId === params?.accountId)

  useEffect(() => {
    setIndustryData(data?.data || [])
    setIsLoading(false)
  }, [data])

  const columns = [
    { field: 'assetClassDesc', headerName: 'Sector', flex: 1, editable: data.fieldEditAllowed?.assetClassDesc },
    { field: 'industryId', headerName: 'Industry', flex: 2, editable: data.fieldEditAllowed?.industryId },
    { field: 'instruments', headerName: 'No. of Securities', flex: 1, editable: data.fieldEditAllowed?.instruments, type: 'number', align: 'right' },
    {
      field: 'weight',
      headerName: 'Weight(%)',
      flex: 1,
      editable: data.fieldEditAllowed?.weight,
      type: 'number',
      align: 'right',
      valueFormatter: (params) =>
        params.value ? (params.value * 100).toFixed(2) : 0
    },
    {
      field: 'industryExclude',
      headerName: 'Exclude',
      flex: 1,
      editable: data.fieldEditAllowed?.industryExclude,
      renderCell: (params) => (
        <Checkbox
          checked={params.value === 1}
          onClick={(event) => event.stopPropagation()}
          disabled={rowModesModel[params.row.id]?.mode !== GridRowModes.Edit}
        />
      ),
      renderEditCell: (params) => (
        <CustomEditCheckComponent {...params} />
      )
    },
    {
      field: 'min',
      headerName: 'Min',
      type: 'text',
      flex: 1,
      editable: data.fieldEditAllowed?.min,
      renderHeader: (params) => (
        <>
          Min
          <CustomTooltipIcon text='You can indicate a relative value by using the format B(+/-)(value). For example,  B-0.58 or B+0.58.' action='help' />
        </>
      ),
      renderCell: (params) => (
        <CustomZscoreField params={params} rowModesModel={rowModesModel} checkboxFieldName='industryExclude' deleteFieldName='accountIndRestrId' />
      ),
      renderEditCell: (params) => (
        <CustomEditZscoreField {...params} disabled={rowModesModel[params.row.id]?.mode !== GridRowModes.Edit} checkboxFieldName='industryExclude' />
      )
    },
    {
      field: 'max',
      headerName: 'Max',
      type: 'text',
      flex: 1,
      editable: data.fieldEditAllowed?.max,
      renderHeader: (params) => (
        <>
          Max
          <CustomTooltipIcon text='You can indicate a relative value by using the format B(+/-)(value). For example,  B-0.58 or B+0.58.' action='help' />
        </>
      ),
      renderCell: (params) => (
        <CustomZscoreField params={params} rowModesModel={rowModesModel} checkboxFieldName='industryExclude' deleteFieldName='accountIndRestrId' />
      ),
      renderEditCell: (params) => (
        <CustomEditZscoreField {...params} disabled={rowModesModel[params.row.id]?.mode !== GridRowModes.Edit} checkboxFieldName='industryExclude' />
      )
    }
  ]

  const editIndustryData = (industry) => {
    setIsLoading(true)
    let industryPayload = {
      sectorId: industry?.assetClassCd,
      industryId: industry?.industryId,
      industryExclude: !!industry?.industryExclude
    }
    if (!industry.industryExclude) {
      industryPayload = {
        ...industryPayload,
        min: industry?.min,
        max: industry?.max
      }
    }
    API.post('baseUriAccountOptimization', `account-customization/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/accounts/${params.accountId}/industry-restrictions`, {
      body: [
        {
          ...industryPayload
        }
      ]
    })
      .then((response) => {
        if (response && response?.success && response?.data && response?.data?.length) {
          const newIndustryData = industryData?.map((row) => {
            if (row.id === industry.id) {
              return { id: industry.id, ...row, ...response?.data[0] }
            }
            return row
          })
          setIndustryData(newIndustryData)
          getUpdatedData(newIndustryData)
        }
      })
      .catch((error) => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const deleteIndustryData = (id) => {
    setIsLoading(true)
    API.del('baseUriAccountOptimization', `account-customization/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/accounts/${params.accountId}/industry-restrictions`, {
      body: {
        accountIndustryRestrictionId: [id]
      }
    })
      .then((response) => {
        if (response.success) {
          const newIndustryData = industryData.filter((row) => (row.accountIndRestrId !== id))
          setIndustryData(newIndustryData)
          getUpdatedData(newIndustryData)
        }
      })
      .catch((error) => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }
  const customValidations = (row) => {
    return (row.minError || row.maxError) || (row.industryExclude !== 1 && (!row.min || !row.max))
  }

  const addNewIndustry = (data) => {
    setIsLoading(true)
    const industryPayload = data.map((industry) => {
      let payload = {
        sectorId: industry.assetClass,
        industryId: industry.industry,
        industryExclude: industry.exclude
      }
      if (!industry.exclude) {
        payload = {
          ...payload,
          min: industry.min,
          max: industry.max
        }
      }
      return payload
    })
    API.post('baseUriAccountOptimization', `account-customization/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/accounts/${params.accountId}/industry-restrictions`, {
      body: [
        ...industryPayload
      ]
    })
      .then((response) => {
        if (response && response?.success && response?.data && response?.data?.length) {
          addIndustryData()
          setIsLoading(false)
        }
      })
      .catch((error) => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
        setIsLoading(false)
      })
  }

  return (
    <>
      {isLoading ? <Loader /> : ''}
      <Box
        sx={{
          '.MuiDataGrid-footerContainer': {
            border: 'none'
          },
          '.MuiDataGrid-root': {
            border: 'none'
          }
        }}
      >
        {
         (
           (filterSecureData && filterSecureData[0]?.accountType === 'TRADING' &&
          checkAccess(moduleConfig.ACCOUNT_REVIEW, ACCESS_LEVEL.COMPONENT_ACCESS,
            { subModuleName: moduleConfig.PERSONALIZATION, component_name: moduleConfig.EDIT_TRADING_PERSONALIZATION })) ||
          ((filterSecureData && filterSecureData[0]?.accountType === 'TRANSITION' &&
           checkAccess(moduleConfig.ACCOUNT_REVIEW, ACCESS_LEVEL.COMPONENT_ACCESS,
             { subModuleName: moduleConfig.PERSONALIZATION, component_name: moduleConfig.EDIT_TRANSITION_PERSONALIZATION })))) &&
              (
                <AddIndustryModal addNewIndustry={addNewIndustry} allIndustries={allIndustries} />
              )
       }
        <DataTable
          data={industryData}
          initialColumns={columns}
          deleteKey='accountIndRestrId'
          editData={editIndustryData}
          deleteData={deleteIndustryData}
          setRowModes={setRowModesModel}
          customValidations={customValidations}
        />
      </Box>
    </>
  )
}

export default Industry
