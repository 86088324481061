import React, { useEffect, useContext } from 'react'
import { Box, Divider, Tabs, Tab } from '@mui/material'
import { tabNameData } from '../TabData'
import { TabPanelData } from './TabPanelData'
import CustomSearchComponent from './CustomSearchComponent'

import { requestDataContext } from '../DataMaintenance'
import { DataFormatting } from './DataFormatting'
import CustomDatePickComponent from './CustomDatePickComponent'

const TabPanel = (props) => {
  const { children, value, index, ...other } = props
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  )
}
export const TabDetails = (props) => {
  const { name } = props
  const [tabValue, setTabValue] = React.useState(tabNameData[name] ? tabNameData[name][0]?.id : '')
  const { responseData, setResponseData } = useContext(requestDataContext)

  useEffect(() => {
    setTabValue(tabNameData[name][0]?.id) // Reset the tab value when the 'name' prop changes
  }, [name])

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }

  // display data that is selected from search component's option list
  const showData = (selectedData, index) => {
    if (selectedData?.data?.data) {
      const updatedResponseData = Object.values(responseData)
      updatedResponseData[index] = selectedData
      const formattedResponseData = DataFormatting(updatedResponseData, tabNameData[name])
      setResponseData(formattedResponseData)
    }
  }

  const renderTabs = () => {
    if (name in tabNameData) {
      return tabNameData[name].map((tab, index) => (
        <Tab key={index} name={tab.id} label={tab.name} value={tab.id} />
      ))
    } else {
      return null
    }
  }

  return (
    <>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        variant='scrollable'
        scrollButtons='auto'
        aria-label='scrollable tabs'
      >
        {renderTabs()}
      </Tabs>
      <Divider sx={{ bgcolor: 'black' }} />
      {
        tabNameData[name].map((tab, index) => (
          <TabPanel key={index} value={tabValue} index={tab.id}>
            {(tab.searchable && tab.searchFunc) ? <CustomSearchComponent {...tab} showData={(data) => showData(data, index)} /> : ''}
            {/* Display data table only if there is selected data for searchable field */}
            {(tab.searchable && tab.dateSearchFunc) && (tab.id === 'instrument-split' || tab.id === 'instrument-delist')
              ? <CustomDatePickComponent {...tab} showData={(data) => showData(data, index)} />
              : ''}
            {tab.searchable
              ? (responseData[tab.id]?.data?.data?.length > 0 || ((tab.id === 'instrument-split' && responseData['instrument-split']?.data?.data?.length <= 0) ||
             (tab.id === 'instrument-delist' && responseData['instrument-delist']?.data?.data?.length <= 0)))
                  ? <TabPanelData tabIndex={tab.id} name={name} />
                  : ''
              : <TabPanelData tabIndex={tab.id} name={name} />}
          </TabPanel>))
      }
    </>
  )
}
