import React, { useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import dayjs from 'dayjs'
import * as Sentry from '@sentry/react'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { Autocomplete, Box, Button, FormControl, InputLabel, MenuItem, OutlinedInput, Select, Skeleton, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import { randomId } from '@mui/x-data-grid-generator'
import { DataGridPro, GridActionsCellItem, gridClasses, GridRowModes } from '@mui/x-data-grid-pro'
import AddIcon from '@mui/icons-material/Add'
import CancelIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import InfoOutlined from '@mui/icons-material/InfoOutlined'
import SaveIcon from '@mui/icons-material/Save'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LoadingButton } from '@mui/lab'
import { useAuth } from '../../../contexts/AuthContext'
import { useErrorToast } from '../../../hooks/useErrorToast'
import { useSuccessToast } from '../../../hooks/useSuccessToast'
import { isNumberOnly, isWholeNumber, numberOnlyPasteHandler, wholeNumberPasteHandler } from '../../../utils/NumberUtils'

const UpdatePropertiesModal = ({ selectedItem, index, allScenarioList }) => {
  const { scenarioId, propertyCode, propertyValue, accountId } = selectedItem
  const [data] = useState({
    scenarioId,
    propertyCode,
    propertyValue,
    accountId
  })
  const [approvalProperties, setApprovalProperties] = useState([])
  const [hasDuplicatePropertyCode, setHasDuplicatePropertyCode] = useState(false)
  const { user } = useAuth()
  const { showError } = useErrorToast()
  const { showSuccess } = useSuccessToast()
  const [isOptPropertiesLoading, setIsOptPropertiesLoading] = useState(true)
  const [isPropertyCodesLoading, setIsPropertyCodesLoading] = useState(true)

  const [rowModesModel, setRowModesModel] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [scenarioGroupId, setScenarioGroupId] = useState((index && index[1] && index[1]?.optDetails?.map(obj => obj.scenarioId)?.sort((a, b) => a - b)?.join(',')) || '1,2,3')
  const [propertiesList, setPropertiesList] = useState([])

  const handleChange = (event) => {
    const {
      target: { value }
    } = event
    setScenarioGroupId(value)
    setIsOptPropertiesLoading(true)
    setRowModesModel({})
    setHasDuplicatePropertyCode(false)
  }

  const getAdhocOptm = (formatteddata) => {
    const accId = index[0]
    setIsSubmitting(true)
    API.patch(
      'baseOptimizationURL',
      `optimization/v1/${user?.userGroup}/accounts/${accId}`,
      { body: formatteddata }
    )
      .then((response) => {
        showSuccess(response.message)
      })
      .catch((error) => {
        if (Array.isArray(error.response?.data?.errorInfo?.userMessage)) {
          error.response?.data?.errorInfo?.userMessage?.forEach((element) => {
            showError(element.message)
          })
        } else {
          showError(error.response?.data?.errorInfo?.userMessage || error.message)
        }
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => setIsSubmitting(false))
  }

  const getApprovalProperties = async () => {
    const accountId = index[0]
    API.get(
      'baseOptimizationURL',
      `optimization/v1/${user?.userGroup}/opt-properties/accounts/${accountId}/scenarios/${scenarioGroupId}`
    )
      .then((response) => {
        if (response.data.length > 0) {
          const proData = []
          response.data.forEach((item) => {
            item.data.forEach((property) => {
              if (property.propertyCode !== 'SCENARIO_GROUP_ID') {
                proData.push({
                  scenarioId: item.scenarioId,
                  scenarioDesc: item.scenarioDesc,
                  propertyCode: property.propertyCode,
                  propertyValue: property.propertyValue,
                  propertySource: property?.propertySource,
                  propertyValueCopy: property?.propertyValue,
                  propertySourceCopy: property?.propertySource,
                  id: randomId()
                })
              }
            })
          })
          setApprovalProperties(proData)
        }
      })
      .catch((error) => {
        if (Array.isArray(error.response?.data?.errorInfo?.userMessage)) {
          error.response?.data?.errorInfo?.userMessage?.forEach((element) => {
            showError(element.message)
          })
        } else {
          showError(error.response?.data?.errorInfo?.userMessage || error.message)
        }
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => {
        setIsOptPropertiesLoading(false)
      })
  }

  const getAdhocPropertiesList = () => {
    setIsPropertyCodesLoading(true)
    API.get(
      'baseOptimizationURL',
      `optimization/v1/${user?.userGroup}/adhoc-property-list`
    )
      .then((response) => {
        if (response?.data?.length) {
          setPropertiesList(response?.data)
        }
      })
      .catch((error) => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => {
        setIsPropertyCodesLoading(false)
      })
  }

  useEffect(() => {
    if (user) {
      getApprovalProperties(data)
      getAdhocPropertiesList()
    }
  }, [user, scenarioGroupId])

  const handleSubmitHandler = () => {
    const formatteddata = approvalProperties.map((item) => {
      return {
        scenarioId: item.scenarioId,
        propertyCode: item.propertyCode,
        propertyValue: item.propertyValue,
        propertySource: item?.propertySource
      }
    })
    getAdhocOptm(formatteddata)
  }

  if (approvalProperties === null) {
    return null
  }

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true
  }

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true
  }

  const handleCellEditStart = (params, event) => {
    event.defaultMuiPrevented = true
  }

  const handleCellEditStop = (params, event) => {
    event.defaultMuiPrevented = true
  }

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
  }

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
  }

  const handleDeleteClick = (id) => () => {
    const updatedProperties = approvalProperties.filter((row) => row.id !== id)
    setApprovalProperties(updatedProperties)
    setHasDuplicatePropertyCode(updatedProperties.some((currentProperty, index) => {
      return updatedProperties.find((otherProperty, otherIndex) =>
        otherIndex !== index && otherProperty.propertyCode === currentProperty.propertyCode && otherProperty.scenarioId === currentProperty.scenarioId
      )
    }))
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
  }

  const handleCancelClick = (id, row) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    })
    const editedRow = approvalProperties.find((row) => row.id === id)
    if (editedRow.isNew) {
      setApprovalProperties(approvalProperties.filter((row) => row.id !== id))
    }
  }

  const processRowUpdate = (newRow) => {
    let updatedRow = { ...newRow }
    if (updatedRow.scenarioDesc === null || updatedRow.propertyCode === null || updatedRow.propertyValue === null || updatedRow.propertyValue === '') {
      setRowModesModel({ ...rowModesModel, [updatedRow.id]: { mode: GridRowModes.Edit } })
      return approvalProperties
    }
    // update source and value copy variables on saving new row
    if (newRow?.isNew) {
      updatedRow = {
        ...newRow,
        propertySourceCopy: newRow.propertySource,
        propertyValueCopy: newRow?.propertyValue,
        scenarioId: newRow.scenarioDesc?.split('+')[0],
        scenarioDesc: newRow.scenarioDesc?.split('+')[1],
        isNew: false
      }
      updatedRow.hasError = approvalProperties.some((otherProperty) =>
        otherProperty.propertyCode === updatedRow.propertyCode && otherProperty.scenarioId === updatedRow.scenarioId
      )
    } else if (newRow?.propertyValue !== newRow?.propertyValueCopy) {
      updatedRow = { ...newRow, propertySource: 'ADHOC' }
    } else {
      updatedRow = { ...newRow, propertySource: newRow?.propertySourceCopy }
    }
    const updatedProperties = approvalProperties.map((row) => (row.id === newRow.id ? updatedRow : row))
    setApprovalProperties(updatedProperties)
    // check if any rows have same property code
    setHasDuplicatePropertyCode(updatedProperties.some((currentProperty, index) => {
      return updatedProperties.find((otherProperty, otherIndex) =>
        otherIndex !== index && otherProperty.propertyCode === currentProperty.propertyCode && otherProperty.scenarioId === currentProperty.scenarioId
      )
    }))
    return updatedRow
  }

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel)
  }

  const handleAddRecordClick = () => {
    const id = randomId()
    setApprovalProperties((oldRows) => [{
      id,
      isNew: true,
      scenarioId: null,
      scenarioDesc: null,
      propertyCode: null,
      propertyValue: null,
      propertySource: 'ADHOC',
      propertyValueCopy: null,
      propertySourceCopy: null,
      hasError: false
    },
    ...oldRows
    ])
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit }
    }))
  }

  const getEditCellFromType = (params, propertyType) => {
    if (!params.row.isNew) {
      return <TextField
        fullWidth
        value={params?.value}
        onChange={(e) => {
          params?.api?.setEditCellValue({ id: params?.row?.id, field: params?.field, value: e.target.value })
        }}
      />
    }
    switch (propertyType) {
      case 'INT':
        return <TextField
          fullWidth
          value={params?.value}
          onChange={(e) => {
            params?.api?.setEditCellValue({ id: params?.row?.id, field: params?.field, value: e.target.value })
          }}
          onKeyDown={isWholeNumber}
          onPaste={wholeNumberPasteHandler}
        />
      case 'DECIMAL':
        return <TextField
          fullWidth
          value={params?.value}
          onChange={(e) => {
            params?.api?.setEditCellValue({ id: params?.row?.id, field: params?.field, value: e.target.value })
          }}
          onKeyDown={isNumberOnly}
          onPaste={numberOnlyPasteHandler}
        />
      case 'STRING':
        return <TextField
          fullWidth
          value={params?.value}
          onChange={(e) => {
            params?.api?.setEditCellValue({ id: params?.row?.id, field: params?.field, value: e.target.value })
          }}
        />
      case 'BOOLEAN':
        return <Select
          fullWidth
          value={params?.value || ''}
          onChange={(e) => {
            params?.api?.setEditCellValue({ id: params?.row?.id, field: params?.field, value: e.target.value })
          }}
        >
          <MenuItem value='0'>
            0
          </MenuItem>
          <MenuItem value='1'>
            1
          </MenuItem>
        </Select>
      case 'DATE':
        return (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={dayjs(params?.value)}
              onChange={(value) => {
                params?.api?.setEditCellValue({ id: params?.row?.id, field: params?.field, value: dayjs(value).format('YYYY-MM-DD') })
              }}
              format='YYYY-MM-DD'
              fullWidth
            />
          </LocalizationProvider>
        )
      default:
        return ''
    }
  }

  const getScenarioList = () => {
    const currentScenario = Object.keys(allScenarioList).find(key => allScenarioList[key].map(obj => obj.scenarioId).join(',') === scenarioGroupId)
    return currentScenario ? (allScenarioList[currentScenario] || []) : []
  }

  const columns = [
    {
      field: 'scenarioDesc',
      headerName: 'Scenario Description',
      flex: 1,
      editable: true,
      renderEditCell: (params) => (
        <Select
          fullWidth
          value={params?.value || ''}
          onChange={(e) => {
            // params?.api?.setEditCellValue({ id: params?.row?.id, field: 'scenarioId', value: e.target.value?.key })
            params?.api?.setEditCellValue({ id: params?.row?.id, field: params?.field, value: e.target.value })
          }}
        >
          {
            getScenarioList().map(scenario => (
              <MenuItem value={scenario?.scenarioId + '+' + scenario?.scenarioDesc}>
                {scenario?.scenarioDesc}
              </MenuItem>
            ))
          }
        </Select>
      )
    },
    {
      field: 'propertyCode',
      headerName: 'Property Code',
      flex: 1,
      editable: true,
      renderEditCell: (params) => (
        <Autocomplete
          value={params?.value}
          options={propertiesList?.map(propertyObj => propertyObj?.propertyCode)}
          fullWidth
          slotProps={{
            popper: {
              sx: {
                width: 'auto !important',
              }
            }
          }}
          onChange={(e, newValue) => {
            params?.api?.setEditCellValue({ id: params?.row?.id, field: 'propertyValue', value: '' })
            params?.api?.setEditCellValue({ id: params?.row?.id, field: params?.field, value: newValue })
          }}
          renderInput={(params) => (
            <TextField {...params} fullWidth />
          )}
        />
      )
    },
    {
      field: 'propertyValue',
      headerName: 'Property Value',
      editable: true,
      flex: 1,
      align: 'right',
      headerAlign: 'right',
      renderEditCell: (params) => {
        const propertyIndex = propertiesList.findIndex(obj => obj?.propertyCode === params?.row?.propertyCode)
        if (propertyIndex > -1)
          return getEditCellFromType(params, propertiesList[propertyIndex]?.dataType)
        return null
      }
    },
    {
      field: 'propertySource',
      headerName: 'Property Source',
      flex: 1,
      align: 'right',
      headerAlign: 'right'
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      getActions: ({ id, row }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              key='save'
              icon={<SaveIcon />}
              label='Save'
              onClick={handleSaveClick(id, row)}
            />,
            <GridActionsCellItem
              key='cancel'
              icon={<CancelIcon />}
              label='Cancel'
              className='textPrimary'
              onClick={handleCancelClick(id, row)}
              color='inherit'
            />
          ]
        }
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            disabled={isPropertyCodesLoading}
            key='edit'
            label='Edit'
            className='textPrimary'
            onClick={handleEditClick(id)}
            color='inherit'
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            disabled={isPropertyCodesLoading}
            key='delete'
            label='Delete'
            className='textPrimary'
            onClick={handleDeleteClick(id)}
            color='inherit'
          />
        ]
      }
    }
  ]

  const getRowId = (row) => row.id
  const tableHeaderHTML = columns.map(({ headerName }, index) => <TableCell key={index} sx={{ padding: '8px' }}>{headerName}</TableCell>)
  const rowSkeleton = columns.map(({ headerName }, index) => <TableCell key={index} sx={{ padding: '8px' }}><Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} /></TableCell>)

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography style={{ textAlign: 'left', fontSize: '22px', fontWeight: 400 }}>Update Properties</Typography>
        <FormControl sx={{ m: 1, width: 300 }}>
          <InputLabel id='demo-multiple-name-label'>Scenario Group Id</InputLabel>
          <Select
            labelId='demo-multiple-name-label'
            id='demo-multiple-name'
            value={scenarioGroupId}
            disabled={isOptPropertiesLoading}
            onChange={handleChange}
            size='small'
            input={<OutlinedInput label='Scenario Group Id' />}
            label='Scenario Group Id'
          >
            {
              Object.keys(allScenarioList).map(scenario => (
                <MenuItem
                  key={scenario}
                  value={allScenarioList[scenario].map(obj => obj.scenarioId).join(',')}
                >
                  {scenario + ' (' + allScenarioList[scenario].map(obj => (obj.scenarioGroupId)) + ')'}
                </MenuItem>
              ))
            }
          </Select>
        </FormControl>
      </Box>
      {isOptPropertiesLoading
        ? (
          <Table className='security-holdings-table'>
            <TableHead>
              <TableRow style={{ position: 'sticky', top: '0', background: 'white' }}>
                {tableHeaderHTML}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {rowSkeleton}
              </TableRow>
              <TableRow>
                {rowSkeleton}
              </TableRow>
              <TableRow>
                {rowSkeleton}
              </TableRow>
            </TableBody>
          </Table>
        )
        : (
          <>
            {!approvalProperties
              ? (
                <>
                  <h6 style={{ color: 'black', height: '10px', textAlign: 'center' }}>No Data found</h6>
                </>
              )
              : (
                <>
                  <DataGridPro
                    density='compact'
                    autoHeight
                    rows={approvalProperties}
                    columns={columns}
                    loading={isOptPropertiesLoading}
                    getRowId={getRowId}
                    pagination
                    pageSizeOptions={[10, 15, 25, 50, 100]}
                    editMode='row'
                    rowModesModel={rowModesModel}
                    onRowModesModelChange={handleRowModesModelChange}
                    onRowEditStart={handleRowEditStart}
                    onRowEditStop={handleRowEditStop}
                    onCellEditStart={handleCellEditStart}
                    onCellEditStop={handleCellEditStop}
                    isCellEditable={(params) => {
                      if (params.row.isNew) {
                        return true
                      }
                      if (params.field === 'propertyValue') {
                        return true
                      }
                      return false
                    }}
                    rowSelection={false}
                    getCellClassName={(params) => params?.row?.hasError === true ? 'error-row' : ''}
                    processRowUpdate={processRowUpdate}
                    initialState={{
                      ...approvalProperties?.initialState,
                      pagination: { paginationModel: { pageSize: 10 } }
                    }}
                    sx={{
                      '.error-row': {
                        backgroundColor: '#f05f5f1a'
                      },
                      [`.${gridClasses.main}`]: {
                        height: 'min(100vh - 56px - 39px - 53px - 52px, 600px - 56px - 39px - 53px - 52px)',
                      },
                      [`.${gridClasses.cell}:focus`]: {
                        outline: 'none'
                      },
                      [`.${gridClasses['cell--editing']}`]: {
                        outline: 'none'
                      },
                      [`.${gridClasses['cell--editing']}:focus`]: {
                        outline: 'none'
                      },
                      '.MuiOutlinedInput-notchedOutline': {
                        border: 'none'
                      },
                      [`.${gridClasses.virtualScroller}`]: {
                        overflowY: 'auto !important',
                        scrollbarGutter: 'stable',
                        scrollbarWidth: 'none'
                      }
                    }}
                  />
                </>
              )}
          </>
        )}
      {!isOptPropertiesLoading
        ? (
          <Box sx={{ display: 'flex', justifyContent: hasDuplicatePropertyCode ? 'space-between' : 'flex-end', mt: 2, alignItems: 'center' }}>
            {
              hasDuplicatePropertyCode
                ? <Typography color='#FF6161' fontSize='14px'>Duplicate property code is not allowed for the same scenario.</Typography>
                : ''
            }
            <Box>
              <Button variant='contained' startIcon={<AddIcon />} onClick={handleAddRecordClick} sx={{ mr: 1 }}>Add Property</Button>
              {
                !approvalProperties
                  ? ''
                  : isSubmitting
                    ? (
                      <LoadingButton
                        loading
                        loadingPosition='start'
                        startIcon={<SaveIcon />}
                        variant='outlined'
                      >
                        Run Optimization
                      </LoadingButton>
                    )
                    : (
                      <Button
                        variant='contained'
                        disabled={Object.keys(rowModesModel).some(rowId => rowModesModel[rowId].mode === GridRowModes.Edit) || hasDuplicatePropertyCode}
                        endIcon={<Tooltip title='Refresh the screen after 10 minutes to see latest opt-run data' placement='top-end'><InfoOutlined /></Tooltip>}
                        onClick={() => handleSubmitHandler()}
                      >Run Optimization
                      </Button>
                    )
              }
            </Box>
          </Box>
        )
        : ''}
    </>
  )
}
export default UpdatePropertiesModal
