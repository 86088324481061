import React, { useContext } from 'react'
import { Card, CardContent, Grid, Stack, Tooltip, Typography } from '@mui/material'
import { summaryDataContext } from '../Index'
import { decTwoPercentage, decTwoPlaces, formatCurrencyWithSymbol, formatNumbersWithSymbol } from './dataProcess/DataProcess'

const accSummaryData = (data) => {
  return [
    {
      value: formatNumbersWithSymbol(data?.portMv || 0, 1, '$'),
      pmv: formatCurrencyWithSymbol(data?.portMv || 0, 1, '$'),
      label: 'Portfolio Market Value'
    },
    {
      value: decTwoPercentage(data?.trackingError || 0, 1) + '%',
      pmv: decTwoPercentage(data?.trackingError || 0, 1) + '%',
      label: 'Tracking Error'
    },
    {
      value: decTwoPlaces(data?.relativeVolatility || 0, 2),
      pmv: decTwoPlaces(data?.relativeVolatility || 0, 2),
      label: 'Relative Volatility'
    },
    {
      value: data?.initNumHoldings?.toString() || 0,
      pmv: data?.initNumHoldings?.toString() || 0,
      label: 'No. Of Holdings'
    },
    {
      value: formatNumbersWithSymbol(data?.cash || 0, 1, '$'),
      pmv: formatCurrencyWithSymbol(data?.cash || 0, 1, '$'),
      label: 'Available Cash'
    }
  ]
}

export const AccountTranInformation = () => {
  const summaryData = useContext(summaryDataContext)
  const content = accSummaryData(summaryData?.data?.accountData)
  return (
    <Card className='report-card'>
      <CardContent sx={{ pl: '20px', pr: '20px', pt: '15px', pb: '15px!Important' }}>
        <Grid className='report-card-border' container xs={10} sm={16} columns={16}>
          {
            content.map((item, index) => {
              return (
                <Grid key={index} xs={2} sm={index === 0 ? 4 : 3}>
                  <Stack className='stack-container' pl={3} p={2}>
                    <Tooltip title={item.value} placement='top'>
                      <Typography variant='h6' className='card-heading' sx={{ color: '#4B8CCA', fontFamily: 'Open Sans' }} mb={1}>
                        {item.pmv}
                      </Typography>
                    </Tooltip>
                    <Typography variant='subtitle1' sx={{ fontFamily: 'Open Sans' }} className='card-subheading'>
                      {item.label}
                    </Typography>
                  </Stack>
                </Grid>
              )
            })
          }
        </Grid>
      </CardContent>
    </Card>
  )
}
