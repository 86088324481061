import { getUserRole } from '../../../utils/getUserRole'
import { DeleteAPI } from '../components/Actions'

export const DeleteOptimizationData = async (deleteRow, tabIndex) => {
  switch (tabIndex) {
    case 'opt-status-master':
      return await DeleteOptimizationStatusMaster(deleteRow?.optStatusId)
    case 'opt-scenario-list':
      return await DeleteOptimizationScenarioList(deleteRow?.scenarioId)
    case 'opt-scenario-properties':
      return await DeleteOptimizationScenarioProperty(deleteRow?.optScenarioPropertiesId)
    default:
      return {}
  }
}

const DeleteOptimizationStatusMaster = async (optStatusId) => {
  const userRole = getUserRole()
  return DeleteAPI(
    'baseUriOptimizationDataMaintenance',
    `data-maintenance/v1/${userRole}/optimizations/status-master/${optStatusId}`)
}

const DeleteOptimizationScenarioList = async (scenarioId) => {
  const userRole = getUserRole()
  return DeleteAPI(
    'baseUriOptimizationDataMaintenance',
    `data-maintenance/v1/${userRole}/optimizations/scenario-list/${scenarioId}`)
}

const DeleteOptimizationScenarioProperty = async (optScenarioPropertiesId) => {
  const userRole = getUserRole()
  return DeleteAPI(
    'baseUriOptimizationDataMaintenance',
    `data-maintenance/v1/${userRole}/optimizations/${optScenarioPropertiesId}/scenario-properties`)
}
