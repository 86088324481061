import React from 'react'
import { FormikInput } from './FormikInput'
import { FormikNumber } from './FormikNumber'
import { FormikDate } from './FormikDate'
import { FormikDateTimeLocal } from './FormikDateTimeLocal'
import { FormikSelect } from './FormikSelect'
import { FormikAutocomplete } from './FormikAutocomplete'

export const FormikControl = (props) => {
  const { control, ...rest } = props
  switch (control) {
    case 'text':
      return <FormikInput {...rest} />
    case 'number':
      return <FormikNumber {...rest} />
    case 'dateTime':
      return <FormikDateTimeLocal {...rest} />
    case 'date':
      return <FormikDate {...rest} />
    case 'select':
      return <FormikSelect {...rest} />
    case 'autocomplete':
      return <FormikAutocomplete {...rest} />
    default:
      return null
  }
}
