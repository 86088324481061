import React, { useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { API } from 'aws-amplify'
import { TabContext } from '@mui/lab'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { Divider, Table, TableBody, TableHead, TableRow } from '@mui/material'
import Tab from '@mui/material/Tab'
import { randomId } from '@mui/x-data-grid-generator'
import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro'
import * as Sentry from '@sentry/react'
import { useAuth } from '../../../contexts/AuthContext'
import { useErrorToast } from '../../../hooks/useErrorToast'
import ErrorFallback from '../../ErrorFallback'
import StyledTradePopupBox from '../components/StyledTradePopupBox'
import { instrumentDataFormatter, sectorDataFormatter, industryDataFormatter, factoryDataFormatter, substitutionDataFormatter } from '../components/TradeDataFormatting'
import { propertiesTableHeaders, tableData } from '../components/PersonalizationHeaders'
import TradePersonalizationTab from '../components/TradePersonalizationTab'

const ViewOptProperties = ({ index, optId, showCompact = false }) => {
  const [approvalPropertiesLoading, setApprovalPropertiesLoading] = useState(true)
  const [otherPropertiesLoading, setOtherPropertiesLoading] = useState(true)
  const [approvalProperties, setApprovalProperties] = useState()
  const { user } = useAuth()
  const [optData, setOptData] = useState([])
  const { showError } = useErrorToast()
  const [value, setValue] = useState('0')
  const screen = 'not main'

  const viewOptPropertiesTabs =
    ['Properties', 'Sector', 'Industry', 'Factor Tilts', 'Substitutions', 'Instrument']

  const handleTabPanelChange = (event, newValue) => {
    setValue(newValue)
  }

  const getOptimizationPersonalization = async () => {
    try {
      const response = await API.get('baseOptimizationURL', `optimization/v1/${user?.userGroup}/personalization/${optId}`)
      setOptData(response?.data)
      setOtherPropertiesLoading(false)
    } catch (error) {
      setOtherPropertiesLoading(false)
      showError(error.response?.data?.errorInfo?.userMessage || error.message)
      Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
    }
  }

  const renderTabPanel = (item) => {
    switch (item) {
      case 'Properties': {
        if (approvalPropertiesLoading) {
          return (
            <>
              {/* dynamically changes skeleton for all diff personalization tabs */}
              <Table className='security-holdings-table'>
                <TableHead>
                  <TableRow>
                    {tableData[item]?.tableHeaderHTML}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {tableData[item]?.rowSkeleton}
                  </TableRow>
                  <TableRow>
                    {tableData[item]?.rowSkeleton}
                  </TableRow>
                  <TableRow>
                    {tableData[item]?.rowSkeleton}
                  </TableRow>
                </TableBody>
              </Table>
            </>
          )
        } else {
          return (
            <ErrorBoundary fallbackRender={(props) => (<ErrorFallback {...props} screen={screen} />)}>
              <DataGridPro
                autoHeight
                rows={approvalProperties}
                columns={propertiesTableHeaders}
                loading={approvalPropertiesLoading}
                getRowId={getRowId}
                pagination
                pageSizeOptions={[10]}
                {...(showCompact ? {
                  rowHeight: 30,
                  columnHeaderHeight: 39,
                } : { density: 'compact' })}
                sx={
                  showCompact ? {
                    [`.${gridClasses.cell}, .${gridClasses.cellContent}, .${gridClasses.columnHeaderTitle}, .${gridClasses.columnHeaders}`]: {
                      fontSize: '12px'
                    },
                  } : {}}
                initialState={{
                  ...approvalProperties?.initialState,
                  pagination: { paginationModel: { pageSize: 10 } }
                }}
              />
            </ErrorBoundary>
          )
        }
      }
      // for all other tabs call TradePersonalizationTab to get data or loading screen(skeleton)
      case 'Sector': {
        const sector = sectorDataFormatter(optData, 'SECTOR_RESTRICTIONS')
        return (
          <TradePersonalizationTab
            loading={otherPropertiesLoading}
            header='Sector'
            tableData={tableData}
            item={item}
            type={sector}
            screen={screen}
            rowData={sector?.sectorData}
            showCompact={showCompact}
          />
        )
      }
      case 'Industry': {
        const industry = industryDataFormatter(optData, 'INDUSTRY_RESTRICTIONS')
        return (
          <TradePersonalizationTab
            loading={otherPropertiesLoading}
            header='Industry'
            tableData={tableData}
            item={item}
            type={industry}
            screen={screen}
            rowData={industry?.industryData}
            showCompact={showCompact}
          />
        )
      }
      case 'Factor Tilts': {
        const factorTilts = factoryDataFormatter(optData, 'FACTOR_TILTS')
        return (
          <TradePersonalizationTab
            loading={otherPropertiesLoading}
            header='Factor Tilts'
            tableData={tableData}
            item={item}
            type={factorTilts}
            screen={screen}
            rowData={factorTilts?.factoryData}
            showCompact={showCompact}
          />
        )
      }
      case 'Substitutions': {
        const substitutions = substitutionDataFormatter(optData, 'INSTRUMENT_SUBSTITUTIONS')
        return (
          <TradePersonalizationTab
            loading={otherPropertiesLoading}
            header='Substitutions'
            tableData={tableData}
            item={item}
            type={substitutions}
            screen={screen}
            rowData={substitutions?.subData}
            showCompact={showCompact}
          />
        )
      }
      case 'Instrument': {
        const instrument = instrumentDataFormatter(optData, 'INSTRUMENT_RESTRICTIONS')
        return (
          <TradePersonalizationTab
            loading={otherPropertiesLoading}
            header='Instrument'
            tableData={tableData}
            item={item}
            type={instrument}
            screen={screen}
            rowData={instrument?.instrumentData}
            showCompact={showCompact}
          />
        )
      }
      default:
        return 'Something went wrong'
    }
  }

  const getApprovalProperties = async (optMapId) => {
    API.post(
      'baseOptimizationURL',
      `optimization/v1/${user?.userGroup}/opt-properties`,
      {
        body: {
          accOptMapId: [optMapId]
        }
      }
    )
      .then((response) => {
        if (response?.data?.data && response?.data?.data?.length > 0) {
          setApprovalProperties(response?.data?.data?.map((row) => ({ ...row, id: randomId() })))
        }
      })
      .catch((error) => {
        if (Array.isArray(error.response?.data?.errorInfo?.userMessage)) {
          error.response?.data?.errorInfo?.userMessage?.forEach((element) => {
            showError(element.message)
            setApprovalPropertiesLoading(false)
          })
        } else {
          showError(error.response?.data?.errorInfo?.userMessage || error.message)
          setApprovalPropertiesLoading(false)
        }
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => {
        setApprovalPropertiesLoading(false)
      })
  }

  useEffect(() => {
    if (user) {
      getOptimizationPersonalization()
      getApprovalProperties(index)
    }
  }, [user, index])

  if (approvalProperties === null) {
    return null
  }

  const getRowId = (row) => row?.id

  return (
    <TabContext value={value}>
      <TabList
        onChange={handleTabPanelChange}
        variant='scrollable'
        scrollButtons={false}
        TabIndicatorProps={{
          style: {
            backgroundColor: '#34475A'
          }
        }}
        indicatorColor='#34475A'
      >
        {viewOptPropertiesTabs?.map((item, index) => (
          <Tab
            className='reportCardNav' key={index} value={index.toString()} label={item} disabled={item === 'Geography'} sx={{
              opacity: item === 'Geography' ? 0.5 : 1
            }}
          />
        ))}
      </TabList>
      <Divider sx={{
        bgcolor: 'rgba(116, 120, 141, 0.15)'
      }}
      />
      {viewOptPropertiesTabs?.map((item, index) => (
        <TabPanel
          key={index}
          value={index.toString()}
          sx={{ p: 0 }}
        >
          {renderTabPanel(item)}
        </TabPanel>
      ))}
    </TabContext>
  )
}
export default ViewOptProperties
