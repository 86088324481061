import React, { useState } from 'react'

const Definitions = ({ data, chartIndex, index, chartHeight, clickEvent }) => {

  return (
    <div
      className={'showInRow width-100 margin-bottom-2'}
    >
      <div className='width-50 padding-1 '>
        <p style={{ margin: '0', fontSize: '10px', fontWeight: 700 }}>
          {data?.heading1}
        </p>
        <p
          style={{
            margin: '0',
            fontSize: '6px',
            fontWeight: 400,
            lineHeight: '10px',
            wordBreak: 'break-all',
            fontFamily: 'Open Sans',
            color: '#333333'
          }}
        >
          {data?.definition1}
        </p>
      </div>
      <div className='width-50 padding-1 '>
        <p style={{ margin: '0', fontSize: '10px', fontWeight: 700 }}>
          {data?.heading2}
        </p>
        <p
          style={{
            margin: '0',
            fontSize: '6px',
            fontWeight: 400,
            lineHeight: '10px',
            wordBreak: 'break-all',
            fontFamily: 'Open Sans',
            color: '#333333'
          }}
        >
          {data?.definition2}
        </p>
      </div>
    </div>
  )
}

export default Definitions
