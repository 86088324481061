//! //////////////////////////// performance chart ////////////////////////////
export const performanceChart = {
  data: [
    {
      dataDate: '2023-08-30',
      postTaxDailyReturns: 0,
      preTaxDailyReturns: 0,
      cumulatedPreTaxDailyReturns: 0,
      cumulatedPostTaxDailyReturns: 0
    },
    {
      dataDate: '2023-08-31',
      postTaxDailyReturns: -0.0022813,
      preTaxDailyReturns: -0.0022813,
      cumulatedPreTaxDailyReturns: -0.0022813000000000416,
      cumulatedPostTaxDailyReturns: -0.0022813000000000416
    },
    {
      dataDate: '2023-09-01',
      postTaxDailyReturns: 0.0047936,
      preTaxDailyReturns: 0.0047936,
      cumulatedPreTaxDailyReturns: 0.002501364360320002,
      cumulatedPostTaxDailyReturns: 0.002501364360320002
    },
    {
      dataDate: '2023-09-05',
      postTaxDailyReturns: -0.0010877,
      preTaxDailyReturns: -0.0010877,
      cumulatedPreTaxDailyReturns: 0.0014109436263052277,
      cumulatedPostTaxDailyReturns: 0.0014109436263052277
    },
    {
      dataDate: '2023-09-06',
      postTaxDailyReturns: -0.0056691,
      preTaxDailyReturns: -0.0056691,
      cumulatedPreTaxDailyReturns: -0.004266155154206697,
      cumulatedPostTaxDailyReturns: -0.004266155154206697
    },
    {
      dataDate: '2023-09-07',
      postTaxDailyReturns: -0.0038012,
      preTaxDailyReturns: -0.0038012,
      cumulatedPreTaxDailyReturns: -0.008051138645234435,
      cumulatedPostTaxDailyReturns: -0.008051138645234435
    },
    {
      dataDate: '2023-09-08',
      postTaxDailyReturns: 0.0044993,
      preTaxDailyReturns: 0.0044993,
      cumulatedPreTaxDailyReturns: -0.003588063133340924,
      cumulatedPostTaxDailyReturns: -0.003588063133340924
    },
    {
      dataDate: '2023-09-11',
      postTaxDailyReturns: 0.00435,
      preTaxDailyReturns: 0.00435,
      cumulatedPreTaxDailyReturns: 0.0007463287920290718,
      cumulatedPostTaxDailyReturns: 0.0007463287920290718
    },
    {
      dataDate: '2023-09-12',
      postTaxDailyReturns: -0.0015843,
      preTaxDailyReturns: -0.0015843,
      cumulatedPreTaxDailyReturns: -0.0008391536166760805,
      cumulatedPostTaxDailyReturns: -0.0008391536166760805
    },
    {
      dataDate: '2023-09-13',
      postTaxDailyReturns: 0.0008002,
      preTaxDailyReturns: 0.0008002,
      cumulatedPreTaxDailyReturns: -0.00003962510740007641,
      cumulatedPostTaxDailyReturns: -0.00003962510740007641
    },
    {
      dataDate: '2023-09-14',
      postTaxDailyReturns: 0.007656,
      preTaxDailyReturns: 0.007656,
      cumulatedPreTaxDailyReturns: 0.0076160715227777676,
      cumulatedPostTaxDailyReturns: 0.0076160715227777676
    },
    {
      dataDate: '2023-09-15',
      postTaxDailyReturns: -0.013782,
      preTaxDailyReturns: -0.013782,
      cumulatedPreTaxDailyReturns: -0.00627089317494911,
      cumulatedPostTaxDailyReturns: -0.00627089317494911
    },
    {
      dataDate: '2023-09-18',
      postTaxDailyReturns: 0.0018406,
      preTaxDailyReturns: 0.0018406,
      cumulatedPreTaxDailyReturns: -0.004441835380926906,
      cumulatedPostTaxDailyReturns: -0.004441835380926906
    },
    {
      dataDate: '2023-09-19',
      postTaxDailyReturns: -0.0028012,
      preTaxDailyReturns: -0.0028012,
      cumulatedPreTaxDailyReturns: -0.0072305929116578405,
      cumulatedPostTaxDailyReturns: -0.0072305929116578405
    },
    {
      dataDate: '2023-09-20',
      postTaxDailyReturns: -0.0106822,
      preTaxDailyReturns: -0.0106822,
      cumulatedPreTaxDailyReturns: -0.017835554272056853,
      cumulatedPostTaxDailyReturns: -0.017835554272056853
    },
    {
      dataDate: '2023-09-21',
      postTaxDailyReturns: -0.0160016,
      preTaxDailyReturns: -0.0160016,
      cumulatedPreTaxDailyReturns: -0.033551756866817106,
      cumulatedPostTaxDailyReturns: -0.033551756866817106
    },
    {
      dataDate: '2023-09-22',
      postTaxDailyReturns: -0.0006654,
      preTaxDailyReturns: -0.0006654,
      cumulatedPreTaxDailyReturns: -0.03419483152779801,
      cumulatedPostTaxDailyReturns: -0.03419483152779801
    },
    {
      dataDate: '2023-09-25',
      postTaxDailyReturns: 0.0049111,
      preTaxDailyReturns: 0.0049111,
      cumulatedPreTaxDailyReturns: -0.029451665764914203,
      cumulatedPostTaxDailyReturns: -0.029451665764914203
    },
    {
      dataDate: '2023-09-26',
      postTaxDailyReturns: -0.0139085,
      preTaxDailyReturns: -0.0139085,
      cumulatedPreTaxDailyReturns: -0.0429505372716229,
      cumulatedPostTaxDailyReturns: -0.0429505372716229
    },
    {
      dataDate: '2023-09-27',
      postTaxDailyReturns: 0.0054038,
      preTaxDailyReturns: 0.0054038,
      cumulatedPreTaxDailyReturns: -0.037778833384931265,
      cumulatedPostTaxDailyReturns: -0.037778833384931265
    },
    {
      dataDate: '2023-09-28',
      postTaxDailyReturns: 0.0061212,
      preTaxDailyReturns: 0.0061212,
      cumulatedPreTaxDailyReturns: -0.031888885179847115,
      cumulatedPostTaxDailyReturns: -0.031888885179847115
    },
    {
      dataDate: '2023-09-29',
      postTaxDailyReturns: -0.0043631,
      preTaxDailyReturns: -0.0043631,
      cumulatedPreTaxDailyReturns: -0.03611285078491888,
      cumulatedPostTaxDailyReturns: -0.03611285078491888
    },
    {
      dataDate: '2023-10-02',
      postTaxDailyReturns: -0.0026826,
      preTaxDailyReturns: -0.0026826,
      cumulatedPreTaxDailyReturns: -0.038698574451403234,
      cumulatedPostTaxDailyReturns: -0.038698574451403234
    },
    {
      dataDate: '2023-10-03',
      postTaxDailyReturns: -0.0133523,
      preTaxDailyReturns: -0.0133523,
      cumulatedPreTaxDailyReturns: -0.05153415947575579,
      cumulatedPostTaxDailyReturns: -0.05153415947575579
    },
    {
      dataDate: '2023-10-04',
      postTaxDailyReturns: 0.0039165,
      preTaxDailyReturns: 0.0039165,
      cumulatedPreTaxDailyReturns: -0.04781949301134247,
      cumulatedPostTaxDailyReturns: -0.04781949301134247
    },
    {
      dataDate: '2023-10-05',
      postTaxDailyReturns: -0.0027674,
      preTaxDailyReturns: -0.0027674,
      cumulatedPreTaxDailyReturns: -0.05045455734638282,
      cumulatedPostTaxDailyReturns: -0.05045455734638282
    },
    {
      dataDate: '2023-10-06',
      postTaxDailyReturns: 0.011048,
      preTaxDailyReturns: 0.011048,
      cumulatedPreTaxDailyReturns: -0.03996397929594575,
      cumulatedPostTaxDailyReturns: -0.03996397929594575
    },
    {
      dataDate: '2023-10-09',
      postTaxDailyReturns: 0.0104538,
      preTaxDailyReturns: 0.0104538,
      cumulatedPreTaxDailyReturns: -0.029927954742709595,
      cumulatedPostTaxDailyReturns: -0.029927954742709595
    },
    {
      dataDate: '2023-10-10',
      postTaxDailyReturns: 0.0053174,
      preTaxDailyReturns: 0.0053174,
      cumulatedPreTaxDailyReturns: -0.024769693649258406,
      cumulatedPostTaxDailyReturns: -0.024769693649258406
    },
    {
      dataDate: '2023-10-11',
      postTaxDailyReturns: 0.0014364,
      preTaxDailyReturns: 0.0014364,
      cumulatedPreTaxDailyReturns: -0.023368872837216226,
      cumulatedPostTaxDailyReturns: -0.023368872837216226
    },
    {
      dataDate: '2023-10-12',
      postTaxDailyReturns: -0.0049523,
      preTaxDailyReturns: -0.0049523,
      cumulatedPreTaxDailyReturns: -0.028205443168264455,
      cumulatedPostTaxDailyReturns: -0.028205443168264455
    },
    {
      dataDate: '2023-10-13',
      postTaxDailyReturns: -0.0006149,
      preTaxDailyReturns: -0.0006149,
      cumulatedPreTaxDailyReturns: -0.02880299964126032,
      cumulatedPostTaxDailyReturns: -0.02880299964126032
    },
    {
      dataDate: '2023-10-16',
      postTaxDailyReturns: 0.0101763,
      preTaxDailyReturns: 0.0101763,
      cumulatedPreTaxDailyReturns: -0.018919807606509798,
      cumulatedPostTaxDailyReturns: -0.018919807606509798
    },
    {
      dataDate: '2023-10-17',
      postTaxDailyReturns: 0.0008266,
      preTaxDailyReturns: 0.0008266,
      cumulatedPreTaxDailyReturns: -0.018108846719477434,
      cumulatedPostTaxDailyReturns: -0.018108846719477434
    },
    {
      dataDate: '2023-10-18',
      postTaxDailyReturns: -0.0090393,
      preTaxDailyReturns: -0.0090393,
      cumulatedPreTaxDailyReturns: -0.026984455421325992,
      cumulatedPostTaxDailyReturns: -0.026984455421325992
    },
    {
      dataDate: '2023-10-19',
      postTaxDailyReturns: -0.0058966,
      preTaxDailyReturns: -0.0058966,
      cumulatedPreTaxDailyReturns: -0.032721938881488666,
      cumulatedPostTaxDailyReturns: -0.032721938881488666
    },
    {
      dataDate: '2023-10-20',
      postTaxDailyReturns: -0.0140716,
      preTaxDailyReturns: -0.0140716,
      cumulatedPreTaxDailyReturns: -0.04633308884632392,
      cumulatedPostTaxDailyReturns: -0.04633308884632392
    },
    {
      dataDate: '2023-10-23',
      postTaxDailyReturns: -0.0017954,
      preTaxDailyReturns: -0.0017954,
      cumulatedPreTaxDailyReturns: -0.04804530241860927,
      cumulatedPostTaxDailyReturns: -0.04804530241860927
    },
    {
      dataDate: '2023-10-24',
      postTaxDailyReturns: 0.0049615,
      preTaxDailyReturns: 0.0049615,
      cumulatedPreTaxDailyReturns: -0.04332217918655912,
      cumulatedPostTaxDailyReturns: -0.04332217918655912
    },
    {
      dataDate: '2023-10-25',
      postTaxDailyReturns: -0.0153276,
      preTaxDailyReturns: -0.0153276,
      cumulatedPreTaxDailyReturns: -0.057985754152859226,
      cumulatedPostTaxDailyReturns: -0.057985754152859226
    },
    {
      dataDate: '2023-10-26',
      postTaxDailyReturns: -0.0121045,
      preTaxDailyReturns: -0.0121045,
      cumulatedPreTaxDailyReturns: -0.0693883655917159,
      cumulatedPostTaxDailyReturns: -0.0693883655917159
    },
    {
      dataDate: '2023-10-27',
      postTaxDailyReturns: -0.0040553,
      preTaxDailyReturns: -0.0040553,
      cumulatedPreTaxDailyReturns: -0.07316227495273175,
      cumulatedPostTaxDailyReturns: -0.07316227495273175
    },
    {
      dataDate: '2023-10-30',
      postTaxDailyReturns: 0.0093165,
      preTaxDailyReturns: 0.0093165,
      cumulatedPreTaxDailyReturns: -0.06452739128732887,
      cumulatedPostTaxDailyReturns: -0.06452739128732887
    },
    {
      dataDate: '2023-10-31',
      postTaxDailyReturns: 0.0075102,
      preTaxDailyReturns: 0.0075102,
      cumulatedPreTaxDailyReturns: -0.05750180490137491,
      cumulatedPostTaxDailyReturns: -0.05750180490137491
    },
    {
      dataDate: '2023-11-01',
      postTaxDailyReturns: 0.008649,
      preTaxDailyReturns: 0.008649,
      cumulatedPreTaxDailyReturns: -0.04935013801196697,
      cumulatedPostTaxDailyReturns: -0.04935013801196697
    },
    {
      dataDate: '2023-11-02',
      postTaxDailyReturns: 0.0209536,
      preTaxDailyReturns: 0.0209536,
      cumulatedPreTaxDailyReturns: -0.029430601063814565,
      cumulatedPostTaxDailyReturns: -0.029430601063814565
    },
    {
      dataDate: '2023-11-03',
      postTaxDailyReturns: 0.0049734,
      preTaxDailyReturns: 0.0049734,
      cumulatedPreTaxDailyReturns: -0.024603571215145448,
      cumulatedPostTaxDailyReturns: -0.024603571215145448
    },
    {
      dataDate: '2023-11-06',
      postTaxDailyReturns: -0.0001964,
      preTaxDailyReturns: -0.0001964,
      cumulatedPreTaxDailyReturns: -0.024795139073758743,
      cumulatedPostTaxDailyReturns: -0.024795139073758743
    },
    {
      dataDate: '2023-11-07',
      postTaxDailyReturns: 0.0013749,
      preTaxDailyReturns: 0.0013749,
      cumulatedPreTaxDailyReturns: -0.02345432991047114,
      cumulatedPostTaxDailyReturns: -0.02345432991047114
    },
    {
      dataDate: '2023-11-08',
      postTaxDailyReturns: -0.0006413,
      preTaxDailyReturns: -0.0006413,
      cumulatedPreTaxDailyReturns: -0.024080588648699552,
      cumulatedPostTaxDailyReturns: -0.024080588648699552
    },
    {
      dataDate: '2023-11-09',
      postTaxDailyReturns: -0.0071561,
      preTaxDailyReturns: -0.0071561,
      cumulatedPreTaxDailyReturns: -0.031064365548270634,
      cumulatedPostTaxDailyReturns: -0.031064365548270634
    },
    {
      dataDate: '2023-11-10',
      postTaxDailyReturns: 0.0163939,
      preTaxDailyReturns: 0.0163939,
      cumulatedPreTaxDailyReturns: -0.015179731650632466,
      cumulatedPostTaxDailyReturns: -0.015179731650632466
    },
    {
      dataDate: '2023-11-13',
      postTaxDailyReturns: 0.0011462,
      preTaxDailyReturns: 0.0011462,
      cumulatedPreTaxDailyReturns: -0.014050930659050387,
      cumulatedPostTaxDailyReturns: -0.014050930659050387
    },
    {
      dataDate: '2023-11-14',
      postTaxDailyReturns: 0.0177018,
      preTaxDailyReturns: 0.0177018,
      cumulatedPreTaxDailyReturns: 0.0034021425766093127,
      cumulatedPostTaxDailyReturns: 0.0034021425766093127
    },
    {
      dataDate: '2023-11-15',
      postTaxDailyReturns: -0.0005555,
      preTaxDailyReturns: -0.0005555,
      cumulatedPreTaxDailyReturns: 0.0028447526864079364,
      cumulatedPostTaxDailyReturns: 0.0028447526864079364
    },
    {
      dataDate: '2023-11-16',
      postTaxDailyReturns: -0.000809,
      preTaxDailyReturns: -0.000809,
      cumulatedPreTaxDailyReturns: 0.0020334512814845773,
      cumulatedPostTaxDailyReturns: 0.0020334512814845773
    },
    {
      dataDate: '2023-11-17',
      postTaxDailyReturns: 0.0032293,
      preTaxDailyReturns: 0.0032293,
      cumulatedPreTaxDailyReturns: 0.005269317905707949,
      cumulatedPostTaxDailyReturns: 0.005269317905707949
    },
    {
      dataDate: '2023-11-20',
      postTaxDailyReturns: 0.0062666,
      preTaxDailyReturns: 0.0062666,
      cumulatedPreTaxDailyReturns: 0.011568938613295776,
      cumulatedPostTaxDailyReturns: 0.011568938613295776
    },
    {
      dataDate: '2023-11-21',
      postTaxDailyReturns: -0.003185,
      preTaxDailyReturns: -0.003185,
      cumulatedPreTaxDailyReturns: 0.008347091543812413,
      cumulatedPostTaxDailyReturns: 0.008347091543812413
    },
    {
      dataDate: '2023-11-22',
      postTaxDailyReturns: 0.0034556,
      preTaxDailyReturns: 0.0034556,
      cumulatedPreTaxDailyReturns: 0.011831535753351163,
      cumulatedPostTaxDailyReturns: 0.011831535753351163
    },
    {
      dataDate: '2023-11-27',
      postTaxDailyReturns: -0.0004394,
      preTaxDailyReturns: -0.0004394,
      cumulatedPreTaxDailyReturns: 0.01138693697654114,
      cumulatedPostTaxDailyReturns: 0.01138693697654114
    },
    {
      dataDate: '2023-11-28',
      postTaxDailyReturns: 0.0016565,
      preTaxDailyReturns: 0.0016565,
      cumulatedPreTaxDailyReturns: 0.01306229943764281,
      cumulatedPostTaxDailyReturns: 0.01306229943764281
    },
    {
      dataDate: '2023-11-29',
      postTaxDailyReturns: -0.0003602,
      preTaxDailyReturns: -0.0003602,
      cumulatedPreTaxDailyReturns: 0.012697394397385375,
      cumulatedPostTaxDailyReturns: 0.012697394397385375
    },
    {
      dataDate: '2023-11-30',
      postTaxDailyReturns: 0.0022111,
      preTaxDailyReturns: 0.0022111,
      cumulatedPreTaxDailyReturns: 0.014936569606137429,
      cumulatedPostTaxDailyReturns: 0.014936569606137429
    },
    {
      dataDate: '2023-12-01',
      postTaxDailyReturns: 0.003889,
      preTaxDailyReturns: 0.003889,
      cumulatedPreTaxDailyReturns: 0.01888365792533575,
      cumulatedPostTaxDailyReturns: 0.01888365792533575
    },
    {
      dataDate: '2023-12-04',
      postTaxDailyReturns: -0.0071783,
      preTaxDailyReturns: -0.0071783,
      cumulatedPreTaxDailyReturns: 0.011569805363650376,
      cumulatedPostTaxDailyReturns: 0.011569805363650376
    },
    {
      dataDate: '2023-12-05',
      postTaxDailyReturns: -0.0020118,
      preTaxDailyReturns: -0.0020118,
      cumulatedPreTaxDailyReturns: 0.00953472922921983,
      cumulatedPostTaxDailyReturns: 0.00953472922921983
    },
    {
      dataDate: '2023-12-06',
      postTaxDailyReturns: -0.0064457,
      preTaxDailyReturns: -0.0065033,
      cumulatedPreTaxDailyReturns: 0.0029694220246234693,
      cumulatedPostTaxDailyReturns: 0.003027571225026948
    },
    {
      dataDate: '2023-12-07',
      postTaxDailyReturns: 0.0070107,
      preTaxDailyReturns: 0.0070107,
      cumulatedPreTaxDailyReturns: 0.010000939751611426,
      cumulatedPostTaxDailyReturns: 0.010059496618614272
    },
    {
      dataDate: '2023-12-08',
      postTaxDailyReturns: 0.0051237,
      preTaxDailyReturns: 0.0051237,
      cumulatedPreTaxDailyReturns: 0.015175881566616756,
      cumulatedPostTaxDailyReturns: 0.015234738461439079
    },
    {
      dataDate: '2023-12-11',
      postTaxDailyReturns: 0.0069324,
      preTaxDailyReturns: 0.0069324,
      cumulatedPreTaxDailyReturns: 0.022213486847989072,
      cumulatedPostTaxDailyReturns: 0.023272751762349108
    },
    {
      dataDate: '2023-12-12',
      postTaxDailyReturns: 0.0044097,
      preTaxDailyReturns: 0.0044097,
      cumulatedPreTaxDailyReturns: 0.02672114166094275,
      cumulatedPostTaxDailyReturns: 0.056780667915795586
    },
    {
      dataDate: '2023-12-13',
      postTaxDailyReturns: 0.0136214,
      preTaxDailyReturns: 0.0136214,
      cumulatedPreTaxDailyReturns: 0.04070652101996308,
      cumulatedPostTaxDailyReturns: 0.08076685810574365
    },
    {
      dataDate: '2023-12-14',
      postTaxDailyReturns: 0.0033942,
      preTaxDailyReturns: 0.0033942,
      cumulatedPreTaxDailyReturns: 0.044238887093609014,
      cumulatedPostTaxDailyReturns: 0.08429942897552608
    },
    {
      dataDate: '2023-12-15',
      postTaxDailyReturns: 0.0011994,
      preTaxDailyReturns: 0.0011994,
      cumulatedPreTaxDailyReturns: 0.04549134721478909,
      cumulatedPostTaxDailyReturns: 0.045551961710639244
    },
    {
      dataDate: '2023-12-18',
      postTaxDailyReturns: 0.0050928,
      preTaxDailyReturns: 0.0050928,
      cumulatedPreTaxDailyReturns: 0.05081582554788455,
      cumulatedPostTaxDailyReturns: 0.05087674874123915
    },
    {
      dataDate: '2023-12-19',
      postTaxDailyReturns: 0.0058509,
      preTaxDailyReturns: 0.0058509,
      cumulatedPreTaxDailyReturns: 0.0569640438615826,
      cumulatedPostTaxDailyReturns: 0.0570253235104492
    },
    {
      dataDate: '2023-12-20',
      postTaxDailyReturns: -0.0133851,
      preTaxDailyReturns: -0.0133851,
      cumulatedPreTaxDailyReturns: 0.042816474438091,
      cumulatedPostTaxDailyReturns: 0.042876933852729504
    },
    {
      dataDate: '2023-12-21',
      postTaxDailyReturns: 0.009573,
      preTaxDailyReturns: 0.009573,
      cumulatedPreTaxDailyReturns: 0.05279935654788681,
      cumulatedPostTaxDailyReturns: 0.05286039474050175
    },
    {
      dataDate: '2023-12-22',
      postTaxDailyReturns: -0.0000633,
      preTaxDailyReturns: -0.0000633,
      cumulatedPreTaxDailyReturns: 0.05273271434861737,
      cumulatedPostTaxDailyReturns: 0.05279374867751474
    },
    {
      dataDate: '2023-12-26',
      postTaxDailyReturns: 0.0043706,
      preTaxDailyReturns: 0.0043706,
      cumulatedPreTaxDailyReturns: 0.057333787949949366,
      cumulatedPostTaxDailyReturns: 0.057395089035484625
    },
    {
      dataDate: '2023-12-27',
      postTaxDailyReturns: 0.0000329,
      preTaxDailyReturns: 0.0000329,
      cumulatedPreTaxDailyReturns: 0.05736857423157282,
      cumulatedPostTaxDailyReturns: 0.05742987733391369
    },
    {
      dataDate: '2023-12-28',
      postTaxDailyReturns: -0.001091,
      preTaxDailyReturns: -0.001091,
      cumulatedPreTaxDailyReturns: 0.056214985117086336,
      cumulatedPostTaxDailyReturns: 0.05627622133774235
    },
    {
      dataDate: '2023-12-29',
      postTaxDailyReturns: -0.0030054,
      preTaxDailyReturns: -0.0030054,
      cumulatedPreTaxDailyReturns: 0.05304063660081537,
      cumulatedPostTaxDailyReturns: 0.05310168878213384
    },
    {
      dataDate: '2024-01-02',
      postTaxDailyReturns: -0.0084573,
      preTaxDailyReturns: -0.0084573,
      cumulatedPreTaxDailyReturns: 0.04413475602489125,
      cumulatedPostTaxDailyReturns: 0.0441952918695967
    },
    {
      dataDate: '2024-01-03',
      postTaxDailyReturns: -0.0075341,
      preTaxDailyReturns: -0.0075341,
      cumulatedPreTaxDailyReturns: 0.03626814035952419,
      cumulatedPostTaxDailyReturns: 0.03632822012112191
    },
    {
      dataDate: '2024-01-04',
      postTaxDailyReturns: -0.005544,
      preTaxDailyReturns: -0.005544,
      cumulatedPreTaxDailyReturns: 0.030523069789371027,
      cumulatedPostTaxDailyReturns: 0.03058281646877048
    },
    {
      dataDate: '2024-01-05',
      postTaxDailyReturns: 0.0008717,
      preTaxDailyReturns: 0.0008717,
      cumulatedPreTaxDailyReturns: 0.03142137674930656,
      cumulatedPostTaxDailyReturns: 0.03148117550988627
    },
    {
      dataDate: '2024-01-08',
      postTaxDailyReturns: 0.0109589,
      preTaxDailyReturns: 0.0109589,
      cumulatedPreTaxDailyReturns: 0.042724620474964725,
      cumulatedPostTaxDailyReturns: 0.04278507456418157
    },
    {
      dataDate: '2024-01-09',
      postTaxDailyReturns: -0.002083,
      preTaxDailyReturns: -0.002083,
      cumulatedPreTaxDailyReturns: 0.04055262509051549,
      cumulatedPostTaxDailyReturns: 0.04061295325386438
    },
    {
      dataDate: '2024-01-10',
      postTaxDailyReturns: 0.0043149,
      preTaxDailyReturns: 0.0043149,
      cumulatedPreTaxDailyReturns: 0.045042505612518546,
      cumulatedPostTaxDailyReturns: 0.04510309408585944
    },
    {
      dataDate: '2024-01-11',
      postTaxDailyReturns: 0.000032,
      preTaxDailyReturns: 0.000032,
      cumulatedPreTaxDailyReturns: 0.04507594697269823,
      cumulatedPostTaxDailyReturns: 0.08513653738487022
    },
    {
      dataDate: '2024-01-12',
      postTaxDailyReturns: 0.0022808,
      preTaxDailyReturns: 0.0022808,
      cumulatedPreTaxDailyReturns: 0.04745955619255371,
      cumulatedPostTaxDailyReturns: 0.067520284799337675
    },
    {
      dataDate: '2024-01-16',
      postTaxDailyReturns: -0.0048446,
      preTaxDailyReturns: -0.0048446,
      cumulatedPreTaxDailyReturns: 0.04238503362662338,
      cumulatedPostTaxDailyReturns: 0.04244546802759874
    },
    {
      dataDate: '2024-01-17',
      postTaxDailyReturns: -0.0060428,
      preTaxDailyReturns: -0.0060428,
      cumulatedPreTaxDailyReturns: 0.03608610934542433,
      cumulatedPostTaxDailyReturns: 0.03614617855340163
    },
    {
      dataDate: '2024-01-18',
      postTaxDailyReturns: 0.0089015,
      preTaxDailyReturns: 0.0089015,
      cumulatedPreTaxDailyReturns: 0.04530882984776241,
      cumulatedPostTaxDailyReturns: 0.0453694337617947
    },
    {
      dataDate: '2024-01-19',
      postTaxDailyReturns: 0.0138894,
      preTaxDailyReturns: 0.0138894,
      cumulatedPreTaxDailyReturns: 0.059827542309049964,
      cumulatedPostTaxDailyReturns: 0.059888987975085906
    },
    {
      dataDate: '2024-01-22',
      postTaxDailyReturns: 0.0029737,
      preTaxDailyReturns: 0.0029737,
      cumulatedPreTaxDailyReturns: 0.06297915147161448,
      cumulatedPostTaxDailyReturns: 0.06304077985862744
    },
    {
      dataDate: '2024-01-23',
      postTaxDailyReturns: 0.0039715,
      preTaxDailyReturns: 0.0039715,
      cumulatedPreTaxDailyReturns: 0.06720077317168394,
      cumulatedPostTaxDailyReturns: 0.0672626463158359
    },
    {
      dataDate: '2024-01-24',
      postTaxDailyReturns: 0.0014994,
      preTaxDailyReturns: 0.0014994,
      cumulatedPreTaxDailyReturns: 0.06880093401097742,
      cumulatedPostTaxDailyReturns: 0.06886289992772188
    },
    {
      dataDate: '2024-01-25',
      postTaxDailyReturns: 0.0057539,
      preTaxDailyReturns: 0.0057539,
      cumulatedPreTaxDailyReturns: 0.0749507077051832,
      cumulatedPostTaxDailyReturns: 0.07501303016761596
    },
    {
      dataDate: '2024-01-26',
      postTaxDailyReturns: -0.0008512,
      preTaxDailyReturns: -0.0008512,
      cumulatedPreTaxDailyReturns: 0.07403570966278439,
      cumulatedPostTaxDailyReturns: 0.0740979790763372
    },
    {
      dataDate: '2024-01-31',
      postTaxDailyReturns: 0,
      preTaxDailyReturns: 0,
      cumulatedPreTaxDailyReturns: 0.07403570966278439,
      cumulatedPostTaxDailyReturns: 0.0740979790763372
    },
    {
      dataDate: '2024-02-01',
      postTaxDailyReturns: 0.005991,
      preTaxDailyReturns: 0.005991,
      cumulatedPreTaxDailyReturns: 0.08047025759937432,
      cumulatedPostTaxDailyReturns: 0.09053290006898361
    },
    {
      dataDate: '2024-02-02',
      postTaxDailyReturns: -0.0076226,
      preTaxDailyReturns: -0.0076226,
      cumulatedPreTaxDailyReturns: 0.07223426501379726,
      cumulatedPostTaxDailyReturns: 0.09229642998491781
    },
    {
      dataDate: '2024-02-05',
      postTaxDailyReturns: -0.0047724,
      preTaxDailyReturns: -0.0047724,
      cumulatedPreTaxDailyReturns: 0.06711713420744547,
      cumulatedPostTaxDailyReturns: 0.07717900250245767
    },
    {
      dataDate: '2024-02-06',
      postTaxDailyReturns: 0.0027141,
      preTaxDailyReturns: 0.0027141,
      cumulatedPreTaxDailyReturns: 0.07001339682139784,
      cumulatedPostTaxDailyReturns: 0.07007543303314945
    },
    {
      dataDate: '2024-02-07',
      postTaxDailyReturns: 0.0077956,
      preTaxDailyReturns: 0.0077956,
      cumulatedPreTaxDailyReturns: 0.0783547932576587,
      cumulatedPostTaxDailyReturns: 0.07841731307890254
    },
    {
      dataDate: '2024-02-08',
      postTaxDailyReturns: -0.0104211,
      preTaxDailyReturns: -0.0104211,
      cumulatedPreTaxDailyReturns: 0.06711715012164143,
      cumulatedPostTaxDailyReturns: 0.06717901841757601
    }
  ]
}
//! //////////////////////////// marketCap chart ////////////////////////////
export const marketCapChart = {
  data: {
    fieldLabels: {
      portfolio: 'Portfolio',
      strategy: 'CRSP US Large Cap Index',
      scenario1: 'Model'
    },
    marketCapAllocationData: [
      {
        marketCapName: 'Micro',
        portfolio: 'NA',
        strategy: 'NA',
        scenario1: 'NA'
      },
      {
        marketCapName: 'Small',
        portfolio: 'NA',
        strategy: 0.0001,
        scenario1: 'NA'
      },
      {
        marketCapName: 'Mid',
        portfolio: 'NA',
        strategy: 0.0024,
        scenario1: 'NA'
      },
      {
        marketCapName: 'Large',
        portfolio: 'NA',
        strategy: 0.4768,
        scenario1: 0.3843
      },
      {
        marketCapName: 'Mega',
        portfolio: 'NA',
        strategy: 0.5208,
        scenario1: 0.6152
      }
    ]
  }
}

//! //////////////////////////// factor chart ////////////////////////////
export const factorChart = {
  data: {
    fieldLabels: {
      portfolio: 'Portfolio',
      strategy: 'CRSP US Small/Mid Cap Value Index'
    },
    factorAllocationData: [
      {
        factorName: 'Dividend Yield',
        portfolio: -0.0178,
        strategy: 0.3573
      },
      {
        factorName: 'Momentum',
        portfolio: -0.0995,
        strategy: -0.3041
      },
      {
        factorName: 'Profitability',
        portfolio: -0.4702,
        strategy: -0.2597
      },
      {
        factorName: 'Size',
        portfolio: -0.6798,
        strategy: -0.3708
      },
      {
        factorName: 'Value',
        portfolio: 0.3879,
        strategy: 0.4282
      },
      {
        factorName: 'Growth',
        portfolio: -0.0544,
        strategy: -0.2997
      },
      {
        factorName: 'Volatility',
        portfolio: 0.5062,
        strategy: 0.0366
      }
    ]
  }
}
//! //////////////////////////// security-by-weight ////////////////////////////
export const securityByWeight = {
  data: {
    overWeight: [
      {
        instrId: 'AAPL-XNGS',
        name: 'Apple Inc',
        localSymbol: 'AAPL',
        weightDrift: 0.350500758523,
        weight: 0.4225685
      },
      {
        instrId: '__CASH',
        name: 'CASH PGM FOR USD',
        localSymbol: '__USD',
        weightDrift: 0.2434571,
        weight: 0.2434571
      },
      {
        instrId: 'AEE-XNYS',
        name: 'Ameren Corp.',
        localSymbol: 'AEE',
        weightDrift: 0.21978221269099998,
        weight: 0.2202556
      }
    ],
    underweight: [
      {
        instrId: 'MSFT-XNGS',
        name: 'Microsoft Corporation',
        localSymbol: 'MSFT',
        weightDrift: -0.068961089004,
        weight: -0.068961089004
      },
      {
        instrId: 'AMZN-XNGS',
        name: 'Amazon.com Inc.',
        localSymbol: 'AMZN',
        weightDrift: -0.035420345899,
        weight: -0.035420345899
      },
      {
        instrId: 'NVDA-XNGS',
        name: 'NVIDIA Corp',
        localSymbol: 'NVDA',
        weightDrift: -0.028263059429,
        weight: -0.028263059429
      }
    ]
  }
}
//! //////////////////////////// sectorWise chart ////////////////////////////
export const sectorWiseChart = {
  success: true,
  message: 'Successfully fetched sectorWise chart data for the account',
  data: {
    fieldLabels: {
      portfolio: 'Portfolio',
      scenario1: 'Min Tax',
      scenario2: 'Balanced',
      scenario3: 'Model',
      strategy: 'CRSP US Total Market Index'
    },
    sectorWiseAllocationData: [
      {
        assetClass: 'EDSH',
        assetClassDesc: 'Healthcare',
        portfolio: 0.1302,
        scenario1: 0.1072,
        scenario2: 0.1257,
        scenario3: 0.1279,
        strategy: 0.1287
      },
      {
        assetClass: 'EDSF',
        assetClassDesc: 'Financials',
        portfolio: 0.1014,
        scenario1: 0.1401,
        scenario2: 0.1393,
        scenario3: 0.1339,
        strategy: 0.1324
      },
      {
        assetClass: 'EDSN',
        assetClassDesc: 'Energy',
        portfolio: 0.0342,
        scenario1: 0.0546,
        scenario2: 0.0508,
        scenario3: 0.0482,
        strategy: 0.0463
      },
      {
        assetClass: 'EDSR',
        assetClassDesc: 'Real Estate',
        portfolio: 0,
        scenario1: 0.0437,
        scenario2: 0.037,
        scenario3: 0.0349,
        strategy: 0.0286
      },
      {
        assetClass: 'EDST',
        assetClassDesc: 'Technology',
        portfolio: 0.3916,
        scenario1: 0.3308,
        scenario2: 0.2576,
        scenario3: 0.2616,
        strategy: 0.2672
      },
      {
        assetClass: 'EDSU',
        assetClassDesc: 'Utilities',
        portfolio: 0,
        scenario1: 0.0124,
        scenario2: 0.017,
        scenario3: 0.0201,
        strategy: 0.0245
      },
      {
        assetClass: 'EDSO',
        assetClassDesc: 'Consumer Cyclical',
        portfolio: 0.1173,
        scenario1: 0.0896,
        scenario2: 0.107,
        scenario3: 0.1055,
        strategy: 0.1069
      },
      {
        assetClass: 'EDSS',
        assetClassDesc: 'Consumer Non-Cyclical',
        portfolio: 0.0684,
        scenario1: 0.0552,
        scenario2: 0.0577,
        scenario3: 0.0519,
        strategy: 0.0609
      },
      {
        assetClass: 'EDSI',
        assetClassDesc: 'Industrials',
        portfolio: 0.0061,
        scenario1: 0.0409,
        scenario2: 0.0911,
        scenario3: 0.0993,
        strategy: 0.098
      },
      {
        assetClass: 'EDSM',
        assetClassDesc: 'Basic Materials',
        portfolio: 0.0092,
        scenario1: 0.0284,
        scenario2: 0.0332,
        scenario3: 0.0266,
        strategy: 0.0261
      },
      {
        assetClass: 'EDSA',
        assetClassDesc: 'Communication Services',
        portfolio: 0.1315,
        scenario1: 0.0867,
        scenario2: 0.0733,
        scenario3: 0.0799,
        strategy: 0.0804
      },
      {
        assetClass: 'CASH_',
        assetClassDesc: 'Cash',
        portfolio: 0.01,
        scenario1: 0.0103,
        scenario2: 0.0103,
        scenario3: 0.0103,
        strategy: 0
      }
    ]
  }
}
//! //////////////////////////// summary data ////////////////////////////
export const SummaryTable = {
  data: {
    data: {
      accountData: {
        optimizationRunDate: '2024-01-30T00:00:00.000Z',
        relativeVolatility: '0.001587629299365724',
        portMv: 10000,
        trackingError: 0.1494544,
        initNumHoldings: 0,
        cash: 10000
      },
      optimizationData: [
        {
          "scenarioId": 0,
          "scenarioDesc": "No Trade",
          "scenarioCode": "No Trade",
          "te": 0.0916755,
          "risk": 0.1416048,
          "modelBeta": 0.7817372,
          "numHoldings": 346,
          "numSell": 0,
          "numBuy": 0,
          "ltUrg": 775814.5063605,
          "stUrg": 0,
          "ltUrl": -29964.2713235,
          "stUrl": 0,
          "ltRg": 0,
          "stRg": 0,
          "ltRl": 0,
          "stRl": 0,
          "taxCost": 0
        },
        {
          "scenarioId": 1,
          "scenarioDesc": "Min Tax",
          "scenarioCode": "Min Tax",
          "te": 0.0840542,
          "risk": 0.1400444,
          "modelBeta": 0.8035022,
          "numHoldings": 278,
          "numSell": 72,
          "numBuy": 5,
          "ltUrg": 774422.4563599,
          "stUrg": 0,
          "ltUrl": 0,
          "stUrl": 0,
          "ltRg": 1392.0500006000511,
          "stRg": 0,
          "ltRl": 29964.2713235,
          "stRl": 0,
          "taxCost": -1428.6110661
        },
        {
          "scenarioId": 2,
          "scenarioDesc": "Balanced",
          "scenarioCode": "Balanced",
          "te": 0.0055663,
          "risk": 0.1437819,
          "modelBeta": 0.9993073,
          "numHoldings": 249,
          "numSell": 345,
          "numBuy": 249,
          "ltUrg": 0,
          "stUrg": 0,
          "ltUrl": 0,
          "stUrl": 0,
          "ltRg": 7814.5063605,
          "stRg": 0,
          "ltRl": 2964.2713235,
          "stRl": 0,
          "taxCost": 37292.5117519
        },
        {
          "scenarioId": 3,
          "scenarioDesc": "Model Repl",
          "scenarioCode": "Model",
          "te": 0.0213077,
          "risk": 0.1435665,
          "modelBeta": 0.987578,
          "numHoldings": 82,
          "numSell": 345,
          "numBuy": 82,
          "ltUrg": 0,
          "stUrg": 0,
          "ltUrl": 0,
          "stUrl": 0,
          "ltRg": 77814.5063605,
          "stRg": 0,
          "ltRl": 2964.2713235,
          "stRl": 0,
          "taxCost": 37292.5117519
        },
        {
          "scenarioId": 4,
          "scenarioDesc": "Liquidation",
          "scenarioCode": "Liquidation",
          "te": 0,
          "risk": "0.14438128982482742",
          "modelBeta": 1,
          "numHoldings": 959,
          "numSell": 346,
          "numBuy": 959,
          "ltUrg": 0,
          "stUrg": 0,
          "ltUrl": 0,
          "stUrl": 0,
          "ltRg": 5814.5063605,
          "stRg": 0,
          "ltRl": 2994.2713235,
          "stRl": 0,
          "taxCost": 37292.5117519
        }
      ]
    }
  }
}
//! //////////////////////////// tax-savings-data ////////////////////////////
export const taSavingsData = {
  action: 'Get Cache for tax-savings-data',
  data: {
    accountCd: 'ARIME1491',
    accountName: 'meetH',
    runDate: '2024-02-12T08:45:25.000Z',
    shortTermTaxRate: 1,
    longTermTaxRate: 2,
    taxSavedBasedOnOverWeightedSecurities: 15.372221835803419,
    taxSavedBasedOnSortTermToLongTermConvertedHoldings: 0,
    taxSavedBasedOnYTDGainLossRecognized: -316.525684,
    totalTaxSaved: -301.1534621641966,
    totalTaxSavedInPercentage: -0.02991463483216309,
    shortTermTax: 316.525684,
    longTermTax: 0,
    ytdEstimatedTax: 316.525684,
    ytdEstimatedTaxWithoutTaxSaving: 15.37222183580343,
    externalLongGainLoss: 0,
    externalShortGainLoss: 0,
    totalExternalGainLoss: 0
  },
  key: 'tax-savings-data',
  expiry: -1
}

export const accountData = {
  data: {
    accountId: '4660666c-4c9a-469c-874a-06c680cd6d7e',
    accountName: 'John Doe',
    accountCd: 'ARIS9369',
    accountType: 'TRADING',
    sponsorId: '158208ed-bb9c-4e84-907d-8b851eb30324',
    sponsorName: 'Callan Family Office'
  }
}

//! //////////////////////////// tax-management-data ////////////////////////////
export const taxManagementData = {
  action: 'Get Cache for tax-management-data',
  data: {
    performanceData: {
      "preTaxMV": {
        "1M": 0.04629871993006751,
        "3M": 0.1326379999144114,
        "1Y": null,
        "3Y": null,
        "YTD": 0.24184151301462498
      },
      "postTaxMV": {
        "1M": 0.04609481947225813,
        "3M": 0.1321556401527615,
        "1Y": null,
        "3Y": null,
        "YTD": 0.2404468448193935
      }
    }
  }
}

//! //////////////////////////// allocation ////////////////////////////
export const AllocationChart = {
  data: [
    {
      assetClass: 'ENGY',
      name: 'Energy',
      difference: 0.030314705749039324,
      targetPortfolioWeight: 0.041557000000000004,
      currentPortfolioWeight: 0.07187170574903932
    },
    {
      assetClass: 'MTRL',
      name: 'Materials',
      difference: -0.04185866845939823,
      targetPortfolioWeight: 0.08197899999999998,
      currentPortfolioWeight: 0.04012033154060178
    },
    {
      assetClass: 'INDS',
      name: 'Industrials',
      difference: -0.024435915372517776,
      targetPortfolioWeight: 0.18951100000000007,
      currentPortfolioWeight: 0.16507508462748224
    },
    {
      assetClass: 'CDIS',
      name: 'Consumer Discretionary',
      difference: 0.013427867716068295,
      targetPortfolioWeight: 0.09085280000000001,
      currentPortfolioWeight: 0.10428066771606831
    },
    {
      assetClass: 'CSTP',
      name: 'Consumer Staples',
      difference: -0.020657261754128894,
      targetPortfolioWeight: 0.0493523,
      currentPortfolioWeight: 0.02869503824587111
    },
    {
      assetClass: 'HLTH',
      name: 'Health Care',
      difference: 0.06790903452416312,
      targetPortfolioWeight: 0.08243010000000002,
      currentPortfolioWeight: 0.15033913452416311
    },
    {
      assetClass: 'FINC',
      name: 'Financials',
      difference: -0.03046140991027086,
      targetPortfolioWeight: 0.2043724,
      currentPortfolioWeight: 0.1739109900897292
    },
    {
      assetClass: 'TECH',
      name: 'Information Technology',
      difference: 0.08383722184122921,
      targetPortfolioWeight: 0.06201440000000001,
      currentPortfolioWeight: 0.14585162184122927
    },
    {
      assetClass: 'COMM',
      name: 'Communication Services',
      difference: -0.009268977495707917,
      targetPortfolioWeight: 0.024309700000000004,
      currentPortfolioWeight: 0.015040722504292083
    },
    {
      assetClass: 'UTIL',
      name: 'Utilities',
      difference: -0.05646904768048675,
      targetPortfolioWeight: 0.08362800000000001,
      currentPortfolioWeight: 0.02715895231951325
    },
    {
      assetClass: 'REAL',
      name: 'Real Estate',
      difference: -0.025743622644515775,
      targetPortfolioWeight: 0.08999389999999999,
      currentPortfolioWeight: 0.06425027735548425
    },
    {
      assetClass: 'UNCG',
      name: 'Uncategorised',
      difference: 0.013405473486526156,
      targetPortfolioWeight: 0,
      currentPortfolioWeight: 0.013405473486526156
    }
  ]
}
//! //////////////////////////// PerformanceTable ////////////////////////////
export const PerformanceTable = {
  success: true,
  message: 'Successfully fetched data',
  data: {
    accountCd: 'ARISA1880',
    accountName: 'Sarah Davis',
    runDate: '2023-11-06T05:36:04.000Z',
    shortTermTaxRate: 40,
    longTermTaxRate: 20,
    taxSavedBasedOnOverWeightedSecurities: 15943.10894229638,
    taxSavedBasedOnSortTermToLongTermConvertedHoldings: 11068.69057206883,
    taxSavedBasedOnYTDGainLossRecognized: 0,
    totalTaxSaved: 27011.799514365208,
    totalTaxSavedInPercentage: 2.4383139528549944,
    shortTermTax: 0,
    longTermTax: 0,
    ytdEstimatedTax: 0,
    ytdEstimatedTaxWithoutTaxSaving: 27011.799514365208,
    externalLongGainLoss: 3000,
    externalShortGainLoss: 5000,
    totalExternalGainLoss: 8000,
    companyName: 'Aris Investing',
    companyLogoUrl:
      'https://aris-public-assets-dev.s3.us-east-2.amazonaws.com/ARIS+Logo/aris-investing-logo.svg'
  }
}
//! ////////////////////////////sectorTable ////////////////////////////
export const sectorTable = {
  data: {
    fieldLabels: {
      assetClassDesc: 'Sector',
      relative: 'Relative',
      sectorExclude: 'Exclude',
      min: 'Min',
      max: 'Max'
    },
    data: [
      {
        max: '2.00',
        min: '1.00',
        relative: 'NO',
        sectorExclude: 'NO',
        assetClassDesc: 'Industrials'
      },
      {
        max: '2.00',
        min: '1.00',
        relative: 'NO',
        sectorExclude: 'NO',
        assetClassDesc: 'Materials'
      }
    ]
  }
}
//! ////////////////////////////policyConstraints ////////////////////////////
export const policyConstraints = {
  action: 'Get Cache for policy-constraints',
  data: {
    sectorDataLabel: {
      data: [
        {
          max: '0.08',
          min: '0.06',
          relative: 'NO',
          sectorExclude: 'NO',
          assetClassDesc: 'Energy'
        },
        {
          max: null,
          min: null,
          relative: 'NO',
          sectorExclude: 'YES',
          assetClassDesc: 'Industrials'
        },
        {
          max: '0.04',
          min: '0.02',
          relative: 'NO',
          sectorExclude: 'NO',
          assetClassDesc: 'Financials'
        }
      ],
      fieldLabels: {
        assetClassDesc: 'Sector',
        relative: 'Relative',
        sectorExclude: 'Exclude',
        min: 'Min',
        max: 'Max'
      }
    },
    industryDataLabel: {
      data: [
        {
          max: null,
          min: null,
          relative: 'NO',
          sectorId: 'EDSI',
          industryId: 'Industrial Conglomerates',
          assetClassDesc: 'Industrials',
          industryExclude: 'YES'
        },
        {
          max: null,
          min: null,
          relative: 'NO',
          sectorId: 'EDSI',
          industryId: 'Electrical Equipment',
          assetClassDesc: 'Industrials',
          industryExclude: 'YES'
        },
        {
          max: null,
          min: null,
          relative: 'NO',
          sectorId: 'EDSI',
          industryId: 'Construction & Engineering',
          assetClassDesc: 'Industrials',
          industryExclude: 'YES'
        },
        {
          max: null,
          min: null,
          relative: 'NO',
          sectorId: 'EDSI',
          industryId: 'Commercial Services & Supplies',
          assetClassDesc: 'Industrials',
          industryExclude: 'YES'
        }
      ],
      fieldLabels: {
        assetClassDesc: 'Sector',
        industryId: 'Industry',
        relative: 'Relative',
        sectorId: 'Sector Id',
        industryExclude: 'Exclude',
        min: 'Min',
        max: 'Max'
      }
    },
    restrictionDataLabel: {
      data: [
        {
          name: 'CK HUTCHISON HOLDINGS LTD',
          source: 'ACCOUNT',
          instrId: '1-XHKG',
          restrictionId: 2,
          restrictionCode: 'DNS',
          restrictionDesc: 'Do Not Sell'
        },
        {
          name: 'Janus Capital Management LLC - Janus Henderson AAA CLO ETF',
          source: 'ACCOUNT',
          instrId: 'JAAA-ARCX',
          restrictionId: 2,
          restrictionCode: 'DNS',
          restrictionDesc: 'Do Not Sell'
        },
        {
          name: 'Yamazaki Baking Co Ltd - ADR',
          source: 'ACCOUNT',
          instrId: 'YMZBY-OTCM',
          restrictionId: 8,
          restrictionCode: 'DNT',
          restrictionDesc: 'Do Not Trade'
        }
      ],
      fieldLabels: {
        name: 'Security',
        source: 'Source',
        instrId: 'Instrument Id',
        restrictionId: 'Restriction Id',
        restrictionCode: 'Restriction Code',
        restrictionDesc: 'Restriction Description'
      }
    },
    accountFactorDataLabel: {
      data: [
        {
          attrLabel: 'Growth',
          statsValue: 'B+0.15',
          statsLevelValue: 'Growth'
        },
        {
          attrLabel: 'Momentum',
          statsValue: 'B+0.15',
          statsLevelValue: 'MediumTerm Momentum'
        }
      ],
      fieldLabels: {
        statsLevelValue: 'Code',
        attrLabel: 'Name',
        statsValue: 'Value'
      }
    },
    substitutionDataLabel: {
      data: [
        {
          sourceName: 'Advance Auto Parts Inc',
          targetName:
            'GraniteShares ETF Trust - GraniteShares 1.75x Long AAPL Daily ETF',
          sourceInstrId: 'AAP-XNYS',
          targetInstrId: 'AAPB-XNMS'
        },
        {
          sourceName: 'PGIM ETF Trust - PGIM AAA CLO ETF',
          targetName:
            'BlackRock Institutional Trust Company N.A. - iShares Aaa - A Rated Corporate Bond ETF',
          sourceInstrId: 'PAAA-ARCX',
          targetInstrId: 'QLTA-ARCX'
        },
        {
          sourceName:
            'Investment Managers Series Trust II - AXS First Priority CLO Bond ETF',
          targetName: 'Aareal Bank AG - ADR',
          sourceInstrId: 'AAA-ARCX',
          targetInstrId: 'AAALY-OTCM'
        },
        {
          sourceName: 'Toppan Holdings Inc.',
          targetName: 'Hauppauge Digital, Inc.',
          sourceInstrId: 'TONPF-OTCM',
          targetInstrId: 'HAUP-OTCM'
        }
      ],
      fieldLabels: {
        sourceName: 'Source Instrument',
        targetName: 'Target Instrument',
        sourceInstrId: 'Source Instrument Id',
        targetInstrId: 'Target Instrument Id'
      }
    },
    constraints: {
      data: [
        {
          properties: [
            {
              propertyCode: 'IS_TAXABLE',
              propertyValue: 'NA',
              propertySource: 'ACCOUNTPARAM',
              propertyScenario: 'Min Tax',
              propertyDescription: 'Is Taxable'
            },
            {
              propertyCode: 'MAX_CASH_LEVEL',
              propertyValue: '12.00%',
              propertySource: 'ACCOUNT',
              propertyScenario: 'Min Tax',
              propertyDescription: 'Maximum Cash Level'
            },
            {
              propertyCode: 'MAX_HOLDINGS',
              propertyValue: '0',
              propertySource: 'SPONSOR',
              propertyScenario: 'Min Tax',
              propertyDescription: 'Max holdings'
            },
            {
              propertyCode: 'MAX_LONG_TERM_GAIN',
              propertyValue: '$0.00',
              propertySource: 'ACCOUNTPARAM',
              propertyScenario: 'Min Tax',
              propertyDescription: 'Maximum long term gain'
            },
            {
              propertyCode: 'MAX_SHORT_TERM_GAIN',
              propertyValue: '$0.00',
              propertySource: 'ACCOUNTPARAM',
              propertyScenario: 'Min Tax',
              propertyDescription: 'Maximum short term gain'
            },
            {
              propertyCode: 'MAX_TURNOVER_YTD',
              propertyValue: '0',
              propertySource: 'SPONSOR',
              propertyScenario: 'Min Tax',
              propertyDescription: 'YTD turnover limit'
            },
            {
              propertyCode: 'MIN_CASH_LEVEL',
              propertyValue: '1.00%',
              propertySource: 'ACCOUNT',
              propertyScenario: 'Min Tax',
              propertyDescription: 'Minimum Cash Level'
            },
            {
              propertyCode: 'MIN_TRD_SIZE',
              propertyValue: '15',
              propertySource: 'SPONSOR',
              propertyScenario: 'Min Tax',
              propertyDescription: 'Min trade size'
            },
            {
              propertyCode: 'MINCASH',
              propertyValue: '$0.04',
              propertySource: 'SCENARIO',
              propertyScenario: 'Min Tax',
              propertyDescription: 'Minimum Cash Amount'
            },
            {
              propertyCode: 'PCT_CASH_CUSHION',
              propertyValue: '0',
              propertySource: 'ACCOUNTPARAM',
              propertyScenario: 'Min Tax',
              propertyDescription: 'Percentage Cash Cushion'
            },
            {
              propertyCode: 'RISK_MODEL_VERSION_ID',
              propertyValue: 'WW4AxiomaMH4.0',
              propertySource: 'STRATEGY',
              propertyScenario: 'Min Tax',
              propertyDescription: 'Risk model version Id'
            },
            {
              propertyCode: 'SELL_LOGIC',
              propertyValue: 'FIFO',
              propertySource: 'ACCOUNTPARAM',
              propertyScenario: 'Min Tax',
              propertyDescription: 'Sell Logic ID'
            },
            {
              propertyCode: 'TRIVIAL_TAX_DLR',
              propertyValue: '0',
              propertySource: 'SPONSOR',
              propertyScenario: 'Min Tax',
              propertyDescription:
                'Trivial tax benefit (in dollars) for a single asset'
            },
            {
              propertyCode: 'TRIVIAL_TAX_PCT',
              propertyValue: '0',
              propertySource: 'SPONSOR',
              propertyScenario: 'Min Tax',
              propertyDescription:
                'Trivial tax benefit (in percentage) for a single asset'
            },
            {
              propertyCode: 'TXN_COST_PER_TRD',
              propertyValue: '0',
              propertySource: 'SPONSOR',
              propertyScenario: 'Min Tax',
              propertyDescription: 'Transaction cost'
            }
          ]
        },
        {
          properties: [
            {
              propertyCode: 'IS_TAXABLE',
              propertyValue: 'NA',
              propertySource: 'ACCOUNTPARAM',
              propertyScenario: 'Model',
              propertyDescription: 'Is Taxable'
            },
            {
              propertyCode: 'MAX_CASH_LEVEL',
              propertyValue: '12.00%',
              propertySource: 'ACCOUNT',
              propertyScenario: 'Model',
              propertyDescription: 'Maximum Cash Level'
            },
            {
              propertyCode: 'MAX_HOLDINGS',
              propertyValue: '0',
              propertySource: 'SPONSOR',
              propertyScenario: 'Model',
              propertyDescription: 'Max holdings'
            },
            {
              propertyCode: 'MAX_LONG_TERM_GAIN',
              propertyValue: '$0.00',
              propertySource: 'ACCOUNTPARAM',
              propertyScenario: 'Model',
              propertyDescription: 'Maximum long term gain'
            },
            {
              propertyCode: 'MAX_SHORT_TERM_GAIN',
              propertyValue: '$0.00',
              propertySource: 'ACCOUNTPARAM',
              propertyScenario: 'Model',
              propertyDescription: 'Maximum short term gain'
            },
            {
              propertyCode: 'MAX_TURNOVER_YTD',
              propertyValue: '0',
              propertySource: 'SPONSOR',
              propertyScenario: 'Model',
              propertyDescription: 'YTD turnover limit'
            },
            {
              propertyCode: 'MIN_CASH_LEVEL',
              propertyValue: '1.00%',
              propertySource: 'ACCOUNT',
              propertyScenario: 'Model',
              propertyDescription: 'Minimum Cash Level'
            },
            {
              propertyCode: 'MIN_TRD_SIZE',
              propertyValue: '15',
              propertySource: 'SPONSOR',
              propertyScenario: 'Model',
              propertyDescription: 'Min trade size'
            },
            {
              propertyCode: 'MINCASH',
              propertyValue: '$0.42',
              propertySource: 'SCENARIO',
              propertyScenario: 'Model',
              propertyDescription: 'Minimum Cash Amount'
            },
            {
              propertyCode: 'PCT_CASH_CUSHION',
              propertyValue: '0',
              propertySource: 'ACCOUNTPARAM',
              propertyScenario: 'Model',
              propertyDescription: 'Percentage Cash Cushion'
            },
            {
              propertyCode: 'RISK_MODEL_VERSION_ID',
              propertyValue: 'WW4AxiomaMH4.0',
              propertySource: 'STRATEGY',
              propertyScenario: 'Model',
              propertyDescription: 'Risk model version Id'
            },
            {
              propertyCode: 'SELL_LOGIC',
              propertyValue: 'FIFO',
              propertySource: 'ACCOUNTPARAM',
              propertyScenario: 'Model',
              propertyDescription: 'Sell Logic ID'
            },
            {
              propertyCode: 'TRIVIAL_TAX_DLR',
              propertyValue: '0',
              propertySource: 'SPONSOR',
              propertyScenario: 'Model',
              propertyDescription:
                'Trivial tax benefit (in dollars) for a single asset'
            },
            {
              propertyCode: 'TRIVIAL_TAX_PCT',
              propertyValue: '0',
              propertySource: 'SPONSOR',
              propertyScenario: 'Model',
              propertyDescription:
                'Trivial tax benefit (in percentage) for a single asset'
            },
            {
              propertyCode: 'TXN_COST_PER_TRD',
              propertyValue: '0',
              propertySource: 'SPONSOR',
              propertyScenario: 'Model',
              propertyDescription: 'Transaction cost'
            }
          ]
        },
        {
          properties: [
            {
              propertyCode: 'IS_TAXABLE',
              propertyValue: 'NA',
              propertySource: 'ACCOUNTPARAM',
              propertyScenario: 'Balanced',
              propertyDescription: 'Is Taxable'
            },
            {
              propertyCode: 'MAX_CASH_LEVEL',
              propertyValue: '12.00%',
              propertySource: 'ACCOUNT',
              propertyScenario: 'Balanced',
              propertyDescription: 'Maximum Cash Level'
            },
            {
              propertyCode: 'MAX_HOLDINGS',
              propertyValue: '0',
              propertySource: 'SPONSOR',
              propertyScenario: 'Balanced',
              propertyDescription: 'Max holdings'
            },
            {
              propertyCode: 'MAX_LONG_TERM_GAIN',
              propertyValue: '$0.00',
              propertySource: 'ACCOUNTPARAM',
              propertyScenario: 'Balanced',
              propertyDescription: 'Maximum long term gain'
            },
            {
              propertyCode: 'MAX_SHORT_TERM_GAIN',
              propertyValue: '$0.00',
              propertySource: 'ACCOUNTPARAM',
              propertyScenario: 'Balanced',
              propertyDescription: 'Maximum short term gain'
            },
            {
              propertyCode: 'MAX_TURNOVER_YTD',
              propertyValue: '0',
              propertySource: 'SPONSOR',
              propertyScenario: 'Balanced',
              propertyDescription: 'YTD turnover limit'
            },
            {
              propertyCode: 'MIN_CASH_LEVEL',
              propertyValue: '1.00%',
              propertySource: 'ACCOUNT',
              propertyScenario: 'Balanced',
              propertyDescription: 'Minimum Cash Level'
            },
            {
              propertyCode: 'MIN_TRD_SIZE',
              propertyValue: '15',
              propertySource: 'SPONSOR',
              propertyScenario: 'Balanced',
              propertyDescription: 'Min trade size'
            },
            {
              propertyCode: 'MINCASH',
              propertyValue: '$0.00',
              propertySource: 'N/A',
              propertyDescription: 'N/A'
            },
            {
              propertyCode: 'PCT_CASH_CUSHION',
              propertyValue: '0',
              propertySource: 'ACCOUNTPARAM',
              propertyScenario: 'Balanced',
              propertyDescription: 'Percentage Cash Cushion'
            },
            {
              propertyCode: 'RISK_MODEL_VERSION_ID',
              propertyValue: 'WW4AxiomaMH4.0',
              propertySource: 'STRATEGY',
              propertyScenario: 'Balanced',
              propertyDescription: 'Risk model version Id'
            },
            {
              propertyCode: 'SELL_LOGIC',
              propertyValue: 'FIFO',
              propertySource: 'ACCOUNTPARAM',
              propertyScenario: 'Balanced',
              propertyDescription: 'Sell Logic ID'
            },
            {
              propertyCode: 'TRIVIAL_TAX_DLR',
              propertyValue: '0',
              propertySource: 'SPONSOR',
              propertyScenario: 'Balanced',
              propertyDescription:
                'Trivial tax benefit (in dollars) for a single asset'
            },
            {
              propertyCode: 'TRIVIAL_TAX_PCT',
              propertyValue: '0',
              propertySource: 'SPONSOR',
              propertyScenario: 'Balanced',
              propertyDescription:
                'Trivial tax benefit (in percentage) for a single asset'
            },
            {
              propertyCode: 'TXN_COST_PER_TRD',
              propertyValue: '0',
              propertySource: 'SPONSOR',
              propertyScenario: 'Balanced',
              propertyDescription: 'Transaction cost'
            }
          ]
        }
      ],
      fieldLabels: {
        propertyCode: 'Property Code',
        propertyValue: 'Property Value',
        propertySource: 'Property Source',
        propertyScenario: 'Property Scenario',
        propertyDescription: 'Property Description'
      }
    }
  },
  key: 'policy-constraints',
  expiry: -1
}

//! ////////////////////////////industryTable ////////////////////////////
export const industryTable = {
  data: {
    data: [
      {
        max: '2.00',
        min: '1.00',
        relative: 'NO',
        sectorId: 'MTRL',
        assetClassDesc: 'Materials',
        industryExclude: 'NO'
      }
    ],
    fieldLabels: {
      assetClassDesc: 'Sector',
      industryId: 'Industry',
      relative: 'Relative',
      sectorId: 'Sector Id',
      industryExclude: 'Exclude',
      min: 'Min',
      max: 'Max'
    }
  }
}

//! ////////////////////////////securityTable ////////////////////////////
export const securityTable = {
  data: {
    data: [
      {
        name: 'GraniteShares ETF Trust - GraniteShares 2x Long AAPL Daily ETF',
        source: 'ACCOUNT',
        instrId: 'AAPB-XNMS',
        restrictionId: 2,
        restrictionCode: 'DNS',
        restrictionDesc: 'Do Not Sell'
      }
    ],
    fieldLabels: {
      name: 'Security',
      source: 'Source',
      instrId: 'Instrument Id',
      restrictionId: 'Restriction Id',
      restrictionCode: 'Restriction Code',
      restrictionDesc: 'Restriction Description'
    }
  }
}

//! ////////////////////////////substitutionTable ////////////////////////////
export const substitutionTable = {
  data: {
    data: [
      {
        sourceName: 'Aehr Test Systems',
        targetName: 'ADVANTEST CORP',
        sourceInstrId: 'AEHR-XNCM',
        targetInstrId: '6857-XJPX'
      }
    ],
    fieldLabels: {
      sourceName: 'Source Instrument',
      targetName: 'Target Instrument',
      sourceInstrId: 'Source Instrument Id',
      targetInstrId: 'Target Instrument Id'
    }
  }
}

//! ////////////////////////////factorTiltsTable ////////////////////////////
export const factorTiltsTable = {
  data: {
    data: [{
      attrLabel: 'Momentum',
      statsValue: '1.00',
      statsLevelValue: 'MediumTerm Momentum'
    }],
    fieldLabels: {
      statsLevelValue: 'Code',
      attrLabel: 'Name',
      statsValue: 'Value'
    }
  }
}

//! ////////////////////////////scenarioTable ////////////////////////////
export const scenarioTable = {
  data: [
    {
      properties: [
        {
          propertyCode: 'CHILD_STR_CD',
          propertyValue: 'AXISTXDWXNA-ADR-MCAP',
          propertySource: 'STRATEGY',
          propertyScenario: 'Model',
          propertyDescription: 'Child Strategy Code'
        },
        {
          propertyCode: 'FIXED_TXN_COST_LAMBDA',
          propertyValue: '10',
          propertySource: 'STRATEGY',
          propertyScenario: 'Model',
          propertyDescription: 'Fixed Transaction Cost Multiplier'
        },
        {
          propertyCode: 'IS_TAXABLE',
          propertyValue: 'YES',
          propertySource: 'ACCOUNTPARAM',
          propertyScenario: 'Model',
          propertyDescription: 'Is Taxable'
        },
        {
          propertyCode: 'MAX_HOLDINGS',
          propertyValue: '0',
          propertySource: 'SPONSOR',
          propertyScenario: 'Model',
          propertyDescription: 'Max Holdings'
        },
        {
          propertyCode: 'MAX_LONG_TERM_GAIN',
          propertyValue: '$0.00',
          propertySource: 'ACCOUNTPARAM',
          propertyScenario: 'Model',
          propertyDescription: 'Maximum long term gain'
        },
        {
          propertyCode: 'MAX_SHORT_TERM_GAIN',
          propertyValue: '$0.00',
          propertySource: 'ACCOUNTPARAM',
          propertyScenario: 'Model',
          propertyDescription: 'Maximum short term gain'
        },
        {
          propertyCode: 'MAX_TURNOVER_YTD',
          propertyValue: '0',
          propertySource: 'SPONSOR',
          propertyScenario: 'Model',
          propertyDescription: 'YTD turnover limit'
        },
        {
          propertyCode: 'MIN_CASH_LEVEL',
          propertyValue: '0.00%',
          propertySource: 'SPONSOR',
          propertyScenario: 'Model',
          propertyDescription: 'Minimum Cash Level'
        },
        {
          propertyCode: 'MIN_TRD_SIZE',
          propertyValue: '10',
          propertySource: 'STRATEGY',
          propertyScenario: 'Model',
          propertyDescription: 'Min Trade Weight'
        },
        {
          propertyCode: 'MINCASH',
          propertyValue: '$0.42',
          propertySource: 'SCENARIO',
          propertyScenario: 'Model',
          propertyDescription: 'Minimum Cash Amount'
        },
        {
          propertyCode: 'OPT_ENGINE_NAME',
          propertyValue: 'AXIOMA',
          propertySource: 'ACCOUNT',
          propertyScenario: 'Model',
          propertyDescription: 'OPT_ENGINE_NAME'
        },
        {
          propertyCode: 'PCT_CASH_CUSHION',
          propertyValue: '0',
          propertySource: 'ACCOUNTPARAM',
          propertyScenario: 'Model',
          propertyDescription: 'Percentage Cash Cushion'
        },
        {
          propertyCode: 'RISK_MODEL_VERSION_ID',
          propertyValue: 'WW4AxiomaMH4.0',
          propertySource: 'STRATEGY',
          propertyScenario: 'Model',
          propertyDescription: 'Risk model version Id'
        },
        {
          propertyCode: 'SELL_LOGIC',
          propertyValue: 'AUTO',
          propertySource: 'ACCOUNTPARAM',
          propertyScenario: 'Model',
          propertyDescription: 'Sell Logic ID'
        },
        {
          propertyCode: 'SHOW_OPT_REPORT',
          propertyValue: '0',
          propertySource: 'ACCOUNT',
          propertyScenario: 'Model',
          propertyDescription: 'Show Opt Report'
        },
        {
          propertyCode: 'STR_ASSET_TYPE',
          propertyValue: 'Equity',
          propertySource: 'STRATEGY',
          propertyScenario: 'Model',
          propertyDescription: 'Strategy Assect Class Type'
        },
        {
          propertyCode: 'TRIVIAL_TAX_DLR',
          propertyValue: '0',
          propertySource: 'SPONSOR',
          propertyScenario: 'Model',
          propertyDescription: 'Trivial tax benefit (in dollars) for a single asset'
        },
        {
          propertyCode: 'TRIVIAL_TAX_PCT',
          propertyValue: '0',
          propertySource: 'SPONSOR',
          propertyScenario: 'Model',
          propertyDescription: 'Trivial tax benefit (in percentage) for a single asset'
        },
        {
          propertyCode: 'TXN_COST_PER_TRD',
          propertyValue: '0',
          propertySource: 'SPONSOR',
          propertyScenario: 'Model',
          propertyDescription: 'Transaction cost'
        },
        {
          propertyCode: 'TXN_COST_PER_TRD_OTC_ADR',
          propertyValue: '6.95',
          propertySource: 'STRATEGY',
          propertyScenario: 'Model',
          propertyDescription: 'Cost for one trade of ADR'
        }
      ]
    },
    {
      properties: [
        {
          propertyCode: 'CHILD_STR_CD',
          propertyValue: 'AXISTXDWXNA-ADR-MCAP',
          propertySource: 'STRATEGY',
          propertyScenario: 'Balanced',
          propertyDescription: 'Child Strategy Code'
        },
        {
          propertyCode: 'FIXED_TXN_COST_LAMBDA',
          propertyValue: '10',
          propertySource: 'STRATEGY',
          propertyScenario: 'Balanced',
          propertyDescription: 'Fixed Transaction Cost Multiplier'
        },
        {
          propertyCode: 'IS_TAXABLE',
          propertyValue: 'YES',
          propertySource: 'ACCOUNTPARAM',
          propertyScenario: 'Balanced',
          propertyDescription: 'Is Taxable'
        },
        {
          propertyCode: 'MAX_HOLDINGS',
          propertyValue: '0',
          propertySource: 'SPONSOR',
          propertyScenario: 'Balanced',
          propertyDescription: 'Max Holdings'
        },
        {
          propertyCode: 'MAX_LONG_TERM_GAIN',
          propertyValue: '$0.00',
          propertySource: 'ACCOUNTPARAM',
          propertyScenario: 'Balanced',
          propertyDescription: 'Maximum long term gain'
        },
        {
          propertyCode: 'MAX_SHORT_TERM_GAIN',
          propertyValue: '$0.00',
          propertySource: 'ACCOUNTPARAM',
          propertyScenario: 'Balanced',
          propertyDescription: 'Maximum short term gain'
        },
        {
          propertyCode: 'MAX_TURNOVER_YTD',
          propertyValue: '0',
          propertySource: 'SPONSOR',
          propertyScenario: 'Balanced',
          propertyDescription: 'YTD turnover limit'
        },
        {
          propertyCode: 'MIN_CASH_LEVEL',
          propertyValue: '0.00%',
          propertySource: 'SPONSOR',
          propertyScenario: 'Balanced',
          propertyDescription: 'Minimum Cash Level'
        },
        {
          propertyCode: 'MIN_TRD_SIZE',
          propertyValue: '10',
          propertySource: 'STRATEGY',
          propertyScenario: 'Balanced',
          propertyDescription: 'Min Trade Weight'
        },
        {
          propertyCode: 'MINCASH',
          propertyValue: '$0.00',
          propertySource: 'N/A',
          propertyDescription: 'N/A'
        },
        {
          propertyCode: 'OPT_ENGINE_NAME',
          propertyValue: 'AXIOMA',
          propertySource: 'ACCOUNT',
          propertyScenario: 'Balanced',
          propertyDescription: 'OPT_ENGINE_NAME'
        },
        {
          propertyCode: 'PCT_CASH_CUSHION',
          propertyValue: '0',
          propertySource: 'ACCOUNTPARAM',
          propertyScenario: 'Balanced',
          propertyDescription: 'Percentage Cash Cushion'
        },
        {
          propertyCode: 'RISK_MODEL_VERSION_ID',
          propertyValue: 'WW4AxiomaMH4.0',
          propertySource: 'STRATEGY',
          propertyScenario: 'Balanced',
          propertyDescription: 'Risk model version Id'
        },
        {
          propertyCode: 'SELL_LOGIC',
          propertyValue: 'AUTO',
          propertySource: 'ACCOUNTPARAM',
          propertyScenario: 'Balanced',
          propertyDescription: 'Sell Logic ID'
        },
        {
          propertyCode: 'SHOW_OPT_REPORT',
          propertyValue: '0',
          propertySource: 'ACCOUNT',
          propertyScenario: 'Balanced',
          propertyDescription: 'Show Opt Report'
        },
        {
          propertyCode: 'STR_ASSET_TYPE',
          propertyValue: 'Equity',
          propertySource: 'STRATEGY',
          propertyScenario: 'Balanced',
          propertyDescription: 'Strategy Assect Class Type'
        },
        {
          propertyCode: 'TRIVIAL_TAX_DLR',
          propertyValue: '0',
          propertySource: 'SPONSOR',
          propertyScenario: 'Balanced',
          propertyDescription: 'Trivial tax benefit (in dollars) for a single asset'
        },
        {
          propertyCode: 'TRIVIAL_TAX_PCT',
          propertyValue: '0',
          propertySource: 'SPONSOR',
          propertyScenario: 'Balanced',
          propertyDescription: 'Trivial tax benefit (in percentage) for a single asset'
        },
        {
          propertyCode: 'TXN_COST_PER_TRD',
          propertyValue: '0',
          propertySource: 'SPONSOR',
          propertyScenario: 'Balanced',
          propertyDescription: 'Transaction cost'
        },
        {
          propertyCode: 'TXN_COST_PER_TRD_OTC_ADR',
          propertyValue: '6.95',
          propertySource: 'STRATEGY',
          propertyScenario: 'Balanced',
          propertyDescription: 'Cost for one trade of ADR'
        }
      ]
    },
    {
      properties: [
        {
          propertyCode: 'CHILD_STR_CD',
          propertyValue: 'AXISTXDWXNA-ADR-MCAP',
          propertySource: 'STRATEGY',
          propertyScenario: 'Min Tax',
          propertyDescription: 'Child Strategy Code'
        },
        {
          propertyCode: 'FIXED_TXN_COST_LAMBDA',
          propertyValue: '10',
          propertySource: 'STRATEGY',
          propertyScenario: 'Min Tax',
          propertyDescription: 'Fixed Transaction Cost Multiplier'
        },
        {
          propertyCode: 'IS_TAXABLE',
          propertyValue: 'YES',
          propertySource: 'ACCOUNTPARAM',
          propertyScenario: 'Min Tax',
          propertyDescription: 'Is Taxable'
        },
        {
          propertyCode: 'MAX_HOLDINGS',
          propertyValue: '0',
          propertySource: 'SPONSOR',
          propertyScenario: 'Min Tax',
          propertyDescription: 'Max Holdings'
        },
        {
          propertyCode: 'MAX_LONG_TERM_GAIN',
          propertyValue: '$0.00',
          propertySource: 'ACCOUNTPARAM',
          propertyScenario: 'Min Tax',
          propertyDescription: 'Maximum long term gain'
        },
        {
          propertyCode: 'MAX_SHORT_TERM_GAIN',
          propertyValue: '$0.00',
          propertySource: 'ACCOUNTPARAM',
          propertyScenario: 'Min Tax',
          propertyDescription: 'Maximum short term gain'
        },
        {
          propertyCode: 'MAX_TURNOVER_YTD',
          propertyValue: '0',
          propertySource: 'SPONSOR',
          propertyScenario: 'Min Tax',
          propertyDescription: 'YTD turnover limit'
        },
        {
          propertyCode: 'MIN_CASH_LEVEL',
          propertyValue: '0.00%',
          propertySource: 'SPONSOR',
          propertyScenario: 'Min Tax',
          propertyDescription: 'Minimum Cash Level'
        },
        {
          propertyCode: 'MIN_TRD_SIZE',
          propertyValue: '10',
          propertySource: 'STRATEGY',
          propertyScenario: 'Min Tax',
          propertyDescription: 'Min Trade Weight'
        },
        {
          propertyCode: 'MINCASH',
          propertyValue: '$0.04',
          propertySource: 'SCENARIO',
          propertyScenario: 'Min Tax',
          propertyDescription: 'Minimum Cash Amount'
        },
        {
          propertyCode: 'OPT_ENGINE_NAME',
          propertyValue: 'AXIOMA',
          propertySource: 'ACCOUNT',
          propertyScenario: 'Min Tax',
          propertyDescription: 'OPT_ENGINE_NAME'
        },
        {
          propertyCode: 'PCT_CASH_CUSHION',
          propertyValue: '0',
          propertySource: 'ACCOUNTPARAM',
          propertyScenario: 'Min Tax',
          propertyDescription: 'Percentage Cash Cushion'
        },
        {
          propertyCode: 'RISK_MODEL_VERSION_ID',
          propertyValue: 'WW4AxiomaMH4.0',
          propertySource: 'STRATEGY',
          propertyScenario: 'Min Tax',
          propertyDescription: 'Risk model version Id'
        },
        {
          propertyCode: 'SELL_LOGIC',
          propertyValue: 'AUTO',
          propertySource: 'ACCOUNTPARAM',
          propertyScenario: 'Min Tax',
          propertyDescription: 'Sell Logic ID'
        },
        {
          propertyCode: 'SHOW_OPT_REPORT',
          propertyValue: '0',
          propertySource: 'ACCOUNT',
          propertyScenario: 'Min Tax',
          propertyDescription: 'Show Opt Report'
        },
        {
          propertyCode: 'STR_ASSET_TYPE',
          propertyValue: 'Equity',
          propertySource: 'STRATEGY',
          propertyScenario: 'Min Tax',
          propertyDescription: 'Strategy Assect Class Type'
        },
        {
          propertyCode: 'TRIVIAL_TAX_DLR',
          propertyValue: '0',
          propertySource: 'SPONSOR',
          propertyScenario: 'Min Tax',
          propertyDescription: 'Trivial tax benefit (in dollars) for a single asset'
        },
        {
          propertyCode: 'TRIVIAL_TAX_PCT',
          propertyValue: '0',
          propertySource: 'SPONSOR',
          propertyScenario: 'Min Tax',
          propertyDescription: 'Trivial tax benefit (in percentage) for a single asset'
        },
        {
          propertyCode: 'TXN_COST_PER_TRD',
          propertyValue: '0',
          propertySource: 'SPONSOR',
          propertyScenario: 'Min Tax',
          propertyDescription: 'Transaction cost'
        },
        {
          propertyCode: 'TXN_COST_PER_TRD_OTC_ADR',
          propertyValue: '6.95',
          propertySource: 'STRATEGY',
          propertyScenario: 'Min Tax',
          propertyDescription: 'Cost for one trade of ADR'
        }
      ]
    }
  ]
}

//! //////////////////////////// top-position-security ////////////////////////////
export const topPositionSecurity = {
  data: {
    data: [
      {
        holdingDate: '2024-02-06T00:00:00.000Z',
        instrId: 'MSFT-XNGS',
        weight: 0.06663756603,
        localSymbol: 'MSFT',
        name: 'Microsoft Corporation'
      },
      {
        holdingDate: '2024-02-06T00:00:00.000Z',
        instrId: 'AAPL-XNGS',
        weight: 0.06108989545,
        localSymbol: 'AAPL',
        name: 'Apple Inc'
      },
      {
        holdingDate: '2024-02-06T00:00:00.000Z',
        instrId: 'NVDA-XNGS',
        weight: 0.036363389312,
        localSymbol: 'NVDA',
        name: 'NVIDIA Corp'
      },
      {
        holdingDate: '2024-02-06T00:00:00.000Z',
        instrId: 'AMZN-XNGS',
        weight: 0.033868795389,
        localSymbol: 'AMZN',
        name: 'Amazon.com Inc.'
      },
      {
        holdingDate: '2024-02-06T00:00:00.000Z',
        instrId: 'META-XNGS',
        weight: 0.022429658198,
        localSymbol: 'META',
        name: 'Meta Platforms Inc - Class A'
      },
      {
        holdingDate: '2024-02-06T00:00:00.000Z',
        instrId: 'GOOGL-XNGS',
        weight: 0.018846347603,
        localSymbol: 'GOOGL',
        name: 'Alphabet Inc - Class A'
      }
    ]
  }
}
//! //////////////////////////// sectorwise-risk ////////////////////////////
export const sectorWiseRisk = {
  data: {
    data: [
      {
        assetClass: 'TECH',
        assetClassDesc: 'Information Technology',
        statsValue: 0.002913999999999999,
        weight: 0.2886662064604709,
        riskColor: 'R'
      },
      {
        assetClass: 'FINC',
        assetClassDesc: 'Financials',
        statsValue: 0.00118,
        weight: 0.13103312000932252,
        riskColor: 'R'
      },
      {
        assetClass: 'CDIS',
        assetClassDesc: 'Consumer Discretionary',
        statsValue: 0.0010009999999999995,
        weight: 0.12239218916226373,
        riskColor: 'R'
      },
      {
        assetClass: 'HLTH',
        assetClassDesc: 'Health Care',
        statsValue: 0.0005599999999999998,
        weight: 0.11879695196159736,
        riskColor: 'R'
      },
      {
        assetClass: 'COMM',
        assetClassDesc: 'Communication Services',
        statsValue: 0.0006519999999999999,
        weight: 0.08910721563221244,
        riskColor: 'R'
      },
      {
        assetClass: 'INDS',
        assetClassDesc: 'Industrials',
        statsValue: 0.0008510000000000004,
        weight: 0.07747135306241859,
        riskColor: 'R'
      },
      {
        assetClass: 'CSTP',
        assetClassDesc: 'Consumer Staples',
        statsValue: 0.00027900000000000006,
        weight: 0.05749889318064174,
        riskColor: 'Y'
      },
      {
        assetClass: 'ENGY',
        assetClassDesc: 'Energy',
        statsValue: 0.000241,
        weight: 0.03462943437613638,
        riskColor: 'Y'
      },
      {
        assetClass: 'REAL',
        assetClassDesc: 'Real Estate',
        statsValue: 0.00037199999999999993,
        weight: 0.03436431792441055,
        riskColor: 'Y'
      },
      {
        assetClass: 'UTIL',
        assetClassDesc: 'Utilities',
        statsValue: 0.000026000000000000005,
        weight: 0.019505081451712387,
        riskColor: 'G'
      },
      {
        assetClass: 'MTRL',
        assetClassDesc: 'Materials',
        statsValue: -0.000019,
        weight: 0.016561623294424115,
        riskColor: 'G'
      },
      {
        assetClass: 'UNCG',
        assetClassDesc: 'Uncategorised',
        statsValue: 0,
        weight: 0.009973613484388836,
        riskColor: 'G'
      }
    ]
  }
}

//! //////////////////////////// securitywise-risk ////////////////////////////
export const securityWiseRisk = {
  data: {
    data: [
      {
        statsLevelValue: 'MSFT-XNGS',
        name: 'Microsoft Corporation',
        statsValue: -0.000012,
        localSymbol: 'MSFT',
        weight: 0.0664869,
        riskColor: 'G'
      },
      {
        statsLevelValue: 'AAPL-XNGS',
        name: 'Apple Inc',
        statsValue: -0.004818,
        localSymbol: 'AAPL',
        weight: 0.0606016,
        riskColor: 'G'
      },
      {
        statsLevelValue: 'NVDA-XNGS',
        name: 'NVIDIA Corp',
        statsValue: -0.029002,
        localSymbol: 'NVDA',
        weight: 0.0386022,
        riskColor: 'G'
      },
      {
        statsLevelValue: 'AMZN-XNGS',
        name: 'Amazon.com Inc.',
        statsValue: 0.005369,
        localSymbol: 'AMZN',
        weight: 0.0328012,
        riskColor: 'R'
      },
      {
        statsLevelValue: 'META-XNGS',
        name: 'Meta Platforms Inc - Class A',
        statsValue: 0.000004,
        localSymbol: 'META',
        weight: 0.0224566,
        riskColor: 'R'
      },
      {
        statsLevelValue: 'GOOGL-XNGS',
        name: 'Alphabet Inc - Class A',
        statsValue: 0,
        localSymbol: 'GOOGL',
        weight: 0.0181317,
        riskColor: 'R'
      },
      {
        statsLevelValue: 'GOOG-XNGS',
        name: 'Alphabet Inc - Class C',
        statsValue: -0.005005,
        localSymbol: 'GOOG',
        weight: 0.0160643,
        riskColor: 'G'
      },
      {
        statsLevelValue: 'BRK.B-XNYS',
        name: 'Berkshire Hathaway Inc. - Class B',
        statsValue: 0.000002,
        localSymbol: 'BRK/B',
        weight: 0.015986,
        riskColor: 'R'
      },
      {
        statsLevelValue: 'LLY-XNYS',
        name: 'Lilly(Eli) & Co',
        statsValue: -0.020007,
        localSymbol: 'LLY',
        weight: 0.0157854,
        riskColor: 'G'
      },
      {
        statsLevelValue: 'AVGO-XNGS',
        name: 'Broadcom Inc',
        statsValue: -0.000008,
        localSymbol: 'AVGO',
        weight: 0.0129205,
        riskColor: 'G'
      },
      {
        statsLevelValue: 'UNH-XNYS',
        name: 'Unitedhealth Group Inc',
        statsValue: -0.008901,
        localSymbol: 'UNH',
        weight: 0.0118929,
        riskColor: 'G'
      }
    ]
  }
}

//! //////////////////////////// diversification-statistics ////////////////////////////
export const diversificationStatistics = {
  data: {
    lastUpdatedTime: {
      updateTime: '2024-02-08 10:11:27'
    },
    accountRiskAttributes: [
      {
        statsCode: 'PORT_RISK_STD',
        statsValue: '0.00023171791039969266'
      },
      {
        statsCode: 'PORT_REL_VOL',
        statsValue: '0.0015921339149577837'
      },
      {
        statsCode: 'PORT_TRACKING_ERROR',
        statsValue: '0.14553939333388569'
      },
      {
        statsCode: 'AA_DRIFT',
        statsValue: '1.0000000'
      },
      {
        statsCode: 'PORT_BETA',
        statsValue: 0
      }
    ],
    strategyRiskAttributes: [
      {
        statsCode: 'PORT_RISK_STD',
        statsValue: '0.1455392088710306'
      }
    ]
  }
}

//! //////////////////////////// diversification-statistics ////////////////////////////
export const portfolioStatistics = {
  data: {
    accountParameters: [
      [
        {
          accountId: '37ce7659-3f61-4b37-82c5-bc5492be99fa',
          totalMarketValue: 10000,
          accountName: 'Test SHOW_OPT_REPORT prop',
          accountType: 'TRADING',
          portSpecName: 'CRSP US Large Cap Index',
          faId: '9263c97a-db09-43cd-aeae-46c31fb9eeca',
          faName: 'Yash Kumar Dev Advisor',
          taxSensitivity: 'LOW',
          unrealizedGainLoss: 0,
          unrealizedGainLossWeight: 0,
          ytdRealizedGainLoss: 0,
          assetAllocationDrift: 1,
          incomeDivYield: 0,
          cashLevel: 1,
          cashDrift: 1,
          cashAmount: 10000
        }
      ],
      {
        fieldCount: 0,
        affectedRows: 0,
        insertId: 0,
        info: '',
        serverStatus: 2,
        warningStatus: 0,
        changedRows: 0
      }
    ]
  }
}

//! //////////////////////////// personalisation-data ////////////////////////////
export const personalisationData = {
  action: 'Get Cache for personalisation-data',
  data: {
    data: {
      sectorData: [
        {
          instruments: 36,
          weight: 0.0361632,
          accountSectorRestrId: 'e07c1afc-2ad6-4bd3-b77f-5774ebad6ca2',
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          assetClass: 'ENGY',
          assetClassDesc: 'Energy',
          sectorExclude: 0,
          relative: 0,
          min: '1',
          max: '2'
        }
      ],
      industryData: [],
      securityData: [
        {
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          instrId: 'WOLF-XNYS',
          name: 'Wolfspeed Inc',
          restrictionId: 2,
          restrictionCode: 'DNS',
          restrictionDesc: 'Do Not Sell',
          startDate: '2024-02-09T00:00:00.000Z',
          endDate: '2024-02-14T23:59:59.000Z'
        },
        {
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          instrId: 'WMT-XNYS',
          name: 'Walmart Inc',
          restrictionId: 2,
          restrictionCode: 'DNS',
          restrictionDesc: 'Do Not Sell',
          startDate: '2024-02-09T00:00:00.000Z',
          endDate: '2024-02-14T23:59:59.000Z'
        },
        {
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          instrId: 'WM-XNYS',
          name: 'Waste Management, Inc.',
          restrictionId: 2,
          restrictionCode: 'DNS',
          restrictionDesc: 'Do Not Sell',
          startDate: '2024-02-09T00:00:00.000Z',
          endDate: '2024-02-14T23:59:59.000Z'
        },
        {
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          instrId: 'W-XNYS',
          name: 'Wayfair Inc - Class A',
          restrictionId: 2,
          restrictionCode: 'DNS',
          restrictionDesc: 'Do Not Sell',
          startDate: '2024-02-09T00:00:00.000Z',
          endDate: '2024-02-14T23:59:59.000Z'
        },
        {
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          instrId: 'UNH-XNYS',
          name: 'Unitedhealth Group Inc',
          restrictionId: 2,
          restrictionCode: 'DNS',
          restrictionDesc: 'Do Not Sell',
          startDate: '2024-02-09T00:00:00.000Z',
          endDate: '2024-02-14T23:59:59.000Z'
        },
        {
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          instrId: 'U-XNYS',
          name: 'Unity Software Inc',
          restrictionId: 2,
          restrictionCode: 'DNS',
          restrictionDesc: 'Do Not Sell',
          startDate: '2024-02-09T00:00:00.000Z',
          endDate: '2024-02-14T23:59:59.000Z'
        },
        {
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          instrId: 'TXG-XNGS',
          name: '10x Genomics Inc - Class A',
          restrictionId: 2,
          restrictionCode: 'DNS',
          restrictionDesc: 'Do Not Sell',
          startDate: '2024-02-09T00:00:00.000Z',
          endDate: '2024-02-14T23:59:59.000Z'
        },
        {
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          instrId: 'TSLA-XNGS',
          name: 'Tesla Inc',
          restrictionId: 2,
          restrictionCode: 'DNS',
          restrictionDesc: 'Do Not Sell',
          startDate: '2024-02-09T00:00:00.000Z',
          endDate: '2024-02-14T23:59:59.000Z'
        },
        {
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          instrId: 'SQ-XNYS',
          name: 'Block Inc - Class A',
          restrictionId: 2,
          restrictionCode: 'DNS',
          restrictionDesc: 'Do Not Sell',
          startDate: '2024-02-09T00:00:00.000Z',
          endDate: '2024-02-14T23:59:59.000Z'
        },
        {
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          instrId: 'SOFI-XNGS',
          name: 'SoFi Technologies Inc',
          restrictionId: 2,
          restrictionCode: 'DNS',
          restrictionDesc: 'Do Not Sell',
          startDate: '2024-02-09T00:00:00.000Z',
          endDate: '2024-02-14T23:59:59.000Z'
        },
        {
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          instrId: 'RYAN-XNYS',
          name: 'Ryan Specialty Holdings Inc Class A',
          restrictionId: 2,
          restrictionCode: 'DNS',
          restrictionDesc: 'Do Not Sell',
          startDate: '2024-02-09T00:00:00.000Z',
          endDate: '2024-02-14T23:59:59.000Z'
        },
        {
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          instrId: 'RUN-XNGS',
          name: 'Sunrun Inc',
          restrictionId: 2,
          restrictionCode: 'DNS',
          restrictionDesc: 'Do Not Sell',
          startDate: '2024-02-09T00:00:00.000Z',
          endDate: '2024-02-14T23:59:59.000Z'
        },
        {
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          instrId: 'RSG-XNYS',
          name: 'Republic Services, Inc.',
          restrictionId: 2,
          restrictionCode: 'DNS',
          restrictionDesc: 'Do Not Sell',
          startDate: '2024-02-09T00:00:00.000Z',
          endDate: '2024-02-14T23:59:59.000Z'
        },
        {
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          instrId: 'ROKU-XNGS',
          name: 'Roku Inc - Class A',
          restrictionId: 2,
          restrictionCode: 'DNS',
          restrictionDesc: 'Do Not Sell',
          startDate: '2024-02-09T00:00:00.000Z',
          endDate: '2024-02-14T23:59:59.000Z'
        },
        {
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          instrId: 'RNG-XNYS',
          name: 'RingCentral Inc. - Class A',
          restrictionId: 2,
          restrictionCode: 'DNS',
          restrictionDesc: 'Do Not Sell',
          startDate: '2024-02-09T00:00:00.000Z',
          endDate: '2024-02-14T23:59:59.000Z'
        },
        {
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          instrId: 'RKT-XNYS',
          name: 'Rocket Companies Inc Class A',
          restrictionId: 2,
          restrictionCode: 'DNS',
          restrictionDesc: 'Do Not Sell',
          startDate: '2024-02-09T00:00:00.000Z',
          endDate: '2024-02-14T23:59:59.000Z'
        },
        {
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          instrId: 'RIVN-XNGS',
          name: 'Rivian Automotive Inc - Class A',
          restrictionId: 2,
          restrictionCode: 'DNS',
          restrictionDesc: 'Do Not Sell',
          startDate: '2024-02-09T00:00:00.000Z',
          endDate: '2024-02-14T23:59:59.000Z'
        },
        {
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          instrId: 'REYN-XNGS',
          name: 'Reynolds Consumer Products Inc',
          restrictionId: 2,
          restrictionCode: 'DNS',
          restrictionDesc: 'Do Not Sell',
          startDate: '2024-02-09T00:00:00.000Z',
          endDate: '2024-02-14T23:59:59.000Z'
        },
        {
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          instrId: 'QS-XNYS',
          name: 'QuantumScape Corp - Class A',
          restrictionId: 2,
          restrictionCode: 'DNS',
          restrictionDesc: 'Do Not Sell',
          startDate: '2024-02-09T00:00:00.000Z',
          endDate: '2024-02-14T23:59:59.000Z'
        },
        {
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          instrId: 'PTON-XNGS',
          name: 'Peloton Interactive Inc - Class A',
          restrictionId: 2,
          restrictionCode: 'DNS',
          restrictionDesc: 'Do Not Sell',
          startDate: '2024-02-09T00:00:00.000Z',
          endDate: '2024-02-14T23:59:59.000Z'
        },
        {
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          instrId: 'PLUG-XNCM',
          name: 'Plug Power Inc',
          restrictionId: 2,
          restrictionCode: 'DNS',
          restrictionDesc: 'Do Not Sell',
          startDate: '2024-02-09T00:00:00.000Z',
          endDate: '2024-02-14T23:59:59.000Z'
        },
        {
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          instrId: 'PGR-XNYS',
          name: 'Progressive Corp.',
          restrictionId: 2,
          restrictionCode: 'DNS',
          restrictionDesc: 'Do Not Sell',
          startDate: '2024-02-09T00:00:00.000Z',
          endDate: '2024-02-14T23:59:59.000Z'
        },
        {
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          instrId: 'PG-XNYS',
          name: 'Procter & Gamble Co.',
          restrictionId: 2,
          restrictionCode: 'DNS',
          restrictionDesc: 'Do Not Sell',
          startDate: '2024-02-09T00:00:00.000Z',
          endDate: '2024-02-14T23:59:59.000Z'
        },
        {
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          instrId: 'NVDA-XNGS',
          name: 'NVIDIA Corp',
          restrictionId: 2,
          restrictionCode: 'DNS',
          restrictionDesc: 'Do Not Sell',
          startDate: '2024-02-09T00:00:00.000Z',
          endDate: '2024-02-14T23:59:59.000Z'
        },
        {
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          instrId: 'NET-XNYS',
          name: 'Cloudflare Inc - Class A',
          restrictionId: 2,
          restrictionCode: 'DNS',
          restrictionDesc: 'Do Not Sell',
          startDate: '2024-02-09T00:00:00.000Z',
          endDate: '2024-02-14T23:59:59.000Z'
        },
        {
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          instrId: 'MSFT-XNGS',
          name: 'Microsoft Corporation',
          restrictionId: 2,
          restrictionCode: 'DNS',
          restrictionDesc: 'Do Not Sell',
          startDate: '2024-02-09T00:00:00.000Z',
          endDate: '2024-02-14T23:59:59.000Z'
        },
        {
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          instrId: 'MRK-XNYS',
          name: 'Merck & Co Inc',
          restrictionId: 2,
          restrictionCode: 'DNS',
          restrictionDesc: 'Do Not Sell',
          startDate: '2024-02-09T00:00:00.000Z',
          endDate: '2024-02-14T23:59:59.000Z'
        },
        {
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          instrId: 'META-XNGS',
          name: 'Meta Platforms Inc - Class A',
          restrictionId: 2,
          restrictionCode: 'DNS',
          restrictionDesc: 'Do Not Sell',
          startDate: '2024-02-09T00:00:00.000Z',
          endDate: '2024-02-14T23:59:59.000Z'
        },
        {
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          instrId: 'MDB-XNMS',
          name: 'MongoDB Inc - Class A',
          restrictionId: 2,
          restrictionCode: 'DNS',
          restrictionDesc: 'Do Not Sell',
          startDate: '2024-02-09T00:00:00.000Z',
          endDate: '2024-02-14T23:59:59.000Z'
        },
        {
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          instrId: 'MCK-XNYS',
          name: 'Mckesson Corporation',
          restrictionId: 2,
          restrictionCode: 'DNS',
          restrictionDesc: 'Do Not Sell',
          startDate: '2024-02-09T00:00:00.000Z',
          endDate: '2024-02-14T23:59:59.000Z'
        },
        {
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          instrId: 'LLY-XNYS',
          name: 'Lilly(Eli) & Co',
          restrictionId: 2,
          restrictionCode: 'DNS',
          restrictionDesc: 'Do Not Sell',
          startDate: '2024-02-09T00:00:00.000Z',
          endDate: '2024-02-14T23:59:59.000Z'
        },
        {
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          instrId: 'KMB-XNYS',
          name: 'Kimberly-Clark Corp.',
          restrictionId: 2,
          restrictionCode: 'DNS',
          restrictionDesc: 'Do Not Sell',
          startDate: '2024-02-09T00:00:00.000Z',
          endDate: '2024-02-14T23:59:59.000Z'
        },
        {
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          instrId: 'JNJ-XNYS',
          name: 'Johnson & Johnson',
          restrictionId: 2,
          restrictionCode: 'DNS',
          restrictionDesc: 'Do Not Sell',
          startDate: '2024-02-09T00:00:00.000Z',
          endDate: '2024-02-14T23:59:59.000Z'
        },
        {
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          instrId: 'GTLB-XNGS',
          name: 'Gitlab Inc - Class A',
          restrictionId: 2,
          restrictionCode: 'DNS',
          restrictionDesc: 'Do Not Sell',
          startDate: '2024-02-09T00:00:00.000Z',
          endDate: '2024-02-14T23:59:59.000Z'
        },
        {
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          instrId: 'GOOGL-XNGS',
          name: 'Alphabet Inc - Class A',
          restrictionId: 2,
          restrictionCode: 'DNS',
          restrictionDesc: 'Do Not Sell',
          startDate: '2024-02-09T00:00:00.000Z',
          endDate: '2024-02-14T23:59:59.000Z'
        },
        {
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          instrId: 'GLOB-XNYS',
          name: 'Globant S.A.',
          restrictionId: 2,
          restrictionCode: 'DNS',
          restrictionDesc: 'Do Not Sell',
          startDate: '2024-02-09T00:00:00.000Z',
          endDate: '2024-02-14T23:59:59.000Z'
        },
        {
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          instrId: 'GL-XNYS',
          name: 'Globe Life Inc',
          restrictionId: 2,
          restrictionCode: 'DNS',
          restrictionDesc: 'Do Not Sell',
          startDate: '2024-02-09T00:00:00.000Z',
          endDate: '2024-02-14T23:59:59.000Z'
        },
        {
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          instrId: 'GFS-XNGS',
          name: 'GlobalFoundries Inc',
          restrictionId: 2,
          restrictionCode: 'DNS',
          restrictionDesc: 'Do Not Sell',
          startDate: '2024-02-09T00:00:00.000Z',
          endDate: '2024-02-14T23:59:59.000Z'
        },
        {
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          instrId: 'FCN-XNYS',
          name: 'FTI Consulting Inc.',
          restrictionId: 2,
          restrictionCode: 'DNS',
          restrictionDesc: 'Do Not Sell',
          startDate: '2024-02-09T00:00:00.000Z',
          endDate: '2024-02-14T23:59:59.000Z'
        },
        {
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          instrId: 'ENPH-XNMS',
          name: 'Enphase Energy Inc',
          restrictionId: 2,
          restrictionCode: 'DNS',
          restrictionDesc: 'Do Not Sell',
          startDate: '2024-02-09T00:00:00.000Z',
          endDate: '2024-02-14T23:59:59.000Z'
        },
        {
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          instrId: 'DNA-XNYS',
          name: 'Ginkgo Bioworks Holdings Inc - Class A',
          restrictionId: 2,
          restrictionCode: 'DNS',
          restrictionDesc: 'Do Not Sell',
          startDate: '2024-02-09T00:00:00.000Z',
          endDate: '2024-02-14T23:59:59.000Z'
        },
        {
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          instrId: 'DKNG-XNGS',
          name: 'DraftKings Inc. - Class A',
          restrictionId: 2,
          restrictionCode: 'DNS',
          restrictionDesc: 'Do Not Sell',
          startDate: '2024-02-09T00:00:00.000Z',
          endDate: '2024-02-14T23:59:59.000Z'
        },
        {
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          instrId: 'COIN-XNGS',
          name: 'Coinbase Global Inc - Class A',
          restrictionId: 2,
          restrictionCode: 'DNS',
          restrictionDesc: 'Do Not Sell',
          startDate: '2024-02-09T00:00:00.000Z',
          endDate: '2024-02-14T23:59:59.000Z'
        },
        {
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          instrId: 'CL-XNYS',
          name: 'Colgate-Palmolive Co.',
          restrictionId: 2,
          restrictionCode: 'DNS',
          restrictionDesc: 'Do Not Sell',
          startDate: '2024-02-09T00:00:00.000Z',
          endDate: '2024-02-14T23:59:59.000Z'
        },
        {
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          instrId: 'CHPT-XNYS',
          name: 'ChargePoint Holdings Inc - Class A',
          restrictionId: 2,
          restrictionCode: 'DNS',
          restrictionDesc: 'Do Not Sell',
          startDate: '2024-02-09T00:00:00.000Z',
          endDate: '2024-02-14T23:59:59.000Z'
        },
        {
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          instrId: 'CHE-XNYS',
          name: 'Chemed Corp.',
          restrictionId: 2,
          restrictionCode: 'DNS',
          restrictionDesc: 'Do Not Sell',
          startDate: '2024-02-09T00:00:00.000Z',
          endDate: '2024-02-14T23:59:59.000Z'
        },
        {
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          instrId: 'CB-XNYS',
          name: 'Chubb Limited',
          restrictionId: 2,
          restrictionCode: 'DNS',
          restrictionDesc: 'Do Not Sell',
          startDate: '2024-02-09T00:00:00.000Z',
          endDate: '2024-02-14T23:59:59.000Z'
        },
        {
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          instrId: 'AVGO-XNGS',
          name: 'Broadcom Inc',
          restrictionId: 2,
          restrictionCode: 'DNS',
          restrictionDesc: 'Do Not Sell',
          startDate: '2024-02-09T00:00:00.000Z',
          endDate: '2024-02-14T23:59:59.000Z'
        },
        {
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          instrId: 'AMZN-XNGS',
          name: 'Amazon.com Inc.',
          restrictionId: 2,
          restrictionCode: 'DNS',
          restrictionDesc: 'Do Not Sell',
          startDate: '2024-02-09T00:00:00.000Z',
          endDate: '2024-02-14T23:59:59.000Z'
        },
        {
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          instrId: 'AMED-XNGS',
          name: 'Amedisys Inc.',
          restrictionId: 2,
          restrictionCode: 'DNS',
          restrictionDesc: 'Do Not Sell',
          startDate: '2024-02-09T00:00:00.000Z',
          endDate: '2024-02-14T23:59:59.000Z'
        },
        {
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          instrId: 'AMD-XNCM',
          name: 'Advanced Micro Devices Inc.',
          restrictionId: 2,
          restrictionCode: 'DNS',
          restrictionDesc: 'Do Not Sell',
          startDate: '2024-02-09T00:00:00.000Z',
          endDate: '2024-02-14T23:59:59.000Z'
        },
        {
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          instrId: 'AMC-XNYS',
          name: 'AMC Entertainment Holdings Inc - Class A',
          restrictionId: 2,
          restrictionCode: 'DNS',
          restrictionDesc: 'Do Not Sell',
          startDate: '2024-02-09T00:00:00.000Z',
          endDate: '2024-02-14T23:59:59.000Z'
        },
        {
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          instrId: 'AFRM-XNGS',
          name: 'Affirm Holdings Inc - Class A',
          restrictionId: 2,
          restrictionCode: 'DNS',
          restrictionDesc: 'Do Not Sell',
          startDate: '2024-02-09T00:00:00.000Z',
          endDate: '2024-02-14T23:59:59.000Z'
        },
        {
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          instrId: 'ABC-XNYS',
          name: 'Cencora Inc.',
          restrictionId: 2,
          restrictionCode: 'DNS',
          restrictionDesc: 'Do Not Sell',
          startDate: '2024-02-09T00:00:00.000Z',
          endDate: '2024-02-14T23:59:59.000Z'
        },
        {
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          instrId: 'AAPL-XNGS',
          name: 'Apple Inc',
          restrictionId: 2,
          restrictionCode: 'DNS',
          restrictionDesc: 'Do Not Sell',
          startDate: '2024-02-09T00:00:00.000Z',
          endDate: '2024-02-14T23:59:59.000Z'
        }
      ],
      factorTiltsData: [
        {
          accountFactorTiltId: null,
          riskModelVerId: 'WW4AxiomaMH4.0',
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          statsLevelValue: 'Dividend Yield',
          attrLabel: 'Dividend Yield',
          strategyValue: '-0.041366',
          range: '-0.54 - 0.46',
          isDefault: null,
          statsValue: null,
          rn: 1
        },
        {
          accountFactorTiltId: null,
          riskModelVerId: 'WW4AxiomaMH4.0',
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          statsLevelValue: 'Growth',
          attrLabel: 'Growth',
          strategyValue: '0.022297',
          range: '-0.48 - 0.52',
          isDefault: null,
          statsValue: null,
          rn: 1
        },
        {
          accountFactorTiltId: null,
          riskModelVerId: 'WW4AxiomaMH4.0',
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          statsLevelValue: 'MediumTerm Momentum',
          attrLabel: 'Momentum',
          strategyValue: '0.130284',
          range: '-0.37 - 0.63',
          isDefault: null,
          statsValue: null,
          rn: 1
        },
        {
          accountFactorTiltId: null,
          riskModelVerId: 'WW4AxiomaMH4.0',
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          statsLevelValue: 'Profitability',
          attrLabel: 'Profitability',
          strategyValue: '0.051021',
          range: '-0.45 - 0.55',
          isDefault: null,
          statsValue: null,
          rn: 1
        },
        {
          accountFactorTiltId: null,
          riskModelVerId: 'WW4AxiomaMH4.0',
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          statsLevelValue: 'Size',
          attrLabel: 'Size',
          strategyValue: '0.260504',
          range: '-0.24 - 0.76',
          isDefault: null,
          statsValue: null,
          rn: 1
        },
        {
          accountFactorTiltId: null,
          riskModelVerId: 'WW4AxiomaMH4.0',
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          statsLevelValue: 'Value',
          attrLabel: 'Value',
          strategyValue: '-0.052079',
          range: '-0.55 - 0.45',
          isDefault: null,
          statsValue: null,
          rn: 1
        },
        {
          accountFactorTiltId: null,
          riskModelVerId: 'WW4AxiomaMH4.0',
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          statsLevelValue: 'Volatility',
          attrLabel: 'Volatility',
          strategyValue: '-0.116268',
          range: '-0.62 - 0.38',
          isDefault: null,
          statsValue: null,
          rn: 1
        }
      ],
      substitutionData: [],
      taxConstraintsData: [
        {
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          taxSensitivity: 'LOW',
          maxLongTermGain: 0,
          maxShortTermGain: 0
        }
      ],
      cashConstraintsData: [
        {
          accountPropertiesId: null,
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          propertyCode: 'CASH_RAISE',
          propertyDesc: 'Withdrawal',
          propertyType: 'CASH_CONSTRAINT',
          propertyValue: null
        },
        {
          accountPropertiesId: null,
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          propertyCode: 'MAX_CASH_LEVEL',
          propertyDesc: 'Maximum Cash Level',
          propertyType: 'CASH_CONSTRAINT',
          propertyValue: null
        },
        {
          accountPropertiesId: null,
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          propertyCode: 'MIN_CASH_LEVEL',
          propertyDesc: 'Minimum Cash Level',
          propertyType: 'CASH_CONSTRAINT',
          propertyValue: null
        },
        {
          accountPropertiesId: null,
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          propertyCode: 'MINCASH',
          propertyDesc: 'Minimum Cash Amount',
          propertyType: 'CASH_CONSTRAINT',
          propertyValue: null
        }
      ],
      otherConstraintsData: [
        {
          accountPropertiesId: null,
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          propertyCode: 'FIXED_TXN_COST_LAMBDA',
          propertyDesc: 'Fixed Transaction Cost Multiplier',
          propertyType: 'OTHER_CONSTRAINT',
          propertyValue: null
        },
        {
          accountPropertiesId: null,
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          propertyCode: 'MAX_BETA',
          propertyDesc: 'Maximum Beta',
          propertyType: 'OTHER_CONSTRAINT',
          propertyValue: null
        },
        {
          accountPropertiesId: null,
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          propertyCode: 'MAX_HOLDINGS',
          propertyDesc: 'Max Holdings',
          propertyType: 'OTHER_CONSTRAINT',
          propertyValue: null
        },
        {
          accountPropertiesId: null,
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          propertyCode: 'MIN_BETA',
          propertyDesc: 'Minimum Beta',
          propertyType: 'OTHER_CONSTRAINT',
          propertyValue: null
        },
        {
          accountPropertiesId: null,
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          propertyCode: 'MIN_BUY_SIZE',
          propertyDesc: 'Min Buy Size',
          propertyType: 'OTHER_CONSTRAINT',
          propertyValue: null
        },
        {
          accountPropertiesId: null,
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          propertyCode: 'MIN_BUY_WEIGHT',
          propertyDesc: 'Min Buy Weight',
          propertyType: 'OTHER_CONSTRAINT',
          propertyValue: null
        },
        {
          accountPropertiesId: null,
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          propertyCode: 'MIN_TRD_SIZE',
          propertyDesc: 'Min Trade Weight',
          propertyType: 'OTHER_CONSTRAINT',
          propertyValue: null
        },
        {
          accountPropertiesId: null,
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          propertyCode: 'RISK_LAMBDA',
          propertyDesc: 'Active Risk Multiplier',
          propertyType: 'OTHER_CONSTRAINT',
          propertyValue: null
        },
        {
          accountPropertiesId: null,
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          propertyCode: 'TAX_LAMBDA',
          propertyDesc: 'Tax Cost Multiplier',
          propertyType: 'OTHER_CONSTRAINT',
          propertyValue: null
        },
        {
          accountPropertiesId: null,
          accountId: '90690385-3846-41e4-accb-0df65810ae66',
          propertyCode: 'TXN_COST_LAMBDA',
          propertyDesc: 'Variable Transaction Cost Multiplier',
          propertyType: 'OTHER_CONSTRAINT',
          propertyValue: null
        }
      ]
    },
    fieldLabels: {
      sectorData: {
        assetClassDesc: 'Sector',
        relative: 'Relative',
        sectorExclude: 'Exclude',
        min: 'Min',
        max: 'Max'
      },
      industryData: {
        assetClassDesc: 'Sector',
        industryId: 'Industry',
        relative: 'Relative',
        sectorId: 'Sector Id',
        industryExclude: 'Exclude',
        min: 'Min',
        max: 'Max'
      },
      securityData: {
        name: 'Security',
        source: 'Source',
        instrId: 'Instrument Id',
        restrictionId: 'Restriction Id',
        restrictionCode: 'Restriction Code',
        restrictionDesc: 'Restriction Description'
      },
      factorTiltsData: {
        statsLevelValue: 'Code',
        attrLabel: 'Name',
        statsValue: 'Value'
      },
      substitutionData: {
        sourceName: 'Source Instrument',
        targetName: 'Target Instrument',
        sourceInstrId: 'Source Instrument Id',
        targetInstrId: 'Target Instrument Id'
      },
      taxConstraintsData: {
        taxSensitivity: 'Tax Sensitivity',
        maxLongTermGain: 'Max Long Term Gain',
        maxShortTermGain: 'Max Short Term Gain'
      },
      cashConstraintsData: {
        accountPropertiesId: 'Account Properties ID',
        accountId: 'Account ID',
        propertyCode: 'Property Code',
        propertyDesc: 'Name',
        propertyType: 'Property Type',
        propertyValue: 'Value'
      },
      otherConstraintsData: {
        accountPropertiesId: 'Account Properties ID',
        accountId: 'Account ID',
        propertyCode: 'Property Code',
        propertyDesc: 'Name',
        propertyType: 'Property Type',
        propertyValue: 'Value'
      }
    }
  },
  key: 'personalisation-data',
  expiry: -1
}

export const initialPortfolioBlockData = {
  data: {
    data: {
      portfolioMarketValue: '$0.0',
      costBasis: '$10.0K',
      noOfHoldings: 0,
      ltUrg: 0,
      stUrg: 0,
      ltUrl: 0,
      stUrl: 0,
      unrealizedGain: '$0.0',
      unrealizedLoss: '$0.0',
      fullLiquidationTaxCost: '$0.0',
      totalPercentOfPortfolioValue: '0%'
    }
  }
}

//! ////////////////////////// aggregate-portfolio-data ////////////////////////////
export const aggregatePortfolioData = {
  data: [
    {
      "instrId": "A-XNYS",
      "localSymbol": "A",
      "instrumentName": "Agilent Technologies Inc.",
      "purchaseDate": "2024-03-01T00:00:00.000Z",
      "marketValue": 43512312.4199981689453,
      "aggTaxlotId": "fd3e34c9-0eb5-11ef-b940-0ae47778074f",
      "shares": 3,
      "weight": 0.02694311097019681,
      "orgPurchasePrice": 138.385,
      "company": "AGILENT TECHNOLOGIES INC",
      "assetClass": "EQ",
      "assetClassDesc": "Cash",
      "accountId": "5cd7f2d5-eca8-458c-b144-858dcecfebd8",
      "strategyName": "Russell 3000 index",
      "accountName": "Harry And Marry R3"
    },
    {
      "instrId": "AAGIY-OTCM",
      "localSymbol": "AAGIY",
      "instrumentName": "AIA Group Limited - ADR",
      "purchaseDate": "2024-03-05T00:00:00.000Z",
      "marketValue": 941.050022125244,
      "aggTaxlotId": "fd3ee579-0eb5-11ef-b940-0ae47778074f",
      "shares": 29,
      "weight": 0.05823070892758769,
      "orgPurchasePrice": 32.0156,
      "company": "AIA GROUP LTD",
      "assetClass": "EQ",
      "assetClassDesc": "Equity",
      "accountId": "c3ea14c1-54ce-4a22-aa5d-8d75073e7fe9",
      "strategyName": "STOXX Developed World ex North America",
      "accountName": "Harry And Marry Stx Intl Dev"
    },
    {
      "instrId": "AAPL-XNGS",
      "localSymbol": "AAPL",
      "instrumentName": "Apple Inc",
      "purchaseDate": "2024-03-01T00:00:00.000Z",
      "marketValue": 12919.900512695312,
      "aggTaxlotId": "fd3fb20c-0eb5-11ef-b940-0ae47778074f",
      "shares": 70,
      "weight": 0.7994633105996821,
      "orgPurchasePrice": 178.72,
      "company": "APPLE INC",
      "assetClass": "EQ",
      "assetClassDesc": "Equity",
      "accountId": "5cd7f2d5-eca8-458c-b144-858dcecfebd8",
      "strategyName": "Russell 3000 index",
      "accountName": "Harry And Marry R3"
    },
    {
      "instrId": "ABB-XNYS",
      "localSymbol": "ABBNY",
      "instrumentName": "ABB Ltd. - ADR",
      "purchaseDate": "2024-03-01T00:00:00.000Z",
      "marketValue": 930.8879928588868,
      "aggTaxlotId": "fd3fb5f9-0eb5-11ef-b940-0ae47778074f",
      "shares": 18,
      "weight": 0.05760189839211104,
      "orgPurchasePrice": 46.0575,
      "company": "ABB LTD",
      "assetClass": "EQ",
      "assetClassDesc": "Equity",
      "accountId": "c3ea14c1-54ce-4a22-aa5d-8d75073e7fe9",
      "strategyName": "STOXX Developed World ex North America",
      "accountName": "Harry And Marry Stx Intl Dev"
    },
    {
      "instrId": "ABBV-XNYS",
      "localSymbol": "ABBV",
      "instrumentName": "Abbvie Inc",
      "purchaseDate": "2024-03-01T00:00:00.000Z",
      "marketValue": 1443.5999450683594,
      "aggTaxlotId": "fd3fb7f2-0eb5-11ef-b940-0ae47778074f",
      "shares": 9,
      "weight": 0.08932771503401489,
      "orgPurchasePrice": 177.8586,
      "company": "ABBVIE INC",
      "assetClass": "EQ",
      "assetClassDesc": "Equity",
      "accountId": "5cd7f2d5-eca8-458c-b144-858dcecfebd8",
      "strategyName": "Russell 3000 index",
      "accountName": "Harry And Marry R3"
    },
    {
      "instrId": "ABNB-XNGS",
      "localSymbol": "ABNB",
      "instrumentName": "Airbnb Inc - Ordinary Shares - Class A",
      "purchaseDate": "2024-03-01T00:00:00.000Z",
      "marketValue": 441.15000915527344,
      "aggTaxlotId": "fd3fb9ac-0eb5-11ef-b940-0ae47778074f",
      "shares": 3,
      "weight": 0.027297675120934752,
      "orgPurchasePrice": 160.2907,
      "company": "AIRBNB A",
      "assetClass": "EQ",
      "assetClassDesc": "Equity",
      "accountId": "5cd7f2d5-eca8-458c-b144-858dcecfebd8",
      "strategyName": "Russell 3000 index",
      "accountName": "Harry And Marry R3"
    },
    {
      "instrId": "ABT-XNYS",
      "localSymbol": "ABT",
      "instrumentName": "Abbott Laboratories",
      "purchaseDate": "2024-03-01T00:00:00.000Z",
      "marketValue": 732.6899871826172,
      "aggTaxlotId": "fd3fbb70-0eb5-11ef-b940-0ae47778074f",
      "shares": 7,
      "weight": 0.04533771465350506,
      "orgPurchasePrice": 119.19,
      "company": "ABBOTT LABORATORIES",
      "assetClass": "EQ",
      "assetClassDesc": "Equity",
      "accountId": "5cd7f2d5-eca8-458c-b144-858dcecfebd8",
      "strategyName": "Russell 3000 index",
      "accountName": "Harry And Marry R3"
    },
    {
      "instrId": "ACGL-XNGS",
      "localSymbol": "ACGL",
      "instrumentName": "Arch Capital Group Ltd",
      "purchaseDate": "2024-03-01T00:00:00.000Z",
      "marketValue": 298.8899917602539,
      "aggTaxlotId": "fd3fbd23-0eb5-11ef-b940-0ae47778074f",
      "shares": 3,
      "weight": 0.018494846928810826,
      "orgPurchasePrice": 87.09,
      "company": "ARCH CAPITAL GROUP LTD",
      "assetClass": "EQ",
      "assetClassDesc": "Equity",
      "accountId": "5cd7f2d5-eca8-458c-b144-858dcecfebd8",
      "strategyName": "Russell 3000 index",
      "accountName": "Harry And Marry R3"
    },
    {
      "instrId": "ACN-XNYS",
      "localSymbol": "ACN",
      "instrumentName": "Accenture plc - Ordinary Shares - Class A",
      "purchaseDate": "2024-03-01T00:00:00.000Z",
      "marketValue": 919.8299560546876,
      "aggTaxlotId": "fd3fbeda-0eb5-11ef-b940-0ae47778074f",
      "shares": 3,
      "weight": 0.05691764430644441,
      "orgPurchasePrice": 381.3164,
      "company": "ACCENTURE PLC",
      "assetClass": "EQ",
      "assetClassDesc": "Equity",
      "accountId": "5cd7f2d5-eca8-458c-b144-858dcecfebd8",
      "strategyName": "Russell 3000 index",
      "accountName": "Harry And Marry R3"
    },
    {
      "instrId": "ADBE-XNGS",
      "localSymbol": "ADBE",
      "instrumentName": "Adobe Inc",
      "purchaseDate": "2024-03-01T00:00:00.000Z",
      "marketValue": 965.2999877929688,
      "aggTaxlotId": "fd3fc086-0eb5-11ef-b940-0ae47778074f",
      "shares": 2,
      "weight": 0.05973125901430065,
      "orgPurchasePrice": 568.58,
      "company": "ADOBE INC",
      "assetClass": "EQ",
      "assetClassDesc": "Equity",
      "accountId": "5cd7f2d5-eca8-458c-b144-858dcecfebd8",
      "strategyName": "Russell 3000 index",
      "accountName": "Harry And Marry R3"
    },
    {
      "instrId": "ADI-XNGS",
      "localSymbol": "ADI",
      "instrumentName": "Analog Devices Inc.",
      "purchaseDate": "2024-03-01T00:00:00.000Z",
      "marketValue": 409.5799865722656,
      "aggTaxlotId": "fd3fc240-0eb5-11ef-b940-0ae47778074f",
      "shares": 2,
      "weight": 0.025344171319174217,
      "orgPurchasePrice": 195.335,
      "company": "ANALOG DEVICES INC",
      "assetClass": "EQ",
      "assetClassDesc": "Equity",
      "accountId": "5cd7f2d5-eca8-458c-b144-858dcecfebd8",
      "strategyName": "Russell 3000 index",
      "accountName": "Harry And Marry R3"
    },
    {
      "instrId": "ADM-XNYS",
      "localSymbol": "ADM",
      "instrumentName": "Archer Daniels Midland Co.",
      "purchaseDate": "2024-03-01T00:00:00.000Z",
      "marketValue": 312.1999931335449,
      "aggTaxlotId": "fd3fc3f0-0eb5-11ef-b940-0ae47778074f",
      "shares": 5,
      "weight": 0.01931844907276864,
      "orgPurchasePrice": 54.326,
      "company": "ARCHER-DANIELS-MIDLAND CO",
      "assetClass": "EQ",
      "assetClassDesc": "Equity",
      "accountId": "5cd7f2d5-eca8-458c-b144-858dcecfebd8",
      "strategyName": "Russell 3000 index",
      "accountName": "Harry And Marry R3"
    },
    {
      "instrId": "ADP-XNGS",
      "localSymbol": "ADP",
      "instrumentName": "Automatic Data Processing Inc.",
      "purchaseDate": "2024-03-01T00:00:00.000Z",
      "marketValue": 490.2900085449219,
      "aggTaxlotId": "fd3fc590-0eb5-11ef-b940-0ae47778074f",
      "shares": 2,
      "weight": 0.030338381708134243,
      "orgPurchasePrice": 249.6279,
      "company": "AUTOMATIC DATA PROCESSING INC",
      "assetClass": "EQ",
      "assetClassDesc": "Equity",
      "accountId": "5cd7f2d5-eca8-458c-b144-858dcecfebd8",
      "strategyName": "Russell 3000 index",
      "accountName": "Harry And Marry R3"
    },
    {
      "instrId": "ADSK-XNGS",
      "localSymbol": "ADSK",
      "instrumentName": "Autodesk Inc.",
      "purchaseDate": "2024-03-01T00:00:00.000Z",
      "marketValue": 437.260009765625,
      "aggTaxlotId": "fd3fc746-0eb5-11ef-b940-0ae47778074f",
      "shares": 2,
      "weight": 0.02705696802050288,
      "orgPurchasePrice": 264.1219,
      "company": "AUTODESK INC",
      "assetClass": "EQ",
      "assetClassDesc": "Equity",
      "accountId": "5cd7f2d5-eca8-458c-b144-858dcecfebd8",
      "strategyName": "Russell 3000 index",
      "accountName": "Harry And Marry R3"
    },
    {
      "instrId": "ADYEY-OTCM",
      "localSymbol": "ADYEY",
      "instrumentName": "Adyen NV - ADR",
      "purchaseDate": "2024-03-01T00:00:00.000Z",
      "marketValue": 672.1800155639648,
      "aggTaxlotId": "fd3fcaa5-0eb5-11ef-b940-0ae47778074f",
      "shares": 51,
      "weight": 0.04159345190264208,
      "orgPurchasePrice": 15.845,
      "company": "ADYEN N.V",
      "assetClass": "EQ",
      "assetClassDesc": "Equity",
      "accountId": "c3ea14c1-54ce-4a22-aa5d-8d75073e7fe9",
      "strategyName": "STOXX Developed World ex North America",
      "accountName": "Harry And Marry Stx Intl Dev"
    },
    {
      "instrId": "AEG-XNYS",
      "localSymbol": "AEG",
      "instrumentName": "Aegon Ltd. - New York Shares",
      "purchaseDate": "2024-03-01T00:00:00.000Z",
      "marketValue": 344.5,
      "aggTaxlotId": "fd3fcc84-0eb5-11ef-b940-0ae47778074f",
      "shares": 53,
      "weight": 0.021317123164451845,
      "orgPurchasePrice": 5.5923,
      "company": "AEGON LTD",
      "assetClass": "EQ",
      "assetClassDesc": "Equity",
      "accountId": "c3ea14c1-54ce-4a22-aa5d-8d75073e7fe9",
      "strategyName": "STOXX Developed World ex North America",
      "accountName": "Harry And Marry Stx Intl Dev"
    },
    {
      "instrId": "AEP-XNGS",
      "localSymbol": "AEP",
      "instrumentName": "American Electric Power Company Inc.",
      "purchaseDate": "2024-03-01T00:00:00.000Z",
      "marketValue": 272.84999084472656,
      "aggTaxlotId": "fd3fce33-0eb5-11ef-b940-0ae47778074f",
      "shares": 3,
      "weight": 0.016883532250381982,
      "orgPurchasePrice": 83.3052,
      "company": "AMERICAN ELECTRIC POWER CO INC",
      "assetClass": "EQ",
      "assetClassDesc": "Equity",
      "accountId": "5cd7f2d5-eca8-458c-b144-858dcecfebd8",
      "strategyName": "Russell 3000 index",
      "accountName": "Harry And Marry R3"
    },
    {
      "instrId": "AFL-XNYS",
      "localSymbol": "AFL",
      "instrumentName": "Aflac Inc.",
      "purchaseDate": "2024-03-01T00:00:00.000Z",
      "marketValue": 256.1399917602539,
      "aggTaxlotId": "fd3fd000-0eb5-11ef-b940-0ae47778074f",
      "shares": 3,
      "weight": 0.015849543546284508,
      "orgPurchasePrice": 80.5299,
      "company": "AFLAC INCORPORATED",
      "assetClass": "EQ",
      "assetClassDesc": "Equity",
      "accountId": "5cd7f2d5-eca8-458c-b144-858dcecfebd8",
      "strategyName": "Russell 3000 index",
      "accountName": "Harry And Marry R3"
    },
    {
      "instrId": "AIG-XNYS",
      "localSymbol": "AIG",
      "instrumentName": "American International Group Inc",
      "purchaseDate": "2024-03-01T00:00:00.000Z",
      "marketValue": 319.5199890136719,
      "aggTaxlotId": "fd3fd1b2-0eb5-11ef-b940-0ae47778074f",
      "shares": 4,
      "weight": 0.01977139901105586,
      "orgPurchasePrice": 73.015,
      "company": "AMERICAN INTERNATIONAL GROUP INC",
      "assetClass": "EQ",
      "assetClassDesc": "Equity",
      "accountId": "5cd7f2d5-eca8-458c-b144-858dcecfebd8",
      "strategyName": "Russell 3000 index",
      "accountName": "Harry And Marry R3"
    },
    {
      "instrId": "AIQUY-OTCM",
      "localSymbol": "AIQUY",
      "instrumentName": "Air Liquide S.A - ADR",
      "purchaseDate": "2024-03-01T00:00:00.000Z",
      "marketValue": 886.599983215332,
      "aggTaxlotId": "fd3fd3a7-0eb5-11ef-b940-0ae47778074f",
      "shares": 22,
      "weight": 0.05486142536952735,
      "orgPurchasePrice": 40.5071,
      "company": "L'AIR LIQUIDE SA",
      "assetClass": "EQ",
      "assetClassDesc": "Equity",
      "accountId": "c3ea14c1-54ce-4a22-aa5d-8d75073e7fe9",
      "strategyName": "STOXX Developed World ex North America",
      "accountName": "Harry And Marry Stx Intl Dev"
    },
    {
      "instrId": "AJG-XNYS",
      "localSymbol": "AJG",
      "instrumentName": "Arthur J. Gallagher & Co.",
      "purchaseDate": "2024-03-01T00:00:00.000Z",
      "marketValue": 247.55999755859372,
      "aggTaxlotId": "fd3fd556-0eb5-11ef-b940-0ae47778074f",
      "shares": 1,
      "weight": 0.015318626875320582,
      "orgPurchasePrice": 243.12,
      "company": "ARTHUR J. GALLAGHER & CO.",
      "assetClass": "EQ",
      "assetClassDesc": "Equity",
      "accountId": "5cd7f2d5-eca8-458c-b144-858dcecfebd8",
      "strategyName": "Russell 3000 index",
      "accountName": "Harry And Marry R3"
    },
    {
      "instrId": "ALC-XNYS",
      "localSymbol": "ALC",
      "instrumentName": "Alcon Inc. - Registered Shares",
      "purchaseDate": "2024-03-01T00:00:00.000Z",
      "marketValue": 325.8399963378906,
      "aggTaxlotId": "fd3fd6e6-0eb5-11ef-b940-0ae47778074f",
      "shares": 4,
      "weight": 0.02016247121578912,
      "orgPurchasePrice": 86.765,
      "company": "ALCON AG CHF0.04",
      "assetClass": "EQ",
      "assetClassDesc": "Equity",
      "accountId": "c3ea14c1-54ce-4a22-aa5d-8d75073e7fe9",
      "strategyName": "STOXX Developed World ex North America",
      "accountName": "Harry And Marry Stx Intl Dev"
    },
    {
      "instrId": "ALGN-XNGS",
      "localSymbol": "ALGN",
      "instrumentName": "Align Technology, Inc.",
      "purchaseDate": "2024-03-01T00:00:00.000Z",
      "marketValue": 280.3900146484375,
      "aggTaxlotId": "fd3fd8a5-0eb5-11ef-b940-0ae47778074f",
      "shares": 1,
      "weight": 0.017350097173710298,
      "orgPurchasePrice": 304.4897,
      "company": "ALIGN TECHNOLOGY INC",
      "assetClass": "EQ",
      "assetClassDesc": "Equity",
      "accountId": "5cd7f2d5-eca8-458c-b144-858dcecfebd8",
      "strategyName": "Russell 3000 index",
      "accountName": "Harry And Marry R3"
    },
    {
      "instrId": "ALIZY-OTCM",
      "localSymbol": "ALIZY",
      "instrumentName": "Allianz SE - ADR",
      "purchaseDate": "2024-03-01T00:00:00.000Z",
      "marketValue": 848.9999771118164,
      "aggTaxlotId": "fd3fda45-0eb5-11ef-b940-0ae47778074f",
      "shares": 30,
      "weight": 0.05253479558406207,
      "orgPurchasePrice": 27.2325,
      "company": "ALLIANZ SE",
      "assetClass": "EQ",
      "assetClassDesc": "Equity",
      "accountId": "c3ea14c1-54ce-4a22-aa5d-8d75073e7fe9",
      "strategyName": "STOXX Developed World ex North America",
      "accountName": "Harry And Marry Stx Intl Dev"
    },
    {
      "instrId": "ALL-XNYS",
      "localSymbol": "ALL",
      "instrumentName": "Allstate Corp (The)",
      "purchaseDate": "2024-03-01T00:00:00.000Z",
      "marketValue": 342.9200134277344,
      "aggTaxlotId": "fd3fdbd8-0eb5-11ef-b940-0ae47778074f",
      "shares": 2,
      "weight": 0.021219356057458616,
      "orgPurchasePrice": 155.4587,
      "company": "THE ALLSTATE CORPORATION",
      "assetClass": "EQ",
      "assetClassDesc": "Equity",
      "accountId": "5cd7f2d5-eca8-458c-b144-858dcecfebd8",
      "strategyName": "Russell 3000 index",
      "accountName": "Harry And Marry R3"
    },
    {
      "instrId": "AMAT-XNGS",
      "localSymbol": "AMAT",
      "instrumentName": "Applied Materials Inc.",
      "purchaseDate": "2024-03-01T00:00:00.000Z",
      "marketValue": 825.3200073242188,
      "aggTaxlotId": "fd3fdd86-0eb5-11ef-b940-0ae47778074f",
      "shares": 4,
      "weight": 0.05106951595418481,
      "orgPurchasePrice": 209.83,
      "company": "APPLIED MATERIALS INC",
      "assetClass": "EQ",
      "assetClassDesc": "Equity",
      "accountId": "5cd7f2d5-eca8-458c-b144-858dcecfebd8",
      "strategyName": "Russell 3000 index",
      "accountName": "Harry And Marry R3"
    },
    {
      "instrId": "AMD-XNCM",
      "localSymbol": "AMD",
      "instrumentName": "Advanced Micro Devices Inc.",
      "purchaseDate": "2024-03-01T00:00:00.000Z",
      "marketValue": 1219.1199951171875,
      "aggTaxlotId": "fd3fe0de-0eb5-11ef-b940-0ae47778074f",
      "shares": 8,
      "weight": 0.07543724553892311,
      "orgPurchasePrice": 200.51,
      "company": "ADVANCED MICRO DEVICES INC",
      "assetClass": "EQ",
      "assetClassDesc": "Equity",
      "accountId": "5cd7f2d5-eca8-458c-b144-858dcecfebd8",
      "strategyName": "Russell 3000 index",
      "accountName": "Harry And Marry R3"
    },
    {
      "instrId": "AME-XNYS",
      "localSymbol": "AME",
      "instrumentName": "Ametek Inc",
      "purchaseDate": "2024-03-01T00:00:00.000Z",
      "marketValue": 339.79998779296875,
      "aggTaxlotId": "fd3fe374-0eb5-11ef-b940-0ae47778074f",
      "shares": 2,
      "weight": 0.02102629373312612,
      "orgPurchasePrice": 180.715,
      "company": "AMETEK INC",
      "assetClass": "EQ",
      "assetClassDesc": "Equity",
      "accountId": "5cd7f2d5-eca8-458c-b144-858dcecfebd8",
      "strategyName": "Russell 3000 index",
      "accountName": "Harry And Marry R3"
    },
    {
      "instrId": "AMGN-XNGS",
      "localSymbol": "AMGN",
      "instrumentName": "AMGEN Inc.",
      "purchaseDate": "2024-03-01T00:00:00.000Z",
      "marketValue": 938.5799560546876,
      "aggTaxlotId": "fd3fe5e1-0eb5-11ef-b940-0ae47778074f",
      "shares": 3,
      "weight": 0.0580778650882542,
      "orgPurchasePrice": 280.5444,
      "company": "AMGEN INC",
      "assetClass": "EQ",
      "assetClassDesc": "Equity",
      "accountId": "5cd7f2d5-eca8-458c-b144-858dcecfebd8",
      "strategyName": "Russell 3000 index",
      "accountName": "Harry And Marry R3"
    },
    {
      "instrId": "AMP-XNYS",
      "localSymbol": "AMP",
      "instrumentName": "Ameriprise Financial Inc",
      "purchaseDate": "2024-03-01T00:00:00.000Z",
      "marketValue": 430.0299987792969,
      "aggTaxlotId": "fd3fe7a2-0eb5-11ef-b940-0ae47778074f",
      "shares": 1,
      "weight": 0.02660958620722017,
      "orgPurchasePrice": 406.9558,
      "company": "AMERIPRISE FINANCIAL INC",
      "assetClass": "EQ",
      "assetClassDesc": "Equity",
      "accountId": "5cd7f2d5-eca8-458c-b144-858dcecfebd8",
      "strategyName": "Russell 3000 index",
      "accountName": "Harry And Marry R3"
    },
    {
      "instrId": "AMT-XNYS",
      "localSymbol": "AMT",
      "instrumentName": "American Tower Corp.",
      "purchaseDate": "2024-03-01T00:00:00.000Z",
      "marketValue": 372.739990234375,
      "aggTaxlotId": "fd3fe95d-0eb5-11ef-b940-0ae47778074f",
      "shares": 2,
      "weight": 0.02306456975367997,
      "orgPurchasePrice": 200.075,
      "company": "AMERICAN TOWER CORP",
      "assetClass": "EQ",
      "assetClassDesc": "Equity",
      "accountId": "5cd7f2d5-eca8-458c-b144-858dcecfebd8",
      "strategyName": "Russell 3000 index",
      "accountName": "Harry And Marry R3"
    },
    {
      "instrId": "AMZN-XNGS",
      "localSymbol": "AMZN",
      "instrumentName": "Amazon.com Inc.",
      "purchaseDate": "2024-03-01T00:00:00.000Z",
      "marketValue": 8338,
      "aggTaxlotId": "fd401fce-0eb5-11ef-b940-0ae47778074f",
      "shares": 44,
      "weight": 0.5159424468656008,
      "orgPurchasePrice": 178.2898,
      "company": "AMAZON.COM INC",
      "assetClass": "EQ",
      "assetClassDesc": "Equity",
      "accountId": "5cd7f2d5-eca8-458c-b144-858dcecfebd8",
      "strategyName": "Russell 3000 index",
      "accountName": "Harry And Marry R3"
    },
    {
      "instrId": "ANET-XNYS",
      "localSymbol": "ANET",
      "instrumentName": "Arista Networks Inc",
      "purchaseDate": "2024-03-01T00:00:00.000Z",
      "marketValue": 296.07000732421875,
      "aggTaxlotId": "fd4022ee-0eb5-11ef-b940-0ae47778074f",
      "shares": 1,
      "weight": 0.01832035068630052,
      "orgPurchasePrice": 287.755,
      "company": "ARISTA NETWORKS INC",
      "assetClass": "EQ",
      "assetClassDesc": "Equity",
      "accountId": "5cd7f2d5-eca8-458c-b144-858dcecfebd8",
      "strategyName": "Russell 3000 index",
      "accountName": "Harry And Marry R3"
    },
    {
      "instrId": "ANSS-XNGS",
      "localSymbol": "ANSS",
      "instrumentName": "Ansys Inc. - Registered Shares",
      "purchaseDate": "2024-03-01T00:00:00.000Z",
      "marketValue": 327.25,
      "aggTaxlotId": "fd40249a-0eb5-11ef-b940-0ae47778074f",
      "shares": 1,
      "weight": 0.02024972004518684,
      "orgPurchasePrice": 339.65,
      "company": "ANSYS INC",
      "assetClass": "EQ",
      "assetClassDesc": "Equity",
      "accountId": "5cd7f2d5-eca8-458c-b144-858dcecfebd8",
      "strategyName": "Russell 3000 index",
      "accountName": "Harry And Marry R3"
    },
    {
      "instrId": "ANZGY-OTCM",
      "localSymbol": "ANZGY",
      "instrumentName": "ANZ Group Holdings Ltd - ADR",
      "purchaseDate": "2024-03-01T00:00:00.000Z",
      "marketValue": 916.3200073242188,
      "aggTaxlotId": "fd40265b-0eb5-11ef-b940-0ae47778074f",
      "shares": 48,
      "weight": 0.05670045414856832,
      "orgPurchasePrice": 18.81,
      "company": "ANZ GROUP HOLDINGS LIMITED",
      "assetClass": "EQ",
      "assetClassDesc": "Equity",
      "accountId": "c3ea14c1-54ce-4a22-aa5d-8d75073e7fe9",
      "strategyName": "STOXX Developed World ex North America",
      "accountName": "Harry And Marry Stx Intl Dev"
    },
    {
      "instrId": "AON-XNYS",
      "localSymbol": "AON",
      "instrumentName": "Aon plc. - Ordinary Shares - Class A",
      "purchaseDate": "2024-03-01T00:00:00.000Z",
      "marketValue": 287.5799865722656,
      "aggTaxlotId": "fd402b09-0eb5-11ef-b940-0ae47778074f",
      "shares": 1,
      "weight": 0.017795001432198528,
      "orgPurchasePrice": 313.2963,
      "company": "AON PLC",
      "assetClass": "EQ",
      "assetClassDesc": "Equity",
      "accountId": "5cd7f2d5-eca8-458c-b144-858dcecfebd8",
      "strategyName": "Russell 3000 index",
      "accountName": "Harry And Marry R3"
    },
    {
      "instrId": "APD-XNYS",
      "localSymbol": "APD",
      "instrumentName": "Air Products & Chemicals Inc.",
      "purchaseDate": "2024-03-01T00:00:00.000Z",
      "marketValue": 501.2200012207031,
      "aggTaxlotId": "fd41ed96-0eb5-11ef-b940-0ae47778074f",
      "shares": 2,
      "weight": 0.031014712622665985,
      "orgPurchasePrice": 235.9299,
      "company": "AIR PRODUCTS AND CHEMICALS INC.",
      "assetClass": "EQ",
      "assetClassDesc": "Equity",
      "accountId": "5cd7f2d5-eca8-458c-b144-858dcecfebd8",
      "strategyName": "Russell 3000 index",
      "accountName": "Harry And Marry R3"
    },
    {
      "instrId": "APH-XNYS",
      "localSymbol": "APH",
      "instrumentName": "Amphenol Corp. - Ordinary Shares - Class A",
      "purchaseDate": "2024-03-01T00:00:00.000Z",
      "marketValue": 382.8300018310547,
      "aggTaxlotId": "fd41f001-0eb5-11ef-b940-0ae47778074f",
      "shares": 3,
      "weight": 0.023688923947982343,
      "orgPurchasePrice": 110.213,
      "company": "AMPHENOL CORP",
      "assetClass": "EQ",
      "assetClassDesc": "Equity",
      "accountId": "5cd7f2d5-eca8-458c-b144-858dcecfebd8",
      "strategyName": "Russell 3000 index",
      "accountName": "Harry And Marry R3"
    },
    {
      "instrId": "APO-XNYS",
      "localSymbol": "APO",
      "instrumentName": "Apollo Global Management Inc - Ordinary Shares - Class A (New)",
      "purchaseDate": "2024-03-01T00:00:00.000Z",
      "marketValue": 448.760009765625,
      "aggTaxlotId": "fd41f1b0-0eb5-11ef-b940-0ae47778074f",
      "shares": 4,
      "weight": 0.02776857010001289,
      "orgPurchasePrice": 110.09,
      "company": "APOLLO GLOBAL MANAGEMENT INC",
      "assetClass": "EQ",
      "assetClassDesc": "Equity",
      "accountId": "5cd7f2d5-eca8-458c-b144-858dcecfebd8",
      "strategyName": "Russell 3000 index",
      "accountName": "Harry And Marry R3"
    },
    {
      "instrId": "APTV-XNYS",
      "localSymbol": "APTV",
      "instrumentName": "Aptiv PLC",
      "purchaseDate": "2024-03-01T00:00:00.000Z",
      "marketValue": 334.760009765625,
      "aggTaxlotId": "fd41f366-0eb5-11ef-b940-0ae47778074f",
      "shares": 4,
      "weight": 0.020714427746609376,
      "orgPurchasePrice": 79.299,
      "company": "APTIVE PLC",
      "assetClass": "EQ",
      "assetClassDesc": "Equity",
      "accountId": "5cd7f2d5-eca8-458c-b144-858dcecfebd8",
      "strategyName": "Russell 3000 index",
      "accountName": "Harry And Marry R3"
    },
    {
      "instrId": "ARE-XNYS",
      "localSymbol": "ARE",
      "instrumentName": "Alexandria Real Estate Equities Inc.",
      "purchaseDate": "2024-03-01T00:00:00.000Z",
      "marketValue": 361.620002746582,
      "aggTaxlotId": "fd41f527-0eb5-11ef-b940-0ae47778074f",
      "shares": 3,
      "weight": 0.02237648225625052,
      "orgPurchasePrice": 122.7056,
      "company": "ALEXANDRIA REAL ESTATE EQUITIES INC.",
      "assetClass": "EQ",
      "assetClassDesc": "Equity",
      "accountId": "5cd7f2d5-eca8-458c-b144-858dcecfebd8",
      "strategyName": "Russell 3000 index",
      "accountName": "Harry And Marry R3"
    },
    {
      "instrId": "ARES-XNYS",
      "localSymbol": "ARES",
      "instrumentName": "Ares Management Corp - Ordinary Shares - Class A",
      "purchaseDate": "2024-03-01T00:00:00.000Z",
      "marketValue": 284.8800048828125,
      "aggTaxlotId": "fd41f6b7-0eb5-11ef-b940-0ae47778074f",
      "shares": 2,
      "weight": 0.017627930772646027,
      "orgPurchasePrice": 133.0991,
      "company": "ARES MANAGEMENT CORP",
      "assetClass": "EQ",
      "assetClassDesc": "Equity",
      "accountId": "5cd7f2d5-eca8-458c-b144-858dcecfebd8",
      "strategyName": "Russell 3000 index",
      "accountName": "Harry And Marry R3"
    },
    {
      "instrId": "ASAZY-OTCM",
      "localSymbol": "ASAZY",
      "instrumentName": "Assa Abloy AB - ADR",
      "purchaseDate": "2024-03-01T00:00:00.000Z",
      "marketValue": 973.58997631073,
      "aggTaxlotId": "fd41f849-0eb5-11ef-b940-0ae47778074f",
      "shares": 69,
      "weight": 0.06024423058546178,
      "orgPurchasePrice": 14.2432,
      "company": "ASSA ABLOY AB",
      "assetClass": "EQ",
      "assetClassDesc": "Equity",
      "accountId": "c3ea14c1-54ce-4a22-aa5d-8d75073e7fe9",
      "strategyName": "STOXX Developed World ex North America",
      "accountName": "Harry And Marry Stx Intl Dev"
    },
    {
      "instrId": "ASHTY-OTCM",
      "localSymbol": "ASHTY",
      "instrumentName": "Ashtead Group plc - ADR",
      "purchaseDate": "2024-03-01T00:00:00.000Z",
      "marketValue": 896.69970703125,
      "aggTaxlotId": "fd41fa19-0eb5-11ef-b940-0ae47778074f",
      "shares": 3,
      "weight": 0.0554863805408216,
      "orgPurchasePrice": 295.39,
      "company": "ASHTEAD GROUP PLC",
      "assetClass": "EQ",
      "assetClassDesc": "Equity",
      "accountId": "c3ea14c1-54ce-4a22-aa5d-8d75073e7fe9",
      "strategyName": "STOXX Developed World ex North America",
      "accountName": "Harry And Marry Stx Intl Dev"
    },
    {
      "instrId": "ASML-XNGS",
      "localSymbol": "ASML",
      "instrumentName": "ASML Holding NV - New York Shares",
      "purchaseDate": "2024-03-01T00:00:00.000Z",
      "marketValue": 2740.6199340820312,
      "aggTaxlotId": "fd41fbd1-0eb5-11ef-b940-0ae47778074f",
      "shares": 3,
      "weight": 0.16958529080342105,
      "orgPurchasePrice": 984.695,
      "company": "ASML HOLDING NV",
      "assetClass": "EQ",
      "assetClassDesc": "Equity",
      "accountId": "5cd7f2d5-eca8-458c-b144-858dcecfebd8",
      "strategyName": "Russell 3000 index",
      "accountName": "Harry And Marry R3"
    },
    {
      "instrId": "ATLKY-OTCM",
      "localSymbol": "ATLKY",
      "instrumentName": "Atlas Copco AB - ADR - Class A",
      "purchaseDate": "2024-03-01T00:00:00.000Z",
      "marketValue": 1003.319995880127,
      "aggTaxlotId": "fd41fd62-0eb5-11ef-b940-0ae47778074f",
      "shares": 54,
      "weight": 0.06208387786802317,
      "orgPurchasePrice": 17.44,
      "company": "ATLAS COPCO AB",
      "assetClass": "EQ",
      "assetClassDesc": "Equity",
      "accountId": "c3ea14c1-54ce-4a22-aa5d-8d75073e7fe9",
      "strategyName": "STOXX Developed World ex North America",
      "accountName": "Harry And Marry Stx Intl Dev"
    },
    {
      "instrId": "ATO-XNYS",
      "localSymbol": "ATO",
      "instrumentName": "Atmos Energy Corp.",
      "purchaseDate": "2024-03-01T00:00:00.000Z",
      "marketValue": 239.3800048828125,
      "aggTaxlotId": "fd41ff07-0eb5-11ef-b940-0ae47778074f",
      "shares": 2,
      "weight": 0.014812461675454277,
      "orgPurchasePrice": 112.64,
      "company": "ATMOS ENERGY CORP",
      "assetClass": "EQ",
      "assetClassDesc": "Equity",
      "accountId": "5cd7f2d5-eca8-458c-b144-858dcecfebd8",
      "strategyName": "Russell 3000 index",
      "accountName": "Harry And Marry R3"
    }
  ]
}

//! //////////////////////////// Icon Json Fector Table ////////////////////////////

export const FactorTableIcon = {
  'Dividend Yield':
    'https://aris-logos-infra-bucket.s3.us-east-2.amazonaws.com/ARIS_LOGOS/Dividend-yield-icon.svg',
  Momentum:
    'https://aris-logos-infra-bucket.s3.us-east-2.amazonaws.com/ARIS_LOGOS/momentum-icon.svg',
  Profitability:
    'https://aris-logos-infra-bucket.s3.us-east-2.amazonaws.com/ARIS_LOGOS/profitablity-icon.svg',
  Value:
    'https://aris-logos-infra-bucket.s3.us-east-2.amazonaws.com/ARIS_LOGOS/value-icon.svg',
  Growth:
    'https://aris-logos-infra-bucket.s3.us-east-2.amazonaws.com/ARIS_LOGOS/growth-icon.svg',
  Volatility:
    'https://aris-logos-infra-bucket.s3.us-east-2.amazonaws.com/ARIS_LOGOS/volatility-icon.svg',
  Size: 'https://aris-logos-infra-bucket.s3.us-east-2.amazonaws.com/ARIS_LOGOS/size-icon.svg',
  Quality:
    'https://aris-logos-infra-bucket.s3.us-east-2.amazonaws.com/ARIS_LOGOS/quality-icon.svg'
}

//! //////////////////////////// cash-flow-summary ////////////////////////////
export const cashFlowSummaryData = {
  data: [{
    "startDate": "2023-12-29",
    "beginningMarketValue": 3914549.8218531,
    "endingMarketValue": 4690677.2276473,
    "deposit": 294461.224,
    "withdrawal": -55,
    "internalDeposit": 11419.052309,
    "internalWithdrawal": -66331.6392194,
    "dividendAndInterest": 2837.7900000000004,
    "fees": 1093.47,
    "netChangeInMv": 3327.320503199997,
    "endDate": "2024-07-25"
  }]
}


//! //////////////////////////// activity-summary ////////////////////////////
export const activitySummaryData = {
  data: {
    "accountId": "51e4e1da-3d12-458e-b840-642fa041e375",
    "accStartDate": "2024-04-26",
    "accEndDate": "2024-08-05",
    "beginningMarketValue": {
      "total": 1529.0099945
    },
    "contribution": {
      "total": 0
    },
    "internalDeposit": 1892.8142166,
    "distribution": {
      "total": 0
    },
    "internalWithdrawal": 9962.3845164,
    "allIncome": {
      "total": 106.68,
      "QDV": 106.68
    },
    "netChangeInMv": 18410.5400725,
    "advisoryFeesPaid": {
      "commissionFee": 0,
      "exchangeProcessFee": 0,
      "total": 0
    },
    "endingMarketValue": 11976.6597672
  }
}

//! //////////////////////////// uma-activity-summary ////////////////////////////
export const umaActivitySummaryData = {
  data: [
    {
      "accountId": "075d0980-9636-4735-b9ef-bebae02c5bba",
      "accountName": "Harry And Marry R3",
      "accStartDate": "2024-01-31",
      "accEndDate": "2024-06-17",
      "beginningMarketValue": {
        "total": 303572.18
      },
      "contribution": {
        "total": 194241.224,
        "CREDIT": 194241.224
      },
      "internalDeposit": 510,
      "distribution": {
        "total": 0
      },
      "internalWithdrawal": 200,
      "allIncome": {
        "total": 306.85,
        "QDV": 305.17,
        "BKINT": 1.68,
        "NDVAJ": -7.74,
        "NDV": 7.74
      },
      "netChangeInMv": -397120.19369539997,
      "advisoryFeesPaid": {
        "commissionFee": 0,
        "exchangeProcessFee": 0,
        "total": 0
      },
      "endingMarketValue": 101310.0603046
    }
  ]
}

//! //////////////////////////// uma-market-value ////////////////////////////
export const umaMarketValueData = {
  "data": {
    "groupBy": [
      "family",
      "trust",
      "account",
      "uma"
    ],
    "data": [
      {
        "family": "Harry And Marry House",
        "trust": null,
        "account": "Drashti test ACL trading account",
        "uma": null,
        "accountId": "bd7bba7d-90ee-4c2b-be67-fc7a14e71641",
        "accountName": "Drashti test ACL trading account",
        "marketValue": 4938.4098816,
        "allocationPercentage": 0.001388150977067065
      },
      {
        "family": "Harry And Marry House",
        "trust": null,
        "account": "Harry And Marry Stx Intl Dev",
        "uma": "Harry And Marry",
        "accountId": "c3ea14c1-54ce-4a22-aa5d-8d75073e7fe9",
        "accountName": "Harry And Marry Stx Intl Dev",
        "marketValue": 119930.0646106,
        "allocationPercentage": 0.03371146590914043
      },
      {
        "family": "Harry And Marry House",
        "trust": null,
        "account": "Harry And Marry R3",
        "uma": "Harry And Marry",
        "accountId": "5cd7f2d5-eca8-458c-b144-858dcecfebd8",
        "accountName": "Harry And Marry R3",
        "marketValue": 1137578.2975182,
        "allocationPercentage": 0.31976495735476573
      },
      {
        "family": "Harry And Marry House",
        "trust": null,
        "account": "UN-TAGGED",
        "uma": "Harry And Marry",
        "accountId": "UN-TAGGED",
        "accountName": "UN-TAGGED",
        "marketValue": 391867.6922797,
        "allocationPercentage": 0.11015114843866296
      },
      {
        "family": "Harry And Marry House",
        "trust": "Harry And Marry House1",
        "account": "Harry And Marry R3",
        "uma": null,
        "accountId": "5cd7f2d5-eca8-458c-b144-858dcecfebd8",
        "accountName": "Harry And Marry R3",
        "marketValue": 1137578.2975182,
        "allocationPercentage": 0.31976495735476573
      },
      {
        "family": "Harry And Marry House3",
        "trust": "Harry And Marry House1",
        "account": "Asmita AGG Acc 1",
        "uma": null,
        "accountId": "b1144187-fce9-46d7-b436-a47257d89e67",
        "accountName": "Asmita AGG Acc 1",
        "marketValue": 8020.881631,
        "allocationPercentage": 0.0022546112898600766
      },
      {
        "family": "Harry And Marry House2",
        "trust": "Harry And Marry House1",
        "account": "Drashti test ACL trading account",
        "uma": "ADI AGG Test",
        "accountId": "bd7bba7d-90ee-4c2b-be67-fc7a14e71641",
        "accountName": "Drashti test ACL trading account",
        "marketValue": 4938.4098816,
        "allocationPercentage": 0.001388150977067065
      },
      {
        "family": "Harry And Marry House2",
        "trust": "Harry And Marry House1",
        "account": "ADI SLV1 Do Not Touch",
        "uma": "ADI AGG Test",
        "accountId": "e73d126e-4a83-4315-ae93-4e988f427291",
        "accountName": "ADI SLV1 Do Not Touch",
        "marketValue": 748846.6026816,
        "allocationPercentage": 0.2104953149107629
      },
      {
        "family": "Harry And Marry House2",
        "trust": "Harry And Marry House1",
        "account": "ADI SLV2 Do Not Touch",
        "uma": "ADI AGG Test",
        "accountId": "787fd1db-5f93-4070-bf60-436227a80780",
        "accountName": "ADI SLV2 Do Not Touch",
        "marketValue": 203584.8997612,
        "allocationPercentage": 0.05722622955469389
      },
      {
        "family": "Harry And Marry House2",
        "trust": "Harry And Marry House1",
        "account": "ADI SLV 3 Do Not Touch",
        "uma": "ADI AGG Test",
        "accountId": "f93c82ff-7ad9-4316-b4c9-3888c876908e",
        "accountName": "ADI SLV 3 Do Not Touch",
        "marketValue": 833868.1550832,
        "allocationPercentage": 0.23439425280123247
      },
      {
        "family": "Harry And Marry House2",
        "trust": "Harry And Marry House1",
        "account": "Mumbai Indians",
        "uma": "ADI AGG Test",
        "accountId": "185b2367-64e2-4238-86b2-a271e7f20f68",
        "accountName": "Mumbai Indians",
        "marketValue": 100000,
        "allocationPercentage": 0.028109270197258653
      },
      {
        "family": "Harry And Marry House2",
        "trust": "Harry And Marry House1",
        "account": "UN-TAGGED",
        "uma": "ADI AGG Test",
        "accountId": "UN-TAGGED",
        "accountName": "UN-TAGGED",
        "marketValue": -1133606.484726,
        "allocationPercentage": -0.318648509765277
      }
    ]
  }
}

//! //////////////////////////// uma-market-value ////////////////////////////
export const aggregatePerformanceData = {
  "data": {
    "groupBy": [
      "family",
      "trust",
      "account",
      "uma"
    ],
    "data": [
      {
        "family": "CallansHouse",
        "trust": null,
        "account": "Drashti test ACL trading account",
        "uma": null,
        "accountId": "bd7bba7d-90ee-4c2b-be67-fc7a14e71641",
        "accountName": "Drashti test ACL trading account",
        "accountCd": "WORDR8022",
        "performanceStartDate": "2024-03-27",
        "1M": 0.0013826394826788047,
        "3M": 0.0013826394826788047,
        "1Y": 0.0013826394826788047,
        "3Y": 0.0013826394826788047,
        "YTD": 0.0013826394826788047
      },
      {
        "family": "CallansHouse",
        "trust": null,
        "account": "Michael Smolarski Stx Intl Dev",
        "uma": "Michael Smolarski",
        "accountId": "c3ea14c1-54ce-4a22-aa5d-8d75073e7fe9",
        "accountName": "Michael Smolarski Stx Intl Dev",
        "accountCd": "ARIMI8436",
        "performanceStartDate": "2024-05-08",
        "1M": 0.009312275366103586,
        "3M": 0.009781678024124685,
        "1Y": 0.009781678024124685,
        "3Y": 0.9781678024124685,
        "YTD": 0.9781678024124685
      },
      {
        "family": "CallansHouse",
        "trust": null,
        "account": "Michael Smolarski R3",
        "uma": "Michael Smolarski",
        "accountId": "5cd7f2d5-eca8-458c-b144-858dcecfebd8",
        "accountName": "Michael Smolarski R3",
        "accountCd": "ARIMI8170",
        "performanceStartDate": "2024-05-08",
        "1M": -0.0015781000000000267,
        "3M": 0.007033608382548184,
        "1Y": 0.007033608382548184,
        "3Y": 0.007033608382548184,
        "YTD": 0.007033608382548184
      },
      {
        "family": "CallansHouse",
        "trust": "CallansHouse1",
        "account": "Michael Smolarski R3",
        "uma": null,
        "accountId": "5cd7f2d5-eca8-458c-b144-858dcecfebd8",
        "accountName": "Michael Smolarski R3",
        "accountCd": "ARIMI8170",
        "performanceStartDate": "2023-12-01",
        "1M": -0.0015781000000000267,
        "3M": 0.007033608382548184,
        "1Y": 0.007033608382548184,
        "3Y": 0.007033608382548184,
        "YTD": 0.007033608382548184
      },
      {
        "family": "CallansHouse2",
        "trust": "CallansHouse1",
        "account": "Drashti test ACL trading account",
        "uma": "ADI AGG Test",
        "accountId": "bd7bba7d-90ee-4c2b-be67-fc7a14e71641",
        "accountCd": "WORDR8022",
        "accountName": "Drashti test ACL trading account",
        "performanceStartDate": "2024-01-01",
        "1M": 0.0013826394826788047,
        "3M": 0.0013826394826788047,
        "1Y": 0.0013826394826788047,
        "3Y": 0.0013826394826788047,
        "YTD": 0.0013826394826788047
      },
      {
        "family": "CallansHouse2",
        "trust": "CallansHouse1",
        "account": "ADI SLV1 Do Not Touch",
        "uma": "ADI AGG Test",
        "accountId": "e73d126e-4a83-4315-ae93-4e988f427291",
        "accountCd": "ARIAD6885",
        "accountName": "ADI SLV1 Do Not Touch",
        "performanceStartDate": "2024-01-01",
        "1M": 0,
        "3M": 0.1675318201369218,
        "1Y": -0.9703122232807263,
        "3Y": -0.9703122232807263,
        "YTD": -0.9703122232807263
      },
      {
        "family": "CallansHouse2",
        "trust": "CallansHouse1",
        "account": "ADI SLV2 Do Not Touch",
        "uma": "ADI AGG Test",
        "accountId": "787fd1db-5f93-4070-bf60-436227a80780",
        "accountCd": "ARIAD4202",
        "accountName": "ADI SLV2 Do Not Touch",
        "performanceStartDate": "2024-01-01",
        "1M": -0.002189108346741353,
        "3M": -0.909968425675301,
        "1Y": -0.990596286729519,
        "3Y": -0.990596286729519,
        "YTD": -0.990596286729519
      },
      {
        "family": "CallansHouse2",
        "trust": "CallansHouse1",
        "account": "ADI SLV 3 Do Not Touch",
        "uma": "ADI AGG Test",
        "accountId": "f93c82ff-7ad9-4316-b4c9-3888c876908e",
        "accountCd": "ARIAD4474",
        "accountName": "ADI SLV 3 Do Not Touch",
        "performanceStartDate": "2024-01-01",
        "1M": 0.00003460877068373058,
        "3M": 0.031744291298648974,
        "1Y": -0.6850447247383333,
        "3Y": -0.6850447247383333,
        "YTD": -0.6850447247383333
      },
      {
        "family": "CallansHouse2",
        "trust": "CallansHouse1",
        "account": "Mumbai Indians",
        "uma": "ADI AGG Test",
        "accountId": "185b2367-64e2-4238-86b2-a271e7f20f68",
        "accountCd": "WORMU7188",
        "accountName": "Mumbai Indians",
        "performanceStartDate": "2024-01-01",
        "1M": 0,
        "3M": 0,
        "1Y": 0,
        "3Y": 0,
        "YTD": 0
      },
      {
        "family": "CallansHouse3",
        "trust": "CallansHouse1",
        "account": "Asmita AGG Acc 1",
        "uma": null,
        "accountId": "b1144187-fce9-46d7-b436-a47257d89e67",
        "accountName": "Asmita AGG Acc 1",
        "accountCd": "ARIAS5047",
        "performanceStartDate": "2024-02-19",
        "1M": 0,
        "3M": 0,
        "1Y": 0.013334937920900014,
        "3Y": 0.013334937920900014,
        "YTD": 0.013334937920900014
      }
    ]
  }
}