import React, { useContext, useState } from 'react'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { Box, Divider, Grid, Tab } from '@mui/material'
import { methodologyContext } from '../Index'
import { Methodology } from './methodologyComponents/Methodology'

export const SubTabs = () => {
  const methodologyData = useContext(methodologyContext)
  const methodologySubTabs = []
  const methodologySubTabData = []

  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  let methodData = []
  for (const pageSequence in methodologyData) {
    methodologySubTabs.push(methodologyData[pageSequence].pageName)
    for (const section of methodologyData[pageSequence].sections) {
      methodData.push({ sectionName: section.sectionName, longText: section.longText })
    }
    methodologySubTabData.push(methodData)
    methodData = []
  }

  const renderTabPanel = (item) => {
    switch (item) {
      case 0:
        return <Methodology pageDetails={methodologySubTabData[item]} />
      case 1:
        return <Methodology pageDetails={methodologySubTabData[item]} />
      case 2:
        return <Methodology pageDetails={methodologySubTabData[item]} />
      case 3:
        return <Methodology pageDetails={methodologySubTabData[item]} />
      case 4:
        return <Methodology pageDetails={methodologySubTabData[item]} />
      case 5:
        return <Methodology pageDetails={methodologySubTabData[item]} />
      default:
        return 'Something went wrong'
    }
  }

  return (
    <Grid container xs={12} sm={12}>
      <Box
        sx={{
          flexGrow: 1,
          maxWidth: { xs: '100%' },
          bgcolor: 'background.paper'
        }}
      >
        <TabContext value={value}>
          <TabList
            variant='scrollable'
            scrollButtons={false}
            onChange={handleChange}
            aria-label='visible arrows tabs example'
            className='report-tab-header'
            TabIndicatorProps={{
              style: {
                backgroundColor: '#34475A'
              }
            }}
          >
            {
              methodologySubTabs?.map((item, index) => (
                <Tab className='reportCardNav' key={index} value={index} label={item} />
              ))
            }
          </TabList>
          <Divider
            sx={{
              bgcolor: 'rgba(116, 120, 141, 0.15)'
            }}
          />
          {
            methodologySubTabs?.map((item, index) => (
              <TabPanel key={index} value={index}>
                {renderTabPanel(index)}
              </TabPanel>
            ))
          }
        </TabContext>
      </Box>
    </Grid>
  )
}
