import MonitorHeartOutlinedIcon from '@mui/icons-material/MonitorHeartOutlined'
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined'
import IPSActiveIcon from '../assets/images/IPSActiveIcon.svg'
import IPSInactiveIcon from '../assets/images/IPSInactiveIcon.svg'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong'
import { ReactComponent as TradeIcon } from '../assets/images/trade-example-icon.svg'
import { ReactComponent as DashboardIcon } from '../assets/images/Dashboard.svg'
import { ReactComponent as StatementIcon } from '../assets/images/Statement.svg'
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined'
import PermIdentityIcon from '@mui/icons-material/PermIdentity'
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined'
import CoPresentOutlinedIcon from '@mui/icons-material/CoPresentOutlined'
import { BsJournalText, BsAward, BsPersonVcard, BsClipboard2Data, BsPeople, BsJournalBookmark, BsDatabaseFillGear, BsListTask, BsBarChartLine } from 'react-icons/bs'
import { BiTransferAlt } from 'react-icons/bi'
import { TbHierarchy2 } from 'react-icons/tb'

const getMenuConfig = (params) => [
  {
    name: 'Book of Business',
    icon: {
      component: BsJournalText,
      type: 'component'
    },
    path: '/book-of-business',
    moduleCd: 'book-of-business',
    hasSubMenu: false,
    id: 'book-of-business',
    roles: ['adv-classic', 'pm']
  },
  {
    name: 'All Requests',
    icon: {
      component: BsListTask,
      type: 'component'
    },
    path: '/all-requests',
    hasSubMenu: false,
    moduleCd: 'all-requests',
    id: 'all-request',
    roles: ['pm']
  },
  {
    name: 'Account Review',
    icon: {
      component: BsPersonVcard,
      type: 'component'
    },
    paths: [
      { path: `/account-review/account-dashboard/${params?.accountId || ''}`, name: 'Dashboard', dependentEntityKey: 'accountId', moduleCd: 'account-dashboard' },
      { path: `/account-review/risk-dashboard/${params?.accountId || ''}`, name: 'Risk', dependentEntityKey: 'accountId', moduleCd: 'risk-dashboard' },
      { path: `/account-review/request/${params?.accountId || ''}`, name: 'Request', dependentEntityKey: 'accountId', moduleCd: 'request' },
      { path: `/account-review/reports/${params?.accountId || ''}`, name: 'Report', dependentEntityKey: 'accountId', moduleCd: 'reports' },
      { path: `/account-review/personalization/${params?.accountId || ''}`, name: 'Personalization', dependentEntityKey: 'accountId', moduleCd: 'personalization' },
      { path: `/account-review/import-portfolio/${params?.accountId || ''}`, name: 'Portfolio', dependentEntityKey: 'accountId', moduleCd: 'import-portfolio' }
    ],
    hasSubMenu: true,
    moduleCd: 'account-review',
    id: 'review-menu-item',
    menuId: 'review-sub-menu',
    roles: ['adv-classic', 'pm', 'spn-pm']
  },
  {
    name: 'Model',
    icon: {
      component: BsClipboard2Data,
      type: 'component'
    },
    paths: [
      { path: '/model/strategy-portfolio', name: 'Strategy Portfolio', moduleCd: 'strategy-portfolio' },
      { path: '/model/port-spec', name: 'Port Spec', moduleCd: 'port-spec' },
      { path: '/model/model-changes', name: 'Model Changes', moduleCd: 'model-changes' }
    ],
    hasSubMenu: true,
    id: 'modal-menu-item',
    moduleCd: 'model',
    menuId: 'modal-sub-menu',
    roles: ['pm']
  },
  {
    name: 'Account Onboarding',
    icon: {
      component: BsPeople,
      type: 'component'
    },
    path: '/account-onboarding',
    hasSubMenu: false,
    moduleCd: 'account-onboarding',
    id: 'account-onboarding',
    roles: ['adv-classic']
  },
  {
    name: 'IPS',
    icon: {
      component: BsAward,
      type: 'component'
    },
    paths: [
      { path: '/ips/policy', name: 'Policy', moduleCd: 'policy' },
      { path: '/ips/monitoring', name: 'Monitoring', moduleCd: 'monitoring' },
      { path: '/ips/evidence', name: 'Evidence', moduleCd: 'evidence' },
      { path: '/ips/statement', name: 'Statement', moduleCd: 'statement' }
    ],
    hasSubMenu: true,
    id: 'ips-menu-item',
    menuId: 'ips-sub-menu',
    moduleCd: 'ips',
    roles: ['adv-classic', 'pm']
  },
  // {
  //   name: 'Resource Corner',
  //   icon: {
  //     component: BsJournalBookmark,
  //     type: 'component'
  //   },
  //   path: '.',
  //   hasSubMenu: false,
  //   id: 'resource-corner',
  //   roles: ['adv-classic']
  // },
  {
    name: 'Trade',
    icon: {
      component: BsBarChartLine,
      type: 'component'
    },
    paths: [
      { path: '/trade/trade-approvals', name: 'Trade Approvals', moduleCd: 'trade-approvals' },
      { path: '/trade/trade-blotter', name: 'Trade Blotter', moduleCd: 'trade-blotter' },
      { path: '/trade/trade-history', name: 'Trade History', moduleCd: 'trade-history' },
      { path: '/trade/trade-adhoc', name: 'Trade Adhoc', moduleCd: 'trade-adhoc' },
      { path: '/trade/mandatory-trades', name: 'Mandatory Trades', moduleCd: 'mandatory-trades' },
      { path: '/trade/trade-comparator', name: 'Trade Compare', moduleCd: 'trade-comparator' }
    ],
    hasSubMenu: true,
    moduleCd: 'trade',
    id: 'trade-menu-item',
    menuId: 'trade',
    roles: ['pm']
  },
  {
    name: 'Process Monitoring',
    icon: {
      active: MonitorHeartOutlinedIcon,
      inactive: MonitorHeartOutlinedIcon,
      type: 'svg'
    },
    path: '/process-monitoring',
    hasSubMenu: false,
    id: 'monitoring-menu-item',
    moduleCd: 'process-monitoring',
    menuId: 'monitoring-sub-menu',
    roles: ['pm']
  },
  {
    name: 'Aggregate',
    icon: {
      component: TbHierarchy2,
      type: 'component'
    },
    paths: [
      { path: '/aggregate/aggregate-dashboard', name: 'Dashboard', moduleCd: 'aggregate-dashboard' },
      { path: '/aggregate/entity-mapping', name: 'Service Map', moduleCd: 'entity-mapping' },
      { path: '/aggregate/taxlot-swapping', name: 'Taxlot Swapping', moduleCd: 'taxlot-swapping' }
    ],
    hasSubMenu: true,
    moduleCd: 'aggregate',
    id: 'aggregate-menu-item',
    menuId: 'aggregate',
    roles: ['pm']
  },
  {
    name: 'PDF Customizer',
    icon: {
      active: NoteAltOutlinedIcon,
      inactive: NoteAltOutlinedIcon,
      type: 'svg'
    },
    path: '/pdf-customizer',
    hasSubMenu: false,
    id: 'pdf-customizer-item',
    moduleCd: 'pdf-customizer',
    menuId: 'pdf-customizer-sub-menu',
    roles: ['pm']
  },
  {
    name: 'Account Migration',
    icon: {
      component: BiTransferAlt,
      type: 'component'
    },
    path: '/account-migration',
    moduleCd: 'account-migration',
    id: 'account-migration',
    roles: ['pm']
  },
  {
    name: 'Data Maintenance',
    icon: {
      component: BsDatabaseFillGear,
      type: 'component'
    },
    hasSubMenu: true,
    paths: [
      {
        name: 'Account Data',
        path: '/data-maintenance/data-maintenance-accounts',
        moduleCd: 'data-maintenance-accounts'
      },
      {
        name: 'Request',
        path: '/data-maintenance/data-maintenance-request',
        moduleCd: 'data-maintenance-request'
      },
      {
        name: 'Strategy',
        path: '/data-maintenance/data-maintenance-strategy',
        moduleCd: 'data-maintenance-strategy'
      },
      {
        name: 'DI Allocation',
        path: '/data-maintenance/data-maintenance-strategy-allocation',
        moduleCd: 'data-maintenance-strategy-allocation'
      },
      {
        name: 'Sponsor',
        path: '/data-maintenance/data-maintenance-sponsor',
        moduleCd: 'data-maintenance-sponsor'
      },
      {
        name: 'Report',
        path: '/data-maintenance/data-maintenance-report',
        moduleCd: 'data-maintenance-report'
      },
      {
        name: 'Instrument',
        path: '/data-maintenance/data-maintenance-instrument',
        moduleCd: 'data-maintenance-instrument'
      },
      {
        name: 'Master',
        path: '/data-maintenance/data-maintenance-master',
        moduleCd: 'data-maintenance-master'
      },
      {
        name: 'Optimization',
        path: '/data-maintenance/data-maintenance-optimization',
        moduleCd: 'data-maintenance-optimization'
      },
      {
        name: 'Trade',
        path: '/data-maintenance/data-maintenance-trade',
        moduleCd: 'data-maintenance-trade'
      }
    ],
    moduleCd: 'data-maintenance',
    id: 'data-maintenance-menu-item',
    menuId: 'data-maintenance-sub-menu',
    roles: ['pm']
  },
  {
    name: 'Admin',
    icon: {
      component: BsPersonVcard,
      type: 'component'
    },
    hasSubMenu: true,
    paths: [
      {
        name: 'Access Control',
        path: '/admin/access-control',
        moduleCd: 'access-control'
      }
    ],
    moduleCd: 'admin',
    id: 'admin-menu-item',
    menuId: 'admin-sub-menu',
    roles: ['admin']
  }
]

export const PAGE_LIST = [
  {
    name: 'Book of Business',
    path: '/book-of-business'
  },
  {
    name: 'All Requests',
    path: '/all-requests'
  },
  {
    path: '/account-review/account-dashboard/:accountId',
    name: 'Dashboard',
    example: {
      type: 'View dashboard for user',
      icon: CoPresentOutlinedIcon,
      priority: 4
    },
    dependentEntityKey: 'accountId'
  },
  {
    path: '/account-review/risk-dashboard/:accountId',
    name: 'Risk',
    dependentEntityKey: 'accountId'
  },
  {
    path: '/account-review/request/:accountId',
    name: 'Request',
    dependentEntityKey: 'accountId'
  },
  {
    path: '/account-review/reports/:accountId',
    name: 'Report',
    example: {
      type: 'View report for user',
      icon: AnalyticsOutlinedIcon,
      priority: 1
    },
    dependentEntityKey: 'accountId'
  },
  {
    path: '/account-review/personalization/:accountId',
    name: 'Personalization',
    dependentEntityKey: 'accountId'
  },
  {
    path: '/account-review/import-portfolio/:accountId',
    name: 'Portfolio',
    dependentEntityKey: 'accountId'
  },
  {
    path: '/model/strategy-portfolio',
    name: 'Strategy Portfolio'
  },
  {
    path: '/model/port-spec',
    name: 'Portfolio Specification'
  },
  {
    path: '/model/model-changes',
    name: 'Model Changes'
  },
  {
    name: 'Account Onboarding',
    path: '/account-onboarding',
    example: {
      type: 'Create client account',
      icon: PermIdentityIcon,
      priority: 2
    }
  },
  {
    path: '/ips/policy',
    name: 'Policy',
    example: {
      type: 'View policy',
      icon: ReceiptLongIcon,
      priority: 5
    },
    dependentEntityKey: 'sponsorId'
  },
  {
    path: '/ips/monitoring',
    name: 'Monitoring',
    example: {
      type: 'View monitoring',
      icon: MonitorHeartOutlinedIcon,
      priority: 6
    },
    dependentEntityKey: 'sponsorId'
  },
  {
    path: '/ips/evidence',
    name: 'Evidence',
    dependentEntityKey: 'sponsorId'
  },
  {
    path: '/ips/statement',
    name: 'Statement',
    example: {
      type: 'View statement',
      icon: FeedOutlinedIcon,
      priority: 7
    },
    dependentEntityKey: 'sponsorId'
  },
  {
    name: 'Account Maintenance',
    path: '/data-maintenance/data-maintenance-accounts'
  },
  {
    name: 'Request Maintenance',
    path: '/data-maintenance/data-maintenance-request'
  },
  {
    path: '/data-maintenance/data-maintenance-strategy',
    name: 'Strategy Maintenance'
  },
  {
    path: '/data-maintenance/data-maintenance-strategy-allocation',
    name: 'DI Allocation Maintenance'
  },
  {
    name: 'Sponsor Maintenance',
    path: '/data-maintenance/data-maintenance-sponsor'
  },
  {
    name: 'Report Maintenance',
    path: '/data-maintenance/data-maintenance-report'
  },
  {
    name: 'Instrument Maintenance',
    path: '/data-maintenance/data-maintenance-instrument'
  },
  {
    name: 'Master Maintenance',
    path: '/data-maintenance/data-maintenance-master'
  },
  {
    name: 'Optimization Maintenance',
    path: '/data-maintenance/data-maintenance-optimization'
  },
  {
    name: 'Trade Maintenance',
    path: '/data-maintenance/data-maintenance-trade'
  },
  {
    path: '/trade/trade-approvals',
    name: 'Trade Approvals',
    example: {
      type: 'Go to trades',
      icon: TradeIcon,
      priority: 3
    }
  },
  {
    path: '/trade/trade-blotter',
    name: 'Trade Blotter'
  },
  {
    path: '/trade/trade-history',
    name: 'Trade History'
  },
  {
    path: '/trade/trade-adhoc',
    name: 'Trade Adhoc'
  },
  {
    path: '/trade/mandatory-trades',
    name: 'Mandatory Trades'
  },
  {
    path: '/trade/trade-comparator',
    name: 'Trade Compare'
  },
  {
    name: 'Process Monitoring',
    path: '/process-monitoring'
  },
  {
    name: 'Service Map',
    path: '/aggregate/entity-mapping'
  },
  {
    name: 'Aggregate Dashboard',
    path: '/aggregate/aggregate-dashboard'
  },
  {
    name: 'PDF Customizer',
    path: '/pdf-customizer'
  },
  {
    name: 'Taxlot Swapping',
    path: '/aggregate/taxlot-swapping'
  },
  {
    name: 'Account Migration',
    path: '/account-migration'
  }
]

export default getMenuConfig
