import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addChartValues } from '../../../../store/pdf-customizer-reducer/Action/chartDropAction'
import {
  factorAllocationApi,
  marketCapAllocationApi,
  sectorWiseRiskApi,
  securityWiseRiskApi,
  topPositionSecurityApi
} from '../../../../utils/pdf-customizer/_data'
import { useAuth } from '../../../../contexts/AuthContext'
import {
  dataTableData,
  decTwoPercentage,
  fetchDataAndDispatch,
  isNumeric
} from '../APIResponseConverts/APIResponseConverts'
import {
  factorChart,
  marketCapChart,
  sectorWiseRisk,
  securityWiseRisk,
  topPositionSecurity
} from '../APIResponseConverts/chartDefaultsData'
import './dataTablePrimary.css'

const DataTablePrimary = ({
  data,
  chartIndex,
  index,
  chartHeight,
  chartWidth,
  clickEvent
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isDragging, setIsDragging] = useState(false)

  const {
    topPositionSecurityResponse,
    isResponseElements,
    securityWiseRiskResponse,
    marketCapChartResponse,
    sectorWiseRiskResponse,
    factorChartResponse
  } = useSelector((state) => state.chartState)

  const templateData = useSelector(state => state.templateData)
  const { user } = useAuth()

  const dispatch = useDispatch()

  useEffect(() => {
    if (
      data?.name === 'Top Positions' &&
      isResponseElements &&
      !topPositionSecurityResponse && !isLoading
    ) {
      setIsLoading(true)
      fetchDataAndDispatch(() => topPositionSecurityApi(templateData, user?.userGroup), 'TOP_POSITION_SECURITY', dispatch)
    }
    if (
      data?.name === 'Risk By Sector' &&
      isResponseElements &&
      !sectorWiseRiskResponse && !isLoading
    ) {
      setIsLoading(true)
      fetchDataAndDispatch(() => sectorWiseRiskApi(templateData, user?.userGroup), 'SECTOR_WISE_RISK', dispatch)
    }
    if (
      data?.name === 'Risk By Security' &&
      isResponseElements &&
      !securityWiseRiskResponse && !isLoading
    ) {
      setIsLoading(true)
      fetchDataAndDispatch(() => securityWiseRiskApi(templateData, user?.userGroup), 'SECURITY_WISE_RISK', dispatch)
    }
    if (
      data?.name === 'Market Cap table' &&
      isResponseElements &&
      !marketCapChartResponse && !isLoading
    ) {
      setIsLoading(true)
      fetchDataAndDispatch(() => marketCapAllocationApi(templateData, user?.userGroup), 'MARKET_CHART', dispatch)
    }
    if (
      data?.name === 'Factor table' &&
      isResponseElements &&
      !factorChartResponse && !isLoading
    ) {
      setIsLoading(true)
      fetchDataAndDispatch(() => factorAllocationApi(templateData, user?.userGroup), 'FACTOR_CHART', dispatch)
    }
    if (data?.name === 'Top Positions' && isResponseElements && topPositionSecurityResponse) {
      setIsLoading(false)
    }
    if (data?.name === 'Risk By Sector' && isResponseElements && sectorWiseRiskResponse) {
      setIsLoading(false)
    }
    if (data?.name === 'Risk By Security' && isResponseElements && securityWiseRiskResponse) {
      setIsLoading(false)
    }
    if (data?.name === 'Market Cap table' && isResponseElements && marketCapChartResponse) {
      setIsLoading(false)
    }
    if (data?.name === 'Factor table' && isResponseElements && factorChartResponse) {
      setIsLoading(false)
    }
  }, [
    isResponseElements,
    topPositionSecurityResponse,
    // templateData,
    dispatch,
    // data,
    securityWiseRiskResponse,
    sectorWiseRiskResponse,
    marketCapChartResponse,
    factorChartResponse
  ])

  const getDataTable = (name) => {
    switch (name) {
      case 'Top Positions':
        return topPositionSecurityResponse || topPositionSecurity
      case 'Risk By Sector':
        return sectorWiseRiskResponse || sectorWiseRisk
      case 'Risk By Security':
        return securityWiseRiskResponse || securityWiseRisk
      case 'Market Cap table':
        return marketCapChartResponse || marketCapChart
      case 'Factor table':
        return factorChartResponse || factorChart
      default:
        return null
    }
  }

  const result = getDataTable(data.name) || []
  const { datasets = [] } = data?.value || {}
  const { headingColumns, tableRows, tableWidth, tableTextAlign } = dataTableData(
    result,
    data.name
  )

  for (let i = 0; i < tableRows.length; i++) {
    tableRows[i] = { ...tableRows[i], index: i + 1 }
  }

  // storing column keys
  const processedColumns = useMemo(() => {
    return Array.isArray(headingColumns)
      ? headingColumns
      : Object.keys(headingColumns) || []
  }, [headingColumns])

  useEffect(() => {
    if (data.value || !processedColumns.length) return

    const mappedDatasets = processedColumns.map((label) => ({
      label: label?.field ? label.field : label,
      hidden: false,
      backgroundColor: 'rgba(255, 255, 255  , 1)',
      labelText: headingColumns[label?.field ? label.field : label] || (label?.field ? label.field : label)
    }))

    const chartValues = {
      labels: [],
      datasets: mappedDatasets,
      chartIndex: data?.chartIndex,
      pageIndex: data?.pageIndex
    }

    dispatch(addChartValues(chartValues))
  }, [processedColumns, dispatch, data])
  const colWidth = 100 / Object.keys(headingColumns).length

  return (
    <div
      className={'group'}
    >
      <p style={{ margin: '0', fontSize: '10px', fontWeight: 500, textTransform: 'capitalize' }}>
        {data.headingText || data.name}
      </p>
      {isLoading
        ? <span className='loading-text'>Loading...</span>
        : <div className='table-container-primary'>
          <div className='table-border'>
            <div className=''>
              {tableRows.length
                ? (
                  <table className='width-100' style={{ borderCollapse: 'collapse' }}>
                    <thead>
                      <tr
                        className='headingBorder' style={{
                          // backgroundImage: 'linear-gradient(to bottom, #FFFFFF, #FFFFFF 50%, #E9E9E9 50% 100%)',
                          // backgroundPosition: '0 100%',
                          // backgroundRepeat: 'no-repeat',
                          // backgroundSize: '100% 1px'
                        }}
                      >
                        {Array.isArray(datasets) &&
                          datasets.map(
                            (columnKey, columnIndex) =>
                              !columnKey.hidden && (
                                <th
                                  className={`text-header width-${tableWidth ? tableWidth[columnIndex] : colWidth
                                    } ${tableTextAlign
                                      ? tableTextAlign[columnIndex]
                                      : 'text-align-center'
                                    }`}
                                  key={columnIndex}
                                >
                                  {columnKey?.label !== 'noNameShow' &&
                                    columnKey?.label
                                    ? (headingColumns[columnKey.label] || columnKey.label)
                                      .toString()
                                      // .toLowerCase()
                                      .replace(/_/g, ' ')
                                      .split(' ')
                                      .map(
                                        (word) =>
                                          word.charAt(0).toUpperCase() +
                                          word.slice(1)
                                      )
                                      .join(' ')
                                    : ''}
                                </th>
                              )
                          )}
                      </tr>
                    </thead>
                    <tbody className='table-body'>
                      {tableRows.map((row, rowIndex) => (
                        <tr
                          className='borderBottom'
                          key={rowIndex}
                        >
                          {Array.isArray(datasets) &&
                            datasets.map(
                              (columnKey, columnIndex) =>
                                !columnKey.hidden && (
                                  <td
                                    key={columnIndex}
                                    className={`${tableTextAlign
                                      ? tableTextAlign[columnIndex]
                                      : 'text-align-center'
                                      }`}
                                    style={{
                                      // background: columnKey.backgroundColor,
                                      // backgroundImage: 'linear-gradient(to bottom, #FFFFFF, #FFFFFF 50%, #E9E9E9 50% 100%)',
                                      // backgroundPosition: '0 100%',
                                      // backgroundRepeat: 'no-repeat',
                                      // backgroundSize: '100% 1px'
                                    }}
                                  >
                                    <div style={{ background: columnKey.backgroundColor }}>
                                      {columnKey?.label === 'riskColor'
                                        ? (
                                          <>
                                            <div className='risk-color'>
                                              {[
                                                ...Array(
                                                  row[columnKey?.label] === 'R'
                                                    ? 3
                                                    : row[columnKey?.label] === 'Y'
                                                      ? 2
                                                      : 1
                                                )
                                              ].map((_, index) => (
                                                <div
                                                  key={index}
                                                  className={
                                                    row[columnKey?.label] === 'R'
                                                      ? 'r'
                                                      : row[columnKey?.label] === 'Y'
                                                        ? 'y'
                                                        : 'g'
                                                  }
                                                />
                                              ))}
                                              {row[columnKey?.label] !== 'Y' &&
                                                row[columnKey?.label] !== 'R' && (
                                                  <div className='n' />
                                                )}
                                              {row[columnKey?.label] !== 'R' && (
                                                <div className='n' />
                                              )}
                                            </div>
                                          </>
                                        )
                                        : isNumeric(row[columnKey?.label] * 1)
                                          ? (
                                            data.name === 'Market Cap table'
                                              ? (
                                                `${decTwoPercentage(
                                                  row[columnKey?.label] * 1,
                                                  2
                                                )}%`
                                              )
                                              : (
                                                decTwoPercentage(
                                                  row[columnKey?.label] * 1,
                                                  2
                                                )
                                              )
                                          )
                                          : (
                                            row[columnKey?.label] ||
                                            decTwoPercentage(0, 2)
                                          )}
                                    </div>
                                  </td>
                                )
                            )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )
                : (
                  <p>No data available</p>
                )}
            </div>
          </div>
        </div>}
    </div>
  )
}

export default DataTablePrimary
