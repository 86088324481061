import { useContext } from "react"
import { createContext } from "react"

export const arisPMDataContext = createContext()
export const optimizerDataContext = createContext()
export const sponsorPMDataContext = createContext()


export const useArisPMDataContext = () => useContext(arisPMDataContext)
export const useOptimizerDataContext = () => useContext(optimizerDataContext)
export const useSponsorPMDataContext = () => useContext(sponsorPMDataContext)
