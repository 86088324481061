import { API } from 'aws-amplify'

export const CreateAPI = async (baseUrl, endPoint, payload) => {
  try {
    const response = await API.post(
      baseUrl,
      endPoint,
      { body: { ...payload } }
    )
    return response
  } catch (error) {
    return { error: error.response?.data?.errorInfo?.userMessage || error.message }
  }
}

export const UpdateAPI = async (baseUrl, endPoint, payload) => {
  try {
    const response = await API.patch(
      baseUrl,
      endPoint,
      { body: { ...payload } }
    )
    return response
  } catch (error) {
    return { error: error.response?.data?.errorInfo?.userMessage || error.message }
  }
}

export const DeleteAPI = async (baseUrl, endPoint) => {
  try {
    const response = await API.del(
      baseUrl,
      endPoint,
      {}
    )
    return response
  } catch (error) {
    return { error: error.response?.data?.errorInfo?.userMessage || error.message }
  }
}

export const GetAPI = async (baseUrl, endPoint, queryStringParameters) => {
  try {
    const response = await API.get(
      baseUrl,
      endPoint,
      { ...(queryStringParameters ? { queryStringParameters } : {}) } // pass query string params optionally
    )
    return response
  } catch (error) {
    return { error: error.response?.data?.errorInfo?.userMessage || error.message }
  }
}
