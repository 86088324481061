import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Bar } from 'react-chartjs-2'
import { Chart as ChartJS, registerables } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import './topSecurities.css'
import { FactorTableOptions } from '../../common/ChartOptions/options'
import { securityByWeight } from '../APIResponseConverts/chartDefaultsData'
import { fetchDataAndDispatch } from '../APIResponseConverts/APIResponseConverts'
import { topSecurityApi } from '../../../../utils/pdf-customizer/_data'
import { useAuth } from '../../../../contexts/AuthContext'

ChartJS.register(...registerables)

const TopSecurities = ({
  data,
  chartIndex,
  index,
  chartHeight,
  clickEvent,
}) => {
  const dispatch = useDispatch()
  const { securityByWeightResponse, isResponseElements } = useSelector(
    ({ chartState }) => chartState
  )
  const templateData = useSelector(state => state.templateData)
  // const { isLoading } = useSelector((state) => state.allPageStyle)
  const [isLoading, setIsLoading] = useState(false)

  const chartRef = useRef(null)
  const [chartSize, setChartSize] = useState(60)
  const {user}=useAuth()

  useEffect(() => {
    if(isResponseElements && !securityByWeightResponse && !isLoading) {
      setIsLoading(true)
      fetchDataAndDispatch(() => topSecurityApi(templateData, user?.userGroup), "SECURITY_BY_WEIGHT", dispatch)
    }
    if(isResponseElements && securityByWeightResponse) {
      setIsLoading(false)
    }
  }, [isResponseElements, securityByWeightResponse, dispatch])

  const topSecuritiesData = securityByWeightResponse?.data || securityByWeight?.data
  const { overWeight: overWeightSecurities, underweight: underWeightSecurities } =
    topSecuritiesData || {
      overWeightSecurities: [],
      underWeightSecurities: [],
    }
  useEffect(() => {
    if (chartRef?.current) {
      setChartSize(chartRef?.current?.height)
    }
  }, [chartRef])

  // const renderBarChart = (label, data, backgroundColor, value) => (
  //   <Bar
  //     ref={chartRef}
  //     style={chartStyle}
  //     data={{
  //       labels: topSecuritiesData?.[label].map(
  //         (_, index) => `Label ${index}`
  //       ),
  //       datasets: [
  //         {
  //           label,
  //           backgroundColor,
  //           data,
  //         },
  //       ],
  //     }}
  //     plugins={[ChartDataLabels]}
  //     options={FactorTableOptions(value === 'negative' ? 'start' : 'end')}
  //   />
  // )

  const renderSecurityIcons = (securities, isOverWeight) =>
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', height: '100%' }}>
      {securities.map((items, i) => (
        <div style={{ padding: '1px 4px', color: '#2F2F2F', fontWeight: 400 }}>
          <div className="show-icon-factor">
            <div className="show-icon-top-securities-table" key={items?.localSymbol}>
              <p className="">{items?.localSymbol}</p>
            </div>
          </div>
        </div>
      ))}
    </div>

  const renderTableRow = (label, securities, isOverWeight) => (
    <tr>
      <td
        className="table-border"
        style={{
          padding: '1px 4px',
          color: '#2F2F2F',
          fontWeight: 400,
          position: 'relative',
        }}
      >
        <p className='text-vertical'>
          {isOverWeight ? 'Overweight' : 'Underweight'}
        </p>
      </td>
      <td
        style={{
          padding: '1px 4px',
          color: '#2F2F2F',
          fontWeight: 400,
          height: `${chartSize}px`,
          borderRight: '1px solid #ADC3DB'
        }}
      >
        {renderSecurityIcons(securities, isOverWeight)}
      </td>
      <td
        style={{
          padding: '1px 4px',
          color: '#2F2F2F',
          fontWeight: 400,
          height: `${chartSize}px`,
          borderRight: '1px solid #ADC3DB'
        }}
      >
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-around', height: '100%'}}>
          {securities.map((items, i) => (
            <div style={{ padding: '1px 4px', color: '#2F2F2F', fontWeight: 400 }}>
              <div className="show-icon-factor">
                <div className="show-icon-top-securities-table" style={{}} key={items?.weightDrift}>
                  <p style={{ textAlign: 'end' }}>{typeof items?.weightDrift !== 'undefined' ? (items?.weightDrift * 100)?.toFixed(2) : ''}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </td>
      <td 
        style={{
          padding: '1px 4px',
          color: '#2F2F2F',
          fontWeight: 400,
          height: `${chartSize}px`,
        }}>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', height: '100%' }}>
            {securities.map((items, i) => (
              <div style={{ padding: '1px 4px', color: '#2F2F2F', fontWeight: 400 }}>
                <div className="show-icon-factor">
                  <div className="show-icon-top-securities-table" key={items?.weight}>
                    <p style={{ textAlign: 'end' }}>{typeof items?.weight !== 'undefined' ? (items?.weight * 100)?.toFixed(2) : ''}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
      </td>
    </tr>
  )

  return (
    <div
      className={'group'}
      key={data?.chartIndex + data?.pageIndex}
      style={{
        height: '100%',
        width: '100%',
      }}
    >
      <p style={{ margin: '0', fontSize: '10px', fontWeight: 600 }}>
        {data.hedgingText || data.name}
      </p>

      {isLoading
        ? <span className='loading-text'>Loading...</span>
        : <div className={'margin-top-2 chart-border'}>
          <table
            className="top-securities-table"
            style={{ borderCollapse: 'collapse' }}
          >
            <thead>
              <tr>
                <th
                  className="top-securities-table text-header width-4"
                  style={{ textAlign: 'left', width: 'auto' }}
                ></th>
                <th
                  className="top-securities-table text-header width-20"
                  style={{ textAlign: 'left', borderRight: '1px solid #ADC3DB' }}
                >
                  Security
                </th>
                <th
                  className="top-securities-table text-header width-38"
                  style={{ textAlign: 'right', borderRight: '1px solid #ADC3DB' }}
                >
                  Drift(%)
                </th>
                <th
                  className="top-securities-table text-header width-38"
                  style={{ textAlign: 'right', borderRight: '1px solid #ADC3DB' }}
                >
                  Weight(%)
                </th>
              </tr>
            </thead>
            <tbody className='table-body'>
              {renderTableRow('overWeightSecurities', overWeightSecurities, true)}
              {renderTableRow(
                'underWeightSecurities',
                underWeightSecurities,
                false
              )}
            </tbody>
          </table>
        </div>
      }
    </div>
  )
}

export default TopSecurities
