import React, { useEffect, useState, useMemo } from 'react'
import { Box } from '@mui/material'
import './element-panel.css'
import { useDispatch, useSelector } from 'react-redux'
import {
  onDeleteChart,
  chartEditorName
} from '../../../store/pdf-customizer-reducer/Action/chartDropAction.js'
import { changeColor, changePadding, fontChange } from '../../../store/pdf-customizer-reducer/Action/allPageAction.js'
import { ReactComponent as DeleteIcon } from '../../../assets/images/pdf-customizer/svg/delete.svg'
import { ReactComponent as EditIcon } from '../../../assets/images/pdf-customizer/svg/Edit.svg'
import ChartLabelEditor from './ChartLabelEditor.js'
import ColorPicker from './ColorPicker.js'
import { chartComponentMap } from './editElementsMapping.js'

const CustomizationPanel = () => {
  const dispatch = useDispatch()
  const { clickEvent, pages, pageHeadingFooter, activeElement } = useSelector((state) => state.elementStates)
  const { color, fontFamily, padding } = useSelector((state) => state.allPageStyle)
  const [activeChart, setActiveChart] = useState(null)
  const [isEditingChartLabel, setIsEditingChartLabel] = useState(false)

  useEffect(() => {
    if (pages?.length) {
      if (activeElement === 'headingElement' || activeElement === 'footerElement') {
        setActiveChart(pageHeadingFooter[activeElement] || null)
      } else if (activeElement === null) {
        setActiveChart(null)
      } else {
        const activeChart = pages
          .flatMap((page) => page.elements)
          .find((element) => element.id === activeElement)
        setActiveChart(activeChart || null)
      }
    }
  }, [activeElement, pages])

  const handleColorChange = (newColor) => {
    dispatch(changeColor(newColor))
  }

  const handleFontChange = (newFont) => {
    dispatch(fontChange(newFont))
  }

  const handlePaddingChange = (newPadding) => {
    dispatch(changePadding(newPadding))
  }

  const handleSaveChartLabel = (newLabel) => {
    setIsEditingChartLabel(false)
    dispatch(chartEditorName({ label: newLabel, activeChart }))
  }

  const handleDeleteChart = () => {
    if (activeChart) {
      dispatch(onDeleteChart({ activeChart }))
    }
  }

  const renderChartLabels = useMemo(() => {
    if (activeChart && activeChart.name && pages.length) {
      const { headingText, name } = activeChart
      return (
        <div className='chart-label-container flexOrAlignOrBetWeen'>
          {isEditingChartLabel
            ? (
              <ChartLabelEditor
                headingText={headingText}
                onSave={handleSaveChartLabel}
                onCancel={() => setIsEditingChartLabel(false)}
              />
            )
            : (
              <>
                <label className='chart-label'>{headingText || name}</label>

                <div className='showInRow'>
                  {activeChart?.isEditTitle && (
                    <div
                      className='edit-button margin-x-1 '
                      onClick={() => setIsEditingChartLabel(true)}
                    >
                      <EditIcon />
                    </div>
                  )}

                  <div
                    className='delete-button margin-x-1'
                    onClick={handleDeleteChart}
                  >
                    <DeleteIcon />
                  </div>
                </div>
              </>
            )}
        </div>
      )
    }
    return null
  }, [activeChart, isEditingChartLabel, dispatch, pages])

  const renderEditComponent = useMemo(() => {
    // if (clickEvent > 1) {
    if (activeChart && activeChart.name) {
      const EditComponent = chartComponentMap[activeChart.name]

      return EditComponent
        ? (
          <EditComponent activeChart={activeChart} />
        )
        : null
    }
    // }
    return null
  }, [activeChart])

  return (
    <div className='sidebar-right padding-3'>
      {
        activeChart?.canOverflow
          ? <>
            <Box sx={{
              width: '100%',
              '& .chart-label-container': {
                margin: 0
              }
            }}
            >
              {renderChartLabels}
              {renderEditComponent}
            </Box>
          </>
          : <>
            <h2 className='text-align-start width-100 right_side_title margin-top-2'>
              Theme Customization
            </h2>
            <div className='color-picker check-box-spacing'>
              {/* //! Color Picker and Font Selector */}
              <ColorPicker
                color={color}
                onColorChange={handleColorChange}
                font={fontFamily}
                onFontChange={handleFontChange}
                padding={padding}
                onPaddingChange={handlePaddingChange}
              />

              {/* //!  Dynamic Chart Labels */}
              {renderChartLabels}
              {/* //! Customization Option */}
              {renderEditComponent}
            </div>
          </>
      }
    </div>
  )
}

export default CustomizationPanel
