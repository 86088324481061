import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { persistor, store } from './store' // Import your Redux store and persistor
import * as Sentry from '@sentry/react'
import { HttpClient as HttpClientIntegration } from '@sentry/integrations'
import { PersistGate } from 'redux-persist/integration/react'

// sentry integration
Sentry.init({
  environment: (process.env.REACT_APP_ENV_NAME || 'development'),
  dsn: (process.env.REACT_APP_SENTRY_DSN_URL || ''),
  integrations: [new Sentry.BrowserTracing(), new HttpClientIntegration()],
  beforeSend: (event, hint) => {
    const accId = event.request.url.match(/([a-f\d]+(-[a-f\d]+){3}-[a-f\d]+)/i)
    if (accId && accId.length > 0) {
      if (event.request && event.request.url) {
        event.request.url = event.request?.url?.replaceAll(/([a-f\d]+(-[a-f\d]+){3}-[a-f\d]+)/ig, '*****-****-****-****')
      }
      if (event.request && event.request.headers && event.request?.headers?.Referer) {
        event.request.headers.Referer = event.request?.headers?.Referer?.replaceAll(/([a-f\d]+(-[a-f\d]+){3}-[a-f\d]+)/ig, '*****-****-****-****')
      }
      if (event.tags && event.tags.transaction) {
        delete event.tags.transaction
      }
      if (event.transaction) {
        delete event.transaction
      }
      if (event.breadcrumbs && event.breadcrumbs.length > 0) {
        event.breadcrumbs = event.breadcrumbs?.map((crumb) => {
          if (crumb.type === 'http') {
            crumb.data.url = crumb.data?.url?.replaceAll(/([a-f\d]+(-[a-f\d]+){3}-[a-f\d]+)/ig, '*****-****-****-****')
          }
          if (crumb.category === 'navigation' && crumb.data && crumb.data.from) {
            crumb.data.from = crumb.data?.from?.replaceAll(/([a-f\d]+(-[a-f\d]+){3}-[a-f\d]+)/ig, '*****-****-****-****')
            crumb.data.to = crumb.data?.to?.replaceAll(/([a-f\d]+(-[a-f\d]+){3}-[a-f\d]+)/ig, '*****-****-****-****')
          }
          return crumb
        })
      }
    }
    return event
  }
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
