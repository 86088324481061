// Document status timeline steps
export const TIMELINE_STEPS = [
  {
    id: 1,
    label: 'Submitted by sponsor admin',
    statusCd: 'SUBMITTED'
  },
  {
    id: 2,
    label: 'Downloaded document by sponsor admin',
    statusCd: 'PENDING_ADMIN_DOWNLOAD'
  },
  {
    id: 3,
    label: 'Uploaded signed document by sponsor admin',
    statusCd: 'PENDING_ADMIN_SIGNATURE'
  },
  {
    id: 4,
    label: 'Waiting for review from Aris PM',
    statusCd: 'REVIEW'
  },
  {
    id: 5,
    label: 'Approved by Aris PM',
    statusCd: 'ACTIVE'
  }
]
