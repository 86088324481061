import React, { useState } from 'react'
import { Button, IconButton, Menu, MenuItem } from '@mui/material'
import { MoreVert } from '@mui/icons-material'
import { BsArrowCounterclockwise, BsClipboard2Data, BsPersonVcard, BsTrash } from 'react-icons/bs'
import { TbHandClick } from 'react-icons/tb'
import dayjs from 'dayjs'
import WithdrawIcon from '../../../assets/images/withdraw-splits-icon.png'

const CustomActionButton = ({ params, handleShowModal, name }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [activeRow, setActiveRow] = useState(null)

  const checkApplySplitsVisible = (params) => {
    if (dayjs(params?.row?.effectiveDate.split('T')[0]).isSame(dayjs(), 'day')) {
      if ((params?.row?.status === 'APPLIED' || params?.row?.status === 'NOT APPLICABLE') && params?.row?.priceSplitsStatus === 'APPLIED')
        {return false}
      return true
    }
    return false
  }

  const checkWithdrawSplitsVisible = (params) => {
    if (dayjs(params?.row?.effectiveDate?.split('T')[0]).isSame(dayjs(), 'day')) {
      if ((params?.row?.status === 'PENDING' || params?.row?.status === 'NOT APPLICABLE') && params?.row?.priceSplitsStatus === 'PENDING')
        {return false}
      return true
    }
    return false
  }

  return (
    <>
      <IconButton
        aria-haspopup='true'
        disabled={dayjs(params?.row?.effectiveDate?.split('T')[0]).isBefore(dayjs(), 'day') || !params?.row?.isActive}
        onClick={(event) => {
          setAnchorEl(event.currentTarget)
          setActiveRow(params.row)
        }}
      >
        <MoreVert fontSize='small' />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl) || activeRow?.id === params?.row?.id}
        onClose={() => {
          setActiveRow(null)
          setAnchorEl(null)
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        sx={{
          padding: 0,
          '.MuiMenuItem-root': {
            padding: '0 10px'
          },
          '.MuiButtonBase-root': {
            justifyContent: 'flex-start',
            textTransform: 'capitalize'
          }
        }}
      >
        <MenuItem>
          <Button
            fullWidth
            variant='text'
            sx={{
              color: 'rgba(0, 0, 0, 0.87)'
            }}
            onClick={() => handleShowModal('Accounts', params.row)}
            startIcon={
              <BsPersonVcard
                sx={{ fontSize: '8px' }}
              />
            }
          >
            Accounts
          </Button>
        </MenuItem>
        {name !== 'splits'
          ? [
            <MenuItem key={2}>
              <Button
                fullWidth
                variant='text'
                sx={{
                  color: 'rgba(0, 0, 0, 0.87)'
                }}
                onClick={() => handleShowModal('Delete Delist', params.row)}
                startIcon={
                  <BsTrash />
                }
              >
                Delete Delist
              </Button>
            </MenuItem>,
            params.row.status === 'PENDING' || params.row.status === 'PARTIALLY COMPLETED'
              ? <MenuItem key={4}>
                <Button
                  fullWidth
                  variant='text'
                  sx={{
                    color: 'rgba(0, 0, 0, 0.87)'
                  }}
                  onClick={() => handleShowModal('Apply DNH', params.row)}
                  startIcon={
                    <TbHandClick />
                  }
                >
                  Apply DNH
                </Button>
                </MenuItem>
              : null,
            params.row.status !== 'PENDING' && params.row.status !== 'NOT APPLICABLE'
              ? <MenuItem key={3}>
                <Button
                  fullWidth
                  variant='text'
                  sx={{
                    color: 'rgba(0, 0, 0, 0.87)'
                  }}
                  onClick={() => handleShowModal('Remove DNH', params.row)}
                  startIcon={
                    <BsArrowCounterclockwise />
                  }
                >
                  Remove DNH
                </Button>
                </MenuItem>
              : null
            ]
          : [
            <MenuItem key={4}>
              <Button
                fullWidth
                variant='text'
                sx={{
                  color: 'rgba(0, 0, 0, 0.87)'
                }}
                onClick={() => handleShowModal('Delete Record', params.row)}
                startIcon={
                  <BsTrash />
                }
              >
                Delete Record
              </Button>
            </MenuItem>,
            checkApplySplitsVisible(params)
              ? <MenuItem key={5}>
                <Button
                  fullWidth
                  variant='text'
                  sx={{
                    color: 'rgba(0, 0, 0, 0.87)'
                  }}
                  onClick={() => handleShowModal('Apply Split', params.row)}
                  startIcon={
                    <TbHandClick />
                  }
                >
                  Apply Split
                </Button>
                </MenuItem>
              : null,
            checkWithdrawSplitsVisible(params)
              ? <MenuItem key={6}>
                <Button
                  fullWidth
                  variant='text'
                  sx={{
                    color: 'rgba(0, 0, 0, 0.87)'
                  }}
                  onClick={() => handleShowModal('Withdraw Split', params.row)}
                  startIcon={
                    <img src={WithdrawIcon} height="20px" width="20px" />
                  }
                >
                  Withdraw Split
                </Button>
                </MenuItem>
              : null
            ]}
      </Menu>
    </>
  )
}

export default CustomActionButton
