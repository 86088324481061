import React, { useContext } from 'react'
import Box from '@mui/material/Box'
import { accountDataContext } from '../Index'
import { LineChartPerformance } from './performanceComponents/LineChartPerformance'
import { TaxAlphaDetails } from './performanceComponents/TaxAlphaDetails'

export const Performance = () => {
  const accountData = useContext(accountDataContext)

  return (
    <Box>
      <LineChartPerformance accountData={accountData} />
      <TaxAlphaDetails />
    </Box>
  )
}
