import React, { useEffect } from 'react'
import { API } from 'aws-amplify'
import * as Sentry from '@sentry/react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchCustomTableMetadata, resetThemeCustomization } from '../../../store/pdf-customizer-reducer/Action/allPageAction'
import { useErrorToast } from '../../../hooks/useErrorToast'
import { useAuth } from '../../../contexts/AuthContext'
import ElementPanel from '../sidebar/ElementPanel'
import CustomizationPanel from '../sidebar/CustomizationPanel'
import ReportPage from '../ReportPage/ReportPage'
import { chartDataState } from '../../../store/pdf-customizer-reducer/Action/chartStateAction'
import { resetTemplateData } from '../../../store/pdf-customizer-reducer/Action/chartDropAction'

const NewReport = ({ pdfExportComponent, generatePDF, handleZoomLevelChange }) => {
  const { user } = useAuth()
  const dispatch = useDispatch()
  const { showError } = useErrorToast()
  const { customTableMetadata } = useSelector(state => state.allPageStyle)

  const fetchCustomTableMetaData = () => {
    if (!customTableMetadata?.length) {
      API.get('baseUriReportBuilder', `report-builder/v1/${user.userGroup}/custom-table/headers`)
        .then(response => {
          if (response?.success) {
            dispatch(fetchCustomTableMetadata(response?.data))
          }
        })
        .catch(error => {
          showError(error.response?.data?.errorInfo?.userMessage || error.message)
          Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
        })
    }
  }
  
  const reSetStore = () => {
    dispatch(resetTemplateData())
    dispatch(resetThemeCustomization())
    dispatch(chartDataState(false, 'IS_API_CALL'))
  }

  useEffect(() => {
    fetchCustomTableMetaData()
    return () => {
      handleZoomLevelChange(100)
      reSetStore()
    }
  }, [])

  return (
    <>
      <ElementPanel />
      <main className='page-content relative'>
        <ReportPage
          pdfExportComponent={pdfExportComponent}
          generatePDF={generatePDF}
        />
      </main>
      <CustomizationPanel />
    </>
  )
}

export default NewReport
