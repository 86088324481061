import { useGridApiContext } from '@mui/x-data-grid-pro'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'

export default function CustomEditDateComponent (props) {
  const { id, value, field, hasFocus } = props
  const apiRef = useGridApiContext()
  const ref = useRef()
  const [selectedDateTime, setSelectedDateTime] = useState('')

  useEffect(() => {
    if (value) {
      const dateObject = new Date(value)
      const day = ('0' + dateObject.getDate()).slice(-2)
      const month = ('0' + (dateObject.getMonth() + 1)).slice(-2) // Months are zero-indexed
      const year = dateObject.getFullYear()
      const newDate = `${year}-${month}-${day}`
      setSelectedDateTime(newDate)
    } else {
      setSelectedDateTime('')
    }
  }, [value])

  useLayoutEffect(() => {
    if (hasFocus) {
      ref.current.focus()
    }
  }, [hasFocus])

  const handleValueChange = (event) => {
    const newValue = event.target.value + 'T00:00:00.000Z' // The new value entered by the user
    setSelectedDateTime(event.target.value)
    const dateObject = new Date(newValue)
    apiRef.current.setEditCellValue({ id, field, value: new Date(dateObject?.getTime() + dateObject?.getTimezoneOffset() * 1000 * 60) })
  }

  return (
    <input
      ref={ref}
      type='date'
      value={selectedDateTime}
      onChange={handleValueChange}
      className='date-input'
    />
  )
}
