import React from 'react'
import { Box, Grid, IconButton, Stack, Typography } from '@mui/material'
import { DataGridPro, gridClasses, useGridSelector, useGridApiContext, gridFilteredDescendantCountLookupSelector } from '@mui/x-data-grid-pro'
import { ExpandMore } from '@mui/icons-material'

export const TransitionPortfolioTable = ({ data = {} }) => {
  const { securityDataTableColumn, securityRows } = data

  const CustomGridTreeDataGroupingCell = (props) => {
    const { id, field, rowNode } = props
    const apiRef = useGridApiContext()
    const filteredDescendantCountLookup = useGridSelector(
      apiRef,
      gridFilteredDescendantCountLookupSelector
    )
    const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0

    const handleClick = (event) => {
      if (rowNode.type !== 'group') {
        return
      }

      apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded)
      apiRef.current.setCellFocus(id, field)
      event.stopPropagation()
    }
    return (
      <Box sx={{ ml: 0 }}>
        <div>
          {filteredDescendantCount > 0 && !props?.row?.isLeaf
            ? (
              <>
                <IconButton
                  size='small'
                  onClick={handleClick}
                  tabIndex={-1}
                  aria-label={rowNode.childrenExpanded ? 'Close' : 'Open'}
                >
                  <ExpandMore
                    sx={{
                      transform: `rotateZ(${rowNode.childrenExpanded ? 360 : 270}deg)`,
                      transition: (theme) =>
                        theme.transitions.create('transform', {
                          duration: theme.transitions.duration.shortest
                        })
                    }}
                    fontSize='inherit'
                  />
                </IconButton>
                <span>
                  {props?.row?.company}
                </span>
              </>
              )
            : (
              <span style={{ marginLeft: rowNode.depth * 27 }}>
                {props?.row?.instrId}
              </span>
              )}
        </div>
      </Box>
    )
  }

  return (
    <Grid item xs={12}>
      <Box className='trade-table-list-header' mb={2}>Portfolio</Box>
      {securityRows?.length > 0
        ? (
          <Box
            className='table-responsive'
            sx={{
              width: '100%',
              scrollbarWidth: 'none',
              '.MuiDataGrid-cell': {
                fontSize: '12px',
                color: '#34475A',
                fontWeight: 600
              },
              '.MuiDataGrid-cellContent': {
                fontFamily: 'Open Sans',
                color: '#34475A',
                fontWeight: 600
              },
              '.MuiDataGrid-root': {
                border: 'none'
              }
            }}
          >
            <DataGridPro
              density='compact'
              autoHeight
              rows={securityRows}
              columns={securityDataTableColumn}
              pagination
              initialState={{
                ...securityRows?.initialState,
                pagination: { paginationModel: { pageSize: 10 } }
              }}
              pageSizeOptions={[10, 25, 50, 75, 100]}
              treeData
              getTreeDataPath={(row) => (row.children ? [row.company] : [row.company, row.id])}
              groupingColDef={{
                headerName: 'Name',
                filterable: true,
                sortable: true,
                disableColumnMenu: false,
                valueGetter: (params) => {
                  return params.row.isParent ? params.row.company : params.row.instrId
                },
                renderCell: (params) => <CustomGridTreeDataGroupingCell {...params} />,
                flex: 1
              }}
              disableRowSelectionOnClick
              sx={(theme) => ({
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none'
                },
                [`.${gridClasses.columnHeaderTitle}`]: {
                  color: '#74788d',
                  fontWeight: 600
                }
              })}
            />
          </Box>
          )
        : (
          <Stack direction='row' pt='0px' pl='20px'>
            <Typography variant='outlined' sx={{ fontSize: 14, color: '#34475A' }}>
              No Portfolio available.
            </Typography>
          </Stack>
          )}

    </Grid>
  )
}
