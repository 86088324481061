import * as React from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Button } from '@mui/material'

export default function DownloadReportButton (props) {
  const { isReportDownload, toggleArrow } = props
  const handleClick = (event) => {
    toggleArrow()
  }

  return (
    <div>
      <Button
        id='demo-customized-button'
        aria-controls={undefined}
        aria-haspopup='true'
        aria-expanded='true'
        disableElevation
        onClick={handleClick}
        endIcon={isReportDownload ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
        sx={{ color: 'white', background: 'rgba(52, 71, 90, 0.9)', border: 'none', width: '100%', borderRadius: '4px 4px 0px 0px', height: '48px', '&:hover': { backgroundColor: 'rgba(52, 71, 90, 0.9)' }, fontFamily: 'Open Sans' }}
        className='reportCardButton'
      >
        Downloads
      </Button>
    </div>
  )
}
