import { Card, CardContent, Table, TableContainer, Typography } from '@mui/material'

export const CardBodyDashboard = ({ children, title }) => {
  return (
    <>
      <Card>
        <CardContent>
          <Typography component='p' className='text-title'>{title}</Typography>
          <TableContainer mt={5}>
            <Table className='risk-page-table'>
              {children}
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </>
  )
}
