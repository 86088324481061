import { Tooltip, styled, tooltipClasses } from "@mui/material";

export const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#FFFFFF',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 250,
        fontSize: theme.typography.pxToRem(14),
        padding: '16px',
        boxShadow: '0px 7px 29px rgba(100, 100, 111, 0.2)',
        borderRadius: '10px',
        marginRight: '10px'
    }
}))