const allowedKeyNames = ['Home', 'End', 'PageUp', 'PageDown', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Delete', 'Backspace', 'Tab', 'Escape', 'Enter']

export const isNumberOnly = (event) => {
  if (event?.ctrlKey || event?.metaKey) {
    return true
  }
  const regex = /^(\d*\.?\d*)$/
  if (allowedKeyNames.includes(event?.key)) {
    return true
  }
  // adds new text at the position of cursor
  const inputValue = event?.target?.value?.slice(0, event?.target?.selectionStart) + event?.key + event?.target?.value?.slice(event?.target?.selectionEnd)
  if (!regex.test(inputValue)) {
    event.preventDefault()
    return false
  }
  return true
}

export const numberOnlyPasteHandler = (event) => {
  const inputValue = event?.target?.value?.slice(0, event?.target?.selectionStart) + event?.clipboardData?.getData('text') + event?.target?.value?.slice(event?.target?.selectionEnd)
  const regex = /^(\d*)\.?(\d*)$/
  if (!regex.test(inputValue)) {
    event.preventDefault()
    return false
  }
  return true
}

export const isWholeNumber = (event) => {
  const regex = /^\d+$/
  if (event?.ctrlKey || event?.metaKey) {
    return true
  }
  if (allowedKeyNames.includes(event?.key)) {
    return true
  }
  const inputValue = event?.target?.value + event?.key
  if (!regex.test(inputValue)) {
    event.preventDefault()
    return false
  }
  return true
}

// export const isNumberWithSign = (event) => {
//   const regex = /^[-+]?(\d*\.?\d*)$/
//   if ((event.keyCode === 8 || (event.keyCode >= 35 && event.keyCode <= 40) || [46, 8, 9, 27, 13].includes(event.keyCode) || (event.keyCode === 65 && (event.ctrlKey === true || event.metaKey === true)))) {
//     return true
//   }
//   const inputValue = event.target.value + event.key
//   if (!regex.test(inputValue)) {
//     event.preventDefault()
//     return false
//   }
//   return true
// }

export const isNumberWithSign = (event) => {
  if (event?.ctrlKey || event?.metaKey) {
    return true
  }

  const regex = /^[+-]?\d*\.?\d*$/
  if (allowedKeyNames.includes(event?.key)) {
    return true
  }
  const inputValue = event?.target?.value?.slice(0, event?.target?.selectionStart) + event?.key + event?.target?.value?.slice(event?.target?.selectionEnd)

  if (!regex.test(inputValue)) {
    event.preventDefault()
    return false
  }

  return true
}

export const numberWithSignPasteHandler = (event) => {
  const inputValue = event?.target?.value?.slice(0, event?.target?.selectionStart) + event?.clipboardData?.getData('text') + event?.target?.value?.slice(event?.target?.selectionEnd)
  const regex = /^[-+]?(\d*\.\d+|\d+\.\d*|\d+)$/
  if (!regex.test(inputValue)) {
    event.preventDefault()
    return false
  }
  return true
}

export const wholeNumberPasteHandler = (event) => {
  const inputValue = event?.target?.value?.slice(0, event?.target?.selectionStart) + event?.clipboardData?.getData('text') + event?.target?.value?.slice(event?.target?.selectionEnd)
  const regex = /^\d+$/
  if (!regex.test(inputValue)) {
    event.preventDefault()
    return false
  }
  return true
}