import { useState } from 'react'
import { API } from 'aws-amplify'
import * as Sentry from '@sentry/react'
import { Box, Button, Card, CardContent, Grid, Typography, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro'
import Loader from '../../pages/Loader'
import { useAuth } from '../../contexts/AuthContext'
import { useErrorToast } from '../../hooks/useErrorToast'
import CustomKeyFieldSearch from '../../components/CustomKeyFieldSearch'

// Renders a table skeleton with specified headers and hidden columns.
const renderTableSkeleton = (header, hiddenColumns) => {
  if (hiddenColumns)
      header = header.filter(col => !hiddenColumns.includes(col.field))
  return (
      <TableContainer mt={5}>
          <Table className='risk-page-table'>
              <TableHead>
                  <TableRow>
                      {header.map((item, index) => {
                          return (
                              <TableCell key={index}>{item.headerName}</TableCell>
                          )
                      })}
                  </TableRow>
              </TableHead>
              <TableBody>
                  {Array.from({ length: 5 }).map((_, index) => (
                      <TableRow key={index}>
                          {Array.from({ length: header.length }).map((_, index) => (
                              <TableCell key={index}>
                                  <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
                              </TableCell>))}
                      </TableRow>))}
              </TableBody>
          </Table>
      </TableContainer>
  )
}

const ExportAccount = ({ accounts, accountsLoading, setAccounts, copyAccountData, hasImportAccountAccess }) => {
  const [accountExporting, setAccountExporting] = useState(false)
  const { showError } = useErrorToast()
  const [selectedAccounts, setSelectedAccounts] = useState([])
  const { user } = useAuth()

  // Define columns for the DataGrid
  const columns = [
    {
      field: 'accountName',
      headerName: 'Name',
      flex: 1
    },
    {
      field: 'accountType',
      headerName: 'Account Type',
      flex: 1,
      valueGetter: (params) => params.value?.toLowerCase()?.charAt(0)?.toUpperCase() + params.value?.slice(1)?.toLowerCase()
    },
    {
      field: 'isTaxable',
      headerName: 'Taxable',
      flex: 1,
      valueGetter: (params) => (params.value === 0 ? 'No' : 'Yes')
    },
    {
      field: 'accountCode',
      headerName: 'Account Code',
      flex: 1
    },
    {
      field: 'portSpecName',
      headerName: 'Model',
      flex: 1
    },
    {
      field: 'totalMarketValue',
      headerName: 'Total Market Value',
      flex: 1,
      valueFormatter: (params) => `$${formatCurrency(params.value)}`,
      align: 'right',
      headerAlign: 'right'
    }
  ]

  // Define columns for the DataGrid
  const handleSelectionChange = (value) => {
    setSelectedAccounts(value) // Update selected accounts
  }

  // Formats a numeric amount into a human-readable currency format with magnitude suffixes.
  const formatCurrency = (amount) => {
    // Nine Zeroes for Billions
    return Math.abs(Number(amount)) >= 1.0e9
      ? (Math.abs(Number(amount)) / 1.0e9).toFixed(2) + 'B'
      : // Six Zeroes for Millions
      Math.abs(Number(amount)) >= 1.0e6
        ? (Math.abs(Number(amount)) / 1.0e6).toFixed(2) + 'M'
        : // Three Zeroes for Thousands
        Math.abs(Number(amount)) >= 1.0e3
          ? (Math.abs(Number(amount)) / 1.0e3).toFixed(2) + 'K'
          : Math.abs(Number(amount).toFixed(2))
  }

  // Downloads json file. if no account is selected, download json for all accounts else download json for selected accounts
  const downloadAccountsJson = () => {
    setAccountExporting(true)
    API.post('baseUriTransactionalMaster', `transactional-master/v1/${user.userGroup}/export/account`, {
      body: selectedAccounts?.length ? { accountIds: selectedAccounts} : {}
    })
      .then(async (response) => {
        if (response?.data) {
          // fetch content of account file stored in s3
          const accountFileResponse = await fetch(response?.data)
          // convert content to blob
          const accountFileBlob = await accountFileResponse.blob()
          // create a link tag, assign blob of account file and trigger download of link
          const blobUrl = window.URL.createObjectURL(accountFileBlob)
          const fileLink = document.createElement('a')
          fileLink.href = blobUrl
          fileLink.download = response?.data?.split('.json')[0]?.split('/')?.pop() + '.json'
          document.body.appendChild(fileLink)
          fileLink.click()
          // Cleanup
          fileLink.remove()
          window.URL.revokeObjectURL(blobUrl)
          setAccountExporting(false)
        }
      })
      .catch((error) => {
        showError(error?.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error?.response?.data?.errorInfo?.userMessage || error)
        setAccountExporting(false)
      })
  }

  return (
    <Box>
      {accountExporting ? <Loader /> : null}
      <Typography className='page-title' mb={2}>
        Export Account
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card className='card-layout'>
            <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Button
                      onClick={downloadAccountsJson}
                      disabled={selectedAccounts.length === 0}
                    >
                      Export Json
                    </Button>
                    <CustomKeyFieldSearch
                      filterData={(data) => {
                        setAccounts(data)
                      }}
                      fieldName='accountName'
                      tableData={copyAccountData}
                      fieldLabel='Name'
                    />
                  </Box>
                  {/* Render table skeleton and the DataGridPro component */}
              <Box sx={{
                          // Calculate the height of the element based on whether import account access is available
                          height: hasImportAccountAccess ? `calc(100vh - 48px - 15px - 20px - 39px - 16px - 16px - 40px - 24px - 39px - 16px - 202px - 20px)` : `calc(100vh - 48px - 15px - 20px - 39px - 16px - 16px - 40px - 24px - 10px)`, overflow: 'auto', scrollbarWidth: 'none' }}>
                  {
                    accountsLoading ? renderTableSkeleton(columns)
                    : <DataGridPro
                        rows={accounts}
                        columns={columns}
                        disableRowSelectionOnClick
                        checkboxSelection
                        keepNonExistentRowsSelected
                        rowSelectionModel={selectedAccounts}
                        onRowSelectionModelChange={handleSelectionChange}
                        getRowId={(row) => row?.accountId}
                        autoHeight
                        pageSizeOptions={[15]}
                        initialState={{
                          ...accounts?.initialState,
                          pagination: { paginationModel: { pageSize: 15 } }
                        }}
                        pagination
                        sx={(theme) => ({
                          [`.${gridClasses.main}`]: {
                              overflow: 'unset'
                          },
                          [`.${gridClasses.columnHeaders}`]: {
                              position: 'sticky',
                              backgroundColor: theme.palette.background.paper,
                              top: '0px',
                              zIndex: 1,
                          },
                          [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
                              color: '#74788d',
                              fontWeight: 600
                          },
                          [`.${gridClasses.footerContainer}`]: {
                              position: 'sticky',
                              bottom: '0px',
                              backgroundColor: theme.palette.background.paper,
                              zIndex: 1
                          },
                          border: 'none',
                          fontFamily: 'Open Sans'
                      })}                      
                    />
                  }
                </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  )  
}

export default ExportAccount