import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Chip,
  Grid,
  IconButton,
  Modal,
  Typography
} from '@mui/material'

const personalizationPopupStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '800px',
  height: '700px',
  maxWidth: '100%',
  maxHeight: '100vh',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
  overflow: 'auto',
  borderRadius: '4px'
}

const chipStyles = {
  borderRadius: '50px', // set the border radius to create the rounded corners
  backgroundColor: '#E7F0FF',
  color: '#3A76D4', // set the text color
  fontWeight: 600, // set the font weight
  fontSize: '14px',
  fontFamily: 'Open Sans',
  padding: '4px', // set the padding to create the size of the chip
  marginRight: '10px',
  marginTop: '12px'
}

const ViewPersonalizationData = ({ isOpen, setIsOpen, personalization }) => {
  return (
    <Modal
      open={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <Box sx={personalizationPopupStyle}>
        <Box
          sx={{ flexGrow: 1 }} className='modelSection'
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Box sx={{ fontSize: '22px', fontFamily: 'Open Sans', fontWeight: 400 }}>Personalization</Box>
            <IconButton onClick={() => setIsOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
          {!(
            personalization?.factorUtilData?.filter(factor => factor?.value !== '' || factor?.selected !== false)?.length > 0 ||
              personalization?.sectorData?.filter(sector => sector?.min !== '' || sector?.max !== '' || sector?.exclude !== false)?.length > 0 ||
              personalization?.industryData?.filter(industry => industry?.min !== '' || industry?.max !== '' || industry?.exclude !== false)?.length > 0 ||
              personalization?.securityData?.length > 0 ||
              personalization?.factorUtilData?.filter(factor => factor?.value !== '' || factor?.selected !== false)?.length > 0 ||
              personalization?.substitutionData?.length > 0 ||
              personalization?.taxConstraintData?.filter(taxConstraint => taxConstraint?.value !== '')?.length > 0 ||
              personalization?.cashConstraintData?.filter(cashConstraint => cashConstraint?.value !== null && cashConstraint?.value !== undefined && cashConstraint?.value !== '')?.length > 0 ||
              personalization?.otherConstraintData?.filter(otherConstraint => otherConstraint?.value !== null && otherConstraint?.value !== undefined && otherConstraint?.value !== '')?.length > 0
          )
            ? <Box> No Personalization data. </Box>
            : <Grid container spacing={2} columns={12}>
              {personalization?.sectorData?.filter(sector => sector?.min !== '' || sector?.max !== '' || sector?.exclude !== false)?.length > 0
                ? <Grid item xs={12}>
                  <Box>
                    <Typography className='sub-labels-pers' sx={{ color: '#74788d', fontWeight: 600 }}>
                      Sector
                    </Typography>
                    {(personalization.sectorData || [])?.map((sector) => {
                      let valueSel = `${sector.sectorName} | `
                      if (sector.exclude) {
                        valueSel += 'Exclude'
                      } else if (sector.min && sector.max) {
                        valueSel += `${sector.min}-${sector.max}`
                      } else if (sector.min) {
                        valueSel += `${sector.min}`
                      } else if (sector.max) {
                        valueSel += `${sector.max}`
                      }
                      return (sector.min !== '' || sector.max !== '' || sector.exclude !== false) ? <Chip style={chipStyles} label={valueSel} /> : <></>
                    })}
                  </Box>
                  </Grid>
                : null}
              {personalization?.industryData?.filter(industry => industry?.min !== '' || industry?.max !== '' || industry?.exclude !== false)?.length > 0
                ? <Grid item xs={12}>
                  <Box>
                    <Typography className='sub-labels-pers' sx={{ color: '#74788d', fontWeight: 600 }}>
                      Industry
                    </Typography>
                    {(personalization.industryData || [])?.map((industry) => {
                      let valueSel = `${industry.industry} | `
                      if (industry.exclude) {
                        valueSel += 'Exclude'
                      } else if (industry.min && industry.max) {
                        valueSel += `${industry.min}-${industry.max}`
                      } else if (industry.min) {
                        valueSel += `${industry.min}`
                      } else if (industry.max) {
                        valueSel += `${industry.max}`
                      }
                      return (industry.min !== '' || industry.max !== '' || industry.exclude !== false) ? <Chip label={valueSel} style={chipStyles} /> : <></>
                    })}
                  </Box>
                  </Grid>
                : null}
              {personalization?.securityData?.length > 0 && (
                <Grid item xs={12}>
                  <Box>
                    <Typography className='sub-labels-pers' sx={{ color: '#74788d', fontWeight: 600 }}>
                      Security Restrictions
                    </Typography>
                    {(personalization.securityData || [])?.map((security) => (
                      <Chip label={`${security.name} | ${security.action}`} style={chipStyles} />
                    ))}
                  </Box>
                </Grid>
              )}
              {personalization?.factorUtilData?.filter(factor => factor?.value !== '' || factor?.selected !== false)?.length > 0
                ? <Grid item xs={12}>
                  <Box>
                    <Typography className='sub-labels-pers' sx={{ color: '#74788d', fontWeight: 600 }}>
                      Factor Tilts
                    </Typography>
                    {(personalization.factorUtilData || []).map((factor) => {
                      return (factor.value !== '' || factor.selected !== false) ? <Chip label={`${factor.attrLabel} | ${factor.value}`} style={chipStyles} /> : <></>
                    })}
                  </Box>
                  </Grid>
                : null}
              {personalization?.substitutionData?.length > 0
                ? <Grid item xs={12}>
                  <Box>
                    <Typography className='sub-labels-pers' sx={{ color: '#74788d', fontWeight: 600 }}>
                      Substitutes
                    </Typography>
                    {(personalization.substitutionData || [])?.map(
                      (security, index) => {
                        return (
                          <Chip key={index} style={chipStyles} label={`${security.source} -> ${security.target}`} />
                        )
                      }
                    )}
                  </Box>
                  </Grid>
                : null}
              <Grid item xs={12}>
                {personalization?.cashConstraintData?.filter(cashConstraint => cashConstraint?.value !== null && cashConstraint?.value !== undefined && cashConstraint?.value !== '')?.length > 0
                  ? (
                    <Box>
                      <Typography className='sub-labels-pers' sx={{ color: '#74788d', fontWeight: 600 }}>
                        Cash Constraints
                      </Typography>
                      {(personalization?.cashConstraintData || []).map((cashConstraint, index) => (
                        cashConstraint.value !== '' && cashConstraint.value !== undefined
                          ? (
                            <Chip style={chipStyles} label={`${cashConstraint?.propertyDesc} | ${cashConstraint?.value}`} />
                            )
                          : null
                      ))}
                    </Box>
                    )
                  : null}

              </Grid>
              <Grid item xs={12} style={{ display: personalization?.taxConstraintData?.filter(taxConstraint => taxConstraint?.value !== '')?.length > 0 ? 'block' : 'none' }}>
                {personalization?.taxConstraintData?.filter(taxConstraint => taxConstraint?.value !== '')?.length > 0
                  ? <Box>
                    <Typography className='sub-labels-pers' sx={{ color: '#74788d', fontWeight: 600 }}>
                      Tax Constraints
                    </Typography>
                    {(personalization?.taxConstraintData || [])?.map(
                      (taxConstraint, index) => {
                        return (taxConstraint?.value !== '') ? <Chip style={chipStyles} label={`${taxConstraint?.name} | ${taxConstraint?.value}`} /> : <></>
                      }
                    )}
                  </Box>
                  : null}
              </Grid>
              <Grid item xs={12} style={{ display: personalization?.otherConstraintData?.filter(otherConstraint => otherConstraint?.value !== null && otherConstraint?.value !== undefined && otherConstraint?.value !== '')?.length > 0 ? 'block' : 'none' }}>
                {personalization?.otherConstraintData?.filter(otherConstraint => otherConstraint?.value !== null && otherConstraint?.value !== undefined && otherConstraint?.value !== '')?.length > 0
                  ? (
                    <Box>
                      <Typography className='sub-labels-pers' sx={{ color: '#74788d', fontWeight: 600 }}>
                        Other Constraints
                      </Typography>
                      {(personalization?.otherConstraintData || [])?.map((otherConstraint, index) => {
                        return otherConstraint?.value !== undefined && otherConstraint?.value !== ''
                          ? (
                            <Chip style={chipStyles} label={`${otherConstraint?.propertyDesc} | ${otherConstraint?.value}`} />
                            )
                          : null
                      })}
                    </Box>
                    )
                  : null}
              </Grid>
            </Grid>}
        </Box>
      </Box>
    </Modal>
  )
}

export default ViewPersonalizationData
