import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  coverNameEditor
} from '../../../../store/pdf-customizer-reducer/Action/chartDropAction'
import ChartLabel from './ChartLabel'
import ImageHolderEdit from './ImageHolderEdit'

const CoverPage = ({ activeChart }) => {
  const dispatch = useDispatch()
  const [editModes, setEditModes] = useState({
    templateType: false,
    accountName: false,
    companyName: false
  })

  const handleToggleEditMode = (label) => {
    setEditModes((prevEditModes) => ({
      ...prevEditModes,
      [label]: !prevEditModes[label]
    }))
  }
  const handleToggleSaveMode = (value, label) => {
    setEditModes((prevEditModes) => ({
      ...prevEditModes,
      [label]: !prevEditModes[label]
    }))
    dispatch(coverNameEditor({ value, label }))
  }

  return (
    <div>
      {['templateType', 'accountName', 'companyName'].map((label) => (
        <ChartLabel
          key={label}
          value={activeChart.value[label]}
          isEditing={editModes[label]}
          onSave={(value) => handleToggleSaveMode(value, label)}
          onEdit={() => handleToggleEditMode(label)}
          onCancel={() => handleToggleEditMode(label)}
        />
      ))}

      <ImageHolderEdit isUploadImage />
    </div>
  )
}

export default CoverPage
