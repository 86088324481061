import { Link, useParams } from 'react-router-dom'
import { Box, IconButton, styled } from '@mui/material'
import { RefreshOutlined } from '@mui/icons-material'
import clickIcon from '../../../assets/images/icon-click.svg'
import docIcon from '../../../assets/images/icon-doc.svg'

const CustomIconButton = styled(IconButton)(({ theme }) => ({
  width: '40px',
  height: '40px',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: '2px solid #dee2e6',
  ':hover': {
    background: 'transparent',
    border: '2px solid #dee2e6'
  },
  background: 'transparent',
  borderRadius: '10px'
}))

export const InternalNavigation = ({ clearCaching }) => {
  const params = useParams()

  // refresh dashboard data
  const refreshDashboardData = () => {
    if (clearCaching) {
      clearCaching()
    }
  }

  return (
    <>
      <Box>
        {/* <CustomIconButton sx={{ mr: 1 }}>
          <Link
            to={`/account-review/personalization/${params.accountId}`}
            className='linkId action-clock-btn tooltip-trade'
          >
            <img src={docIcon} alt='' />
            <span className='tooltiptext' style={{ marginTop: '15px', marginLeft: '-45px' }}>
              Personalization
            </span>
          </Link>
        </CustomIconButton>
        <CustomIconButton>
          <Link
            to={`/account-review/request/${params.accountId}`}
            className='linkId action-clock-btn tooltip-trade'
          >
            <img src={clickIcon} alt='' />
            <span className='tooltiptext' style={{ marginTop: '15px', marginLeft: '-45px' }}>
              Request
            </span>
          </Link>
        </CustomIconButton> */}
        <IconButton
          variant='outlined'
          onClick={refreshDashboardData}
          sx={{
            border: '2px solid #dee2e6',
            padding: 1,
            borderRadius: '10px',
            minWidth: 'auto',
            ':hover': {
              background: 'transparent',
              border: '2px solid #dee2e6'
            },
            marginLeft: '8px'
          }}
          className='tooltip-trade'
        >
          <RefreshOutlined sx={{ height: 20, width: 20, color: '#74788D' }} />
          <span className='tooltiptext' style={{ marginTop: '10px', marginLeft: '-45px' }}>
            Refresh
          </span>
        </IconButton>
      </Box>
    </>
  )
}
