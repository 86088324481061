import FactorAllocationChart from '../droppableElements/FactorAllocationChart.js'
import MarketCap from '../droppableElements/MarketCap.js'
import PageHeading from '../droppableElements/Header/PageHeading.js'
import PageFooter from '../droppableElements/Footer/PageFooter.js'
import PageFooter2 from '../droppableElements/Footer/PageFooter2.js'
import CoverPage from '../droppableElements/CoverPage/CoverPage.js'
import CoverPage2 from '../droppableElements/CoverPage/CoverPage2.js'
import PerformanceLineChart from '../droppableElements/PerformanceLineChart/PerformanceLineChart.js'
import Definitions from '../droppableElements/Definitions/Definitions.js'
import BubbleChart from '../droppableElements/BubbleChart/BubbleChart.js'
import YTDTaxDetails from '../droppableElements/YTDTaxDetails/YTDTaxDetails.js'
import TaxManagementChart from '../droppableElements/TaxManagementChart/TaxManagementChart.js'
import Table from '../droppableElements/Table.js'
import InitialPortfolioChart from '../droppableElements/InitialPortfolioChart.js'
import FactorTable from '../droppableElements/FactorTable.js'
import YTDTable from '../droppableElements/YTDTable/YTDTable.js'
import TextEditing from '../droppableElements/TextEditing.js'
import DataTable from '../droppableElements/DataTable/DataTable.jsx'
import PortfolioStatistics from '../droppableElements/PortfolioStatistics/PortfolioStatistics.js'
import TaxManagementSummary from '../droppableElements/TaxManagementSummary/TaxManagementSummary.js'
import DiversificationStatistics from '../droppableElements/DiversificationStatistics/DiversificationStatistics.js'
import PersonalizationConstraints from '../droppableElements/PersonalizationConstraints/PersonalizationConstraints.js'
import PageCoverShow from '../droppableElements/PageCover/PageCover.jsx'
import DataTablePrimary from '../droppableElements/DataTable/DataTablePrimary.jsx'
import PDFHolder from '../droppableElements/PDFHolder/PDFHolder.js'
import ImageHolder from '../droppableElements/ImageHolder/ImageHolder.js'
import AssetAllocationChart from '../droppableElements/AssetAllocationChart.js'
import TopSecurities from '../droppableElements/TopSecurities/TopSecurities.js'
import PageFooter3 from '../droppableElements/Footer/PageFooter3.js'
import SectionPage from '../droppableElements/SectionPage.js'
import CustomTable from '../droppableElements/CustomTable/CustomTable.js'
import CashFlowSummary from '../droppableElements/CashFlowSummary/CashFlowSummary.js'
import AggregateActivitySummary from '../droppableElements/CashFlowSummary/AggregateActivitySummary.js'
import AggregateAssetsChart from '../droppableElements/AllocationElements/AggregateAssetsChart.js'
import AggregateTable from '../droppableElements/AllocationElements/AggregateTable.js'
import ActivitySummary from '../droppableElements/CashFlowSummary/ActivitySummary.js'

export const renderDropComponentMap = {
  'Cover page 1': CoverPage,
  'Cover page 2': CoverPage2,
  'Cover page 3': CoverPage,
  'Page Heading 1': PageHeading,
  'Page Heading 2': PageHeading,
  'Page Footer 1': PageFooter,
  'Page Footer 2': PageFooter2,
  'Page Footer 3': PageFooter3,
  'Summary Table': Table,
  Definitions,
  'Summary Bubble Chart': BubbleChart,
  'Market cap vertical bar table': MarketCap,
  'Performance line chart': PerformanceLineChart,
  'Factor bar chart': FactorAllocationChart,
  'YTD tax details': YTDTaxDetails,
  'Asset allocation chart': AssetAllocationChart,
  'YTD tax saving details table': YTDTable,
  'Title Text': TextEditing,
  'Paragraph Text': TextEditing,
  'Initial Portfolio Block': InitialPortfolioChart,
  'Factor table': DataTablePrimary,
  'Tax Management Comparison Block': TaxManagementChart,
  'Tax Management Comparison Table': YTDTaxDetails,
  'Tax Management Summary Chart': TaxManagementSummary,
  'Sector constraints': DataTable,
  'Industry constraints': DataTable,
  'Security constraints': DataTable,
  'Substitution constraints': DataTable,
  'Factor Tilts constraints': DataTable,
  Scenario: DataTable,
  'Top Securities': TopSecurities,
  'PDF Uploads': PageCoverShow,
  'Top Positions': DataTablePrimary,
  'Risk By Security': DataTablePrimary,
  'Risk By Sector': DataTablePrimary,
  'Market Cap table': DataTablePrimary,
  'Personalization constraints': PersonalizationConstraints,
  'Diversification statistics': DiversificationStatistics,
  'Portfolio statistics': PortfolioStatistics,
  'PDF Holder': PDFHolder,
  'Image Holder': ImageHolder,
  'Section Page': SectionPage,
  'Custom Table': CustomTable,
  'Cash Flow Summary': CashFlowSummary,
  'Activity Summary': ActivitySummary,
  'Aggregate Activity Summary': AggregateActivitySummary,
  'Aggregate Assets Chart': AggregateAssetsChart,
  'Aggregate Assets Table': AggregateTable,
  'Aggregate Performance': AggregateTable
}
