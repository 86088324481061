import React, { useContext } from 'react'
import { Box, Card, CardContent, Divider, Grid, Typography } from '@mui/material'
import menIcon from '../../../assets/images/men.png'
import { accountDataContext } from '../Index'

export const AccountInformation = () => {
  const accountData = useContext(accountDataContext)
  return (
    <Grid xs={12} sm={12}>
      <Card elevation={6} sx={{ minWidth: '100%' }} className='report-card'>
        <CardContent sx={{ p: 0 }}>
          {/* <Grid xs={12} sm={12} container>
            <Grid xs={4} sm={3}>
              <img
                src={menIcon}
                width={40}
                height={40}
                alt='menu'
                className='mt-2 mb-2'
              />
            </Grid>
            <Grid xs={8} sm={9}>
              <Box>
                <Typography sx={{ fontSize: '20px', fontWeight: 400, color: '#34475A', lineHeight: '26px', fontFamily: 'Open Sans' }}>
                  {accountData?.accountName || 'N/A'}
                </Typography>
              </Box>
              <Box>
                <Typography variant='body1' className='reportCardSub'>
                  AC Code: {accountData?.accountCd || 'N/A'}
                </Typography>
              </Box>
            </Grid>
          </Grid> */}
          <Grid xs={12} sm={12}>
            <Box sx={{ px: 2, pt: 2, pb: 1 }}>
              <Typography variant='outlined' sx={{ fontSize: 16, color: '#34475A', fontFamily: 'Open Sans' }}>
                Account Information
              </Typography>
            </Box>
          </Grid>
          <Divider
            sx={{
              bgcolor: 'rgba(116, 120, 141, 0.15)'
            }}
          />
          <Box ml={2}>
            <Grid xs={12} sm={12} mt={2} style={{ color: '#34475A' }}>
              <Box>
                <Typography variant='outlined' className='reportCardSub'>
                  Strategy: {accountData?.strategyName || 'N/A'}
                </Typography>
              </Box>
            </Grid>
            <Grid xs={12} sm={12} mt={1} style={{ color: '#34475A' }}>
              <Box>
                <Typography variant='outlined' className='reportCardSub'>
                  Account Type: {accountData?.isTaxable ? 'Taxable' : accountData?.isTaxable !== undefined ? 'Tax Exempt' : 'N/A'}
                </Typography>
              </Box>
            </Grid>
            <Grid xs={12} sm={12} mt={1} style={{ color: '#34475A' }}>
              <Box>
                <Typography variant='outlined' className='reportCardSub'>
                  Benchmark: {accountData?.bmkName || 'N/A'}
                </Typography>
              </Box>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  )
}
