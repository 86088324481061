import { useState } from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

const CustomStrategyField = (props) => {
  const { strategies, params, error, currRowsStrategyList, loading } = props
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState(strategies || [])
  const [value, setValue] = useState(strategies?.find(s => s.strategyName === params?.value?.strategyName) || currRowsStrategyList?.find(s => s.strategy === params?.value?.strategy) || null)
  const handleStrategyChange = (event, newValue) => {
    const selectedStrategy = newValue?.strategy || newValue?.strategyName || ''
    params?.api.setEditCellValue({ id: params?.id, field: params?.field, value: newValue, strategyId: params?.row?.id })
    setValue(newValue)
    setInputValue(selectedStrategy)
  }

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue?.trim() || '')
    if (newInputValue?.trim()?.length >= 3) {
      setOptions(strategies)
    }
  }

  return (
    <Autocomplete
      value={value || null}
      inputValue={inputValue}
      loading={loading}
      onChange={handleStrategyChange}
      onInputChange={handleInputChange}
      loadingText='Loading...'
      size='small'
      noOptionsText='No strategies found'
      options={options}
      getOptionLabel={(option) => (`${option?.strategy?.strategyName || option?.strategyName || ''}`)}
      isOptionEqualToValue={(option, value) => (option?.strategyName === value?.strategyName)}
      renderInput={(params) => (
        <TextField
          {...params}
          label='Strategy'
          InputLabelProps={{ shrink: true }}
          error={error}
        />
      )}
      style={params?.style || {}}
      sx={{ width: '100%', mr: 1 }}
    />
  )
}

export default CustomStrategyField
