import React, { useContext } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { Stack } from '@mui/material'
import { monitoringHeaderCardDataContext } from '../Monitoring/index'

export const MonitoringHeaderCard = () => {
  const monitoringHeaderCardData = useContext(monitoringHeaderCardDataContext)
  return (
    <Card className='report-card' sx={{ height: '105px', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }} py={2}>
      <CardContent sx={{ pl: '0px', pr: '0px', pt: '15px', pb: '15px!Important', width: '100%' }}>
        <Grid className='report-card-border' container xs={10} sm={16} columns={16}>
          {
            monitoringHeaderCardData.map((item, index) => {
              return (
                <Grid key={index} xs={2} sm={3} sx={{ width: '100px!Important' }} ml={1}>
                  <Stack className='stack-container' ml={2}>
                    <Typography variant='h6' className='card-heading' sx={{ color: '#4B8CCA', textAlign: 'center', paddingBottom: '5px' }}>
                      {item.value}
                    </Typography>
                    <Typography variant='subtitle1' className='card-subheading' sx={{ width: '130px' }}>
                      {item.label}
                    </Typography>
                  </Stack>
                </Grid>
              )
            })
          }
        </Grid>
      </CardContent>
    </Card>
  )
}
