import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Tooltip } from '@mui/material'

const CustomTooltipIcon = ({ text, action }) => {
  const isError = action === 'error'

  return (
    <Tooltip
      disableFocusListener
      disableTouchListener
      placement='top'
      title={text}
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: action === 'warning' ? '#EFAE3A' : action === 'error' ? 'red' : '',
            '& .MuiTooltip-arrow': {
              color: action === 'warning' ? '#EFAE3A' : action === 'error' ? 'red' : ''
            }
          }
        }
      }}
    >
      {isError
        ? (
          <ErrorOutlineIcon
            fontSize='small'
            sx={{
              ml: 1,
              color: 'red'
            }}
          />
          )
        : (
          <InfoOutlinedIcon
            fontSize='small'
            sx={{
              ml: 1,
              ...action === 'warning' ? { color: '#EFAE3A' } : {}
            }}
          />
          )}
    </Tooltip>
  )
}

export default CustomTooltipIcon
