export const passwordValidationMessages = (password) => {
  const validations = [
    {
      message: 'Password must be at least 8 characters long',
      isValid: password.length >= 8
    },
    {
      message: 'Password must not exceed 14 characters',
      isValid: password.length <= 14 && password.length > 1
    },
    {
      message: 'Password must contain at least one lowercase letter',
      isValid: /[a-z]/.test(password)
    },
    {
      message: 'Password must contain at least one uppercase letter',
      isValid: /[A-Z]/.test(password)
    },
    {
      message: 'Password must contain at least one number',
      isValid: /\d/.test(password)
    },
    {
      message: 'Password must contain at least one special character: ! @ # $ % ^ & *',
      isValid: /[!@#$%^&*]/.test(password)
    }
  ]

  return validations
}