import { getUserRole } from '../../../utils/getUserRole'
import { UpdateAPI } from '../components/Actions'
import { FormatDateAPI, FormatPayload } from '../components/FormatAPIRequest'

export const UpdateRequestData = async (updatedRow, tabIndex) => {
  switch (tabIndex) {
    case 'type-code':
      return await UpdateRequestTypeCode(updatedRow)
    case 'status-code':
      return await UpdateRequestStatusCode(updatedRow)
    default:
      return {}
  }
}

const UpdateRequestTypeCode = async (updatedRow) => {
  const userRole = getUserRole()
  const startDateValue = updatedRow.startDate ? FormatDateAPI(updatedRow.startDate) : null
  const endDateValue = updatedRow.endDate ? FormatDateAPI(updatedRow.endDate) : null

  let payload = {
    typeCd: updatedRow?.typeCd?.toUpperCase(),
    label: updatedRow?.label,
    desc: updatedRow?.desc,
    etaHours: updatedRow?.etaHours,
    priority: updatedRow?.priority,
    startDate: startDateValue,
    endDate: endDateValue,
    requestEntity: updatedRow?.requestEntity
  }

  payload = FormatPayload(payload)
  return UpdateAPI(
    'baseRequestMaintainUrl',
    `data-maintenance/v1/${userRole}/requests/type-code/${updatedRow?.typeCd}`,
    payload
  )
}

const UpdateRequestStatusCode = async (updatedRow) => {
  const userRole = getUserRole()
  const startDateValue = updatedRow.startDate ? FormatDateAPI(updatedRow.startDate) : null
  const endDateValue = updatedRow.endDate ? FormatDateAPI(updatedRow.endDate) : null

  let payload = {
    statusCd: updatedRow.statusCd?.toUpperCase(),
    label: updatedRow.label,
    desc: updatedRow.desc,
    startDate: startDateValue,
    endDate: endDateValue,
    requestEntity: updatedRow.requestEntity
  }

  payload = FormatPayload(payload)
  return UpdateAPI(
    'baseRequestMaintainUrl',
    `data-maintenance/v1/${userRole}/requests/status-code/${updatedRow.statusCd}`,
    payload
  )
}
