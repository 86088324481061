//! ////////////////////////////// Performance line chart ////////////////////////////

import { store } from '../../../../store'
import {
  addChartValues,
  upDatePageHeadingFooter
} from '../../../../store/pdf-customizer-reducer/Action/chartDropAction'
import { chartDataState } from '../../../../store/pdf-customizer-reducer/Action/chartStateAction'
import { toastError } from '../../../../utils/pdf-customizer/common'
import { formatNumbersWithSymbol } from '../../../Report/components/dataProcess/DataProcess'

// export const performanceDataProcess = (response) => {
//   const dates =
//     Array.isArray(response?.data) &&
//     response?.data?.map((obj) => obj.date.slice(0, 10))
//   const preTaxMVs =
//     Array.isArray(response?.data) && response?.data?.map((obj) => obj.preTaxMV)
//   const postTaxMVs =
//     Array.isArray(response?.data) && response?.data?.map((obj) => obj.postTaxMV)
//   const firstDate = dates[0]
//   const lastDate = dates[dates.length - 1]
//   const reverseDate = dates && dates.length > 0 && dates.reverse()
//   const reversePreTaxMVs =
//     preTaxMVs && preTaxMVs.length > 0 && preTaxMVs.reverse()
//   const reversePostTaxMVs =
//     postTaxMVs && postTaxMVs.length > 0 && postTaxMVs.reverse()
//   return {
//     reverseDate,
//     reversePreTaxMVs,
//     reversePostTaxMVs,
//     firstDate,
//     lastDate
//   }
// }

//! ////////////////////////////// Market cap vertical bar chart ////////////////////////////

export const responseDataConvert = (response, type, filter, value) => {
  const colors = [
    'rgba(75, 140, 202, 1)',
    'rgba(164, 197, 227, 1)',
    'rgba(52, 195, 143, 1)',
    'rgba(200, 219, 238, 1)',
    'rgba(235, 242, 248, 1)'
  ]
  if (!response || !response.data) {
    return null
  }
  const fieldLabels = response.data.fieldLabels || {
    targetPortfolioWeight: 'Balanced'
  }
  const responseDataConvert = response.data[type] || response.data || []
  const labels =
    Array.isArray(responseDataConvert) &&
    responseDataConvert.map((item) => item[filter])
  const datasets = Object.entries(fieldLabels).map(([key, label], index) => {
    const backgroundColor =
      value && value?.datasets[index]
        ? value.datasets[index].backgroundColor
        : colors[index] ||
        `rgba(${index * 50 + 75}, ${index * 50 + 75}, ${index * 50 + 75
        }, 0.4)`
    return {
      label,
      backgroundColor,
      borderColor: backgroundColor,
      borderWidth: 0,
      data:
        Array.isArray(responseDataConvert) &&
        responseDataConvert.map((item) => item[key])
    }
  })

  return { labels, datasets }
}

export const assetAllocationChartData = (response, type, filter, value) => {
  const colors = [
    '#7ba0c4'
  ]
  if (!response || !response.data) {
    return null
  }
  const fieldLabels = response?.data?.fieldLabels || {
    currentPortfolioWeight: 'Current portfolio'
  }
  const responseDataConvert = (response?.data || [])?.sort((a, b) => Math.abs(b.currentPortfolioWeight) - Math.abs(a.currentPortfolioWeight))
  const labels =
    Array.isArray(responseDataConvert) &&
    responseDataConvert.map((item) => item[filter])
  const datasets = Object.entries(fieldLabels).map(([key, label], index) => {
    const backgroundColor =
      value && value?.datasets[index]
        ? value.datasets[index].backgroundColor
        : colors[index] ||
        'transparent'
    return {
      label,
      backgroundColor,
      borderColor: key === 'targetPortfolioWeight'
        ? Array.isArray(responseDataConvert) &&
        responseDataConvert.map((item) => Number(item.difference) > 0 ? '#34C38F' : '#F46A6A')
        : backgroundColor,
      borderWidth: key === 'targetPortfolioWeight' ? 2 : 0,
      ...{
        ...key === 'targetPortfolioWeight'
          ? {
            pointRadius: 18,
            hoverRadius: 18,
            hoverBorderWidth: 2,
            showLine: false,
            pointStyle: 'line',
            type: 'line'
          }
          : {}
      },
      data:
        Array.isArray(responseDataConvert) &&
        responseDataConvert.map((item) => (Number(item[key]) * 100).toFixed(2))
    }
  })

  return { labels, datasets }
}
//! ////////////////////////////// Bubble chart ////////////////////////////
export const formatCurrencyWithSymbol = (amount, decimal, symbol) => {
  const absAmount = Math.abs(Number(amount))
  const sign = Math.sign(amount) === -1 ? '-' : ''
  if (absAmount >= 1.0e9) {
    return sign + symbol + (absAmount / 1.0e9).toFixed(decimal) + 'B'
  } else if (absAmount >= 1.0e6) {
    return sign + symbol + (absAmount / 1.0e6).toFixed(decimal) + 'M'
  } else if (absAmount >= 1.0e3) {
    return sign + symbol + (absAmount / 1.0e3).toFixed(decimal) + 'K'
  } else if (absAmount === 0) {
    return symbol + absAmount.toFixed(decimal)
  } else {
    return sign + symbol + absAmount.toFixed(decimal)
  }
}
export const isNumeric = (num) => {
  return !isNaN(num * 1) && !Number.isInteger(num * 1)
}
export const decTwoPercentage = (value, decimal = 2) => {
  const numericValue = Number(value)

  if (numericValue === 0) {
    return 0
  }

  if (isNaN(numericValue)) {
    return 'Invalid Number'
  }

  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' }
  ]

  if (Math.abs(value) > 999) {
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
    const item = lookup
      .slice()
      .reverse()
      .find((item) => Math.abs(value) >= item.value)

    const formattedValue = item
      ? (numericValue / item.value).toFixed(decimal).replace(rx, '$1') +
      item.symbol
      : '0'

    return value < 0 ? `${formattedValue}` : formattedValue
  } else {
    return (numericValue * 100).toFixed(decimal)
  }
}

export const bubbleResponse = (
  convertedDataBubble,
  bubbleColorChartData,
  convertedDataLine
) => {
  return {
    datasets: [
      {
        label: 'Bubble Dataset',
        data: convertedDataBubble,
        backgroundColor: bubbleColorChartData
        // type: 'bubble'
      },
      {
        type: 'line',
        label: 'line',
        data: convertedDataLine,
        fill: false,
        backgroundColor: 'rgba(139,174,225, 1)',
        borderColor: 'rgba(139,174,225, 1)',
        borderWidth: 1,
        pointRadius: 0,
        pointHitRadius: 0,
        pointHoverBorderWidth: 0,
        pointBorderWidth: 0,
        pointHoverRadius: 0
      }
    ]
  }
}

//! ////////////////////////////// Bubble chart ////////////////////////////
export const summeryTableConvertResponse = (response, state) => {
  const headingTableKeys = [
    'Scenario Name',
    'Tracking Error(%)',
    'No. of Stocks (Turnover)',
    'Proposed Gain/Loss($)',
    'Total Taxes($)',
    'Sell',
    'Buy'
  ]

  const capitalizeFirstLetter = (str) =>
    str.charAt(0).toUpperCase() + str.slice(1)

  const formatKey = (key) =>
    capitalizeFirstLetter(key.replace(/([A-Z])/g, '$1'))

  // Ensure that response.data is an array with elements
  const tableRows =
    response?.data && Array.isArray(response?.data?.optimizationData)
      ? response?.data?.optimizationData?.map((obj) => {
        const formattedData = {
          'Scenario Name': obj.scenarioDesc,
          'Tracking Error(%)': obj.te,
          'No. of Stocks (Turnover)': obj.risk,
          Buy: obj.numBuy,
          Sell: obj.numSell,
          'Proposed Gain/Loss($)': [
            { name: 'Long Term Gain', value: obj.ltRg },
            { name: 'Long Term Loss', value: obj.ltRl },
            { name: 'Short Term Gain', value: obj.stRg },
            { name: 'Short Term Loss', value: obj.stRl },
            { name: 'Total', value: (parseFloat(obj.ltRg || 0) + parseFloat(obj.stRg || 0)) - (parseFloat(obj.ltRl || 0) + parseFloat(obj.stRl || 0)) }
          ],
          'Total Taxes($)': obj.taxCost
        }
        return formattedData
      })
      : []

  const headingColumns =
    Array.isArray(headingTableKeys) &&
    headingTableKeys.map((key) => formatKey(key))

  return {
    headingColumns,
    tableRows,
    tableTextAlign: ['text-left', 'text-right', 'text-right', 'text-left', 'text-right', 'text-right', 'text-right']
  }
}
export const fetchDataAndDispatch = async (
  apiCall,
  actionType,
  dispatch,
  updateHeader
) => {
  try {
    const response = await apiCall()
    if (response && response.success) {
      dispatch(chartDataState(response.data, actionType))
      if (updateHeader) {
        const data = {
          ...response?.data?.data,
          allUpdate: true,
          logo: 'no change'
        }
        dispatch(upDatePageHeadingFooter(data))
      }
    }
  } catch (error) {
    toastError(error?.message || 'Something went wrong')
    throw error
  }
}
export const rgbaToHex = (rgba) => {
  if (rgba.startsWith('#')) {
    return rgba
  }
  const [r, g, b] = rgba
    .substring(rgba.indexOf('(') + 1, rgba.lastIndexOf(')'))
    .split(',')
    .map((val) => parseInt(val))

  return `#${r.toString(16).padStart(2, '0')}${g
    .toString(16)
    .padStart(2, '0')}${b.toString(16).padStart(2, '0')}`
}

export const dataTableData = (response, name, type) => {
  const defaultColumns = {
    index: 'Sr No',
    sector: 'Sector',
    portfolio: 'Portfolio(%)',
    minTax: 'Min Tax(%)',
    balanced: 'Balanced(%)',
    model: 'Model(%)',
    strategy: 'Strategy(%)'
  }

  const generateScenarioFieldLabels = (columns) => {
    // this function returns one array of fieldLabels that can be passed as prop to datagrid column
    const fieldLabels = []
    for (let i = 0; i < columns.length; i++) {
      const fieldLabel = {}
      fieldLabel.headerName = columns[i]
      // fieldLabel.flex = 1
      if (i === 0) {
        /* static data
        for field that maps fieldLabels to rows in DataGrid is set to 'Property Description' and headerName which has to be shown on screen is set to 'Description' */
        fieldLabel.field = 'property_description'
        fieldLabel.headerName = 'Description'
        fieldLabels.push(fieldLabel)
        continue
      }
      // dynamic columns
      // field of any object won't consist space and changing to the lower case
      const field_value = columns[i].toLowerCase().replace(' ', '_')
      fieldLabel.field = field_value
      fieldLabels.push(fieldLabel)
    }
    return fieldLabels
  }
  const generateScenarioRowData = (rows, scenarioData) => {
    // this function converts an array from column major to row major so it becomes easy to pass data to DataGrid
    const row_data = []
    for (let k = 0; k < rows.length; k++) {
      // iterates over the rows array which consist of all the unique 'scenario Description'
      const new_row = { id: k, property_description: rows[k] }
      for (let i = 0; i < scenarioData?.length; i++) {
        // iterates over the unique columns (i.e. scenarioData has data in column major so whatever is length of the scenarioData it becomes the total column number)
        for (let j = 0; j < scenarioData[i].properties?.length; j++) {
          // iterates over whole data for each unique column
          const curr_data = scenarioData[i].properties[j]
          if (curr_data?.propertyDescription === rows[k]) {
            // check if we are accessing correct row to append data in new_row array
            new_row[
              curr_data?.propertyScenario?.toLowerCase().replace(' ', '_')
            ] = [
                isNaN(curr_data?.propertyValue)
                  ? curr_data?.propertyValue
                  : parseFloat(curr_data?.propertyValue).toFixed(2),
                '(' + curr_data?.propertySource?.substring(0, 2) + ')'
              ]
          }
        }
      }
      row_data.push(new_row)
    }
    return row_data
  }
  const getPersonalizationColumData = (label, rows) => {
    const getData = (data, property, format, filter, filterBy) => {
      return (
        (filter !== undefined && filterBy !== undefined
          ? data?.[property]
            ?.filter((item) => item[`${filter}`] === filterBy)
            ?.map((item) => format(item))
            ?.join(', ')
          : data?.[property]?.map((item) => format(item))?.join(', ')) || ''
      )
    }

    switch (label) {
      case 'Sector Constraints':
        return getData(
          rows,
          'sectorData',
          (item) => `${item.assetClassDesc}|${item.max}-${item.min}`,
          'sectorExclude',
          0
        )
      case 'Industry Constraints':
        return getData(
          rows,
          'industryData',
          (item) => `${item.industryId}|${item.max}-${item.min}`,
          'industryExclude',
          0
        )
      case 'Factor Tilts Constraints':
        return getData(
          rows,
          'factorTiltsData',
          (item) => `${item.attrLabel}|${item.statsValue}`
        )
      case 'Substitution Constraints':
        return getData(
          rows,
          'substitutionData',
          (item) => `${item.targetName}|${item.sourceName}`
        )
      case 'Cash Constraints':
        return getData(
          rows,
          'cashConstraintsData',
          (item) => `${item.propertyDesc}|${item.propertyValue}`,
          'propertyType',
          'CASH_CONSTRAINT'
        )
      case 'Tax Constraints':
        return rows?.taxConstraintsData?.length === 1
          ? Object.keys(rows?.taxConstraintsData[0]).filter(key => key !== 'accountId' && rows?.taxConstraintsData[0][key])?.map((key) => `${response?.fieldLabels?.taxConstraintsData[key]}|${rows?.taxConstraintsData[0][key]}`)?.join(', ')
          : ''
      case 'Security Constraints':
        return getData(
          rows,
          'securityData',
          (item) => `${item.name}|${item.restrictionDesc}`
        )
      case 'Other Constraints':
        return getData(
          rows,
          'otherConstraintsData',
          (item) => `${item.propertyDesc}|${item.propertyValue}`,
          'propertyType',
          'OTHER_CONSTRAINT'
        )
      default:
        return ''
    }
  }
  const getDefaultData = (dataLabel) => {
    if (name === 'Sector constraints') {
      return {
        headingColumns:
          {
            index: 'Sr No',
            assetClassDesc: 'Sector',
            relative: 'Relative',
            sectorExclude: 'Exclude',
            min: 'Min',
            max: 'Max'
          } || defaultColumns,
        tableRows: (dataLabel?.data || response.data || [])?.slice(0, 15)
      }
    } else if (name === 'Factor Tilts constraints') {
      delete dataLabel?.fieldLabels?.statsLevelValue
      return {
        headingColumns: dataLabel?.fieldLabels || defaultColumns,
        tableRows: (dataLabel?.data || response.data || [])?.slice(0, 15)
      }
    } else if (name === 'Top Positions') {
      return {
        headingColumns:
          {
            localSymbol: 'Security',
            weight: 'weight(%)'
          } || defaultColumns,
        tableRows: (dataLabel?.data || response.data || [])?.slice(0, 15)
      }
    } else if (name === 'Risk By Sector') {
      return {
        headingColumns:
          {
            index: 'Sr No',
            assetClassDesc: 'Sector',
            weight: 'Weight',
            riskColor: 'Risk',
            statsValue: 'MCTE'
          } || defaultColumns,
        tableRows:
          (dataLabel?.data ||
            response.data ||
            [])?.slice(0, 10)
      }
    } else if (name === 'Risk By Security') {
      return {
        headingColumns:
          {
            index: 'Sr No',
            localSymbol: 'Name',
            name: 'Security Name',
            weight: 'Weight',
            riskColor: 'Risk',
            statsValue: 'MCTE'
          } || defaultColumns,
        tableRows: (dataLabel?.data || response.data || [])?.sort((a, b) => Math.abs(b.statsValue) - Math.abs(a.statsValue))?.slice(0, 10)
      }
    } else if (name === 'Scenario') {
      const columns = ['Property Description']
      const rows = []

      dataLabel.data?.forEach((element) => {
        element.properties?.forEach((property) => {
          if (
            property.propertyScenario &&
            !columns.find((scenario) => scenario === property.propertyScenario)
          ) {
            columns.push(property.propertyScenario)
          }
          if (
            property.propertyDescription !== 'N/A' &&
            !rows.find((scenario) => scenario === property.propertyDescription)
          ) {
            rows.push(property.propertyDescription)
          }
        })
      })
      return {
        headingColumns: generateScenarioFieldLabels(columns) || defaultColumns,
        tableRows:
          (generateScenarioRowData(rows, dataLabel?.data) || response.data || [])?.slice(0, 15)
      }
    } else if (name === 'Market cap vertical bar table') {
      const headingColumns = {
        noNameShow: '',
        Micro: 'Micro',
        Small: 'Small',
        Mid: 'Mid',
        Large: 'Large',
        Mega: 'Mega'
      }

      const tableRows = []
      for(let obj in dataLabel?.fieldLabels) {
        let temp = { noNameShow: dataLabel?.fieldLabels[obj]}
        for (let i = 0; i < dataLabel?.marketCapAllocationData?.length;i++) {
          temp = {
            ...temp,
            [dataLabel?.marketCapAllocationData[i]?.marketCapName]: dataLabel?.marketCapAllocationData[i][obj]
          }
        }
        tableRows.push(temp)
      }
      return {
        headingColumns,
        tableRows
      }
    } else if (name === 'Market Cap table') {
      const marketCapAllocationData = dataLabel?.marketCapAllocationData || []
      const fields = []
      fields.push(['noNameShow', ''])
      for (let i = 0; i < marketCapAllocationData.length; i++) {
        fields.push([marketCapAllocationData[i].marketCapName, marketCapAllocationData[i].marketCapName])
      }
      const headingColumns = Object.fromEntries(fields) || defaultColumns
      const tableData1 = [
        {
          noNameShow: 'Portfolio'
        },
        {
          noNameShow: 'Benchmark'
        }
      ]

      for (let index = 0; index < tableData1.length; index++) {
        const maps = ['Micro', 'Small', 'Mid', 'Large', 'Mega']
        const portfolio = marketCapAllocationData.map(
          (items) => items.portfolio
        )
        const strategy = marketCapAllocationData.map((items) => items.strategy)
        for (let i = 0; i < portfolio.length; i++) {
          const element = maps[i]

          if (tableData1[index]?.noNameShow === 'Portfolio') {
            Object.assign(tableData1[index], {
              [element]: portfolio[i]
            })
          } else {
            Object.assign(tableData1[index], {
              [element]: strategy[i]
            })
          }
        }
      }
      return {
        headingColumns,
        tableRows: tableData1
      }
    } else if (name === 'Personalization constraints') {
      const label = [
        'Sector Constraints',
        'Industry Constraints',
        'Factor Tilts Constraints',
        'Substitution Constraints',
        'Cash Constraints',
        'Tax Constraints',
        'Security Constraints',
        'Other Constraints'
      ]
      const headingColumns = {
        Constraints: 'Sector Constraints',
        noNameShow: 'Sector'
      }

      const tableRows = []
      for (let index = 0; index < label.length; index++) {
        const element = label[index]

        tableRows.push({
          Constraints: element,
          noNameShow: getPersonalizationColumData(element, dataLabel)
        })
      }

      return {
        headingColumns,
        tableRows
      }
    } else if (name === 'Factor table') {
      const factorAllocationData = dataLabel?.factorAllocationData || []
      const headingColumns = {
        factorName: 'Factor Name',
        portfolio: 'Portfolio (%)',
        strategy: 'Strategy (%)'
      }
      return {
        headingColumns,
        tableRows: factorAllocationData?.slice(0, 4)
      }
    } else {
      return {
        headingColumns: dataLabel?.fieldLabels || defaultColumns,
        tableRows: (dataLabel?.data?.map((data, index) => ({
          index: index + 1,
          ...data
        })) || response.data || []?.slice(0, 15))
      }
    }
  }

  const getIndustryData = (dataLabel, widths, textAlign) => ({
    ...getDefaultData(dataLabel),
    tableWidth: widths,
    tableTextAlign: textAlign
  })

  switch (name) {
    case 'Sector constraints':
      return {
        ...getDefaultData(response),
        tableWidth: [7, 33, 15, 15, 15, 15],
        tableTextAlign: [
          'text-right',
          'text-left',
          'text-left',
          'text-left',
          'text-right',
          'text-right'
        ]
      }

    case 'Industry constraints':
      return getIndustryData(
        response,
        [25, 25, 10, 10, 10, 10, 10],
        [
          'text-left',
          'text-left',
          'text-left',
          'text-left',
          'text-left',
          'text-right',
          'text-right'
        ]
      )

    case 'Security constraints':
      return getIndustryData(
        response,
        [30, 15, 15, 10, 15, 15],
        [
          'text-left',
          'text-left',
          'text-left',
          'text-right',
          'text-left',
          'text-left'
        ]
      )

    case 'Substitution constraints':
      return getIndustryData(
        response,
        [30, 25, 22, 23],
        [
          'text-left',
          'text-left',
          'text-left',
          'text-left'
        ]
      )

    case 'Factor Tilts constraints':
      return {
        ...getDefaultData(response),
        tableWidth: [50, 50],
        tableTextAlign: ['text-left', 'text-right']
      }
    case 'Scenario':
      return {
        ...getDefaultData(response),
        tableWidth: [30, 24, 23, 23],
        tableTextAlign: ['text-left', 'text-left', 'text-left', 'text-left']
      }
    case 'Top Positions':
      return {
        ...getDefaultData(response?.data),
        tableWidth: [50, 50],
        tableTextAlign: ['text-left', 'text-right']
      }
    case 'Risk By Sector':
      return {
        ...getDefaultData(response?.data),
        tableWidth: [10, 37, 14, 25, 14],
        tableTextAlign: [
          'text-right',
          'text-left',
          'text-right',
          'text-right',
          'text-right'
        ]
      }
    case 'Risk By Security':
      return {
        ...getDefaultData(response?.data),
        tableWidth: [10, 16, 35, 9, 20, 10],
        tableTextAlign: [
          'text-right',
          'text-left',
          'text-left',
          'text-right',
          'text-right',
          'text-right'
        ]
      }
    case 'Market Cap table':
      return {
        ...getDefaultData(response?.data),
        tableWidth: [25, 15, 15, 15, 15, 15],
        tableTextAlign: [
          'text-left',
          'text-right',
          'text-right',
          'text-right',
          'text-right',
          'text-right'
        ]
      }
    case 'Factor table':
      return {
        ...getDefaultData(response?.data),
        tableWidth: [50, 25, 25],
        tableTextAlign: [
          'text-left',
          'text-right',
          'text-right'
        ]
      }
    case 'Personalization constraints':
      return {
        ...getDefaultData(response?.data),
        tableWidth: [30, 70],
        tableTextAlign: ['text-left', 'text-left']
      }

    case 'Market cap vertical bar table':
      return {
        ...getDefaultData(response?.data),
        tableWidth: [25, 15, 15, 15, 15, 15],
        tableTextAlign: [
          'text-left',
          'text-right',
          'text-right',
          'text-right',
          'text-right',
          'text-right'
        ]
      }

    case 'Custom Table':
      return {
        ...getDefaultData(response?.data)
      }
    default:
      return getDefaultData()
  }
}

export const commonSetDataElement = (data, performanceData, chartIndex) => {
  if (
    (!data.value && performanceData) ||
    data?.value?.datasets.length !== performanceData?.datasets.length ||
    data?.value?.labels.length !== performanceData?.labels.length
  ) {
    const { labels, datasets } = performanceData

    const mappedLabels =
      Array.isArray(labels) &&
      labels.map((label, i) => ({
        name: label,
        hidden: data?.value?.labels
          ? data?.value?.labels[i]
            ? data?.value?.labels[i].hidden
            : false
          : false
      }))

    const mappedDatasets =
      Array.isArray(datasets) &&
      datasets.map(({ label, backgroundColor }, i) => ({
        label,
        backgroundColor: data?.value?.datasets
          ? data?.value?.datasets[i]
            ? data?.value?.datasets[i].backgroundColor
            : backgroundColor
          : backgroundColor,
        hidden: data?.value?.datasets
          ? data?.value?.datasets[i]
            ? data?.value?.datasets[i].hidden
            : false
          : false
      }))

    const chartValues = {
      labels: mappedLabels,
      datasets: mappedDatasets,
      chartIndex,
      pageIndex: data.pageIndex
    }
    store.dispatch(addChartValues(chartValues))
  }
}
export const updatedElements = (value, chartInstance, performanceData) => {
  if (!value || !value.datasets || !value.labels || !chartInstance) {
    return
  }
  try {
    const { datasets, labels } = value
    datasets.forEach((dataset, index) => {
      const chartDataset = chartInstance.data.datasets[index]
      Object.assign(chartDataset, {
        hidden: dataset.hidden,
        backgroundColor: dataset.backgroundColor,
        borderColor: dataset.borderColor
      })
    })

    const filteredLabels = labels.filter((label) => !label.hidden)
    chartInstance.data.labels =
      Array.isArray(filteredLabels) && filteredLabels.map((label) => label.name)

    labels.forEach((label, index) => {
      if (label.hidden) {
        performanceData.datasets.forEach((dataset, datasetsIndex) => {
          chartInstance.data.datasets[datasetsIndex].data.splice(index, 1)
        })
      }
    })

    chartInstance.data = {
      labels: chartInstance.data.labels,
      datasets: chartInstance.data.datasets
    }

    chartInstance.update()
  } catch (error) {
    console.error('Error in useEffect:', error)
  }
}
