import { useState } from 'react'
import { Box, Grid, IconButton, Menu, MenuItem, Skeleton, Tooltip, Typography } from '@mui/material'
import { RefreshOutlined } from '@mui/icons-material'
import { FaChartColumn } from 'react-icons/fa6'
import PerformanceChart from './PerformanceChart'
import MarketValueChart from './MarketValueChart'
import BenchmarkChart from './BenchmarkChart'

const PerformanceTab = ({ performance, benchmark, marketValue, umaType, loading }) => {
  const [isChartZoomed, setIsChartZoomed] = useState(false)
  const [seed, setSeed] = useState(0)
  const [graphTypeAnchorEl, setGraphTypeAnchorEl] = useState(null)
  const [selectedGraph, setSelectedGraph] = useState(umaType === 'SCENARIO_TRANSITION' ? 'BENCHMARK_RETURNS' : 'PERFORMANCE')

  function resetZoom() {
    setSeed(Math.random())
    setIsChartZoomed(false)
  }

  const handleGraphChange = (graph) => {
    setSelectedGraph(graph)
    setGraphTypeAnchorEl(null)
    document.body.classList.remove('model-open')
  }

  const formatChartTooltipLabel = (graph) => {
    switch (graph) {
      case 'PERFORMANCE':
        return 'Performance'
      case 'MV':
        return 'Market Value'
      case 'BENCHMARK_RETURNS':
        return 'Benchmark Returns'
      default:
        return graph
    }
  }

  const handleChartClick = (event) => {
    document.body.classList.add('model-open')
    setGraphTypeAnchorEl(event.currentTarget)
  }

  return (
    loading ? (
      <Skeleton variant='rectangular' sx={{ mt: '10px', height: '450px' }} />
    ) : (
      <Grid container>
        <Grid item xs={12} sm={12} sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }} >
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', height: '36px' }}>
            <Typography sx={{ fontSize: '16px', color: '#74788D', fontWeight: 600 }}>{formatChartTooltipLabel(selectedGraph)}</Typography>
            <Box sx={{ display: 'flex' }}>
              {
                isChartZoomed
                  ? (
                    <Tooltip title='Reset Chart'>
                      <IconButton onClick={resetZoom}>
                        <RefreshOutlined sx={{ height: 20, width: 20, color: '#74788D' }} />
                      </IconButton>
                    </Tooltip>
                  )
                  : ''
              }
              {
                umaType !== 'SCENARIO_TRANSITION'
                  ? (
                    <Tooltip title={formatChartTooltipLabel(selectedGraph)}>
                      <IconButton onClick={handleChartClick}>
                        <FaChartColumn size='15px' color='#74788D' />
                      </IconButton>
                    </Tooltip>
                  )
                  : ''
              }
              <Menu
                anchorEl={graphTypeAnchorEl}
                open={Boolean(graphTypeAnchorEl)}
                onClose={() => setGraphTypeAnchorEl(null)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
                }}
                size='small'
              >
                <MenuItem onClick={() => handleGraphChange('PERFORMANCE')} value='PERFORMANCE'>
                  Performance
                </MenuItem>
                <MenuItem onClick={() => handleGraphChange('MV')} value='MV'>
                  Market Value
                </MenuItem>
                <MenuItem onClick={() => handleGraphChange('BENCHMARK_RETURNS')} value='BENCHMARK_RETURNS'>
                  Benchmark Returns
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} id='chart-container'>
          {selectedGraph === 'PERFORMANCE' ? <PerformanceChart data={performance} seed={seed} setIsChartZoomed={setIsChartZoomed} /> : ''}
          {selectedGraph === 'MV' ? <MarketValueChart data={marketValue} seed={seed} setIsChartZoomed={setIsChartZoomed} /> : ''}
          {selectedGraph === 'BENCHMARK_RETURNS' ? <BenchmarkChart data={benchmark} seed={seed} setIsChartZoomed={setIsChartZoomed} /> : ''}
        </Grid>
      </Grid >
    )
  )
}

export default PerformanceTab
