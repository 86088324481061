import { FormatDateAPI, FormatPayload } from '../components/FormatAPIRequest'
import { CreateAPI } from '../components/Actions'
import { getUserRole } from '../../../utils/getUserRole'

export const CreateReportData = async (newRow, tabIndex) => {
  switch (tabIndex) {
    case 'stats-level-code':
      return await CreateStatusLevelCode(newRow)
    case 'stats-master':
      return await CreateStatusMasterCode(newRow)
    default:
      break
  }
}

const CreateStatusLevelCode = async (newRow) => {
  const userRole = getUserRole()
  let payload = {
    statsLevelCd: newRow?.statsLevelCd?.toUpperCase(),
    statsLevelDesc: newRow?.statsLevelDesc,
    startDate: newRow?.startDate ? FormatDateAPI(newRow.startDate) : null,
    endDate: newRow?.endDate ? FormatDateAPI(newRow.endDate) : null
  }

  payload = FormatPayload(payload)

  return CreateAPI(
    'baseReportingMaintainURL',
    `data-maintenance/v1/${userRole}/reports/stats-level-code`,
    payload
  )
}

const CreateStatusMasterCode = async (newRow) => {
  const userRole = getUserRole()
  let payload = {
    statsCode: newRow?.statsCode?.toUpperCase(),
    statsDesc: newRow?.statsDesc,
    statsDtype: newRow?.statsDtype,
    startDate: newRow?.startDate ? FormatDateAPI(newRow.startDate) : null,
    endDate: newRow?.endDate ? FormatDateAPI(newRow.endDate) : null
  }

  payload = FormatPayload(payload)

  return CreateAPI(
    'baseReportingMaintainURL',
    `data-maintenance/v1/${userRole}/reports/stats-mast`,
    payload
  )
}
