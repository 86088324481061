import React from 'react'
import { Card, CardContent, Typography } from '@mui/material'
import { Doughnut } from 'react-chartjs-2'

function MonitoringChart (props) {
  const { data, options, height, chartTitle } = props
  // Filter out datasets with any value equal to 0
  const filteredData = data.datasets.map((dataset) => ({
    ...dataset,
    data: dataset.data.filter((value) => value !== 0)
  }))

  const datalabelsOptions = {
    color: 'white',
    formatter: (value, context) => {
      return context.dataset.data.length > 1 ? `${value}` : value
    }
  }

  return (
    <Card sx={{ height: { height }, display: 'flex' }} className='report-card'>
      <CardContent className='pie-chart-card report-card-border' sx={{ position: 'relative' }}>
        <Typography
          sx={{
            marginTop: '20px',
            color: '#74788D',
            fontWeight: '600',
            fontFamily: 'Open Sans'
          }}
        >{chartTitle}
        </Typography>
        <Doughnut
          data={{ ...data, datasets: filteredData }}
          options={{
            ...options,
            plugins: {
              ...options.plugins,
              datalabels: datalabelsOptions
            }
          }}
        />
      </CardContent>
    </Card>
  )
}

export default MonitoringChart
