import { toast } from 'react-toastify'

export const setInToStorage = (data) => {
  localStorage.setItem('token', data.token)
};

export const getFromStorage = (key) => localStorage.getItem(key)

const showToast = (msg, type) => {
  toast[type](msg, {
    position: 'bottom-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: false,
    theme: 'colored',
    whiteSpace: 'pre-line',
    pauseOnFocusLoss: false
  })
};
export const toastError = (msg) => showToast(msg, 'error')
export const toastSuccess = (msg) => showToast(msg, 'success')
export const ARIS_COLORS = ['#002A59', '#3369A6', '#3B79BF', '#6A86A6', '#6E9FC0', '#95CEEC', '#BCBEBE', '#9DDFD0', '#93DAC0', '#AEC7E2', '#B3D1F2', '#CFE5FF', '#E0E8EF', '#DBE9F9', '#C6F7FA']