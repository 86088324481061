export const hexToRgba = (hexCode, opacity = 1) => {
  if (hexCode?.startsWith('#')) {
    hexCode = hexCode?.slice(1)
  }
  const r = parseInt(hexCode?.substring(0, 2), 16)
  const g = parseInt(hexCode?.substring(2, 4), 16)
  const b = parseInt(hexCode?.substring(4, 6), 16)
  const rgba = `rgba(${r}, ${g}, ${b}, ${opacity})`

  return rgba
}
