import React, { useState } from 'react'
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai'

const ChartLabelEditor = ({ headingText, onSave, onCancel, textarea }) => {
  const [editorName, setEditorName] = useState(headingText)

  const handleInputChange = (e) => {
    setEditorName(e.target.value)
  }

  const handleSaveClick = () => {
    onSave(editorName)
  }

  return (
    <div className='flexOrAlign'>
      <div className='flexOrAlign'>
        {textarea ? (
          <textarea
            type='text'
            className='report_edit_input margin-top-1 common-text-data'
            value={editorName}
            rows={1}
            // style={{ width: (editorName.length + 1) * 8 + 'px' }}
            onChange={handleInputChange}
          />
        ) : (
          <input
            type='text'
            className='report_edit_input margin-top-1 common-text-data'
            value={editorName}
            // style={{ width: (editorName.length + 1) * 8 + 'px' }}
            onChange={handleInputChange}
          />
        )}

        <div className='margin-x-1'>
          <AiFillCheckCircle
            color='#727474'
            size={20}
            onClick={handleSaveClick}
          />
        </div>
        <div className='margin-x-1'>
          <AiFillCloseCircle color='#727474' size={20} onClick={onCancel} />
        </div>
      </div>
    </div>
  )
}

export default ChartLabelEditor
