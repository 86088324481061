import { styled, Box } from '@mui/material'

const StyledTradePopupBox = styled(Box)(({ theme }) => ({
  maxHeight: '650px',
  '@media (max-width: 1600px)': {
    maxHeight: '620px' // Apply this style on screens with a max width of 600px or less.
  },
  '@media (max-width: 1400px)': {
    maxHeight: '450px' // Apply this style on screens with a max width of 600px or less.
  }
}))

export default StyledTradePopupBox
