import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addChartValues } from '../../../store/pdf-customizer-reducer/Action/chartDropAction'
import { accountDataApi } from '../../../utils/pdf-customizer/_data'
import { useAuth } from '../../../contexts/AuthContext'
import { fetchDataAndDispatch } from './APIResponseConverts/APIResponseConverts'
import './section-page.css'

const SectionPage = ({
  data
}) => {
  const dispatch = useDispatch()
  const { user } = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const { accountDataResponse, isResponseElements } = useSelector(
    (state) => state.chartState
  )
  const templateData = useSelector(state => state.templateData)

  useEffect(() => {
    if (isResponseElements && !accountDataResponse && !isLoading) {
      setIsLoading(true)
      fetchDataAndDispatch(
        () => accountDataApi(templateData, user?.userGroup),
        'ACCOUNT_DATA',
        dispatch,
        true
      )
    }
    if (isResponseElements && accountDataResponse) {
      setIsLoading(false)
    }
  }, [isResponseElements, accountDataResponse])

  useEffect(() => {
    if (accountDataResponse?.data)
      dispatch(addChartValues({ pageIndex: data?.pageIndex, chartIndex: data?.chartIndex, sectionUserName: accountDataResponse?.data?.accountName }))
  }, [accountDataResponse])

  const chartStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end'
  }

  return (
    isLoading
      ? <span className='loading-text'>Loading...</span>
      : <div
        className={'width-100 height-100'}
        style={chartStyle}
      >
        <div className='section-info' style={{ height: '160px', width: '100%', border: '1px solid #d9d9d9' }}>
          <div className='width-100 padding-1 username'>
            <p style={{ margin: '4px 0', fontSize: '11px', fontWeight: 400, color: '#6d9ac3' }}>
              {data?.value?.sectionUserName || 'Section User Name'}
            </p>
          </div>
          <div className='bordered-div' />
          <div className='width-100 section-title'>
            <p style={{ margin: '0', fontWeight: 500, fontSize: '22px' }}>
              {data?.value?.sectionTitle || 'Section title'}
            </p>
          </div>
          <div className='width-100 section-sub-title'>
            <p style={{ margin: '0', fontSize: '10px', fontWeight: 400 }}>
              {data?.value?.sectionSubTitle || 'Section sub title'}
            </p>
          </div>
        </div>
      </div>
  )
}

export default SectionPage
