import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { API } from 'aws-amplify'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import { Autocomplete, Box, Button, Card, CardContent, debounce, FormControl, Grid, IconButton, InputLabel, MenuItem, Modal, Select, Skeleton, TextField, Tooltip, Typography } from '@mui/material'
import { randomId } from '@mui/x-data-grid-generator'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import * as Sentry from '@sentry/react'
import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import utc from 'dayjs/plugin/utc'
import { useAuth } from '../../contexts/AuthContext'
import { useErrorToast } from '../../hooks/useErrorToast'
import { checkInstrumentSearchQuery } from '../../utils/searchQueryUtils'
import Loader from '../Loader'
import RestrictionTable from './RestrictionTable'

dayjs.extend(isSameOrAfter) // Extend dayjs with the isSameOrAfter plugin
dayjs.extend(utc)

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  padding: '16px 24px',
  width: '500px',
  borderRadius: '4px'
}

export default function ManageRestriction (props) {
  const { showError } = useErrorToast()
  const { user } = useAuth()
  const [restrictedCodes, setRestrictedCodes] = useState([])
  const params = useParams()
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const [startDate, setStartDate] = useState(dayjs.utc().startOf('day')) // Use UTC for initial value
  const [endDate, setEndDate] = useState(dayjs.utc().startOf('day')) // Use UTC for initial value
  const [selectKey, setSelectKey] = useState('')
  const [selectInstrID, setSelectInstrId] = useState('')
  const [selectedValues, setSelectedValues] = useState([])
  const [selectValue, setSelectValue] = useState('')
  const [autocompleteValue, setAutocompleteValue] = useState('')
  const [sourceOptions, setSourceOptions] = useState([])
  const [isLoadingSource, setIsLoadingSource] = useState(false)
  const [errorName, setErrorName] = useState(false)
  const [errorCode, setErrorCode] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [rows, setRows] = useState(props?.strategyRestrictionInfo ? props?.strategyRestrictionInfo : [])
  const [errorEndDate, setErrorEndDate] = useState(null)

  const handleClose = () => {
    setOpen(false)
    setErrorName(false)
    setErrorCode(false)
    setAutocompleteValue('')
    setSelectValue('')
    setSelectInstrId('')
    setSelectKey('')
    setSelectedValues([])
    setStartDate(dayjs.utc().startOf('day'))
    setEndDate(dayjs.utc().startOf('day'))
    setIsLoading(false)
  }

  useEffect(() => {
    if (props?.strategyRestrictionInfo) {
      setRows(props?.strategyRestrictionInfo || [])
    }
  }, [props?.strategyRestrictionInfo])

  const addSecurityRestrictions = async () => {
    const id = randomId()
    setIsLoading(true)
    try {
      const response = await API.post(
        'baseUriAccountOptimization',
        `account-customization/v1/${user?.userGroup}/active-manager/${params.strategyId}/instr-restrictions`,
        {
          body: {
            startDate: startDate.toISOString().slice(0, 10),
            instrId: selectInstrID,
            restrictionId: selectKey,
            endDate: endDate.toISOString().slice(0, 10)
          }
        }
      )
      if (response.success && response?.data && response?.data?.length) {
        const updatedSecurityList = [{ id, ...response?.data[0] }, ...rows]
        setRows(updatedSecurityList)
        props?.onRestrictionUpdate && props?.onRestrictionUpdate(updatedSecurityList)
        handleClose()
      }
    } catch (error) {
      showError(error.response?.data?.errorInfo?.userMessage || error.message)
      setIsLoading(false)
    }
  }

  const onRestrictionCodeChange = (event, key) => {
    if (event.target.value !== '') {
      setSelectValue(event.target.value)
      setSelectKey(key)
      setSelectedValues([...selectedValues, selectValue])
      setErrorCode(false)
    }
  }
  const onSourceNameChange = (query, newValue) => {
    setIsLoadingSource(true)
    setAutocompleteValue(newValue)
    setSelectInstrId(newValue?.instrId || '')
    if (newValue) {
      setSelectedValues([...selectedValues, newValue])
    }
    if (errorName && newValue?.name) {
      setErrorName(false)
    }
  }

  const restrictionCodesList = async () => {
    API.get(
      'baseUriTransactionalMaster',
      `transactional-master/v1/${user?.userGroup}/restriction-codes`,
      { queryStringParameters: {} }
    )
      .then((response) => {
        if (response.data) {
          setRestrictedCodes([...response.data])
        }
      })
      .catch((error) => {
        showError(error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
  }

  const debouncedCheckInstrumentSearchQuery = useMemo(
    () =>
      debounce((query) => {
        if (query === '') {
          return
        }

        if (checkInstrumentSearchQuery(query)) {
          setIsLoadingSource(true)
          API.get(
            'baseUriTransactionalMaster',
            `transactional-master/v1/${user?.userGroup}/instruments`,
            { queryStringParameters: { search: query } }
          )
            .then((response) => {
              if (response?.data) {
                setSourceOptions([...response?.data])
              }
            })
            .catch((error) => {
              showError(error?.response?.data?.errorInfo?.userMessage || error.message)
              Sentry.captureException(error?.response?.data?.errorInfo?.userMessage || error)
            })
            .finally(() => {
              setIsLoadingSource(false)
            })
        }
      }, 400),
    [user]
  )

  const onInputChange = (event) => {
    const query = (event?.target?.value || '').toString().trim()
    debouncedCheckInstrumentSearchQuery(query)
  }

  const submitSecurityHandler = (event) => {
    if (autocompleteValue?.name && selectValue && errorEndDate === null && endDate !== null) {
      event.preventDefault()
      setErrorName(false)
      setErrorCode(false)
      addSecurityRestrictions()
    } else {
      if (!autocompleteValue?.name) {
        setErrorName(true)
      }
      if (!selectValue) {
        setErrorCode(true)
      }
    }
  }

  useEffect(() => {
    if (user) {
      restrictionCodesList()
    }
  }, [user])

  const renderSkeletonRows = () => {
    return Array.from({ length: 4 }).map((_, rowIndex) => (
      <tr key={`skeleton-row-${rowIndex}`}>
        {Array.from({ length: 7 }).map((_, colIndex) => (
          <td key={`skeleton-cell-${rowIndex}-${colIndex}`}>
            <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
          </td>
        ))}
      </tr>
    ))
  }

  const handleEndDateChange = (newDate) => {
    if (newDate === null || !dayjs(newDate).isValid()) {
      setErrorEndDate(true)
    } else {
      setErrorEndDate(false)
    }
    setEndDate(newDate)
  }

  return (
    <>
      {isLoading ? <Loader /> : ''}
      <Card sx={{ mt: 1 }}>
        <CardContent>
          {props?.loading
            ? <Box className='table-responsive'>
              <table>
                <thead>
                  <tr>
                    <th>Instrument Id</th>
                    <th>Local Symbol</th>
                    <th>Security Name</th>
                    <th>Restriction Type</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{renderSkeletonRows()}</tbody>
              </table>
              </Box>
            : <Box
                sx={{
                  width: '100%',
                  '& .actions': {
                    color: 'text.secondary'
                  },
                  '& .textPrimary': {
                    color: 'text.primary'
                  },
                  '.MuiDataGrid-footerContainer': {
                    border: 'none'
                  },
                  '.MuiDataGrid-root': {
                    border: 'none'
                  }
                }}
              >
              <>
                <Tooltip
                  disableFocusListener
                  disableTouchListener
                  placement='top'
                  title='Apply restrictions at a security level'
                >
                  <Button
                    variant='outlined'
                    color='primary'
                    startIcon={<AddIcon />}
                    onClick={handleOpen}
                    sx={{
                      fontFamily: 'Open Sans'
                    }}
                  >
                    Add Security
                  </Button>
                </Tooltip>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby='modal-modal-title'
                  aria-describedby='modal-modal-description'
                >
                  <Box sx={style}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Typography id='modal-modal-title' variant='h6' sx={{ fontWeight: 400, color: '#34475A' }}>
                        Add Security
                      </Typography>
                      <IconButton onClick={() => setOpen(false)}>
                        <CloseIcon />
                      </IconButton>
                    </Box>
                    <Box id='modal-modal-description' sx={{ mt: 2 }}>
                      <Box component='form'>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography sx={{ paddingRight: '99px', color: '#74788d!important', fontSize: '16px', fontWeight: 500 }}>Security *</Typography>
                            <Autocomplete
                              autoWidth
                              id='my-autocomplete'
                              options={sourceOptions}
                              loading={isLoadingSource}
                              getOptionLabel={(option) => option.name || ''}
                              onInputChange={onInputChange}
                              onChange={onSourceNameChange}
                              value={autocompleteValue}
                              sx={{
                                width: '260px'
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label='Security Name'
                                  error={errorName}
                                  variant='outlined'
                                  InputProps={{ ...params.InputProps, placeholder: 'Security Name here' }}
                                  required
                                  sx={{
                                    width: '260px'
                                  }}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography sx={{ paddingRight: '38px', color: '#74788d!important', fontSize: '16px', fontWeight: 500 }}>Restriction Type *</Typography>
                            <FormControl variant='outlined' error={errorCode} size='small'>
                              <InputLabel
                                id='demo-simple-select-label'
                                sx={{
                                  '&.MuiInputLabel-shrink': {
                                    marginTop: 0 // Margin when label is in shrinked state
                                  },
                                  '&:not(.MuiInputLabel-shrink)': {
                                    marginTop: '7px' // Margin when label is not in shrinked state
                                  }
                                }}
                              >Action *
                              </InputLabel>
                              <Select
                                labelId='my-dropdown-label'
                                label='Action'
                                id='my-dropdown'
                                value={selectValue}
                                onChange={(e) => onRestrictionCodeChange(e, restrictedCodes.find(option => option.restrictionDesc === e.target.value).restrictionId)}
                                className='actions-select'
                                native={false}
                                required
                                sx={{ width: '260px', height: '56px' }}
                              >
                                {restrictedCodes?.map((option, index) => {
                                  if (option.restrictionCode !== 'ONB' && option.restrictionCode !== 'ONS') {
                                    return (
                                      <MenuItem key={option.restrictionId} value={option.restrictionDesc}>
                                        {option.restrictionDesc}
                                      </MenuItem>
                                    )
                                  }
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography sx={{ paddingRight: '84px', color: '#74788d!important', fontSize: '16px', fontWeight: 500 }}>Start date *</Typography>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                label='Start date *'
                                value={startDate}
                                required
                                disablePast
                                onChange={setStartDate}
                                sx={{ width: '260px' }}
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography sx={{ paddingRight: '92px', color: '#74788d!important', fontSize: '16px', fontWeight: 500 }}>End date *</Typography>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                label='End date *'
                                value={endDate}
                                sx={{ width: '260px' }}
                                onChange={handleEndDateChange}
                                minDate={startDate}
                                onError={setErrorEndDate}
                                required
                                disablePast
                                error={errorEndDate}
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={12}>
                            <Box sx={{ textAlign: 'end', marginTop: '10px', marginRight: '18px' }}>
                              <Button
                                variant='contained'
                                onClick={submitSecurityHandler}
                              >
                                Submit
                              </Button>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Box>
                </Modal>
              </>
              <RestrictionTable data={rows} onRestrictionUpdate={props?.onRestrictionUpdate} />
              </Box>}
        </CardContent>
      </Card>
    </>
  )
}
