import React from 'react'
import { TableBody, TableCell, TableHead, TableRow, TableContainer, Table, Skeleton  } from '@mui/material'

const PolicyBreakCheckTableLoader = ({headers}) => {
    return (
        <>
            <TableContainer mt={5}>
            <Table className='risk-page-table'>
                <TableHead>
                <TableRow>
                    {headers.map(item => {
                    return (
                        <TableCell>{item.headerName}</TableCell>
                    )
                    })}
                </TableRow>
                </TableHead>
                <TableBody>
                {Array.from({length: 10}).map( _ => (
                    <TableRow>
                    {Array.from({length: 5}).map( _ => (
                    <TableCell>
                    <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={75} />
                    </TableCell>))}
                    <TableCell>
                    <Skeleton variant='circular' width={25} height={25} />
                    </TableCell>
                </TableRow>))}
                </TableBody>
            </Table>
            </TableContainer>
        </>
    )
}

export default PolicyBreakCheckTableLoader