import React, { useEffect, useRef, useState } from 'react'
import dayjs from 'dayjs'
import './Table.css'

function applyCurrencyFormat(formatObj, value) {
  if (value === null || value === undefined || value === '') {
    return ''
  }
  let finalCurrencyValue = Number.isInteger(value) ? parseInt(value) : parseFloat(value)
  if (formatObj?.decimal !== undefined) {
    finalCurrencyValue = parseFloat(finalCurrencyValue?.toFixed(formatObj?.decimal))
  }
  if (formatObj?.commas) {
    // decimal format can be added into toLocaleString parameter
    finalCurrencyValue = finalCurrencyValue?.toLocaleString(undefined, { maximumFractionDigits: 10 })
  }
  finalCurrencyValue = '$' + finalCurrencyValue
  return finalCurrencyValue
}

function applyPercentageFormat(formatObj, value) {
  if (value === null || value === undefined || value === '') {
    return ''
  }
  let finalNumber = parseFloat(value) * 100
  if (formatObj?.decimal !== undefined) {
    finalNumber = finalNumber.toFixed(formatObj?.decimal)
  }
  if (formatObj?.symbol) {
    finalNumber = finalNumber.toString() + '%'
  }
  return finalNumber
}

function applyNumberFormat(formatObj, value) {
  if (value === null || value === undefined || value === '') {
    return ''
  }
  let isInteger = Number.isInteger(value)
  let finalNumber = isInteger ? parseInt(value) : parseFloat(value)
  if (formatObj?.decimal !== undefined) {
    finalNumber = finalNumber.toFixed(formatObj?.decimal)
  }
  return finalNumber
}

function applyDateFormat(formatObj, value) {
  if (value === null || value === undefined || value === '') {
    return ''
  }
  let finalDate = value
  if (formatObj?.type) {
    finalDate = dayjs(value).format(formatObj?.type)
  }
  return finalDate
}

function applyTextFormat(formatObj, value) {
  return value
}

const RenderCell = ({ formatObj, type, value }) => {
  switch (type) {
    case 'Currency':
      return applyCurrencyFormat(formatObj, value)
    case 'Percentage':
      return applyPercentageFormat(formatObj, value)
    case 'Number':
      return applyNumberFormat(formatObj, value)
    case 'Date':
      return applyDateFormat(formatObj, value)
    case 'Text':
      return applyTextFormat(formatObj, value)
    default:
      return value
  }
}

const Table = ({ columns, rows, storeColumnWidth }) => {
  const tableRef = useRef()
  const headerRef = useRef([null])
  const mousePreviousX = useRef(null)
  const [activeIndex, setActiveIndex] = useState(null)
  const [width, setWidth] = useState(columns?.map(colObj => colObj.width)?.join(' '))

  useEffect(() => {
    headerRef.current = Array.from({ length: columns.length })
    setWidth(columns.map(colObj => colObj.width).join(' '))
  }, [columns])

  const handleMouseUp = (e) => {
    mousePreviousX.current = null
    const columnWiseWidth = headerRef.current.map(colEl => `${colEl.offsetWidth}px`)
    storeColumnWidth(columns.map((colObj, colIndex) => ({ ...colObj, width: columnWiseWidth[colIndex] })))
    setActiveIndex(null)
    document.removeEventListener('mousemove', handleMouseMove)
    document.removeEventListener('mouseup', handleMouseUp)
    resetCursor()
  }

  const handleMouseMove = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const gridColumns = headerRef.current.map((colEl, index) => {
      if (index === activeIndex) {
        const width = e.clientX - (mousePreviousX.current || 0)
        mousePreviousX.current = e.clientX
        return `${colEl?.offsetWidth + width}px`
      }
      return `${colEl?.offsetWidth}px`
    })
    setWidth(gridColumns.join(' '))
  }

  const handleMouseDown = (e) => {
    updateCursor()
    mousePreviousX.current = e.clientX
    document.addEventListener('mousemove', handleMouseMove)
    document.addEventListener('mouseup', handleMouseUp)
  }

  const resetColumnWidth = (e) => {
    storeColumnWidth(columns.map(colObj => ({ ...colObj, width: '1fr' })))
  }

  const updateCursor = () => {
    document.body.style.cursor = 'col-resize';
    document.body.style.userSelect = 'none';
  }

  const resetCursor = () => {
    document.body.style.removeProperty('cursor');
    document.body.style.removeProperty('user-select');
  }

  return (
    <div className='custom-table' style={{ gridTemplateColumns: width.split(' ').every(w => w === '1fr') ? `repeat(${columns.length}, minmax(0, 1fr))` : width }} ref={tableRef}>
      <div className='header'>
        {
          columns?.map((col, colIndex) => (
            <div
              key={colIndex}
              className='header-data-parent headingBorder'
              ref={(el) => headerRef.current[colIndex] = el}
              style={{ justifyContent: col?.headerType === 'NUMBER' ? 'end' : 'start' }}
              role='columnheader'
              data-field={col.field}
              onMouseEnter={(e) => setActiveIndex(colIndex)}
              onMouseLeave={(e) => setActiveIndex(null)}
            >
              <div className='header-data'>
                {col?.displayHeaderName}
              </div>
              <div className={`resize-handle ${activeIndex !== null ? 'active' : ''}`}
                onDoubleClick={resetColumnWidth} onMouseDown={(e) => handleMouseDown(e)} />
            </div>
          ))
        }
      </div>
      <div className='rowGroup'>
        {
          rows?.length ? rows?.map((row, rowIndex) => (
            <div className='row' key={rowIndex}>
              {
                columns.map((col, colIndex) => (
                  col.hidden !== undefined && col.hidden === true
                    ? ''
                    : <div key={colIndex}
                      className={`row-data-parent ${rowIndex === rows.length - 2 && rows[rows.length - 1]?.lastTotalRow ? 'total-row' : row?.lastTotalRow ? 'total-row' : 'borderBottom'}`}
                      data-colindex={colIndex}
                      style={{ justifyContent: col?.headerType === 'NUMBER' ? 'end' : 'start' }}
                    >
                      <div className='row-data' style={{ marginLeft: colIndex === 0 ? `${row.level * 8}px` : '0px' }}>
                        <RenderCell formatObj={col?.formatObj} type={col?.displayType} value={row[col.field]} />
                      </div>
                    </div>
                ))
              }
            </div>
          ))
            : columns?.length ? (
              <div className='row'>
                <div className='row-data-parent' style={{
                  gridColumn: `1/${Number(columns?.length) + 1}`
                }}>No rows found</div>
              </div>
            )
              : ''
        }
      </div>
    </div>
  )
}
export default Table