import CloseIcon from '@mui/icons-material/Close'
import { Box, Card, CardContent, Dialog, DialogContent, DialogTitle, IconButton, Stack, Typography } from '@mui/material'
import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro'
import { formatNumbers } from '../../Report/components/dataProcess/DataProcess'

const columns = [
  { field: 'name', headerName: 'Name', flex: 1 },
  { field: 'symbol', headerName: 'Symbol', flex: 1 },
  { field: 'shares', headerName: 'Shares', flex: 1, type: 'number' },
  {
    field: 'purchaseCost',
    headerName: 'Total Purchase Cost',
    flex: 1,
    type: 'number',
    renderCell: (params) => {
      // Check if the symbol is CASH or USD
      if (params?.row?.symbol === '__CASH' || params?.row?.symbol === '__USD') {
        const sharesValue = params?.row?.shares || 0
        if (sharesValue < 0) {
          return `-${formatNumbers(Math.abs(sharesValue), 2, '$')}`
        }
        return formatNumbers(sharesValue, 2, '$')
      }
      return formatNumbers(params?.value || 0, 2, '$')
    }
  },
  { field: 'purchaseDate', headerName: 'Purchase Date', flex: 1 },
  { field: 'isoCountryCode', headerName: 'ISO Country Code', flex: 1 }
]

const ViewPortfolioPopup = ({ transitionUploadPortfolioData, open, closeModal }) => {
  return (
    <>
      <Dialog fullScreen open={open}>
        <DialogTitle>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Typography variant='h6' style={{ fontWeight: 400, color: '#34475A' }}>View Portfolio</Typography>
            <Box display='flex' justifyContent='flex-end' alignItems='center'>
              <IconButton
                onClick={() => closeModal()}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Stack
            spacing={{ xs: 2, sm: 4 }}
            direction='row'
            sx={{ marginBottom: '20px' }}
          >
            {/* <Stack spacing={1}> */}
            {transitionUploadPortfolioData?.fundingInformation?.uploadType === 'manual'
              ? (
                <Typography variant='body1'>
                  <strong>Funding Source:</strong> {transitionUploadPortfolioData?.fundingInformation?.fundingSource}
                </Typography>
                )
              : null}

            {
              (transitionUploadPortfolioData?.fundingInformation?.fundingSource === 'SECURITY' ||
                transitionUploadPortfolioData?.fundingInformation?.fundingSource === 'CASH_SECURITY')
                ? (
                  <Typography variant='body1'>
                    <strong>Total Portfolio Market Value:</strong>
                    {
                      (transitionUploadPortfolioData?.fundingInformation?.totalPortfolioMarketValue < 0 ? '-' : '') +
                      formatNumbers(transitionUploadPortfolioData?.fundingInformation?.totalPortfolioMarketValue || 0, 2, '$')
                    }
                  </Typography>
                  )
                : null
            }
            {/* </Stack> */}
            {
              transitionUploadPortfolioData?.fundingInformation?.uploadType === 'urlupload'
                ? (
                  <>
                    {/* <Stack spacing={1}> */}
                    <Typography variant='body1'><strong>Custodian:</strong> {transitionUploadPortfolioData?.fundingInformation?.custodianId?.custodianName}</Typography>
                    <Typography variant='body1'><strong>Custodian Account Number:</strong> {transitionUploadPortfolioData?.fundingInformation?.custodianNumber}</Typography>
                    {/* </Stack> */}
                  </>
                  )
                : <></>
            }
            {
              transitionUploadPortfolioData?.fundingInformation?.fundingSource === 'CASH' || transitionUploadPortfolioData?.fundingInformation?.fundingSource === 'CASH_SECURITY'
                ? (
                  <>
                    {/* <Stack spacing={1}> */}
                    <Typography variant='body1'>
                      <strong>Total Cash Value:</strong>
                      {
                        (transitionUploadPortfolioData?.fundingInformation?.totalCashValue < 0 ? '-' : '') +
                        formatNumbers(transitionUploadPortfolioData?.fundingInformation?.totalCashValue || 0, 2, '$')
                      }
                    </Typography>
                    {/* </Stack> */}
                  </>
                  )
                : <></>
            }
          </Stack>
          {
            transitionUploadPortfolioData?.data && transitionUploadPortfolioData?.data?.length
              ? (
                <Card className='card-layout' mt={3}>
                  <CardContent>
                    <DataGridPro
                      autoHeight
                      columns={columns}
                      density='compact'
                      rows={transitionUploadPortfolioData?.data || []}
                      disableRowSelectionOnClick
                      pagination
                      pageSizeOptions={[15, 25, 50, 100]}
                      initialState={{
                        ...transitionUploadPortfolioData?.data?.rows?.initialState,
                        pagination: { paginationModel: { pageSize: 15 } }
                      }}
                      sx={(theme) => ({
                        [`.${gridClasses.main}`]: {
                          overflow: 'unset'
                        },
                        [`.${gridClasses.columnHeaders}`]: {
                          position: 'sticky',
                          backgroundColor: theme.palette.background.paper,
                          top: 0,
                          zIndex: 1
                        },
                        [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
                          color: '#74788d',
                          fontWeight: 600
                        },
                        [`.${gridClasses.footerContainer}`]: {
                          position: 'sticky',
                          bottom: '-1px',
                          backgroundColor: theme.palette.background.paper,
                          zIndex: 1
                        },
                        [`.${gridClasses.virtualScroller}`]: {
                          '::-webkit-scrollbar': {
                            width: '0px !important'
                          },
                          overflowY: 'auto !important',
                          height: 'calc(100vh - 72px - 48px - 20px - 24px - 16px - 24px - 39px - 52px)'
                        }
                      })}
                    />
                  </CardContent>
                </Card>
                )
              : <></>
          }
        </DialogContent>
      </Dialog>
    </>
  )
}

export default ViewPortfolioPopup
