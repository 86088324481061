import React from 'react'
import { Box, Grid, Typography } from '@mui/material'

export const Methodology = (param) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={3}>
        {
          param.pageDetails.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <Grid item xs={12} md={6}>
                  <Grid item xs={12} md={12}>
                    <Box>
                      <Typography variant='subtitle1' sx={{ color: '#34475A', fontFamily: 'Open Sans' }} gutterBottom>
                        {item.sectionName}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Box sx={{ textAlign: 'justify' }}>
                      <Typography variant='body2' sx={{ fontFamily: 'Open Sans' }}>
                        {item.longText}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </React.Fragment>
            )
          })
        }
      </Grid>
    </Box>
  )
}
