import { Box, IconButton, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

const invalidErrorModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  px: 3,
  py: 2,
  width: '100%',
  minWidth: '600px',
  maxWidth: '800px',
  borderRadius: '4px',
  height: '650px',
  maxHeight: '100vh'
}

const InvalidRowEntriesPopup = ({ isErrorModelOpen, setIsErrorModelOpen, errorModalContent }) => {
  return (
    <Modal
      open={isErrorModelOpen}
      onClose={() => setIsErrorModelOpen(false)}
      centered
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <Box sx={invalidErrorModalStyle} overflow='auto'>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography id='modal-title' variant='h6' component='h2'>
            Missing Information in Uploaded File
          </Typography>
          <IconButton onClick={() => setIsErrorModelOpen(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{ mt: 2 }}>
          {(errorModalContent?.headerErrors?.length > 0 || errorModalContent?.rowErrors?.length > 0) && (
            <TableContainer>
              <Table
                stickyHeader
                sx={{
                  width: '100%',
                  '.MuiTableCell-root': {
                    padding: '6px 8px !important'
                  }
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Type</TableCell>
                    <TableCell align='left'>Error Message</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {errorModalContent?.headerErrors?.length
                    ? <TableRow key='header'>
                      <TableCell width='80px' sx={{ verticalAlign: 'top' }}>Header</TableCell>
                      <TableCell sx={{ color: '#34475a', p: '4px', fontWeight: 600 }}>
                        <Box sx={{ maxHeight: errorModalContent?.rowErrors?.length === 0 ? '80vh' : '40vh', overflowY: 'auto' }}>
                          <ul>
                            {errorModalContent?.headerErrors?.map((errorMessage, index) => (
                              <li key={index} style={{ marginBottom: '4px', marginLeft: '20px' }}>
                                <Typography key={index} sx={{ whiteSpace: 'normal', wordBreak: 'break-word', color: '#34475a', fontWeight: 600 }}>
                                  {errorMessage}
                                </Typography>
                              </li>
                            ))}
                          </ul>
                        </Box>
                      </TableCell>
                    </TableRow>
                    : ''}
                  {errorModalContent?.rowErrors?.length
                    ? <TableRow key='rows'>
                      <TableCell sx={{ verticalAlign: 'top' }}>Rows</TableCell>
                      <TableCell sx={{ color: '#34475a', p: '4px', fontWeight: 600 }}>
                        <Box sx={{ maxHeight: errorModalContent?.headerErrors?.length === 0 ? '80vh' : '40vh', overflowY: 'auto' }}>
                          <ul>
                            {errorModalContent?.rowErrors?.map((errorMessage, index) => (
                              <li key={index} style={{ marginBottom: '4px', marginLeft: '20px' }}>
                                <Typography sx={{ whiteSpace: 'normal', wordBreak: 'break-word', color: '#34475a', fontWeight: 600 }}>
                                  {errorMessage}
                                </Typography>
                              </li>
                            ))}
                          </ul>
                        </Box>
                      </TableCell>
                    </TableRow>
                    : ''}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      </Box>
    </Modal>
  )
}

export default InvalidRowEntriesPopup