import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { storeSvgCode } from '../../../store/pdf-customizer-reducer/Action/chartDropAction'
import { CircularProgress } from '@mui/material'

const ImageDisplay = ({ imageUrl, className, style, width, height, pageIdx, chartIdx, svgContent, name }) => {
  // const [blobURL, setBlobURL] = useState(null)
  const [loading, setLoading] = useState(false)
  const [isSvg, setIsSvg] = useState(imageUrl.toLowerCase().endsWith('.svg') && imageUrl.startsWith('https://pdf-custom-images'))
  const [svgCode, setSvgCode] = useState(svgContent)
  const dispatch = useDispatch()
  useEffect(() => {
    // Check if the imageUrl ends with ".svg"
    // Example: Fetch blob for non-SVG image (You may need to handle SVG differently)
    const isSVG = imageUrl.toLowerCase().endsWith('.svg') && imageUrl.startsWith('https://pdf-custom-images')
    setIsSvg(isSVG)
    if (isSVG && !svgContent && !loading) {
      setLoading(true)
      fetch(imageUrl)
        .then((response) => (!isSVG ? response.blob() : response.text()))
        .then((blob) => {
          if (isSVG) {
            setLoading(false)
            setSvgCode(blob)
            dispatch(
              storeSvgCode({
                value: blob,
                pageIdx,
                chartIdx,
                imageUrl,
                name
              })
            )
          }
        })
        .catch((error) => {
          console.error('Error fetching image:', error)
        })
    } else if (isSvg && svgContent) {
      setSvgCode(svgContent)
    }
  }, [imageUrl])

  // return loading ? (
  //   <p
  //     className={className ? className : ''}
  //     width={width || ''}
  //     style={style ? style : {}}
  //   >
  //     Loading...
  //   </p>
  // ) : svgCode ? (
  //   // Render SVG directly
  //   <div
  //     className={className ? className : ''}
  //     width={width || ''}
  //     style={style ? style : {}}
  //     dangerouslySetInnerHTML={{ __html: svgCode }}
  //   />
  // ) : blobURL ? (
  //   // Render image using blob URL
  //   <img
  //     className={className ? className : ''}
  //     src={blobURL}
  //     alt="LiveImage"
  //     width={width || ''}
  //     loading="lazy"
  //     style={style ? style : {}}
  //   />
  // ) : (
  //   <img
  //     className={className ? className : ''}
  //     src={imageUrl}
  //     alt="LiveImage"
  //     width={width || ''}
  //     loading="lazy"
  //     style={style ? style : {}}
  //   />
  // )
  return (
    <>
      {
        isSvg && svgCode
          ? <div
              height={height || 'auto'}
              className={className ? className + ' svg-container' : 'svg-container'}
              width={width || ''}
              style={style || {}}
              dangerouslySetInnerHTML={{ __html: svgCode }}
            />
          : (isSvg && loading)
              ? <CircularProgress size='20px' sx={style || {}} />
              : <img
                  className={className || ''}
                  src={imageUrl}
                  alt='LiveImage'
                  width={width || ''}
                  height={height || 'auto'}
                  loading='eager'
                  style={style || {}}
                />
      }
    </>
  )
}

export default ImageDisplay
