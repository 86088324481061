import { FormatDateAPI, FormatPayload } from '../components/FormatAPIRequest'
import { UpdateAPI } from '../components/Actions'
import { getUserRole } from '../../../utils/getUserRole'

export const UpdateInstrumentData = async (updatedRow, oldRow, tabIndex) => {
  switch (tabIndex) {
    case 'asset-class-map':
      return await UpdateInstrumentMaster(updatedRow, oldRow)
    case 'instrument-split':
      return await UpdateInstrumentSplitCal(updatedRow, oldRow)
    case 'instrument-delist':
      return await UpdateInstrumentDelistCal(updatedRow, oldRow)
    default:
      return {}
  }
}

const UpdateInstrumentMaster = async (updatedRow, oldRow) => {
  const userRole = getUserRole()
  const dataDate = oldRow?.dataDate ? FormatDateAPI(updatedRow.dataDate) : null
  const updTs = oldRow?.updTs ? new Date(oldRow?.updTs).toISOString() : null

  let payload = {
    assetClassCd: updatedRow.assetClassCd
  }
  payload = FormatPayload(payload)

  return await UpdateAPI(
    'baseInstrumentMaintainURL',
    `data-maintenance/v1/${userRole}/instruments/${oldRow.instrId}/asset-class/${oldRow.assetClassCd}/update-time/${updTs}/data-date/${dataDate}`,
    payload
  )
}

const UpdateInstrumentSplitCal = async (updatedRow, oldRow) => {
  const userRole = getUserRole()
  const effectiveDate = updatedRow?.effectiveDate ? FormatDateAPI(updatedRow?.effectiveDate) : null
  const endDate = updatedRow?.endDate ? FormatDateAPI(updatedRow?.endDate) : null

  const announcementDt = oldRow?.announcementDate ? FormatDateAPI(oldRow?.announcementDate) : null
  const startDate = oldRow?.startDate ? FormatDateAPI(oldRow?.startDate) : null
  const instrId = oldRow?.instrId ? oldRow?.instrId : null

  let payload = {
    ratio: updatedRow.ratio,
    effectiveDate,
    adjustmentFactor: updatedRow.adjustmentFactor,
    endDate
  }

  payload = FormatPayload(payload)

  return await UpdateAPI(
    'baseInstrumentMaintainURL',
    `data-maintenance/v1/${userRole}/instruments/splits-cal/${announcementDt}/${instrId}/${startDate}`,
    payload
  )
}

const UpdateInstrumentDelistCal = async (updatedRow, oldRow) => {
  const userRole = getUserRole()
  const oldStartDate = oldRow?.startDate ? FormatDateAPI(oldRow?.startDate) : null
  const effectiveDate = updatedRow?.effectiveDate ? FormatDateAPI(updatedRow?.effectiveDate) : null
  const endDate = updatedRow?.endDate ? FormatDateAPI(updatedRow?.endDate) : null
  const startDate = updatedRow?.startDate ? FormatDateAPI(updatedRow?.startDate) : null
  const instrId = oldRow?.instrId ? oldRow?.instrId : null

  let payload = {
    startDate,
    effectiveDate,
    endDate
  }
  payload = FormatPayload(payload)

  return await UpdateAPI(
    'baseInstrumentMaintainURL',
        `data-maintenance/v1/${userRole}/instruments/delist-cal/${instrId}/${oldStartDate}`,
        payload
  )
}
