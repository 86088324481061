import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SiteFrame from './../../../../assets/images/pdf-customizer/img/Design-Element.png'
import dayjs from 'dayjs'
import { accountDataApi } from '../../../../utils/pdf-customizer/_data'
import { useAuth } from '../../../../contexts/AuthContext'
import ImageDisplay from '../../common/ImageDisplay'
import { fetchDataAndDispatch } from '../APIResponseConverts/APIResponseConverts'
import './coverPage1.css'

const CoverPage2 = ({ data, chartIndex, index }) => {
  const [isLoading, setIsLoading] = useState(false)
  const { accountDataResponse, isResponseElements } = useSelector(
    (state) => state.chartState
  )
  const templateData = useSelector(state => state.templateData)
  const { user } = useAuth()
  const dispatch = useDispatch()

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isResponseElements && !accountDataResponse && !isLoading) {
          setIsLoading(true)
          await fetchDataAndDispatch(
            () => accountDataApi(templateData, user?.userGroup),
            'ACCOUNT_DATA',
            dispatch,
            true
          )
        }
        if (isResponseElements && accountDataResponse) {
          setIsLoading(false)
        }
      } catch (error) {
        console.error('Error fetching and dispatching data:', error)
      }
    }

    fetchData()
  }, [isResponseElements, accountDataResponse, dispatch])

  return (
    <div className='width-100 height-100 '>
      <div className='cover-page2 '>
        <img src={SiteFrame} alt='SiteFrame' className='siteframe' />
        <div className='text-align-end'>
          <p className='fontSize-22 margin-bottom-1 '>
            {data?.value?.templateType || 'Position Report'}
          </p>
          <p className='fontSize-10'>For {data?.value?.accountName}</p>
          <p className='fontSize-10 margin-bottom-3'>
            {dayjs()?.format('MMMM DD, YYYY') || 'OCTOBER 16, 2023'}
          </p>
          <p className='fontSize-8'>Prepared By</p>
          <p className='fontSize-8'>
            {data?.value?.companyName || 'ARIS INVESTING'}
          </p>
        </div>
      </div>
      <ImageDisplay
        imageUrl={
          data.value.companyLogoUrl ||
          'https://aris-public-assets-dev.s3.us-east-2.amazonaws.com/ARIS+Logo/aris-investing-logo.svg'
        }
        alt='Logo'
        className='pdf-logo'
        style={{
          objectFit: 'contain',
          position: 'absolute',
          bottom: '50px',
          left: '38px'
        }}
        height={30}
        pageIdx={data?.pageIndex}
        chartIdx={data?.chartIndex}
        name={data.name}
        svgContent={data?.value?.svgCode || null}
      />
    </div>
  )
}

export default CoverPage2
