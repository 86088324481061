export const PERFORMANCE_CHART_TYPE = 'PERFORMANCE_CHART'
export const MARKET_CHART_TYPE = 'MARKET_CHART'
export const FACTOR_CHART_TYPE = 'FACTOR_CHART'
export const ACCOUNT_DATA_TYPE = 'ACCOUNT_DATA'
export const ALLOCATION_CHART_TYPE = 'ALLOCATION_CHART'
export const IS_API_CALL = 'IS_API_CALL'
export const SECTOR_CONSTRAINTS_TYPE = 'SECTOR_CONSTRAINTS'
export const POLICY_CONSTRAINTS_TYPE = 'POLICY_CONSTRAINTS'
export const TOP_POSITION_SECURITY_TYPE = 'TOP_POSITION_SECURITY'
export const SECTOR_WISE_RISK_TYPE = 'SECTOR_WISE_RISK'
export const SECURITY_WISE_RISK_TYPE = 'SECURITY_WISE_RISK'
export const TAX_SAVING_DATA_TYPE = 'TAX_SAVING_DATA'
export const TAX_MANAGEMENT_DATA_TYPE = 'TAX_MANAGEMENT_DATA'
export const SECURITY_BY_WEIGHT_TYPE = 'SECURITY_BY_WEIGHT'
export const INITIAL_PORTFOLIO_BLOCK_TYPE = 'INITIAL_PORTFOLIO_BLOCK_DATA'
export const PERSONALIZATION_DATA_TYPE = 'PERSONALIZATION_DATA'
export const SUMMARY_DATA_TYPE = 'SUMMARY_DATA'
export const INDUSTRY_CONSTRAINTS_TYPE = 'INDUSTRY_CONSTRAINTS'
export const SECURITY_CONSTRAINTS_TYPE = 'SECURITY_CONSTRAINTS'
export const SUBSTITUTION_CONSTRAINTS_TYPE = 'SUBSTITUTION_CONSTRAINTS'
export const FACTOR_TILTS_CONSTRAINSTS_TYPE = 'FACTOR_TILTS_CONSTRAINSTS'
export const SCENARIO_CONSTRAINTS_TYPE = 'SCENARIO_CONSTRAINTS'
export const YTD_TAX_DETAILS_CONSTRAINTS_TYPE = 'YTD_TAX_DETAILS_CONSTRAINTS'
export const DIVERSIFICATION_STATISTICS_TYPE = 'DIVERSIFICATION_STATISTICS'
export const PORTFOLIO_STATISTICS_TYPE = 'PORTFOLIO_STATISTICS'
export const CUSTOM_TABLE_TYPE = 'CUSTOM_TABLE_TYPE'
export const CASH_FLOW_SUMMARY_TYPE = 'CASH_FLOW_SUMMARY_TYPE'
export const AGGREGATE_MARKET_VALUE = 'AGGREGATE_MARKET_VALUE'
export const AGGREGATE_PERFORMANCE = 'AGGREGATE_PERFORMANCE'
export const ACTIVITY_SUMMARY_TYPE = 'ACTIVITY_SUMMARY_TYPE'
