import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined'

function Transition (props) {
  return <Slide direction='up' {...props} />
}

export default function Alert ({
  handleClose,
  handleSubmit,
  isOpen,
  hasTwoButtons = false,
  submitButtonText,
  title,
  text
}) {
  return (
    <Dialog
      open={isOpen}
      transition={Transition}
      keepMounted
      disableEscapeKeyDown
      onClose={handleSubmit}
      aria-labelledby='alert-dialog-slide-title'
      aria-describedby='alert-dialog-slide-description'
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '10px'
        }
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{
        textAlign: 'center',
        padding: 4,
        paddingBottom: 0
      }}
      >
        <GppGoodOutlinedIcon
          sx={{
            fontSize: '80px',
            color: '#3369A6',
            marginBottom: 1
          }}
        />
        <DialogContentText
          sx={{
            fontWeight: '500',
            fontSize: '22px',
            color: '#34475A',
            fontFamily: 'Open Sans !important'
          }}
        >{text}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{
        paddingX: 3,
        paddingY: 3,
        justifyContent: 'center'
      }}
      >
        {hasTwoButtons
          ? (
            <Button onClick={handleClose} variant='raised' color='primary'>
              CANCEL
            </Button>
            )
          : null}
        <Button
          onClick={handleSubmit}
          fullWidth
          variant='contained'
          size='large'
          sx={{
            width: '80%',
            backgroundColor: '#3369A6',
            borderRadius: '50px',
            '&:hover': {
              backgroundColor: '#3369A6'
            }
          }}
        >
          {submitButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
