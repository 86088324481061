import React, { useEffect, useMemo, useState } from 'react'
import { Autocomplete, Button, Grid, TextField, debounce } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import * as Sentry from '@sentry/react'
import dayjs from 'dayjs'
import { useErrorToast } from '../../../hooks/useErrorToast'
import { useAuth } from '../../../contexts/AuthContext'

const CustomSearchComponent = ({ searchFunc, id, name, searchKey, showData }) => {
  const [inputValue, setInputValue] = useState('')
  const [value, setValue] = useState(null)
  const [date, setDate] = useState(null)
  const [loading, setLoading] = useState(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [options, setOptions] = useState({})
  const { showError } = useErrorToast()
  const { user } = useAuth()
  let timeout

  // Uses debounce time to avoid subsequent API calls and calls API after 400ms
  const handleInputChange = useMemo(
    () =>
      debounce(async (request) => {
        // Prevent API call on option selection. Autocomplete changes input value on option selection that leads to invalid search string containing ()
        const selectedOption = getOption(value)
        if (request?.trim().length > 2 && selectedOption.trim() !== request) {
          setIsDropdownOpen(true)
          clearTimeout(timeout)
          if (id === 'last-market-data' && !date) {
            showError('Please select date')
            setLoading(false)
            return
          }
          const optionsList = await searchFunc(request?.toUpperCase(), date ? dayjs(date).format('YYYY-MM-DD') : '')
          if (optionsList.error) {
            showError(optionsList.error)
            Sentry.captureException(optionsList.error)
          } else if (optionsList?.data?.data) {
            optionsList.data.data = optionsList?.data?.data.map((optionObj, index) => ({ ...optionObj, idx: index }))
            setOptions(optionsList)
          }
        } else {
          if ((date && dayjs(date).isValid())) {
            if (request?.trim()?.length > 1 && request?.trim()?.length < 3 && selectedOption.trim() !== request) {
              timeout = setTimeout(() => {
                showError('Input length must be at least 3 characters')
                setLoading(false)
              }, 300)
            } else {
              if (request?.trim().length < 1 || request?.trim().length >= 3 || request === '') {
                clearTimeout(timeout)
              }
              // if (request.trim().length >= 3) {
              // const optionsList = await searchFunc(request ? request.toUpperCase() : '', date ? dayjs(date).format('YYYY-MM-DD') : '')
              // if (optionsList.error && loading) {
              //   showError(optionsList.error)
              //   Sentry.captureException(optionsList.error)
              // } else if (optionsList?.data?.data) {
              //   optionsList.data.data = optionsList?.data?.data.map((optionObj, index) => ({ ...optionObj, idx: index }))
              //   setOptions(optionsList)
              //   setIsDropdownOpen(true)
              // }
              // }
            }
          }
        }
        setLoading(false)
      }, 500),
    [date, searchFunc, value]
  )

  const getOption = (optionValue) => {
    if (optionValue) {
      let selectedOption = ''
      if (id === 'instr-master') {
        selectedOption = `${optionValue?.instrId || optionValue?.localSymbol || optionValue?.name} ${optionValue.name ? `(${optionValue.name})` : ''} ${optionValue.localSymbol ? `(${optionValue.localSymbol})` : ''}`
      } else {
        selectedOption = `${optionValue[searchKey]} ${optionValue.name ? `(${optionValue.name})` : ''}`
      }
      return selectedOption.trim()
    } else {
      return ''
    }
  }

  const handleOptionSelect = (selectedOption) => {
    setValue(selectedOption)
    setIsDropdownOpen(false)
    // Keep selected options and remove others from option list
    setOptions(prevOptions => ({
      ...prevOptions,
      data: {
        ...prevOptions.data,
        data: selectedOption.idx !== -1 ? [prevOptions?.data?.data[selectedOption.idx]] : prevOptions?.data?.data
      }
    }))
  }

  useEffect(() => {
    if (user) {
      setDate(dayjs(new Date()))
    }
  }, [user])

  // checks length of input to call search API
  useEffect(() => {
    if ((inputValue && inputValue?.trim().length >= 3)) {
      setLoading(true)
      handleInputChange(inputValue)
    } else {
      if (!(inputValue && inputValue?.trim().length)) {
        setValue(null)
        setIsDropdownOpen(false)
      } if (date) {
        if (date && (inputValue || inputValue === '')) {
          handleInputChange(inputValue)
        }
      }
      setLoading(false)
    }
  }, [inputValue, date])

  return (
    <Grid container gap={2} sx={{ padding: '20px 0' }}>
      <Grid item xs={12} lg={5}>
        <Autocomplete
          id={id}
          isOptionEqualToValue={(option, value) => (option[searchKey] === value[searchKey])}
          value={value}
          forcePopupIcon={false}
          name={id}
          disableClearable
          options={options?.data?.data || []}
          noOptionsText='No Record Found'
          onChange={(_, newValue) => {
            handleOptionSelect(newValue)
          }}
          getOptionLabel={(option) => {
            if (option) {
              let label = ''
              if (id === 'instr-master') {
                label = (option.instrId || option.localSymbol || option.name)
                  ? `${option?.instrId} ${option.name ? `(${option.name})` : ''} ${option.localSymbol ? `(${option.localSymbol})` : ''}`
                  : ''
              } else {
                label = option[searchKey] ? `${option[searchKey]} ${option.name ? `(${option.name})` : ''}` : ''
              }
              return label.trim()
            }
          }}
          loading={loading}
          onInputChange={(_, newInputValue) => {
            setInputValue(newInputValue)
            setIsDropdownOpen(false)
          }}
          fullWidth
          inputValue={inputValue}
          renderInput={(params) => <TextField {...params} label={`Search ${name} by ${searchKey}`} variant='outlined' size='small' fullWidth />}
          open={isDropdownOpen}
        />
      </Grid>
      <Grid item xs={5} lg={2}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            disableFuture
            value={date || dayjs(new Date())}
            onChange={setDate}
            slotProps={{ textField: { size: 'small' } }}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={2} lg={1}>
        <Button
          variant='contained'
          fullWidth
          // disabled={!(value && value.idx !== -1)}
          onClick={() => {
            if (!inputValue || inputValue?.trim().length < 3) {
              showError('Instrument Id is mandatory')
            } else {
              showData(options)
            }
          }}
        >
          View
        </Button>
      </Grid>
    </Grid>
  )
}

export default CustomSearchComponent
