import React from 'react'
import { Box } from '@mui/material'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { formatCurrency } from '../../../../utils/FormateCurrenyInMilion'
import { v4 as uuidv4 } from 'uuid'

export function ReportExpandAssetAllocation (props) {
  const { row } = props
  let flattenedRows = []
  if (typeof row === 'object' && Array.isArray(row?.securityLevelAllocation)) {
    // Flatten the securityLevelAllocation array
    flattenedRows = row?.securityLevelAllocation?.map((security, index) => ({
      id: uuidv4(),
      assetClass: row?.assetClass,
      name: security?.name,
      weight: (Number(security?.weight) * 100).toFixed(2),
      costBasis: (Number(security?.costBasis)).toFixed(2),
      currentValue: (Number(security?.currentValue)).toFixed(2)
    }))
  }

  const columns = [
    { field: 'id', headerName: '', width: 50, valueFormatter: () => '' },
    { field: 'name', headerName: 'Asset Category', flex: 1 },
    {
      field: 'weight',
      headerName: 'Weight(%)',
      align: 'right',
      type: 'number',
      flex: 1
    },
    {
      field: 'costBasis',
      headerName: 'Cost Basis ($)',
      align: 'right',
      type: 'number',
      flex: 1,
      valueFormatter: (params) => formatCurrency(params.value) // Use the valueFormatter for costBasis column
    },
    {
      field: 'currentValue',
      headerName: 'Current value ($)',
      align: 'right',
      type: 'number',
      flex: 1,
      valueFormatter: (params) => formatCurrency(params.value) // Use the valueFormatter for currentValue column
    }
  ]

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <DataGridPro
          density='compact'
          autoHeight
          rows={flattenedRows}
          columns={columns}
          getRowId={(row) => row.id}
          columnHeaderHeight={0}
          hideFooter
          disableSelectionOnClick
        />
      </Box>
    </>
  )
}
