import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Line } from 'react-chartjs-2'
import { Chart as ChartJS, registerables } from 'chart.js'
import {
  fetchDataAndDispatch
} from '../APIResponseConverts/APIResponseConverts'
import { useDispatch, useSelector } from 'react-redux'
import { performanceChart } from '../APIResponseConverts/chartDefaultsData'
import { performanceLineOptions } from '../../common/ChartOptions/options'
import { addChartValues } from '../../../../store/pdf-customizer-reducer/Action/chartDropAction'
import { performanceApi } from '../../../../utils/pdf-customizer/_data'
import { performanceDataProcess } from '../../../Report/DataFormatting'
import ReactApexChart from 'react-apexcharts'
import { useAuth } from '../../../../contexts/AuthContext'

ChartJS.register(...registerables)

const PerformanceLineChart = ({
  data: { value, headingText, name, isActive, pageIndex, chartIndex },
  // chartIndex,
  index,
  chartHeight,
  chartWidth,
  clickEvent
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const { performanceChartResponse, isResponseElements } = useSelector(
    (state) => state.chartState
  )
  const templateData = useSelector(state => state.templateData)
  const chartRef = useRef(null)
  const { user } = useAuth()

  useEffect(() => {
    if (isResponseElements && !performanceChartResponse && !isLoading) {
      setIsLoading(true)
      fetchDataAndDispatch(() => performanceApi(templateData, user?.userGroup), 'PERFORMANCE_CHART', dispatch)
    }
    if (isResponseElements && performanceChartResponse) {
      setIsLoading(false)
    }
  }, [isResponseElements, performanceChartResponse, dispatch])

  const performanceData = performanceDataProcess(
    (performanceChartResponse || performanceChart)
  )

  const performanceChartDataset = (performanceData?.preTaxReturns || []).map((dataPoint, index) => ({
    x: new Date(performanceData?.date[index]).getTime(),
    y: dataPoint,
    postTaxReturns: performanceData?.postTaxReturns ? performanceData?.postTaxReturns[index] : ''
  }))
    .filter((point) => point.y !== null)

  const chartStyle = {
    height: '100%',
    width: '100%',
  }

  const lineChartData = useMemo(() => {
    // const commonStyles = {
    //   borderWidth: 0.5,
    //   pointRadius: 0,
    //   pointHitRadius: 0,
    //   pointHoverBorderWidth: 0,
    //   pointBorderWidth: 0,
    //   pointHoverRadius: 0
    // }

    const legendLabels = ['Pre Tax Returns', 'Post Tax Returns']

    return [
      {
        name: legendLabels[0],
        data: performanceChartDataset?.map((point) => ({ x: point?.x, y: point?.y !== null ? (point?.y || 0) * 100 : point?.y })),
        backgroundColor: '#44546A',
        borderColor: '#44546A'
        // fill: false,
        // ...commonStyles
      },
      {
        name: 'Post Tax Returns',
        data: performanceChartDataset?.find((point) => point?.postTaxReturns !== null)
          ? performanceChartDataset?.map((point) => ({
            x: point?.x,
            y: point?.postTaxReturns !== null ? (point?.postTaxReturns || 0) * 100 : point?.postTaxReturns
          }))
          : [],
        backgroundColor: '#7ba0c4',
        borderColor: '#7ba0c4'
        // fill: false,
        // ...commonStyles
      }
    ]
  }, [performanceChartDataset])

  let options = { ...performanceLineOptions(performanceChartDataset), colors: value?.datasets?.length ? value?.datasets?.map(dataset => dataset.backgroundColor) : ['#44546A', '#7ba0c4'] }

  useEffect(() => {
    if (value?.datasets) {
      const filteredColor = value?.datasets?.map((dataset) => dataset.backgroundColor)
      options = { ...performanceLineOptions(performanceChartDataset), colors: filteredColor }
    }
    if (value || !lineChartData) return
    const datasets = lineChartData

    const mappedDatasets =
      Array.isArray(datasets) &&
      datasets.filter(dataset => dataset?.data?.length).map(({ name, backgroundColor, borderColor }) => ({
        label: name,
        backgroundColor,
        borderColor,
        hidden: false
      }))

    const chartValues = {
      labels: [],
      datasets: mappedDatasets,
      chartIndex,
      pageIndex
    }

    dispatch(addChartValues(chartValues))
  }, [lineChartData, dispatch, pageIndex, value])

  useEffect(() => {
    const { current: chartInstance } = chartRef
    if (
      !value ||
      value?.datasets?.length === 0 ||
      !value?.labels?.length === 0 ||
      !chartInstance ||
      !lineChartData
    ) {
      return
    }
    try {
      const { datasets } = value
      // const mappedLabels = lineChartData?.map(dataset => dataset.name)
      // const labels =
      //   Array.isArray(mappedLabels) &&
      //   mappedLabels.map((label) => ({
      //     name: label,
      //     hidden: false
      //   }))
      datasets.forEach((dataset, index) => {
        const chartDataset = lineChartData[index]
        Object.assign(chartDataset, {
          hidden: dataset.hidden,
          backgroundColor: dataset.backgroundColor,
          borderColor: dataset.borderColor
        })
      })
      const filteredColor = datasets.map((dataset) => dataset.backgroundColor)
      options = { ...performanceLineOptions(performanceChartDataset), colors: filteredColor }
    } catch (error) {
      console.error('Error in useEffect:', error)
    }
  }, [value])

  const labelStyle = { margin: '0', fontSize: '10px', fontWeight: 500, textTransform: 'capitalize', lineHeight: '10px' }

  return (
    <div
      className={'group'}
      key={chartIndex + pageIndex}
      style={chartStyle}
    >
      <p style={labelStyle}>{headingText || name}</p>
      {isLoading
        ? <span className='loading-text'>Loading...</span>
        // : <Line ref={chartRef} data={lineChartData} options={options} />
        : <ReactApexChart ref={chartRef} options={options} series={lineChartData} type='area' height='100%' width='100%' id='performance-line-chart' />}
    </div>
  )
}

export default PerformanceLineChart
