import React, { useContext, useEffect, useState } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Button, Card, CardContent, Grid } from '@mui/material'
import { scenarioSensitivity } from '../../../contstants/constants'
import { accountDataContext, summaryDataContext } from '../Index'

export default function ScenarioSelection (props) {
  const { changeScenario } = props
  const summaryData = useContext(summaryDataContext)
  const accountData = useContext(accountDataContext)
  const [isOpen, setIsOpen] = useState(true)
  const toggleArrow = () => {
    setIsOpen(!isOpen)
  }
  const handleChange = (event) => {
    changeScenario(event.target.value)
  }

  // const highlightInitialScenario = scenarioSensitivity[accountData?.taxSensitivity] || summaryData?.data?.optimizationData?.filter((item) => item.scenarioCode !== 'No Trade' && item.scenarioCode !== 'Liquidation')[0]?.scenarioCode
  // selects first scenario from API response and highlights it or selects based on tax sensitivity
  const scenarioDesc = summaryData?.data?.optimizationData?.map((item) => item.scenarioDesc)
  const highlightInitialScenario = scenarioDesc && scenarioDesc.includes(scenarioSensitivity[accountData?.taxSensitivity]) ? scenarioSensitivity[accountData?.taxSensitivity] : summaryData?.data?.optimizationData?.filter((item) => item.scenarioDesc !== 'No Trade' && item.scenarioDesc !== 'Liquidation')[0]?.scenarioDesc

  useEffect(() => {
    changeScenario(highlightInitialScenario)
  }, [highlightInitialScenario])

  return (
    <div>
      <Button
        id='scenario-selection-button'
        aria-haspopup='true'
        aria-expanded='true'
        disableElevation
        sx={{
          color: 'white',
          background: 'rgba(52, 71, 90, 0.9)',
          border: 'none',
          width: '100%',
          borderRadius: '4px 4px 0px 0px',
          height: '48px',
          '&:hover': { backgroundColor: 'rgba(52, 71, 90, 0.9)' },
          fontFamily: 'Open Sans'
        }}
        onClick={toggleArrow}
        endIcon={isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        className='reportCardButton'
      >
        Scenario Selection
      </Button>
      {isOpen &&
        <Grid xs={12} sm={12}>
          <Card sx={{ minWidth: '100%' }}>
            <CardContent>
              <Grid xs={12} sm={12} container>
                <Grid sx={{ width: '100%' }}>
                  <select class='ui dropdown selection inputEdit ScenarioSelectionValues' style={{ fontFamily: 'Open Sans' }} onChange={handleChange}>
                    {
                      summaryData?.data?.optimizationData?.filter((item) => item.scenarioDesc !== 'No Trade' && item.scenarioDesc !== 'Liquidation').map((item) => {
                        return highlightInitialScenario === item.scenarioDesc
                          ? (<option key={item.scenarioId} value={item.scenarioDesc} selected>{item.scenarioDesc}</option>)
                          : (<option key={item.scenarioId} value={item.scenarioDesc}>{item.scenarioDesc}</option>)
                      })
                    }
                  </select>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>}
    </div>
  )
}
