import { IconButton } from '@mui/material'
import { gridFilteredDescendantCountLookupSelector, useGridApiContext, useGridSelector } from '@mui/x-data-grid-pro'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Badge from '@mui/material/Badge'
import ErrorIcon from '@mui/icons-material/Error'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import { useEffect, useState } from 'react'

const CustomGridTreeDataGroupingCell = (props) => {
  const { id, field, rowNode } = props
  const apiRef = useGridApiContext()
  const [failedParentScenario, setFailedParentScenario] = useState(props?.row?.failedScenario)
  const [failedChildScenario, setFailedChildScenario] = useState([])
  const filteredDescendantCountLookup = useGridSelector(
    apiRef,
    gridFilteredDescendantCountLookupSelector
  )
  const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0
  const handleClick = (event) => {
    if (rowNode.type !== 'group') {
      return
    }

    if (!rowNode.childrenExpanded && !props?.row?.failedScenario) {
      setFailedParentScenario(false)
    } else {
      setFailedParentScenario(props?.row?.failedScenario)
    }

    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded)
    apiRef.current.setCellFocus(id, field)
    event.stopPropagation()
  }

  useEffect(() => {
    if (failedParentScenario) {
      const childHasFailedScenario = props?.row?.account?.optDetails?.map((data) => {
        if (data.isDefault !== 1 && data.failedScenario) {
          return true
        }
        return false
      })
      setFailedChildScenario([...childHasFailedScenario])
    }
  }, [failedParentScenario])

  return (
    <div className={rowNode.type === 'group' && rowNode.childrenExpanded ? 'cell-group-open' : 'cell-' + rowNode.type}>
      {filteredDescendantCount > 0
        ? (
          <Badge
            badgeContent={<ErrorIcon
              color='error' sx={{
                height: '16.5px',
                minWidth: '16.5px'
              }}
                          />} color='error' sx={{
                            '.MuiBadge-badge': {
                              visibility: failedParentScenario ? 'visible' : 'hidden',
                              background: 'none',
                              top: '11px',
                              right: '9px'
                            }
                          }}
          >
            <IconButton onClick={handleClick} tabIndex={-1} size='small'>
              {rowNode.childrenExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Badge>
          )
        : (
            rowNode.type !== 'group' &&
             failedParentScenario
              ? (
                <ErrorIcon
                  color='error' sx={{
                    marginLeft: '13px',
                    height: '16.5px',
                    minWidth: '16.5px',
                    marginRight: '9px'
                  }}
                />
                )
              : (
                  failedChildScenario.length > 0
                    ? (
                      <ErrorIcon
                        color='error' sx={{
                          marginLeft: '13px',
                          height: '16.5px',
                          minWidth: '16.5px',
                          marginRight: '9px'
                        }}
                      />
                      )
                    : (
                      <></>
                      )
                )
          )}
    </div>

  )
}

export default CustomGridTreeDataGroupingCell
