import { useState, useEffect } from 'react'
import { Box, Button, Drawer, IconButton, Typography, OutlinedInput, InputLabel, MenuItem, FormControl, Select, Tooltip, Divider, Grid, InputAdornment, FormHelperText } from '@mui/material'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import ClearIcon from '@mui/icons-material/Clear'
import TradeChart from './TradeChart'
import CloseIcon from '@mui/icons-material/Close'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import GaugeChart from './GaugeChart'
import { InputAdornmentTextField } from '../../AccountOnboarding/components/inputAdornmentTextfield'
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined'
import { isNumberWithSign, isWholeNumber, numberWithSignPasteHandler, wholeNumberPasteHandler } from '../../../utils/NumberUtils'
import { moduleConfig } from '../../../contexts/data.js'
import { ACCESS_LEVEL } from '../../../contstants/constants.js'
import { useAuth } from '../../../contexts/AuthContext'

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FFFFFF',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 250,
    fontSize: theme.typography.pxToRem(14),
    padding: '16px',
    boxShadow: '0px 7px 29px rgba(100, 100, 111, 0.2)',
    borderRadius: '10px'
  }
}))

const taxStatus = [
  'Taxable',
  'Tax Exempt'
]

const modelChangeList = [
  { label: 'Yes', value: true },
  { label: 'No', value: false }
]

const TradeApprovalFilterMenu = ({
  optmRun,
  showFilters,
  closeFilters,
  removeAll,
  tradeStatusFilters,
  applyTradeStatusFilter,
  applyZoomedAccountFilter,
  tradeArray,
  getChartLabel,
  updateLegends,
  appliedLegend,
  // resetChipDatas,
  resetLegends,
  onTaxStatusAccountIdsChange,
  strategies,
  onStrategiesChange,
  onStrategyNames,
  // chipData,
  // isFiltersApplied,
  applyFilter,
  selectedFilters,
  isDefaultScenarioChecked,
  scenarioMappingWithLegend = {
    balanced: 'Balanced',
    mintax: 'Min Tax',
    model: 'Model'
  },
  delistData
}) => {
  const { checkAccess } = useAuth()
  const filterLabelList = [
    { name: 'Trade Status', type: 'select', filterKeyName: 'tradeStatus' },
    { name: 'Strategy', type: 'select', filterKeyName: 'strategy' },
    { name: 'Tax Status', type: 'select', filterKeyName: 'taxStatus' },
    { name: 'Trading Age', type: 'input', filterKeyName: 'tradingAge' },
    { name: 'Tracking Error Improvement', type: 'input', filterKeyName: 'trackingError' },
    { name: 'Tax Cost Benefit', type: 'input', filterKeyName: 'taxCostBenefit' },
    { name: 'Model Change', type: 'select', filterKeyName: 'isModelChange' },
    ...(checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.SUB_MODULE_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL }) &&
    checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL, id: null, component_name: moduleConfig.DELIST_INSTRUMENTS })
      ? [{ name: 'Delist', type: 'select', filterKeyName: 'delist' }]
      : []),
    { name: 'Initial Tracking Error vs Total Taxes', type: 'chart', filterKeyName: 'zoomedAccountIds' },
    { name: 'Proposed Tracking Error vs Total Taxes', type: 'chart', filterKeyName: 'zoomedAccountIds' },
    { name: 'Tracking Error Improvement vs Total Taxes', type: 'chart', filterKeyName: 'zoomedAccountIds' }
  ]
  const [scenarioWiseData, setScenarioWiseData] = useState({})
  const [zoomedAccountIds, setZoomedAccountIds] = useState(selectedFilters.find(filterObj => filterObj.name === 'zoomedAccountIds')?.value || [])
  const [tradeStatusList, setTradeStatusList] = useState(tradeStatusFilters)
  const [noOfAccounts, setNoOfAccounts] = useState(optmRun?.length)
  const [isLegendClicked, setIsLegendClicked] = useState(false)
  const [selectAllChecked, setSelectAllChecked] = useState(false)
  const [filterValue, setFilterValue] = useState(
    selectedFilters
      ? (filterLabelList.filter(filterObj => filterObj.type === 'select'
          ? selectedFilters?.find(appliedFilter => appliedFilter.name === filterObj.filterKeyName)
          : selectedFilters?.find(appliedFilter => appliedFilter.name === 'zoomedAccountIds')?.activeFilterNames?.includes(filterObj.name))?.map(obj => obj.name))
      : [])
  const [tradeStatusValue, setTradeStatusValue] = useState([])
  const [strategyValue, setStrategyValue] = useState([])
  const [taxStatusValue, setTaxStatusValue] = useState([])
  const [trackingErrorValue, setTrackingErrorValue] = useState([])
  const [tradingAge, setTradingAge] = useState([])
  const [modelChange, setModelChange] = useState(null)
  const [delist, setDelist] = useState([])
  const [taxCostBenefit, setTaxCostBenefit] = useState([])
  const [isDefaultChecked, setIsDefaultChecked] = useState(isDefaultScenarioChecked || false)
  const [legends, setLegends] = useState(appliedLegend
    ? { ...appliedLegend }
    : {
        balanced: false,
        model: false,
        mintax: false
      })
  const [selectedStrategies, setSelectedStrategies] = useState([])

  useEffect(() => {
    setNoOfAccounts(tradeArray.length)
  }, [tradeArray])

  useEffect(() => {
    setIsDefaultChecked(isDefaultScenarioChecked)
  }, [isDefaultScenarioChecked])

  useEffect(() => {
    if (!selectedFilters?.length) {
      setLegends(appliedLegend)
    }
  }, [appliedLegend])

  useEffect(() => {
    setZoomedAccountIds(selectedFilters.find(filterObj => filterObj.name === 'zoomedAccountIds')?.value || [])
    setTradeStatusValue(selectedFilters.find(filterObj => filterObj.name === 'tradeStatus')?.value || [])
    setStrategyValue((selectedFilters.find(filterObj => filterObj.name === 'strategy')?.value || [])?.map(str => str.strategyId))
    setSelectedStrategies(selectedFilters.find(filterObj => filterObj.name === 'strategy')?.value || [])
    setTaxStatusValue(selectedFilters.find(filterObj => filterObj.name === 'taxStatus')?.value || [])
    setTrackingErrorValue(selectedFilters.find(filterObj => filterObj.name === 'trackingError')?.value || '25')
    setTradingAge(selectedFilters.find(filterObj => filterObj.name === 'tradingAge')?.value || '30')
    setModelChange(selectedFilters.find(filterObj => filterObj.name === 'modelChange')?.value || true)
    setDelist(selectedFilters.find(filterObj => filterObj.name === 'delist')?.value || [])
    setTaxCostBenefit(selectedFilters.find(filterObj => filterObj.name === 'taxCostBenefit')?.value || [])
    const delistValue = selectedFilters.find(filterObj => filterObj.name === 'delist')?.value
    if (delistValue) {
      const isAllSelected = delistValue?.length === delistData?.length
      setSelectAllChecked(isAllSelected)
    }
    if (!selectedFilters?.length) {
      setLegends(appliedLegend)
      setNoOfAccounts(tradeArray?.length)
    }
  }, [selectedFilters])

  const handleChange = (event, type) => {
    const { target: { value } } = event
    if (type === 'filterValue') {
      setFilterValue(value)
      if (value.filter(filterName => filterLabelList.find(filterObj => filterObj.name === filterName && filterObj.type === 'chart')).length === 0) {
        setIsDefaultChecked(false)
        const originalLegends = {}
        Object.keys(scenarioMappingWithLegend).forEach(key => {
          originalLegends[key] = false
        })
        setLegends(originalLegends)
      }
      const filters = [
        {
          name: 'tradeStatus',
          value: value.includes('Trade Status') ? tradeStatusValue : []
        },
        {
          name: 'strategy',
          value: value.includes('Strategy') ? selectedStrategies : []
        },
        {
          name: 'taxStatus',
          value: value.includes('Tax Status') ? taxStatusValue : []
        },
        {
          name: 'trackingError',
          value: value.includes('Tracking Error Improvement') ? trackingErrorValue : []
        },
        {
          name: 'tradingAge',
          value: value.includes('Trading Age') ? tradingAge : []
        },
        {
          name: 'isModelChange',
          value: value.includes('Model Change') ? modelChange : null
        },
        {
          name: 'delist',
          value: value.includes('Delist') ? delist : []
        },
        {
          name: 'taxCostBenefit',
          value: value.includes('Tax Cost Benefit') ? taxCostBenefit : []
        }
      ]
      if (filters.length) {
        const chartFilter = filters.find(filter => filter.name === 'zoomedAccountIds')
        if (chartFilter?.value?.length) {
          setNoOfAccounts(zoomedAccountIds.length)
        } else {
          let selectedAccounts = [...tradeArray]
          filters.filter(filterObj => filterObj.value?.length)?.forEach((filter) => {
            if (filter.name === 'tradeStatus' && filter.value) {
              const selectedFilters = filter.value
                .filter((filter) => filter.selected)
                .map((filter) => filter.colorNumber) || []
              const filteredAccounts = selectedAccounts.filter((account) => selectedFilters.includes(account.colorNumber))
              selectedAccounts = [...filteredAccounts]
            } else if (filter.name === 'strategy' && filter.value) {
              const filteredAccounts = selectedAccounts.filter((account) => (filter.value)?.map(str => str.strategyId).includes(account.strategyId))
              selectedAccounts = [...filteredAccounts]
            } else if (filter.name === 'taxStatus' && filter.value) {
              const selectedTaxStatus = []
              if (filter.value.includes('Taxable')) {
                selectedTaxStatus.push(1)
              } if (filter.value.includes('Tax Exempt')) {
                selectedTaxStatus.push(0)
              }
              const filteredAccounts = selectedAccounts.filter((account) => selectedTaxStatus?.includes(account.isTaxable))
              selectedAccounts = [...filteredAccounts]
            }
          })
          fetchChartData(selectedAccounts, isDefaultChecked)
          if (filters.filter(filterObj => filterObj.value?.length)?.length) {
            setNoOfAccounts(selectedAccounts.length)
          } else {
            fetchChartData(tradeArray, isDefaultChecked)
            setNoOfAccounts(tradeArray.length)
          }
        }
      } else {
        fetchChartData(tradeArray, isDefaultChecked)
        setNoOfAccounts(tradeArray.length)
      }
    } else if (type === 'tradeStatus') {
      setTradeStatusValue(value)
    } else if (type === 'strategy') {
      setStrategyValue(value)
      setSelectedStrategies([...strategies?.filter(strategy => value.includes(strategy.strategyId))])
      // // Extract accountIds based on selected strategy
      // const selectedStrategyDetails = optmRun
      //   .filter((item) => value.includes(item.strategyId))
      //   .map((item) => ({
      //     accountId: item.accountId,
      //     strategyName: item.strategyName,
      //     strategyId: item.strategyId
      //   }))

      // // Separate accountIds and strategy names
      // const selectedStrategyAccountIds = selectedStrategyDetails.map((item) => item.accountId)

      // // Filter strategy names based on selected accountIds
      // const strategyNamesOfSelectedAccounts = selectedStrategyDetails
      //   .map((item) => item.strategyName)
      //   .filter((name, index, self) => self.indexOf(name) === index)

      // // Update the relevant states with the selected accountIds and strategy names
      // setStrategyAccountIds(selectedStrategyAccountIds)
      // setStrategyNames(strategyNamesOfSelectedAccounts)
    } else if (type === 'taxStatus') {
      // Extract accountIds based on selected tax status
      // const selectedTaxable = optmRun.filter((item) => item.isTaxable === 1).map((item) => item.accountId)
      // const selectedTaxExempt = optmRun.filter((item) => item.isTaxable === 0).map((item) => item.accountId)
      // Set the relevant accountIds based on the selected tax status
      setTaxStatusValue(value)
      // const updatedTaxStatusAccountIds = {
      // taxable: value.includes('Taxable') ? selectedTaxable : [],
      // taxExempt: value.includes('Tax Exempt') ? selectedTaxExempt : []
      // }
      // setTaxStatusAccountIds(updatedTaxStatusAccountIds)
    } else return null
  }

  const handleClear = (type) => {
    if (type === 'tradeStatus') {
      setTradeStatusValue([])
    } else if (type === 'strategy') {
      // setStrategyAccountIds([])
      // setStrategyNames([])
      setStrategyValue([])
      setSelectedStrategies([])
    } else if (type === 'taxStatus') {
      // setTaxStatusAccountIds([])
      setTaxStatusValue([])
    } else if (type === 'trackingError') {
      setTrackingErrorValue([])
    } else if (type === 'tradingAge') {
      setTradingAge([])
    } else if (type === 'isModelChange') {
      setModelChange(null)
    } else if (type === 'delist') {
      setDelist([])
      setSelectAllChecked(false)
    } else if (type === 'taxCostBenefit') {
      setTaxCostBenefit([])
    } else {
      setFilterValue([])
      setZoomedAccountIds([])
      setNoOfAccounts(tradeArray?.length)
      // setUniqueAccountIds([])
      setIsDefaultChecked(false)
      setStrategyValue([])
      setTradeStatusValue([])
      setTaxStatusValue([])
      setSelectedStrategies([])
      setIsLegendClicked(false)
      const originalLegends = {}
      Object.keys(scenarioMappingWithLegend).forEach(key => {
        originalLegends[key] = false
      })
      setLegends(originalLegends)
      // setLegends({
      //   balanced: false,
      //   model: false,
      //   mintax: false
      // })
    }
  }

  const handleResetAll = () => {
    // setIsApplyClicked(false)
    setFilterValue([])
    setTradeStatusValue([])
    setStrategyValue([])
    setTaxStatusValue([])
    setZoomedAccountIds([])
    setNoOfAccounts(tradeArray?.length)
    setSelectedStrategies([])
    setTrackingErrorValue([])
    setTaxCostBenefit([])
    setModelChange(null)
    setDelist([])
    setSelectAllChecked(false)
    setTradingAge([])
    setIsLegendClicked(false)
    // setUniqueAccountIds([])
    removeAll()
    // resetChipDatas()
    resetLegends()
    // setTaxStatusAccountIds([])
    // setStrategyAccountIds([])
    // setStrategyNames([])
  }

  const handleApplyFilter = () => {
    applyFilter([
      {
        name: 'tradeStatus',
        value: filterValue.includes('Trade Status') ? tradeStatusValue : []
      },
      {
        name: 'strategy',
        value: filterValue.includes('Strategy') ? selectedStrategies : []
      },
      {
        name: 'taxStatus',
        value: filterValue.includes('Tax Status') ? taxStatusValue : []
      },
      {
        name: 'trackingError',
        value: filterValue.includes('Tracking Error Improvement') ? trackingErrorValue : []
      },
      {
        name: 'tradingAge',
        value: filterValue.includes('Trading Age') ? tradingAge : []
      },
      {
        name: 'isModelChange',
        value: filterValue.includes('Model Change') ? modelChange : null
      },
      {
        name: 'delist',
        value: filterValue.includes('Delist') ? delist : []
      },
      {
        name: 'taxCostBenefit',
        value: filterValue.includes('Tax Cost Benefit') ? taxCostBenefit : null
      },
      {
        name: 'zoomedAccountIds',
        value: zoomedAccountIds,
        activeFilterNames: filterLabelList.filter(filterObj => filterObj.type === 'chart' && filterValue.includes(filterObj.name)).map(filterObj => filterObj.name)
      }
    ], isDefaultChecked, isLegendClicked, legends, true)
  }

  const fetchChartData = (accountsList, showDefault = false) => {
    if (accountsList && accountsList.length) {
      const scenarioData = {}

      accountsList.forEach((item) => {
        item.optDetails?.forEach((data) => {
          if (!data.failedScenario) {
            if (showDefault) {
              if (data.isDefault) {
                if (data.summaryDetails) {
                  const accountId = item.accountId // Extract accountId from optmRun
                  const accountName = item.accountName // Extract accountId from optmRun
                  // Add accountId to the summaryDetails data
                  data.summaryDetails.accountId = accountId
                  data.summaryDetails.accountName = accountName
                  if (!scenarioData[data.scenarioDesc]) {
                    scenarioData[data.scenarioDesc] = []
                  }

                  // Create a dynamic data object based on scenarioDesc
                  const scenarioDataObject = { ...data.summaryDetails, accountId }

                  // Push the dynamic data object to the corresponding array in scenarioData
                  scenarioData[data.scenarioDesc].push(scenarioDataObject)
                }
              } else {
                if (!scenarioData[data.scenarioDesc]) {
                  scenarioData[data.scenarioDesc] = []
                }
              }
            } else {
              if (data.summaryDetails) {
                const accountId = item.accountId // Extract accountId from optmRun
                const accountName = item.accountName // Extract accountId from optmRun
                // Add accountId to the summaryDetails data
                data.summaryDetails.accountId = accountId
                data.summaryDetails.accountName = accountName
                if (!scenarioData[data.scenarioDesc]) {
                  scenarioData[data.scenarioDesc] = []
                }

                // Create a dynamic data object based on scenarioDesc
                const scenarioDataObject = { ...data.summaryDetails, accountId }

                // Push the dynamic data object to the corresponding array in scenarioData
                scenarioData[data.scenarioDesc].push(scenarioDataObject)
              }
            }
          }
        })
      })
      Object.keys(scenarioMappingWithLegend).forEach((scenario) => {
        if (!scenarioData[scenarioMappingWithLegend[scenario]]) {
          scenarioData[scenarioMappingWithLegend[scenario]] = []
        }
      })
      setScenarioWiseData({ ...scenarioData })
    }
  }

  useEffect(() => {
    fetchChartData(optmRun, isDefaultChecked)
  }, [optmRun])

  const selectDefaultScenario = (showDefault) => {
    fetchChartData(optmRun, showDefault)
    setIsDefaultChecked(showDefault)
    setIsLegendClicked(false)
    const originalLegends = {}
    Object.keys(scenarioMappingWithLegend).forEach(key => {
      originalLegends[key] = false
    })
    setLegends(originalLegends)
  }

  useEffect(() => {
    setTradeStatusList(tradeStatusFilters)
  }, [showFilters])

  const handleLegendClick = (updatedLegends) => {
    setLegends(updatedLegends)
    setIsLegendClicked(true)
  }

  const handleZoomChart = (dataPoints, label) => {
    getChartLabel(dataPoints, label)
    const uniqueAccountIds = [...new Set(dataPoints.map((point) => point.accountId))]
    setZoomedAccountIds(uniqueAccountIds)
  }

  // calculate the No. of Accounts
  useEffect(() => {
    const defaultTrackingError = filterValue.includes('Tracking Error Improvement') ? trackingErrorValue : '25'
    const defaultModelChange = filterValue.includes('Model Change') ? modelChange : true
    const defaultTradingAge = filterValue.includes('Trading Age') ? tradingAge : '30'

    // Update states with default values if the filter is selected
    setTrackingErrorValue(defaultTrackingError)
    setModelChange(defaultModelChange)
    setTradingAge(defaultTradingAge)
    const filters = [
      {
        name: 'tradeStatus',
        value: filterValue.includes('Trade Status') ? tradeStatusValue : []
      },
      {
        name: 'strategy',
        value: filterValue.includes('Strategy') ? selectedStrategies : []
      },
      {
        name: 'taxStatus',
        value: filterValue.includes('Tax Status') ? taxStatusValue : []
      },
      {
        name: 'trackingError',
        value: filterValue.includes('Tracking Error Improvement') ? defaultTrackingError : []
      },
      {
        name: 'tradingAge',
        value: filterValue.includes('Trading Age') ? defaultTradingAge : []
      },
      {
        name: 'isModelChange',
        value: filterValue.includes('Model Change') ? defaultModelChange : null
      },
      {
        name: 'delist',
        value: filterValue.includes('Delist') ? delist : []
      },
      {
        name: 'taxCostBenefit',
        value: filterValue.includes('Tax Cost Benefit') ? taxCostBenefit : []
      },
      {
        name: 'zoomedAccountIds',
        value: zoomedAccountIds
      }
    ]
    if (filters?.length) {
      const chartFilter = filters.find(filter => filter.name === 'zoomedAccountIds')
      // if (chartFilter?.value?.length) {
      //   setNoOfAccounts(zoomedAccountIds.length)
      // } else {
      let selectedAccounts = chartFilter?.value?.length ? [...tradeArray?.filter(account => zoomedAccountIds.includes(account.accountId))] : [...tradeArray]
      filters.filter(filterObj =>
        filterObj.name === 'isModelChange'
          ? filterObj.value !== undefined && filterObj.value !== null
          : filterObj.value?.length
      )
        .forEach((filter) => {
          if (filter.name === 'tradeStatus' && filter.value) {
            const selectedFilters = filter.value
              .filter((filter) => filter.selected)
              .map((filter) => filter.colorNumber) || []
            const filteredAccounts = selectedAccounts.filter((account) => selectedFilters.includes(account.colorNumber))
            selectedAccounts = [...filteredAccounts]
          } else if (filter.name === 'strategy' && filter.value) {
            const filteredAccounts = selectedAccounts.filter((account) => (filter.value)?.map(str => str.strategyId).includes(account.strategyId))
            selectedAccounts = [...filteredAccounts]
          } else if (filter.name === 'taxStatus' && filter.value) {
            const selectedTaxStatus = []
            if (filter.value.includes('Taxable')) {
              selectedTaxStatus.push(1)
            } if (filter.value.includes('Tax Exempt')) {
              selectedTaxStatus.push(0)
            }
            const filteredAccounts = selectedAccounts.filter((account) => selectedTaxStatus?.includes(account.isTaxable))
            selectedAccounts = [...filteredAccounts]
          } else if (filter.name === 'trackingError' && filter.value) {
            const minTeImprovement = parseFloat(filter.value)
            selectedAccounts = selectedAccounts.filter(account =>
              account?.optDetails?.some(opt =>
                parseFloat(opt?.summaryDetails?.teImprovement) >= minTeImprovement
              )
            )
          } else if (filter.name === 'tradingAge' && filter.value) {
            const minAge = parseFloat(filter.value)
            selectedAccounts = selectedAccounts.filter(account =>
              account?.optDetails?.some(opt =>
                opt?.summaryDetails?.tradeAge === 'NA' || parseFloat(opt?.summaryDetails?.tradeAge) >= minAge
              )
            )
          } else if (filter.name === 'isModelChange' && filter.value !== null) {
            const modelChangedValue = filter.value
            selectedAccounts = selectedAccounts.filter(account =>
              account?.optDetails?.some(opt =>
                opt?.summaryDetails?.isModelChanged === modelChangedValue
              )
            )
          } else if (filter.name === 'delist' && filter.value) {
            const delistValue = filter.value
            const allAccounts = delistValue?.flatMap(group => {
              const accounts = []
              if (group?.proposedAccounts) accounts.push(...group.proposedAccounts)
              if (group?.pendingAccounts) accounts.push(...group.pendingAccounts)
              return accounts
            })
            const delistAccountIds = new Set(allAccounts.map(account => account.accountId))
            selectedAccounts = selectedAccounts?.filter(account =>
              delistAccountIds.has(account.accountId)
            )
          } else if (filter.name === 'taxCostBenefit' && filter.value) {
            const taxCostBenefitValue = parseFloat(filter.value !== 0 ? filter.value * -1 : filter.value)
            selectedAccounts = selectedAccounts.filter(account =>
              account?.optDetails?.some(opt =>
                opt?.summaryDetails?.taxCost === null || parseFloat(opt?.summaryDetails?.taxCost) <= taxCostBenefitValue
              )
            )
          }
        })
      fetchChartData(selectedAccounts, isDefaultChecked)
      if (filters.filter(filterObj =>
        filterObj.name === 'isModelChange'
          ? filterObj.value !== undefined && filterObj.value !== null
          : filterObj.value?.length
      )?.length) {
        setNoOfAccounts(selectedAccounts.length)
      } else {
        fetchChartData(tradeArray, isDefaultChecked)
        setNoOfAccounts(tradeArray.length)
      }
      // }
    } else {
      fetchChartData(tradeArray, isDefaultChecked)
      setNoOfAccounts(tradeArray.length)
    }
  }, [tradeStatusValue, zoomedAccountIds, taxStatusValue, strategyValue, trackingErrorValue, tradingAge, modelChange, taxCostBenefit, filterValue, delist])

  const handleResetChart = () => {
    setZoomedAccountIds([])
    setNoOfAccounts(optmRun?.length)
    // setUniqueAccountIds([])
    selectDefaultScenario(false)
    // setLegends({
    //   balanced: false,
    //   model: false,
    //   mintax: false
    // })
    const originalLegends = {}
    Object.keys(scenarioMappingWithLegend).forEach(key => {
      originalLegends[key] = false
    })
    setLegends(originalLegends)
  }

  const handleSelectAllChange = (event) => {
    const isChecked = event.target.checked
    setSelectAllChecked(isChecked)
    if (isChecked) {
      // If "Select All" is checked, select all options
      const allAccounts = delistData?.flatMap(item => item)
      setDelist(allAccounts)
    } else {
      setDelist([])
      setSelectAllChecked(false)
    }
  }

  return (
    <Drawer
      anchor='right'
      open={showFilters}
      onClose={closeFilters}
      sx={{
        width: '100%',
        '& .MuiDrawer-paper': {
          width: '100%'
        }
      }}
    >
      <Box mx={4}>
        <Box display='flex' justifyContent='space-between'>
          <Typography component='h3' className='page-title' sx={{ marginRight: '20px' }} mb={4}>Trade Approval Filter</Typography>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Button sx={{ marginX: '10px' }} startIcon={<AutorenewIcon />} onClick={handleResetAll}>Reset All</Button>
            <Button
              variant='contained' sx={{
                float: 'right',
                backgroundColor: '#34C38F',
                '&:hover': {
                  backgroundColor: '#34c38f'
                }
              }}
              onClick={handleApplyFilter}
            // disabled={!noOfAccounts}
            >Apply
            </Button>
            <IconButton onClick={closeFilters} sx={{ ml: 1 }}>
              <CloseIcon
                fontSize='small' onClick={() => {
                  if (!selectedFilters.length) {
                    setZoomedAccountIds([])
                    if (appliedLegend && Object.keys(appliedLegend).some(key => (key === ' selectedScenario') ? false : appliedLegend[key] !== legends[key])) {
                      setLegends({
                        ...appliedLegend
                      })
                    }
                    setIsDefaultChecked(isDefaultScenarioChecked)
                  } else {
                    setFilterValue(filterLabelList.filter(filterObj => filterObj.type === 'select' ? selectedFilters.find(appliedFilter => appliedFilter.name === filterObj.filterKeyName) : selectedFilters.find(appliedFilter => appliedFilter.name === 'zoomedAccountIds')?.activeFilterNames?.includes(filterObj.name)).map(obj => obj.name))
                    setZoomedAccountIds(selectedFilters.find(filterObj => filterObj.name === 'zoomedAccountIds')?.value || [])
                    setTradeStatusValue(selectedFilters.find(filterObj => filterObj.name === 'tradeStatus')?.value || [])
                    setStrategyValue((selectedFilters.find(filterObj => filterObj.name === 'strategy')?.value || [])?.map(str => str.strategyId))
                    setTaxStatusValue(selectedFilters.find(filterObj => filterObj.name === 'taxStatus')?.value || [])
                    setTrackingErrorValue(selectedFilters.find(filterObj => filterObj.name === 'trackingError')?.value || '25')
                    setTradingAge(selectedFilters.find(filterObj => filterObj.name === 'tradingAge')?.value || '30')
                    setModelChange(selectedFilters.find(filterObj => filterObj.name === 'isModelChange')?.value || true)
                    setDelist(selectedFilters.find(filterObj => filterObj.name === 'delist')?.value || [])
                    if (!selectedFilters.find(filterObj => filterObj.name === 'delist')?.value) {
                      setSelectAllChecked(false)
                    }
                    setTaxCostBenefit(selectedFilters.find(filterObj => filterObj.name === 'taxCostBenefit')?.value || [])
                    setLegends(appliedLegend)
                    setIsDefaultChecked(isDefaultScenarioChecked)
                  }
                }}
              />
            </IconButton>
          </Box>
        </Box>
        <Box sx={{ position: 'absolute', right: '0px', top: '60px' }}>
          <GaugeChart value={noOfAccounts} maxValue={tradeArray.length} />
        </Box>
        <Box display='flex' mb={1} width='95%' justifyContent='space-between' alignItems='center'>
          <Typography sx={{ color: '#002A59', fontSize: '16px', fontWeight: '600', marginLeft: '10px', height: '20px' }}>Select Filters</Typography>
        </Box>
        <FormControl sx={{ m: 1 }}>
          <InputLabel id='demo-multiple-name-label'>Select Filters from here</InputLabel>
          <Select
            labelId='demo-multiple-name-label'
            id='demo-multiple-name'
            multiple
            value={filterValue}
            onChange={(event) => handleChange(event, 'filterValue')}
            input={<OutlinedInput label='Select Filters from here' />}
            sx={{ width: '600px' }}
            endAdornment={
              <>
                <HtmlTooltip
                  title={
                    <>
                      <Typography color='inherit' fontFamily='Open Sans' fontWeight={600}>Selected Filters</Typography>
                      <Divider sx={{ backgroundColor: '#000000' }} />
                      <Typography sx={{ fontSize: '14px' }} my={1}>{filterValue.join(', ')}</Typography>
                    </>
                  }
                >
                  <IconButton sx={{ display: filterValue.length > 0 ? '' : 'none' }}>
                    <InfoOutlinedIcon />
                  </IconButton>
                </HtmlTooltip>
                <IconButton sx={{ display: filterValue.length > 0 ? '' : 'none', marginX: 1 }} onClick={() => handleClear('filterValue')}>
                  <ClearIcon />
                </IconButton>
              </>
            }
          >
            {filterLabelList?.map((filterName) => (
              <MenuItem
                key={filterName.name}
                value={filterName.name}
              >
                {filterName.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Grid container>
          {filterValue.includes('Trade Status') && (
            <Grid item xs={12} lg={6}>
              <Box mt={3}>
                <Typography sx={{ color: '#002A59', fontSize: '16px', fontWeight: '600', marginLeft: '10px', mb: 1 }}>Trade Status</Typography>
                <FormControl sx={{ m: 1 }}>
                  <InputLabel id='trade-status-label'>Trade Status</InputLabel>
                  <Select
                    labelId='trade-status-label'
                    id='trade-status'
                    multiple
                    value={tradeStatusValue}
                    onChange={(event) => handleChange(event, 'tradeStatus')}
                    input={<OutlinedInput label='Trade Status' />}
                    sx={{ width: '600px' }}
                    endAdornment={
                      <>
                        <HtmlTooltip
                          title={
                            <>
                              <Typography color='inherit' fontFamily='Open Sans' fontWeight={600}>Selected Scenarios</Typography>
                              <Divider sx={{ backgroundColor: '#000000' }} />
                              <Typography sx={{ fontSize: '14px' }} my={1}>{tradeStatusValue.map((status) => status.name).join(', ')}</Typography>
                            </>
                          }
                        >
                          <IconButton sx={{ display: tradeStatusValue.length > 0 ? '' : 'none' }}>
                            <InfoOutlinedIcon />
                          </IconButton>
                        </HtmlTooltip>
                        <IconButton sx={{ display: tradeStatusValue.length > 0 ? '' : 'none', marginX: 1 }} onClick={() => handleClear('tradeStatus')}>
                          <ClearIcon />
                        </IconButton>
                      </>
                    }
                  >
                    {tradeStatusList?.map((status) => (
                      <MenuItem key={status.name} value={status}>
                        {status.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          )}
          {filterValue.includes('Strategy') && (
            <Grid item xs={12} lg={6}>

              <Box mt={3}>
                <Typography sx={{ color: '#002A59', fontSize: '16px', fontWeight: '600', marginLeft: '10px', mb: 1 }}>Strategy</Typography>
                <FormControl sx={{ m: 1 }}>
                  <InputLabel id='strategy-label'>Strategy</InputLabel>
                  <Select
                    labelId='strategy-label'
                    id='strategy'
                    multiple
                    value={strategyValue}
                    onChange={(event) => handleChange(event, 'strategy')}
                    input={<OutlinedInput label='Strategy' />}
                    sx={{ width: '600px' }}
                    endAdornment={
                      <>
                        <HtmlTooltip
                          title={
                            <>
                              <Typography color='inherit' fontFamily='Open Sans' fontWeight={600}>Selected Strategy</Typography>
                              <Divider sx={{ backgroundColor: '#000000' }} />
                              <Typography sx={{ fontSize: '14px' }} my={1}>{selectedStrategies?.map((strategy) => strategy.strategyName).join(', ')}</Typography>
                            </>
                          }
                        >
                          <IconButton sx={{ display: strategyValue.length > 0 ? '' : 'none' }}>
                            <InfoOutlinedIcon />
                          </IconButton>
                        </HtmlTooltip>
                        <IconButton sx={{ display: strategyValue.length > 0 ? '' : 'none', marginX: 1 }} onClick={() => handleClear('strategy')}>
                          <ClearIcon />
                        </IconButton>
                      </>
                    }
                  >
                    {strategies?.map((strategy) => (
                      <MenuItem key={strategy.strategyId} value={strategy.strategyId}>
                        {strategy.strategyName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          )}
          {filterValue.includes('Tax Status') && (
            <Grid item xs={12} lg={6}>

              <Box mt={3}>
                <Typography sx={{ color: '#002A59', fontSize: '16px', fontWeight: '600', marginLeft: '10px', mb: 1 }}>Tax Status</Typography>
                <FormControl sx={{ m: 1 }}>
                  <InputLabel id='taxStatus-label'>Tax Status</InputLabel>
                  <Select
                    labelId='taxStatus-label'
                    id='taxStatus'
                    multiple
                    value={taxStatusValue}
                    onChange={(event) => handleChange(event, 'taxStatus')}
                    input={<OutlinedInput label='Tax Status' />}
                    sx={{ width: '600px' }}
                    endAdornment={
                      <>
                        <HtmlTooltip
                          title={
                            <>
                              <Typography color='inherit' fontFamily='Open Sans' fontWeight={600}>Selected Tax Status</Typography>
                              <Divider sx={{ backgroundColor: '#000000' }} />
                              <Typography sx={{ fontSize: '14px' }} my={1}>{taxStatusValue?.map((status) => status).join(', ')}</Typography>
                            </>
                          }
                        >
                          <IconButton sx={{ display: taxStatusValue.length > 0 ? '' : 'none' }}>
                            <InfoOutlinedIcon />
                          </IconButton>
                        </HtmlTooltip>
                        <IconButton sx={{ display: taxStatusValue.length > 0 ? '' : 'none', marginX: 1 }} onClick={() => handleClear('taxStatus')}>
                          <ClearIcon />
                        </IconButton>
                      </>
                    }
                  >
                    {taxStatus?.map((status) => (
                      <MenuItem key={status} value={status}>
                        {status}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          )}

          {filterValue.includes('Trading Age') && (
            <Grid item xs={12} lg={6}>
              <Box mt={3}>
                <Typography sx={{ color: '#002A59', fontSize: '16px', fontWeight: '600', marginLeft: '10px', mb: 1 }}>
                  Trading Age
                </Typography>
                <FormControl sx={{ m: 1 }}>
                  <InputLabel htmlFor='age-input'>Trading Age</InputLabel>
                  <OutlinedInput
                    id='age-input'
                    type='number'
                    sx={{ width: '600px' }}
                    value={tradingAge}
                    onChange={(event) => setTradingAge(event.target.value)}
                    onKeyDown={(e) => isWholeNumber(e)}
                    onPaste={wholeNumberPasteHandler}
                    endAdornment={<InputAdornment position='end'>Days</InputAdornment>}
                    label='Trading Age'
                  />
                  <FormHelperText sx={{ whiteSpace: 'nowrap' }}>Trade Age to be greater than or equal to the specified value.</FormHelperText>
                </FormControl>
              </Box>
            </Grid>
          )}

          {filterValue.includes('Tracking Error Improvement') && (
            <Grid item xs={12} lg={6}>
              <Box mt={3}>
                <Typography sx={{ color: '#002A59', fontSize: '16px', fontWeight: '600', marginLeft: '10px', mb: 1 }}>
                  Tracking Error Improvement
                </Typography>
                <FormControl sx={{ m: 1 }}>
                  <InputLabel htmlFor='te-input'>Tracking Error Improvement</InputLabel>
                  <OutlinedInput
                    id='tracking-error-input'
                    type='number'
                    value={trackingErrorValue}
                    sx={{ width: '600px' }}
                    onChange={(event) => setTrackingErrorValue(event.target.value)}
                    onKeyDown={(e) => isWholeNumber(e)}
                    onPaste={wholeNumberPasteHandler}
                    endAdornment={<InputAdornment position='end'>bps</InputAdornment>}
                    label='Tracking Error Improvement'
                  />
                  <FormHelperText sx={{ whiteSpace: 'nowrap' }}>TE to be greater than or equal to the specified value.</FormHelperText>
                </FormControl>
              </Box>
            </Grid>
          )}

          {filterValue.includes('Model Change') && (
            <Grid item xs={12} lg={6}>
              <Box mt={3}>
                <Typography sx={{ color: '#002A59', fontSize: '16px', fontWeight: '600', marginLeft: '10px', mb: 1 }}>
                  Model Change
                </Typography>
                <FormControl sx={{ m: 1 }}>
                  <InputLabel id='Model-label'>Model Change</InputLabel>
                  <Select
                    labelId='Model-label'
                    id='isModelChange'
                    value={modelChange}
                    onChange={(e) => setModelChange(e.target.value)}
                    input={<OutlinedInput label='Model Change' />}
                    sx={{ width: '600px' }}
                  >
                    {modelChangeList?.map((item) => (
                      <MenuItem key={item.label} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          )}
          {filterValue.includes('Delist') && (
            <Grid item xs={12} lg={6}>
              <Box mt={3}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '600px' }}>
                  <Typography sx={{ color: '#002A59', fontSize: '16px', fontWeight: '600', marginLeft: '10px', mb: 1 }}>
                    Delist
                  </Typography>
                  <div>
                    <input
                      type='checkbox'
                      name='Select All'
                      checked={selectAllChecked}
                      onChange={handleSelectAllChange}
                      disabled={!delistData?.length}
                      sx={{ opacity: delistData?.length ? 1 : 0.5 }}
                    />
                    Select All
                  </div>
                </Box>
                <FormControl sx={{ m: 1 }}>
                  <InputLabel id='Model-label'>Delist</InputLabel>
                  <Select
                    labelId='Model-label'
                    id='delist'
                    multiple
                    value={delist}
                    onChange={(e) => {
                      const selectedValue = e.target.value
                      const isAllSelected = selectedValue?.length === delistData?.length
                      setDelist(selectedValue)
                      setSelectAllChecked(isAllSelected)
                    }}
                    input={<OutlinedInput label='Delist' />}
                    sx={{ width: '600px' }}
                    renderValue={() => {
                      if (selectAllChecked) {
                        // If selectAllChecked is true, display all instrId
                        return delistData?.map((item) => item.instrId).join(', ')
                      } else {
                        // Otherwise, display selected items from delist array
                        return delist?.map((selectedItem) => selectedItem.instrId).join(', ')
                      }
                    }}
                  >
                    {delistData?.length
                      ? (
                          delistData?.map((item) => (
                            <MenuItem key={item.instrId} value={item}>
                              {item.instrId}
                            </MenuItem>
                          ))
                        )
                      : (
                        <MenuItem disabled>
                          No Options
                        </MenuItem>
                        )}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          )}
          {filterValue.includes('Tax Cost Benefit') && (
            <Grid item xs={12} lg={6}>
              <Box mt={3}>
                <Typography sx={{ color: '#002A59', fontSize: '16px', fontWeight: '600', marginLeft: '10px' }}>
                  Tax Cost Benefit
                </Typography>
                <FormControl sx={{ m: 1 }}>
                  <InputAdornmentTextField
                    id='tax-cost-benefit'
                    type='number'
                    value={taxCostBenefit}
                    sx={{ width: '600px' }}
                    onChange={(event) => setTaxCostBenefit(event.target.value)}
                    adornment={<AttachMoneyOutlinedIcon />}
                    onKeyDown={(event) => isNumberWithSign(event)}
                    onPaste={numberWithSignPasteHandler}
                    label='Tax Cost Benefit'
                  />
                  <FormHelperText sx={{ whiteSpace: 'nowrap' }}>Tax Cost Benefit to be greater than or equal to the specified value.</FormHelperText>
                </FormControl>
              </Box>
            </Grid>
          )}

        </Grid>
        <Grid container>
          <Grid item xs={12} mt={6} textAlign='right'>
            <Button
              variant='contained'
              size='small'
              sx={{
                // height: '30px',
                // width: '180px',
                display: zoomedAccountIds.length ? 'inline' : 'none'
                // whiteSpace: 'nowrap',
                // marginRight: '60px',
                // marginTop: '20px'
              }}
              // p={2}
              onClick={handleResetChart}
            >Reset Chart
            </Button>
          </Grid>
          {filterValue.includes('Initial Tracking Error vs Total Taxes') && (
            <Grid item xs={12} lg={6}>
              <TradeChart
                isDefaultChecked={isDefaultChecked}
                selectDefaultScenario={selectDefaultScenario}
                // balancedData={balancedData}
                // minTaxData={minTaxData}
                // modelData={modelData}
                scenarioWiseData={scenarioWiseData}
                yAxis='initTe'
                xAxis='taxCost'
                onZoom={handleZoomChart}
                zoomedAccountIds={zoomedAccountIds}
                legends={legends}
                onLegendClick={handleLegendClick}
                chartHeader='Initial Tracking Error vs Total Taxes'
                yAxisLabel='Initial Tracking Error(%)'
                xAxisLabel='Total Taxes($)'
                setNoOfAccounts={setNoOfAccounts}
              />
            </Grid>
          )}

          {filterValue.includes('Proposed Tracking Error vs Total Taxes') && (
            <Grid item xs={12} lg={6}>
              <TradeChart
                isDefaultChecked={isDefaultChecked}
                selectDefaultScenario={selectDefaultScenario}
                // balancedData={balancedData}
                // minTaxData={minTaxData}
                // modelData={modelData}
                scenarioWiseData={scenarioWiseData}
                yAxis='propTe'
                xAxis='taxCost'
                onZoom={handleZoomChart}
                zoomedAccountIds={zoomedAccountIds}
                legends={legends}
                onLegendClick={handleLegendClick}
                chartHeader='Proposed Tracking Error vs Total Taxes'
                yAxisLabel='Proposed Tracking Error(%)'
                xAxisLabel='Total Taxes($)'
                setNoOfAccounts={setNoOfAccounts}
              />
            </Grid>
          )}
          {filterValue.includes('Tracking Error Improvement vs Total Taxes') && (
            <Grid item xs={12} lg={6}>
              <TradeChart
                isDefaultChecked={isDefaultChecked}
                selectDefaultScenario={selectDefaultScenario}
                // balancedData={balancedData}
                // minTaxData={minTaxData}
                // modelData={modelData}
                scenarioWiseData={scenarioWiseData}
                yAxis='teImprovement'
                xAxis='taxCost'
                onZoom={handleZoomChart}
                zoomedAccountIds={zoomedAccountIds}
                legends={legends}
                onLegendClick={handleLegendClick}
                chartHeader='Tracking Error Improvement vs Total Taxes'
                yAxisLabel='Tracking Error Improvement (bps)'
                xAxisLabel='Total Taxes($)'
                setNoOfAccounts={setNoOfAccounts}
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </Drawer>
  )
}

export default TradeApprovalFilterMenu
