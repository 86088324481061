import React, { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  isActiveHandle} from '../../../store/pdf-customizer-reducer/Action/chartDropAction.js'
import { renderDropComponentMap } from './elementsMapping.js'
import { RiDragMove2Line } from 'react-icons/ri'

const NewElementsComponent = memo(({
  elementData,
  elementPosition,
  elementId,
  pageIndex
}) => {
  const dispatch = useDispatch()
  const { activeElement } = useSelector((state) => state.elementStates)

  // find mapped react element based on element name
  const RenderDropComponent = renderDropComponentMap[elementData?.name]
  const isPageHeading = elementData?.name.includes('Page Heading')
  const isPageFooter = elementData?.name.includes('Page Footer')
  const isCoverPageOrPDFPage = Boolean(elementData?.coverPage || elementData?.name === 'PDF Uploads')

  if (!RenderDropComponent) {
    return null
  }

  const handleElementActive = (e) => {
    if (!e?.target?.classList?.contains('drag-handle')) {
      dispatch(isActiveHandle({ pageIndex, elementId, isHeader: elementData?.name?.startsWith('Page Heading'), isFooter: elementData?.name?.startsWith('Page Footer') }))
    }
  }

  return (
    <div
      onClick={(e) => handleElementActive(e)}
      style={{
        width: '100%',
        border: '1px solid transparent',
        height: (isPageFooter || isPageHeading) ? 'auto' : '100%',
        overflowY: 'hidden',
        overflowX: 'auto',
        position: 'relative'
      }}
      className={`element-wrapper ${(activeElement === elementId || (activeElement === 'headingElement' && isPageHeading) || (activeElement === 'footerElement' && isPageFooter)) ? 'isActive' : ''}`}
    >
      {
        !isCoverPageOrPDFPage && !isPageHeading && !isPageFooter
          ? (
            <div
              style={{
                height: 15,
                width: 15,
                position: 'absolute',
                right: 10,
                top: 10,
                zIndex: 99,
                cursor: 'pointer',
                background: 'white',
                border: '0.5px solid #74788D',
                borderRadius: '2px',
                boxShadow: '0px 1px 8px 0px #00000026',
                justifyContent: 'center',
                alignItems: 'center',
                padding: 0
              }}
              className='drag-handle'
            ><RiDragMove2Line className='drag-handle' color='#74788D' size={12} />
            </div>
          )
          : ''
      }
      <div className='width-100 height-100'>
        <RenderDropComponent
          data={elementData}
          chartIndex={elementData?.chartIndex}
          pageIndex={elementData?.pageIndex || pageIndex}
        />
      </div>
    </div>
  )
})

export default NewElementsComponent
