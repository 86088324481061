import { Tooltip, styled } from '@mui/material'

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))`
  & .MuiTooltip-tooltip {
    background: rgba(23, 92, 194, 0.8);
  }
`
export default StyledTooltip
