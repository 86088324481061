import { ARIS_COLORS } from "../../../../utils/pdf-customizer/common"
import { formatCurrencyWithSymbol } from "../../droppableElements/APIResponseConverts/APIResponseConverts"

const defaultFontOptions = (color = '#727474', size = 6) => ({
  color,
  font: {
    size
  }
})
const getFontOptions = () => ({
  title: defaultFontOptions(),
  ticks: defaultFontOptions()
})
const defaultDevice = {
  animation: {
    duration: 50
  },
  devicePixelRatio: 20,
  responsive: true,
  pointLabelFontSize: 10,
  maintainAspectRatio: false
}
const commonChartOptions = {
  ...defaultDevice,
  plugins: {
    legend: {
      display: (chartObject) =>
        chartObject?.chart?.legend?.legendItems.map((data) => data.hidden),
      position: 'top',
      align: 'center',
      labels: {
        boxWidth: 15,
        boxHeight: 5,
        ...defaultFontOptions(),
        padding: 6
      }
    },
    datalabels: {
      display: false
    }
  }
}

const getDefaultAxisOptions = (data) => ({
  display: true,
  stacked: data === 'right',
  position: data === 'right' ? 'right' : 'left',
  ...getFontOptions(),
  beginAtZero: true
})
const getXAxisOptions = (data, grid) => ({
  display: data !== 'right',
  grid: { display: false },
  ...getDefaultAxisOptions(data)
})

const getYAxisOptions = (data) => ({
  grid: {
    lineWidth: (context) => (context?.tick?.value === 0 ? 1 : 0)
  },
  ...getDefaultAxisOptions(data)
})
export const barChartOptions = {
  ...commonChartOptions,
  indexAxis: 'x',
  scales: {
    x: getXAxisOptions(),
    y: getYAxisOptions()
  }
}

export const assetAllocationChartOptions = (categories) => ({
  chart: {
    zoom: {
      enabled: false
    },
    toolbar: {
      show: false
    },
    animation: {
      enabled: false
    }
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '55%',
      dataLabels: {
        position: 'top'
      }
    }
  },
  dataLabels: {
    enabled: true,
    formatter: function (val) {
      return val + '%'
    },
    offsetY: -15,
    style: {
      fontSize: '7px',
      colors: ['#373d3f'],
      fontWeight: 400
    }
  },
  stroke: {
    show: false
  },
  xaxis: {
    categories,
    tooltip: {
      enabled: false
    },
    crosshairs: {
      show: false
    },
    labels: {
      maxHeight: 50,
      trim: true,
      style: {
        fontSize: '7px'
      },
      offsetY: -5
    },
    axisBorder: {
      show: true,
      color: '#8d8d8d',
      height: 1,
      width: '100%',
      offsetX: 0,
      offsetY: 0
    },
    axisTicks: {
      show: false
    }
  },
  yaxis: [
    {
      labels: {
        formatter: function (value) {
          return value?.toFixed(0) + ' %'
        },
        offsetX: -15,
        style: {
          fontSize: '7px'
        }
      },
      crosshairs: {
        show: false
      }
    }
  ],
  grid: {
    padding: {
      left: -5,
      right: 5,
      top: -20
    }
  },
  states: {
    normal: {
      filter: {
        type: 'none'
      }
    },
    hover: {
      filter: {
        type: 'none'
      }
    },
    active: {
      filter: {
        type: 'none'
      }
    }
  },
  markers: {
    size: 0
  },
  fill: {
    opacity: 1
  },
  tooltip: {
    enabled: false
  },
  legend: {
    show: false
  }
})

export const MarketChartOptions = (data) => ({
  ...commonChartOptions,
  indexAxis: data?.name === 'Market cap horizontal bar chart' ? 'y' : 'x',
  scales: {
    x: getXAxisOptions(
      data?.name === 'Market cap horizontal bar chart' ? 'right' : ''
    ),
    y: getYAxisOptions(
      data?.name === 'Market cap horizontal bar chart' ? 'right' : ''
    )
  }
})
export const InitialPortfolioChartOptions = () => ({
  ...defaultDevice,
  maintainAspectRatio: false,
  indexAxis: 'y',
  plugins: {
    legend: {
      position: 'right',
      display: false
    },
    datalabels: {
      align: function (context) {
        const index = context.dataIndex
        const currentValue = context.dataset.data[index]

        const maxValue = Math.max(...context.dataset.data)

        const diff = Math.abs(currentValue - maxValue)

        if (diff < 10000) {
          if (currentValue < 10) {
            return 'end'
          }
          return 'center'
        } else {
          return 'end'
        }
      },
      offset: function (context) {
        const index = context.dataIndex
        const currentValue = context.dataset.data[index]

        const maxValue = Math.max(...context.dataset.data)

        const diff = Math.abs(currentValue - maxValue)

        if (diff < 10000) {
          if (currentValue < 10) {
            return 10
          }
          return 0
        } else {
          return 10
        }
      },
      color: function (context) {
        const index = context.dataIndex
        const currentValue = context.dataset.data[index]

        const maxValue = Math.max(...context.dataset.data)

        const diff = Math.abs(currentValue - maxValue)

        if (diff < 10000) {
          if (currentValue < 10) {
            return '#002A59'
          }
          return 'white'
        } else {
          return '#002A59'
        }
      },
      formatter: function (value, context) {
        const absAmount = Math.abs(Number(value))
        if (absAmount >= 1.0e9) {
          return '$' + (absAmount / 1.0e9).toFixed(2) + 'B'
        } else if (absAmount >= 1.0e6) {
          return '$' + (absAmount / 1.0e6).toFixed(2) + 'M'
        } else if (absAmount >= 1.0e3) {
          return '$' + (absAmount / 1.0e3).toFixed(2) + 'K'
        } else if (absAmount === 0) {
          return '$' + absAmount.toFixed(2)
        } else {
          return '$' + absAmount.toFixed(2)
        }
      },

      font: {
        size: 6
      }
    }
  },
  scales: {
    x: {
      grid: {
        display: false
      },
      barThickness: 10,
      title: { ...defaultFontOptions() },
      ticks: { display: false, ...defaultFontOptions() }
    },
    y: {
      barThickness: 10,
      display: true,
      grid: {
        display: false
      },
      title: { ...defaultFontOptions() },
      ticks: { ...defaultFontOptions() }
    }
  }
})
export const lineChartOptions = {
  ...commonChartOptions,
  indexAxis: 'x',
  plugins: {
    legend: {
      display: true,
      position: 'top',
      ...defaultFontOptions(),
      align: 'center',
      labels: {
        boxWidth: 5,
        color: 'white',
        ...defaultFontOptions(),
        padding: 0
      }
    }
  },
  scales: {
    x: {
      beginAtZero: true
    },
    y: {
      beginAtZero: true
    }
  }
}
export const performanceLineOptions = (performanceData) => {
  return {
    xaxis: {
      type: 'datetime',
      tooltip: {
        enabled: false
      },
      crosshairs: {
        show: false
      },
      axisBorder: {
        show: true,
        color: '#8d8d8d',
        height: 1,
        width: '100%',
        offsetX: 0,
        offsetY: 0
      },
      axisTicks: {
        show: false
      },
      labels: {
        style: {
          fontSize: '7px'
        },
        offsetY: -7
      }
    },
    yaxis: [
      {
        title: {
          offsetX: 0,
          style: {
            fontSize: '7px',
            color: '#74788D',
            fontWeight: 500
          }
        },
        labels: {
          formatter: function (value) {
            return value?.toFixed(2)
          },
          style: {
            fontSize: '7px'
          },
          offsetX: -15
        },
        crosshairs: {
          show: false
        }
      }
    ],
    grid: {
      padding: {
        left: -5,
        right: 5,
        top: -15
      }
    },
    tooltip: {
      enabled: false
    },
    markers: {
      size: 0
    },
    dataLabels: {
      enabled: false
    },
    chart: {
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false
      },
      animation: {
        enabled: false
      }
    },
    legend: {
      show: true,
      // show: performanceData?.find(point => point?.postTaxReturns !== null), // Show legend only if postTax data is present
      position: 'bottom',
      offsetY: -3,
      markers: {
        width: 8,
        height: 2,
        radius: 0
      },
      fontSize: '7px',
      onItemClick: {
        toggleDataSeries: false
      }
    },
    stroke: {
      curve: 'straight',
      width: 1
    },
    fill: {
      type: 'gradient',
      gradient: {
        enabled: true,
        opacityFrom: 0.3,
        opacityTo: 0
      }
    },
    colors: ['#002A59', '#33A68D'],
    noData: {
      text: 'No performance data available',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: '8px',
        fontFamily: undefined
      }
    }
  }
}

export const umaMarketValueDonutChartOptions = (data) => {
  return {
    legend: {
      show: false,
    },
    animation: false,
    label: {
      formatter: (params) => {
        return params?.name + '\n' + formatCurrencyWithSymbol(params?.data?.value, 0, '$')
      },
      fontFamily: 'Open sans',
      fontSize: 6.5
    },
    labelLine: {
      show: true,
      length: 2,
      length2: 2
    },
    series: [
      {
        name: 'Market Value Chart',
        type: 'pie',
        radius: ['40%', '70%'],
        center: ['50%', '50%'],
        data: data
      }
    ],
    color: ARIS_COLORS
  }
}

export const FactorTableOptions = (data) => ({
  ...commonChartOptions,
  indexAxis: 'y',

  plugins: {
    ...defaultFontOptions,
    legend: {
      display: false // Hide legend
    },
    datalabels: {
      enabled: true,
      anchor: data || 'center',
      align: data || 'center',
      verticalAlign: 'top',
      offset: 0,
      ...defaultFontOptions,
      formatter: function (value, context) {
        return value
      },
      font: {
        size: 6
      }
    }
  },

  scales: {
    x: {
      display: false,

      position: 'right',
      ...getFontOptions(),
      grid: {
        lineWidth: (context) => (context.tick.value === 0 ? 1 : 0)
      },
      beginAtZero: true
    },
    y: {
      display: false,

      position: 'right',
      ...getFontOptions(),
      grid: { display: false },
      beginAtZero: true
    }
  }
})
