import React, { useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { Autocomplete, Box, FormControl, IconButton, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material'
import { useAuth } from '../../contexts/AuthContext'
import { useErrorToast } from '../../hooks/useErrorToast'
import './AccountOnboarding.scss'
import DeleteIcon from '@mui/icons-material/Delete'
import * as Sentry from '@sentry/react'
import { checkInstrumentSearchQuery } from '../../utils/searchQueryUtils'

function SecurityRestictions (props) {
  const [restrictedCodes, setRestrictedCodes] = useState([])
  const { showError } = useErrorToast()
  const { user } = useAuth()
  const [customErrors, setCustomErrors] = useState({})
  const [sourceOptions, setSourceOptions] = useState([])
  const [sourceName, setSourceName] = useState([])
  const [customErrors2, setCustomErrors2] = useState({})
  const [isLoadingSource, setIsLoadingSource] = useState(false)
  const [addClicked, setAddClicked] = useState(!!props.personalization?.securityData)
  const [selectValue, setSelectValue] = useState('')
  const [selectKey, setSelectKey] = useState('')
  const [selectInstrID, setSelectInstrId] = useState('')
  const [autocompleteValue, setAutocompleteValue] = useState('')
  const [securityList, setSecurityList] = useState(props.personalization?.securityData ? props.personalization.securityData : [])
  const [selectedValues, setSelectedValues] = useState([])
  const [tableRows, setTableRows] = useState(props.personalization?.securityData
    ? props.personalization.securityData?.map((security) => {
      const secData = {
        autocompleteValue: { name: security.name },
        selectValue: security.action,
        ...(security.action === 'Hold Minimum' || security.action === 'Hold Maximum'
          ? { securityWeight: security.weight }
          : {})
      }
      return secData
    })
    : [])
  const [errorName, setErrorName] = useState(false)
  const [errorCode, setErrorCode] = useState(false)
  const [weightError, setWeightError] = useState(false)
  const [securityWeight, setSecurityWeight] = useState(null)

  // GET API for restriction codes
  const getRestrictedCodes = async () => {
    API.get(
      'baseUriTransactionalMaster',
      `transactional-master/v1/${user.userGroup}/restriction-codes`,
      { queryStringParameters: {} }
    )
      ?.then((response) => {
        if (response?.data) {
          setRestrictedCodes([...response.data])
        }
      })
      .catch((error) => {
        showError(error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
  }

  useEffect(() => {
    props?.onData && props?.onData([...securityList])
  }, [securityList])

  useEffect(() => {
    if (props?.resetSecurityRestrictions) {
      setTableRows([])
    }
  }, [props?.resetSecurityRestrictions])

  // handle onChange for action dropdown
  const onRestrictionCodeChange = (event, key) => {
    if (event.target.value !== '') {
      const temp = customErrors
      delete temp.restrictionCode
      setCustomErrors(temp)
      setSelectValue(event.target.value)
      setSelectKey(key)
      setSelectedValues([...selectedValues, selectValue])
      setErrorCode(false)
      setSecurityWeight(null)
      setWeightError(false)
    }
  }

  // handle onChange for source name
  const onSourceNameChange = (query, newValue) => {
    setIsLoadingSource(true)
    setAutocompleteValue(newValue)
    setSelectInstrId(newValue?.instrId || '')
    if (newValue) {
      setSelectedValues([...selectedValues, newValue])
    }
    if (errorName && newValue?.name) {
      setErrorName(false)
    }
  }

  // API to render the list of securities when searched for security name
  const onInputChange = (event) => {
    const query = (event?.target?.value || '').toString().trim()
    if (query === '') {
      setSourceName([])
    }
    if (sourceName?.length === 0 && query?.toString().trim() === '') {
      setCustomErrors2({ sourceName: { message: 'Source Name required' } })
    } else {
      if (checkInstrumentSearchQuery(query)) {
        setIsLoadingSource(true)
        API.get('baseUriTransactionalMaster', `transactional-master/v1/${user?.userGroup}/instruments`, {
          queryStringParameters: { search: query }
        })
          .then((response) => {
            if (response?.data) {
              setSourceOptions([...response.data])
              const tempErrors = { ...customErrors2 }
              delete tempErrors.sourceName
              setCustomErrors2(tempErrors)
            }
          })
          .catch((error) => {
            showError(error.response?.data?.errorInfo?.userMessage || error.message)
            Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
          })
          .finally(() => {
            setIsLoadingSource(false)
          })
      }
    }
  }

  // handle security restriction with validations
  const handleSubmitSecurityRestrictions = (event) => {
    const isAutocompleteValueEmpty = !autocompleteValue?.name
    const isSelectValueEmpty = !selectValue
    setErrorName(isAutocompleteValueEmpty)
    setErrorCode(isSelectValueEmpty)
    if ((selectValue === 'Hold Minimum' || selectValue === 'Hold Maximum') && !securityWeight) {
      setWeightError(!securityWeight)
      return
    }
    if (!isAutocompleteValueEmpty && !isSelectValueEmpty) {
      const isDuplicate = securityList.some(
        (item) =>
          item.name === autocompleteValue?.name && item.action === selectValue
      )

      if (isDuplicate) {
        showError('An active entry already exists. Please choose a different security.')
        return
      }
      event.preventDefault()
      setAddClicked(true)
      setSecurityList([
        ...securityList,
        {
          name: autocompleteValue?.name,
          action: selectValue,
          secKey: selectKey,
          actionKey: selectInstrID,
          ...(selectValue === 'Hold Minimum' || selectValue === 'Hold Maximum'
            ? { weight: securityWeight }
            : {})
        }
      ])
      setTableRows([
        ...tableRows,
        {
          autocompleteValue,
          selectValue,
          securityWeight
        }
      ])
      setAutocompleteValue('')
      setSelectValue('')
      setSecurityWeight(null)
      setSelectInstrId('')
      setSelectKey('')
      setSelectedValues([])
    }
  }

  useEffect(() => {
    if (user) {
      getRestrictedCodes()
    }
  }, [user])

  return (
    <Box sx={{ width: '100%', marginTop: '-30px' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell
                className='table-cell-styles'
                sx={{
                  paddingLeft: props.size ? '0px !important' : ''
                }}
              >
                <Autocomplete
                  autoWidth
                  id='my-autocomplete'
                  size={props?.size ? '' : 'small'}
                  options={sourceOptions}
                  loading={isLoadingSource}
                  getOptionLabel={(option) => option.name || ''}
                  onInputChange={onInputChange}
                  onChange={onSourceNameChange}
                  value={autocompleteValue}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Security Name'
                      error={errorName}
                      variant='outlined'
                      InputProps={{ ...params.InputProps, placeholder: 'Security Name here' }}
                      required
                    />
                  )}
                />
              </TableCell>
              <TableCell className='table-cell-styles' sx={{ width: '100px !important' }}>
                <FormControl variant='outlined' error={errorCode} size={props?.size ? '' : 'small'}>
                  <InputLabel id='demo-simple-select-label'>Action *</InputLabel>
                  <Select
                    labelId='my-dropdown-label'
                    label='Action'
                    id='my-dropdown'
                    value={selectValue}
                    onChange={(e) => onRestrictionCodeChange(e, restrictedCodes.find(option => option.restrictionDesc === e.target.value).restrictionId)}
                    className='actions-select'
                    native={false}
                    required
                  >
                    {restrictedCodes?.map((option, index) => {
                      if (option.restrictionCode !== 'ONB' && option.restrictionCode !== 'ONS') {
                        return (
                          <MenuItem key={option.restrictionId} value={option.restrictionDesc}>
                            {option.restrictionDesc}
                          </MenuItem>
                        )
                      }
                    })}
                  </Select>
                </FormControl>
              </TableCell>
              {
                  selectValue === 'Hold Minimum' || selectValue === 'Hold Maximum'
                    ? (
                      <TableCell>
                        <FormControl variant='outlined' size={props?.size ? '' : 'small'}>
                          <TextField
                            required
                            size='small'
                            sx={{ fontWeight: 600, width: '150px' }}
                            value={securityWeight}
                            error={weightError}
                            onChange={(e) => {
                              let value = parseFloat(e?.target?.value)
                              if (value > 100) value = 100
                              setWeightError(false)
                              setSecurityWeight(value)
                            }}
                            InputProps={{
                              inputProps: {
                                type: 'number',
                                step: '0.1',
                                min: 0.1,
                                max: 100
                              }
                            }}
                            label='Weight'
                          />
                        </FormControl>
                      </TableCell>
                      )
                    : null
                }
              <TableCell>
                <AddCircleIcon
                  sx={{ mx: 2, color: 'primary.main', marginTop: props?.size ? '16px' : '8px' }}
                  onClick={(e) => handleSubmitSecurityRestrictions(e)}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
      <TableContainer component={Paper} className='table-body'>
        <Table>
          <TableHead>
            <TableRow className='table-header'>
              <TableCell sx={{ fontWeight: 600 }}>Security</TableCell>
              <TableCell sx={{ fontWeight: 600 }}>Action</TableCell>
              <TableCell sx={{ fontWeight: 600 }}>Weight</TableCell>
              <TableCell sx={{ fontWeight: 600 }}>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props?.resetSecurityRestrictions
              ? ''
              : <>
                {addClicked
                  ? tableRows?.map((value, index) => {
                    return (
                      <TableRow key={index}>
                        {value?.autocompleteValue.name && value?.selectValue
                          ? <>
                            <TableCell className='table-cell-styles' sx={{ fontWeight: 600 }}>{value?.autocompleteValue.name}</TableCell>
                            <TableCell className='table-cell-styles' sx={{ fontWeight: 600 }}>{value?.selectValue}</TableCell>
                            <TableCell className='table-cell-styles' sx={{ fontWeight: 600 }} align='right'>{value?.securityWeight}</TableCell>
                            <TableCell className='table-cell-styles' sx={{ fontWeight: 600 }}>
                              <IconButton
                                aria-label='delete' size='medium' onClick={() => {
                                  const temp = [...tableRows]
                                  const security = [...securityList]
                                  temp.splice(index, 1)
                                  security.splice(index, 1)
                                  setTableRows(temp)
                                  setSecurityList(security)
                                }}
                              >
                                <DeleteIcon fontSize='inherit' />
                              </IconButton>
                            </TableCell>
                            </>
                          : ''}
                      </TableRow>
                    )
                  })
                  : ''}
              </>}

          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default SecurityRestictions
