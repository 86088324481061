import { Table, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'

export const RiskTable = ({ children, headers, loading }) => {
  const SecurityWiseHeaders = [
    { name: 'Sr No', id: 1 },
    { name: 'Security', id: 2 },
    { name: 'Security Name', id: 3 },
    { name: 'Weight(%)', id: 5, className: 'text-end' },
    { name: '', id: 4 },
    { name: 'MCTE', id: 6, className: 'text-end' }
  ]

  const SectorWiseHeaders = [
    { name: 'Sr No', id: 11 },
    { name: 'Sector', id: 12 },
    { name: 'Weight(%)', id: 13, className: 'text-end' },
    { name: '', id: 14 },
    { name: 'MCTE', id: 15, className: 'text-end' }
  ]

  const SecurityHeaderHTML = SecurityWiseHeaders.map(({ name, id, className }) => (
    <TableCell key={id} sx={{ textAlign: loading ? 'left' : className === 'text-end' ? 'right' : 'left' }}>{name}</TableCell>
  ))

  const sectorHeaderHTML = SectorWiseHeaders.map(({ name, id, className }) => (
    <TableCell key={id} sx={{ textAlign: loading ? 'left' : className === 'text-end' ? 'right' : 'left' }}>{name}</TableCell>
  ))

  return (
    <>
      <TableContainer mt={5}>
        <Table className='risk-page-table'>
          <TableHead>
            <TableRow>
              {headers === 'Security'
                ? (
                  <>{SecurityHeaderHTML}</>
                  )
                : (
                  <>{sectorHeaderHTML}</>
                  )}
            </TableRow>
          </TableHead>
          {children}
        </Table>
      </TableContainer>
    </>
  )
}
