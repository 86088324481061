import { useState, useEffect, useMemo } from 'react'
import { Box, TextField, debounce } from '@mui/material'

// Custom search field for data grid tables
const CustomKeyFieldSearch = ({ tableData, filterData, fieldName, fieldLabel, handleSearchTextChange = null }) => {
  const [searchText, setSearchText] = useState(null)

  // Uses debounce time to avoid subsequent API calls and calls API after 400ms
  const handleInputChange = useMemo(
    () =>
      debounce(async (request) => {
        if (handleSearchTextChange) {
          // call search API
          await handleSearchTextChange(request)
        } else {
          //   if search text is removed, assign original table data else filter by fieldName column
          if (tableData && tableData.length) {
            filterData(request?.trim().length
              ? tableData.filter((row) =>
                row[fieldName].toLowerCase().includes(request?.toLowerCase())
              )
              : [...tableData]
            )
          }
        }
      }, 400),
    [handleSearchTextChange, tableData]
  )

  useEffect(() => {
    if (searchText !== null) {
      handleInputChange(searchText)
    }
  }, [searchText])

  return (
    <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
      <form autoComplete='off' onSubmit={(e) => e.preventDefault()}>
        <TextField
          size='small'
          label={`Search by ${fieldLabel || fieldName || ''}`}
          variant='outlined'
          type='text'
          value={searchText || ''}
          autoComplete='off'
          onChange={(event) => setSearchText(event?.target?.value)}
        />
      </form>
    </Box>
  )
}

export default CustomKeyFieldSearch
