import React, { useCallback, useEffect, useState } from 'react'
import { Box, styled, Tooltip } from '@mui/material'
import { DataGridPro, gridClasses, GridFilterInputMultipleSingleSelect, GridFilterInputSingleSelect } from '@mui/x-data-grid-pro'
import starIcon from '../../../assets/images/starIcon.svg'
import { useAuth } from '../../../contexts/AuthContext'
import { moduleConfig } from '../../../contexts/data'
import { ACCESS_LEVEL } from '../../../contstants/constants'
import ApprovalRadioButton from './ApprovalRadioButton'
import CustomGridTreeDataGroupingCell from './CustomGridTreeDataGroupingCell'
import './Trade.css'
import CustomPolicyBreakCell from './TradeApprovalIconCells/CustomPolicyBreakCell'
import CustomPropertiesDetailsCell from './TradeApprovalIconCells/CustomPropertiesDetailsCell'
import CustomTradeDetailsCell from './TradeApprovalIconCells/CustomTradeDetailsCell'
import CustomTradeSummaryChartCell from './TradeApprovalIconCells/CustomTradeSummaryChartCell'
import CustomUpdatePropertiesCell from './TradeApprovalIconCells/CustomUpdatePropertiesCell'
import PolicyStatusCell from './TradeApprovalIconCells/PolicyStatusCell'
import TradeCommentColumn from './TradeApprovalIconCells/TradeCommentColumn'

const groupingColDef = {
  headerName: '',
  width: 35,
  minWidth: 35,
  align: 'center',
  field: 'group-toggle',
  renderCell: (params) => <CustomGridTreeDataGroupingCell {...params} />
}

let expansionLookup = {}

const policySelectOperator = [
  {
    label: 'has',
    value: 'has',
    getApplyFilterFn: (filterItem) => {
      if (!filterItem.field || !filterItem.value || !filterItem.operator) {
        return null
      }
      return (params) => {
        return Object.values(params?.row?.policyStatus || {}).includes(filterItem?.value)
      }
    },
    InputComponent: GridFilterInputSingleSelect
  },
  {
    label: 'has not',
    value: 'has not',
    getApplyFilterFn: (filterItem) => {
      if (!filterItem.field || !filterItem.value || !filterItem.operator) {
        return null
      }
      return (params) => {
        return !Object.values(params?.row?.policyStatus || {}).includes(filterItem.value)
      }
    },
    InputComponent: GridFilterInputSingleSelect
  },
  {
    label: 'has any of',
    value: 'has any of',
    getApplyFilterFn: (filterItem) => {
      if (!filterItem.field || !filterItem.value?.length || !filterItem.operator) {
        return null
      }
      return (params) => {
        return Object.values(params?.row?.policyStatus || {}).find(status => filterItem.value.includes(status))
      }
    },
    InputComponent: GridFilterInputMultipleSingleSelect
  }
]

const TradeLegacyViewTable = ({
  dataArray, updatePendingApprovalIds, allScenarioList, getOptimRun, legendState, scenarioMappingWithLegend = {
    balanced: 'Balanced',
    mintax: 'Min Tax',
    model: 'Model'
  },
  apiRef,
  setTradeDatagridState,
  tradeDatagridState,
  filterModel,
  setFilterModel
}) => {
  const [data, setData] = useState(dataArray)
  const { checkAccess } = useAuth()
  const scenarioMap = scenarioMappingWithLegend
  const selectedScenario = legendState ? Object.keys(legendState).filter(key => !legendState[key]).map(scenario => scenarioMap[scenario]) : []
  const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))`
  & .MuiTooltip-tooltip {
    background: rgba(23, 92, 194, 0.8);
  }
`
  const wholeNumberCurrencyFormatter = (amount) => {
    return Math.abs(Number(amount)) >= 1.0e9
      ? (Math.abs(Number(amount)) / 1.0e9).toFixed(0) + 'B'
      : Math.abs(Number(amount)) >= 1.0e6
        ? (Math.abs(Number(amount)) / 1.0e6).toFixed(0) + 'M'
        : Math.abs(Number(amount)) >= 1.0e3
          ? (Math.abs(Number(amount)) / 1.0e3).toFixed(0) + 'K'
          : Math.abs(Number(amount)).toFixed(0)
  }

  const tradeColumns = [
    ...(checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL, id: null, component_name: moduleConfig.TRADE_OPT_TRADE_DETAILS })
      ? [{
          field: 'tradeDetails',
          headerName: '',
          type: 'actions',
          width: 35,
          minWidth: 35,
          renderCell: (params) => (<CustomTradeDetailsCell {...params} data={data} />)
        }]
      : []),
    ...(checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL, id: null, component_name: moduleConfig.VIEW_OPT_TRADE_PROPERTIES })
      ? [{
          field: 'propertiesDetails',
          headerName: '',
          type: 'actions',
          width: 35,
          minWidth: 35,
          renderCell: (params) => (<CustomPropertiesDetailsCell {...params} />)
        }]
      : []
    ),
    ...(checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL, id: null, component_name: moduleConfig.POLICY_CHECK })
      ? [{
          field: 'policyCheck',
          headerName: '',
          type: 'actions',
          width: 35,
          minWidth: 35,
          renderCell: (params) => (<CustomPolicyBreakCell {...params} />)
        }]
      : []
    ),
    ...(checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL, id: null, component_name: moduleConfig.TRADE_SUMMARY })
      ? [{
          field: 'tradeSummary',
          headerName: '',
          type: 'actions',
          width: 35,
          minWidth: 35,
          renderCell: (params) => (<CustomTradeSummaryChartCell {...params} data={data} />)
        }]
      : []
    ),
    ...(checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL, component_name: moduleConfig.ACCOUNT_COMMENTS })
      ? [{
        field: 'comments',
        headerName: '',
        type: 'actions',
        width: 35,
        minWidth: 35,
        renderCell: (params) => (<TradeCommentColumn {...params} />)
      }]
      : []
    ),
    ...(checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL, id: null, component_name: moduleConfig.VIEW_POLICY_STATUS })
      ? [{
          field: 'policyStatus',
          headerName: 'Policy Status',
          type: 'singleSelect',
          valueOptions: ['red', 'yellow', 'green', 'gray'],
          sortable: false,
          width: 80,
          filterOperators: policySelectOperator,
          renderCell: (params) => <PolicyStatusCell params={params} />
        }]
      : []
    ),
    {
      field: 'accountName',
      headerName: 'Account Name',
      width: 200,
      renderCell: (params) => (
        <StyledTooltip
          title={params.row.accountName || 'N/A'}
        >
          <span
            style={{
              textOverflow:
                'ellipsis',
              overflow: 'hidden'
            }}
          >
            {params.row.accountName || 'N/A'}
          </span>
        </StyledTooltip>
      )
    },
    {
      field: 'accountCd',
      headerName: 'Account Code',
      renderCell: (params) => {
        return params.row.accountCd
      }
    },
    {
      field: 'accOptMapId',
      headerName: 'Account Opt Map Id'
    },
    {
      field: 'custodianAccountNumber',
      headerName: 'Custodian No',
      renderCell: (params) => (
        <StyledTooltip
          title={params.row.custodianAccountNumber || 'N/A'}
        >
          <span
            style={{
              textOverflow:
                'ellipsis',
              overflow: 'hidden'
            }}
          >
            {params.row.custodianAccountNumber || 'N/A'}
          </span>
        </StyledTooltip>
      )
    },
    {
      field: 'scenarioDesc',
      headerName: 'Scenario',
      renderCell: (params) => <Box sx={{ display: 'flex', alignItems: 'center' }} className='col col-5'>
        {params?.row?.isDefault
          ? (
            <img
              src={starIcon}
              alt=''
              style={{
                marginBottom: 1, marginRight: 4
              }}
            />
            )
          : (
            <img
              src={starIcon}
              alt=''
              style={{
                visibility: 'hidden', marginBottom: 1, marginRight: 4
              }}
            />
            )}
        <Box>
          {params?.row?.scenarioDesc || 'N/A'}
        </Box>
                              </Box>
    },
    {
      field: 'approval-1',
      headerName: 'AP 1',
      width: 50,
      valueGetter: (params) => params?.row?.approvalDetails?.length > 0 ? params?.row?.approvalDetails[0].isSelected || params?.row?.approvalDetails[0].approvalStatus : 'disabled',
      sortComparator: (v1, v2) => {
        const order = ['APPROVED']
        const index1 = order.indexOf(v1)
        const index2 = order.indexOf(v2)
        return index2 - index1
      },
      renderCell: (params) => <ApprovalRadioButton
        option={params.row}
        handleApprovalRadioClick={handleApprovalRadioClick}
        levelIndex={0}
        dIndex={params.row.dIndex}
        enabledFromCheckAccess={(checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL, id: null, component_name: moduleConfig.APPROVAL1 }))}
                              />
    },
    {
      field: 'approval-2',
      headerName: 'AP 2',
      width: 50,
      valueGetter: (params) => params?.row?.approvalDetails?.length >= 1 ? params?.row?.approvalDetails[1]?.isSelected || params?.row?.approvalDetails[1]?.approvalStatus : null,
      sortComparator: (v1, v2) => {
        const order = ['APPROVED']
        const index1 = order.indexOf(v1)
        const index2 = order.indexOf(v2)
        return index2 - index1
      },
      renderCell: (params) => <ApprovalRadioButton
        option={params.row}
        handleApprovalRadioClick={handleApprovalRadioClick}
        levelIndex={1}
        dIndex={params.row.dIndex}
        enabledFromCheckAccess={checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL, id: null, component_name: moduleConfig.APPROVAL2 })}
                              />
    },
    {
      field: 'initTe',
      headerName: 'initTe',
      headerAlign: 'right',
      align: 'right',
      type: 'number',
      renderCell: (params) => {
        return params?.row?.summary?.preOptAlpha === undefined ? '' : (params.row.summary.initTe?.toFixed(2) || 'N/A')
      }
    },
    {
      field: 'propTe',
      headerName: 'propTe',
      headerAlign: 'right',
      align: 'right',
      type: 'number',
      renderCell: (params) => {
        return params?.row?.summary?.preOptAlpha === undefined ? '' : (params.row.summary.propTe?.toFixed(2) || 'N/A')
      }
    },
    {
      field: 'isTaxable',
      headerName: 'isTaxable',
      headerAlign: 'right',
      align: 'right',
      type: 'boolean',
      renderCell: (params) => {
        return params.row?.isTaxable !== undefined && params.row?.isTaxable !== null ? params.row?.isTaxable ? 'YES' : 'NO' : 'N/A'
      }
    },
    {
      field: 'taxCost',
      headerName: 'taxCost',
      headerAlign: 'right',
      align: 'right',
      type: 'number',
      valueGetter: (params) => params?.row?.summary?.taxCost?.toFixed(0),
      renderCell: (params) => {
        return params?.row?.summary?.preOptAlpha === undefined
          ? ''
          : !isNaN(params.row?.summary?.taxCost)
              ? (params.row?.failedScenario
                  ? 'N/A'
                  : (params.row?.summary?.taxCost < 0
                      ? `-$${wholeNumberCurrencyFormatter(params.row?.summary?.taxCost)}`
                      : params.row?.summary?.taxCost > 0
                        ? `$${wholeNumberCurrencyFormatter(params.row?.summary?.taxCost)}`
                        : '0'
                    ))
              : 'N/A'
      }
    },
    {
      field: 'totalTurnover',
      headerName: 'totalTurnover',
      headerAlign: 'right',
      align: 'right',
      type: 'number',
      renderCell: (params) => {
        return params?.row?.summary?.preOptAlpha === undefined
          ? ''
          : (params.row?.summary?.totalTurnover || 'N/A')
      }
    },
    {
      field: 'tradeAge',
      headerName: 'tradeAge',
      headerAlign: 'right',
      align: 'right',
      type: 'number',
      renderCell: (params) => {
        return params.row?.summary?.tradeAge !== undefined && params.row?.summary?.tradeAge !== null ? params.row?.summary?.tradeAge : 'N/A'
      }
    },
    {
      field: 'isModelChanged',
      headerName: 'ModelChanged',
      headerAlign: 'right',
      align: 'right',
      type: 'boolean',
      renderCell: (params) => {
        return params.row?.summary?.isModelChanged !== undefined && params.row?.summary?.isModelChanged !== null ? params.row?.summary?.isModelChanged ? 'YES' : 'NO' : 'N/A'
      }
    },
    {
      field: 'initNumHoldings',
      headerName: 'initNumHoldings',
      headerAlign: 'right',
      align: 'right',
      type: 'number',
      renderCell: (params) => {
        return params?.row?.summary?.preOptAlpha === undefined
          ? ''
          : (params.row?.summary?.initNumHoldings || 'N/A')
      }
    },
    {
      field: 'propNumHoldings',
      headerName: 'propNumHoldings',
      headerAlign: 'right',
      align: 'right',
      type: 'number',
      renderCell: (params) => {
        return params?.row?.summary?.preOptAlpha === undefined
          ? ''
          : (params.row?.summary?.numSec || 'N/A')
      }
    },
    {
      field: 'buy',
      headerName: 'Buy',
      headerAlign: 'right',
      align: 'right',
      type: 'number',
      renderCell: (params) => {
        return params?.row?.summary?.preOptAlpha === undefined
          ? ''
          : (params.row?.summary?.numBuy || 'N/A')
      }
    },
    {
      field: 'sell',
      headerName: 'Sell',
      headerAlign: 'right',
      align: 'right',
      type: 'number',
      renderCell: (params) => {
        return params?.row?.summary?.preOptAlpha === undefined
          ? ''
          : (params.row?.summary?.numSell || 'N/A')
      }
    },
    {
      field: 'initRisk',
      headerName: 'initRisk',
      headerAlign: 'right',
      align: 'right',
      type: 'number',
      renderCell: (params) => {
        return params?.row?.summary?.preOptAlpha === undefined
          ? ''
          : (params.row?.summary?.initRisk?.toFixed(2) || 'N/A')
      }
    },
    {
      field: 'preOptAlpha',
      headerName: 'preOptAlpha',
      headerAlign: 'right',
      align: 'right',
      type: 'number',
      renderCell: (params) => {
        return params?.row?.summary?.preOptAlpha === undefined
          ? ''
          : params.row?.summary?.preOptAlpha === null
            ? (params.row?.failedScenario ? 'N/A' : '0')
            : params.row?.summary?.preOptAlpha
      }
    },
    {
      field: 'initUtil',
      headerName: 'initUtil',
      headerAlign: 'right',
      align: 'right',
      type: 'number',
      renderCell: (params) => {
        return params?.row?.summary?.preOptAlpha === undefined ? '' : (params.row?.summary?.initUtil || 'N/A')
      }
    },
    {
      field: 'portMv',
      headerName: 'portMv',
      headerAlign: 'right',
      align: 'right',
      type: 'number',
      valueGetter: (params) => params?.row?.summary?.portMv?.toFixed(0),
      renderCell: (params) => {
        return params?.row?.summary?.preOptAlpha === undefined
          ? ''
          : !isNaN(params.row?.summary?.portMv)
              ? (params.row?.failedScenario
                  ? 'N/A'
                  : (params.row?.summary?.portMv < 0
                      ? `-$${wholeNumberCurrencyFormatter(params.row?.summary?.portMv)}`
                      : params.row?.summary?.portMv > 0
                        ? `$${wholeNumberCurrencyFormatter(params.row?.summary?.portMv)}`
                        : '0'))
              : 'N/A'
      }
    },
    {
      field: 'propLongRgl',
      headerName: 'propLongRgl',
      headerAlign: 'right',
      align: 'right',
      type: 'number',
      valueGetter: (params) => params?.row?.summary?.propLongRgl?.toFixed(0),
      renderCell: (params) => {
        return params?.row?.summary?.preOptAlpha === undefined
          ? ''
          : !isNaN(params.row?.summary?.propLongRgl)
              ? (params.row?.failedScenario
                  ? 'N/A'
                  : (params.row?.summary?.propLongRgl < 0
                      ? `-$${wholeNumberCurrencyFormatter(params.row?.summary?.propLongRgl)}`
                      : params.row?.summary?.propLongRgl > 0
                        ? `$${wholeNumberCurrencyFormatter(params.row?.summary?.propLongRgl)}`
                        : '0'))
              : 'N/A'
      }
    },
    {
      field: 'propShortRgl',
      headerName: 'propShortRgl',
      headerAlign: 'right',
      align: 'right',
      type: 'number',
      valueGetter: (params) => params?.row?.summary?.propShortRgl?.toFixed(0),
      renderCell: (params) => {
        return params?.row?.summary?.preOptAlpha === undefined
          ? ''
          : !isNaN(params.row?.summary?.propShortRgl)
              ? (params.row?.failedScenario
                  ? 'N/A'
                  : (params.row?.summary?.propShortRgl < 0
                      ? `-$${wholeNumberCurrencyFormatter(params.row?.summary?.propShortRgl)}`
                      : params.row?.summary?.propShortRgl > 0
                        ? `$${wholeNumberCurrencyFormatter(params.row?.summary?.propShortRgl)}`
                        : '0'))
              : 'N/A'
      }
    },
    {
      field: 'initLtUrgl',
      headerName: 'initLtUrgl',
      headerAlign: 'right',
      align: 'right',
      type: 'number',
      valueGetter: (params) => params?.row?.summary?.initLtUrgl?.toFixed(0),
      renderCell: (params) => {
        return params?.row?.summary?.preOptAlpha === undefined
          ? ''
          : !isNaN(params?.row?.summary?.initLtUrgl)
              ? (params.row?.failedScenario
                  ? 'N/A'
                  : (params?.row?.summary?.initLtUrgl < 0
                      ? `-$${wholeNumberCurrencyFormatter(params?.row?.summary?.initLtUrgl)}`
                      : params?.row?.summary?.initLtUrgl > 0
                        ? `$${wholeNumberCurrencyFormatter(params?.row?.summary?.initLtUrgl)}`
                        : '0'))
              : 'N/A'
      }
    },
    {
      field: 'initStUrgl',
      headerName: 'initStUrgl',
      headerAlign: 'right',
      align: 'right',
      type: 'number',
      valueGetter: (params) => params?.row?.summary?.initStUrgl?.toFixed(0),
      renderCell: (params) => {
        return params?.row?.summary?.preOptAlpha === undefined
          ? ''
          : !isNaN(params.row?.summary?.initStUrgl)
              ? (params.row?.failedScenario
                  ? 'N/A'
                  : (params.row?.summary?.initStUrgl < 0
                      ? `-$${wholeNumberCurrencyFormatter(params.row?.summary?.initStUrgl)}`
                      : params.row?.summary?.initStUrgl > 0
                        ? `$${wholeNumberCurrencyFormatter(params.row?.summary?.initStUrgl)}`
                        : '0'))
              : 'N/A'
      }
    },
    {
      field: 'propLtUrgl',
      headerName: 'propLtUrgl',
      headerAlign: 'right',
      align: 'right',
      type: 'number',
      valueGetter: (params) => params?.row?.summary?.propLtUrgl?.toFixed(0),
      renderCell: (params) => {
        return params?.row?.summary?.preOptAlpha === undefined
          ? ''
          : !isNaN(params.row?.summary?.propLtUrgl)
              ? (params.row?.failedScenario
                  ? 'N/A'
                  : (params.row?.summary?.propLtUrgl < 0
                      ? `-$${wholeNumberCurrencyFormatter(params.row?.summary?.propLtUrgl)}`
                      : params.row?.summary?.propLtUrgl > 0
                        ? `$${wholeNumberCurrencyFormatter(params.row?.summary?.propLtUrgl)}`
                        : '0'))
              : 'N/A'
      }
    },
    {
      field: 'propStUrgl',
      headerName: 'propStUrgl',
      headerAlign: 'right',
      align: 'right',
      type: 'number',
      valueGetter: (params) => params?.row?.summary?.propStUrgl?.toFixed(0),
      renderCell: (params) => {
        return params?.row?.summary?.preOptAlpha === undefined
          ? ''
          : !isNaN(params.row?.summary?.propStUrgl)
              ? (params.row?.failedScenario
                  ? 'N/A'
                  : (params.row?.summary?.propStUrgl < 0
                      ? `-$${wholeNumberCurrencyFormatter(params.row?.summary?.propStUrgl)}`
                      : params.row?.summary?.propStUrgl > 0
                        ? `$${wholeNumberCurrencyFormatter(params.row?.summary?.propStUrgl)}`
                        : '0'))
              : 'N/A'
      }
    },
    ...(checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL, id: null, component_name: moduleConfig.RUN_ADHOC })
      ? [{
          field: 'action',
          headerName: 'action',
          headerAlign: 'center',
          align: 'center',
          renderCell: (params) => (<CustomUpdatePropertiesCell {...params} data={data} allScenarioList={allScenarioList} />)
        }]
      : [])
  ]

  const exportDataGridState = () => {
    if (apiRef?.current?.exportState) {
      setTradeDatagridState(apiRef?.current?.exportState())
    }
  }

  const restoreDatagridState = () => {
    if (apiRef?.current?.restoreState && tradeDatagridState) {
      apiRef?.current?.restoreState({
        pinnedColumns: tradeDatagridState.pinnedColumns,
        columns: tradeDatagridState.columns,
        filter: filterModel,
        sorting: tradeDatagridState.sorting
      })
    }
  }

  useEffect(() => {
    setData(dataArray)
    expansionLookup = {}
  }, [dataArray])

  // restore the datagrid state whenever the state variables updates
  // add new state variables in the dependency array to prevent columns from getting resize and reorder
  useEffect(() => {
    restoreDatagridState()
  }, [data, tradeDatagridState, filterModel])

  useEffect(() => {
    if (apiRef?.current?.subscribeEvent) {
      apiRef?.current?.subscribeEvent('rowExpansionChange', (node) => {
        if (node?.groupingKey) {
          expansionLookup[node?.groupingKey] = node?.childrenExpanded
        }
      })
    }
  }, [apiRef])

  const isGroupExpandedByDefault = useCallback(
    (node) => {
      return node?.groupingKey && !!expansionLookup?.[node?.groupingKey]
    },
    []
  )

  const handleApprovalRadioClick = (e, selectedOption, dIndex, scenarioDesc, approve) => {
    const newData = data
    const index = newData[dIndex]?.optDetails?.findIndex(
      (option) => option.scenarioDesc === scenarioDesc
    )
    if (
      newData[dIndex] &&
      newData[dIndex]?.optDetails &&
      index !== -1 &&
      newData[dIndex]?.optDetails[index]?.approvalDetails &&
      newData[dIndex]?.optDetails[index]?.approvalDetails[approve]?.isSelected
    ) {
      e.currentTarget.checked = false
      newData[dIndex].optDetails[index].approvalDetails[approve].isSelected = false
    } else {
      newData[dIndex].optDetails = newData[dIndex]?.optDetails?.map((item) => {
        if (item?.approvalDetails[approve]) {
          item.approvalDetails[approve].isSelected =
            item?.approvalDetails[approve]?.optApprovalId === selectedOption
        }
        return item
      })
    }
    const formattedData = newData
      .flatMap(account =>
        account.optDetails?.flatMap(detail =>
          detail?.approvalDetails
            .filter(approval => approval?.isSelected)
            .map(approval => ({
              accountId: account?.accountId, // store accountId
              accOptMapId: detail?.accOptMapId, // store accOptMapId
              approvalId: approval?.optApprovalId // store optApprovalId
            }))
        )
      )
    setData(newData)
    updatePendingApprovalIds(formattedData)
  }

  const findFirstTradeScenario = (item) => {
    let itemOpt = []
    if (selectedScenario?.length > 0 && item.optDetails.find((i) => selectedScenario.includes(i.scenarioDesc) && (legendState ? legendState.isDefaultScenario ? i.isDefault : true : true))) {
      // Find the selected scenario in item.optDetails and push it into itemOpt
      const foundScenario = item.optDetails.find((i) => selectedScenario.includes(i.scenarioDesc) && (legendState ? legendState.isDefaultScenario ? i.isDefault : true : true))
      if (foundScenario) {
        itemOpt.push(foundScenario)
      }
    } else {
      itemOpt.push(
        // show opt run that is already approved or has single level of approval
        item.optDetails.find((i) => {
          if (
            (i.approvalDetails[0]?.approvalLevel === 1 ||
              i.approvalDetails[0]?.approvalLevel === 2) &&
            i.approvalDetails[0]?.approvalStatus === 'APPROVED'
          ) {
            return true
          }
          return false
        })
      )
      // show default opt run first
      if (itemOpt[0] === undefined) {
        itemOpt = []
        itemOpt.push(item.optDetails.find((i) => i.isDefault))
      }
      // if no default opt run or approved opt run present, then show in the same order of API response
      if (itemOpt[0] === undefined) {
        itemOpt[0] = item.optDetails[0]
      }
    }
    return itemOpt
  }

  return (
    <>
      <DataGridPro
        apiRef={apiRef}
        columns={tradeColumns}
        density='compact'
        onColumnOrderChange={() => exportDataGridState()}
        onColumnWidthChange={() => exportDataGridState()}
        onSortModelChange={() => exportDataGridState()}
        // pinnedColumns={{
        //   left: ['__tree_data_group__', 'tradeDetails', 'propertiesDetails', 'policyCheck', 'tradeSummary', 'accountName']
        // }}
        rows={data.flatMap((account, actIndex) => {
          const firstScenario = findFirstTradeScenario(account)
          return account?.optDetails?.map((opt, optIndex) => {
            const isFirstScenario = firstScenario?.length > 0 && firstScenario[0].runId === opt?.runId
            return {
              id: opt.runId,
              dIndex: actIndex,
              optIndex,
              path: isFirstScenario ? `${account?.accountCd}-${account?.optId}` : `${account?.accountCd}-${account?.optId}/${opt?.scenarioDesc}`,
              account,
              ...account,
              ...opt,
              ...opt.summary
            }
          })
        })}
        initialState={{
          pinnedColumns: {
            left: ['__tree_data_group__', 'tradeDetails', 'propertiesDetails', 'policyCheck', 'tradeSummary', 'comments', 'policyStatus', 'accountName']
          },
          columns: {
            columnVisibilityModel: {
              accOptMapId: false,
              initUtil: false,
              preOptAlpha: false
            }
          }
        }}
        filterModel={filterModel}
        onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
        treeData
        getTreeDataPath={(row) => row.path.split('/')}
        groupingColDef={groupingColDef}
        isGroupExpandedByDefault={isGroupExpandedByDefault}
        sx={(theme) => ({
          [`.${gridClasses.main}`]: {
            overflow: 'unset'
          },
          [`.${gridClasses.columnHeaders}`]: {
            position: 'sticky',
            backgroundColor: theme.palette.background.paper,
            zIndex: 96,
            top: 0
          },
          [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
            color: '#74788d',
            fontWeight: 600
          },
          [`.${gridClasses.virtualScroller}`]: {
            '::-webkit-scrollbar': {
              width: '0px !important'
            },
            maxHeight: 'calc(100vh - 70px - 48px - 35px - 90px)'
          }
        })}
      />
    </>
  )
}

export default TradeLegacyViewTable
