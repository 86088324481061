import { Auth } from 'aws-amplify'

export const SignInMfa = async ({ user, action, value }) => {
  try {
    if ((user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA') && action === 'MFA_LOGIN') {
      const loggedUser = await Auth.confirmSignIn(
        user,
        value,
        user.challengeName
      )
      return { type: 'SUCCESS', value: loggedUser }
    } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED' && action === 'NEW_PASSWORD') {
      const name = user?.challengeParam?.userAttributes?.name || user?.username
      const newUser = await Auth.completeNewPassword(
        user,
        value,
        { name }
      )
      // We can also processed with MFA but it is recommend to re-login.
      return { type: 'SUCCESS', value: newUser }
    } else if ((user.challengeName === 'MFA_SETUP' || user.challengeName === undefined) && action === 'MFA_TOTP_SETUP') {
      if (!value) {
        return Auth.setupTOTP(user).then((code) => {
          return { type: 'SUCCESS', value: code }
        })
      } else {
        return Auth.verifyTotpToken(user, value)
          .then(() => {
            Auth.setPreferredMFA(user, 'TOTP')
            return { type: 'SUCCESS', value: user }
          })
          .catch((error) => {
            // Token is not verified
            return { type: 'FAIL', value: error }
          })
      }
    } else {
      // The user directly signs in
    }
    // else if (user.challengeName === 'SELECT_MFA_TYPE') {
    //   user.sendMFASelectionAnswer(mfaType, {
    //     onFailure: (err) => {
    //       console.error(err);
    //     },
    //     mfaRequired: (challengeName, parameters) => {
    //       // Auth.confirmSignIn with SMS code
    //     },
    //     totpRequired: (challengeName, parameters) => {
    //       // Auth.confirmSignIn with TOTP code
    //     }
    //   });
    // }
  } catch (error) {
    if (error.code === 'UserNotConfirmedException') {
      // The error happens if the user didn't finish the confirmation step when signing up
      // In this case you need to resend the code and confirm the user
      // About how to resend the code and confirm the user, please check the signUp part
    } else if (error.code === 'PasswordResetRequiredException') {
      // The error happens when the password is reset in the Cognito console
      // In this case you need to call forgotPassword to reset the password
      // Please check the Forgot Password part.
    } else if (error.code === 'NotAuthorizedException') {
      // The error happens when the incorrect password is provided
    } else if (error.code === 'UserNotFoundException') {
      // The error happens when the supplied username/email does not exist in the Cognito user pool
    } else {
      // console.log(error)
    }
    return { type: 'FAIL', value: error }
  }
}
