import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  reviewPageAccounts: {}
}

const accountReviewSlice = createSlice({
  name: 'accountReview',
  initialState,
  // action as functions
  reducers: {
    storeReviewPageAccounts(state, action) {
      state.reviewPageAccounts = { ...state.reviewPageAccounts, [`${action.payload.moduleName}`]: action.payload.accounts }
    },
    clearReviewPageAccountsStore (state, action) {
      if (action.payload === 'RESET') {
        state.reviewPageAccounts = {}
      }
    }
  }
})

export const { storeReviewPageAccounts, clearReviewPageAccountsStore } = accountReviewSlice.actions
export default accountReviewSlice.reducer
