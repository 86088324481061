import * as React from 'react'
import { Grid } from '@mui/material'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { DataGridPro } from '@mui/x-data-grid-pro'

export const IPSTable = (props) => {
  const [pageSize, setPageSize] = React.useState(10)

  function getRowId (row) {
    return row?.pId
  }

  const handlePageSizeChange = (newPageSize) => {
    // Update the state variable for pageSize
    setPageSize(newPageSize)
  }

  if (props?.columns) {
    return (
      <Grid xs={12}>
        <Box className='trade-table-list-header' mb={0}>{props?.tableHeader}</Box>
        {props?.rows?.length > 0
          ? <Box
              sx={{
                '.MuiDataGrid-footerContainer': {
                  border: 'none'
                },
                '.MuiDataGrid-root': {
                  border: 'none'
                },
                '.MuiTablePagination-toolbar': {
                  alignItems: 'baseline'
                }
              }}
            >
            <DataGridPro
              autoHeight
              rows={props?.rows}
              columns={props?.columns}
              filterModel={props?.filterModel}
              onCellClick={props?.onCellClick}
              onFilterModelChange={props?.onFilterModelChange}
              getRowId={getRowId}
              pagination
              pageSizeOptions={[pageSize]}
              initialState={{
                ...props?.rows?.initialState,
                pagination: { paginationModel: { pageSize } }
              }}
              hideFooter={!(props?.rows?.length > pageSize)}
              onPaginationModelChange={(model) => {
                // Handle the pagination model change
                handlePageSizeChange(model.pageSize)
              }}
              disableRowSelectionOnClick
            />
            </Box>
          : <Stack direction='row' pt={1.5}>
            <Typography variant='outlined' sx={{ fontSize: 14, color: '#34475A' }}>
              No {props.tableHeader?.toLowerCase()} available.
            </Typography>
            </Stack>}
      </Grid>
    )
  } else {
    return (
      <Stack direction='row' pt='20px' pl='20px'>
        <Typography variant='outlined' sx={{ fontSize: 14, color: '#34475A' }}>
          No Data
        </Typography>
      </Stack>
    )
  }
}
