import { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { Box, Card, CardContent, Dialog, DialogContent, DialogTitle, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import Skeleton from '@mui/material/Skeleton'
import { ArcElement, Chart as ChartJS, Tooltip } from 'chart.js'
import redirect from '../../assets/images/pop-out-line.svg'
import { formatCurrency } from '../../utils/FormateCurrenyInMilion'
import DonutChart from './DonutChart'

ChartJS.register(ArcElement, Tooltip)

const TopAssignedModels = ({ topAssignedModels, allAssignedModels, isLoading }) => {
  const [showModal, setShowModal] = useState(false)
  const [topAssignedModelsChart, setTopAssignedModelsChart] = useState({
    labels: [],
    series: [],
    colors: []
  })
  useEffect(() => {
    if (topAssignedModels) {
      const chartConfig = {
        labels: topAssignedModels?.map(model => model.strategyName),
        series: topAssignedModels?.map(model => model.totalMarketValue),
        colors: ['#002A59', '#33557A', '#667F9B', '#99AABD', '#CCD4DE']
      }
      setTopAssignedModelsChart(chartConfig)
    }
  }, [topAssignedModels])

  const openModal = () => {
    setShowModal(true)
  }

  const closeModal = () => {
    setShowModal(false)
    setTimeout(() => {
    }, 200)
  }
  return (
    <>
      <Card variant='outlined' sx={{ height: '100%' }}>
        <CardContent>
          <Box display='flex' alignItems='center' justifyContent='space-between'>
            <Typography
              component='p'
              className='text-title header-text'
            >
              Top Assigned Models
            </Typography>
            <IconButton
              onClick={openModal}
              className='tooltip-trade'
            >
              <img src={redirect} alt='' />
              <span
                className='tooltiptext'
                style={{ marginLeft: '-20px' }}
              >
                All Assigned Models
              </span>
            </IconButton>
          </Box>
          {isLoading
            ? <Skeleton
                variant='rectangle'
                animation='wave'
                width='100%'
                height={270}
              />
            : (topAssignedModels && topAssignedModelsChart.series?.length)
                ? (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: { xs: 'column', md: 'row' },
                      justifyContent: { xs: 'center', md: 'space-between' },
                      alignItems: 'flex-start',
                      paddingX: '10px'
                    }}
                    alignItems='center'
                    className='assigned-models-container'
                  >
                    <Box className='pie-chart'>
                      {(topAssignedModels && topAssignedModelsChart && topAssignedModelsChart?.series?.length) &&
                        <DonutChart chartData={topAssignedModelsChart} />}
                    </Box>
                    <Box className='top-assigned-model-table-container' overflow='auto' mt={2}>
                      <TableContainer>
                        <Table sx={{
                          '.MuiTableCell-root': {
                            padding: '8px',
                            fontWeight: 600,
                            fontSize: '14px',
                            lineHeight: '14px',
                            whiteSpace: 'nowrap',
                            color: '#2F2F2F'
                          },
                          '.MuiTableCell-body': {
                            borderBottom: 'none'
                          }
                        }}
                        >
                          <TableBody>
                            {(topAssignedModels || []).map((model, index) => (
                              <TableRow key={index}>
                                <TableCell>
                                  <Grid container alignItems='center' spacing={1} flexWrap='nowrap'>
                                    <Grid item>
                                      <Box style={{ height: '15px', width: '15px', backgroundColor: topAssignedModelsChart.colors[index], borderRadius: '3px' }} />
                                    </Grid>
                                    <Grid item fontWeight={600} fontSize='12px' color='#2F2F2F'>
                                      {model.strategyName}
                                    </Grid>
                                  </Grid>
                                </TableCell>
                                <TableCell align='right'>${formatCurrency(model.totalMarketValue)}</TableCell>
                                <TableCell sx={{ fontSize: '8px !important', paddingRight: '4px !important' }} align='right'>A/C</TableCell>
                                <TableCell align='right' sx={{ paddingLeft: '0px !important' }}>
                                  {model.totalAccounts}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </Box>
                  )
                : <Typography component='div' py={1} className='text-title'>No Data</Typography>}
        </CardContent>
      </Card>
      {topAssignedModels
        ? <Dialog
            maxWidth={600}
            open={showModal}
            onClose={() => closeModal()}
          >
          <DialogTitle sx={{ pb: '0px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Typography
              variant='h6' sx={{ fontWeight: 600, color: '#34475A' }}
            >
              All Assigned Models
            </Typography>

            <IconButton
              aria-label='close'
              onClick={closeModal}
              sx={{
                position: 'absolute',
                right: 15,
                top: 8,
                color: (theme) => theme.palette.grey[500]
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box sx={{ maxHeight: '500px', overflowY: 'auto', width: 600 }}>

              <Box mt={2} mb={4} className='assigned-models-container'>
                <TableContainer>
                  <Table sx={{
                    '.MuiTableCell-root': {
                      padding: '10px',
                      fontWeight: 600,
                      fontSize: '14px !important',
                      lineHeight: '18px',
                      color: '#74788D'
                    },
                    '.MuiTableCell-body': {
                      color: '#34475A'
                    }
                  }}
                  >
                    <TableHead sx={{
                      position: 'sticky', top: '0', background: 'white'
                    }}
                    >
                      <TableRow>
                        <TableCell>Model</TableCell>
                        <TableCell align='right'>Value</TableCell>
                        <TableCell align='right'>A/C</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(allAssignedModels || []).map((model, index) => (
                        <TableRow key={index} height='46px'>
                          <TableCell>
                            {model.strategyName}
                          </TableCell>
                          <TableCell align='right'>${formatCurrency(model.totalMarketValue)}</TableCell>
                          <TableCell align='right'>{model.totalAccounts}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </DialogContent>
          </Dialog>
        : ''}
    </>
  )
}

export default TopAssignedModels
