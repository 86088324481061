import React from 'react'
import { Checkbox } from '@mui/material'
import { useGridApiContext } from '@mui/x-data-grid-pro'

const CustomEditCheckComponent = (props) => {
  const { id, value, field, row } = props
  const apiRef = useGridApiContext()
  const handleValueChange = (event) => {
    apiRef.current.setEditCellValue({ id, field, value: event.target.checked ? 1 : 0 })
    if (field === 'isDefault') {
      apiRef.current.setEditCellValue({ id, field: 'statsValue', value: event.target.checked ? row?.attrLabel === 'Volatility' || row?.attrLabel === 'Size' ? 'B-0.15' : 'B+0.15' : '' })
      apiRef.current.updateRows([{ id, error: !event.target.checked }])
    } else {
      if (event.target.checked) {
        apiRef.current.setEditCellValue({ id, field: 'min', value: '' })
        apiRef.current.setEditCellValue({ id, field: 'max', value: '' })
      }
      apiRef.current.updateRows([{ id, error: !event.target.checked, minError: false, maxError: false }])
    }
  }

  return (
    <Checkbox
      name={field}
      value={value}
      checked={value === 1}
      onChange={handleValueChange}
      onClick={(event) => event.stopPropagation()}
    />
  )
}

export default CustomEditCheckComponent
