import { API } from 'aws-amplify'
import { useAuth } from '../contexts/AuthContext'

export function useAccountService () {
  const { user } = useAuth()

    const getAccountAttributes = async (accountId, queryParams) => {
    return API
      .get('baseURL', `account-master/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/accounts/${accountId}/attributes`, { queryStringParameters: queryParams })
  }
  const getAccountRestrictionDetails = async (accountId, queryParams) => {
    return API
      .get('baseURL', `account-master/v1/${user.userGroup}/accounts/${accountId}/instr-restriction`, { queryStringParameters: queryParams })
  }
  const getAccountSubstitutionDetails = async (accountId, queryParams) => {
    return API
      .get('baseURL', `account-master/v1/${user.userGroup}/accounts/${accountId}/instr-substitution`, { queryStringParameters: queryParams })
  }
  const getAccountMasterDetails = async (accountId, queryParams) => {
    return API
      .get('baseAccountURL', `data-maintenance/v1/${user.userGroup}/accounts/${accountId}/master`, { queryStringParameters: queryParams })
  }
  const getStrategyAttributes = async (strategyId, queryParams) => {
    return API
      .get('baseStrategyURL', `strategy/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/strategies/${strategyId}/attributes`, { queryStringParameters: queryParams })
  }
  const postExtglData = async (accountId) => {
    return API
      .post('baseAccountURL', `data-maintenance/v1/${user.userGroup}/accounts/${accountId}/external-gain-loss`, { })
  }
  return { getAccountAttributes, getStrategyAttributes, getAccountMasterDetails, getAccountRestrictionDetails, postExtglData, getAccountSubstitutionDetails }
}
