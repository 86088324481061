import React, { useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import { CloseOutlined, FileDownloadOutlined, TryOutlined } from '@mui/icons-material'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import { Box, Button, Divider, IconButton, Skeleton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { randomId } from '@mui/x-data-grid-generator'
import { DataGridPro, gridClasses, useGridApiRef } from '@mui/x-data-grid-pro'
import * as Sentry from '@sentry/react'
import '../../../assets/styles/AccountDetailspage.scss'
import { useAuth } from '../../../contexts/AuthContext'
import { moduleConfig } from '../../../contexts/data'
import { ACCESS_LEVEL } from '../../../contstants/constants'
import { useErrorToast } from '../../../hooks/useErrorToast'
import { formatCurrency } from '../../../utils/FormateCurrenyInMilion'
import Loader from '../../Loader'
import StyledTradePopupBox from '../components/StyledTradePopupBox'
import { HtmlTooltip } from './TradeApprovedMain'

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))`
  & .MuiTooltip-tooltip {
    background: rgba(23, 92, 194, 0.8);
  }
`
const ViewTradeApprovedModal = ({ selectedItem, index, setIsShown }) => {
  const apiRef = useGridApiRef()
  const { user, checkAccess } = useAuth()
  const { showError } = useErrorToast()
  const [loading, setLoading] = useState(true)
  const [optimPropertyDetails, setOptimPropertyDetails] = useState()
  const [optMapId] = useState({ selectedItem })
  const [noData, setNoData] = useState()
  const [tradeLogsLoading, setTradeLogsLoading] = useState(false)

  const getOptimPropertyDetails = (optMapId) => {
    const accMapId = optMapId?.selectedItem?.optDetails.length > 0 && optMapId?.selectedItem?.optDetails[0]?.accOptMapId
    API.post(
      'baseUriTrade',
      `trade/v1/${user?.userGroup}/trade-prop-details`,
      { body: { accOptMapId: [accMapId] } }
    )
      .then((response) => {
        if (response) {
          setNoData(response?.data?.data ? null : response.message)
          setOptimPropertyDetails(response?.data?.data ? response?.data?.data?.map((row) => ({ ...row, id: randomId() })) : [])
          setLoading(false)
        }
      })
      .catch((error) => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        setLoading(false)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
  }

  useEffect(() => {
    if (user) {
      getOptimPropertyDetails(optMapId)
    }
  }, [user])

  const fixConnectivityStatusColor = (data) => {
    switch (data) {
      case 'TRADE_COMPLETED':
        return '#3BBFA3'
      case 'TRADE_SENT':
        return '#d29922'
      case 'TRADE_STOPPED':
        return '#F89406'
      case 'NA':
        return '#34475A'
      default:
        return '#ff6161'
    }
  }

  const handleDownloadTradeLogs = async (params) => {
    setTradeLogsLoading(true)
    try {
      const response = await API.post('baseUriTrade', `trade/v1/${user?.userGroup}/trade-logs`, { body: { tradeIds: [params?.tradeId] }})
      if (response?.data) {
        const jsonBlob = new Blob([JSON.stringify(response?.data, null, 2)], { type: 'application/json' })
        const url = URL.createObjectURL(jsonBlob)
        const fileName = `Trade_logs_${params?.instrId}.json`

        const linkTag = document.createElement('a')
        linkTag.href = url
        linkTag.download = fileName
        document.body.appendChild(linkTag)
        linkTag.click()
        document.body.removeChild(linkTag)
        URL.revokeObjectURL(url)
      } else {
        showError(response?.message || 'No data available')
      }
    } catch (error) {
      showError(error.response?.data?.errorInfo?.userMessage || error.message)
      Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
    } finally {
      setTradeLogsLoading(false)
    }
  }

  const tableHeaders = [
    ...(checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, {
      subModuleName: moduleConfig.TRADE_HISTORY,
      component_name: moduleConfig.VIEW_TRADE_EXECUTION_LOGS
    })
      ? [{
        field: 'actions',
        headerName: '',
        width: 50,
        renderCell: (params) => (
          <IconButton
            className='action-clock-btn'
            onClick={() => {
              handleDownloadTradeLogs(params?.row)
            }}
          >
            <StyledTooltip title='Download Trade Logs'>
              <FileDownloadOutlinedIcon sx={{ color: '#74788d', fontSize: '20px' }} />
            </StyledTooltip>
          </IconButton>
        )
      }]
      : []
    ),

    { field: 'instrId', headerName: 'Instrument Id' },
    { field: 'localSymbol', headerName: 'Local Symbol' },
    { field: 'tradeStatusCode', headerName: 'Trade Status' },
    {
      field: 'fixFlyerStatus',
      headerName: 'FIX Connectivity Status',
      renderCell: (params) => {
        const data = params?.value
        return (
          <Box sx={{ color: fixConnectivityStatusColor(data), textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }} title={data}>
            {data || 'N/A'}
          </Box>
        )
      }
    },
    {
      field: 'purchaseDate',
      headerName: 'Purchase Date',
      type: 'date',
      valueGetter: (params) => {
        if (!params?.value) return params?.value
        const date = new Date(params?.value)
        return new Date(date?.getTime() + date?.getTimezoneOffset() * 1000 * 60)
      },
      renderCell: (params) => params?.row?.purchaseDate ? params?.row?.purchaseDate?.split('T')[0] : ''
    },
    { field: 'orgPurchasePrice', headerName: 'Original Purchase Price', type: 'number', valueFormatter: (params) => params?.value?.toFixed(2), align: 'right', headerAlign: 'right' },
    {
      field: 'initWeight',
      headerName: 'Initial Weight (%)',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      valueGetter: (params) => (params?.value * 100).toFixed(2),
      renderCell: (params) => (params?.row?.initWeight * 100).toFixed(2)
    },
    {
      field: 'propWeight',
      headerName: 'Proposed Weight (%)',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      valueGetter: (params) => (params.value * 100).toFixed(2),
      renderCell: (params) => (params?.row?.propWeight * 100).toFixed(2)
    },
    { field: 'initShares', headerName: 'Initial Shares', type: 'number', align: 'right', headerAlign: 'right' },
    { field: 'propShares', headerName: 'Proposed Shares', type: 'number', valueFormatter: (params) => params?.value, align: 'right', headerAlign: 'right' },
    {
      field: 'tradedShares',
      headerName: 'Traded Shares',
      type: 'number',
      align: 'right',
      renderCell: (params) => {
        const data = params?.row?.fixFlyerStatus
        return params?.value !== null
          ? <Box sx={{ color: data === 'TRADE_COMPLETED' ? '#3BBFA3' : data === 'TRADE_SENT' ? '#d29922' : data === 'NA' ? '#34475A' : '#ff6161', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{params?.value || 0}</Box>
          : 'NA'
      }
    },
    {
      field: 'tradedPrice',
      headerName: 'Traded Price',
      type: 'number',
      align: 'right',
      renderCell: (params) => !isNaN(params?.row?.tradedPrice) && params?.row?.tradedPrice !== null
        ? params?.row?.tradedPrice < 0
          ? `-$${formatCurrency(params?.row?.tradedPrice)}`
          : `$${formatCurrency(params?.row?.tradedPrice)}`
        : 'N/A'
    },
    { field: 'age', headerName: 'Age', type: 'number', align: 'right', headerAlign: 'right' },
    {
      field: 'costBasis',
      headerName: 'Cost Basis',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      renderCell: (params) => params?.row?.costBasis
        ? params?.row?.costBasis.toFixed(2)
        : '-'
    },
    {
      field: 'lastClosePrice',
      headerName: 'Last Closed Price',
      type: 'number',
      renderCell: (params) => !isNaN(params?.row?.lastClosePrice)
        ? params?.row?.lastClosePrice < 0
          ? `-$${formatCurrency(params?.row?.lastClosePrice)}`
          : `$${formatCurrency(params?.row?.lastClosePrice)}`
        : 'N/A',
      align: 'right'
    },
    {
      field: 'initMarketValue',
      headerName: 'Initial Market Value',
      type: 'number',
      renderCell: (params) => !isNaN(params?.row?.initMarketValue)
        ? params?.row?.initMarketValue < 0
          ? `-$${formatCurrency(params?.row?.initMarketValue)}`
          : `$${formatCurrency(params?.row?.initMarketValue)}`
        : 'N/A',
      align: 'right'
    },
    {
      field: 'propMarketValue',
      headerName: 'Proposed Market Value',
      type: 'number',
      renderCell: (params) => !isNaN(params?.row?.propMarketValue)
        ? params?.row?.propMarketValue < 0
          ? `-$${formatCurrency(params?.row?.propMarketValue)}`
          : `$${formatCurrency(params?.row?.propMarketValue)}`
        : 'N/A',
      align: 'right'
    },
    {
      field: 'initUrgl',
      headerName: 'Initial Unrealized gain-loss',
      type: 'number',
      renderCell: (params) => !isNaN(params?.row?.initUrgl)
        ? params?.row?.initUrgl < 0
          ? `-$${formatCurrency(params?.row?.initUrgl)}`
          : `$${formatCurrency(params?.row?.initUrgl)}`
        : 'N/A',
      align: 'right'
    },
    {
      field: 'rgl',
      headerName: 'Realized gain-loss',
      type: 'number',
      renderCell: (params) => !isNaN(params?.row?.rgl)
        ? params?.row?.rgl < 0
          ? `-$${formatCurrency(params?.row?.rgl)}`
          : `$${formatCurrency(params?.row?.rgl)}`
        : 'N/A',
      align: 'right'
    },
    {
      field: 'trdDate',
      headerName: 'Trade Date',
      type: 'date',
      valueGetter: (params) => {
        if (!params?.value) return params?.value
        const date = new Date(params?.value)
        return new Date(date?.getTime() + date?.getTimezoneOffset() * 1000 * 60)
      },
      renderCell: (params) => params?.row?.trdDate ? params?.row?.trdDate?.split('T')[0] : ''
    },
    {
      field: 'trdCode',
      headerName: 'Trade Code',
      renderCell: (params) => <span style={{ color: params.value === 'SELL' ? '#FF6161' : params.value === 'BUY' ? '#3BBFA3' : '#34475A' }}>{params.value}</span>
    },
    { field: 'tripNum', headerName: 'Trip Number', valueFormatter: (params) => (params?.value) || 'N/A' },
    {
      field: 'cashBalance',
      headerName: 'Cash Balance',
      type: 'number',
      align: 'right',
      renderCell: (params) => !isNaN(params?.row?.cashBalance) && params?.row?.cashBalance !== null
        ? params?.row?.cashBalance < 0
          ? `-$${formatCurrency(params?.row?.cashBalance)}`
          : `$${formatCurrency(params?.row?.cashBalance)}`
        : 'N/A'
    },
    { field: 'trdQty', headerName: 'Trade Quantity', type: 'number', valueFormatter: (params) => params?.value, align: 'right' },
    {
      field: 'fixComments',
      headerName: 'Fix Comments',
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <HtmlTooltip
            title={
              <>
                <Typography color='inherit' fontFamily='Open Sans' fontWeight={600}>Fix Comments</Typography>
                <Divider sx={{ backgroundColor: '#000000' }} />
                <Typography sx={{ fontSize: '14px' }} my={1}>{!params?.row?.fixComments ? '-' : params?.row?.fixComments}</Typography>
              </>
            }
          >
            <TryOutlined />
          </HtmlTooltip>
        )
      }
    }
  ]

  const getRowId = (row) => row.id
  const tableHeaderHTML = tableHeaders.map(({ headerName }, index) => <TableCell key={index}>{headerName}</TableCell>)
  const rowSkeleton = tableHeaders.map(({ field }, index) => (
    <TableCell key={index}>
      {
        field === 'actions'
          ? <Skeleton variant='circular' width={20} height={20} sx={{ ml: '10px' }} />
          : <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
      }
    </TableCell>
  ))

  const downloadCsv = () => {
    if (apiRef.current) {
      apiRef.current.exportDataAsCsv({
        fileName: 'trade_history_details',
        allColumns: true
      })
    }
  }

  return (
    <>
      {tradeLogsLoading ? <Loader /> : ''}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography style={{ textAlign: 'left', fontSize: '22px', fontWeight: 400, color: '#34475A' }}>Trade History Details</Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
          {!loading && optimPropertyDetails?.length > 0
            ? <Button
              variant='text'
              color='primary'
              onClick={downloadCsv}
              startIcon={<FileDownloadOutlined />}
            >
              Export as CSV
            </Button>
            : ''}
          <IconButton aria-label='close' style={{ marginRight: '-10px' }} onClick={() => setIsShown()}>
            <CloseOutlined />
          </IconButton>
        </Box>
      </Box>
      {loading
        ? (
          <Box className='table-responsive'>
            <Table>
              <TableHead>
                <TableRow>
                  {tableHeaderHTML}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>{rowSkeleton}</TableRow>
                <TableRow>{rowSkeleton}</TableRow>
              </TableBody>
            </Table>
          </Box>
        )
        : (
          <>
            {!optimPropertyDetails
              ? (
                <Typography
                  component='h6'
                  style={{
                    color: 'black',
                    marginTop: '10px',
                    textAlign: 'center'
                  }}
                >
                  No optimization properties data found
                </Typography>
              )
              : (
                <>
                  <DataGridPro
                    density='compact'
                    autoHeight
                    rows={optimPropertyDetails}
                    columns={tableHeaders}
                    loading={loading}
                    getRowId={getRowId}
                    apiRef={apiRef}
                    pagination
                    disableRowSelectionOnClick
                    pageSizeOptions={[15]}
                    initialState={{
                      ...optimPropertyDetails?.initialState,
                      pinnedColumns: {
                        left: ['actions', 'instrId']
                      },
                      pagination: {
                        paginationModel: { pageSize: 15 }
                      }
                    }}
                    sx={() => ({
                      [`.${gridClasses.main}`]: {
                        height: 'calc(100vh - 16px - 16px - 40px - 53px)'
                      },
                    })}
                  />
                </>
              )}
          </>
        )}
    </>
  )
}
export default ViewTradeApprovedModal
