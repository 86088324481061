import React, { useEffect, useState, useRef } from 'react'
import { Chart } from 'chart.js/auto'
import zoomPlugin from 'chartjs-plugin-zoom'
import { Box, Stack, Typography } from '@mui/material'
import { formatCurrencyWithSymbol } from '../../../utils/FormateCurrenyInMilion'

Chart.register(zoomPlugin)

const chartBackgroundColors = {
  balanced: '#3BBFA3',
  model: '#8BAEE1',
  mintax: '#3B79BF'
}

const TradeChart = (props) => {
  const { scenarioWiseData, yAxis, xAxis, onZoom, zoomedAccountIds, legends, onLegendClick, chartHeader, yAxisLabel, xAxisLabel, selectDefaultScenario, isDefaultChecked, setNoOfAccounts } = props
  const chartRef = useRef(null)
  const [chartData, setChartData] = useState({ datasets: [] })
  const [legendHidden, setLegendHidden] = useState(legends
    ? { ...legends }
    : {
        balanced: false,
        model: false,
        mintax: false
      })
  const mapSeriesData = (datasets) => {
    return datasets
      .filter((item) => item[xAxis] !== null && item[yAxis] !== null)
      .filter(item => zoomedAccountIds.length ? zoomedAccountIds.includes(item.accountId) : true)
      .map((item) => ({
        x: item[xAxis],
        y: item[yAxis],
        accountId: item.accountId,
        accountName: item.accountName
      }))
  }

  function getVisibleData ({ chart }, setDataFunction, zoomOtherChartFunction) {
    const xScale = chart.scales.x
    const yScale = chart.scales.y
    const xMin = xScale.getValueForPixel(xScale.left)
    const xMax = xScale.getValueForPixel(xScale.right)
    const yMin = yScale.getValueForPixel(yScale.bottom)
    const yMax = yScale.getValueForPixel(yScale.top)
    // Filter the original data based on the visible range
    const visibleData = chart.data.datasets.map((dataset) => {
      const filteredData = dataset.data.filter(
        (point) =>
          point.x >= xMin && point.x <= xMax && point.y >= yMin && point.y <= yMax
      )
      //   // Update the background color of each point in the filtered data
      //   const labelLowerCase = dataset.label?.toLowerCase().replace(/\s+/g, '')
      //   const originalColor = chartBackgroundColors[labelLowerCase]
      //   const updatedData = filteredData.map((point) => {
      //     if (originalColor) {
      //       point.backgroundColor = originalColor
      //     }
      //     return point
      //   })
      //   // Set backgroundColor of the dataset
      const updatedDataset = {
        ...dataset,
        //     borderColor: chartBackgroundColors[dataset.label?.toLowerCase()],
        //     backgroundColor: originalColor || 'transparent',
        data: filteredData
      }
      return updatedDataset
    })
    // // Set the chart data to the filtered and updated data
    // setDataFunction({
    //   datasets: visibleData
    // })
    // // Extract the visible data points for each dataset
    // const selectedLegend = Object.keys(legends).find(legend => !legends[legend])
    const visibleDataPoints = visibleData
      // .filter((dataset) => dataset.label.toLowerCase().replace(/\s+/g, '') === selectedLegend)
      .map((dataset) => dataset.data)
      .flat()
    // // Send visibleDataPoints to the onZoom function
    onZoom(visibleDataPoints, yAxisLabel)
    // // Zoom the other chart based on the visible range
    zoomOtherChartFunction(visibleData)
  }

  const zoomChart = (chartData, zoomedDataAccountIds, setChartDataFunction, legendHidden, chart) => {
    if (chartData?.datasets?.length > 0) {
      const matchingData = chartData.datasets.flatMap((dataset) => {
        // Check if the dataset is visible in the legend
        const datasetLabel = dataset.label?.toLowerCase().replace(/\s+/g, '')
        if (legendHidden[datasetLabel]) {
          return [] // Return an empty array if the dataset is hidden
        }
        // Filter data for the visible dataset
        return dataset.data.filter((point) => zoomedDataAccountIds.includes(point.accountId))
      })

      if (matchingData?.length > 0) {
        const xMin = Math.min(...matchingData.map((point) => point.x))
        const xMax = Math.max(...matchingData.map((point) => point.x))
        const yMin = Math.min(...matchingData.map((point) => point.y))
        const yMax = Math.max(...matchingData.map((point) => point.y))

        const updatedOptions = {
          scales: {
            x: { min: xMin - 10, max: xMax + 10 },
            y: { min: yMin - 5, max: yMax + 5 }
          }
        }
        setChartDataFunction((prevData) => {
          const updatedDatasets = prevData.datasets.map((dataset) => ({
            ...dataset,
            data: dataset.data.filter((point) =>
              zoomedDataAccountIds.includes(point.accountId)
            )
          }))
          return { ...prevData, datasets: updatedDatasets }
        })
        setLegendHidden(legendHidden)
      }
    }
  }

  useEffect(() => {
    if (chartRef.current && zoomedAccountIds?.length > 0) {
      const filteredDatasets = chartData.datasets.map((dataset) => {
        const filteredData = dataset.data.filter((point) => zoomedAccountIds.includes(point.accountId))
        return {
          ...dataset,
          data: filteredData
        }
      })

      setChartData({
        datasets: filteredDatasets
      })
    }
  }, [zoomedAccountIds])

  function zoomSecondChart (visibleData, chart) {
    if (chartData && chartData.datasets) {
      const allAccountIds = visibleData.flatMap((dataset) => {
        return dataset.data.map((point) => point.accountId)
      })
      zoomChart(chartData, allAccountIds, setChartData, legendHidden, chart)
    }
  }

  const handleLegendClick = (chart, legendItem, legendHidden, onLegendClick, zoomedAccountIds) => {
    const index = legendItem.datasetIndex
    // const meta = chart.getDatasetMeta(index)
    // Create an updatedLegends object based on the visibility of the clicked dataset
    const updatedLegends = {
      ...legendHidden,
      [legendItem.text.toLowerCase().replace(/\s+/g, '')]: !legendHidden[legendItem.text.toLowerCase().replace(/\s+/g, '')]
    }
    // Toggle visibility of the clicked dataset
    // meta.hidden = !legendHidden[legendItem.text.toLowerCase().replace(/\s+/g, '')]
    // chart.data.datasets[index].hidden = meta.hidden

    // Show the clicked legend and hide all others
    // chart.data.datasets.forEach((dataset, i) => {
    //   const otherMeta = chart.getDatasetMeta(i)
    //   otherMeta.hidden = i !== index // Hide other legends, show the clicked one
    //   dataset.hidden = i !== index
    // })

    // Filter data based on zoomedAccountIds
    const filteredData = chart.data.datasets[index].data.filter((point) =>
      zoomedAccountIds.includes(point.accountId)
    )

    // const findMinMax = (datasets, property) => {
    //   let minX = Infinity
    //   let maxX = -Infinity
    //   let minY = Infinity
    //   let maxY = -Infinity

    //   datasets.forEach((dataset) => {
    //     dataset.data.forEach((point) => {
    //       minX = Math.min(minX, point.x)
    //       maxX = Math.max(maxX, point.x)
    //       minY = Math.min(minY, point.y)
    //       maxY = Math.max(maxY, point.y)
    //     })
    //   })

    //   return { minX, maxX, minY, maxY }
    // }

    // const { minX, maxX, minY, maxY } = findMinMax([...chart.data.datasets, { data: filteredData }], 'y')
    // chart.options.scales.x.min = minX - 10
    // chart.options.scales.x.max = maxX + 10
    // chart.options.scales.y.min = minY - 5
    // chart.options.scales.y.max = maxY + 5

    // Update the dataset's data with the filtered data
    chart.data.datasets[index].data = filteredData
    onLegendClick(updatedLegends)
    chart.update()
  }

  const calculateLegendDisplay = () => {
    const hiddenValues = Object.values(legendHidden)
    return !hiddenValues.every((value) => value)
  }

  useEffect(() => {
    if (chartRef.current) {
      // const seriesData3 = mapSeriesData(balancedData)
      // const seriesData4 = mapSeriesData(modelData)
      // const seriesData5 = mapSeriesData(minTaxData)

      // setLegendHidden((prevLegendHidden) => ({
      //   ...prevLegendHidden,
      //   balanced: legends.balanced,
      //   model: legends.model,
      //   mintax: legends.mintax
      // }))
      const chartDataTemp = {
        datasets: Object.keys(scenarioWiseData).map(scenario => {
          return ({
            label: scenario,
            pointRadius: 5,
            data: mapSeriesData(scenarioWiseData[scenario]),
            backgroundColor: chartBackgroundColors[scenario.toLowerCase().replace(/\s+/g, '')] || '#1a1c8a',
            color: 'white',
            hidden: legends[scenario.toLowerCase().replace(/\s+/g, '')]
          })
        })
      }
      setChartData(
        chartDataTemp
      )
      // if (chartDataTemp.datasets.some(datasets => datasets.hidden)) {
      const accIds = [...chartDataTemp.datasets.flatMap(dataset => {
        if (dataset.hidden) {
          return []
        }
        return dataset.data.map((acc) => acc.accountId)
      })]
      setNoOfAccounts([...new Set(accIds)].length)
      // }
      // setChartData({
      //   datasets: [
      //     { label: 'Balanced', pointRadius: 5, data: seriesData3, backgroundColor: chartBackgroundColors.balanced, color: 'white', hidden: legends.balanced },
      //     { label: 'Model', pointRadius: 5, data: seriesData4, backgroundColor: chartBackgroundColors.model, color: 'white', hidden: legends.model },
      //     { label: 'Min Tax', pointRadius: 5, data: seriesData5, backgroundColor: chartBackgroundColors.mintax, color: 'white', hidden: legends.mintax }
      //   ]
    }
  }, [legends, scenarioWiseData])

  const findMinMax = (datasets, property) => {
    let minX = Infinity
    let maxX = -Infinity
    let minY = Infinity
    let maxY = -Infinity
    // calculate min and max value for the selected scenario only
    datasets?.forEach((dataset) => {
      if (!dataset.hidden) {
        dataset?.data?.forEach((point) => {
          if (minX === null || point.x < minX) {
            minX = point.x
          }
          if (maxX === null || point.x > maxX) {
            maxX = point.x
          }
          if (minY === null || point.y < minY) {
            minY = point.y
          }
          if (maxY === null || point.y > maxY) {
            maxY = point.y
          }
        })
      }
    })
    return { minX, maxX, minY, maxY }
  }

  const updateLegendItems = (chart, hiddenState) => {
    const datasets = chart.data.datasets
    datasets.forEach((dataset, index) => {
      const labelLowerCase = dataset.label?.toLowerCase().replace(/\s+/g, '')
      const hidden = hiddenState[labelLowerCase]
      // Set the visibility of the legend item
      chart.legend.legendItems[index].hidden = hidden
      // Set the visibility of the dataset in the chart
      dataset.hidden = hidden
    })
    chart.update()
  }

  useEffect(() => {
    if (chartRef.current) {
      const { minX: minX1, maxX: maxX1, minY: minY1, maxY: maxY1 } = findMinMax(chartData.datasets, 'y')
      let yOffset = 0.5
      let xOffset = 1
      if (minY1 !== maxY1) {
        const yRange = Math.abs(maxY1 - minY1)
        yOffset = yRange * 0.05
      }
      if (minX1 !== maxX1) {
        const xRange = Math.abs(maxX1 - minX1)
        xOffset = xRange * 0.5
      }

      const chartOptions = {
        devicePixelRatio: 3,
        scales: {
          x: {
            type: 'linear',
            position: 'bottom',
            title: {
              display: true,
              text: xAxisLabel
            },
            min: minX1 - xOffset,
            max: maxX1 + xOffset,
            ticks: {
              callback: function (value) {
                return formatCurrencyWithSymbol(value, 2, '')
              }
            }
          },
          y: {
            type: 'linear',
            title: {
              display: true,
              text: yAxisLabel
            },
            min: minY1 - yOffset,
            max: maxY1 + yOffset
          }
        },
        plugins: {
          legend: {
            display: calculateLegendDisplay(),
            labels: {
              boxWidth: 8,
              boxHeight: 8,
              usePointStyle: true,
              pointStyle: 'circle'
            },
            onClick: (e, legendItem) => handleLegendClick(chart, legendItem, legends, onLegendClick, zoomedAccountIds)
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                const data = context.dataset.data[context.dataIndex]
                const accountName = data.accountName
                const xValue = data.x
                const yValue = data.y
                const xMenuName = xAxisLabel
                const yMenuName = yAxisLabel
                return [
                  `Account Name: ${accountName}`,
                  `${yMenuName}: ${yValue.toFixed(2)}`,
                  `${xMenuName}: ${xValue.toFixed(2)}`
                ]
              }
            }
          },
          datalabels: {
            display: false,
            color: 'white',
            formatter: function (value, context) {
              const datasetIndex = context.datasetIndex
              const dataIndex = context.dataIndex
              const yValue = context.chart.data.datasets[datasetIndex].data[dataIndex].y
              return yValue !== undefined ? yValue.toString() : ''
            }
          },
          zoom: {
            zoom: {
              mode: 'xy',
              onZoomComplete: () => {
                getVisibleData({ chart }, setChartData, zoomSecondChart)
              },
              drag: {
                enabled: true,
                borderColor: 'rgb(54, 162, 235)',
                backgroundColor: 'rgba(54, 162, 235, 0.3)'
              }
            }
          }
        }
      }

      const ctx = chartRef.current.getContext('2d')

      const chart = new Chart(ctx, {
        type: 'scatter',
        data: chartData || [],
        options: chartOptions
      })

      updateLegendItems(chart, legends)

      return () => {
        chart.destroy()
      }
    }
  }, [chartData, legends])

  return (
    <Box width='100%'>
      <Stack direction='row' alignItems='center' justifyContent='space-between' width='600px'>
        <Typography sx={{ fontFamily: 'Open Sans', fontSize: '16px', fontWeight: 600, color: '#002A59', marginLeft: '8px' }} my={1}>{chartHeader}</Typography>
        <div><input type='checkbox' checked={isDefaultChecked || false} onChange={(e) => selectDefaultScenario(e.target.checked)} name='default scenario' />Default Scenario</div>
      </Stack>
      <canvas ref={chartRef} style={{ width: '630px', height: '350px', maxWidth: '600px', maxHeight: '350px' }} />
    </Box>
  )
}

export default TradeChart
