import { Fragment, useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import { API } from 'aws-amplify'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { Box, Grid, FormControl, RadioGroup, FormControlLabel, Radio, Button } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useAuth } from '../../contexts/AuthContext'
import { useSuccessToast } from '../../hooks/useSuccessToast'
import { useErrorToast } from '../../hooks/useErrorToast'
import { ACLLoadingTable } from './ACLLoadingTable'

const ManageUMAAccountsTable = (props) => {
  const {
    allUmaAggregateList,
    umaAggregateLoading,
    selectionModel,
    setAllAggregateAccountsFlag,
    setSelectionModel,
    defaultCheckBoxApiLoading,
    modelProps,
    allUmaTradingAccounts
  } = props

  const { user } = useAuth()
  const [currentPage, setCurrentPage] = useState(0)
  const [row, setRow] = useState([])
  const { showSuccess } = useSuccessToast()
  const { showError } = useErrorToast()
  const [isSortProcessLoading, setIsSortProcessLoading] = useState(true)
  const [currentTab, setCurrentTab] = useState('')
  const [idSaveLoading, setIdSaveLoading] = useState(false)
  const [allAggregates, setAllAggregates] = useState([])
  const [isAggregatesSorting, setIsAggregatesSorting] = useState(true)
  const [sortAggregatesFlag, setSortAggregatesFlag] = useState(true)

  const columns = [
    {
      field: 'aggName',
      headerName: 'Aggregate Name',
      flex: 2
    },
    {
      field: 'aggType',
      headerName: 'Aggregate Type',
      flex: 2
    },
    {
      field: 'aggCd',
      headerName: 'Aggregate Code',
      flex: 2
    },
    {
      field: 'aggId',
      headerName: 'Aggregate Id',
      flex: 2,
      hidden: true
    }
  ]

  useEffect(() => {
    if (!umaAggregateLoading && !defaultCheckBoxApiLoading) {
      setCurrentTab('Select Manually')
    }
  }, [umaAggregateLoading, defaultCheckBoxApiLoading])

  useEffect(() => {
    if (selectionModel?.length > 0) {
      if (allUmaTradingAccounts && modelProps?.resourceId === 'group-onboarding') {
        if (sortAggregatesFlag && selectionModel?.length > 0) {
          const reorderedRows = [...allUmaTradingAccounts].sort((a, b) => {
            const isSelectedA = sortAggregatesFlag && selectionModel?.includes(a?.aggId)
            const isSelectedB = sortAggregatesFlag && selectionModel?.includes(b?.aggId)
            return isSelectedB - isSelectedA
          })
          if (reorderedRows && reorderedRows.length > 0 && selectionModel?.length > 0) {
            setIsAggregatesSorting(false)
            setRow(reorderedRows)
          }
          setIsSortProcessLoading(false)
        }
      } else {
        if (sortAggregatesFlag && selectionModel?.length > 0) {
          const reorderedRows = [...allUmaAggregateList].sort((a, b) => {
            const isSelectedA = sortAggregatesFlag && selectionModel?.includes(a?.aggId)
            const isSelectedB = sortAggregatesFlag && selectionModel?.includes(b?.aggId)
            return isSelectedB - isSelectedA
          })
          if (reorderedRows && reorderedRows.length > 0 && selectionModel?.length > 0) {
            setIsAggregatesSorting(false)
            setRow(reorderedRows)
          }
          setIsSortProcessLoading(false)
        }
      }
    } else {
      if (allUmaTradingAccounts && modelProps?.resourceId === 'group-onboarding') {
        setRow(allUmaTradingAccounts)
        setIsAggregatesSorting(false)
        setIsSortProcessLoading(false)
      } else {
        setRow(allUmaAggregateList)
        setIsAggregatesSorting(false)
        setIsSortProcessLoading(false)
      }
    }
  }, [allUmaAggregateList, modelProps, sortAggregatesFlag, selectionModel])

  const handleAccountTypeChange = (e) => {
    if (e.target.value === 'Select Manually') {
      setCurrentTab(e.target.value)
      setAllAggregateAccountsFlag(false)
    } else {
      setCurrentTab(e.target.value)
      const selectedAccounts = (modelProps?.resourceId === 'group-onboarding' ? allUmaTradingAccounts : allUmaAggregateList)?.map((data) => data.aggId)
      setAllAggregates(selectedAccounts)
      setAllAggregateAccountsFlag(true)
    }
  }

  const handleAccountIdsSave = () => {
    setIdSaveLoading(true)
    API.patch('baseAclURL', `user-access-control/v1/${user?.userGroup}/user/aggregate/${modelProps?.userId}`, {
      queryStringParameters: {
        'event-type': 'UMA'
      },
      body: [{
        serviceId: modelProps?.serviceId,
        resourceId: modelProps?.resourceId,
        allAggregates: false,
        aggIds: currentTab === 'Select Manually' ? selectionModel : allAggregates
      }]
    }).then((res) => {
      if (res && res?.success) {
        showSuccess(res?.message)
        setIdSaveLoading(false)
      }
    }).catch((error) => {
      setIdSaveLoading(false)
      showError(error?.response?.data?.errorInfo?.userMessage || error.message)
      Sentry.captureException(error?.response?.data?.errorInfo?.userMessage || error)
    }).finally(() => {
      setIdSaveLoading(false)
      // closeModal()
    })
  }

  return (
    <Box sx={{ width: 'auto', height: '500px', minHeight: '500px', maxHeight: '500px' }}>
      <Grid container xs={12} justifyContent='space-between' alignItems='center' mb={1} mt={1}>
        <FormControl style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '5px 0 0 16px' }}>
          <RadioGroup
            name='radio-buttons-group'
            style={{ display: 'flex', flexDirection: 'row', marginLeft: '0px' }}
          >
            <FormControlLabel
              checked={currentTab === 'Select Manually' && !isAggregatesSorting && !isSortProcessLoading && !umaAggregateLoading && !defaultCheckBoxApiLoading} value='Select Manually' onChange={handleAccountTypeChange} control={<Radio />} label='Select Manually'
              disabled={defaultCheckBoxApiLoading || umaAggregateLoading || isAggregatesSorting || isSortProcessLoading}
            />
          </RadioGroup>
        </FormControl>
        {idSaveLoading
          ? (
            <LoadingButton
              loading
              loadingPosition='start'
              variant='outlined'
              sx={{ float: 'right', width: '120px' }}
            >
              Apply
            </LoadingButton>
            )
          : (
            <Button
              disabled={defaultCheckBoxApiLoading || umaAggregateLoading || row?.length < 1 || isAggregatesSorting}
              variant='contained' color='primary' sx={{ float: 'right', width: '100px' }} onClick={handleAccountIdsSave}
            >Apply
            </Button>
            )}
      </Grid>
      <Grid container xs={12} justifyContent='space-between' alignItems='center' mb={2}>
        {(isSortProcessLoading || defaultCheckBoxApiLoading || umaAggregateLoading || isAggregatesSorting || !currentTab)
          ? (
            <>
              <ACLLoadingTable headers={columns?.filter(col => !col.hidden)} />
            </>
            )
          : (
            <DataGridPro
              autoHeight
              rows={row}
              columns={columns}
              rowSelectionModel={currentTab === 'Select Manually' ? selectionModel : allAggregates}
              onRowSelectionModelChange={(e) => {
                setSortAggregatesFlag(false)
                setSelectionModel(e)
              }}
              getRowId={(row) => row?.aggId}
              checkboxSelection
              density='compact'
              pageSizeOptions={[10]}
              onPaginationModelChange={(event) => setCurrentPage(event.page)}
              pagination
              paginationModel={{ pageSize: 10, page: currentPage }}
              initialState={{
                ...row?.initialState,
                columns: {
                  columnVisibilityModel: {
                    aggId: false
                  }
                }
              }}
            />
            )}
      </Grid>
    </Box>
  )
}

export default ManageUMAAccountsTable
