import React, { useContext, useEffect, useState } from 'react'
import { Box, Card, CardContent, Grid, Typography } from '@mui/material'
import { scenarioSensitivity } from '../../../../contstants/constants'
import { accountDataContext, summaryDataContext } from '../../Index'
import { decTwoPercentage, formatCurrencyWithSymbol } from '../dataProcess/DataProcess'

export const ChartDetails = (props) => {
  const summaryData = useContext(summaryDataContext)
  const accountData = useContext(accountDataContext)
  const { selectedScenario } = props
  // selects first scenario from API response and highlights it or selects based on tax sensitivity
  const scenarioDesc = (summaryData?.data?.optimizationData || [])?.map((item) => item.scenarioDesc)
  const highlightInitialScenario = scenarioDesc && scenarioDesc.includes(scenarioSensitivity[accountData?.taxSensitivity]) ? scenarioSensitivity[accountData?.taxSensitivity] : (summaryData?.data?.optimizationData || [])?.filter((item) => item.scenarioDesc !== 'No Trade' && item.scenarioDesc !== 'Liquidation')[0]?.scenarioDesc

  let scenarioSelData = (summaryData?.data?.optimizationData || [])?.find(data => (data.scenarioDesc === selectedScenario))

  if (!scenarioSelData) {
    scenarioSelData = (summaryData?.data?.optimizationData || [])?.find(data => (data.scenarioDesc === highlightInitialScenario)) ||
      (summaryData?.data?.optimizationData || [])?.filter((item) => item.scenarioDesc !== 'No Trade' && item.scenarioDesc !== 'Liquidation')[0]
  }

  const defaultScenario = (summaryData?.data?.optimizationData || [])?.filter((item) => item.scenarioDesc !== 'No Trade' && item.scenarioDesc !== 'Liquidation')[0]?.scenarioDesc
  const noTradeData = (summaryData?.data?.optimizationData || [])?.find(data => data.scenarioDesc === 'No Trade')
  const liquidationData = (summaryData?.data?.optimizationData || [])?.find(data => data.scenarioDesc === 'Liquidation')

  const [scenarioContent, setScenarioContent] = useState(null)

  const scenarioHeader = selectedScenario || highlightInitialScenario || defaultScenario

  useEffect(() => {
    const content = (
      <Box sx={{ minWidth: 275 }} p={2} mt={3}>
        <Card variant='outlined'>
          <CardContent>
            <Grid xs={12} p={1}>
              <Grid xs={12} sx={{ textAlign: 'center' }}>
                <Typography variant='body2' gutterBottom sx={{ fontSize: '16px', fontFamily: 'Open Sans' }}>
                  {scenarioHeader} Scenario
                </Typography>
              </Grid>
              <Grid xs={12}>
                <Typography variant='body2' sx={{ fontSize: '14px', textAlign: 'justify', fontFamily: 'Open Sans' }}>
                  If you choose to align your portfolio with a {scenarioHeader} scenario, the tracking error is likely to decrease from {decTwoPercentage(noTradeData?.te, 2)} to {decTwoPercentage(scenarioSelData?.te, 2)}. In order to achieve this, you will need to sell {scenarioSelData?.numSell} stocks from your current portfolio.
                  {((scenarioSelData?.taxCost < liquidationData?.taxCost) && ((liquidationData?.taxCost > 0) || (scenarioSelData.taxCost > 0)) && scenarioSelData.scenarioDesc !== 'Liquidation') ? ` By doing this, you can both prevent liquidation and save ${formatCurrencyWithSymbol(Math.abs(liquidationData?.taxCost - scenarioSelData?.taxCost), 2, '$')} amount of taxes.` : ''}

                  {((scenarioSelData?.taxCost < 0) && (liquidationData?.taxCost < 0) && (scenarioSelData.taxCost < 0) && scenarioSelData.scenarioDesc !== 'Liquidation') ? ` By doing this, you can save ${formatCurrencyWithSymbol(Math.abs(scenarioSelData?.taxCost), 2, '$')} amount of taxes.` : ''}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    )
    setScenarioContent(content)
  }, [highlightInitialScenario, selectedScenario, scenarioSelData])
  return scenarioContent
}
