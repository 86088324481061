import { useAuth } from '../contexts/AuthContext'
import { moduleConfig } from '../contexts/data'
import { ACCESS_LEVEL } from '../contstants/constants'

export const useIpsAccess = (module) => {
  const { user, checkAccess } = useAuth()
  switch (module) {
    case 'Policy' : {
      return user?.userGroup === 'adv-classic'
        ? checkAccess(moduleConfig.IPS, ACCESS_LEVEL.COMPONENT_ACCESS,
          { subModuleName: moduleConfig.POLICY, component_name: moduleConfig.POLICY_EDIT })
        : true
    }
    case 'Statement' : {
      return user?.userGroup === 'adv-classic'
        ? checkAccess(moduleConfig.IPS, ACCESS_LEVEL.COMPONENT_ACCESS,
          { subModuleName: moduleConfig.STATEMENT, component_name: moduleConfig.STATEMENT_EDIT })
        : true
    }
    case 'Monitoring' : {
      return user?.userGroup === 'adv-classic'
        ? checkAccess(moduleConfig.IPS, ACCESS_LEVEL.COMPONENT_ACCESS,
          { subModuleName: moduleConfig.MONITORING, component_name: moduleConfig.MONITORING_EDIT })
        : true
    }
    case 'Evidence' : {
      return user?.userGroup === 'adv-classic'
        ? checkAccess(moduleConfig.IPS, ACCESS_LEVEL.COMPONENT_ACCESS,
          { subModuleName: moduleConfig.EVIDENCE, component_name: moduleConfig.EVIDENCE_EDIT })
        : true
    }
    default:
      return true
  }
}
