import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import * as Sentry from '@sentry/react'
import { API } from 'aws-amplify'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Badge, Box, Button, Divider, Grid, Modal, Tab } from '@mui/material'
import { randomId } from '@mui/x-data-grid-generator'
import { useGridApiRef } from '@mui/x-data-grid-pro'
import { DateRangePicker } from '@mui/x-date-pickers-pro'
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { useAuth } from '../../../contexts/AuthContext'
import { useErrorToast } from '../../../hooks/useErrorToast'
import TotalMarketValueIcon from '../../../assets/images/icons/value-of-portfolio.svg'
import AggregateTitle from '../components/AggregateTitle'
import AccountMarketValueModal from './AccountMarketValueModal'
import TaxlotHistory from './TaxlotHistory'
import TaxlotSwappingTab from './TaxlotSwappingTab'
import './taxlot-swapping.css'

dayjs.extend(utc)

export default function TaxlotSwap () {
  const { user } = useAuth()
  const params = useParams()
  const apiRef = useGridApiRef()
  const [aggregateDataLoading, setAggregateDataLoading] = useState(true)
  const { showError } = useErrorToast()
  const [taxlotSwappingTabValue, setTaxlotSwappingTabValue] = useState('taxlot-swap')
  const [aggregateAccountData, setAggregateAccountData] = useState([])
  const [taxlotHistoryLoading, setTaxlotHistoryLoading] = useState(true)
  const [taxlotHistoryData, setTaxlotHistoryData] = useState([])
  const [disableSubmit, setDisableSubmit] = useState(false)
  const [date, setDate] = useState([dayjs.utc().subtract(1, 'month'), dayjs.utc()])
  const [selectedAggregate, setSelectedAggregate] = useState({ aggName: '', aggCode: '' })
  const [accountsLoading, setAccountsLoading] = useState(false)
  const [accountsList, setAccountsList] = useState([])
  const [isMarketValueChange, setIsMarketValueChange] = useState([])
  const [taxlotSwapGridState, setTaxlotSwapGridState] = useState(null)
  const [taxlotHistoryGridState, setTaxlotHistoryGridState] = useState(null)
  const [openMarketValueChangeModal, setOpenMarketValueChangeModal] = useState(false)
  const [badgeDisplay, setBadgeDisplay] = useState('none')

  const restoreDatagridState = () => {
    if (apiRef?.current?.restoreState && taxlotSwapGridState) {
      apiRef?.current?.restoreState({
        pinnedColumns: taxlotSwapGridState.pinnedColumns,
        columns: taxlotSwapGridState.columns
      })
    }
  }

  // restore the datagrid state whenever the state variables updates
  // add new state variables in the dependency array to prevent columns from getting resize and reorder
  useEffect(() => {
    restoreDatagridState()
  }, [isMarketValueChange, badgeDisplay, openMarketValueChangeModal])

  const handleOpenMarketValueChangeModal = () => {
    setOpenMarketValueChangeModal(true)
  }

  const handleCloseMarketValueChangeModal = () => {
    setOpenMarketValueChangeModal(false)
  }

  const handleTabChange = (event, newValue) => {
    setTaxlotSwappingTabValue(newValue)
  }

  // function to show data for the page title
  const formatAggregateData = (aggData) => {
    setSelectedAggregate({ aggName: aggData?.aggName ? aggData?.aggName : '', aggCode: aggData?.aggCode ? aggData?.aggCode : '' })
  }

  // redirect to history swapping tab
  const handleTaxLotHistoryChange = () => {
    dateFilterHandler()
    setTaxlotSwappingTabValue('history-swapping')
  }

  // GET API for taxlot aggregate data
  const getPortfolioAccounts = async () => {
    const aggId = params?.familyId
    setAggregateDataLoading(true)
    API.get(
      'baseUriAggregateMaster',
      `aggregate-master/v1/${user?.userGroup}/aggregate-portfolio/${aggId}?viewUntagged=true`
    )
      .then((response) => {
        if (response?.data) {
          const newData = response.data?.accountData?.map((account) => ({ ...account, id: randomId() }))
          setAggregateAccountData(newData)
          setAggregateDataLoading(false)
          formatAggregateData(response?.data?.agg)
        } else {
          setAggregateAccountData([])
          setAggregateDataLoading(false)
        }
      })
      .catch((error) => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
        setAggregateDataLoading(false)
      })
  }

  // GET API for the list of accounts to be shown in the account name dropdown
  const getAggregateAccounts = async () => {
    const aggId = params?.familyId
    setAccountsLoading(true)
    API.get(
      'baseUriAggregateMaster',
      `aggregate-master/v1/${user?.userGroup}/aggregate-account/${aggId}`
    )
      .then((response) => {
        if (response?.data) {
          setAccountsList(response?.data)
          setAccountsLoading(false)
        } else {
          setAccountsList([])
          setAccountsLoading(false)
        }
      })
      .catch((error) => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
        setAccountsLoading(false)
      })
  }

  // GET API for the data to be shown in the taxlot history table
  const getTaxlotSwappingData = (fromDate, toDate) => {
    const currentDataDate = new Date().toISOString().slice(0, 10)
    fromDate = fromDate || currentDataDate
    toDate = toDate || currentDataDate
    API.get(
      'baseUriAggregateMaster',
      `aggregate-master/v1/${user?.userGroup}/taxlot/swapping/${params?.familyId}`, { queryStringParameters: { startDate: fromDate, endDate: toDate } }
    )
      .then((response) => {
        if (response?.data) {
          setTaxlotHistoryLoading(false)
          const taxlotHistory = response?.data?.map((taxlotHistory) => ({ ...taxlotHistory, id: randomId() }))
          setTaxlotHistoryData(taxlotHistory)
        }
        setTaxlotHistoryLoading(false)
      })
      .catch((error) => {
        showError(error?.response?.data?.errorInfo?.userMessage || error.message)
        setTaxlotHistoryLoading(false)
        Sentry.captureException(error?.response?.data?.errorInfo?.userMessage || error?.message)
      })
  }

  // Function to filter the date change in taxlot history
  const dateFilterHandler = () => {
    setTaxlotHistoryLoading(true)
    getTaxlotSwappingData(date[0].format('YYYY-MM-DD'), date[1].format('YYYY-MM-DD'))
  }

  // Function to handle the date change in taxlot history
  const handleDateChange = (date) => {
    if (!date[0] || !date[1]) {
      setDisableSubmit(true)
      return
    }
    setDate(date)
    setDisableSubmit(false)
  }

  const handleMarketValueChange = (data) => {
    setIsMarketValueChange(data)
  }

  useEffect(() => {
    if (user) {
      getPortfolioAccounts()
      dateFilterHandler()
      getAggregateAccounts()
    }
  }, [user, params?.familyId])

  useEffect(() => {
    if (isMarketValueChange?.length > 0) {
      // badge should be shown only when the market value changes
      const shouldDisplayBadge = isMarketValueChange.some(row =>
        row?.showSwapOptions && (
          (row?.quantity && row?.targetAccount) ||
          row?.children?.some(child => child?.showSwapOptions && child?.quantity && child?.targetAccount?.accountId)
        )
      )
      setBadgeDisplay(shouldDisplayBadge ? 'block' : 'none')
    }
  }, [isMarketValueChange])

  return (
    <>
      <Box className='taxlot-swapping'>
        <Box
          sx={{
            display: 'flex',
            alignItems: { xs: 'start', md: 'center' },
            justifyContent: 'space-between'
          }}
          className='tradeMainhead'
        >
          <Grid xs={12} width='100%' item sx={{ display: 'flex', justifyContent: 'center', alignItems: 'baseline' }}>
            <AggregateTitle title='IPS' chipText='IPS' slug={params?.familyId} name={selectedAggregate?.aggName} number={selectedAggregate?.aggCode} accountCodeShow loading={aggregateDataLoading} />
            {taxlotSwappingTabValue === 'taxlot-swap'
              ? <Badge
                  badgeContent=''
                  sx={{
                    '.MuiBadge-badge': {
                      display: badgeDisplay,
                      height: '8px',
                      minWidth: '8px',
                      padding: '0',
                      backgroundColor: '#FF5D5D',
                      top: '52px',
                      right: '98px',
                      position: 'absolute'
                    }
                  }}
                >
                <Button
                  variant='outlined'
                  onClick={handleOpenMarketValueChangeModal}
                  sx={{
                    border: '2px solid #dee2e6',
                    position: 'absolute',
                    top: '50px',
                    right: '94px',
                    padding: '4px',
                    minWidth: 'auto',
                    ':hover': {
                      background: 'transparent',
                      border: '2px solid #dee2e6'
                    },
                    marginBottom: '16px',
                    marginRight: '0px'
                  }}
                  className='tooltip-trade'
                >
                  <img src={TotalMarketValueIcon} alt='filter' height={24} width={24} />
                  <span
                    className='tooltiptext'
                    style={{ marginLeft: '-15px' }}
                  >
                    Market Value Change
                  </span>
                </Button>
              </Badge>
              : ''}
            <Modal open={openMarketValueChangeModal} onClose={handleCloseMarketValueChangeModal} maxWidth='md' fullWidth>
              <AccountMarketValueModal
                accountsList={accountsList}
                isMarketValueChange={isMarketValueChange}
                handleClose={handleCloseMarketValueChangeModal}
              />
            </Modal>
          </Grid>
          {taxlotSwappingTabValue === 'history-swapping'
            ? <Box
                sx={{ display: 'inline-flex', justifyContent: 'flex-end', alignItems: 'end', gap: 2, position: 'absolute', top: '84px', right: '0' }}
              >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateRangePicker
                  value={date}
                  onChange={handleDateChange}
                  disableFuture
                  format='YYYY-MM-DD'
                  slotProps={{ textField: { size: 'small' } }}
                  sx={{
                    '.MuiMultiInputDateRangeField-separator': {
                      marginLeft: '8px'
                    },
                    '.MuiTextField-root:last-child': {
                      marginLeft: '8px'
                    },
                    '.MuiInputBase-input': {
                      padding: '6px 14px'
                    },
                    width: '17em'
                  }}
                  localeText={{ start: 'From Date', end: 'To Date' }}
                />
              </LocalizationProvider>
              <Grid item>
                <Button
                  variant='contained'
                  disabled={disableSubmit}
                  onClick={() => dateFilterHandler()}
                >
                  Submit
                </Button>
              </Grid>
              </Box>
            : ''}
        </Box>
        <TabContext value={taxlotSwappingTabValue}>
          <TabList
            onChange={handleTabChange}
            variant='scrollable'
            scrollButtons={false}
            id='taxlot-swapping-tabs'
            TabIndicatorProps={{
              style: {
                backgroundColor: '#34475A'
              }
            }}
            indicatorColor='#34475A'
          >
            <Tab
              className='tab-header' value='taxlot-swap' label='Taxlot Swap'
            />
            <Tab
              className='tab-header' value='history-swapping' label='History'
            />
          </TabList>
          <Divider sx={{
            bgcolor: 'rgba(116, 120, 141, 0.15)'
          }}
          />
          <TabPanel
            sx={{
              px: 0
            }}
            value='taxlot-swap'
          >
            <TaxlotSwappingTab
              aggregateAccountData={aggregateAccountData}
              aggregateDataLoading={aggregateDataLoading}
              onConfirm={() => handleTaxLotHistoryChange()}
              accountsList={accountsList}
              accountsLoading={accountsLoading}
              taxlotSwapGridState={taxlotSwapGridState}
              setTaxlotSwapGridState={setTaxlotSwapGridState}
              onMarketValueChange={handleMarketValueChange}
              apiRef={apiRef}
            />
          </TabPanel>
          <TabPanel
            sx={{
              px: 0
            }}
            value='history-swapping'
          >
            <TaxlotHistory
              taxlotHistoryData={taxlotHistoryData}
              taxlotHistoryLoading={taxlotHistoryLoading}
              taxlotHistoryGridState={taxlotHistoryGridState}
              setTaxlotHistoryGridState={setTaxlotHistoryGridState}
            />
          </TabPanel>
        </TabContext>
      </Box>
    </>
  )
}
