import { HANDLE_SIDEBAR } from '../Action/uiAction.types';

const initialState = {
  showSidebar: false,
};

const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case HANDLE_SIDEBAR:
      return {
        ...state,
        showSidebar: action.payload,
      };
    default:
      return state;
  }
};

export default uiReducer;
