import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { MdExpandLess, MdExpandMore } from 'react-icons/md'
import Tooltip from '@mui/material/Tooltip'
import Zoom from '@mui/material/Zoom'
import { changeDraggableElement } from '../../../../store/pdf-customizer-reducer/Action/allPageAction'
import { MAX_GRID_COUNT } from '../../PDFLayout'
import { arrowHorizontal, arrowVertical } from '../svgFile'
import './dropdownStyles.css'

const ElementGroupDropDown = ({
  item,
  handleDragStart,
  handleDragEnd,
  handleTouchStart,
  isDragging
}) => (
  <div
    style={{ cursor: 'grab', opacity: isDragging ? 0.1 : 1 }}
    draggable
    onDragStart={(e) => handleDragStart(e, item)}
    onDragEnd={handleDragEnd}
    // onTouchStart={(e) => handleTouchStart(e, item)}
    // onTouchEnd={handleDragEnd}
    className='drag-group'
  >
    {item?.width
      ? (
        <div className='horizontal-vertical-box'>
          {/* {!item?.text && ( */}
          <div className='vertical'>
            <div className='text-set-mid'>
              {arrowHorizontal}

              <p className='draggable-item'> {item?.text ? 'T' : item.height}</p>
            </div>
          </div>
          {/* )} */}
          <div className='box' />
          {/* <div className='box'>{item?.text && 'T'}</div> */}
          {/* {!item?.text && ( */}
          <div className='horizontal'>
            <div className='text-set-mid'>
              {arrowVertical}

              <p className='draggable-item'>
                {item.width}
              </p>
            </div>
          </div>
          {/* )} */}
        </div>
      )
      : (
        <p className='faq-accordion-sub-que draggable-item'>{item.height}</p>
      )}
    <Tooltip
      title={item.elements}
      arrow
      TransitionComponent={Zoom}
      enterDelay={800}
      component='span'
    >
      <p className='faq-accordion-sub-ans'>{item.elements}</p>
    </Tooltip>
  </div>
)

ElementGroupDropDown.propTypes = {
  item: PropTypes.object.isRequired,
  handleDragStart: PropTypes.func.isRequired,
  handleDragEnd: PropTypes.func.isRequired,
  handleTouchStart: PropTypes.func.isRequired,
  isDragging: PropTypes.bool.isRequired
}

const ElementList = ({ dropItem, open }) => {
  const [activeIndex, setActiveIndex] = useState(open ? 0 : null)
  const [isDragging, setIsDragging] = useState(false)
  const dispatch = useDispatch()

  const toggleAccordion = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index))
  }

  /**
   * @param {*} size component's height or width
   * @returns number of cell required based on size
   */
  const calculateCellCount = (size) => {
    if (size && size !== 'T') {
      switch (size) {
        case '1/2':
          return Math.floor((1 / 2) * MAX_GRID_COUNT)
        case '1/3':
          return Math.floor((1 / 3) * MAX_GRID_COUNT)
        case '1/4':
          return Math.floor((1 / 4) * MAX_GRID_COUNT)
        case '1/8':
          return Math.floor((1 / 8) * MAX_GRID_COUNT)
        case '2/3':
          return Math.floor((2 / 3) * MAX_GRID_COUNT)
        case '1':
          return MAX_GRID_COUNT
        default:
          return 1
      }
    }
    return 1
  }

  const handleDataSetting = (e, sideData) => {
    const data = {
      name: sideData?.elements,
      size: sideData?.height,
      coverPage: sideData?.coverPage,
      width: calculateCellCount(sideData?.width),
      height: calculateCellCount(sideData?.text ? 'T' : sideData?.height),
      isEditTitle: !!sideData?.isEdit,
      headingText: sideData?.headingText || ''
    }
    if (sideData?.canOverflow) {
      data.canOverflow = sideData?.canOverflow
    }

    if (e.type === 'dragstart' && e.dataTransfer) {
      e.dataTransfer.setData('text/plain', JSON.stringify(data))
      // stores current dragged element in redux
      dispatch(changeDraggableElement(data))
    } else {
      if (e.touches && e.touches[0].identifier !== undefined) {
        const dataTransfer = new DataTransfer()
        dataTransfer.setData('text/plain', JSON.stringify(data))
        e.dataTransfer = dataTransfer
      }
    }
  }

  const handleDragStart = (e, sideData) => {
    handleDataSetting(e, sideData)
    setIsDragging(true)
  }

  const handleTouchStart = (e, sideData) => {
    handleDataSetting(e, sideData)
    setIsDragging(true)
  }

  const handleDragEnd = () => {
    setIsDragging(false)
  }

  return (
    <div className='faq-accordion'>
      {Array.isArray(dropItem) &&
        dropItem.map((data, index) => (
          <div
            key={index}
            className={`faq-accordion-item ${activeIndex === index ? 'active' : ''}`}
          >
            <div
              onClick={() => toggleAccordion(index)}
              className='faq-accordion-title'
            >
              <span style={{ userSelect: 'none' }}>{data.height}</span>
              {activeIndex === index
                ? (
                  <MdExpandLess color='#000000' size={30} />
                )
                : (
                  <MdExpandMore color='#000000' size={30} />
                )}
            </div>
            <div
              className={`faq-accordion-content ${activeIndex === index ? 'open' : ''}`}
            >
              {Array.isArray(data.elements)
                ? (
                  data.elements.map((item, itemIndex) => (
                    <ElementGroupDropDown
                      key={itemIndex}
                      item={item}
                      handleDragStart={handleDragStart}
                      handleDragEnd={handleDragEnd}
                      handleTouchStart={handleTouchStart}
                      isDragging={isDragging}
                    />
                  ))
                )
                : (
                  <ElementGroupDropDown
                    item={data}
                    handleDragStart={handleDragStart}
                    handleDragEnd={handleDragEnd}
                    handleTouchStart={handleTouchStart}
                    isDragging={isDragging}
                  />
                )}
            </div>
          </div>
        ))}
    </div>
  )
}

ElementList.propTypes = {
  dropItem: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired
}

export default ElementList
