import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addChartValues } from '../../../../store/pdf-customizer-reducer/Action/chartDropAction'
import { factorTilts, industry, scenarioDataApi, sector, security, substitution, taxSavingsData } from '../../../../utils/pdf-customizer/_data'
import { useAuth } from '../../../../contexts/AuthContext'
import {
  dataTableData,
  decTwoPercentage,
  fetchDataAndDispatch,
  isNumeric
} from '../APIResponseConverts/APIResponseConverts'
import {
  industryTable,
  sectorTable,
  taSavingsData,
  substitutionTable,
  securityTable,
  factorTiltsTable,
  scenarioTable
} from '../APIResponseConverts/chartDefaultsData'
import './dataTable.css'

const DataTable = ({ data }) => {
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const { user } = useAuth()

  const {
    sectorConstraintsResponse,
    isResponseElements,
    taxSavingDataResponse,
    industryConstraintsResponse,
    securityConstraintsResponse,
    substitutionConstraintsResponse,
    factorTiltsConstraintsResponse,
    scenarioConstraintsResponse
  } = useSelector((state) => state.chartState)

  const templateData = useSelector(state => state.templateData)

  useEffect(() => {
    const fetchData = (api, type, responseData) => {
      if (isResponseElements && !responseData && !isLoading) {
        setIsLoading(true)
        fetchDataAndDispatch(api, type, dispatch)
      }
      if (isResponseElements && responseData) {
        setIsLoading(false)
      }
    }
    // if (isResponseElements && !textSavingDataResponse) {
    //   setIsLoading(true)
    //   fetchDataAndDispatch(() => taxSavingsData(templateData), 'TAX_SAVING_DATA', dispatch)
    // }

    // if(isResponseElements && textSavingDataResponse) {
    //   setIsLoading(false)
    // }

    switch (data.name) {
      case 'Sector constraints':
        return fetchData(
          () => sector(templateData, user?.userGroup),
          'SECTOR_CONSTRAINTS',
          sectorConstraintsResponse
        )
      case 'Industry constraints':
        return fetchData(
          () => industry(templateData, user?.userGroup),
          'INDUSTRY_CONSTRAINTS',
          industryConstraintsResponse
        )

      case 'Security constraints':
        return fetchData(
          () => security(templateData, user?.userGroup),
          'SECURITY_CONSTRAINTS',
          securityConstraintsResponse
        )

      case 'Substitution constraints':
        return fetchData(
          () => substitution(templateData, user?.userGroup),
          'SUBSTITUTION_CONSTRAINTS',
          substitutionConstraintsResponse
        )

      case 'Factor Tilts constraints':
        return fetchData(
          () => factorTilts(templateData, user?.userGroup),
          'FACTOR_TILTS_CONSTRAINSTS',
          factorTiltsConstraintsResponse
        )

      case 'Scenario':
        return fetchData(
          () => scenarioDataApi(templateData, user?.userGroup),
          'SCENARIO_CONSTRAINTS',
          scenarioConstraintsResponse
        )

      case 'YTD tax details':
        return fetchData(
          () => taxSavingsData(templateData, user?.userGroup),
          'TAX_SAVING_DATA',
          taxSavingDataResponse
        )

      default:
        return null
    }
  }, [
    taxSavingDataResponse,
    isResponseElements,
    sectorConstraintsResponse,
    industryConstraintsResponse,
    substitutionConstraintsResponse,
    factorTiltsConstraintsResponse,
    securityConstraintsResponse,
    scenarioConstraintsResponse,
    dispatch,
    data
  ])
  const getDataTable = (name) => {
    switch (name) {
      case 'Sector constraints':
        return sectorConstraintsResponse || sectorTable.data
      case 'Industry constraints':
        return industryConstraintsResponse || industryTable.data
      case 'Security constraints':
        return securityConstraintsResponse || securityTable.data
      case 'Substitution constraints':
        return substitutionConstraintsResponse || substitutionTable.data
      case 'Factor Tilts constraints':
        return factorTiltsConstraintsResponse || factorTiltsTable.data
      case 'Scenario':
        return scenarioConstraintsResponse || scenarioTable
      case 'YTD tax details':
        return taxSavingDataResponse || taSavingsData
      default:
        return null
    }
  }
  const result = getDataTable(data.name) || []
  const { datasets = [] } = data?.value || {}
  const { headingColumns, tableRows, tableWidth, tableTextAlign } = dataTableData(
    result,
    data.name
  )

  for (let i = 0; i < tableRows.length; i++) {
    tableRows[i] = { ...tableRows[i], index: i + 1 }
  }

  const processedColumns = useMemo(() => {
    return Array.isArray(headingColumns)
      ? headingColumns
      : Object.keys(headingColumns) || []
  }, [headingColumns])

  useEffect(() => {
    if (data.value || !processedColumns.length) return

    const mappedDatasets = processedColumns.map((label) => ({
      label: label?.field ? label.field : label,
      hidden: false,
      backgroundColor: 'rgba(255, 255, 255  , 1)',
      labelText: headingColumns[label?.field ? label.field : label] || (label?.field ? label.field : label)
    }))

    const chartValues = {
      labels: [],
      datasets: mappedDatasets,
      chartIndex: data?.chartIndex,
      pageIndex: data?.pageIndex
    }
    dispatch(addChartValues(chartValues))
  }, [processedColumns, dispatch, data])

  const colWidth = 100 / Object.keys(processedColumns).length

  return (
    <div
      className={'group'}
    >
      <p style={{ margin: '0', fontSize: '10px', fontWeight: 500, textTransform: 'capitalize' }}>
        {data.headingText || data.name}
      </p>
      {isLoading
        ? <span className='loading-text'>Loading...</span>
        : <div className='table-container'>
          <div className='table-border'>
            <div className=''>
              <table className='width-100' style={{ borderCollapse: 'collapse' }}>
                <thead>
                  <tr
                    className='headingBorder' style={{
                      // backgroundImage: 'linear-gradient(to bottom, #FFFFFF, #FFFFFF 50%, #E9E9E9 50% 100%)',
                      // backgroundPosition: '0 100%',
                      // backgroundRepeat: 'no-repeat',
                      // backgroundSize: '100% 1px'
                    }}
                  >
                    {tableRows.length > 0 &&
                      Array.isArray(datasets) &&
                      datasets.map(
                        (columnKey, columnIndex) =>
                          !columnKey.hidden && (
                            <th
                              key={columnIndex}
                              className={`width-${tableWidth ? tableWidth[columnIndex] : colWidth
                                } ${tableTextAlign.length >= columnIndex
                                  ? tableTextAlign[columnIndex]
                                  : 'text-align-center'
                                }`}
                            >
                              {columnKey?.label
                                ? (headingColumns[columnKey.label] || columnKey.label)
                                  .toString()
                                  // .toLowerCase()
                                  .replace(/_/g, ' ')
                                  .split(' ')
                                  .map(
                                    (word) =>
                                      word.charAt(0).toUpperCase() +
                                      word.slice(1)
                                  )
                                  .join(' ')
                                : ''}
                            </th>
                          )
                      )}
                  </tr>
                </thead>
                <tbody>
                  {tableRows.length > 0 &&
                    Array.isArray(tableRows) &&
                    tableRows.map((row, rowIndex) => (
                      <tr
                        key={rowIndex}
                        className='borderBottom'
                      >
                        {Array.isArray(datasets) &&
                          datasets.map(
                            (columnKey, columnIndex) =>
                              !columnKey.hidden && (
                                <td
                                  key={columnIndex}
                                  className={`${tableRows.length - 1 !== rowIndex
                                      ? 'borderBottom'
                                      : ''
                                    } ${tableTextAlign
                                      ? tableTextAlign[columnIndex]
                                      : 'text-align-center'
                                    }  font-size-8`}
                                  style={{
                                    // background: columnKey.backgroundColor,
                                    // backgroundImage: 'linear-gradient(to bottom, #FFFFFF, #FFFFFF 50%, #E9E9E9 50% 100%)',
                                    // backgroundPosition: '0 100%',
                                    // backgroundRepeat: 'no-repeat',
                                    // backgroundSize: '100% 1px'
                                  }}
                                >
                                  <div style={{ background: columnKey.backgroundColor }}>
                                    {isNumeric(row[columnKey?.label] * 1)
                                      ? decTwoPercentage(
                                        row[columnKey?.label] * 1,
                                        2
                                      ) || 'NA'
                                      : row[columnKey?.label] || 'NA'}
                                  </div>
                                </td>
                              )
                          )}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>}
    </div>
  )
}

export default DataTable
