import React from 'react'
import { Box, Grid } from '@mui/material'

const ChartLabel = (props) => {
  return (
    <Grid xs={12} container width='100%' justifyContent='center'>
      {Object.values(props.label).map((label, index) => {
        return (
          <Box alignItems='center' display='flex' justifyContent='center' p={2} key={index}>
            <Box className='label-box' mr={1} sx={{ background: `${props.backgroundColor[index]}` }} />
            <Grid item className='chart-label'>{label}</Grid>
          </Box>
        )
      })}
    </Grid>
  )
}

export default ChartLabel
