import React, { useState } from 'react'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box, Collapse, Grid, IconButton } from '@mui/material'
import ytdIcon from '../../../assets/images/icon-ytd.svg'
import InvestmentAdvisorIcon from '../../../assets/images/icons/investment-advisor.svg'
import ActiveWeightIcon from '../../../assets/images/icons/active-weight.svg'
import TotalMarketValueIcon from '../../../assets/images/icons/value-of-portfolio.svg'
import AvailableCashIcon from '../../../assets/images/icons/available-cash.svg'
import BenchmarkIcon from '../../../assets/images/icons/benchmark.svg'
import CashFloorIcon from '../../../assets/images/icons/cash-floor.svg'
import IncomeDividendIcon from '../../../assets/images/icons/income-dividend.svg'
import StrategyIncomeDividendIcon from '../../../assets/images/icons/strategy-income-dividend.svg'
import TaxSensitivityIcon from '../../../assets/images/icons/tax-sensitivity.svg'
import UnrealizedGainLossIcon from '../../../assets/images/icons/unrealized-gain-loss.svg'
import StatsWidget from '../../../components/StatsWidget'
import { decTwoPercentage, formatCurrencyWithSymbol, formatNumbersWithSymbol } from '../../Report/components/dataProcess/DataProcess'

const formatCurrency = (amount) => {
  // Nine Zeroes for Billions
  return Math.abs(Number(amount)) >= 1.0e+9

    ? (Math.abs(Number(amount)) / 1.0e+9).toFixed(2) + 'B'
    // Six Zeroes for Millions
    : Math.abs(Number(amount)) >= 1.0e+6

      ? (Math.abs(Number(amount)) / 1.0e+6).toFixed(2) + 'M'
      // Three Zeroes for Thousands
      : Math.abs(Number(amount)) >= 1.0e+3

        ? (Math.abs(Number(amount)) / 1.0e+3).toFixed(2) + 'K'

        : Math.abs(Number(amount).toFixed(2))
}

const formateToUsdCurrency = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
})

const AccountAttributes = ({ data }) => {
  const [expand, setExpand] = useState(false)
  return (
    <Box sx={{ marginBottom: '12px' }} id='account-attributes'>
      <Box display='flex' justifyContent='space-between' className='d-flex justify-content-between'>
        <p className='text-title header-text'>Account Attributes</p>
        <IconButton
          className='tooltip-trade' height={10} onClick={() => setExpand(!expand)}
          width={10}
        >
          {expand ? <ExpandMoreIcon /> : <ChevronRightIcon />}
        </IconButton>
      </Box>
      <Grid container rowSpacing={{ xs: 2, lg: 3 }} columnSpacing={{ xs: 2, lg: 3 }}>
        <Grid item xs={6} xl={3}>
          <StatsWidget
            position='Dashboard'
            title='Total Market Value'
            stats={
              !isNaN(data?.totalMarketValue)
                ? `${formatCurrencyWithSymbol(data?.totalMarketValue, 2, '$')}`
                : 'N/A'
            }
            subStats={` (${Number(data?.totalMvPercentage || '0').toFixed(2)}%)`}
            tooltipValue={!isNaN(data?.totalMarketValue)
              ? `${formatNumbersWithSymbol(data?.totalMarketValue, 2, '$')}`
              : 'N/A'}
            imgSrc={TotalMarketValueIcon}
          />
        </Grid>
        <Grid item xs={6} xl={3}>
          <StatsWidget
            position='Dashboard'
            title='Tax Sensitivity'
            stats={data?.taxSensitivity || 'N/A'}
            imgSrc={TaxSensitivityIcon}
          />
        </Grid>
        <Grid item xs={6} xl={3}>
          <StatsWidget
            position='Dashboard'
            title='Available Cash'
            stats={
              !isNaN(data?.cashAmount)
                ? `${formatCurrencyWithSymbol(data?.cashAmount, 2, '$')}`
                : 'N/A'
            }
            subStats={` (${decTwoPercentage(data?.cashLevel || '0', 2)}%)`}
            imgSrc={AvailableCashIcon}
            tooltipValue={!isNaN(data?.cashAmount)
              ? `${formatNumbersWithSymbol(data?.cashAmount, 2, '$')}
              (${decTwoPercentage(data?.cashLevel || '0', 2)}%)`
              : 'N/A'}
          />
        </Grid>
        <Grid item xs={6} xl={3}>
          <StatsWidget
            position='Dashboard'
            title='Unrealized Gain/Loss'
            stats={
              !isNaN(data?.unrealizedGainLoss)
                ? `${formatCurrencyWithSymbol(data?.unrealizedGainLoss || '0', 2, '$')}`
                : 'N/A'
            }
            subStats={` (${decTwoPercentage(data?.unrealizedGainLossWeight || '0', 2)}%)`}
            imgSrc={UnrealizedGainLossIcon}
            tooltipValue={
              !isNaN(data?.unrealizedGainLoss)
                ? `${formatNumbersWithSymbol(data?.unrealizedGainLoss || '0', 2, '$')} 
                    (${decTwoPercentage(data?.unrealizedGainLossWeight || '0', 2)}%)`
                : 'N/A'
            }
          />
        </Grid>
      </Grid>
      <Collapse in={expand}>
        <Grid container sx={{ mt: { xs: 0, lg: 0 } }} rowSpacing={{ xs: 2, lg: 3 }} columnSpacing={{ xs: 2, lg: 3 }}>
          <Grid item xs={6} xl={3}>
            <StatsWidget
              position='Dashboard'
              title='Investment Advisor'
              stats={data?.faName || 'N/A'}
              imgSrc={InvestmentAdvisorIcon}
            />
          </Grid>
          <Grid item xs={6} xl={3}>
            <StatsWidget
              position='Dashboard'
              title='YTD Realized Gain/Loss'
              stats={
                !isNaN(data?.ytdRealizedGainLoss)
                  ? `$${formatCurrency(
                    Number(data?.ytdRealizedGainLoss).toFixed(2)
                  )}`
                  : 'N/A'
              }
              tooltipValue={
                !isNaN(data?.ytdRealizedGainLoss)
                  ? `${formatNumbersWithSymbol(data?.ytdRealizedGainLoss || '0', 2, '$')} `
                  : 'N/A'
              }
              imgSrc={ytdIcon}
            />
          </Grid>
          <Grid item xs={6} xl={3}>
            <StatsWidget
              position='Dashboard'
              title='Account Incom/Div Yield'
              stats={
                !isNaN(data?.incomeDivYield)
                  ? `${Number((data?.incomeDivYield || 0) * 100).toFixed(
                    2
                  )}%`
                  : 'N/A'
              }
              imgSrc={IncomeDividendIcon}
            />
          </Grid>
          <Grid item xs={6} xl={3}>
            <StatsWidget
              position='Dashboard'
              title='Active Weight'
              stats={
                !isNaN(data?.assetAllocationDrift)
                  ? `${Number(
                    (data?.assetAllocationDrift || 0) * 100
                  ).toFixed(2)}%`
                  : 'N/A'
              }
              imgSrc={ActiveWeightIcon}
            />
          </Grid>
          <Grid item xs={6} xl={3}>
            <StatsWidget
              position='Dashboard'
              title='Benchmark'
              stats={data?.benchmarkLongName || 'N/A'}
              imgSrc={BenchmarkIcon}
            />
          </Grid>
          <Grid item xs={6} xl={3}>
            <StatsWidget
              position='Dashboard'
              title='Cash Floor'
              stats={
                data?.cashFloor
                  ? `${(Number(data?.cashFloor || '0') * 100).toFixed(2)}%`
                  : '0%'
              }
              imgSrc={CashFloorIcon}
            />
          </Grid>
          <Grid item xs={6} xl={3}>
            <StatsWidget
              position='Dashboard'
              title='Strategy Income/Div Yield'
              stats={
                !isNaN(data?.strategyDividendYield)
                  ? `${(Number(data?.strategyDividendYield) * 100).toFixed(
                    2
                  )}%`
                  : 'N/A'
              }
              imgSrc={StrategyIncomeDividendIcon}
            />
          </Grid>
        </Grid>
      </Collapse>
    </Box>
  )
}

export default AccountAttributes
