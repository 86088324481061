import { FormatDateAPI, FormatPayload } from '../components/FormatAPIRequest'
import { UpdateAPI } from '../components/Actions'
import { getUserRole } from '../../../utils/getUserRole'

export const UpdateStrategyData = async (updatedRow, oldRow, tabIndex) => {
  switch (tabIndex) {
    case 'strategy-master':
      return await UpdateStrategyMaster(updatedRow)
    case 'strategy-parameter':
      return await UpdateStrategyParameter(updatedRow, oldRow)
    case 'strategy-benchmark-map':
      return await UpdateStrategyBenchmarkMap(updatedRow)
    case 'strategy-instrument-restriction':
      return await UpdateStrategyInstrumentRestriction(updatedRow, oldRow)
    case 'strategy-properties-master':
      return await UpdateStrategyPropertyMaster(updatedRow)
    case 'strategy-benchmark-master':
      return await UpdateStrategyBenchmarkMaster(updatedRow)
    default:
      return {}
  }
}

const UpdateStrategyMaster = async (updatedRow) => {
  const userRole = getUserRole()
  const startDateValue = updatedRow?.startDate ? FormatDateAPI(updatedRow.startDate) : null
  const endDateValue = updatedRow?.endDate ? FormatDateAPI(updatedRow.endDate) : null

  let payload = {
    strategyName: updatedRow.strategyName,
    strategyOwnerId: updatedRow.strategyOwnerId,
    strategyCd: updatedRow.strategyCd,
    strTypeId: updatedRow.strTypeId,
    strategySource: updatedRow.strategySource,
    aggregationLevel: updatedRow.aggregationLevel,
    startDate: startDateValue,
    endDate: endDateValue
  }

  payload = FormatPayload(payload)

  return UpdateAPI(
    'baseStrategyMaintainURL',
      `data-maintenance/v1/${userRole}/strategies/${updatedRow?.strategyId}/master`,
      payload
  )
}

const UpdateStrategyParameter = async (updatedRow, oldRow) => {
  const userRole = getUserRole()
  const newStartDateValue = updatedRow?.startDate ? FormatDateAPI(updatedRow.startDate) : null
  const endDateValue = updatedRow?.endDate ? FormatDateAPI(updatedRow.endDate) : null
  const oldStartDateValue = oldRow?.startDate ? FormatDateAPI(oldRow.startDate) : null

  let payload = {
    minCashLevel: updatedRow.minCashLevel,
    isWeightStatic: updatedRow.isWeightStatic,
    startDate: newStartDateValue,
    endDate: endDateValue
  }

  payload = FormatPayload(payload)
  return UpdateAPI(
    'baseStrategyMaintainURL',
      `data-maintenance/v1/${userRole}/strategies/strategy-parameter/${updatedRow?.strategyId}/${oldStartDateValue}`,
      payload
  )
}

const UpdateStrategyBenchmarkMap = async (updatedRow) => {
  const userRole = getUserRole()
  const startDateValue = updatedRow?.startDate ? FormatDateAPI(updatedRow.startDate) : null
  const endDateValue = updatedRow?.endDate ? FormatDateAPI(updatedRow.endDate) : null

  let payload = {
    benchmarkId: updatedRow.benchmarkId,
    startDate: startDateValue,
    endDate: endDateValue
  }

  payload = FormatPayload(payload)
  return UpdateAPI(
    'baseStrategyMaintainURL',
    `data-maintenance/v1/${userRole}/strategies/benchmark-map/${updatedRow?.strategyId}`,
    payload
  )
}

const UpdateStrategyInstrumentRestriction = async (updatedRow, oldRow) => {
  const userRole = getUserRole()
  const newStartDateValue = updatedRow?.startDate ? FormatDateAPI(updatedRow.startDate) : null
  const endDateValue = updatedRow?.endDate ? FormatDateAPI(updatedRow.endDate) : null
  const oldStartDateValue = oldRow?.startDate ? FormatDateAPI(oldRow.startDate) : null

  let payload = {
    restrictionId: updatedRow.restrictionId,
    startDate: newStartDateValue,
    endDate: endDateValue
  }

  payload = FormatPayload(payload)
  return UpdateAPI(
    'baseStrategyMaintainURL',
    `data-maintenance/v1/${userRole}/strategies/instrument-restriction/${updatedRow?.strategyId}/instrument/${oldRow.instrId}/start-date/${oldStartDateValue}`,
    payload
  )
}

const UpdateStrategyPropertyMaster = async (updatedRow) => {
  const userRole = getUserRole()
  const startDateValue = updatedRow?.startDate ? FormatDateAPI(updatedRow.startDate) : null
  const endDateValue = updatedRow?.endDate ? FormatDateAPI(updatedRow.endDate) : null

  let payload = {
    strategyId: updatedRow.strategyId,
    propertyCode: updatedRow?.propertyCode?.toUpperCase(),
    propertyValue: updatedRow.propertyValue,
    startDate: startDateValue,
    endDate: endDateValue
  }

  payload = FormatPayload(payload)
  return UpdateAPI(
    'baseStrategyMaintainURL',
    `data-maintenance/v1/${userRole}/strategies/properties/${updatedRow?.strategyPropertiesId}`,
    payload
  )
}

const UpdateStrategyBenchmarkMaster = async (updatedRow) => {
  const userRole = getUserRole()
  let payload = {
    bmkCode: updatedRow.bmkCode?.toUpperCase(),
    longName: updatedRow.longName,
    shortName: updatedRow.shortName,
    indexProxyInstrId: updatedRow.indexProxyInstrId || null,
    bmkTypeCode: updatedRow.bmkTypeCode?.toUpperCase(),
    bmkSource: updatedRow.bmkSource,
    bmkSourceId: updatedRow.bmkSourceId
  }

  payload = FormatPayload(payload)
  return UpdateAPI(
    'baseStrategyMaintainURL',
    `data-maintenance/v1/${userRole}/strategies/bench-master/${updatedRow?.benchmarkId}`,
    payload
  )
}
