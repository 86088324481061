import { Autocomplete, Box, Button, Checkbox, FormControlLabel, MenuItem, Radio, RadioGroup, Tab, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import * as Sentry from '@sentry/react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { useErrorToast } from '../../hooks/useErrorToast'
import { useAuth } from '../../contexts/AuthContext'
import { storeAccountData } from '../../store/dashboard-reducer/dashboard-reducer'
import { TabContext, TabList, TabPanel } from '@mui/lab'

const AccountCheckboxList = ({ options, selectedValues, onChange, benchmarkLength, isLoading }) => {
  const [searchInput, setSearchInput] = useState('')
  const { showError } = useErrorToast()
  const params = useParams()
  const [filteredOptions, setFilteredOptions] = useState(options)

  const handleInputChange = (e) => {
    const inputValue = e.target.value.trim()
    setSearchInput(inputValue)
    if (inputValue === '') {
      setFilteredOptions(options)
    } else {
      const filtered = options.filter(option =>
        option.accountName.toLowerCase().includes(inputValue.toLowerCase().trim())
      )
      setFilteredOptions(filtered)
    }
  }

  const handleSelectChange = (event, newValue) => {
    if (event.type === 'keydown' && event.key === 'Backspace' && event?.target?.value?.trim() === '') {
      return
    }
    if (newValue.length + benchmarkLength > 1 && newValue.length + benchmarkLength < 11) {
      onChange(newValue)
    } else {
      if (newValue.length + benchmarkLength === 1) {
        showError('You need to keep at least 1 parameter')
      } else if (newValue.length + benchmarkLength > 10) {
        showError('You can select up to 10 parameters')
      }
    }
  }

  const handleCheckboxChange = (value) => {
    const currentIndex = selectedValues.indexOf(value)
    const newChecked = [...selectedValues]
    if (currentIndex === -1) {
      if (selectedValues.length + benchmarkLength <= 9) {
        newChecked.push(value)
      } else {
        showError('You can select upto 9 accounts')
      }
    } else {
      if (selectedValues.length + benchmarkLength >= 3) {
        newChecked.splice(currentIndex, 1)
      } else if (selectedValues.length + benchmarkLength === 2) {
        showError('You need to keep at least 1 accounts')
      }
    }

    onChange(newChecked)
  }
  return (
    <Box
      id='checkbox-list'
      className='filter-data-list'
      sx={{
        '& .MuiFormControlLabel-root': {
          marginRight: 0,
          alignItems: 'flex-start'
        }
      }}
    >
      <Autocomplete
        multiple
        id='checkbox-list-autocomplete'
        loading={isLoading}
        value={selectedValues}
        options={filteredOptions.filter(account => account.accountType === 'TRADING')}
        inputValue={searchInput}
        onChange={handleSelectChange}
        onClose={(event, reason) => {
          if (reason === 'selectOption' || reason === 'blur' || reason === 'escape') {
            setSearchInput('')
          }
          if (reason === 'blur') {
            setFilteredOptions(options)
          }
        }}
        disableCloseOnSelect
        getOptionLabel={(option) => `${option.accountId}-${option.accountName}`}
        size='small'
        renderTags={() => null}
        disableClearable
        getOptionDisabled={(option) => option.accountId === params?.accountId}
        renderInput={(params) => (
          <TextField
            {...params}
            label='Select Accounts'
            value={searchInput}
            onChange={handleInputChange}
          />
        )}
        isOptionEqualToValue={(option, value) => option.accountId === value.accountId}
        renderOption={(props, option) => (
          <MenuItem {...props} sx={{ whiteSpace: 'normal' }}>
            {option.accountName}
          </MenuItem>
        )}
        sx={{
          marginTop: '10px',
          marginBottom: '10px',
          '& .MuiAutocomplete-root': {
            fontSize: '12px'
          }
        }}
      />
      {selectedValues.map((selectedOption) => (
        <div key={selectedOption.accountId}>
          <FormControlLabel
            sx={{
              '& .MuiCheckbox-root': {
                padding: '2px 9px'
              },
              '.MuiFormControlLabel-label': {
                fontSize: '14px'
              }
            }}
            control={
              <Checkbox
                size='12px'
                checked
                disabled={selectedOption?.accountId === params?.accountId}
                onChange={() => handleCheckboxChange(selectedOption)}
                sx={{
                  borderRadius: '10px'
                }}
              />
            }
            label={selectedOption.accountName}
          />
        </div>
      ))}
    </Box>
  )
}

const BenchmarkCheckboxList = ({ options, selectedValues, onChange, isTransitionAccount, accountsLength }) => {
  const { showError } = useErrorToast()
  const [filteredOptions, setFilteredOptions] = useState(options)
  const [searchInput, setSearchInput] = useState('')
  const handleInputChange = (e) => {
    const inputValue = e.target.value
    setSearchInput(inputValue)
    if (inputValue === '') {
      setFilteredOptions(options)
    } else {
      const filtered = options.filter(option =>
        option.strategyName.toLowerCase().includes(inputValue.toLowerCase().trim())
      )
      setFilteredOptions(filtered)
    }
  }
  const handleCheckboxChange = (value) => {
    const currentIndex = selectedValues.indexOf(value)
    const newChecked = [...selectedValues]

    if (currentIndex === -1) {
      if (selectedValues.length + accountsLength <= 9) {
        newChecked.push(value)
      } else {
        showError('You can select upto 10 parameters')
      }
    } else {
      if (selectedValues.length + accountsLength >= 3) {
        newChecked.splice(currentIndex, 1)
      } else if (selectedValues.length + accountsLength === 2) {
        showError('You need to keep at least 1 parameter')
      }
    }

    onChange(newChecked)
  }

  const handleSelectChange = (event, newValue) => {
    if (event.type === 'keydown' && event.key === 'Backspace' && event?.target?.value?.trim() === '') {
      return
    }
    if (newValue.length + accountsLength > 1 && newValue.length + accountsLength < 11) {
      onChange(newValue)
    } else {
      if (newValue.length + accountsLength === 1) {
        showError('You need to keep at least 1 parameter')
      } else if (newValue.length + accountsLength > 10) {
        showError('You can select upto 9 parameter')
      }
    }
  }

  return (
    <Box
      id='checkbox-list'
      className='filter-data-list'
      sx={{
        '& .MuiFormControlLabel-root': {
          marginRight: 0,
          alignItems: 'flex-start'
        }
      }}
    >
      <Autocomplete
        multiple
        id='checkbox-list-autocomplete'
        options={filteredOptions}
        inputValue={searchInput}
        disableCloseOnSelect
        disableClearable
        getOptionLabel={(option) => `${option.strategyId}-${option.strategyName}`}
        value={selectedValues}
        size='small'
        renderTags={() => null}
        onChange={handleSelectChange}
        onClose={(event, reason) => {
          if (reason === 'selectOption' || reason === 'blur' || reason === 'escape') {
            setSearchInput('')
          }
          if (reason === 'blur') {
            setFilteredOptions(options)
          }
        }}
        renderInput={(params) => (
          <TextField {...params} label='Select Benchmarks' value={searchInput} onChange={handleInputChange} />
        )}
        isOptionEqualToValue={(option, value) => option.strategyId === value.strategyId}
        renderOption={(props, option) => (
          <MenuItem {...props} sx={{ whiteSpace: 'normal' }}>
            {option.strategyName}
          </MenuItem>
        )}
        sx={{
          marginTop: '10px',
          marginBottom: '10px',
          '& .MuiAutocomplete-root': {
            fontSize: '12px'
          }
        }}
      />
      {selectedValues.map((selectedOption) => (
        <div key={selectedOption.strategyId}>
          <FormControlLabel
            sx={{
              '& .MuiCheckbox-root': {
                padding: '2px 9px'
              },
              '.MuiFormControlLabel-label': {
                fontSize: '14px'
              }
            }}
            control={
              <Checkbox
                size='12px'
                checked
                onChange={() => handleCheckboxChange(selectedOption)}
                sx={{
                  borderRadius: '10px'
                }}
              />
            }
            label={selectedOption.strategyName}
          />
        </div>
      ))}
    </Box>
  )
}

const BenchmarkCustomization = ({ chartData, applyBenchmark, isTransitionAccount }) => {
  const { accountData } = useSelector((state) => state.dashboard)
  const [selectedBenchmark, setSelectedBenchmark] = useState(chartData?.strategyList ? chartData?.strategyList?.filter(strategy => chartData?.selectedBenchmark ? chartData?.selectedBenchmark?.includes(strategy.strategyId) : false) : [])
  const [selectedAccountIds, setSelectedAccountIds] = useState(accountData ? accountData?.filter(account => chartData?.selectedAccountIds ? chartData?.selectedAccountIds?.find(selectedAccount => selectedAccount?.accountId === account.accountId) : false) : [])
  const [categoryName, setCategoryName] = useState('benchmark')
  const { user } = useAuth()
  const dispatch = useDispatch()
  const { showError } = useErrorToast()
  const [accountsLoading, setAccountsLoading] = useState(false)
  const [benchmarkChanged, setBenchmarkChanged] = useState(false)
  const [accountChanged, setAccountChange] = useState(false)
  useEffect(() => {
    setBenchmarkChanged(false)
    setAccountChange(false)
  }, [chartData])

  useEffect(() => {
    if (!accountData?.length) {
      fetchAccountList()
    }
  }, [accountData])

  const fetchAccountList = () => {
    setAccountsLoading(true)
    API
      .get('baseURL', `account-master/v1/${user?.userGroup}/accounts/summary/details`, { queryStringParameters: { page: 1, perpage: 300 } })
      .then(response => {
        if (response.data.accountsSummaryDetails) {
          dispatch(storeAccountData(response.data.accountsSummaryDetails))
        }
      })
      .catch(error => {
        showError(error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => {
        setAccountsLoading(false)
      })
  }

  const handleChangeCategoryType = (event, newValue) => {
    setCategoryName(newValue)
  }

  function arraysAreEqual(array1, array2) {
    if (array1.length !== array2.length) {
      return false
    }

    const sortedArray1 = array1.slice().sort()
    const sortedArray2 = array2.slice().sort()

    return sortedArray1.every((element, index) => element === sortedArray2[index])
  }

  const handleParameterChange = (value, callback) => {
    callback(value)
    if (categoryName === 'benchmark') {
      setBenchmarkChanged(!arraysAreEqual(value?.map(benchmark => benchmark.strategyId), chartData?.selectedBenchmark || []))
    } else if (categoryName === 'account') {
      setAccountChange(!arraysAreEqual(value?.map(account => account.accountId), chartData?.selectedAccountIds?.map(acc => acc.accountId) || []))
    }
  }

  return (
    <>
      <TabContext value={categoryName}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList
            sx={{
              '.MuiButtonBase-root.MuiTab-root': {
                padding: '0 5px'
              }
            }}
            onChange={handleChangeCategoryType}
            aria-label='lab API tabs example'
          >
            <Tab label='Benchmark' value='benchmark' />
            {!isTransitionAccount ? <Tab label='Accounts' value='account' /> : ''}
          </TabList>
        </Box>
        <TabPanel value='benchmark' sx={{ padding: '0px !important' }}>
          <BenchmarkCheckboxList
            options={chartData?.strategyList || []}
            selectedValues={selectedBenchmark}
            onChange={(value) => handleParameterChange(value, setSelectedBenchmark)}
            isTransitionAccount={isTransitionAccount}
            accountsLength={selectedAccountIds?.length}
          />
          <Box textAlign='right'>
            <Button
              disabled={!benchmarkChanged}
              variant='contained'
              size='small'
              sx={{
                marginTop: '15px'
              }} onClick={() => { applyBenchmark(selectedBenchmark?.map(strategy => strategy.strategyId), null, null, selectedAccountIds) }}
            >Add
            </Button>
          </Box>
        </TabPanel>
        <TabPanel value='account' sx={{ padding: '0px !important' }}>
          {
            isTransitionAccount
              ? ''
              : <><AccountCheckboxList
                options={accountData || []}
                selectedValues={selectedAccountIds}
                onChange={(value) => handleParameterChange(value, setSelectedAccountIds)}
                isTransitionAccount={isTransitionAccount}
                benchmarkLength={selectedBenchmark?.length}
                isLoading={accountsLoading}
              />
                <Box textAlign='right'>
                  <Button
                    disabled={!accountChanged}
                    variant='contained'
                    size='small'
                    sx={{
                      marginTop: '15px'
                    }} onClick={() => { applyBenchmark(selectedBenchmark?.map(strategy => strategy.strategyId), null, null, selectedAccountIds) }}
                  >Add
                  </Button>
                </Box>
              </>
          }
        </TabPanel>
      </TabContext>

    </>
  )
}
export default BenchmarkCustomization
