import React, { useEffect, useRef, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import { Chart as ChartJS, registerables } from 'chart.js'
import {
  fetchDataAndDispatch,
  responseDataConvert
} from './APIResponseConverts/APIResponseConverts'
import {
  factorChart
} from './APIResponseConverts/chartDefaultsData'
import { useDispatch, useSelector } from 'react-redux'
import { addChartValues } from '../../../store/pdf-customizer-reducer/Action/chartDropAction'
import { factorAllocationApi } from '../../../utils/pdf-customizer/_data'
import { barChartOptions } from '../common/ChartOptions/options'
import { useAuth } from '../../../contexts/AuthContext'

ChartJS.register(...registerables)
const FactorAllocationChart = ({
  data,
  chartIndex,
  index,
  chartHeight,
  chartWidth,
  clickEvent
}) => {
  const { factorChartResponse, isResponseElements } =
    useSelector((state) => state.chartState)
  const dispatch = useDispatch()
  const [isDragging, setIsDragging] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const chartRef = useRef(null)
  const templateData = useSelector(state => state.templateData)
  const { user } = useAuth()

  useEffect(() => {
    if (
      isResponseElements && !factorChartResponse && !isLoading
    ) {
      setIsLoading(true)
      fetchDataAndDispatch(
        () => factorAllocationApi(templateData, user?.userGroup),
       'FACTOR_CHART',
        dispatch
      )
    }
    if (isResponseElements && factorAllocationApi) {
      setIsLoading(false)
    }
  }, [isResponseElements, dispatch, factorChartResponse])
  const factorAllocationData = responseDataConvert(
   factorChartResponse || factorChart,
   'factorAllocationData',
   'factorName',
    data.value || null
  )

  useEffect(() => {
    if (data.value) return
    if (!factorAllocationData) return
    const { labels, datasets } = factorAllocationData

    const mappedLabels =
      Array.isArray(labels) &&
      labels.map((label) => ({
        name: label,
        hidden: false
      }))

    const mappedDatasets =
      Array.isArray(datasets) &&
      datasets.map(({ label, backgroundColor }) => ({
        label,
        backgroundColor,
        hidden: false
      }))

    const chartValues = {
      labels: mappedLabels,
      datasets: mappedDatasets,
      chartIndex: data?.chartIndex,
      pageIndex: data?.pageIndex
    }

    dispatch(addChartValues(chartValues))
  }, [factorAllocationData, dispatch, data])

  const options = barChartOptions

  useEffect(() => {
    const { current: chartInstance } = chartRef
    const { value } = data
    if (
      !value ||
      !value.datasets ||
      !value.labels ||
      clickEvent <= 1 ||
      !chartInstance
    ) {
      return
    }
    try {
      const { datasets, labels } = value
      datasets.forEach((dataset, index) => {
        const chartDataset = chartInstance.data.datasets[index]
        Object.assign(chartDataset, {
          hidden: dataset.hidden,
          backgroundColor: dataset.backgroundColor,
          borderColor: dataset.borderColor
        })
      })

      const filteredLabels = labels.filter((label) => !label.hidden)
      chartInstance.data.labels =
        Array.isArray(filteredLabels) &&
        filteredLabels.map((label) => label.name)

      labels.forEach((label, index) => {
        if (label.hidden) {
          factorAllocationData.datasets.forEach((dataset, datasetsIndex) => {
            chartInstance.data.datasets[datasetsIndex].data.splice(index, 1)
          })
        }
      })

      chartInstance.data = {
        labels: chartInstance.data.labels,
        datasets: chartInstance.data.datasets
      }

      chartInstance.update()
    } catch (error) {
      console.error('Error in useEffect:', error)
    }
  }, [clickEvent, data, factorAllocationData])
  const heightChart = chartHeight.split('px')
  heightChart[0] = heightChart[0] - 20

  const chartStyle = {
    height: '100%',
    width: '100%'
  }

  return (
    <div
      className={'group'}
      key={data?.chartIndex + data?.pageIndex}
    >
      <p style={{ margin: '0', fontSize: '10px', fontWeight: 600 }}>
        {data.headingText || data.name}
      </p>
      <div
        style={chartStyle}
        className={'margin-top-2 chart-border'}
      >
        {isLoading
        ? <span className='loading-text'>Loading...</span>
          : <Bar
            ref={chartRef}
            data={factorAllocationData || {}}
            redraw={true}
            options={options}
          />
        }
      </div>
    </div>
  )
}

export default FactorAllocationChart
