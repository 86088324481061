import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReactECharts from 'echarts-for-react'
import { AGGREGATE_MARKET_VALUE } from '../../../../store/pdf-customizer-reducer/Action/chartState.types'
import { chartDataState } from '../../../../store/pdf-customizer-reducer/Action/chartStateAction'
import { addChartValues } from '../../../../store/pdf-customizer-reducer/Action/chartDropAction'
import { ARIS_COLORS } from '../../../../utils/pdf-customizer/common'
import { aggregateMarketValue } from '../../../../utils/pdf-customizer/_data'
import { useAuth } from '../../../../contexts/AuthContext'
import { umaMarketValueDonutChartOptions } from '../../common/ChartOptions/options'
import { umaMarketValueData } from '../APIResponseConverts/chartDefaultsData'

const AggregateAssetsChart = ({ data }) => {
	const dispatch = useDispatch()
	const { user } = useAuth()
	const { isResponseElements, aggregateMarketValueResponse } = useSelector(state => state.chartState)
	const [options, setOptions] = useState({})
	const [availableGroups, setAvailableGroup] = useState([])
	const [umaData, setUmaData] = useState([])
	const templateData = useSelector(state => state.templateData)
	const group = data?.value?.group || ''
	const value = data?.value || {}

	async function fetchAndStoreData(fetchDataCallback, action) {
		const response = await fetchDataCallback()
		if (response?.success) {
			dispatch(chartDataState({ data: response?.data ? response?.data : [], loading: false }, action))
		} else {
			dispatch(chartDataState({ data: [], loading: false }, action))
		}
	}

	useEffect(() => {
		if (isResponseElements && !aggregateMarketValueResponse?.loading && !aggregateMarketValueResponse?.data) {
			dispatch(chartDataState({ loading: true, data: [] }, AGGREGATE_MARKET_VALUE))
			fetchAndStoreData(() => aggregateMarketValue(templateData, user?.userGroup), AGGREGATE_MARKET_VALUE)
		}
	}, [isResponseElements, aggregateMarketValueResponse])

	useEffect(() => {
		if (isResponseElements) {
			setAvailableGroup(aggregateMarketValueResponse?.data?.groupBy || [])
			setUmaData(aggregateMarketValueResponse?.data?.data || [])
		}
		else {
			setAvailableGroup(umaMarketValueData.data.groupBy || [])
			setUmaData(umaMarketValueData.data.data || [])
		}
	}, [aggregateMarketValueResponse, isResponseElements])

	// store available groups
	useEffect(() => {
		if (availableGroups.length) {
			dispatch(addChartValues({ pageIndex: data?.pageIndex, chartIndex: data?.chartIndex, availableGroups, group: availableGroups?.includes(group) ? group : null, datasets: availableGroups?.includes(group) ? value?.datasets : [] }))
		}
	}, [availableGroups])

	function groupByField(data, field) {
		const group = {}
		const visitedFields = []
		data.forEach((element) => {
			if (visitedFields.includes(element[field])) {
				group[element[field]].push(element)
			} else {
				group[element[field]] = [element]
				visitedFields.push(element[field])
			}
		})
		return group
	}

	useEffect(() => {
		if (group && umaData?.length && availableGroups?.length && availableGroups?.includes(group)) {
			const groupedObject = groupByField(umaData, group)
			const seriesData = []
			for (const key in groupedObject) {
				const sum = groupedObject[key].reduce((acc, obj) => {
					acc += obj?.marketValue
					return acc
				}, 0)
				seriesData.push({ value: sum, name: key === 'null' ? 'Uncategorized' : key })
			}
			const { datasets } = value
			const mappedDatasets = seriesData?.sort((a, b) => b.value - a.value)
				?.map((obj, index) => ({ ...obj, color: ARIS_COLORS[index % ARIS_COLORS.length] }))
				?.map(({ name, color, value }, index) => ({
					label: name,
					backgroundColor: datasets?.length - 1 >= index ? datasets[index]?.backgroundColor : color,
					hidden: false,
					name,
					value
				}))
			const chartValues = {
				labels: [],
				datasets: mappedDatasets,
				chartIndex: data?.chartIndex,
				pageIndex: data?.pageIndex
			}
			dispatch(addChartValues(chartValues))
		}
	}, [group, umaData])

	useEffect(() => {
		if (value?.datasets?.length && availableGroups?.length) {
			const visibleAccounts = value?.datasets?.filter(data => !data?.hidden)
			const filteredColor = visibleAccounts?.map((dataset) => dataset.backgroundColor)
			setOptions({
				...umaMarketValueDonutChartOptions(visibleAccounts), color: filteredColor
			})
		}
	}, [value])

	return (
		<div
			className={'group'}
			style={{
				height: '100%',
				width: '100%'
			}}
		>
			<div style={{ width: '100%', height: '100%' }}>
				<p
					style={{
						margin: 0, fontSize: '10px', fontWeight: 500
					}}>
					{data?.headingText || data?.name}
				</p>
				{
					aggregateMarketValueResponse?.loading
						? <span className='loading-text'>Loading...</span>
						: value?.datasets?.length
							? <ReactECharts option={options} style={{ width: '100%', height: 'calc(100% - 11px)', color: '#333333' }} opts={{ renderer: 'svg' }} />
							: ''
				}
			</div>
		</div >
	)
}

export default AggregateAssetsChart