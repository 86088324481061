import React, { useEffect, useMemo, useState } from 'react'
import { Add, ExpandLess, ExpandMore } from '@mui/icons-material'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import CancelIcon from '@mui/icons-material/Close'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  IconButton,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import { randomId } from '@mui/x-data-grid-generator'
import { DataGridPro, GridActionsCellItem, GridRowEditStopReasons, GridRowModes, useGridApiRef } from '@mui/x-data-grid-pro'
import { useErrorToast } from '../../../hooks/useErrorToast'
import CustomTooltipIcon from '../../Personalization/components/CustomTooltipIcon'
import CustomStrategyField from './CustomStrategyField'

const isNumberWithLimitedDecimals = (event) => {
  const regex = /^(\d*\.?\d{0,5})$/
  const inputValue=event?.target?.value
  if (!regex.test(inputValue)) {
    event.preventDefault()
    return false
  }
  return true
}

const AllocationModelCards = (props) => {
  const {
    defaultAllocationTitleId, allocationId, setAllocationCardData, allocationCardData, accountType, personalizationData, setPersonalizationData, setAllStepsVisited
    , strategies, loading
  } = props
  const [expanded, setExpanded] = useState(true)
  const apiRef = useGridApiRef()
  const { showError } = useErrorToast()
  const [selectedStrategyPersonalization, setSelectedStrategyPersonalization] = useState(null)
  const [rowModesModel, setRowModesModel] = useState({})
  const [isModalShown, setIsModalShown] = useState(false)
  const [rowErrors, setRowErrors] = useState({})
  const [selectedRowId, setSelectedRowId] = useState(null)

  const currAllocationData = useMemo(() => {
    return allocationCardData?.filter((data) => data?.id === allocationId)
  }, [allocationCardData, allocationId])
  const currSelectedAllocation = currAllocationData && currAllocationData?.length ? currAllocationData[0]?.data : []
  const [allocationTitle, setAllocationTitle] = useState(currAllocationData && currAllocationData?.length && currAllocationData[0]?.allocationTitle ? currAllocationData[0]?.allocationTitle : `Allocation ${defaultAllocationTitleId}`)
  const currentAllocationTitle = currAllocationData && currAllocationData?.length ? currAllocationData[0]?.allocationTitle : `Allocation ${defaultAllocationTitleId}`
  const [allocationGridState, setAllocationGridState] = useState(null)
  const [isNameEditMode, setIsNameEditMode] = useState(allocationCardData?.find((allocation) =>
    allocation?.id === allocationId)?.isNameEditMode || false)
  const [tempAllocationTitle, setTempAllocationTitle] = useState(allocationTitle)

  // Function to save the current state of the DataGridPro
  const handleAllocationsTableChange = () => {
    if (apiRef?.current?.exportState) {
      const state = apiRef?.current?.exportState()
      setAllocationGridState(state)
    }
  }

  // Effect to restore the grid state whenever gridState changes
  useEffect(() => {
    if (apiRef?.current?.restoreState && allocationGridState) {
      const resetGridStateObj = {
        columns: allocationGridState?.columns || {},
        pinnedColumns: allocationGridState?.pinnedColumns || {},
        sorting: allocationGridState?.sorting || []
      }
      apiRef?.current?.restoreState(resetGridStateObj)
    }
  }, [allocationGridState, isModalShown, rowModesModel, selectedStrategyPersonalization, selectedRowId, allocationTitle, strategies, expanded, allocationId, rowErrors, allocationCardData, accountType, personalizationData, currAllocationData])

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const handlePersonalizationClick = (id) => () => {
    setSelectedRowId(id)
    const row = allocationCardData.flatMap(allocation => allocation.data).find(item => item.id === id)
    if (row) {
      setSelectedStrategyPersonalization(row?.strategyId)
      setPersonalizationData(row?.personalizationData)
      setIsModalShown(true)
    }
  }

  const handleCloseModal = () => {
    setIsModalShown(false)
    setSelectedStrategyPersonalization(null)
  }

  const totalAllocation = useMemo(() => {
    const allocation = allocationCardData?.find((alloc) => alloc.id === allocationId)?.data?.filter((data) => data.allocation !== '')
    if (allocation && allocation?.length) {
      const totalAllocation = allocation?.reduce(
        (sum, row) => sum + Number(row?.allocation || 0),
        0
      )
      return parseFloat(totalAllocation.toFixed(5))
    }
    return 0
  }, [allocationCardData, allocationId])

  const checkIfDuplicateStrategyExists = useMemo(() => {
    const allocation = allocationCardData?.find((alloc) => alloc.id === allocationId)
    if (allocation && allocation?.data) {
      const allStrategies = allocation?.data?.flatMap(item => item?.strategy?.strategyName)
      const uniqueStrategies = new Set()
      if (allStrategies && allStrategies?.length) {
        for (const strategyName of allStrategies) {
          if (strategyName && uniqueStrategies.has(strategyName)) {
            return true
          }
          uniqueStrategies.add(strategyName)
        }
      }
      return false
    }
    return false
  }, [allocationCardData, allocationId])

  const hasAllocationError = useMemo(() => {
    if (totalAllocation) {
      return totalAllocation !== 100 || totalAllocation > 100
    }
    return false
  }
  , [totalAllocation])

  // add error key in allocationCardData if below conditions matches
  useEffect(() => {
    const updatedAllocations = allocationCardData?.map((allocation) => {
      if (allocation.id === allocationId) {
        const rowHasError = allocation?.data?.some(row =>
          rowErrors?.[row.id]?.allocationError || rowErrors?.[row.id]?.strategyError
        )

        return {
          ...allocation,
          titleError: !allocationTitle,
          allocationError: hasAllocationError,
          inputFieldError: rowHasError,
          duplicateStrategyError: checkIfDuplicateStrategyExists
        }
      }
      return allocation
    })

    if (
      updatedAllocations?.some((data) => data?.titleError) ||
      updatedAllocations?.some((data) => data?.isNameEditMode) ||
      updatedAllocations?.some((data) => data.allocationError) ||
      updatedAllocations?.some((data) => data?.duplicateStrategyError) ||
      updatedAllocations?.some((data) => data.inputFieldError) ||
      updatedAllocations?.some((data) => data?.data?.length === 0) ||
      !updatedAllocations?.length
    ) {
      setAllStepsVisited(false)
    }

    if (allocationCardData && updatedAllocations && !areAllocationsEqual(allocationCardData, updatedAllocations)) {
      setAllocationCardData(updatedAllocations)
    }
  }, [rowErrors, allocationCardData, allocationId, hasAllocationError, allocationTitle, checkIfDuplicateStrategyExists])

  const areAllocationsEqual = (allocationCardData, updatedAllocations) => {
    return allocationCardData.length === updatedAllocations.length &&
      allocationCardData.every((allocation, index) =>
        allocation.id === updatedAllocations[index].id &&
        allocation.titleError === updatedAllocations[index].titleError &&
        allocation.allocationError === updatedAllocations[index].allocationError &&
        allocation.inputFieldError === updatedAllocations[index].inputFieldError &&
        allocation.duplicateStrategyError === updatedAllocations[index].duplicateStrategyError
      )
  }

  useEffect(() => {
    if (currAllocationData && currAllocationData?.length) {
      setAllocationCardData(
        allocationCardData?.map((allocation) =>
          allocation.id === allocationId && !allocation.allocationTitle
            ? {
                ...allocation,
                allocationTitle // Set default allocation title
              }
            : allocation
        )
      )
    }

    // cleanup function
    return () => {
      setAllocationCardData((prevCards) =>
        prevCards?.map((prev) =>
          prev.id === allocationId
            ? {
                ...prev,
                data: prev.data.filter(row => row.strategy && row.allocation) // remove rows with empty fields
              }
            : prev
        )
      )
    }
  }, [allocationTitle, allocationId])

  useEffect(() => {
    const updatedCardData = allocationCardData?.map((allocation) =>
      allocation.id === allocationId
        ? {
            ...allocation,
            isNameEditMode
          }
        : allocation
    )
    setAllocationCardData(updatedCardData)
  }, [isNameEditMode])

  const columns = [
    {
      field: 'strategy',
      headerName: 'Strategy',
      editable: true,
      flex: 2,
      renderCell: (params) => {
        return params.value?.strategyName || params?.value?.strategy
      },
      preProcessEditCellProps: (params) => {
        const hasError = !params?.props?.value
        setRowErrors((prevErrors) => ({
          ...prevErrors,
          [params.id]: {
            ...prevErrors[params.id],
            strategyError: hasError
          }
        }))
        return { ...params.props, error: hasError }
      },
      renderEditCell: (params) => (
        <CustomStrategyField
          params={params}
          currRowsStrategyList={currSelectedAllocation}
          loading={loading}
          strategies={strategies}
          error={rowErrors[params.id]?.strategyError}
        />
      ),
      renderHeader: (params) => {
        return (
          <Box sx={{
            fontFamily: 'Open Sans',
            color: '#74788d !important',
            fontSize: '14px',
            fontWeight: 600,
            display: 'flex',
            alignItems: 'center'
          }}
          >
            Strategy
            {checkIfDuplicateStrategyExists &&
              currSelectedAllocation &&
              currSelectedAllocation?.length
              ? (
                <CustomTooltipIcon action='error' text='Strategy should be unique.' />
                )
              : ''}
          </Box>
        )
      }
    },
    {
      field: 'allocation',
      headerName: 'Allocation',
      editable: true,
      flex: 1,
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      valueGetter: (params) => {
        return params?.row?.allocation ?? ''
      },
      renderCell: (params) => {
        return params?.value != null ? params?.value?.toString() : ''
      },
      preProcessEditCellProps: (params) => {
        const hasError = !params?.props?.value
        setRowErrors((prevErrors) => ({
          ...prevErrors,
          [params.id]: {
            ...prevErrors[params.id],
            allocationError: hasError
          }
        }))
        return { ...params.props, error: hasError }
      },
      renderEditCell: (params) => (
        <TextField
          type='number'
          variant='outlined'
          value={params.value !== null && params.value !== undefined && params.value !== '' ? params.value : ''}
          sx={{
            ml: 2,
            width: '100%',
            mr: 2,
            height: '40px'
          }}
          error={rowErrors[params.id]?.allocationError}
          InputProps={{
            inputProps: {
              type: 'number',
              min: 0,
              max: 100
            },
            sx: {
              height: '100%'
            }
          }}
          onChange={(event) => {
            const newValue = event.target.value
            if (isNumberWithLimitedDecimals(event)) {
              if (newValue === '' || (newValue >= 0 && newValue <= 100)) {
                params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue })
              }
            }
          }}
        />
      ),
      renderHeader: (params) => {
        return (
          <Box sx={{
            fontFamily: 'Open Sans',
            color: '#74788d !important',
            fontSize: '14px',
            fontWeight: 600,
            display: 'flex',
            alignItems: 'center'
          }}
          >
            Allocation
            {(totalAllocation > 100 ||
              totalAllocation < 100) &&
              totalAllocation &&
              currSelectedAllocation &&
              currSelectedAllocation?.length
              ? (
                <CustomTooltipIcon action='error' text='Total allocation weight should be 100.' />
                )
              : ''}
          </Box>
        )
      }
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      flex: 1,
      getActions: (params) => {
        const isInEditMode = rowModesModel[params?.id]?.mode === GridRowModes.Edit
        const currentRowValues = apiRef?.current?.getRowWithUpdatedValues(params?.id)
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              key='save'
              disabled={rowErrors[params.id]?.strategyError || rowErrors[params.id]?.allocationError || !currentRowValues?.strategy || !currentRowValues?.allocation}
              label='Save'
              sx={{ color: 'primary.main' }}
              onClick={handleSaveClick(params?.id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label='Cancel'
              key='cancel'
              className='textPrimary'
              onClick={handleCancelClick(params?.id)}
              color='inherit'
            />
          ]
        }
        return [
          // <GridActionsCellItem
          //   icon={<TuneIcon />}
          //   key='personalize'
          //   disabled={rowErrors[params.id]?.strategyError || rowErrors[params.id]?.allocationError}
          //   label='Personalize'
          //   color='inherit'
          //   onClick={handlePersonalizationClick(params?.id)}
          // />,
          <GridActionsCellItem
            icon={<EditIcon />}
            label='Edit'
            key='edit'
            className='textPrimary'
            onClick={handleEditClick(params?.id)}
            color='inherit'
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            key='delete'
            label='Delete'
            onClick={handleDeleteClick(params?.id)}
            color='inherit'
          />
        ]
      }
    }
  ]

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut || params.reason === GridRowEditStopReasons.escapeKeyDown || params.reason === GridRowEditStopReasons.enterKeyDown || params.reason === GridRowEditStopReasons.tabKeyDown) {
      event.defaultMuiPrevented = true
    }
  }

  const handleCellEditStart = (params, event) => {
    event.defaultMuiPrevented = true
  }

  const handleCellEditStop = (params, event) => {
    event.defaultMuiPrevented = true
  }

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
    setAllocationCardData(allocationCardData?.map((allocation) => {
      return {
        ...allocation,
        data: allocation?.data?.map((dataItem) => {
          if (dataItem.id === id) {
            return {
              ...dataItem,
              isInEditMode: true
            }
          }
          return dataItem
        })
      }
    }))
  }

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
    setAllocationCardData(allocationCardData?.map((allocation) => {
      return {
        ...allocation,
        data: allocation?.data?.map((dataItem) => {
          if (dataItem.id === id) {
            return {
              ...dataItem,
              isInEditMode: false
            }
          }
          return dataItem
        })
      }
    }))
  }

  const handleDeleteClick = (id) => () => {
    setAllocationCardData(allocationCardData.map((allocation) => {
      if (allocation.id === allocationId) {
        return {
          ...allocation,
          data: allocation.data.filter((row) => row.id !== id)
        }
      }
      return allocation
    }))
  }

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    })

    const editedRow = currSelectedAllocation?.find((row) => row.id === id)
    if (editedRow?.isNew) {
      setAllocationCardData(allocationCardData.map((allocation) => {
        if (allocation.id === allocationId) {
          return {
            ...allocation,
            data: allocation.data.filter((row) => row.id !== id)

          }
        }
        return allocation
      }))
    }

    setRowErrors((prevErrors) => ({
      ...prevErrors,
      [id]: {
        strategyError: false,
        allocationError: false
      }
    }))
  }

  const processRowUpdate = (newRow) => {
    if (newRow?.isNew && (newRow?.strategy === '' || newRow?.allocation === '')) {
      // Set the row mode to Edit and return the oldRow without performing the update
      setRowModesModel({ ...rowModesModel, [newRow?.id]: { mode: GridRowModes.Edit } })
      return currSelectedAllocation
    } else {
      const updatedRow = { ...newRow, isNew: false }

      const updatedAllocations = allocationCardData?.map((allocation) => {
        if (allocation?.id === allocationId) {
          return {
            ...allocation,
            data: allocation?.data?.map((row) => {
              if (row.id === newRow.id) {
                return updatedRow
              }
              return row
            }),
            isInEditMode: allocation.data.some(row => row.id !== newRow.id && rowModesModel[row.id]?.mode === GridRowModes.Edit)
          }
        }
        return allocation
      })

      setAllocationCardData(updatedAllocations)
      return updatedRow
    }
  }

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel)
  }

  // Handle title change and save
  const handleTitleChange = () => {
    const newTitle = tempAllocationTitle?.trim()
    if (!newTitle) {
      showError('Allocation Title is required')
      setIsNameEditMode(true)
      return
    }
    // If the title is not empty, save the title and close the edit mode
    setAllocationCardData(
      allocationCardData?.map((allocation) =>
        allocation.id === allocationId
          ? {
              ...allocation,
              allocationTitle: newTitle
            }
          : allocation
      )
    )
    setAllocationTitle(newTitle)
    setIsNameEditMode(false)
  }

  const closeNameEdit = () => {
    const originalTitle = currentAllocationTitle
    setTempAllocationTitle(originalTitle)
    setIsNameEditMode(false)
  }

  const handleAddStrategyClick = () => {
    const id = randomId()
    setAllocationCardData((prevCards) =>
      prevCards?.map((prev) =>
        prev.id === allocationId
          ? { ...prev, data: [...prev.data, { id, strategy: '', allocation: '', isNew: true, isInEditMode: true }] }
          : prev
      )
    )
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'strategy' }
    }))
  }

  // Effect to update the allocationCardData state when the personalization changes
  // useEffect(() => {
  //   if (personalizationData && allocationId && selectedRowId) {
  //     const updatedAllocations = allocationCardData?.map((allocation) => {
  //       if (allocation.id === allocationId) {
  //         const updatedData = allocation?.data?.map((item) => {
  //           if (item.id === selectedRowId) {
  //             return {
  //               ...item,
  //               personalizationData: { ...personalizationData }
  //             }
  //           }
  //           return item
  //         })
  //         return {
  //           ...allocation,
  //           data: updatedData
  //         }
  //       }
  //       return allocation
  //     })
  //     setAllocationCardData(updatedAllocations)
  //   }
  // }, [personalizationData, allocationId, selectedRowId])

  const copyAllocationModel = () => {
    const currentAllocationIndex = allocationCardData?.findIndex((data) => data?.id === allocationId)
    if (currentAllocationIndex > -1) {
      const updatedAllocations = [...allocationCardData]
      const newAllocationObject = {
        ...allocationCardData[currentAllocationIndex],
        id: randomId(),
        data: allocationCardData[currentAllocationIndex]?.data?.map(allocationObj => ({ ...allocationObj, id: randomId() })),
        isInEditMode: false,
        isNameEditMode: true
      }
      updatedAllocations.splice(currentAllocationIndex + 1, 0, newAllocationObject)
      setAllocationCardData([...updatedAllocations])
    }
  }

  return (
    <Card
      variant='outlined' sx={{
        marginBottom: 2,
        position: 'relative'
      }}
    >
      <CardHeader
        action={
          <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
            {
              expanded
                ? (
                  <Button color='primary' startIcon={<Add />} onClick={handleAddStrategyClick} disabled={totalAllocation >= 100}>
                    Add Strategy
                  </Button>
                  )
                : ''
            }
            {
              accountType === 'transition'
                ? <>
                  <Tooltip title='Copy Allocation Model'>
                    <span>
                      <IconButton
                        disabled={allocationCardData?.length >= 3 || totalAllocation !== 100 || checkIfDuplicateStrategyExists}
                        onClick={() => {
                          copyAllocationModel()
                        }}
                      >
                        <ContentCopyIcon sx={{ fontSize: '18px' }} />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title='Delete Allocation Model'>
                    <IconButton
                      onClick={() => {
                        setAllocationCardData(allocationCardData?.filter((allocation) => allocation.id !== allocationId))
                      }}
                    >
                      <DeleteIcon sx={{ fontSize: '20px' }} />
                    </IconButton>
                  </Tooltip>
                  <IconButton onClick={handleExpandClick}>
                    {expanded ? <ExpandLess sx={{ fontSize: '20px' }} /> : <ExpandMore sx={{ fontSize: '20px' }} />}
                  </IconButton>
                </>
                : ''
            }
          </Box>
        }
      />
      <Box sx={{ position: 'absolute', top: '16px', left: '20px' }}>
        {isNameEditMode
          ? (
            <Box sx={{ height: '32px', display: 'flex', position: 'relative' }}>
              <Box
                sx={{ position: 'relative' }}
                className={`border-animation ${!tempAllocationTitle.trim() ? 'error' : ''}`}
              >
                <input
                  onChange={(e) => setTempAllocationTitle(e.target.value)}
                  // save input value on click on enter
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      event.preventDefault()
                      handleTitleChange()
                    }
                  }}
                  value={tempAllocationTitle}
                  autoFocus
                  error={!tempAllocationTitle}
                  autoComplete='off'
                  id='aggregate-name-inp'
                  style={{
                    border: 'none',
                    outline: 'none',
                    boxShadow: 'none',
                    borderColor: 'transparent'
                  }}
                />
              </Box>
              <Box sx={{ display: 'flex' }}>
                <IconButton
                  onClick={handleTitleChange}
                  sx={{ p: '4px' }}
                  disabled={!tempAllocationTitle}
                >
                  <CheckIcon fontSize='small' />
                </IconButton>
                <IconButton onClick={closeNameEdit} sx={{ p: '4px' }}>
                  <ClearIcon fontSize='small' />
                </IconButton>
              </Box>
            </Box>
            )
          : (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '5px'
              }}
              id='groupName'
            >
              <Typography
                id='background-change-hover'
                onClick={() => {
                  setIsNameEditMode(true)
                  setTempAllocationTitle(allocationTitle)
                }}
                sx={{ cursor: 'pointer' }}
              >
                {allocationTitle === ''
                  ? ''
                  : allocationTitle || `Allocation ${defaultAllocationTitleId}`}
              </Typography>
              <IconButton
                id='edit-icon'
                onClick={() => {
                  setIsNameEditMode(true)
                  setTempAllocationTitle(allocationTitle)
                }}
              >
                <EditIcon fontSize='small' />
              </IconButton>
            </Box>
            )}
      </Box>
      <Divider />
      <>
        <Collapse in={expanded} timeout='auto' unmountOnExit>
          <CardContent sx={{ paddingTop: '3px' }}>
            <DataGridPro
              autoHeight
              rows={currSelectedAllocation || []}
              columns={columns}
              hideFooter
              sx={{
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none !important'
                },
                '& .MuiDataGrid-cell--editing': {
                  outline: 'none !important'
                }
              }}
              getRowId={(row) => row?.id}
              editMode='row'
              rowModesModel={rowModesModel}
              apiRef={apiRef}
              onRowModesModelChange={handleRowModesModelChange}
              onRowEditStop={handleRowEditStop}
              processRowUpdate={processRowUpdate}
              onColumnWidthChange={() => handleAllocationsTableChange()}
              onColumnOrderChange={() => handleAllocationsTableChange()}
              onCellEditStart={handleCellEditStart}
              onCellEditStop={handleCellEditStop}
              onSortModelChange={() => handleAllocationsTableChange()}
              disableRowSelectionOnClick
              initialState={{
                ...allocationGridState?.initialState,
                pagination: { paginationModel: { pageSize: 5 } }
              }}
            />
          </CardContent>
        </Collapse>
        {/* {isModalShown && selectedStrategyPersonalization && (
          <PersonalizationModal
            isShown={isModalShown}
            setIsShown={handleCloseModal}
            strategy={selectedStrategyPersonalization}
            setPersonalizationData={setPersonalizationData}
            personalization={personalizationData}
          />
        )} */}
      </>
    </Card>
  )
}

export default AllocationModelCards
