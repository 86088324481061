import React, { useEffect, useState } from 'react'
import { Box, Link } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'
import { FormPasswordReset } from './components/FormPasswordReset'
import { MfaSetup } from './components/MfaSetup'
import { MfaVerificationCodeLogin } from './components/MfaVerificationCodeLogin'
import './mfa.css'

const MfaQRCode = (props) => {
  const [user, setUser] = useState(props?.user || {})

  useEffect(() => {
    setUser(props?.user)
  }, [])

  const updateUser = (user) => {
    setUser(user)
  }

  return (
    <>
      {
        user?.challengeName === 'MFA_SETUP' || user?.challengeName === undefined
          ? <MfaSetup user={user} action='MFA_TOTP_SETUP' optProps={props}/>
          : user?.challengeName === 'SOFTWARE_TOKEN_MFA'
            ? <MfaVerificationCodeLogin user={user} action='MFA_LOGIN' optProps={props} />
            : user?.challengeName === 'NEW_PASSWORD_REQUIRED'
              ? <FormPasswordReset user={user} action='NEW_PASSWORD' updateUser={updateUser} />
              : user?.preferredMFA === 'NOMFA'
                ? <p style={{ marginTop: '20px' }}>MFA is not enabled, contact administrator</p>
                : <p style={{ marginTop: '20px' }}>Contact administrator to enabled MFA</p>
      }
      <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2 }}>
        <Link
          onClick={props.handleBackToLogin}
          sx={{
            display: 'flex',
            cursor: 'pointer',
            justifyContent: 'center',
            alignItems: 'center',
            fontFamily: 'Open Sans',
            color: '#464F5E',
            fontWeight: '500',
            fontSize: '14px',
            alignSelf: 'center',
            textTransform: 'none',
            textDecoration: 'none',
            '&:hover': {
              color: '#464F5E'
            }
          }}
        >
          <ArrowBack sx={{ fontSize: '14px', mr: 0.5 }} />
          Back to log in
        </Link>
      </Box>
    </>
  )
}
export default MfaQRCode
