import React, { useCallback, useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import { CloudUpload as CloudUploadIcon } from '@mui/icons-material'
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import DownloadDoneIcon from '@mui/icons-material/DownloadDone'
import DownloadingIcon from '@mui/icons-material/Downloading'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Box, Button, Card, CardContent, Grid, IconButton, Menu, MenuItem } from '@mui/material'
import { DataGridPro } from '@mui/x-data-grid-pro'
import * as Sentry from '@sentry/react'
import { useAuth } from '../../../contexts/AuthContext'
import { useSponsorIdContext } from '../../../contexts/IPSSponsorContext'
import { useErrorToast } from '../../../hooks/useErrorToast'
import Loader from '../../Loader'
import ActionModal from './components/ActionModal'
import DeleteDocument from './components/DeleteDocument'
import DownloadDocument from './components/DownloadDocument'
import StatusTimeline from './components/StatusTimeline'
import { UploadModal } from './components/UploadModal'
import { ViewModal } from './components/ViewModal'

import { useIpsAccess } from '../../../hooks/useIpsAccess'

export const Statement = () => {
  const { user } = useAuth()
  const { showError } = useErrorToast()
  const { selectedSponsorId } = useSponsorIdContext()
  const [commentRequests, setCommentRequests] = useState([])
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [downloadingIds, setDownloadingIds] = useState([])
  const [downloadedIds, setDownloadedIds] = useState([])
  const [activeRow, setActiveRow] = useState(null)
  const [loading, setLoading] = useState(false)
  const [rows, setRows] = useState([])
  const [viewModalOpen, setViewModalOpen] = useState(false)
  const [docImportUrl, setDocImportUrl] = useState({})
  const [openModal, setOpenModal] = useState(false)
  const [openDownloadModal, setOpenDownloadModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [activeDownloadRow, setActiveDownloadRow] = useState(null)
  const [isCommentLoading, setIsCommentLoading] = useState(false)
  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState([])
  const statementEditAccess = useIpsAccess('Statement')

  const columns = [
    {
      field: 'documentId',
      headerName: 'Document ID',
      flex: 1,
      renderCell: (params) => (
        <>
          <Button
            endIcon={downloadingIds.includes(params.row?.requestId) && !downloadedIds.includes(params.row?.requestId)
              ? <DownloadingIcon sx={{ fontSize: 18, color: 'black' }} />
              : downloadedIds.includes(params.row?.requestId)
                ? <DownloadDoneIcon sx={{ fontSize: 18, color: 'green' }} />
                : <FileDownloadOutlinedIcon
                    sx={{
                      fontSize: 18,
                      color:
                      (params.row?.requestStatusCd === 'CANCELLED' || params.row?.requestStatusCd === 'REJECTED') ? '#F05F5F' : '#4B8CCA'
                    }}
                  />}
            variant='text'
            sx={{ color: '#000000DE', fontWeight: 600 }}
            onClick={() => downloadingIds.includes(params.row?.requestId) ? '' : user?.userGroup === 'pm' ? handleDownloadModal(params.row) : handleDownload(params.row)}
          >
            {`DOCU${params.row?.userRequestId?.substring(4)}`}
          </Button>
        </>)
    },

    { field: 'userRequestId', headerName: 'Request ID', flex: 1 },
    { field: 'requestStatus', headerName: 'Request Status', flex: 1 },
    { field: 'documentStatus', headerName: 'Document Status', flex: 1 },
    {
      field: 'startDate',
      headerName: 'Start Date',
      flex: 1,
      valueFormatter: (params) =>
        params.value ? new Date(params.value).toDateString() : '--------'
    },
    {
      field: 'endDate',
      headerName: 'End Date',
      flex: 1,
      valueFormatter: (params) =>
        params.value ? new Date(params.value).toDateString() : '--------'
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      maxWidth: 80,
      align: 'center',
      renderCell: (params) => (
        <>
          <IconButton
            aria-label='more'
            id={`long-button-${params.row?.requestId}`}
            aria-controls={activeRow?.requestId === params.row?.requestId ? `long-menu-${params.row?.requestId}` : undefined}
            aria-expanded={activeRow?.requestId === params.row?.requestId ? 'true' : undefined}
            aria-haspopup='true'
            onClick={(event) => {
              setAnchorEl(event.currentTarget)
              setActiveRow(params.row)
            }}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id={`long-menu-${params.row?.requestId}`}
            MenuListProps={{
              'aria-labelledby': `long-button-${params.row?.requestId}`
            }}
            anchorEl={anchorEl}
            open={activeRow?.requestId === params.row?.requestId}
            onClose={() => { setActiveRow(null); setAnchorEl(null) }}
            place
            PaperProps={{
              style: {
                width: '17ch'
              }
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            sx={{
              padding: 0,
              '.MuiMenuItem-root': {
                padding: '0 10px'
              },
              '.MuiButtonBase-root': {
                justifyContent: 'flex-start',
                textTransform: 'capitalize'
              }
            }}
          >
            <MenuItem>
              <Button
                fullWidth
                variant='text'
                sx={{
                  color: 'rgba(0, 0, 0, 0.87)'
                }}
                onClick={() => handleView(params.row)}
                startIcon={
                  <ChatOutlinedIcon
                    sx={{ fontSize: '8px' }}
                  />
                }
              >View Comments
              </Button>
            </MenuItem>
            <MenuItem>
              <Button
                fullWidth
                variant='text'
                sx={{
                  color: 'rgba(0, 0, 0, 0.87)'
                }}
                disabled={
                 (user?.userGroup !== 'pm' && !statementEditAccess
                   ? (params.row?.requestStatusCd !== 'PENDING_SIGNATURE')
                   : (params.row?.requestStatusCd === 'CANCELLED' ||
                  params.row?.requestStatusCd === 'REJECTED' ||
                   params.row?.requestStatusCd === 'APPROVED'))
}
                onClick={() => handleOpenModal(params.row)}
                startIcon={
                  <CloudUploadIcon
                    sx={{ fontSize: '8px' }}
                  />
                }
              >
                {((user?.userGroup !== 'pm' && statementEditAccess && params.row?.documentStatusCd === 'REVIEW') || (user?.userGroup === 'pm' && params.row?.documentStatusCd === 'REVIEW_COMPLETED')) ? 'Re-upload' : 'Upload'}
              </Button>
            </MenuItem>
            <MenuItem>
              <Button
                fullWidth
                sx={{
                  color: 'red'
                }}
                variant='text'
                disabled={!statementEditAccess || (params.row?.requestStatusCd === 'CANCELLED' || params.row?.requestStatusCd === 'REJECTED' || params.row?.requestStatusCd === 'APPROVED')}
                onClick={() => handleOpenDeleteModal()}
                startIcon={
                  <DeleteOutlineIcon
                    sx={{ fontSize: '8px' }}
                  />
                }
              >{user?.userGroup === 'pm' ? 'Reject' : 'Cancel'}
              </Button>
            </MenuItem>
          </Menu>
        </>
      )
    }
  ]

  function getRowId (row) {
    return `${row?.requestId}`
  }

  const getCommentRequests = (requestId) => {
    setIsCommentLoading(true)
    API
      .get('baseRequestURL', `request/v1/${user.userGroup}/ips/comments/${requestId}`, { queryStringParameters: { page: 1, perPage: 10 } })
      .then(response => {
        if (response.data) {
          setCommentRequests(response.data.commentData)
        }
      })
      .catch(error => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => setIsCommentLoading(false))
  }

  useEffect(() => {
    if (user && selectedSponsorId?.sponsorId && selectedSponsorId?.sponsorId !== '-1') {
      setIsLoading(true)
      getStatementData()
    }
  }, [selectedSponsorId?.sponsorId, user])

  const handleDownload = async (row, documentType = '') => {
    if (!downloadingIds.includes(row?.requestId)) {
      try {
        setDownloadingIds(prevData => [...prevData, row?.requestId])
        await API.get('baseUriSubAdvisoryPolicy', `sub-advisory-policy/v1/${user.userGroup}/ips/${row?.requestId}/statements`)
          .then(async (response) => {
            const body = {
              sponsorCd: selectedSponsorId?.sponsorCd || '',
              sponsorName: selectedSponsorId?.sponsorName || '',
              documentId: row.documentId || '',
              documentType: documentType !== '' ? documentType : 'LATEST',
              policyGroups: response.data || []
            }
            await API.post(
              'baseUriSubAdvisoryPolicy',
              `sub-advisory-policy/v1/${user.userGroup}/ips/policy-document/${selectedSponsorId?.sponsorId}${user.userGroup === 'adv-classic' ? `?editAccess=${statementEditAccess}` : ''}`,
              {
                responseType: 'text',
                body
              }
            )
              .then((response) => {
                const base64Response = response
                const byteCharacters = atob(base64Response)
                const byteNumbers = new Array(byteCharacters.length)
                for (let i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i)
                }
                const byteArray = new Uint8Array(byteNumbers)
                const blobData = new Blob([byteArray], { type: 'application/octet-stream' })
                const url = window.URL.createObjectURL(new Blob([(blobData)]))
                const fileLink = document.createElement('a')
                fileLink.href = url
                fileLink.download = 'Sub-advisory-policy-statement.pdf'
                document.body.appendChild(fileLink)
                fileLink.click()
                URL.revokeObjectURL(url)
                document.body.removeChild(fileLink)
                // Remove request id from download inprogress list
                setDownloadingIds(prevData => prevData.filter(id => id !== row?.requestId))
                // Add request id into download completed list
                setDownloadedIds(prevData => [...prevData, row?.requestId])
                // Change document status of statement list for spn-admin
                if (statementEditAccess) {
                  setRows(prevData => prevData.map(data => data.documentId === row.documentId
                    ? ({
                        ...data,
                        documentStatus: 'Pending Admin Signature',
                        documentStatusCd: 'PENDING_ADMIN_SIGNATURE'
                      })
                    : data))
                  // Collapse expanded row on any action performed
                  setDetailPanelExpandedRowIds(prevData => prevData.filter(requestId => requestId !== row?.requestId))
                }
                // Remove download completed after 3s and show download document option
                setTimeout(() => {
                  setDownloadedIds(prevData => prevData.filter(id => id !== row?.requestId))
                }, 3000)
              })
          })
      } catch (error) {
        setDownloadingIds(prevData => prevData.filter(id => id !== row?.requestId))
        showError(error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      }
    }
  }

  const handleOpenDeleteModal = () => {
    setShowDeleteModal(true)
  }

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false)
  }

  const getDetailPanelContent = useCallback(
    ({ row }) => <StatusTimeline row={row} />,
    []
  )

  const getDetailPanelHeight = useCallback(() => 'auto', [])

  const handleView = (row) => {
    setActiveRow(row)
    getCommentRequests(row?.requestId)
    setViewModalOpen(true)
  }

  const handleViewModalClose = () => {
    setActiveRow(null)
    setCommentRequests([])
    setViewModalOpen(false)
  }

  const handleDownloadModal = (row) => {
    setActiveDownloadRow(row)
    setOpenDownloadModal(true)
  }

  const handleDownloadModalClose = () => {
    setActiveDownloadRow(null)
    setOpenDownloadModal(false)
  }

  const handleOpenModal = (row) => {
    setActiveRow(row)
    getDOCImportUrl(row.documentId)
    setOpenModal(true)
  }
  const getStatementData = async (page = 1) => {
    API.get('baseRequestURL', `request/v1/${user.userGroup}/ips/requests/${selectedSponsorId?.sponsorId}`, { queryStringParameters: { page, 'per-page': 100 } })
      .then(response => {
        if (response.data) {
          setIsLoading(true)
          setRows(response?.data?.reqData || [])
        }
      }).catch(error => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => setIsLoading(false))
  }

  const getDOCImportUrl = (documentId) => {
    API.get('baseUriSubAdvisoryPolicy', `sub-advisory-policy/v1/${user.userGroup}/ips/policy-document-import/${selectedSponsorId?.sponsorId}/${documentId}`)
      .then(response => {
        if (response.data) {
          setDocImportUrl(response.data)
        }
      }).catch(error => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
  }
  const handleCloseModal = () => {
    setActiveRow(null)
    setOpenModal(false)
  }

  const deleteStatement = () => {
    setLoading(true)
    API.patch(
      'baseRequestURL',
      `request/v1/${user.userGroup}/${selectedSponsorId?.sponsorId}/policy-requests`, { body: { comment: `Request ${user.userGroup === 'pm' ? 'rejected' : 'cancelled'}` } }
    )
      .then((response) => {
        if (response.data?.requestData && response.data?.requestData?.length) {
          setRows(prevData => prevData.map(data => data.documentId === response.data?.requestData[0]?.documentId
            ? ({
                ...data,
                endDate: response.data?.requestData[0]?.endDate,
                requestStatusCd: user.userGroup === 'pm' ? 'REJECTED' : 'CANCELLED',
                requestStatus: user.userGroup === 'pm' ? 'Rejected' : 'Cancelled',
                documentStatus: 'Inactive',
                documentStatusCd: 'INACTIVE'
              })
            : data))
        }
        // Collapse expanded row on any action performed
        setDetailPanelExpandedRowIds(prevData => prevData.filter(requestId => requestId !== response.data?.requestData[0]?.requestId))
        setActiveRow(null)
        handleCloseDeleteModal()
      })
      .catch(error => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => setLoading(false))
  }

  const handleUploadFile = (requestId) => {
    setRows(prevData => prevData.map(data => data.userRequestId === requestId ? ({ ...data, requestStatusCd: 'IN_APPROVAL', requestStatus: 'In approval' }) : data))
    handleCloseModal()
    // Collapse expanded row on any action performed
    setDetailPanelExpandedRowIds(prevData => prevData.filter(requestId => requestId !== activeRow?.requestId))
    setActiveRow(null)
    setTimeout(() => {
      getStatementData(1)
    }, 2000)
  }

  const handleDetailPanelExpandedRowIdsChange = useCallback((newIds) => {
    setDetailPanelExpandedRowIds(newIds)
  }, [])

  return (
    <>
      {
        isLoading
          ? <Loader />
          : <>
            <Box>
              {!isLoading
                ? (selectedSponsorId?.sponsorId !== '-1'
                    ? (!rows?.length)
                        ? <p style={{ marginTop: '10px' }}>No data found</p>
                        : (<Grid container>
                          <Grid xs={12}>
                            <Grid container>
                              <Grid item xs={12}>
                                <Grid sm={12}>
                                  <Grid item sm={12}>
                                    <Card className='card-layout'>
                                      <CardContent>
                                        <Box
                                          style={{ height: 650, width: '100%' }}
                                          sx={{
                                            '.MuiDataGrid-footerContainer': {
                                              border: 'none'
                                            },
                                            '.MuiDataGrid-root': {
                                              border: 'none'
                                            }
                                          }}
                                        >
                                          <DataGridPro
                                            columns={columns}
                                            rows={rows}
                                            getRowId={getRowId}
                                            disableRowSelectionOnClick
                                            getDetailPanelContent={getDetailPanelContent}
                                            getDetailPanelHeight={getDetailPanelHeight}
                                            detailPanelExpandedRowIds={detailPanelExpandedRowIds}
                                            onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
                                            pageSizeOptions={[10]}
                                            initialState={{
                                              pagination: { paginationModel: { pageSize: 10 } }
                                            }}
                                            pagination
                                            sx={{
                                              fontFamily: 'Open Sans',
                                              '& .MuiDataGrid-columnHeaderTitle': {
                                                fontWeight: 600,
                                                fontFamily: 'Open Sans',
                                                color: '#34475A'
                                              },
                                              '& .MuiDataGrid-virtualScroller': {
                                                overflowX: 'visible'
                                              }
                                            }}
                                          />

                                        </Box>
                                      </CardContent>
                                    </Card>

                                  </Grid>
                                </Grid>
                                <ActionModal openModal={openModal} handleCloseModal={handleCloseModal}>
                                  <UploadModal data={docImportUrl} requestId={activeRow?.userRequestId || ''} updateData={handleUploadFile} />
                                </ActionModal>
                                <ActionModal openModal={viewModalOpen} handleCloseModal={handleViewModalClose} width={800}>
                                  <ViewModal commentRequests={commentRequests} selectedItem={activeRow || {}} sponsorCd={selectedSponsorId?.sponsorCd} loading={isCommentLoading} />
                                </ActionModal>
                                <ActionModal openModal={showDeleteModal} handleCloseModal={handleCloseDeleteModal}>
                                  <DeleteDocument handleCloseDeleteModal={handleCloseDeleteModal} deleteStatement={deleteStatement} loading={loading} />
                                </ActionModal>
                                <ActionModal openModal={openDownloadModal} handleCloseModal={handleDownloadModalClose}>
                                  <DownloadDocument selectFileType={(type) => { handleDownload(activeDownloadRow, type); handleDownloadModalClose() }} />
                                </ActionModal>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>)
                    : (user?.userGroup === 'pm' && <p style={{ marginTop: '10px' }}>Select sponsor</p>))
                : ''}
            </Box>
            </>
      }
    </>
  )
}
