import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    accountId: '',
    optId: '',
    identifier: '',
    reportType:'',
    apiData: {}
}

const templateDataSlice = createSlice({
    name: 'templateData',
    initialState,
    // action as functions
    reducers: {
        storeTemplateData(state, action) {
            state.accountId = action.payload.accountId
            state.optId = action.payload.optId
            state.identifier = action.payload.identifier
            state.reportType = action.payload.reportType
            state.apiData = action.payload.apiData
        }
    }
})

export const { storeTemplateData } = templateDataSlice.actions
export default templateDataSlice.reducer