import { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { API } from 'aws-amplify'
import { Box, Button, Card, CardContent, Grid, Typography, Skeleton } from '@mui/material'
import { DataGridPro } from '@mui/x-data-grid-pro'
import * as Sentry from '@sentry/react'
import { useAuth } from '../contexts/AuthContext'
import { useErrorToast } from '../hooks/useErrorToast'
import CustomKeyFieldSearch from '../components/CustomKeyFieldSearch'
import AddIcon from '@mui/icons-material/Add'
import { moduleConfig } from '../contexts/data'
import { ACCESS_LEVEL } from '../contstants/constants'

const AllStrategyList = ({ resource }) => {
  const location = useLocation()
  const [allStrategy, setAllStrategy] = useState([])
  const [allStrategyCopy, setAllStrategyCopy] = useState([])
  const { user, checkAccess } = useAuth()
  const { showError } = useErrorToast()
  const navigate = useNavigate()
  const [currentPage, setCurrentPage] = useState(0)
  const [Loading, setLoading] = useState(true)

  useEffect(() => {
    if (user) {
      getAllStrategy()
    }
  }, [user, resource])
  
  const getAllStrategy = () => {
    setLoading(true)
    API.get('baseStrategyURL', `strategy/v1/${user.userGroup}/strategy-master`,
      {
        queryStringParameters: {
          resources: encodeURIComponent(JSON.stringify({
            serviceId: 'model',
            resourceId: resource
          }))
        }
      })
      .then((response) => {
        if (response.data) {
          setAllStrategy(response.data)
          setAllStrategyCopy(response?.data)
          const storeArray = []
          response?.data?.forEach((element) => {
            storeArray.push({
              strategyName: element?.strategyName,
              strategyCd: element?.strategyCd,
              strTypeCode: element?.strTypeCode,
              strategyId: element?.strategyId
            })
          })
          localStorage.setItem('strategies', JSON.stringify(storeArray))
        }
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
  }

  const handleButtonClick = () => {
    navigate('/model/strategy-onboarding')
  }

  const renderSkeletonRows = () => {
    const skeletonRows = Array.from({ length: 4 }, (_, index) => (
      <tr key={index}>
        <td>
          <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
        </td>
        <td>
          <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
        </td>
        <td>
          <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
        </td>
      </tr>
    ))
    return skeletonRows
  }

  return (
    <Box className='import-portfolio'>
      <Typography className='page-title' mb={2}>
        {
          !location?.pathname?.startsWith('/model/model-changes')
            ? 'Select Strategy to Upload Portfolio'
            : 'Select Strategy to View Model Changes'
        }
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card className='card-layout'>
            <CardContent>
              {Loading
                ? <Box className='table-responsive'>
                  <table>
                    <thead>
                      <tr>
                        <th>Strategy Name</th>
                        <th>Strategy Name</th>
                        <th>Strategy Type</th>
                      </tr>
                    </thead>
                    <tbody>{renderSkeletonRows()}</tbody>
                  </table>
                  </Box>
                : <Box
                    style={{ width: '100%' }}
                    sx={{
                      '.MuiDataGrid-columnHeaderTitle': {
                        color: '#74788D',
                        fontFamily: 'Open Sans',
                        fontWeight: 600
                      },
                      '.MuiDataGrid-cellContent': {
                        fontFamily: 'Open Sans',
                        color: '#34475A',
                        fontWeight: 600
                      },
                      '.MuiDataGrid-footerContainer': {
                        border: 'none'
                      },
                      '.MuiDataGrid-root': {
                        border: 'none'
                      },
                      '.MuiTablePagination-toolbar': {
                        alignItems: 'baseline'
                      }
                    }}
                  >
                  {
                    checkAccess(moduleConfig.MODEL, ACCESS_LEVEL.SUB_MODULE_ACCESS,
                      { subModuleName: moduleConfig.STRATEGY_ONBOARDING })
                      ? (
                          !location?.pathname?.startsWith('/model/model-changes')
                            ? <Button startIcon={<AddIcon />} variant='outlined' color='primary' onClick={handleButtonClick} sx={{ position: 'absolute', zIndex: 1 }}>
                              Create Strategy
                              </Button>
                            : ''
                        )
                      : null
                  }
                  <DataGridPro
                    autoHeight
                    rows={allStrategy}
                    columns={[
                      { field: 'strategyName', headerName: 'Strategy Name', flex: 1, renderCell: (params) => <Link className='linkId' style={{ fontWeight: 600 }} to={`${params.row.strategyId}`}>{params.value || 'N/A'}</Link> },
                      { field: 'strategyCd', headerName: 'Strategy Code', flex: 1, renderCell: (params) => <span className='linkId' style={{ fontWeight: 600 }}>{params.value || 'N/A'}</span> },
                      { field: 'strTypeCode', headerName: 'Strategy Type', flex: 1, renderCell: (params) => <span className='linkId' style={{ fontWeight: 600 }}>{params.value ? params.value?.charAt(0)?.toUpperCase() + params.value?.slice(1)?.toLowerCase() : 'N/A'}</span> }
                    ]}
                    getRowId={(row) => row.strategyId}
                    pagination
                    pageSizeOptions={[10]}
                    onPaginationModelChange={(event) => setCurrentPage(event.page)}
                    paginationModel={{ pageSize: 10, page: currentPage }}
                    initialState={{
                      pagination: { paginationModel: { pageSize: 10, page: currentPage } }
                    }}
                    slots={{
                      toolbar: CustomKeyFieldSearch
                    }}
                    slotProps={{ toolbar: { filterData: (data) => { setAllStrategy(data); setCurrentPage(0) }, fieldName: 'strategyName', tableData: allStrategyCopy, fieldLabel: 'Strategy Name' } }}
                    sx={{
                      fontFamily: 'Open Sans',
                      '& .MuiDataGrid-columnHeaderTitle': {
                        fontWeight: 600,
                        fontFamily: 'Open Sans',
                        color: '#74788D',
                        fontSize: '14px'
                      },
                      '& .MuiDataGrid-columnHeaderTitleContainerContent': {
                        fontWeight: 600,
                        fontFamily: 'Open Sans',
                        color: '#34475A'
                      },
                      '.MuiDataGrid-cellContent': {
                        fontFamily: 'Open Sans',
                        color: '#34475A',
                        fontWeight: '600 !important'
                      }
                    }}
                  />
                  </Box>}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}

export default AllStrategyList
