export const validateExpression = (expression) => {
  // const pattern = /^B[+-](?:3(?:\.0+)?|[0-2](?:\.\d+)?|\.\d+)$/
  // Remove the prefix if it exists
  const value = expression.replace(/^[Bb][+-]/, '')

  // Check if the value is within the range -3 to 3
  if (/^-?(?:3(?:\.0+)?|[012](?:\.\d+)?)$/.test(value)) {
    return false // Valid expression
  } else {
    return true // Invalid expression
  }
}
