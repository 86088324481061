import { Box, Button, Tooltip } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { DateRangePicker } from '@mui/x-date-pickers-pro'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { RefreshOutlined } from '@mui/icons-material'

const DateRangePickerWithCustomActionBar = ({ hidden, fetchData, date, setDate, prevDate, setPrevDate }) => {

  const handleRefreshClick = () => {
    if (!date || (date?.length === 2 && !date[0] && !date[1])) {
      // fetch api without date if no date or invalid date
      fetchData()
      return
    }
    // fetch api with date
    const fromDateData = date[0].format('YYYY-MM-DD')
    const toDateData = date[1].format('YYYY-MM-DD')
    if (fromDateData && toDateData)
      fetchData(fromDateData, toDateData)
  }

  return (
    hidden
      ?
      <>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateRangePicker
            onClose={() => setDate(prevDate)}
            value={date}
            onChange={setDate}
            format='YYYY-MM-DD'
            closeOnSelect={false}
            calendars={1}
            slots={{ actionBar: CustomActionBar }}
            slotProps={{
              textField: { size: 'small' },
              actionBar: {
                actions: ['accept', 'clear'],
                submit: fetchData,
                date,
                setPrevDate,
              }
            }}
            sx={{
              '.MuiMultiInputDateRangeField-separator': {
                marginLeft: '8px'
              },
              '.MuiTextField-root:last-child': {
                marginLeft: '8px'
              },
              '.MuiInputBase-input': {
                fontSize: '14px'
              },
              '.MuiInputLabel-root': {
                fontSize: '14px'
              },
              width: '300px'
            }}
            localeText={{ start: 'From Date', end: 'To Date' }}
          />
        </LocalizationProvider>
        <Tooltip title={'Refresh'} placement='top'>
          <Button
            variant='outlined'
            onClick={handleRefreshClick}
            sx={{
              border: '2px solid #dee2e6',
              paddingInline: '10px',
              minWidth: 'auto',
              ':hover': {
                background: 'transparent',
                border: '2px solid #dee2e6'
              }
            }}
          >
            <RefreshOutlined sx={{ height: 20, width: 20, color: '#74788D' }} />
          </Button>
        </Tooltip>
      </>
      : ''
  )
}

const CustomActionBar = (props) => {
  const { onAccept, onClear, className, submit, date, setPrevDate } = props

  const handleSubmit = (date) => {
    onAccept()
    if (date === undefined) {
      onClear()
      submit()
      return
    }
    if (date?.length !== 2 || !date[0] || !date[1])
      return
    const fromDateData = date[0].format('YYYY-MM-DD')
    const toDateData = date[1].format('YYYY-MM-DD')
    if (fromDateData && toDateData) {
      submit(fromDateData, toDateData)
      setPrevDate(date)
    }
  }

  const handleClearClick = () => {
    setPrevDate([null, null])
    // fetch data without date
    handleSubmit()
  }

  return <Box className={className} sx={{ display: 'flex', justifyContent: 'flex-end', padding: '10px', gap: '10px' }}>
    <Button
      onClick={() => handleClearClick()}>
      clear
    </Button>
    <Button
      variant='contained'
      onClick={() => handleSubmit(date)}>
      Submit
    </Button>
  </Box>
}

export default DateRangePickerWithCustomActionBar