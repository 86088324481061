import { Box, Typography } from '@mui/material'
import React from 'react'
import { Doughnut } from 'react-chartjs-2'

const GaugeChart = ({ value, maxValue }) => {
  const data = {
    datasets: [
      {
        data: [value, maxValue - value],
        backgroundColor: ['#3BBFA3', 'rgba(114, 116, 116, 0.5)'],
        borderWidth: 0
      }
    ]
  }

  const options = {
    cutout: 40,
    cutoutPercentage: 40,
    rotation: 225,
    circumference: 270,
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false
    },
    tooltips: {
      enabled: false
    },
    plugins: {
      datalabels: {
        display: false
      }
    }
  }

  const textCenter = {
    id: 'textCenter',
    beforeDatasetsDraw (chart, args, pluginOptions) {
      const { ctx, data } = chart
      // Save the context
      ctx.save()

      // Set the font size for the first line
      ctx.font = '24px Open Sans'
      ctx.textBaseline = 'middle'
      ctx.textAlign = 'center'

      // show the selected accounts
      ctx.fillText(data.datasets[0].data[0], chart.getDatasetMeta(0).data[0].x, chart.getDatasetMeta(0).data[0].y)

      // Set the font size for the second line
      ctx.font = '14px Open Sans' // Adjust the font size as needed
      const secondLineY = chart.getDatasetMeta(0).data[0].y + 20 // Adjust the Y position for the second line

      // show the total number of accounts
      ctx.fillText(`out of ${maxValue}`, chart.getDatasetMeta(0).data[0].x, secondLineY)

      // Restore the context
      ctx.restore()
    }
  }

  return (
    <>
      <Box sx={{ height: '120px' }}>
        <Doughnut data={data} options={options} plugins={[textCenter]} height='120px' />
        <Typography sx={{ color: '#2F2F2F', textAlign: 'center', fontFamily: 'Open Sans', fontSize: '14px', fontWeight: '600' }}>No. of Accounts</Typography>
      </Box>
    </>
  )
}

export default GaugeChart
