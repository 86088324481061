import { FormatDateAPI, FormatPayload } from '../components/FormatAPIRequest'
import { CreateAPI } from '../components/Actions'
import { getUserRole } from '../../../utils/getUserRole'

export const CreateStrategyData = async (newRow, tabIndex) => {
  switch (tabIndex) {
    case 'strategy-parameter':
      return await CreateStrategyParameter(newRow)
    case 'strategy-benchmark-map':
      return await CreateStrategyBenchmarkMap(newRow)
    case 'strategy-instrument-restriction':
      return await CreateStrategyInstrumentRestriction(newRow)
    case 'strategy-properties-master':
      return await CreateStrategyPropertyMaster(newRow)
    case 'strategy-benchmark-master':
      return await CreateStrategyBenchmarkMaster(newRow)
    default:
      return {}
  }
}

const CreateStrategyParameter = async (newRow) => {
  const userRole = getUserRole()
  const startDateValue = newRow.startDate ? FormatDateAPI(newRow.startDate) : null
  const endDateValue = newRow.endDate ? FormatDateAPI(newRow.endDate) : null
  let payload = {
    strategyId: newRow.strategyId,
    minCashLevel: newRow.minCashLevel,
    isWeightStatic: newRow.isWeightStatic,
    startDate: startDateValue,
    endDate: endDateValue
  }

  payload = FormatPayload(payload)
  return CreateAPI(
    'baseStrategyMaintainURL',
    `data-maintenance/v1/${userRole}/strategies/strategy-parameter`,
    payload)
}

const CreateStrategyBenchmarkMap = async (newRow) => {
  const userRole = getUserRole()
  const startDateValue = newRow.startDate ? FormatDateAPI(newRow.startDate) : null
  const endDateValue = newRow.endDate ? FormatDateAPI(newRow.endDate) : null

  let payload = {
    strategyId: newRow.strategyId,
    benchmarkId: newRow.benchmarkId,
    startDate: startDateValue,
    endDate: endDateValue
  }

  payload = FormatPayload(payload)
  return CreateAPI(
    'baseStrategyMaintainURL',
    `data-maintenance/v1/${userRole}/strategies/benchmark-map`,
    payload)
}

const CreateStrategyInstrumentRestriction = async (newRow) => {
  const userRole = getUserRole()
  const startDateValue = newRow.startDate ? FormatDateAPI(newRow.startDate) : null
  const endDateValue = newRow.endDate ? FormatDateAPI(newRow.endDate) : null

  let payload = {
    strategyId: newRow.strategyId,
    instrumentId: newRow.instrId,
    restrictionId: newRow.restrictionId,
    startDate: startDateValue,
    endDate: endDateValue
  }

  payload = FormatPayload(payload)
  return CreateAPI(
    'baseStrategyMaintainURL',
    `data-maintenance/v1/${userRole}/strategies/instrument-restriction`,
    payload)
}

const CreateStrategyPropertyMaster = async (newRow) => {
  const userRole = getUserRole()
  const startDateValue = newRow.startDate ? FormatDateAPI(newRow.startDate) : null
  const endDateValue = newRow.endDate ? FormatDateAPI(newRow.endDate) : null

  let payload = {
    strategyId: newRow.strategyId,
    propertyCode: newRow?.propertyCode?.toUpperCase(),
    propertyValue: newRow.propertyValue,
    startDate: startDateValue,
    endDate: endDateValue
  }

  payload = FormatPayload(payload)
  return CreateAPI(
    'baseStrategyMaintainURL',
    `data-maintenance/v1/${userRole}/strategies/properties`,
    payload)
}

const CreateStrategyBenchmarkMaster = async (newRow) => {
  const userRole = getUserRole()
  let payload = {
    bmkCode: newRow?.bmkCode?.toUpperCase(),
    longName: newRow.longName,
    shortName: newRow.shortName,
    indexProxyInstrId: newRow.indexProxyInstrId || null,
    bmkTypeCode: newRow?.bmkTypeCode?.toUpperCase(),
    bmkSource: newRow.bmkSource,
    bmkSourceId: newRow.bmkSourceId
  }

  payload = FormatPayload(payload)
  return CreateAPI(
    'baseStrategyMaintainURL',
    `data-maintenance/v1/${userRole}/strategies/bench-master`,
    payload)
}
