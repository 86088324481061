import { getUserRole } from '../../../../utils/getUserRole'
import { DeleteAPI } from '../../components/Actions'

export const DeleteDirectIndexingData = async (deleteRow, tabIndex) => {
  switch (tabIndex) {
    case 'type-code':
      return await DeleteStrategyTypeCode(deleteRow.strTypeId)
    default:
      return {}
  }
}

const DeleteStrategyTypeCode = async (strTypeId) => {
  const userRole = getUserRole()
  return DeleteAPI(
    'baseStrategyMaintainURL',
    `data-maintenance/v1/${userRole}/strategies/type-codes/${strTypeId}`)
}
