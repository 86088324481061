import React, { useEffect, useState } from 'react'
import './pageFooter2.css'
import { useDispatch, useSelector } from 'react-redux'
import { fetchDataAndDispatch } from '../APIResponseConverts/APIResponseConverts'
import { accountDataApi } from '../../../../utils/pdf-customizer/_data'
import { accountData } from '../APIResponseConverts/chartDefaultsData'
import { upDatePageHeadingFooter } from '../../../../store/pdf-customizer-reducer/Action/chartDropAction'
import ImageDisplay from '../../common/ImageDisplay'
import { useAuth } from '../../../../contexts/AuthContext'

const PageFooter2 = ({
  data,
  chartIndex,
  index,

  chartHeight,
  chartWidth,
  clickEvent
}) => {
  const dispatch = useDispatch()
  const { accountDataResponse, isResponseElements } = useSelector(
    (state) => state.chartState
  )
  const templateData = useSelector(state => state.templateData)
  const { pageHeadingFooter } = useSelector((state) => state.elementStates)
  const { value } = pageHeadingFooter
  const [isLoading, setIsLoading] = useState(false)
  const { user } = useAuth()

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isResponseElements && !accountDataResponse && !isLoading) {
          setIsLoading(true)
          await fetchDataAndDispatch(
            () => accountDataApi(templateData, user?.userGroup),
            'ACCOUNT_DATA',
            dispatch,
            true
          )
        } else if (isResponseElements && accountDataResponse) {
          setIsLoading(false)
        } else {
          if (!value) {
            setIsLoading(false)
            const data = { ...accountData?.data, allUpdate: true }
            dispatch(upDatePageHeadingFooter(data))
          }
        }
      } catch (error) {
        console.error('Error fetching and dispatching data:', error)
      }
    }

    fetchData()
  }, [isResponseElements, accountDataResponse, dispatch, value])

  return (
    <div>
      <div className='headr_2_main_div'>
        {
          isLoading
            ? 'Loading...'
            : <ul className='headr_2'>
              <li className='part_1'>
                <ImageDisplay
                imageUrl={
                    value.companyLogoUrl ||
                    'https://aris-public-assets-dev.s3.us-east-2.amazonaws.com/ARIS+Logo/aris-investing-logo.svg'
                  }
                alt='Logo'
                width='100%'
                height='20px'
                style={{ height: '20px', width: '100%', objectFit: 'contain' }}
                pageIdx={data?.pageIndex}
                name={data.name}
                chartIdx={data?.chartIndex}
                svgContent={value.svgCode || null}
              />
              </li>
              <li className='part_2'>
                {value?.templateType || 'Transition Report'}
              </li>
              <li className='part_3'>{data?.pageIndex + 1}</li>
            </ul>
        }
      </div>
    </div>
  )
}

export default PageFooter2
