import React, { useEffect, useState } from 'react'
import { Badge, IconButton, Tooltip } from '@mui/material'
import { API } from 'aws-amplify'
import { useSelector } from 'react-redux'
import * as Sentry from '@sentry/react'
import { ViewPolicyCheck } from '../components/PolicyCheck'
import PolicyCheckShield from '../assets/images/Policy-check-shield.svg'
import { useAuth } from '../contexts/AuthContext'
import { useErrorToast } from '../hooks/useErrorToast'

const PolicyCheckTop = () => {
  const [showModal, setShowModal] = useState(false)
  const { user } = useAuth()
  const [policyBreakColor, setPolicyBreakColor] = useState(null)
  const { accountData } = useSelector((state) => state.dashboard)
  const { showError } = useErrorToast()

  const handlePolicyCheck = () => {
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  useEffect(() => {
    if (user) {
      fetchPolicyBreakStatus()
    }
  }, [accountData])

  const fetchPolicyBreakStatus = async () => {
    if (accountData && accountData.length) {
      try {
        const queryParams = {}
        // if (user?.userGroup === 'adv-classic') {
        //   const accountIds = (accountData || []).map((account) => account.accountId)
        //   queryParams.account_id = accountIds
        // }
        const response = await API.get(
          'baseDataValidation',
          `data-validation/v1/${user?.userGroup}/policies/break-color`,
          { queryStringParameters: queryParams }
        )
        if (response && response.data && response.data.policyCheckColor) {
          setPolicyBreakColor(response.data.policyCheckColor)
        }
      } catch (error) {
        showError(error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      }
    }
  }

  return (
    <>
      <Tooltip title="Policy Check">
          <IconButton sx={{ mr: '-10px' }} onClick={handlePolicyCheck}>
        <Badge
          badgeContent=''
          sx={{
            '.MuiBadge-badge': {
              display: policyBreakColor === 'red' ? 'block' : 'none',
              height: '8px',
              minWidth: '8px',
              padding: '0',
              backgroundColor: '#FF5D5D',
              top: '6px',
              right: '6px'
            }
          }}
        >
          <img src={PolicyCheckShield} alt='policy-check' height={22} />
        </Badge>
      </IconButton>
        </Tooltip>
      <ViewPolicyCheck showModal={showModal} closeModal={handleCloseModal} />
    </>
  )
}

export default PolicyCheckTop
