import React, { useRef } from 'react'
import Chart from 'react-apexcharts'
import moveSVGIcon from '../../assets/images/move.svg'
import homeSVGIcon from '../../assets/images/home.svg'
import zoomSVGIcon from '../../assets/images/zoom-in.svg'
import exportMenuSVGIcon from '../../assets/images/export-menu.svg'

const colors = ['#2A96CE', '#009445', '#1C75BC', '#002A59', '#6A86A6', '#F7941E', '#F15A2B', '#D7F422', '#7B5231', '#2962FF']

const BenchmarkComparisonChart = ({ data, resetKey, strategyList, selectedAccountIds, isTransitionAccount }) => {
  const chartRef = useRef(null)
  const series = data?.length && data[0]
    ? Object.keys(data[0])?.filter(key => key !== 'x')?.sort((a, b) => {
      // 'accPreTax' will come first, regardless of the alphabetical order of other keys
      if (a === 'accPreTax') return -1
      if (b === 'accPreTax') return 1
      return a.localeCompare(b) // Alphabetical order for other keys
    })?.map((seriesKey, index) => {
      return {
        name: seriesKey === 'accPreTax'
          ? 'Account Pre Tax Returns'
          : seriesKey === 'y'
            ? 'Benchmark Pre Tax Returns'
            : (
              (strategyList || [])?.find(strategy => strategy?.strategyId === seriesKey)?.strategyName ||
              (selectedAccountIds || [])?.find(account => account?.accountId === seriesKey)?.accountName ||
              'Benchmark Pre Tax Returns'
            ),
        data: data?.map((point) => ({ x: point?.x, y: point[seriesKey] !== null ? (point?.[seriesKey] || 0) * 100 : point?.[seriesKey] }))
      }
    })?.filter((mappedSeries) => isTransitionAccount ? mappedSeries.name !== 'Account Pre Tax Returns' : true)
    : []

  const maxY = series?.length ? Math.max(...series?.flatMap(point => point.data.flatMap(obj => obj.y))) : null
  const options = {
    chart: {
      offsetX: -15,
      animations: {
        enabled: false
      },
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: -35,
        tools: {
          download: `<img src="${exportMenuSVGIcon}" />`,
          selection: true,
          zoom: `<img src="${zoomSVGIcon}"  />`,
          zoomin: false,
          zoomout: false,
          pan: `<img src="${moveSVGIcon}" />`,
          reset: `<img src="${homeSVGIcon}"  />`
        },
        export: {
          csv: {
            filename: undefined,
            columnDelimiter: ',',
            headerCategory: 'Date',
            headerValue: 'value',
            dateFormatter (timestamp) {
              const date = new Date(timestamp)
              const offset = date.getTimezoneOffset()
              const adjustedDate = new Date(date.getTime() + offset * 60000)
              const formattedDate = `${adjustedDate.getUTCFullYear()}-${(adjustedDate.getUTCMonth() + 1).toString().padStart(2, '0')}-${date.getUTCDate().toString().padStart(2, '0')}`
              return formattedDate
            }
          }
        }
      }
    },
    xaxis: {
      type: 'datetime',
      tooltip: {
        enabled: true
      }
    },
    yaxis: [
      {
        labels: {
          offsetX: 0,
          formatter: function (value) {
            return value?.toFixed(2)
          }
        },
        ...{ ...maxY !== null ? { max: maxY } : {} },
        tickAmount: 10
      }
    ],
    markers: {
      size: 0
    },
    dataLabels: {
      enabled: false
    },
    tooltip: {
      shared: true,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return ''
      }
    },
    stroke: {
      curve: 'straight',
      width: 1.8
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      position: 'left',
      horizontalAlign: 'left',
      floating: true,
      fontSize: '12px',
      offsetX: 40,
      offsetY: -10,
      markers: {
        width: 15,
        height: 2,
        radius: 0,
        offsetY: -4
      },
      tooltipHoverFormatter: function (seriesName, opts) {
        if (opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] >= 0) {
          return seriesName + ' - <span style="color: green;font-weight:600;">' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex]?.toFixed(2) + ' %' + '</span>'
        }
        return seriesName + ' - <span style="color: red;font-weight:600;">' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex]?.toFixed(2) + ' %' + '</span>'
      },
      onItemClick: {
        toggleDataSeries: false
      }
    },
    colors: series?.length
      ? series?.map((seriesObj, index) => colors[index] || '#002A59')
      : ['#2A96CE', '#009445', '#1C75BC', '#002A59', '#6A86A6', '#F7941E', '#F15A2B', '#D7F422', '#7B5231', '#2962FF'],
    noData: {
      text: 'No benchmark data available',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: '18px',
        fontFamily: undefined
      }
    },
    grid: {
      borderColor: '#74788d33',
      position: 'back'
    }
  }

  return <Chart ref={chartRef} options={options} series={series} type='line' height='100%' />
}

export default BenchmarkComparisonChart
