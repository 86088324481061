import React, { useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined'
import { Box, Card, CardContent, Grid, Link, Paper, Typography } from '@mui/material'
import { ThemeProvider, createTheme, styled } from '@mui/material/styles'
import { DataGridPro } from '@mui/x-data-grid-pro'
import * as Sentry from '@sentry/react'
import { useAuth } from '../../contexts/AuthContext'
import { useErrorToast } from '../../hooks/useErrorToast'

const theme = createTheme({
  typography: {
    fontFamily: 'Open Sans'
  }
})

theme.typography.h6 = {
  fontSize: '20px',
  '@media (min-width:600px)': {
    fontSize: '24px'
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '30px'
  }
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  boxShadow: 'none'
}))

const ModelSelectionTab = ({ setData, data, strategies, getStrategyList, loading }) => {
  const [selectedStrategy, setSelectedStrategy] = useState(data || {})
  const { user } = useAuth()
  const [pageSize, setPageSize] = React.useState(10)

  useEffect(() => {
    if (Array.isArray(strategies) && strategies.length === 0 && user) {
      getStrategyList()
    }
  }, [user, strategies])

  const columns = [
    { field: 'portSpecName', headerName: 'Portfolio Specification', flex: 2 },
    { field: 'strategyName', headerName: 'Strategy', flex: 2 },
    { field: 'count', headerName: 'Number of Accounts', flex: 1, type: 'number', align: 'right' },
    {
      field: 'fsLink',
      headerName: 'Fact Sheet',
      flex: 1,
      renderCell: (params) => {
        if (params.row.fsLink === '#') {
          return <span style={{ fontWeight: 600, color: '#34475A' }}>{params.row.fsName}</span>
        } else {
          return (
            <Link href={params.row.fsLink} target='_blank' rel='noopener noreferrer' style={{ textDecoration: 'none', cursor: 'pointer' }}>
              <PictureAsPdfOutlinedIcon
                underline='none'
                target='_blank'
                rel='noopener'
                sx={{ color: '#74788D', cursor: 'pointer' }}
                href={`${params.row.fsLink}`}
              >
                {params.row.fsName}
              </PictureAsPdfOutlinedIcon>
            </Link>
          )
        }
      }
    }
  ]

  const handleChange = (params) => {
    const selectedItem = strategies.filter((item) => item.id === params.id)
    setSelectedStrategy(selectedItem[0])
    setData(selectedItem[0])
  }

  const handlePageSizeChange = (newPageSize) => {
    // Update the state variable for pageSize
    setPageSize(newPageSize)
  }

  return (
    <Box sx={{ flexGrow: 1, mt: 2 }} className='modelSection'>
      <ThemeProvider theme={theme}>
        <Box className='trade-table-list-header' mb={2}>Model *</Box>
        <hr style={{ borderBottom: '1px solid #dee2e6', borderTop: '0' }} />
        <Grid container item spacing={3}>
          <Grid item xs={12} sm={9} order={{ xs: 2, sm: 1 }} mt={2}>
            <Grid container item spacing={3}>
              <Grid item xs={12} order={{ xs: 4, sm: 3 }}>
                <Card p={4} className='card-layout'>
                  <CardContent sx={{ backgroundColor: 'white', marginTop: '-0.8em' }}>
                    <Grid xs={12}>
                      <Box
                        sx={{
                          '.MuiDataGrid-footerContainer': {
                            border: 'none'
                          },
                          '.MuiDataGrid-root': {
                            border: 'none'
                          },
                          '.MuiTablePagination-toolbar': {
                            alignItems: 'baseline'
                          },
                          '.MuiDataGrid-columnHeaderTitle': {
                            fontFamily: 'Open Sans',
                            color: '#74788D',
                            fontWeight: 600
                          },
                          '.MuiDataGrid-cellContent': {
                            fontFamily: 'Open Sans',
                            color: '#34475A',
                            fontWeight: 600
                          }
                        }}
                      >
                        {/* there is a glitch when clicked on the pagination dropdown, the width of the table decreases */}
                        <DataGridPro
                          autoHeight
                          rows={strategies}
                          columns={columns}
                          pagination
                          loading={loading}
                          pageSizeOptions={[pageSize]}
                          initialState={{
                            ...strategies?.initialState,
                            pagination: { paginationModel: { pageSize } }
                          }}
                          onRowClick={handleChange}
                        />
                      </Box>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={3} order={{ xs: 1, sm: 2 }} mt={2}>
            <Grid container item spacing={3}>
              <Grid item xs={12} order={{ xs: 2, sm: 2 }}>
                <Item>
                  <Grid xs={12} sm={12}>
                    <Card sx={{ minWidth: '100%' }} className='model-card'>
                      <CardContent sx={{ p: 0 }}>
                        <Grid xs={12} sm={12}>
                          <Box sx={{ px: 2, pt: 1.5, pb: 0 }}>
                            <Typography variant='outlined' sx={{ fontSize: 16, color: '#34475A', fontWeight: 600 }}>
                              Selected Strategy
                            </Typography>
                          </Box>
                        </Grid>
                        <hr style={{ borderBottom: '1px solid #dee2e6', borderTop: '0' }} />
                        <Grid xs={12} sm={12} style={{ color: '#34475A' }}>
                          <Box ml={2} pb='5px'>
                            <Typography variant='outlined' className='modelCardSub'>
                              Portfolio Specification: {selectedStrategy.portSpecName ? selectedStrategy.portSpecName : 'N/A'}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid xs={12} sm={12} mt={1} style={{ color: '#34475A' }}>
                          <Box ml={2} pb='5px'>
                            <Typography variant='outlined' className='modelCardSub'>
                              Strategy: {selectedStrategy?.strategyName ? selectedStrategy.strategyName : 'N/A'}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid xs={12} sm={12} mt={1} style={{ color: '#34475A' }}>
                          <Box ml={2}>
                            <Typography variant='outlined' className='modelCardSub'>
                              Number of accounts: {selectedStrategy?.count || 0}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid xs={12} sm={12} mt={1} style={{ color: '#34475A' }}>
                          <Box ml={2}>
                            <Typography variant='outlined' className='modelCardSub'>
                              Number of securities: {selectedStrategy?.numStocks ? selectedStrategy?.numStocks : 'N/A'}
                            </Typography>
                          </Box>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Item>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ThemeProvider>
    </Box>
  )
}

export default ModelSelectionTab
