import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { Box, Card, CardContent, Grid, LinearProgress, Typography, linearProgressClasses, styled, IconButton } from '@mui/material'
import redirect from '../../../assets/images/pop-out-line.svg'

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: '9px',
  width: '70px',
  borderRadius: '0.375rem',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8'
  }
}))

const AccountRequests = ({ data, noData }) => {
  const params = useParams()
  return (
    <Card sx={{ height: '100%' }}>
      <CardContent>
        <Grid container justifyContent='space-between' alignItems={'center'}>
          <Grid item>
            <Typography className='text-title header-text' sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <span>Requests</span>
            </Typography>
          </Grid>
          <Grid item display='flex' alignItems='center'>
            <Typography className='text-title' mr={2}>Fulfilled</Typography>
            <Typography className='text-title' sx={{ color: '#175cc2 !important' }}>{data ? `${data.filter((item) => item?.requestStatus === 'Fulfilled')?.length}` : ''}</Typography>
            <Typography className='text-title'>{data ? `/${data?.length}` : ''}</Typography>
            <BorderLinearProgress variant='determinate' value={data ? `${(data.filter((item) => item.requestStatus === 'Fulfilled')?.length / data.length) * 100}` : ''} className='request-progress' />
            <Link to={`/account-review/request/${params.accountId}`} className='linkId' style={{ marginLeft: '8px' }}>
              <Box sx={{ display: 'flex', alignItems: 'end', justifyContent: 'end', marginLeft: 'auto' }}>
                <IconButton
                  className='tooltip-trade'
                  sx={{ marginTop: '-3px' }}
                >
                  <img src={redirect} alt='' />
                  <span
                    className='tooltiptext'
                  >
                    All Requests
                  </span>
                </IconButton>
              </Box>
            </Link>
          </Grid>
        </Grid>
        {data?.length
          ? <div className='request-list'>
            {noData}
            {
              data.map((item, index) => {
                return (
                  <Grid container key={index} mb={index < data?.length - 1 ? 1 : 0}>
                    <Grid item xs={12}>
                      <Box className='dashboard-comment'>
                        <Box display='flex' justifyContent='space-between' alignItems='center'>
                          <Typography component='h4' className='title' sx={{ fontSize: '14px' }}>{item.title}</Typography>
                          <div className={item.requestStatus === 'Pending' ? 'awaiting-badge' : 'completed-badge'} style={{ fontSize: '10px' }}>{item.requestStatus}</div>
                        </Box>
                        <Box display='flex' justifyContent='space-between' alignItems='center' mt={1}>
                          <Typography className='time'>{new Date(item.requestCreationDate).toLocaleString()}</Typography>
                          <Typography component='h5' className='text-title'>{item.requestType}</Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                )
              })
            }
            </div>
          : <Box sx={{ display: 'flex', justifyContent: 'center' }} m={7}>No Requests</Box>}
      </CardContent>
    </Card>
  )
}

export default AccountRequests
