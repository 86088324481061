import { getUserRole } from '../../../../utils/getUserRole'
import { GetAPI } from '../../components/Actions'

const typeCodeData = async () => {
  const userRole = getUserRole()
  return GetAPI(
    'baseStrategyMaintainURL',
    `data-maintenance/v1/${userRole}/strategies/type-codes`
  )
}

const derivedStrategyAPI = async (id, parameter) => {
  const userRole = getUserRole()
  return GetAPI(
    'baseStrategyMaintainURL',
    `data-maintenance/v1/${userRole}/strategies/${parameter}/${id}`
  )
}

export const formatDerivedWeightData = (data) => {
  return data?.flatMap(strategy => {
    return strategy.subStrategy.map(subStr => ({
      strategyId: strategy.strategyId,
      strategyName: strategy.strategyName,
      batchId: strategy.batchId,
      subStrId: subStr.subStrId,
      subStrategyName: subStr.subStrName,
      weight: (parseFloat((subStr.weight * 100).toFixed(4))),
      startDate: strategy.startDate,
      endDate: strategy.endDate
    }))
  })
}

export const GetDirectIndexingData = async (id) => {
  const apiCalls = [
    typeCodeData(),
    derivedStrategyAPI(id, 'derived-weight-aloc')
  ]
  const responses = await Promise.allSettled(apiCalls)

  const [
    typeCodeAPIData,
    derivedWeightAlocAPIData
  ] = responses

  return [
    typeCodeAPIData?.value,
    derivedWeightAlocAPIData?.value
      ? {
        ...derivedWeightAlocAPIData?.value,
        data: {
          ...derivedWeightAlocAPIData?.value?.data,
          data: formatDerivedWeightData(derivedWeightAlocAPIData?.value?.data?.data)
        }
      }
      : []]
}
