import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai'
import { headingChangeText } from '../../../store/pdf-customizer-reducer/Action/chartDropAction'
import './textEditing.css'
import { styled } from '@mui/material'

const TextArea = styled('textarea')(({ theme }) => ({
  outline: 'none',
  padding: '2px',
  width: '100%',
  resize: 'none',
  overflowY: 'hidden',
  fontFamily: 'inherit'
}))

const TextEditing = ({
  data,
  chartIndex,
  index,
  chartHeight,
  chartWidth,
  clickEvent,
  maxHeight
}) => {
  const dispatch = useDispatch()
  const [isDragging, setIsDragging] = useState(false)
  const [isInputMode, setIsInputMode] = useState(false)
  const [inputText, setInputText] = useState(data?.textShow)
  const textAreaRef = useRef(null)

  const chartStyle = {
    opacity: isDragging ? 0.1 : 1
  }

  const handleSaveClick = () => {
    setIsInputMode(false)
    if (inputText === '') {
      setInputText(data?.name)
    }
    dispatch(headingChangeText({ name: 'textShow', value: inputText || data?.name || '' }))
    // setInputText(textAreaRef.current.textContent)
  }

  const onCancel = () => {
    setIsInputMode(false)
    if (inputText === '') {
      setInputText(data?.name)
    }
    setInputText(data?.textShow)
  }

  // resize text area
  const resizeTextArea = () => {
    if (textAreaRef.current && textAreaRef.current.scrollHeight <= maxHeight) {
      textAreaRef.current.style.height = 'auto'
      textAreaRef.current.style.height = textAreaRef.current.scrollHeight + 'px'
    } else if (textAreaRef.current && textAreaRef.current.scrollHeight > maxHeight) {
      setInputText(prevState => prevState.slice(0, -1))
    }
  }

  useEffect(resizeTextArea, [inputText])

  const onChange = (e) => {
    setInputText(e.target.value)
  }

  useEffect(() => {
    if (isInputMode) {
      textAreaRef.current.focus()
      textAreaRef.current.innerText = inputText
      // move caret to the end of text
      const selection = window.getSelection()
      const range = document.createRange()
      range.selectNodeContents(textAreaRef.current)
      range.collapse(false)
      selection.removeAllRanges()
      selection.addRange(range)
      resizeTextArea()
    }
  }, [isInputMode])

  useEffect(() => {
    resizeTextArea()
  }, [data?.value])

  const handleDragEnd = () => setIsDragging(false)
  return (
    <>
      <div
        className='heading'
        key={data?.chartIndx + data?.pageIndex}
        style={chartStyle}
        // draggable
        // onDragStart={handleDragStart}
        // onDragEnd={handleDragEnd}
      >
        <div>
          <div onDoubleClick={(e) => setIsInputMode(true)}>
            {
              isInputMode
                ? <>
                  <div style={{ position: 'relative', width: '100%', paddingTop: '20px' }}>
                    <div style={{ position: 'absolute', right: 0, top: '0px' }}>
                      <AiFillCheckCircle
                        color='#727474'
                        size={15}
                        style={{ marginInline: '2px', cursor: 'pointer' }}
                        onClick={handleSaveClick}
                      />
                      <AiFillCloseCircle color='#727474' style={{ marginInline: '2px', cursor: 'pointer' }} size={15} onClick={onCancel} />
                    </div>
                    <TextArea
                      name='text'
                      id='text'
                      ref={textAreaRef}
                      value={inputText}
                      onChange={onChange}
                      style={{ ...data?.value || {}, textAlign: data?.value?.justifyContent || 'left', color: data.name === 'Paragraph Text' ? '#000' : 'inherit' }}
                      rows={1}
                    />
                  </div>
                </>
                : <p
                    className={data?.name === 'Paragraph Text' ? 'paragraph-text' : 'title-text'}
                    style={{ ...data?.value || {}, textAlign: data?.value?.justifyContent || 'left', color: 'inherit' }}
                  >
                  {/* <span className={'transparent-border'} style={{ ...{ ...data.name === 'Paragraph Text' ? { color: '#000' } : {} } }}> */}
                    {inputText}
                  {/* </span> */}
                </p>
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default TextEditing
