import React, { useContext, useEffect, useState } from 'react'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { Box, Divider, Grid, Tab } from '@mui/material'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallback from '../../ErrorFallback'
import { tradeListContext, portfolioReportDataContext, accountDataContext } from '../Index'
import { Allocation } from './Allocation'
import { Performance } from './Performance'
import { Policy } from './Policy'
import { Portfolio } from './Portfolio'
import { SubTabs } from './SubTabs'
import { Summary } from './Summary'
import { TradeListTable } from './TradeListTable'
import { useSelector } from 'react-redux'
import { TransitionPortfolioTable } from './TransitionPortfolio'

export const ReportTab = (props) => {
  const { repTabs, selectedScenario } = props
  const accountData = useContext(accountDataContext)
  const tradeList = useContext(tradeListContext)
  const transitionPortfolioData = useContext(portfolioReportDataContext)
  const { expandedChartName } = useSelector(state => state.user)
  const [value, setValue] = useState(expandedChartName && expandedChartName !== '' ? repTabs?.findIndex(key => key === 'Performance') : 0)
  const screen = 'not main'
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const renderTabPanel = (item) => {
    switch (item) {
      case 'Summary':
        return (
          <ErrorBoundary fallbackRender={(props) => (<ErrorFallback {...props} screen={screen} />)}>
            <Summary selectedScenario={selectedScenario} />
          </ErrorBoundary>
        )
      case 'Allocation':
        return (
          <ErrorBoundary fallbackRender={(props) => (<ErrorFallback {...props} screen={screen} />)}>
            <Allocation selectedScenario={selectedScenario} />
          </ErrorBoundary>
        )
      case 'Performance':
        return (
          <ErrorBoundary fallbackRender={(props) => (<ErrorFallback {...props} screen={screen} />)}>
            <Performance />
          </ErrorBoundary>
        )
      case 'Trade List':
        return (
          <ErrorBoundary fallbackRender={(props) => (<ErrorFallback {...props} screen={screen} />)}>
            <TradeListTable tableHeader='Trade List' columns={tradeList?.fieldLabels} rows={tradeList?.tradeListData} />
          </ErrorBoundary>
        )
      case 'Policy':
        return (
          <ErrorBoundary fallbackRender={(props) => (<ErrorFallback {...props} screen={screen} />)}>
            <Policy />
          </ErrorBoundary>
        )
      case 'Methodology':
        return (
          <ErrorBoundary fallbackRender={(props) => (<ErrorFallback {...props} screen={screen} />)}>
            <SubTabs />
          </ErrorBoundary>
        )
      case 'Portfolio':
        return (
          <ErrorBoundary fallbackRender={(props) => (<ErrorFallback {...props} screen={screen} />)}>
            {accountData?.accountType === 'TRANSITION'
              ? <TransitionPortfolioTable data={transitionPortfolioData} />
              : <Portfolio />}
          </ErrorBoundary>
        )
      default:
        return 'Something went wrong'
    }
  }

  useEffect(() => {
    if (expandedChartName && expandedChartName !== '') {
      setValue(repTabs?.findIndex(key => key === 'Performance'))
    }
  }, [expandedChartName])
  return (
    <Grid container xs={12} sm={12}>
      <Grid xs={12} sm={12}>
        <Box
          sx={{
            flexGrow: 1,
            maxWidth: { xs: '100%' },
            bgcolor: 'white'
          }}
        >
          <TabContext value={value}>
            <TabList
              onChange={handleChange}
              variant='scrollable'
              scrollButtons={false}
              className='report-tab-header'
              id='report-tabs'
              TabIndicatorProps={{
                style: {
                  backgroundColor: '#34475A'
                }
              }}
            >
              {repTabs.map((item, index) => (
                <Tab className='reportCardNav' key={index} value={index} label={item} indicatorColor='#34475A' />
              ))}
            </TabList>
            <Divider sx={{
              bgcolor: 'rgba(116, 120, 141, 0.15)'
            }}
            />
            {repTabs.map((item, index) => (
              <TabPanel
                key={index}
                value={index}
                sx={{ p: item === 'Methodology1' ?? -24 }}
              >
                {renderTabPanel(item)}
              </TabPanel>
            ))}
          </TabContext>
        </Box>
      </Grid>
    </Grid>
  )
}
