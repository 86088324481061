import React, { useState } from 'react'
import ChartLabel from './ChartLabel'
import { useDispatch, useSelector } from 'react-redux'
import { upDatePageHeadingFooter } from '../../../../store/pdf-customizer-reducer/Action/chartDropAction'
import ImageHolderEdit from './ImageHolderEdit'

const HeadingEditComponent = ({ activeChart }) => {
  const dispatch = useDispatch()
  const { pageHeadingFooter } = useSelector((state) => state.elementStates)
  const { value } = pageHeadingFooter

  const [editModes, setEditModes] = useState({
    templateType: false,
    accountName: false,
    textFooter: false
  })
  const handleToggleEditMode = (label) => {
    setEditModes((prevEditModes) => ({
      ...prevEditModes,
      [label]: !prevEditModes[label]
    }))
  }
  const handleToggleSaveMode = (value, label) => {
    setEditModes((prevEditModes) => ({
      ...prevEditModes,
      [label]: !prevEditModes[label]
    }))
    dispatch(upDatePageHeadingFooter({ value, label }))
  }

  const getLabels = () => {
    if (activeChart.name.startsWith('Page Heading')) {
      return activeChart.name.startsWith('Page Heading 1')
        ? ['templateType', 'accountName']
        : ['accountName']
    } else if (activeChart.name.startsWith('Page Footer 2') || activeChart.name.startsWith('Page Footer 3')) {
      return ['templateType']
    } else {
      return ['textFooter']
    }
  }
  if (activeChart?.name?.startsWith('Page Footer 3')) {
    return ''
  }
  
  return (
    <div>
      {getLabels().map((label) => (
        <ChartLabel
          key={label}
          value={value[label]}
          isEditing={editModes[label]}
          onSave={(value) => handleToggleSaveMode(value, label)}
          onEdit={() => handleToggleEditMode(label)}
          onCancel={() => handleToggleEditMode(label)}
        />
      ))}
      {
        (activeChart?.name?.startsWith('Page Footer 1') || activeChart?.name?.startsWith('Page Footer 3')) ? '' :
          <ImageHolderEdit isUploadImage />
      }
    </div>
  )
}

export default HeadingEditComponent
