import React, { useEffect, useState } from 'react'
import Stack from '@mui/material/Stack'
import { TabDetails } from './components/TabDetails'
import { GetRequestData } from './Request/GetRequestData'
import { GetAccountData } from './Account/GetAccountData'
import { DataFormatting } from './components/DataFormatting'
import { GetStrategyData } from './Strategy/GetStrategyData'
import { GetSponsorData } from './Sponsor/GetSponsorData'
import { GetReportData } from './Report/GetReportData'
import { GetInstrumentData } from './Instrument/GetInstrumentData'
import { GetTradeData } from './Trade/GetTradeData'
import Loader from '../Loader'
import { tabNameData } from './TabData'
import { GetDirectIndexingData } from './Strategy/DI/GetDirectIndexingData'
import { GetOptimizationData } from './Optimization/GetOptimizationData'
import { GetMasterData } from './Master/GetMasterData'

export const requestDataContext = React.createContext()

export const DataMaintenance = (props) => {
  const { name, id } = props
  const [tabComponents, setTabComponents] = useState(null)
  const [responseData, setResponseData] = useState({})
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    const fetchTabComponent = async () => {
      let responseDataFormat = []
      switch (name) {
        case 'strategy':
          if (id !== '-' && id !== '') {
            responseDataFormat = await GetStrategyData(id)
          }
          break
        case 'strategyAllocation':
          if (id !== '-' && id !== '') {
            responseDataFormat = await GetDirectIndexingData(id)
          }
          break
        case 'request':
          responseDataFormat = await GetRequestData()
          break
        case 'account':
          if (id !== '-' && id !== '') {
            responseDataFormat = await GetAccountData(id)
          }
          break
        case 'sponsor':
          responseDataFormat = await GetSponsorData()
          break
        case 'report':
          responseDataFormat = await GetReportData()
          break
        case 'instrument':
          responseDataFormat = await GetInstrumentData()
          break
        case 'trade':
          responseDataFormat = await GetTradeData()
          break
        case 'optimization':
          responseDataFormat = await GetOptimizationData()
          break
        case 'master':
          responseDataFormat = await GetMasterData()
          break
        // Add more cases as needed
        default:
          return null
      }
      const formattedResponseData = DataFormatting(responseDataFormat, tabNameData[name])
      setResponseData(formattedResponseData)
      setLoading(false)
    }

    fetchTabComponent()
  }, [name])

  useEffect(() => {
    if (Object.keys(responseData).length > 0) {
      const tabComponent = (
        <requestDataContext.Provider value={{ responseData, setResponseData, dataKey: name, activeId: id }}>
          <TabDetails name={name} />
        </requestDataContext.Provider>
      )
      setTabComponents(tabComponent)
    }
  }, [responseData])

  return (
    <>
      {loading
        ? <Loader />
        : (
          <Stack>
            {tabComponents}
          </Stack>
          )}
    </>
  )
}
