import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { API, Auth } from 'aws-amplify'
import * as AWS from 'aws-sdk'
import { Box, Button, Fade, Menu, MenuItem, Modal, Typography } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { roles } from '../contstants/constants'
import Loader from '../pages/Loader'
import { useLogo } from '../contexts/SponsorLogoContext'
import { useErrorToast } from '../hooks/useErrorToast'
import { useAuth } from '../contexts/AuthContext'
import { clearStore } from '../store/dashboard-reducer/dashboard-reducer'
import { storeSponsorList, storeUserGroup } from '../store/user-reducer/user-reducer'
import { useMenuConfig } from '../hooks/useMenuConfig'

// cognitoIdentity and loginsKey from aws
const cognitoIdentity = new AWS.CognitoIdentity({
  region: process.env.REACT_APP_IDENTITY_POOL_REGION
})

const loginsKey = `cognito-idp.${process.env.REACT_APP_REGION}.amazonaws.com/${process.env.REACT_APP_USER_POOL_ID}`

const SwitchUserTop = () => {
  const [anchorElUser, setAnchorElUser] = useState(null)
  const dispatch = useDispatch()
  const { userGroup } = useSelector((state) => state.user)
  const { showError } = useErrorToast()
  const { storeSponsorLogo, handleSetLogoInnerHTML } = useLogo()
  const navigate = useNavigate()
  const sidebarMenuList = useMenuConfig()
  const { user, storeUser, checkCurrentRedirectRouteAccess, setUserAclData, setCurrPath, setIsAclFetching, userGroupArray, refreshACL } = useAuth()
  const [selectedGroup, setSelectedGroup] = useState((user && user?.userGroup) || localStorage.getItem('userGroup') || userGroup || '')
  const [loading, setLoading] = useState(false)
  const [userGroups, setUserGroups] = useState(userGroupArray || [])
  const [differentUserGroup, setDifferentUserGroup] = useState()
  const [showReloadModal, setShowReloadModal] = useState(false)
  const broadcastChannel = new BroadcastChannel('role-switch-sync')

  useEffect(() => {
    if (user?.sub && !userGroupArray?.length) {
      fetchUserGroups()
    }
  }, [user?.sub, userGroupArray])

  const fetchUserGroups = async () => {
    await API.get('baseUriUser', `user/v1/user-groups/${user?.sub}`)
      .then((data) => {
        setUserGroups(data?.data)
      })
      .catch((error) => {
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
  }

  useEffect(() => {
    // if the role is switched then set popup show to true
    if (differentUserGroup && (differentUserGroup !== selectedGroup)) { setShowReloadModal(true) } else { setShowReloadModal(false) }
  }, [differentUserGroup])

  broadcastChannel.onmessage = (e) => {
    // on getting message of role-switch, get the new role from message
    setDifferentUserGroup(e.data)
  }

  useEffect(() => {
    return () => {
      broadcastChannel.close()
    }
  }, [])

  const fetchACLAccessApi = async (props, retries = 2) => {
    let hasError = false
    setIsAclFetching(true)
    try {
      const res = await API.get(
        'baseAclURL',
        `user-access-control/v1/user/${props}`
      )
      if (res && res.success && res.data) {
        const aclData = res.data
        setUserAclData(aclData)
        localStorage.setItem('user-acl-data', JSON.stringify(aclData))
        setIsAclFetching(false)
        return aclData
      }
    } catch (error) {
      hasError = true
      Sentry.captureException(error?.response?.data?.errorInfo?.userMessage || error)
      setIsAclFetching(false)
    } finally {
      // if api fails for the first time retry it again
      if (hasError && retries > 0) {
        retries -= 1
        setTimeout(() => {
          fetchACLAccessApi(props, retries)
        }, 1000)
      } else if (hasError) {
        showError('Failed to load ACL')
      }
    }
  }

  const getCurrentRedirectionPath = (aclData) => {
    for (const data of sidebarMenuList) {
      // Compare menuConfig with the ACL to get first accessible path
      const serviceExists = aclData?.services?.find((service) => service?.Service === data?.moduleCd)
      if (serviceExists) {
        // MenuConfig paths without subMenus
        if (data?.path) {
          setCurrPath(data?.path ? data?.path : '/book-of-business')
          return data?.path ? data?.path : '/book-of-business'
        // MenuConfig subMenu paths
        } else if (data?.paths && data?.paths?.length > 0) {
          const accessiblePaths = data?.paths?.filter((path) =>
            checkCurrentRedirectRouteAccess(path?.path, aclData)
          )
          const path = accessiblePaths?.length > 0 ? accessiblePaths[0]?.path : '/book-of-business'
          setCurrPath(accessiblePaths?.length > 0 ? accessiblePaths[0]?.path : '/book-of-business')
          return path
        }
      }
    }
    return '/book-of-business'
  }

  const fetchLogoApi = (retries = 2) => {
    let hasError = false
    API.get('baseSponserURL', `data-maintenance/v1/logo-details/${user?.sub}`)
      .then(response => {
        if (response?.data) {
          if (Array.isArray(response.data) && response?.data?.length) {
            storeSponsorLogo(response.data[0])
            const favicon = document.getElementById('favicon')
            if (favicon) {
              favicon.href = response.data[0].favIconUrl ? response.data[0].favIconUrl : './ArisFavicon.svg'
            }
            const title = document.getElementById('title')
            if (title && response.data[0].sponsorName) {
              title.innerText = response.data[0].sponsorName ? response.data[0].sponsorName : 'Aris Investing'
            }
          } else {
            storeSponsorLogo(null)
            handleSetLogoInnerHTML()
          }
        }
      })
      .catch(error => {
        hasError = true
        showError(error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      }).finally(() => {
        // retry api call 2 more times if first api call fails
        if (hasError && retries > 0) {
          setTimeout(() => {
            fetchLogoApi(retries - 1)
          }, 1000)
        } else if (hasError && retries === 0) {
          storeSponsorLogo(null)
          handleSetLogoInnerHTML()
        }
      })
  }

  async function switchRoles (roleArn, groupName) {
    setLoading(true)
    try {
      const credentials = await Auth.currentUserCredentials()
      const currentUser = await Auth.currentAuthenticatedUser()
      if (currentUser) {
        const tokenID = currentUser?.signInUserSession?.idToken?.jwtToken
        const identityParams = {
          IdentityId: credentials.identityId,
          CustomRoleArn: roleArn,
          Logins: {
            [loginsKey]: tokenID
          }
        }

        let sessionData
        // get switched user role credentials from the identity params
        await cognitoIdentity?.getCredentialsForIdentity(identityParams)
          ?.promise().then((credentialData) => {
            sessionData = {
              accessKeyId: credentialData?.Credentials?.AccessKeyId,
              sessionToken: credentialData?.Credentials?.SessionToken,
              secretAccessKey: credentialData?.Credentials?.SecretKey,
              expireTime: credentialData?.Credentials?.Expiration,
              identityId: credentialData?.IdentityId,
              expired: false
            }
            Object.assign(credentials, sessionData)
            localStorage.setItem('userGroup', groupName)
            if (roleArn) {
              localStorage.setItem('roleArn', roleArn)
            }
            if (groupName === 'adv-classic' || groupName === 'pm') {
              fetchACLAccessApi(user.sub)
                .then((aclData) => {
                  const path = getCurrentRedirectionPath(aclData)
                  navigate(path)
                })
                .finally(() => {
                  setLoading(false)
                  storeUser({ ...user, userGroup: groupName, roleArn, userData: JSON.stringify(sessionData) })
                }).catch((error) => {
                  showError(error.message)
                  Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
                })
            } else {
              if (groupName === 'admin') {
                navigate('/admin/access-control')
                setLoading(false)
                storeUser({ ...user, userGroup: groupName, roleArn, userData: JSON.stringify(sessionData) })
              }
            }
          }).catch((error) => {
            showError(error.message)
            Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
          })
          .finally(() => {
            dispatch(clearStore('RESET'))
            localStorage.removeItem('object')
            dispatch(storeUserGroup(groupName))
            dispatch(storeSponsorList([]))
          })
        if (groupName === 'adv-classic') {
          refreshACL(user?.sub)
        } else if (groupName === 'pm') {
          refreshACL(user?.sub)
        }
        // setLoading(true)
        fetchLogoApi()
      }
    } catch (error) {
      if (error === 'The user is not authenticated' || error === 'Refresh Token has expired') {
        storeUser(null)
        dispatch(clearStore('RESET'))
        dispatch(storeUserGroup(''))
        navigate('/login')
        setLoading(false)
      }
      showError((error === 'The user is not authenticated' || error === 'Refresh Token has expired') ? 'Your session has expired. Please re-login' : error)
      Sentry.captureException(error)
    } finally {
      setSelectedGroup(groupName)
      handleCloseUserMenu()
    }
  }

  const handleUserGroupChange = async (groupName) => {
    if (groupName !== selectedGroup) {
      const userArn = userGroups?.find((group) => group?.groupName === groupName)?.groupArn
      await switchRoles(userArn, groupName)
      // when switching the role, send the message to other tabs that role is switched to `${groupName}`
      broadcastChannel.postMessage(groupName)
    }
  }

  const handleReloadClick = async (e) => {
    // change the role on click of reload button and set new role
    const userArn = userGroups?.find((group) => group?.groupName === differentUserGroup)?.groupArn
    await switchRoles(userArn, differentUserGroup)
    setShowReloadModal(false)
    setSelectedGroup(differentUserGroup)
  }

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget)
    document.body.classList.add('model-open')
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
    document.body.classList.remove('model-open')
  }

  return (
    <>
      {showReloadModal
        ? (
          <Box sx={{ position: 'absolute', width: '100vw', height: '100vh', WebkitFilter: 'blur(50px)', filter: 'blur(50px)' }}>
            <Modal
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              open={showReloadModal}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', minWidth: '500px', minHeight: '170px', textAlign: 'center', justifyContent: 'space-between', backgroundColor: 'white', padding: '20px' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                  <Typography variant='h6' style={{ fontWeight: 400, color: 'black', textAlign: 'left' }}>Reload the Page</Typography>
                  <Typography>Role has been changed in other opened tabs, please reload!</Typography>
                </Box>
                <Button sx={{ width: '100px', backgroundColor: '#3369A6', color: 'white', '&:hover': { backgroundColor: '#3369A6' } }} onClick={handleReloadClick}>Reload</Button>
              </Box>
            </Modal>
          </Box>
          )
        : ''}
      {loading ? <Loader /> : ''}
      {selectedGroup && userGroups?.length > 1
        ? <>
          <Button
            onClick={handleOpenUserMenu}
            sx={{
              color: '#727474',
              mr: '10px',
              borderRadius: 0,
              textTransform: 'capitalize',
              fontSize: '16px',
              '&:hover': {
                backgroundColor: 'transparent'
              }
            }}
            endIcon={<KeyboardArrowDownIcon />}
          >
            {roles[selectedGroup] || selectedGroup}
          </Button>
          <Menu
            sx={{ mt: '30px' }}
            id='menu-switchRole'
            TransitionComponent={Fade}
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            keepMounted
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {user?.allowedGroups?.map((groupName, index) => (
              <MenuItem
                sx={{ fontWeight: `${groupName === selectedGroup ? 'bold' : 'normal'}` }}
                key={index}
                onClick={() => handleUserGroupChange(groupName)}
              >
                {roles[groupName] || groupName}
              </MenuItem>
            ))}
          </Menu>
        </>
        : userGroups?.length === 1 ? <Typography sx={{ padding: '6px 8px', fontWeight: '600', textTransform: 'capitalize', fontSize: '16px', color: '#727474' }}>{user?.name}</Typography> : ''}
    </>
  )
}

export default SwitchUserTop
