import React, { useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import ReplayIcon from '@mui/icons-material/Replay'
import SaveIcon from '@mui/icons-material/Save'
import { LoadingButton } from '@mui/lab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import {
  Button,
  Divider,
  IconButton,
  ThemeProvider,
  Tooltip
} from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import * as Sentry from '@sentry/react'
import { useAuth } from '../../../contexts/AuthContext'
import { arisPMDataContext, optimizerDataContext, sponsorPMDataContext } from '../../../contexts/IPSPolicyContext'
import { useSponsorIdContext } from '../../../contexts/IPSSponsorContext'
import { useErrorToast } from '../../../hooks/useErrorToast'
import Loader from '../../Loader'
import '../IPSPage.css'
import { CustomTab, Tabtheme } from './CustomTabTheme'
import { OptimizerDataCard } from './Optmizer'
import { DataCard } from './PolicyCards'
import PolicySubmittedSuccess from './PolicySubmittedSuccess'
import { SponsorDataCard } from './Sponser'
import StatementCard from './StatementCard'
import { useIpsAccess } from '../../../hooks/useIpsAccess'

const PolicyPage = () => {
  const { showError } = useErrorToast()
  const { user } = useAuth()
  const [value, setValue] = React.useState(0)
  const [selectedData, setSelectedData] = React.useState([])
  const [selectedOpt, setSelectedOpt] = React.useState([])
  const [selectedSponsor, setSelectedSponsor] = React.useState([])
  const [arisPMData, setArisPMData] = useState([])
  const [optimizerData, setOptimizerData] = useState([])
  const [sponsorPMData, setSponsorPMData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [pendingApproveIds, setPendingApproveIds] = useState([])
  const [pendingSubmitIds, setPendingSubmitIds] = useState([])
  const [originalData, setOriginalData] = useState([])
  const { selectedSponsorId } = useSponsorIdContext()
  const [modifiedData, setModifiedData] = useState([])
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [successTitle, setSuccessTitle] = useState('')
  const [isUnderApproval, setIsUnderApproval] = useState(false)
  const [requestId, setRequestId] = useState('')
  const policyEditAccess = useIpsAccess('Policy')

  const formatPolicyData = (data) => {
    const pendingApprovalIds = [];
    (data || []).forEach(policyGroup => {
      (policyGroup.policyGroups || []).forEach(obj => {
        if (obj.policies.length) {
          (obj.policies).forEach(policy => {
            if (policy.status === 'UNDER_APPROVAL' || policy.status === 'APPROVED') {
              policy.checked = true
              policy.parameters.forEach((param) => { param.currentValue = param.value })
            }
            if (policy.status === 'UNDER_APPROVAL' || policy.status === 'DEACTIVATING') {
              pendingApprovalIds.push(policy.sponsorPolicyMapId)
            }
          })
        }
      })
    })
    setPendingApproveIds(pendingApprovalIds)
    const arisPMPolicy = data?.filter((policy) => policy.reviewerName === 'ARIS')
    const sponsorPMPolicy = data?.filter((policy) => policy.reviewerName === 'Sponsor')
    const optimizerPolicy = data?.filter((policy) => policy.reviewerName === 'Optimizer')
    if (arisPMPolicy.length) {
      setArisPMData(arisPMPolicy[0].policyGroups)
      setSelectedData(arisPMPolicy[0].policyGroups?.flatMap(obj => obj.policies.filter(data => data.checked || data.status === 'UNDER_APPROVAL' || data.status === 'APPROVED').map((data) => ({ policyGroup: obj.policyGroup, ...data }))))
    }

    if (optimizerPolicy.length) {
      setOptimizerData(optimizerPolicy[0].policyGroups)
      setSelectedOpt(optimizerPolicy[0].policyGroups?.flatMap(obj => obj.policies.filter(data => data.checked || data.status === 'UNDER_APPROVAL' || data.status === 'APPROVED').map((data) => ({ policyGroup: obj.policyGroup, ...data }))))
    }

    if (sponsorPMPolicy.length) {
      setSponsorPMData(sponsorPMPolicy[0].policyGroups)
      setSelectedSponsor(sponsorPMPolicy[0].policyGroups?.flatMap(obj => obj.policies.filter(data => data.checked || data.status === 'UNDER_APPROVAL' || data.status === 'APPROVED').map((data) => ({ policyGroup: obj.policyGroup, ...data }))))
    }
  }

  const getPolicyData = () => {
    API.get(
      'baseUriSubAdvisoryPolicy',
      `sub-advisory-policy/v1/${user.userGroup}/ips/policies/${selectedSponsorId?.sponsorId || ''}`)
      .then(response => {
        setOriginalData(JSON.parse(JSON.stringify(response.data)))
        // Handle the response from the API
        if (response.data.length) {
          setIsUnderApproval(response.data.some(reviewGroup => reviewGroup.policyGroups.some(group => group.policies.some(policy => policy.status === 'UNDER_APPROVAL' || policy.status === 'DEACTIVATING'))))
          formatPolicyData(response?.data || [])
        } else {
          setArisPMData([])
          setSelectedData([])
          setSponsorPMData([])
          setSelectedSponsor([])
          setOptimizerData([])
          setSelectedOpt([])
        }
        setIsLoading(false)
      })
      .catch(error => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    if (user && selectedSponsorId?.sponsorId && selectedSponsorId?.sponsorId !== '-1') {
      setIsLoading(true)
      getPolicyData()
    }
  }, [selectedSponsorId?.sponsorId, user])

  useEffect(() => {
    if (isSubmitted) {
      setOriginalData(prevData => {
        return JSON.parse(JSON.stringify(prevData.map((reviewGroup) => {
          if (reviewGroup.reviewerName === 'ARIS') {
            reviewGroup.policyGroups = [...arisPMData]
          } else if (reviewGroup.reviewerName === 'Sponsor') {
            reviewGroup.policyGroups = [...sponsorPMData]
          } else if (reviewGroup.reviewerName === 'Optimizer') {
            reviewGroup.policyGroups = [...optimizerData]
          }
          return reviewGroup
        })))
      })
      // Update statement card data to display selected policies
      setSelectedData([...arisPMData].flatMap(obj => obj.policies.filter(data => data.checked || data.status === 'UNDER_APPROVAL' || data.status === 'APPROVED').map((data) => ({ policyGroup: obj.policyGroup, ...data }))))
      setSelectedOpt([...optimizerData].flatMap(obj => obj.policies.filter(data => data.checked || data.status === 'UNDER_APPROVAL' || data.status === 'APPROVED').map((data) => ({ policyGroup: obj.policyGroup, ...data }))))
      setSelectedSponsor([...sponsorPMData].flatMap(obj => obj.policies.filter(data => data.checked || data.status === 'UNDER_APPROVAL' || data.status === 'APPROVED').map((data) => ({ policyGroup: obj.policyGroup, ...data }))))
    }
  }, [isSubmitted])

  const policyTabs = ['ARIS PM', 'Sponsor PM', 'Optimizer']

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleDataChange = (data) => {
    if (data.remove) {
      setModifiedData(prevData => prevData.filter(obj => obj.policyId !== data.policyId))
      setPendingSubmitIds(prevData => prevData.filter((id) => id !== data.policyId))
      delete data.remove
    } else {
      setModifiedData(prevData => {
        const index = prevData.findIndex(obj => obj.policyId === data.policyId)
        if (index > -1) {
          prevData[index] = { ...data }
          return prevData
        }
        return [...prevData, data]
      })
      setPendingSubmitIds(prevData => data.checked
        ? [...prevData, data.policyId]
        : prevData.filter((id) => id !== data.policyId))
    }

    const index = selectedData.findIndex((obj) => obj.policyId === data.policyId)
    if (index !== -1) {
      // Update existing object
      const updatedData = [...selectedData]
      updatedData[index] = data
      setSelectedData(updatedData)
    } else {
      // Append new data
      const updatedData = [...selectedData, data]
      updatedData.sort((a, b) => a.policyNum - b.policyNum)
      setSelectedData(updatedData)
    }

    const updatedArisPMData = arisPMData.map(obj => {
      if (obj.policies.filter(policy => policy.policyId === data.policyId).length) {
        obj.policies = obj.policies.map(policy => (policy.policyId === data.policyId) ? ({ ...policy, ...data }) : policy)
      }
      return obj
    })
    setArisPMData(updatedArisPMData)
  }

  const handleDataRemove = (data) => {
    setModifiedData(prevData => {
      const index = prevData.findIndex(obj => obj.policyId === data.policyId)
      if (index > -1) {
        prevData[index] = { ...data }
        return prevData
      }
      return [...prevData, data]
    })
    if (!pendingSubmitIds.includes(data.policyId)) {
      setPendingSubmitIds(prevData => [...prevData, data.policyId])
    } else {
      if (data.status === 'INACTIVE') {
        const updatedPendingSubmitIds = pendingSubmitIds.filter((id) => id !== data.policyId)
        setPendingSubmitIds(updatedPendingSubmitIds)
        setModifiedData(prevData => prevData.filter(obj => obj.policyId !== data.policyId))
      }
    }
    const filteredData = selectedData.filter((item) => item.policyId !== data.policyId)
    setSelectedData(filteredData)
    const updatedArisPMData = arisPMData.map(obj => {
      if (obj.policies.filter(policy => policy.policyId === data.policyId).length) {
        obj.policies = obj.policies.map(policy => (policy.policyId === data.policyId) ? ({ ...policy, checked: false }) : policy)
      }
      return obj
    })
    setArisPMData(updatedArisPMData)
  }

  const handleOptDataChange = (data) => {
    if (data.remove) {
      setModifiedData(prevData => prevData.filter(obj => obj.policyId !== data.policyId))
      setPendingSubmitIds(prevData => prevData.filter((id) => id !== data.policyId))
      delete data.remove
    } else {
      setModifiedData(prevData => {
        const index = prevData.findIndex(obj => obj.policyId === data.policyId)
        if (index > -1) {
          prevData[index] = { ...data }
          return prevData
        }
        return [...prevData, data]
      })
      setPendingSubmitIds(prevData => data.checked
        ? [...prevData, data.policyId]
        : prevData.filter((id) => id !== data.policyId))
    }

    const index = selectedOpt.findIndex((obj) => obj.policyId === data.policyId)
    if (index !== -1) {
      // Update existing object
      const updatedData = [...selectedOpt]
      updatedData[index] = data
      setSelectedOpt(updatedData)
    } else {
      // Append new data
      const updatedData = [...selectedOpt, data]
      updatedData.sort((a, b) => a.policyNum - b.policyNum)
      setSelectedOpt(updatedData)
    }

    const updatedOptData = optimizerData.map(obj => {
      if (obj.policies.filter(policy => policy.policyId === data.policyId).length) {
        obj.policies = obj.policies.map(policy => (policy.policyId === data.policyId) ? ({ ...policy, ...data }) : policy)
      }
      return obj
    })
    setOptimizerData(updatedOptData)
  }

  const handleOptDataRemove = (data) => {
    setModifiedData(prevData => {
      const index = prevData.findIndex(obj => obj.policyId === data.policyId)
      if (index > -1) {
        prevData[index] = { ...data }
        return prevData
      }
      return [...prevData, data]
    })
    if (!pendingSubmitIds.includes(data.policyId)) {
      setPendingSubmitIds(prevData => [...prevData, data.policyId])
    } else {
      if (data.status === 'INACTIVE') {
        const updatedPendingSubmitIds = pendingSubmitIds.filter((id) => id !== data.policyId)
        setPendingSubmitIds(updatedPendingSubmitIds)
        setModifiedData(prevData => prevData.filter(obj => obj.policyId !== data.policyId))
      }
    }
    const filteredData = selectedOpt.filter((item) => item.policyId !== data.policyId)
    setSelectedOpt(filteredData)
    const updatedOptData = optimizerData.map(obj => {
      if (obj.policies.filter(policy => policy.policyId === data.policyId).length) {
        obj.policies = obj.policies.map(policy => (policy.policyId === data.policyId) ? ({ ...policy, checked: false }) : policy)
      }
      return obj
    })
    setOptimizerData(updatedOptData)
  }

  const handleSprDataChange = (data) => {
    if (data.remove) {
      setModifiedData(prevData => prevData.filter(obj => obj.policyId !== data.policyId))
      setPendingSubmitIds(prevData => prevData.filter((id) => id !== data.policyId))
      delete data.remove
    } else {
      setModifiedData(prevData => {
        const index = prevData.findIndex(obj => obj.policyId === data.policyId)
        if (index > -1) {
          prevData[index] = { ...data }
          return prevData
        }
        return [...prevData, data]
      })
      setPendingSubmitIds(prevData => data.checked
        ? [...prevData, data.policyId]
        : prevData.filter((id) => id !== data.policyId))
    }

    const index = selectedSponsor.findIndex((obj) => obj.policyId === data.policyId)
    if (index !== -1) {
      // Update existing object
      const updatedData = [...selectedSponsor]
      updatedData[index] = data
      setSelectedSponsor(updatedData)
    } else {
      // Append new data
      const updatedData = [...selectedSponsor, data]
      updatedData.sort((a, b) => a.policyNum - b.policyNum)
      setSelectedSponsor(updatedData)
    }

    const updatedSponsorPMData = sponsorPMData.map(obj => {
      if (obj.policies.filter(policy => policy.policyId === data.policyId).length) {
        obj.policies = obj.policies.map(policy => (policy.policyId === data.policyId) ? ({ ...policy, ...data }) : policy)
      }
      return obj
    })
    setSponsorPMData(updatedSponsorPMData)
  }

  const handleSprDataRemove = (data) => {
    setModifiedData(prevData => {
      const index = prevData.findIndex(obj => obj.policyId === data.policyId)
      if (index > -1) {
        prevData[index] = { ...data }
        return prevData
      }
      return [...prevData, data]
    })
    if (!pendingSubmitIds.includes(data.policyId)) {
      setPendingSubmitIds(prevData => [...prevData, data.policyId])
    } else {
      if (data.status === 'INACTIVE') {
        const updatedPendingSubmitIds = pendingSubmitIds.filter((id) => id !== data.policyId)
        setPendingSubmitIds(updatedPendingSubmitIds)
        setModifiedData(prevData => prevData.filter(obj => obj.policyId !== data.policyId))
      }
    }
    const filteredData = selectedSponsor.filter((item) => item.policyId !== data.policyId)
    setSelectedSponsor(filteredData)
    const updatedSponsorPMData = sponsorPMData.map(obj => {
      if (obj.policies.filter(policy => policy.policyId === data.policyId).length) {
        obj.policies = obj.policies.map(policy => (policy.policyId === data.policyId) ? ({ ...policy, checked: false }) : policy)
      }
      return obj
    })
    setSponsorPMData(updatedSponsorPMData)
  }

  const handleSubmitClick = () => {
    setIsSubmitting(true)
    const policyReqData = modifiedData.map(data => {
      const res = {
        sponsorPolicyMapId: data?.sponsorPolicyMapId,
        status: (data.status === 'UNDER_APPROVAL' || data.status === 'APPROVED') && !data.checked ? 'DEACTIVATING' : 'UNDER_APPROVAL',
        parameters: data.parameters.map(param => ({
          policyParamId: param?.policyParamId,
          value: (data.status === 'UNDER_APPROVAL' || data.status === 'APPROVED') && !data.checked ? (param.options.find((option) => option.isDefault)?.optionValue || '0') : (param?.value || '0')
        }))
      }
      return res
    })
    API.put(
      'baseUriSubAdvisoryPolicy',
      `sub-advisory-policy/v1/${user.userGroup}/${selectedSponsorId?.sponsorId}/ips-data`,
      {
        body: [...policyReqData]
      }
    )
      .then((response) => {
        setIsUnderApproval((response.data?.ipsData || []).some(policy => policy.status === 'UNDER_APPROVAL' || policy.status === 'DEACTIVATING'))
        // group based on sponsorPolicyMapId
        const groupedData = (response.data?.ipsData || []).reduce((acc, curr) => {
          const { sponsorPolicyMapId, ...rest } = curr
          if (acc[sponsorPolicyMapId]) {
            acc[sponsorPolicyMapId].push(rest)
          } else {
            acc[sponsorPolicyMapId] = [rest]
          }
          return acc
        }, {})

        Object.keys(groupedData).forEach(key => {
          const policyGroup = groupedData[key][0].reviewerName === 'ARIS'
            ? arisPMData.find(obj => obj.policies.some(policy => policy.sponsorPolicyMapId === key))
            : groupedData[key][0].reviewerName === 'Optimizer'
              ? optimizerData.find(obj => obj.policies.some(policy => policy.sponsorPolicyMapId === key))
              : groupedData[key][0].reviewerName === 'Sponsor'
                ? sponsorPMData.find(obj => obj.policies.some(policy => policy.sponsorPolicyMapId === key))
                : null

          // Check if the policy exists
          if (policyGroup) {
            if (groupedData[key][0].reviewerName === 'ARIS') {
              setArisPMData(prevData => prevData.map((data) => {
                if (data.policyGroup === policyGroup.policyGroup) {
                  const updatedPolicies = [...data.policies]
                  const policyIndex = updatedPolicies.findIndex(policy => policy.sponsorPolicyMapId === key)

                  if (policyIndex !== -1) {
                    updatedPolicies[policyIndex] = {
                      ...updatedPolicies[policyIndex],
                      status: groupedData[key][0].status,
                      parameters: updatedPolicies[policyIndex].parameters.map((param) => ({ ...param, value: groupedData[key].find(obj => obj.policyParamId === param.policyParamId).value }))
                    }
                  }
                  return { ...data, policies: updatedPolicies }
                }
                return data
              }))
            } else if (groupedData[key][0].reviewerName === 'Optimizer') {
              setOptimizerData(prevData => prevData.map((data) => {
                if (data.policyGroup === policyGroup.policyGroup) {
                  const updatedPolicies = [...data.policies]
                  const policyIndex = updatedPolicies.findIndex(policy => policy.sponsorPolicyMapId === key)

                  if (policyIndex !== -1) {
                    updatedPolicies[policyIndex] = {
                      ...updatedPolicies[policyIndex],
                      status: groupedData[key][0].status,
                      parameters: updatedPolicies[policyIndex].parameters.map((param) => ({ ...param, value: groupedData[key].find(obj => obj.policyParamId === param.policyParamId).value }))
                    }
                  }
                  return { ...data, policies: updatedPolicies }
                }
                return data
              }))
            } else if (groupedData[key][0].reviewerName === 'Sponsor') {
              setSponsorPMData(prevData => prevData.map((data) => {
                if (data.policyGroup === policyGroup.policyGroup) {
                  const updatedPolicies = [...data.policies]
                  const policyIndex = updatedPolicies.findIndex(policy => policy.sponsorPolicyMapId === key)

                  if (policyIndex !== -1) {
                    updatedPolicies[policyIndex] = {
                      ...updatedPolicies[policyIndex],
                      status: groupedData[key][0].status,
                      parameters: updatedPolicies[policyIndex].parameters.map((param) => ({ ...param, value: groupedData[key].find(obj => obj.policyParamId === param.policyParamId).value }))
                    }
                  }
                  return { ...data, policies: updatedPolicies }
                }
                return data
              }))
            }
          }
        })
        setIsSubmitted(true)
        setPendingSubmitIds([])
        setModifiedData([])
        setShowSuccessModal(true)
        setSuccessTitle('Policy Submitted')
        setRequestId(response.data?.requestData[0]?.userRqstId || '')
      })
      .catch(error => {
        setIsSubmitted(false)
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => setIsSubmitting(false))
  }

  const handleApprove = () => {
    setIsSubmitting(true)
    API.patch(
      'baseRequestURL',
      `request/v1/${user.userGroup}/${selectedSponsorId?.sponsorId}/policy-requests`
    )
      .then((response) => {
        if (response.data?.ipsData && response.data?.ipsData?.length) {
          response.data?.ipsData.forEach(ipsObj => {
            const policyGroup = ipsObj.reviewerName === 'ARIS'
              ? arisPMData.find(obj => obj.policies.some(policy => policy.sponsorPolicyMapId === ipsObj.sponsorPolicyMapId))
              : ipsObj.reviewerName === 'Optimizer'
                ? optimizerData.find(obj => obj.policies.some(policy => policy.sponsorPolicyMapId === ipsObj.sponsorPolicyMapId))
                : ipsObj.reviewerName === 'Sponsor'
                  ? sponsorPMData.find(obj => obj.policies.some(policy => policy.sponsorPolicyMapId === ipsObj.sponsorPolicyMapId))
                  : null
            // Check if the reviewer name is aris group. if not, check in other groups and replace the status
            if (policyGroup) {
              if (ipsObj.reviewerName === 'ARIS') {
                setArisPMData(prevData => prevData.map((data) => {
                  if (data.policyGroup === policyGroup.policyGroup) {
                    const updatedPolicies = [...data.policies]
                    const policyIndex = updatedPolicies.findIndex(policy => policy.sponsorPolicyMapId === ipsObj.sponsorPolicyMapId)

                    if (policyIndex !== -1) {
                      updatedPolicies[policyIndex] = {
                        ...updatedPolicies[policyIndex],
                        status: ipsObj.status,
                        policyText: updatedPolicies[policyIndex].policyText.replace(/<strike>(.*?)<\/strike> /g, '')
                      }
                    }
                    return { ...data, policies: updatedPolicies }
                  }
                  return data
                }))
              } else if (ipsObj.reviewerName === 'Optimizer') {
                setOptimizerData(prevData => prevData.map((data) => {
                  if (data.policyGroup === policyGroup.policyGroup) {
                    const updatedPolicies = [...data.policies]
                    const policyIndex = updatedPolicies.findIndex(policy => policy.sponsorPolicyMapId === ipsObj.sponsorPolicyMapId)

                    if (policyIndex !== -1) {
                      updatedPolicies[policyIndex] = {
                        ...updatedPolicies[policyIndex],
                        status: ipsObj.status,
                        policyText: updatedPolicies[policyIndex].policyText.replace(/<strike>(.*?)<\/strike> /g, '')
                      }
                    }
                    return { ...data, policies: updatedPolicies }
                  }
                  return data
                }))
              } else if (ipsObj.reviewerName === 'Sponsor') {
                setSponsorPMData(prevData => prevData.map((data) => {
                  if (data.policyGroup === policyGroup.policyGroup) {
                    const updatedPolicies = [...data.policies]
                    const policyIndex = updatedPolicies.findIndex(policy => policy.sponsorPolicyMapId === ipsObj.sponsorPolicyMapId)

                    if (policyIndex !== -1) {
                      updatedPolicies[policyIndex] = {
                        ...updatedPolicies[policyIndex],
                        status: ipsObj.status,
                        policyText: updatedPolicies[policyIndex].policyText.replace(/<strike>(.*?)<\/strike> /g, '')
                      }
                    }
                    return { ...data, policies: updatedPolicies }
                  }
                  return data
                }))
              }
            }
          })
          setRequestId(response.data?.requestData[0]?.userRequestId || '')
          setPendingApproveIds([])
          setPendingSubmitIds([])
          setShowSuccessModal(true)
          setIsSubmitted(true)
          setSuccessTitle('Policy Approved')
        }
      })
      .catch(error => {
        setIsSubmitted(false)
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => setIsSubmitting(false))
  }

  const handleReset = () => {
    setPendingSubmitIds([])
    setModifiedData([])
    formatPolicyData(JSON.parse(JSON.stringify(originalData)))
  }

  const renderTabPanel = (item) => {
    switch (item) {
      case 'ARIS PM':
        return (
          <arisPMDataContext.Provider value={arisPMData}>
            <DataCard
              onDataChange={handleDataChange}
              onDataRemove={handleDataRemove}
              pendingSubmitIds={pendingSubmitIds}
              isUnderApproval={isUnderApproval}
            />
          </arisPMDataContext.Provider>
        )
      case 'Sponsor PM':
        return (
          <sponsorPMDataContext.Provider value={sponsorPMData}>
            <Box py={2}>
              <SponsorDataCard
                onDataChange={handleSprDataChange}
                onDataRemove={handleSprDataRemove}
                pendingSubmitIds={pendingSubmitIds}
                isUnderApproval={isUnderApproval}
              />
            </Box>
          </sponsorPMDataContext.Provider>
        )
      case 'Optimizer':
        return (
          <optimizerDataContext.Provider value={optimizerData}>
            <OptimizerDataCard
              onDataChange={handleOptDataChange}
              onDataRemove={handleOptDataRemove}
              pendingSubmitIds={pendingSubmitIds}
              isUnderApproval={isUnderApproval}
            />
          </optimizerDataContext.Provider>
        )
      default:
        return 'Something went wrong'
    }
  }

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container xs={12} sm={12}>
          <ThemeProvider theme={Tabtheme}>
            <Box
              sx={{
                flexGrow: 1,
                maxWidth: { xs: '100%' },
                bgcolor: 'white',
                width: '1058px'
              }}
            >
              <TabContext value={value}>
                <TabList
                  onChange={handleChange}
                  variant='scrollable'
                  scrollButtons={false}
                  aria-label='Custom Tabs'
                  // className='report-tab-header'
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: '#34475A'
                    }
                  }}
                >
                  {policyTabs.map((item, index) => (
                    <CustomTab
                      className=''
                      key={index}
                      value={index}
                      label={item}
                      indicatorColor='#34475A'
                    />
                  ))}
                </TabList>
                <Box sx={{
                  position: 'absolute',
                  right: policyEditAccess && user?.userGroup !== 'pm'
                    ? '0px'
                    : '180px',
                  top: '1px'
                }}
                >
                  {
                    policyEditAccess && user?.userGroup !== 'pm'
                      ? <>
                        {isSubmitting
                          ? <LoadingButton
                              loading
                              loadingPosition='start'
                              startIcon={<SaveIcon />}
                              variant='outlined'
                              sx={{ mx: '10px' }}
                            >
                            Submit
                          </LoadingButton>
                          : <Button variant='contained' disabled={!pendingSubmitIds.length} sx={{ mx: '10px' }} onClick={handleSubmitClick}>Submit</Button>}
                        <Tooltip title='Reset'>
                          <IconButton onClick={() => (pendingSubmitIds.length && !isSubmitting) ? handleReset() : ''} sx={{ opacity: (pendingSubmitIds.length && !isSubmitting) ? '1' : '0.5' }}>
                            <ReplayIcon />
                          </IconButton>
                        </Tooltip>
                      </>
                      : ''
                  }
                  {
                    user?.userGroup === 'pm'
                      ? <> {
                        isSubmitting
                          ? <LoadingButton
                              loading
                              loadingPosition='start'
                              startIcon={<SaveIcon />}
                              variant='outlined'
                              sx={{ mx: '10px', py: '5px' }}
                            >
                            Approve
                          </LoadingButton>
                          : <Button variant='contained' disabled={!pendingApproveIds.length} onClick={() => handleApprove()} sx={{ ml: '10px', py: '5px' }}>Approve</Button>
                      }
                      </>
                      : ''
                  }
                </Box>
                <Divider
                  sx={{
                    bgcolor: 'rgba(116, 120, 141, 0.15)'
                  }}
                />
                {isLoading
                  ? <Loader />
                  : <Grid xs={12} sm={12} container>
                    <Grid xs={12} sm={6.5} pr={1}>
                      {policyTabs.map((item, index) => (
                        <TabPanel
                          key={index}
                          value={index}
                          sx={{ padding: 0 }}
                        >
                          {!isLoading
                            ? (selectedSponsorId?.sponsorId !== '-1'
                                ? ((value === 0 && !arisPMData.length) || (value === 2 && !optimizerData.length) || (value === 1 && !sponsorPMData.length))
                                    ? <p style={{ marginTop: '10px' }}>No policy found</p>
                                    : ''
                                : (user?.userGroup === 'pm' && <p style={{ marginTop: '10px' }}>Select sponsor</p>))
                            : ''}
                          {renderTabPanel(item)}
                        </TabPanel>
                      ))}
                    </Grid>
                    <Grid xs={12} sm={5.5}>
                      {selectedSponsorId?.sponsorId !== '-1' && ((value === 0 && arisPMData.length) || (value === 2 && optimizerData.length) || (value === 1 && sponsorPMData.length))
                        ? <StatementCard
                            selectedData={selectedData}
                            selectedOpt={selectedOpt}
                            selectedSpr={selectedSponsor}
                            value={value}
                          />
                        : ''}
                    </Grid>
                  </Grid>}
                <PolicySubmittedSuccess open={showSuccessModal} onClose={() => setShowSuccessModal(false)} title={successTitle} requestId={requestId} />
              </TabContext>
            </Box>
          </ThemeProvider>
        </Grid>
      </Box>
    </>
  )
}

export default PolicyPage
