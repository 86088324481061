import { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, IconButton, Modal, Skeleton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { DataGridPro, GridCell, gridClasses } from '@mui/x-data-grid-pro'

const selectionPopupStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  px: 2,
  py: 2,
  width: '100%',
  minWidth: '600px',
  maxWidth: '900px',
  borderRadius: '4px',
  outline: 'none'
}

const accountsTableColumns = [
  {
    field: 'accountName',
    headerName: 'Account Name',
    flex: 1
  },
  {
    field: 'accountCode',
    headerName: 'Account Code',
    flex: 1
  },
  {
    field: 'accountType',
    headerName: 'Account Type',
    flex: 1
  },
  {
    field: 'isTaxable',
    headerName: 'Taxable',
    valueGetter: (params) => (params.value === 0 ? 'No' : 'Yes')
  }
]


const aggregatesTableColumns = [
  {
    field: 'aggGroupName',
    headerName: 'Aggregate Name',
    flex: 1
  },
  {
    field: 'groupType',
    headerName: 'Group Type',
    renderCell: (params) => <Typography sx={{ textTransform: params?.row?.groupType === 'uma' ? 'uppercase' : 'capitalize' }} className={gridClasses?.cellContent}>{params?.row?.groupType}</Typography>,
    flex: 1
  }
]


const SelectionTable = ({ contentLoading, tableColumns, tableRows, handleSelectionChange, uniqueRowKey }) => {
  return <>
    {
      contentLoading ?
        <>
          <Box className='table-responsive'>
            <Table>
              <TableHead>
                <TableRow>
                  {tableColumns.map((item, index) => {
                    return (
                      <TableCell key={index}>{item.headerName}</TableCell>
                    )
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.from({ length: 5 }).map((_, i) => (
                  <TableRow key={i}>
                    {Array.from({ length: tableColumns.length }).map(_ => (
                      <TableCell>
                        <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
                      </TableCell>))}
                  </TableRow>))}
              </TableBody>
            </Table>
          </Box>
        </>
        : <DataGridPro
          autoHeight
          rows={tableRows}
          columns={tableColumns}
          onRowSelectionModelChange={(e) => {
            handleSelectionChange(e)
          }}
          getRowId={(row) => row?.[`${uniqueRowKey}`]}
          density='compact'
          disableMultipleRowSelection
          pagination
          pageSizeOptions={[15]}
          initialState={{
            ...tableRows?.initialState,
            pagination: { paginationModel: { pageSize: 15 } }
          }}
          sx={(theme) => ({
            '& .MuiDataGrid-cell': {
              cursor: 'pointer'
            },
            '& .MuiDataGrid-cell:focus': {
              outline: 'none'
            },
            [`.${gridClasses.main}`]: {
              height: 'min(100vh - 16px - 45px - 40px - 16px - 53px, 600px - 16px - 45px - 16px - 40px - 53px)',
            },
            [`.${gridClasses.columnHeaders}`]: {
              position: 'sticky',
              backgroundColor: theme.palette.background.paper,
              top: 0,
              zIndex: 1,
            },
            [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
              color: '#74788d',
              fontWeight: 600
            },
            [`.${gridClasses.virtualScroller}`]: {
              overflowY: 'auto !important',
              scrollbarGutter: 'stable',
              scrollbarWidth: 'none'
            }
          })}
        />
    }
  </>
}

const AccountAggregateSelectionPopup = ({
  openSelectionPopup,
  closeSelectionPopup,
  accountsLoading,
  accounts,
  aggregateGroups,
  aggregatesLoading,
  reportType,
  openPreview
}) => {
  const [selectedEntity, setSelectedEntity] = useState([])

  return (
    <Modal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      open={openSelectionPopup}
      onClose={() => {
        closeSelectionPopup()
      }}
    >
      <Box sx={{ ...selectionPopupStyle, ...{ ...reportType === 'Aggregate Report' ? { minWidth: '450px', maxWidth: '600px' } : {} } }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography sx={{ fontSize: '20px', fontWeight: 400, color: '#34475A' }}>
            Select {reportType === 'Aggregate Report' ? 'Aggregate' : 'Account'}
          </Typography>
          <IconButton onClick={closeSelectionPopup} sx={{ color: '#002A59' }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <SelectionTable
          contentLoading={reportType === 'Aggregate Report' ? aggregatesLoading : accountsLoading}
          tableColumns={reportType === 'Aggregate Report' ? aggregatesTableColumns : accountsTableColumns}
          tableRows={reportType === 'Aggregate Report' ? aggregateGroups : accounts}
          handleSelectionChange={setSelectedEntity}
          uniqueRowKey={reportType === 'Aggregate Report' ? 'aggGroupId' : 'accountId'}
        />
        <Box sx={{ float: 'right' }}>
          <Button
            disabled={!selectedEntity?.length}
            type='submit'
            variant='contained'
            onClick={() => openPreview(selectedEntity)}
          >
            Preview
          </Button>
        </Box>

      </Box>
    </Modal>
  )
}

export default AccountAggregateSelectionPopup