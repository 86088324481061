import { useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import { API } from 'aws-amplify'
import { CardContent, Card, Dialog, Typography, Button, Box, IconButton } from '@mui/material'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { KeyboardArrowLeft } from '@mui/icons-material'
import AddIcon from '@mui/icons-material/Add'
import {
  GridRowModes,
  GridToolbarContainer
} from '@mui/x-data-grid'
import { randomId } from '@mui/x-data-grid-generator'
import PortfolioSpecificationInputForm from './PortfolioSpecificationInputForm'
import { CreateAPI } from '../Data Maintenance/components/Actions'
import { FormatPayload } from '../Data Maintenance/components/FormatAPIRequest'
import { useAuth } from '../../contexts/AuthContext'
import { useErrorToast } from '../../hooks/useErrorToast'
import { useSuccessToast } from '../../hooks/useSuccessToast'

const PortSpecTable = ({ sponsorId, setActiveTab }) => {
  const { user } = useAuth()
  const [rowModesModel, setRowModesModel] = useState({})
  const { showError } = useErrorToast()
  const { showSuccess } = useSuccessToast()
  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)
  const [open, setOpen] = useState(false)
  const [portSpecRows, setPortSpecRows] = useState([])

  useEffect(() => {
    if (user) {
      getPortSpecList()
    }
  }, [user])

  const columns = [
    {
      field: 'strategyName',
      headerName: 'Strategy Name',
      flex: 1
    },
    {
      field: 'isStrategyActive',
      headerName: 'Strategy Active',
      flex: 1
    },
    {
      field: 'portSpecCode',
      headerName: 'Port Spec Code',
      flex: 1
    },
    {
      field: 'portSpecName',
      headerName: 'Port Spec Name',
      flex: 1
    },
    {
      field: 'isPortSpecActive',
      headerName: 'PortSpec Active',
      flex: 1
    }
  ]

  const getPortSpecList = async () => {
    setIsLoading(true)
    API.get('baseUriPortSpec', `strategy/v1/${user?.userGroup}/port-spec/sponsor/${sponsorId}`)
      .then(response => {
        if (response?.data) {
          setPortSpecRows(response?.data)
        }
      }).catch(error => {
        setIsLoading(false)
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      }).finally(() => setIsLoading(false))
  }

  const handleRowModesModelChange = (newRoleRowModesModel) => {
    setRowModesModel(newRoleRowModesModel)
  }

  const handleCancelClose = () => {
    setOpen(false)
  }

  const handleClick = () => {
    setOpen(true)
  }

  const CreatePortSpec = async (values) => {
    let payload = {
      portSpecCode: values.portSpecCode,
      portSpecName: values.portSpecName,
      strategyId: values.strategyId,
      sponsorId: values.sponsorId
    }
    payload = FormatPayload(payload)

    return await CreateAPI(
      'baseUriPortSpec',
          `strategy/v1/${user?.userGroup}/strategies/onboarding/port-spec`,
          payload
    )
  }

  const EditToolbar = (props) => {
    const { setPortSpecRows, setRowModesModel } = props

    const handleRoleSaveClose = async (values) => {
      const data = await CreatePortSpec(values)
      if (Object.keys(data).includes('error')) {
        setIsLoading(false)
        showError(data.error)
        return {}
      } else {
        if (data && data?.success) {
          setOpen(false)
          const portSpecId = randomId()
          const newRow = {
            portSpecId,
            ...{ ...(data.data.length && data.data[0] ? data.data[0] : values) },
            isNew: true
          }
          setPortSpecRows((oldRows) => [...oldRows, newRow])
          setRowModesModel((oldModel) => ({
            ...oldModel,
            [portSpecId]: { mode: GridRowModes.View, fieldToFocus: 'name' }
          }))
          showSuccess(data?.message)
        }
      }
    }

    return (
      <>
        <GridToolbarContainer>
          <Button color='primary' startIcon={<AddIcon />} onClick={handleClick}>
            Add record
          </Button>
        </GridToolbarContainer>
        <Dialog open={open} onClose={handleCancelClose} fullWidth maxWidth='sm'>
          <PortfolioSpecificationInputForm savedData={handleRoleSaveClose} sponsorId={sponsorId} />
        </Dialog>
      </>
    )
  }

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton className='rounded-circle' onClick={() => setActiveTab('str-tab')} sx={{ width: '22px', height: '22px', marginRight: '15px', marginBottom: '5px', backgroundColor: 'transparent' }}>
          <KeyboardArrowLeft />
        </IconButton>
        <Typography className='page-title' pb={1}>Portfolio Specification</Typography>
      </Box>
      <Card>
        <CardContent>
          <DataGridPro
            rows={portSpecRows}
            columns={columns}
            loading={isLoading}
            getRowId={(row) => row.portSpecId}
            autoHeight
            pageSizeOptions={[10]}
            onPaginationModelChange={(event) => setCurrentPage(event.page)}
            paginationModel={{ pageSize: 10, page: currentPage }}
            initialState={{
              pagination: { paginationModel: { pageSize: 10, page: currentPage } }
            }}
            pagination
            rowModesModel={rowModesModel}
            editMode='row'
            onRowModesModelChange={handleRowModesModelChange}
            slots={{
              toolbar: EditToolbar
            }}
            slotProps={{
              toolbar: { setPortSpecRows, setRowModesModel }
            }}
          />
        </CardContent>
      </Card>
    </>
  )
}

export default PortSpecTable
