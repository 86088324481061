import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { API } from 'aws-amplify'
import SaveIcon from '@mui/icons-material/Save'
import LoadingButton from '@mui/lab/LoadingButton'
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined'
import { Box, Button, FormControl, FormLabel, Grid, MenuItem, TextField, Typography } from '@mui/material'
import * as Sentry from '@sentry/react'
import { useAuth } from '../../../contexts/AuthContext'
import { RequestStratagy } from '../../../contstants/constants'
import { useErrorToast } from '../../../hooks/useErrorToast'
import { useSuccessToast } from '../../../hooks/useSuccessToast'
import { isNumberOnly, numberOnlyPasteHandler } from '../../../utils/NumberUtils'
import { InputAdornmentTextField } from '../../../pages/AccountOnboarding/components/inputAdornmentTextfield'
import './RequestModal.scss'

const RequestForm = ({ getAccountRequests, handleClose, name }) => {
  const inputType = useRef()
  const inputTitle = useRef()
  const inputAmount = useRef()
  const inputSelectedStrategy = useRef()
  const inputShortAmount = useRef()
  const inputSelectedShortStrategy = useRef()
  const inputComment = useRef()
  const { user} = useAuth()
  const { showError } = useErrorToast()
  const { showSuccess } = useSuccessToast()
  const [select, setSelect] = useState('')
  const [ytdData, setYtdData] = useState()
  const [loading, setLoading] = useState(false)
  const [inputAmountError, setInputAmountError] = useState(false)

  const params = useParams()
  const datasecure = localStorage.getItem('object')
  const getStoreData = JSON.parse(datasecure)
  const filterSecuredata = getStoreData?.filter((item) => item?.accountId === params?.accountId)

  const handleCapacity = (e) => {
    setSelect(e.target.value)
  }

  const sanitizeComment = (input) => {
    return input.replace(/(<([^>]+)>)/gi, '')
  }

  const typecode = 'YTD_EXTERNAL_GAIN_LOSS'
  useEffect(() => {
    if (user) {
      getYTD(typecode)
    }
  }, [user])
  const getYTD = (typecode) => {
    API.get('baseURL', `account-master/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/accounts/${params.accountId}/external-gl`, { queryStringParameters: { typecode } })
      .then(response => {
        if (response.data.length === 0) {
        } else {
          setYtdData(response.data)
        }
      })
      .catch(error => {
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
  }

  const validateInputAmount = (e) => {
    const amount = e.target.value
    const totalMarketValue = (filterSecuredata.length > 0) && filterSecuredata[0]?.totalMarketValue
    if(amount > totalMarketValue) {
      setInputAmountError(true)
    }
    else {
      setInputAmountError(false)
    }
  }

  const onSubmitHandler = (event) => {
    event.preventDefault()
    setLoading(true)
    if (select === 'CASH_RAISE') {
      const enteredType = inputType.current.value
      const enteredTitle = inputTitle.current.value
      const enteredComment = inputComment.current.value
      const sanitizedComment = sanitizeComment(enteredComment)
      const enteredAmount = inputAmount.current.value
      const data = {
        requestType: enteredType,
        title: enteredTitle,
        comment: sanitizedComment,
        amount: enteredAmount
      }
      API
        .post('baseRequestURL', `request/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/requests/${params.accountId}`, { body: { ...data } })
        .then(response => {
          if (response) {
            setLoading(true)
            handleClose()
            getAccountRequests()
            showSuccess(response.message)
            setLoading(false)
          }
        }).then(() => {
          if (
            (inputType && inputType?.current) && (inputTitle && inputTitle?.current) && (inputAmount && inputAmount?.current) && (inputComment && inputComment?.current)
          ) {
            inputType.current.value = ''
            inputTitle.current.value = ''
            inputAmount.current.value = ''
            inputComment.current.value = ''
            setSelect('')
          }
        }).catch(error => {
          showError(error.response?.data?.errorInfo?.userMessage || error.message)
          setLoading(false)
          Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
        })
    } else if (select === 'YTD_EXTERNAL_GAIN_LOSS') {
      const enteredType = inputType.current.value
      const enteredTitle = inputTitle.current.value
      const enteredComment = inputComment.current.value
      const sanitizedComment = sanitizeComment(enteredComment)
      const enteredAmount = inputAmount.current.value
      const ytdType = inputSelectedStrategy.current.value
      const enteredShortAmount = inputShortAmount.current.value
      const enteredShortStrategy = inputSelectedShortStrategy.current.value
      const data = {
        requestType: enteredType,
        ytdTypeLong: ytdType,
        ytdTypeShort: enteredShortStrategy,
        title: enteredTitle,
        comment: sanitizedComment,
        ytdRealLongGain: enteredAmount,
        ytdRealShortGain: enteredShortAmount
      }
      API
        .post('baseRequestURL', `request/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/requests/${params.accountId}`, { body: { ...data } })
        .then(response => {
          if (response) {
            setLoading(true)
            showSuccess(response.message)
            handleClose()
            getAccountRequests()
            setLoading(false)
          }
        }).then(() => {
          if (
            (inputType && inputType?.current) && (inputTitle && inputTitle?.current) && (inputAmount && inputAmount?.current) && (inputComment && inputComment?.current) &&
            (inputSelectedStrategy && inputSelectedStrategy?.current) && (inputShortAmount && inputShortAmount?.current) && (inputSelectedShortStrategy && inputSelectedShortStrategy?.current)
          ) {
            inputType.current.value = ''
            inputTitle.current.value = ''
            inputAmount.current.value = ''
            inputComment.current.value = ''
            inputSelectedStrategy.current.value = ''
            inputShortAmount.current.value = ''
            inputSelectedShortStrategy.current.value = ''
            setSelect('')
          }
        }).catch(error => {
          showError(error.response?.data?.errorInfo?.userMessage || error.message)
          setLoading(false)
          Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
        })
    } else {
      const enteredType = inputType.current.value
      const enteredTitle = inputTitle.current.value
      const enteredComment = inputComment.current.value
      const sanitizedComment = sanitizeComment(enteredComment)
      const data = {
        requestType: enteredType,
        title: enteredTitle,
        comment: sanitizedComment
      }

      API
        .post('baseRequestURL', `request/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/requests/${params.accountId}`, { body: { ...data } })
        .then(response => {
          if (response) {
            setLoading(true)
            showSuccess(response.message)
            handleClose()
            getAccountRequests()
            setLoading(false)
          }
        }).then(() => {
          if (
            (inputType && inputType?.current) && (inputTitle && inputTitle?.current) && (inputComment && inputComment?.current)
          ) {
            inputType.current.value = ''
            inputTitle.current.value = ''
            inputComment.current.value = ''
            setSelect('')
          }
        }).catch(error => {
          showError(error.response?.data?.errorInfo?.userMessage || error.message)
          setLoading(false)
          Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
        })
    }
  }

  return (
    <>
      <Box sx={{ height: 'auto' }}>
        <form className='form-req' onSubmit={onSubmitHandler} disabled={loading}>
          <Grid container className='modal_area' direction='column' spacing={1}>
            <Grid item>
              <Grid container alignItems='center' spacing={1}>
                <Grid item style={{ width: '24%' }}>
                  <Typography variant='h6' sx={{ fontFamily: 'Lora', color: '#34475A', fontSize: '24px', fontWeight: 400 }}>New Request</Typography>
                </Grid>
                <Grid item>
                  <Box className='divider' mr={2} />
                </Grid>
                <Grid item>
                  <Typography variant='text' component='div' sx={{ fontSize: '20px', fontFamily: 'Open Sans' }}>
                    {name}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <FormLabel className='viewReq account-request-label'>Request Type *</FormLabel>
              <FormControl fullWidth>
                <TextField
                  className='text-title'
                  select
                  required
                  value={select}
                  inputRef={inputType}
                  onChange={handleCapacity}
                >
                  {Object.keys(RequestStratagy).map((option, index) => {
                    if (option === 'GENERATE_PROPOSAL' && filterSecuredata[0].accountType === 'TRADING') {
                      return null
                    }
                    return (
                      <MenuItem key={index} required value={option}>
                        {RequestStratagy[option]}
                      </MenuItem>
                    )
                  })}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormLabel className='viewReq account-request-label'>Request Title *</FormLabel>
              <FormControl fullWidth>
                <TextField
                  className='text-title'
                  placeholder='Title Here'
                  maxLength={100}
                  required
                  inputRef={inputTitle}
                />
              </FormControl>
            </Grid>
            {select === 'YTD_EXTERNAL_GAIN_LOSS' && (
              <>
                <Grid item xs={12}>
                  <FormLabel className='viewReq account-request-label'>Long Gain/Loss *</FormLabel>
                  <FormControl fullWidth>
                    <TextField
                      className='text-title'
                      select
                      required
                      placeholder='Select'
                      inputRef={inputSelectedStrategy}
                    >
                      <MenuItem value='GAIN'>GAIN</MenuItem>
                      <MenuItem value='LOSS'>LOSS</MenuItem>
                    </TextField>
                  </FormControl>

                  {select === 'YTD_EXTERNAL_GAIN_LOSS' && (
                    <>
                      {!ytdData
                        ? (
                          <Typography sx={{ fontSize: '14px' }}>
                            No Past YTD External Gain/Loss
                          </Typography>
                          )
                        : (
                          <>
                            <Typography variant='p' sx={{ fontSize: '14px' }}>
                              Last YTD External {ytdData[0].ytdRealLongGains >= 0 ? 'Gain' : 'Loss'} ${ytdData[0].ytdRealLongGains}
                            </Typography>
                          </>
                          )}
                    </>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <FormLabel className='viewReq account-request-label'>Long Gain/Loss Amount *</FormLabel>
                  <FormControl fullWidth>
                    <InputAdornmentTextField
                      className='text-title'
                      inputRef={inputAmount}
                      onKeyDown={(e) => isNumberOnly(e)}
                      onPaste={numberOnlyPasteHandler}
                      adornment={<AttachMoneyOutlinedIcon />}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormLabel className='viewReq account-request-label'>Short Gain/Loss *</FormLabel>
                  <FormControl fullWidth>
                    <TextField
                      className='text-title'
                      select
                      required
                      placeholder='Select'
                      inputRef={inputSelectedShortStrategy}
                    >
                      <MenuItem value='GAIN'>GAIN</MenuItem>
                      <MenuItem value='LOSS'>LOSS</MenuItem>
                    </TextField>
                  </FormControl>
                  {select === 'YTD_EXTERNAL_GAIN_LOSS' && (
                    <>
                      {!ytdData
                        ? (
                          <Typography sx={{ fontSize: '14px' }}>
                            No Past YTD External Gain/Loss
                          </Typography>
                          )
                        : (
                          <Typography variant='p' sx={{ fontSize: '14px' }}>
                            Last YTD External {ytdData[0].ytdRealShortGains >= 0 ? 'Gain' : 'Loss'} ${ytdData[0].ytdRealShortGains}
                          </Typography>
                          )}
                    </>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <FormLabel className='viewReq account-request-label'>Short Gain/Loss Amount*</FormLabel>
                  <FormControl fullWidth>
                    <InputAdornmentTextField
                      className='text-title'
                      inputRef={inputShortAmount}
                      onKeyDown={(e) => isNumberOnly(e)}
                      onPaste={numberOnlyPasteHandler}
                      adornment={<AttachMoneyOutlinedIcon />}
                    />
                  </FormControl>
                </Grid>
              </>
            )}
            {select === 'CASH_RAISE' && (
              <Grid item xs={12}>
                <FormLabel className='viewReq account-request-label'>Enter Amount *</FormLabel>
                <FormControl fullWidth>
                  <InputAdornmentTextField
                    className='text-title'
                    error={inputAmountError}
                    helperText={inputAmountError ? 'Amount can not be greater than total market value': ''}
                    inputRef={inputAmount}
                    onKeyDown={(e) => isNumberOnly(e)}
                    onPaste={numberOnlyPasteHandler}
                    onChange={validateInputAmount}
                    adornment={<AttachMoneyOutlinedIcon />}
                  />
                </FormControl>
              </Grid>
            )}
              <Grid item xs={12}>
                <FormLabel className='viewReq account-request-label'>Comment *</FormLabel>
                <FormControl fullWidth>
                  <TextField
                    className='text-title'
                    required
                    maxLength={500}
                    inputRef={inputComment}
                    multiline
                  />
                </FormControl>
              </Grid>
            <Grid item xs={12}>
              <Typography variant='p' sx={{ fontSize: '14px' }}>
                {select === 'GENERAL' && 'Estimated request completion time: 72 hours'}
                {select === 'CLOSE_ACCOUNT' && 'Estimated request completion time: 24 hours'}
                {select === 'GENERATE_PROPOSAL' && 'Estimated request completion time: 72 hours'}
                {select === 'CASH_RAISE' && 'Estimated request completion time: 72 hours'}
                {select === 'YTD_EXTERNAL_GAIN_LOSS' && 'Estimated request completion time: Immediate'}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box className='confirm-btn' sx={{ marginTop: '10px' }}>
                {loading
                  ? <LoadingButton
                      loading
                      variant='outlined'
                      loadingPosition='start'
                      startIcon={<SaveIcon />}
                    >
                    Submit
                  </LoadingButton>
                  : <Button type='submit' variant='contained' disabled={inputAmountError}>
                    Submit
                  </Button>}
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  )
}
export default RequestForm
