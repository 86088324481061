import React from 'react'
import { Grid } from '@mui/material'
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Bar } from 'react-chartjs-2'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
)

export function TradePopupChart (props) {
  return (
    <>
      <Grid xs={12} sm={12} height={props.chartHeight || '450px'}>
        <Bar options={props.options} data={props.data} width={650} height="100%" />
      </Grid>
    </>
  )
}
