import React, { useContext } from 'react'
import { Box, Divider, Stack, Typography } from '@mui/material'
import { v4 as uuidv4 } from 'uuid'
import { factorTiltsContext, industryContext, instrRestrictionContext, instrSubstitutionContext, sectorContext, policyDataContext } from '../Index'
import { TradeListTable } from './TradeListTable'

export const Policy = () => {
  const sectorData = useContext(sectorContext)
  const industryData = useContext(industryContext)
  const instrRestrictionData = useContext(instrRestrictionContext)
  const instrSubstitutionData = useContext(instrSubstitutionContext)
  const factorTiltsData = useContext(factorTiltsContext)
  const policyData = useContext(policyDataContext)

  const formattedData = factorTiltsData?.factorTiltsData?.map(data => {
    return {
      ...data,
      statsValue: Number(data?.statsValue) ? Number(data?.statsValue)?.toFixed(2) : data?.statsValue
    }
  })

  const scenarioData = policyData?.constraints?.data

  // get rows name and columns name
  const columns = ['Property Description'] // unique property scenario
  const rows = [] // unique property description
  scenarioData?.forEach(element => {
    element.properties?.forEach(property => {
      if (property.propertyScenario && !columns.find((scenario) => scenario === property.propertyScenario)) {
        columns.push(property.propertyScenario)
      }
      if (property.propertyDescription !== 'N/A' && !rows.find((scenario) => scenario === property.propertyDescription)) {
        rows.push(property.propertyDescription)
      }
    })
  })

  const generateScenarioFieldLabels = () => {
    // this function returns one array of fieldLabels that can be passed as prop to datagrid column
    const fieldLabels = []
    for (let i = 0; i < columns.length; i++) {
      const fieldLabel = {}
      fieldLabel.headerName = columns[i]
      fieldLabel.flex = 1
      if (i === 0) {
        /* static data
        for field that maps fieldLabels to rows in DataGrid is set to 'Property Description' and headerName which has to be shown on screen is set to 'Description' */
        fieldLabel.field = 'property_description'
        fieldLabel.headerName = 'Description'
        fieldLabels.push(fieldLabel)
        continue
      }
      // dynamic columns
      // field of any object won't consist space and changing to the lower case
      const field_value = columns[i].toLowerCase().replace(' ', '_')
      fieldLabel.field = field_value
      fieldLabel.renderCell = (params) => (
        <Box>
          {/* two properties has to be shown on one table field one below other */}
          {/* if one column value does not exist N/A has to be shown */}
          {params.row[field_value]
            ? params.row[field_value].map((value, index) => (
              <Box key={index}>{value}</Box>
            ))
            : <Box>N/A</Box>}
        </Box>
      )
      fieldLabels.push(fieldLabel)
    }
    return fieldLabels
  }

  const generateScenarioRowData = () => {
    // this function converts an array from column major to row major so it becomes easy to pass data to DataGrid
    const row_data = []
    for (let k = 0; k < rows.length; k++) {
      // iterates over the rows array which consist of all the unique 'scenario Description'
      const new_row = { id: uuidv4(), property_description: rows[k] }
      for (let i = 0; i < scenarioData?.length; i++) {
        // iterates over the unique columns (i.e. scenarioData has data in column major so whatever is length of the scenarioData it becomes the total column number)
        for (let j = 0; j < scenarioData[i].properties?.length; j++) {
          // iterates over whole data for each unique column
          const curr_data = scenarioData[i].properties[j]
          if (curr_data?.propertyDescription === rows[k]) {
            // check if we are accessing correct row to append data in new_row array
            new_row[curr_data?.propertyScenario?.toLowerCase().replace(' ', '_')] = [isNaN(curr_data?.propertyValue) ? curr_data?.propertyValue : parseFloat(curr_data?.propertyValue).toFixed(2), '(' + curr_data?.propertySource?.substring(0, 2) + ')']
          }
        }
      }
      row_data.push(new_row)
    }
    return row_data
  }

  const getScenarioPropertyAbbreviation = () => {
    /* get all the Property Source from the data object to show abbreviation outside the table */
    const propertySource = []
    for (let i = 0; i < scenarioData?.length; i++) {
      for (let j = 0; j < scenarioData[i].properties?.length; j++) {
        const curr_abbr = scenarioData[i].properties[j].propertySource
        if (!(curr_abbr === 'N/A' || curr_abbr === 'NA') && !propertySource.find((item) => item === curr_abbr)) {
          propertySource.push(curr_abbr)
        }
      }
    }
    return propertySource
  }

  const scenarioCols = generateScenarioFieldLabels()
  const scenarioRows = generateScenarioRowData()
  const propertySourceAbbr = getScenarioPropertyAbbreviation()

  return (
    <Stack>
      <TradeListTable tableHeader='Sector' columns={sectorData?.fieldLabels} rows={sectorData?.sectorData} />
      <Divider sx={{ bgcolor: 'rgba(116, 120, 141, 0.3)', marginTop: '22px', mx: -2 }} />
      <TradeListTable tableHeader='Industry' columns={industryData?.fieldLabels} rows={industryData?.industryData} />
      <Divider sx={{ bgcolor: 'rgba(116, 120, 141, 0.3)', marginTop: '22px', mx: -2 }} />
      <TradeListTable tableHeader='Restrictions' columns={instrRestrictionData?.fieldLabels} rows={instrRestrictionData?.instrRestrictionData} />
      <Divider sx={{ bgcolor: 'rgba(116, 120, 141, 0.3)', marginTop: '22px', mx: -2 }} />
      <TradeListTable tableHeader='Substitutions' columns={instrSubstitutionData?.fieldLabels} rows={instrSubstitutionData?.instrSubstitutionData} />
      <Divider sx={{ bgcolor: 'rgba(116, 120, 141, 0.3)', marginTop: '22px', mx: -2 }} />
      <TradeListTable tableHeader='Factor Tilts' columns={factorTiltsData?.fieldLabels} rows={formattedData} />
      <Divider sx={{ bgcolor: 'rgba(116, 120, 141, 0.3)', marginTop: '22px', mx: -2 }} />
      <TradeListTable tableHeader='Scenario' columns={scenarioCols} rows={scenarioRows} showStandardView />
      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-evenly', textAlign: 'center', marginBlock: '10px' }}>
        {propertySourceAbbr.length > 0
          ? propertySourceAbbr.map((value, index) => (
            <Typography key={index} sx={{ fontSize: '12px', color: '#34475A', fontWeight: 600 }}>
              {'(' + value?.substring(0, 2) + ') - ' + value}
            </Typography>
          ))
          : ''}
      </Box>
    </Stack>
  )
}
