import { getUserRole } from '../../../utils/getUserRole'
import { GetAPI } from '../components/Actions'

export const getInstrumentsList = async (searchTerm) => {
  const userRole = getUserRole()
  return GetAPI('baseUriTransactionalMaster', `transactional-master/v1/${userRole}/instruments`, { search: searchTerm })
}

const instrumentDetailsAPI = async (parameter, queryParams) => {
  const userRole = getUserRole()
  const filteredParams = {}
  // remove empty keys from query params
  for (const key in queryParams) {
    if (queryParams[key]) {
      filteredParams[key] = queryParams[key]
    }
  }
  return GetAPI('baseInstrumentMaintainURL', `data-maintenance/v1/${userRole}/instruments/${parameter}`, { ...filteredParams })
}

export const getAssetClassMapData = async (instrId, date) => {
  return instrumentDetailsAPI('asset-class-map', { instrId, dataDate: date })
}

export const getLastMarketData = async (instrId, date) => {
  return instrumentDetailsAPI('last-market-data', { instrId, dataDate: date })
}

export const getInstrumentMasterData = async (instr, date) => {
  return instrumentDetailsAPI('master', { instr, date })
}

export const getInstrumentIdData = async (instrId, date) => {
  return instrumentDetailsAPI('id', { instrId, date })
}

export const getSplitsCalData = async (fdate, tDate) => {
  return instrumentDetailsAPI('splits-cal', { fromDate: fdate, toDate: tDate })
}

export const getDelistingCalData = async (fdate, tDate) => {
  return instrumentDetailsAPI('delist-cal', { fromDate: fdate, toDate: tDate })
}

export const GetInstrumentData = async () => {
  // return empty object for each tab
  return [{}, {}, {}, {}, {}]
}
