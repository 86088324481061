import React, { useEffect, useMemo, useState } from 'react'
import { Grid, debounce, CircularProgress, Box } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import * as Sentry from '@sentry/react'
import dayjs from 'dayjs'
import { useErrorToast } from '../../../hooks/useErrorToast'

const CustomDatePickComponent = ({ dateSearchFunc, showData }) => {
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const [isFetching, setIsFetching] = useState(false)
  const [options, setOptions] = useState({})
  const { showError } = useErrorToast()

  const handleDateInputChange = useMemo(
    () =>
      debounce(async (fDate, tDate) => {
        if (fDate && tDate) {
          const optionsList = await dateSearchFunc(fDate ? dayjs(fDate).format('YYYY-MM-DD') : '', tDate ? dayjs(tDate).format('YYYY-MM-DD') : '')
          if (optionsList.error) {
            showError(optionsList.error)
            setIsFetching(false)
            Sentry.captureException(optionsList.error)
          } else if (optionsList?.data?.data) {
            optionsList.data.data = optionsList?.data?.data.map((optionObj, index) => ({ ...optionObj, idx: index }))
            setOptions(optionsList)
          }
        } else if (!fDate && tDate) {
          showError('From date required!')
          setIsFetching(false)
          return
        } else {
          showError('To date required!')
          setIsFetching(false)
          return
        }
        setIsFetching(false)
      }, 300), [fromDate, toDate, dateSearchFunc, options])

  // checks length of input to call search API
  useEffect(() => {
    if (fromDate && toDate) {
      setIsFetching(true)
      handleDateInputChange(fromDate, toDate)
    }
  }, [fromDate, toDate])

  useEffect(() => {
    if (dayjs(new Date())) {
      setFromDate(dayjs(new Date()))
      setToDate(dayjs(new Date()))
    }
  }, [])

  useEffect(() => {
    if (fromDate && toDate && options) {
      showData(options)
    }
  }, [fromDate, toDate, options])

  return (
    <Grid container gap={2} sx={{ padding: '20px 0' }}>
      <Grid item xs={5} lg={2}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label='Effective From Date'
            value={fromDate || dayjs(new Date())}
            onChange={setFromDate}
            slotProps={{ textField: { size: 'small' } }}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={5} lg={2}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label='Effective To Date'
            value={toDate || dayjs(new Date())}
            onChange={setToDate}
            slotProps={{ textField: { size: 'small' } }}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={5} lg={2}>
        <Box>
          {isFetching && <CircularProgress size={20} style={{ right: 10, marginTop: '15px' }} />}
        </Box>
      </Grid>
    </Grid>
  )
}

export default CustomDatePickComponent
