import './element-panel.css'
import EditElementSidebar from './editOption/EditElementSidebar.js'
import SummaryEditTable from './editOption/SummaryEditTable.js'
import CoverPageEditComponent from './editOption/CoverPageEditComponent.js'
import HeadingEditComponent from './editOption/HeadingEditComponent.js'
import SummaryBubbleChart from './editOption/SummaryBubbleChart.js'
import EditHeading from './editOption/EditHeading.js'
import PersonalizationConstraints from './editOption/PersonalizationConstraints.js'
import ImageHolderEdit from './editOption/ImageHolderEdit.js'
import CustomTableEdit from './editOption/CustomTableEdit.js'
import AggregateSidebarEdits from './editOption/AggregateSidebarEdits.js'
import CommonTableEdit from './editOption/CommonTableEdit.js'

export const chartComponentMap = {
  'Market cap vertical bar table': PersonalizationConstraints,
  'Performance line chart': EditElementSidebar,
  // 'Market cap horizontal bar chart': EditElementSidebar,
  'Factor bar chart': EditElementSidebar,
  'Asset allocation chart': EditElementSidebar,
  'Summary Table': SummaryEditTable,
  'Sector constraints': SummaryEditTable,
  'Industry constraints': SummaryEditTable,
  'Security constraints': SummaryEditTable,
  'Substitution constraints': SummaryEditTable,
  'Factor Tilts constraints': SummaryEditTable,
  'Top Positions': SummaryEditTable,
  'Risk By Security': SummaryEditTable,
  'Risk By Sector': SummaryEditTable,
  'Market Cap table': SummaryEditTable,
  'Factor table': SummaryEditTable,
  // 'Personalization constraints': SummaryEditTable,
  Scenario: SummaryEditTable,
  'Summary Bubble Chart': SummaryBubbleChart,
  'Title Text': EditHeading,
  'Paragraph Text': EditHeading,
  Definitions: EditHeading,
  'Cover page 1': CoverPageEditComponent,
  'Cover page 2': CoverPageEditComponent,
  'Cover page 3': CoverPageEditComponent,
  'Page Heading 1': HeadingEditComponent,
  'Page Heading 2': HeadingEditComponent,
  'Page Footer 1': HeadingEditComponent,
  'Page Footer 2': HeadingEditComponent,
  'Page Footer 3': HeadingEditComponent,
  'Tax Management Summary Chart': EditElementSidebar,
  'Tax Management Comparison Block': EditElementSidebar,
  'Personalization constraints': PersonalizationConstraints,
  'Image Holder': ImageHolderEdit,
  'Section Page': EditHeading,
  'Custom Table': CustomTableEdit,
  'Cash Flow Summary': SummaryEditTable,
  'Activity Summary': SummaryEditTable,
  'Aggregate Activity Summary': SummaryEditTable,
  'Aggregate Assets Chart': AggregateSidebarEdits,
  'Aggregate Assets Table': CommonTableEdit,
  'Aggregate Performance': CommonTableEdit
}
