import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { styled } from '@mui/material'

const StyledExpandMoreIcon = styled(({ className, ...props }) => (
  <ExpandMoreIcon {...props} className='arrow-icon' />
))``

const StyledChevronRightIcon = styled(({ className, ...props }) => (
  <ChevronRightIcon {...props} className='arrow-icon' />
))``
export { StyledChevronRightIcon, StyledExpandMoreIcon }
