import React, { useState, useRef } from 'react'
import Papa from 'papaparse'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
  Box
} from '@mui/material'
import './TradeCompare.css'
import addIcon from '../../.././assets/images/icon-add-plus-circle.svg'
import fileIcon from '../../../assets/images/icon-file.svg'
import Loader from '../../Loader'
import { useErrorToast } from '../../../hooks/useErrorToast'

const TradeComparatorMain = () => {
  const [file1, setFile1] = useState(null)
  const [file2, setFile2] = useState(null)
  const [compareResult, setCompareResult] = useState([])
  const [compareType, setCompareType] = useState('diffOnly')
  const [file1Info, setFile1Info] = useState({ name: '', size: '' })
  const [file2Info, setFile2Info] = useState({ name: '', size: '' })
  const fileInput1Ref = useRef(null)
  const fileInput2Ref = useRef(null)
  const [isFileUploading, setIsFileUploading] = useState(false)
  const [comparisonPerformed, setComparisonPerformed] = useState(false)
  const [tableHeaders, setTableHeaders] = useState([])

  const { showError } = useErrorToast()

  const handleFile1Change = (e) => {
    setIsFileUploading(true)
    const selectedFile = e.target.files[0]
    setFile1(selectedFile)
    setFile1Info({ name: selectedFile?.name, size: selectedFile?.size })
    setIsFileUploading(false)
  }

  const handleFile2Change = (e) => {
    setIsFileUploading(true)
    const selectedFile = e.target.files[0]
    setFile2(selectedFile)
    setFile2Info({ name: selectedFile?.name, size: selectedFile?.size })
    setIsFileUploading(false)
  }

  const handleCompare = () => {
    // Check if either of the input files is missing
    if (!file1 || !file2) return

    // Parse the first CSV file
    Papa.parse(file1, {
      header: false, // No headers
      skipEmptyLines: true,
      complete: (result1) => {
        const { data: csvData1 } = result1

        // Parse the second CSV file
        Papa.parse(file2, {
          header: false, // No headers
          skipEmptyLines: true,
          complete: (result2) => {
            const { data: csvData2 } = result2

            // Check for parsing errors in either file
            if (!csvData1 || !csvData2) {
              showError('Parsing error.')
              return
            }

            // Find the maximum length of rows in either CSV
            const maxLength = Math.max(csvData1.length, csvData2.length)
            const compareResult = []

            // Define the headers (Field1, Field2, ...)
            const headers = ['Line Number']
            for (let i = 1; i <= Math.max(csvData1[0]?.length, csvData2[0]?.length); i++) {
              headers.push(`Field ${i}`)
            }

            // Compare each row and column in the CSV files
            for (let i = 0; i < maxLength; i++) {
              const row1 = csvData1[i] || []
              const row2 = csvData2[i] || []
              let hasDifferences = false // Flag to track differences in the row
              const comparedRow = []

              // Add the line number to the comparedRow
              comparedRow.push(`${i + 1}`)

              // Compare each cell in the row
              for (let j = 0; j < Math.max(row1.length, row2.length); j++) {
                const value1 = row1[j] || '' // Default to empty string if value is undefined
                const value2 = row2[j] || '' // Default to empty string if value is undefined
                let diff = ''

                // Determine the difference between values in the same position
                if (value1 !== value2) {
                  if (value1 && value2) {
                    diff = `${value1} != ${value2}`
                  } else if (value1) {
                    diff = `${value1} != " "`
                  } else if (value2) {
                    diff = `" " != ${value2}`
                  }
                  hasDifferences = true // Set the flag to indicate differences
                } else {
                  diff = value1 || value2
                }

                // Store the diff in comparedRow
                comparedRow.push(diff)
              }

              // Only store the row if it has differences
              if (hasDifferences) {
                compareResult.push(comparedRow)
              }
            }

            // Update the state with the comparison result, including headers
            setTableHeaders(headers)
            setCompareResult(compareResult)
            setComparisonPerformed(true)
          },
          error: (error) => {
            showError('File2 parsing error:', error)
          }
        })
      },
      error: (error) => {
        showError('File1 parsing error:', error)
      }
    })
  }

  const handleFile1LabelClick = () => {
    fileInput1Ref.current.click()
  }

  const handleFile2LabelClick = () => {
    fileInput2Ref.current.click()
  }

  const handleCancel = (file) => {
    if (file === 'file1') {
      setFile1(null) // Clear the selected file
      setFile1Info({ name: '', size: '' }) // Clear file information
      fileInput1Ref.current.value = ''
    } else {
      setFile2(null) // Clear the selected file
      setFile2Info({ name: '', size: '' }) // Clear file information
      fileInput2Ref.current.value = ''
    }
  }

  return (
    <Box className='Compare-trade compare-sticky'>
      <Box>
        <Typography variant='h3' className='page-title' mb={2}>
          Trade Compare
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card className='card-layout'>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Box className='upload-container' sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box
                      className='upload-area'
                      onDragEnter={(e) => e.preventDefault()}
                      onDragOver={(e) => e.preventDefault()}
                      onDrop={(e) => {
                        e.preventDefault()
                        const droppedFile = e.dataTransfer.files[0]
                        setFile1(droppedFile)
                      }}
                    >
                      <Box className='upload-text' display='flex' justifyContent='space-between'>
                        <Box onMouseDown={handleFile1LabelClick} sx={{ display: 'flex', alignItems: 'center' }}>
                          <img
                            className='cursor-pointer'
                            src={addIcon}
                            alt='add'
                            height={30}
                            width={30}
                          />
                          <Typography sx={{ padding: '5px 0px 0 10px', fontSize: '16px' }}>
                            Drop your files here or browse
                          </Typography>
                        </Box>
                        <input
                          ref={fileInput1Ref}
                          className='upload-input hidden-input'
                          type='file'
                          id='file1-input'
                          accept='.csv'
                          onChange={handleFile1Change}
                        />
                        {file1
                          ? <Box className='uploaded-file-block' sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <Box className='file-upload-img' sx={{ backgroundColor: 'rgba(23, 92, 194, 0.5)', padding: '7px', color: 'white', width: '24px', height: '24px', display: 'flex', justifyContent: 'center' }} align='center' mr={1}>
                                <img
                                  src={fileIcon}
                                  alt=''
                                  height={10}
                                  width={10}
                                />
                              </Box>
                              <Box>
                                <Typography className='uploaded-file-text'>Name: {file1Info?.name}</Typography>
                                <Typography className='uploaded-file-text'>Size: {file1Info?.size} bytes</Typography>
                              </Box>
                              <Box sx={{ display: 'flex' }} p={1}>
                                <Button
                                  className='cancel-button'
                                  type='button'
                                  onClick={() => handleCancel('file1')}
                                  size='small'
                                  sx={{ fontSize: '12px !important', marginLeft: '10px' }}
                                >
                                  Cancel
                                </Button>
                              </Box>
                              {isFileUploading ? <Loader /> : null}
                            </Box>
                          </Box>
                          : ''}
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box className='upload-container' sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box
                      className='upload-area'
                      onDragEnter={(e) => e.preventDefault()}
                      onDragOver={(e) => e.preventDefault()}
                      onDrop={(e) => {
                        e.preventDefault()
                        const droppedFile = e.dataTransfer.files[0]
                        setFile2(droppedFile)
                      }}
                    >
                      <Box className='upload-text' display='flex' justifyContent='space-between'>
                        <Box onMouseDown={handleFile2LabelClick} sx={{ display: 'flex', alignItems: 'center' }}>
                          <img
                            className='cursor-pointer'
                            src={addIcon}
                            alt='add'
                            height={30}
                            width={30}
                          />
                          <Typography sx={{ padding: '5px 0px 0 10px', fontSize: '16px' }}>
                            Drop your files here or browse
                          </Typography>
                        </Box>
                        <input
                          ref={fileInput2Ref}
                          className='upload-input hidden-input'
                          type='file'
                          id='file2-input'
                          accept='.csv'
                          onChange={handleFile2Change}
                        />
                        {file2
                          ? <Box className='uploaded-file-block' sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <Box className='file-upload-img' sx={{ backgroundColor: 'rgba(23, 92, 194, 0.5)', padding: '7px', color: 'white', width: '24px', height: '24px', display: 'flex', justifyContent: 'center' }} align='center' mr={1}>
                                <img
                                  src={fileIcon}
                                  alt=''
                                  height={10}
                                  width={10}
                                />
                              </Box>
                              <Box>
                                <Typography className='uploaded-file-text'>Name: {file2Info?.name}</Typography>
                                <Typography className='uploaded-file-text'>Size: {file2Info?.size} bytes</Typography>
                              </Box>
                              <Box sx={{ display: 'flex' }} p={1}>
                                <Button
                                  className='cancel-button'
                                  type='button'
                                  onClick={() => handleCancel('file2')}
                                  size='small'
                                  sx={{ fontSize: '12px !important', marginLeft: '10px' }}
                                >
                                  Cancel
                                </Button>
                              </Box>
                              {isFileUploading ? <Loader /> : null}
                            </Box>
                          </Box>
                          : ''}
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} display='flex' justifyContent='center'>
                  <Box className='compare-button-container'>
                    <Button
                      variant='contained'
                      onClick={() => {
                        if (!file1 || !file2) {
                          showError('Please select two CSV files for comparison.')
                          return
                        }
                        handleCompare()
                      }}
                    >
                      Compare
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Grid item xs={12} my={4}>
            {comparisonPerformed && compareResult.length === 0
              ? <Card className='card-layout'>
                <CardContent>
                  <Typography mt={1}>Both the files are identical</Typography>
                </CardContent>
                </Card>
              : compareResult.length > 0
                ? <Card className='card-layout' sx={{ overflow: 'auto' }}>
                  <CardContent>
                    <Box>
                      <Typography variant='h5' mb={1}>CSV Comparison</Typography>
                      <Typography mb={1}>Both the files are not matching</Typography>
                      <Table>
                        <TableHead>
                          <TableRow>
                            {tableHeaders.map((header, index) => (
                              <TableCell sx={{ minWidth: '95px' }} key={index}>
                                {header}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {compareResult?.map((row, rowIndex) => {
                            const hasDifferences = Object.values(row).some(cell => cell.trim() !== '')
                            if (compareType === 'diffOnly' && hasDifferences) {
                              return (
                                <TableRow key={rowIndex}>
                                  {Object.values(row)?.map((cell, cellIndex) => {
                                    return (
                                      <TableCell
                                        key={cellIndex}
                                        style={
                                        cell.includes('!=')
                                          ? { backgroundColor: '#ffebee' } // highlighting the mismatched cell
                                          : {}
                                      }
                                      >{cell}
                                      </TableCell>
                                    )
                                  })}
                                </TableRow>
                              )
                            }
                            return null
                          })}
                        </TableBody>
                      </Table>
                    </Box>
                  </CardContent>
                  </Card>
                : null}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default TradeComparatorMain
