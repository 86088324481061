import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Chart as ChartJS, registerables } from 'chart.js'
import {
  assetAllocationChartData,
  fetchDataAndDispatch
} from './APIResponseConverts/APIResponseConverts'
import {
  AllocationChart
} from './APIResponseConverts/chartDefaultsData'
import { useDispatch, useSelector } from 'react-redux'
import { addChartValues } from '../../../store/pdf-customizer-reducer/Action/chartDropAction'
import { assetAllocationChartApi } from '../../../utils/pdf-customizer/_data'
import { assetAllocationChartOptions } from '../common/ChartOptions/options'
import ReactApexChart from 'react-apexcharts'
import { useAuth } from '../../../contexts/AuthContext'

ChartJS.register(...registerables)

const AssetAllocationChart = ({
  data,
  chartIndex,
  pageIndex
}) => {
  const { allocationChartResponse, isResponseElements } =
    useSelector((state) => state.chartState)
  const templateData = useSelector(state => state.templateData)
  const dispatch = useDispatch()
  const chartRef = useRef(null)
  const [isLoading, setIsLoading] = useState(false)
  const { user } = useAuth()

  useEffect(() => {
    if (isResponseElements && !allocationChartResponse && !isLoading) {
      setIsLoading(true)
      fetchDataAndDispatch(
        () => assetAllocationChartApi(templateData, user?.userGroup),
        'ALLOCATION_CHART',
        dispatch
      )
    }
    if (isResponseElements && allocationChartResponse) {
      setIsLoading(false)
    }
  }, [isResponseElements, templateData, dispatch, allocationChartResponse])

  const assetAllocationData = assetAllocationChartData(
    allocationChartResponse || AllocationChart
    ,
    'name',
    'assetClass',
    data.value || null
  )

  const barChartData = useMemo(() => {
    return assetAllocationData?.datasets?.map(dataset => ({ name: dataset.label, ...dataset }))
  }, [assetAllocationData])

  let options = { ...assetAllocationChartOptions(assetAllocationData?.labels || []), colors: data?.value?.datasets?.length ? data?.value?.datasets?.map(dataset => dataset.backgroundColor) : ['#7ba0c4'] }
  useEffect(() => {
    if (data?.value?.datasets) {
      const filteredColor = data?.value?.datasets?.map((dataset) => dataset.backgroundColor)
      options = { ...assetAllocationChartOptions(assetAllocationData?.labels || []), colors: filteredColor }
    }
    if (data.value) return
    if (!assetAllocationData) return
    const { labels, datasets } = assetAllocationData
    const mappedLabels =
      Array.isArray(labels) &&
      labels.map((label) => ({
        name: label,
        hidden: false
      }))

    const mappedDatasets =
      Array.isArray(datasets) &&
      datasets.map(({ label, backgroundColor }) => ({
        label,
        backgroundColor,
        hidden: false
      }))

    const chartValues = {
      labels: mappedLabels,
      datasets: mappedDatasets,
      chartIndex: data?.chartIndex,
      pageIndex: data?.pageIndex
    }
    dispatch(addChartValues(chartValues))
  }, [assetAllocationData, dispatch, data])

  useEffect(() => {
    const { current: chartInstance } = chartRef
    const { value } = data
    if (
      !value ||
      !value.datasets ||
      !value.labels ||
      !barChartData ||
      !chartInstance
    ) {
      return
    }
    try {
      const { datasets, labels } = value
      datasets.forEach((dataset, index) => {
        const chartDataset = barChartData[index]
        Object.assign(chartDataset, {
          hidden: dataset.hidden,
          backgroundColor: dataset.backgroundColor,
          borderColor: dataset.borderColor
        })
      })
      const filteredColor = datasets.map((dataset) => dataset.backgroundColor)
      options = { ...assetAllocationChartOptions(assetAllocationData?.labels || []), colors: filteredColor }
    } catch (error) {
      console.error('Error in useEffect:', error)
    }
  }, [data])

  const chartStyle = {
    height: '100%',
    width: '100%',
  }

  return (
    <div
      className={'group'}
      key={data?.chartIndex + data?.pageIndex}
      style={chartStyle}
    >
      <p style={{ margin: '0', fontSize: '10px', fontWeight: 500, textTransform: 'capitalize', lineHeight: '10px' }}>
        {data.headingText || data.name}
      </p>
      {isLoading
        ? <span className='loading-text'>Loading...</span>
        : <ReactApexChart ref={chartRef} type='bar' height={'100%'} options={options} series={barChartData || []} />}
    </div>
  )
}

export default AssetAllocationChart
