import React from 'react'
import { Box, Modal, Typography } from '@mui/material'
import Success from '../assets/images/success.svg'
import '../pages/AccountOnboarding/AccountOnboarding.scss'
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
  borderRadius: '4px'
}
function SuccessModal ({ message, ...restProps }) {
  return (
    <Modal
      open
      aria-labelledby='contained-modal-title-vcenter'
      centered
      {...restProps}
    >
      <Box sx={style}>
        <Box className='submitBox text-center'>
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
            <img src={Success} alt='' height={100} width={100} />
          </Box>
          <Typography variant='h4' align='center' mb={1} sx={{ fontFamily: 'Open Sans' }}>
            {message}
          </Typography>
        </Box>
      </Box>
    </Modal>
  )
}

export default SuccessModal
