import {
  CONTENT_UPDATE,
  ITEM_DRAGGING,
  NEW_PAGE,
  IS_ACTIVE,
  IS_ACTIVE_REMOVE,
  BACKGROUND_CHANGE,
  BACKGROUND_RESET,
  DRAG_CHANGE,
  EDIT_CHARTS,
  DELETE_CHART,
  MIDDLE_PAGE,
  EDIT_LABEL_CHANGE,
  SET_REPORT,
  // IS_LOADING,
  DELETE_PAGE,
  IS_ACTIVE_PAGE,
  FONT_SIZE_CHANGE,
  ZOOM_PAGE,
  EDITOR_NAME_CHANGE,
  CHART_NAME_CHANGE,
  CHART_VALUES_ADD,
  COVER_NAME_CHANGE,
  REORDER_LABELS,
  UPDATE_HEADER_OR_FOOTER_CHANGE,
  RE_SET_DATA,
  CHANGE_TEXT_UPDATE,
  SELECTED_TEMPLATES,
  // DRAG_CHANGE_SECOND,
  PDF_TO_IMAGES,
  COVER_PAGE_DRAGGING,
  ADD_COVER_PAGE_DATA,
  CLOSE_PDF_MODAL,
  UPDATE_ELEMENT_OBJECT,
  STORE_SVG_CODE,
  CHANGE_PAGE_LAYOUT,
  STORE_CUSTOM_TABLE_ROWS,
  CHANGE_CUSTOM_TABLE_OVERFLOW,
  HANDLE_TABLE_OVERFLOW,
  CREATE_PAGE_FOR_TABLE_OVERFLOW,
  CREATE_PAGE_FOR_OVERFLOWING_ELEMENTS,
  QUEUE_CHANGE,
  PREVIOUS_EDITOR_NAME_CHANGE
} from './chartDropAction.types.js'
import { store } from '../../index.js'

export const itemDragging = (data) => ({
  type: ITEM_DRAGGING,
  payload: data
})
export const addCoverPage = (data) => {
  const { chartState } = store.getState()

  return {
    type: COVER_PAGE_DRAGGING,
    payload: data,
    taxSavingDataResponse: chartState?.taxSavingDataResponse || undefined
  }
}
export const updatedContent = (data) => ({
  type: CONTENT_UPDATE,
  payload: data
})
export const addPage = (data) => ({
  type: NEW_PAGE,
  payload: data
})
export const isActiveHandle = (data) => ({
  type: IS_ACTIVE,
  payload: data
})
export const reportDataSetPage = (data) => {
  return {
    type: SET_REPORT,
    payload: data
  }
}
export const removeActiveHandle = (data) => ({
  type: IS_ACTIVE_REMOVE,
  payload: data
})
// export const isLoadingSet = (data) => ({
//   type: IS_LOADING,
//   payload: data
// })
export const addCoverPageData = (data) => ({
  type: ADD_COVER_PAGE_DATA,
  payload: data
})
export const updatedElementState = (data) => ({
  type: UPDATE_ELEMENT_OBJECT,
  payload: data
})
export const changeBackground = (data) => ({
  type: BACKGROUND_CHANGE,
  payload: data
})

export const onDragChange = (data) => ({
  type: DRAG_CHANGE,
  payload: data
})
export const onDeleteChart = (data) => ({
  type: DELETE_CHART,
  payload: data
})
export const changeEditorName = (data) => ({
  type: EDITOR_NAME_CHANGE,
  payload: data
})
export const resetTemplateData = (data) => ({
  type: RE_SET_DATA,
  payload: data
})
export const chartEditorName = (data) => ({
  type: CHART_NAME_CHANGE,
  payload: data
})
export const coverNameEditor = (data) => ({
  type: COVER_NAME_CHANGE,
  payload: data
})
export const pdfToImages = (data) => ({
  type: PDF_TO_IMAGES,
  payload: data
})
export const pdfModalClose = (data) => ({
  type: CLOSE_PDF_MODAL,
  payload: data
})
export const upDatePageHeadingFooter = (data) => ({
  type: UPDATE_HEADER_OR_FOOTER_CHANGE,
  payload: data
})
export const addChartValues = (data) => ({
  type: CHART_VALUES_ADD,
  payload: data
})
export const reorderLabels = (newLabelOrder) => ({
  type: REORDER_LABELS,
  payload: newLabelOrder
})

export const resetBackground = (data) => ({
  type: BACKGROUND_RESET,
  payload: data
})

export const deletePageWithAllData = (data) => ({
  type: DELETE_PAGE,
  payload: data
})
export const handleAddMidPage = (data) => ({
  type: MIDDLE_PAGE,
  payload: data
})
export const isActivePageHandle = (data) => ({
  type: IS_ACTIVE_PAGE,
  payload: data
})
export const pageZoom = (data) => ({
  type: ZOOM_PAGE,
  payload: data
})

export const queueChange = (data) => ({
  type: QUEUE_CHANGE,
  payload: data
})

export const hadingFontSize = (data) => {
  const { elementStates } = store.getState()
  const { pages, activeElement } = elementStates
  const activeChart = pages.flatMap((page) => page.elements).find((element) => element?.id === activeElement)

  if (!activeChart) {
    console.warn('No active chart found.')
    return null
  }
  return {
    type: FONT_SIZE_CHANGE,
    payload: {
      ...activeChart,
      value: { ...activeChart.value, [data.name]: data.value }
    }
  }
}
export const headingChangeText = (data) => {
  const { elementStates } = store.getState()
  const { pages, activeElement } = elementStates
  const activeChart = pages.flatMap((page) => page.elements).find((element) => element?.id === activeElement)
  if (!activeChart) {
    console.warn('No active chart found.')
    // action should be object not null
    return {
      type: CHANGE_TEXT_UPDATE,
      payload: {
        ...(activeChart || {}),
        value: {
          ...(activeChart?.value || {}),
        }
      }
    }
  }
  if (activeChart?.name === 'Section Page') {
    return {
      type: CHANGE_TEXT_UPDATE,
      payload: {
        ...activeChart,
        value: {
          ...activeChart.value,
          [data.name]: data.value
        }
      }
    }
  }
  return {
    type: CHANGE_TEXT_UPDATE,
    payload: {
      ...activeChart,
      [data.name]: data.value
    }
  }
}

export const onEditChart = (data) => {
  const { elementStates } = store.getState()
  const { pages, activeElement } = elementStates

  const activeChart = pages.flatMap((page) => page.elements).find((element) => element?.id === activeElement)


  if (!activeChart) {
    console.warn('No active chart found.')
    return null
  }

  const { datasets, labels } = activeChart.value

  if (!Array.isArray(datasets) || !Array.isArray(labels)) {
    console.warn('Invalid datasets or labels.')
    return null
  }
  labels.forEach((label) => {
    if (data.label === label.name) {
      label.hidden = !label.hidden
    }
  })
  return {
    type: EDIT_CHARTS,
    payload: {
      ...activeChart,
      value: { ...activeChart.value, datasets, labels }
    },
    index: data.index
  }
}
export const selectedTemplates = (data) => ({
  type: SELECTED_TEMPLATES,
  payload: data
})
export const editLabelChart = (data) => {
  const { pages: updatedPagesIsActive, activeElement } = store.getState().elementStates

  const activeChart = updatedPagesIsActive
    .flatMap((page) => page.elements)
    .find((element) => element.id === activeElement)

  if (!activeChart) {
    console.warn('No active chart found.')
    return {}
  }

  const { datasets } = activeChart.value

  if (Array.isArray(datasets)) {
    // why to iterate over datasets, when index is available in data object
    const updatedDatasets = datasets.map((dataset, colIndex) =>
      colIndex === data.index
        ? { ...dataset, hidden: !data.checked }
        : dataset
    )

    return {
      type: EDIT_LABEL_CHANGE,
      payload: {
        ...activeChart,
        value: { ...activeChart.value, datasets: updatedDatasets }
      }
    }
  } else {
    console.warn('Invalid datasets or labels.')
    return null
  }
}
export const storeSvgCode = (data) => ({
  type: STORE_SVG_CODE,
  payload: data
})

export const changePageLayout = (data) => ({
  type: CHANGE_PAGE_LAYOUT,
  payload: data
})

export const storeCustomTableRows = (data) => ({
  type: STORE_CUSTOM_TABLE_ROWS,
  payload: data
})

export const changeCustomTableOverflow = (data) => ({
  type: CHANGE_CUSTOM_TABLE_OVERFLOW,
  payload: data
})

export const handleTableOverflow = (data) => ({
  type: HANDLE_TABLE_OVERFLOW,
  payload: data
})

// export const createPageForTableOverflow = (data) => ({
//   type: CREATE_PAGE_FOR_TABLE_OVERFLOW,
//   payload: data
// })

export const createPageForOverflowingElements = (data) => ({
  type: CREATE_PAGE_FOR_OVERFLOWING_ELEMENTS,
  payload: data
})

export const changePreviousEditorName = (data) => ({
  type: PREVIOUS_EDITOR_NAME_CHANGE,
  payload: data
})