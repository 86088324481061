import { NavLink } from 'react-router-dom'
import { ListItem, ListItemIcon, ListItemText, Tooltip, useTheme } from '@mui/material'
import 'rc-slider/assets/index.css'
import { hexToRgba } from '../utils/HexOperations'
import { useAuth } from '../contexts/AuthContext'
import { useSelector } from 'react-redux'

const NonSubmenuItems = (props) => {
  const { activePath, icon, linkTo, label, id, handleSubmenuToggle, isSidebarOpen, leftSidebarProps } = props
  const { user } = useAuth()
  const themeData = useSelector((state) => state.theme)
  const isActive = activePath === linkTo
  const theme = useTheme()

  // set dynamic colors for leftside bar service name text
  const primaryText = user?.userGroup === 'adv-classic' && theme.palette.primaryPalette.contrastText ? theme.palette.primaryPalette.contrastText : user?.userGroup === 'admin' && leftSidebarProps ? leftSidebarProps?.brandTextColor : 'white'
  const secondaryText = user?.userGroup === 'adv-classic' && theme.palette.paletteTextColor.contrastText
    ? hexToRgba(theme.palette.paletteTextColor.contrastText, themeData?.opacity)
    : user?.userGroup === 'admin' && leftSidebarProps ? hexToRgba(leftSidebarProps?.brandTextColor, leftSidebarProps.opacity) : 'rgba(255, 255, 255, 0.5)'

  return (
    <>
      <ListItem
        className='menu-item'
        component={NavLink}
        onClick={handleSubmenuToggle}
        to={linkTo}
        id={id}
      >
        <Tooltip title={isSidebarOpen ? '' : label} placement='right'>
          <ListItemIcon id={id + '-icon'} sx={{ color: isActive ? primaryText : secondaryText }}>
            {
              icon?.type === 'svg'
                ? <icon.inactive sx={{ width: '20px', height: '20px', color: isActive ? primaryText : secondaryText }} />
                : icon?.type === 'component'
                  ? <icon.component color={isActive ? primaryText : secondaryText} size='18px' />
                  : <img
                      src={isActive ? icon?.active : icon?.inactive}
                      color={isActive ? primaryText : secondaryText}
                      alt='menu-icon'
                      className='inline-block'
                      style={{ width: '18px', height: '18px' }}
                    />
            }
          </ListItemIcon>
        </Tooltip>
        <ListItemText sx={{ color: isActive ? primaryText : secondaryText }} primary={label} className='menu-link' />
      </ListItem>
    </>
  )
}

export default NonSubmenuItems
