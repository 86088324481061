import { getUserRole } from '../../../utils/getUserRole'
import { DeleteAPI } from '../components/Actions'
import { FormatDateAPI } from '../components/FormatAPIRequest'

export const DeleteInstrumentData = async (deleteRow, tabIndex) => {
  switch (tabIndex) {
    case 'instrument-delist':
      return await DeleteInstrumentDelistCal(deleteRow)
    default:
      return {}
  }
}

const DeleteInstrumentDelistCal = async (deleteRow) => {
  const startDate = deleteRow?.startDate ? FormatDateAPI(deleteRow?.startDate) : null
  const userRole = getUserRole()
  return DeleteAPI(
    'baseInstrumentMaintainURL',
        `data-maintenance/v1/${userRole}/instruments/delist-cal/${deleteRow?.instrId}/${startDate}`
  )
}
