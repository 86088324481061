import React, { useContext } from 'react'
import { Box, Grid, Stack } from '@mui/material'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { formatCurrency } from '../../../utils/FormateCurrenyInMilion'
import { portfolioDataContext } from '../Index'
import { ReportExpandAssetAllocation } from './PortfolioComponent/expandRowdata'

export const Portfolio = () => {
  const AssetData = useContext(portfolioDataContext)
  const columns = [
    { field: 'assetClassDesc', headerName: 'Asset Category', flex: 1 },
    {
      field: 'weight',
      headerName: 'Weight (%)',
      type: 'number',
      align: 'right',
      flex: 1,
      valueGetter: (params) => {
        return params.value !== undefined
          ? `${(Number(params.value) * 100).toFixed(2)}`
          : '0.00'
      }
    },
    {
      field: 'costBasis',
      headerName: 'Cost Basis ($)',
      type: 'number',
      align: 'right',
      flex: 1,
      valueFormatter: (params) =>
        params.value !== undefined
          ? formatCurrency(params.value) // Use formatCurrency to format the value
          : '0'
    },
    {
      field: 'currentValue',
      headerName: 'Current value ($)',
      type: 'number',
      align: 'right',
      flex: 1,
      valueFormatter: (params) =>
        params.value !== undefined
          ? formatCurrency(params.value) // Use formatCurrency to format the value
          : '0'
    }
  ]

  const getDetailPanelContent = (params) => {
    return (
      <ReportExpandAssetAllocation row={params.row} />
    )
  }

  return (
    <Stack>
      <Grid xs={12}>
        <Box className='trade-table-list-header'>Asset Allocation</Box>
        <Box style={{ width: '100%' }}>
          <DataGridPro
            density='compact'
            autoHeight
            rows={AssetData?.portfolioData || []}
            columns={columns}
            hideFooter
            disableSelectionOnClick
            isRowSelectable={() => false}
            getRowId={(row) => row.id}
            getDetailPanelHeight={({ row }) => 'auto'} // Height based on the content.
            getDetailPanelContent={getDetailPanelContent}
            sx={{
              border: 'none',
              '.MuiDataGrid-columnHeaderTitle': {
                color: '#74788D',
                fontFamily: 'Open Sans',
                fontWeight: 600
              },
              '.MuiDataGrid-cellContent': {
                color: '#34475A',
                fontFamily: 'Open Sans',
                fontWeight: 600
              },
              '.MuiDataGrid-footerContainer': {
                border: 'none'
              },
              '.MuiDataGrid-root': {
                border: 'none'
              },
              '.MuiTablePagination-toolbar': {
                alignItems: 'baseline'
              }
            }}
          />
        </Box>
      </Grid>
    </Stack>
  )
}
