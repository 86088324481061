import React, { useEffect, useRef, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Chart as ChartJS, registerables } from 'chart.js'
import { addChartValues } from '../../../store/pdf-customizer-reducer/Action/chartDropAction'
import { marketCapAllocationApi } from '../../../utils/pdf-customizer/_data'
import { useAuth } from '../../../contexts/AuthContext'
import { MarketChartOptions } from '../common/ChartOptions/options'
import {
  fetchDataAndDispatch,
  dataTableData,
  isNumeric,
  decTwoPercentage
} from './APIResponseConverts/APIResponseConverts'
import { marketCapChart } from './APIResponseConverts/chartDefaultsData'
import './MarketCap.css'

ChartJS.register(...registerables)
const MarketCap = ({ data, chartIndex, index, chartHeight, clickEvent }) => {
  const { marketCapChartResponse, isResponseElements } = useSelector(
    (state) => state.chartState
  )
  const templateData = useSelector(state => state.templateData)
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const chartRef = useRef(null)
  const { user } = useAuth()
  const value = data?.value || {}

  useEffect(() => {
    if (isResponseElements && !marketCapChartResponse && !isLoading) {
      setIsLoading(true)
      fetchDataAndDispatch(() => marketCapAllocationApi(templateData, user?.userGroup), 'MARKET_CHART', dispatch)
    }
    if (isResponseElements && marketCapChartResponse) {
      setIsLoading(false)
    }
  }, [isResponseElements, marketCapChartResponse, dispatch])

  const { labels = [], datasets = [] } = data?.value || {}
  const { headingColumns, tableRows, tableWidth, tableTextAlign } = dataTableData(
    marketCapChartResponse || marketCapChart,
    data.name
  )

  const compareTableRowsAndDatasets = (datasets, tableRows) => {
    if (tableRows.length !== datasets.length) {
      return false
    }
    let flag = true
    datasets?.forEach((obj, index) => {
      if (obj?.label !== tableRows[index]?.noNameShow) {
        flag = false
      }
    })
    return flag
  }

  useEffect(() => {
    if (datasets?.length && tableRows?.length) {
      if (!compareTableRowsAndDatasets(datasets, tableRows)) {
        const mappedLabels = Object.keys(headingColumns).map(label => ({
          label
        }))
        const mappedDatasets = tableRows?.map((obj, index) => ({
          label: obj?.noNameShow,
          hidden: datasets?.length - 1 >= index ? datasets[index]?.hidden : false
        }))
        const chartData = {
          labels: mappedLabels,
          datasets: mappedDatasets,
          chartIndex: data?.chartIndex,
          pageIndex: data?.pageIndex
        }
        dispatch(addChartValues(chartData))
      }
    } else {
      const mappedLabels = Object.keys(headingColumns).map(label => ({
        label
      }))
      const mappedDatasets = tableRows.map((obj, index) => ({
        label: obj?.noNameShow,
        hidden: datasets.length - 1 >= index ? datasets[index]?.hidden : false
      }))
      const chartData = {
        labels: mappedLabels,
        datasets: mappedDatasets,
        chartIndex: data?.chartIndex,
        pageIndex: data?.pageIndex
      }
      dispatch(addChartValues(chartData))
    }
  }, [tableRows, value, headingColumns])

  const colWidth = 100 / Object.keys(headingColumns).length

  return (
    <div
      className={'group'}
      key={data?.chartIndex + data?.pageIndex}
      style={{
        height: '100%',
        width: '100%'
      }}
    >
      <p style={{ margin: '0', fontSize: '10px', fontWeight: 500 }}>
        {data.headingText || data.name}
      </p>
      {isLoading
        ? <span className='loading-text'>Loading...</span>
        : <div className='marketcap-table-primary'>
          <div className='table-border'>
            <div className=''>
              {tableRows.length
                ? (
                  <table className='width-100' style={{ borderCollapse: 'collapse' }}>
                    <thead>
                      <tr className='headingBorder'>
                        {Array.isArray(labels) &&
                          labels.map(
                            (columnKey, columnIndex) =>
                              !columnKey.hidden && (
                                <th
                                  className={`text-header width-${tableWidth ? tableWidth[columnIndex] : colWidth
                                    } ${tableTextAlign
                                      ? tableTextAlign[columnIndex]
                                      : 'text-align-center'
                                    }`}
                                  key={columnIndex}
                                >
                                  {columnKey?.label !== 'noNameShow' &&
                                    columnKey?.label
                                    ? columnKey.label
                                      .toString()
                                      .toLowerCase()
                                      .replace(/_/g, ' ')
                                      .split(' ')
                                      .map(
                                        (word) =>
                                          word.charAt(0).toUpperCase() +
                                          word.slice(1)
                                      )
                                      .join(' ')
                                    : ''}
                                </th>
                              )
                          )}
                      </tr>
                    </thead>
                    <tbody className='table-body'>
                      {datasets?.map(
                        (row, rowIndex) =>
                          !row.hidden && (
                            <tr
                              className='borderBottom'
                              key={rowIndex}
                            >
                              {Array.isArray(labels) &&
                                labels.map(
                                  (columnKey, columnIndex) =>
                                    !columnKey.hidden && (
                                      <td
                                        key={columnIndex}
                                        className={`${tableTextAlign
                                          ? tableTextAlign[columnIndex]
                                          : 'text-align-center'
                                          } common-text-paragraph`}
                                      >
                                        {isNumeric(
                                          tableRows[rowIndex]?.[columnKey?.label] * 1
                                        )
                                          ? decTwoPercentage(
                                            tableRows[rowIndex]?.[
                                            columnKey?.label
                                            ] * 1,
                                            2
                                          )
                                          : tableRows[rowIndex]?.[columnKey?.label] ||
                                          decTwoPercentage(0, 2)}
                                      </td>
                                    )
                                )}
                            </tr>
                          )
                      )}
                    </tbody>
                  </table>
                )
                : (
                  <p>No data available</p>
                )}
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default MarketCap
