import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const MetricAccordion = ({ isExpanded, panelName, handleChange, children }) => {
  return (
    <Accordion
      className='accordion-layout'
      expanded={isExpanded}
      onChange={handleChange(panelName)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${panelName}-content`}
        id={`${panelName}-header`}
        sx={{ height: '52px' }}
      >
        <Typography sx={{ fontWeight: '500', color: '#002A59', fontFamily: 'Open Sans' }}>
          {panelName}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {children}
      </AccordionDetails>
    </Accordion>
  )
}

export default MetricAccordion
