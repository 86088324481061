import { FormatDateAPI, FormatPayload } from '../components/FormatAPIRequest'
import { UpdateAPI } from '../components/Actions'
import { getUserRole } from '../../../utils/getUserRole'

export const UpdateReportData = async (updateRow, tabIndex, oldRow) => {
  switch (tabIndex) {
    case 'stats-level-code':
      return await UpdateStatusLevelCode(updateRow, oldRow)
    case 'stats-master':
      return await UpdateStatusMaster(updateRow, oldRow)
    default:
      return {}
  }
}

const UpdateStatusLevelCode = async (updateRow, oldRow) => {
  const userRole = getUserRole()
  const startDateValue = updateRow.startDate ? FormatDateAPI(updateRow.startDate) : null
  const endDateValue = updateRow?.endDate ? FormatDateAPI(updateRow.endDate) : null

  let payload = {
    statsLevelCdData: updateRow?.statsLevelCd?.toUpperCase(),
    statsLevelDesc: updateRow.statsLevelDesc,
    startDate: startDateValue,
    endDate: endDateValue
  }
  payload = FormatPayload(payload)
  return UpdateAPI('baseReportingMaintainURL', `data-maintenance/v1/${userRole}/reports/stats-level-code/${oldRow.statsLevelCd}`, payload)
}

const UpdateStatusMaster = async (updateRow, oldRow) => {
  const userRole = getUserRole()
  const startDateValue = updateRow.startDate ? FormatDateAPI(updateRow.startDate) : null
  const endDateValue = updateRow?.endDate ? FormatDateAPI(updateRow.endDate) : null

  let payload = {
    statsCodeData: updateRow?.statsCode?.toUpperCase(),
    statsDesc: updateRow.statsDesc,
    statsDtype: updateRow.statsDtype,
    startDate: startDateValue,
    endDate: endDateValue
  }
  payload = FormatPayload(payload)
  return UpdateAPI('baseReportingMaintainURL', `data-maintenance/v1/${userRole}/reports/stats-mast/${oldRow.statsCode}`, payload)
}
