import React, { useEffect, useState } from 'react'
import './coverPage.css'
import ImageDisplay from '../../common/ImageDisplay'
import { useDispatch, useSelector } from 'react-redux'
import { accountDataApi } from '../../../../utils/pdf-customizer/_data'
import { fetchDataAndDispatch } from '../APIResponseConverts/APIResponseConverts'
import { useAuth } from '../../../../contexts/AuthContext'
import dayjs from 'dayjs'
const CoverPage = ({ data, chartIndex, index }) => {
  const [isLoading, setIsLoading] = useState(false)
  const { accountDataResponse, isResponseElements } = useSelector(
    (state) => state.chartState
  )
  const templateData = useSelector(state => state.templateData)
  const { pageHeadingFooter } = useSelector((state) => state.elementStates)
  const { user } = useAuth()

  const dispatch = useDispatch()
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isResponseElements && !accountDataResponse && !isLoading) {
          setIsLoading(true)
          await fetchDataAndDispatch(
            () => accountDataApi(templateData, user?.userGroup),
            'ACCOUNT_DATA',
            dispatch,
            true
          )
        }
        if (isResponseElements && accountDataResponse) {
          setIsLoading(false)
        }
      } catch (error) {
        console.error('Error fetching and dispatching data:', error)
      }
    }

    fetchData()
  }, [isResponseElements, accountDataResponse, dispatch])

  const logoStyle = {
    position: 'absolute',
    bottom: '50px',
    left: '38px',
    objectFit: 'contain'
  }
  return (
    <div className='width-100 height-100 '>
      {isLoading
        ? <span className='loading-text'>Loading...</span>
        : <>
          {data?.name === 'Cover page 1' && (
            <img src='https://aris-public-assets-dev.s3.us-east-2.amazonaws.com/ARIS+Logo/cover-page-vector.svg' alt='noFream' className='angel' />
          )}

          <div className='coverpage1 relative flexOrAlign  directionColumn showInCenter '>
            <div>
              <p className='text-align-center fontsize20'>
                {data?.value?.templateType || data?.templateType || 'Transition Report'}
            </p>
            </div>
            <div className='divider1 ' />
            <div className='text-align-center margin-y-2'>
              <p className='fontsize8'>
              {dayjs().format('MMMM DD, YYYY') || 'OCTOBER 16, 2023'}
            </p>
              <p className='fontsize8'>
              {data?.value?.accountName || 'PREPARED FOR'}
            </p>
            </div>
            <div className='divider2 ' />
            <div className='text-align-center margin-top-3'>
              <p className='fontsize8'>PREPARED BY</p>
              <p className='fontsize8'>
              {data?.value?.companyName || 'ARIS INVESTING'}
            </p>
              {data?.name === 'Cover page 3' && (
              <ImageDisplay
                  imageUrl={
                    data?.value?.companyLogoUrl ||
                    'https://aris-public-assets-dev.s3.us-east-2.amazonaws.com/ARIS+Logo/aris-investing-logo.svg'
                  }
                  alt='Logo'
                  style={{ objectFit: 'contain' }}
                  height={30}
                  className='logo-cover-page margin-top-4'
                  pageIdx={data?.pageIndex}
                  chartIdx={data?.chartIndex}
                  name={data?.name}
                  svgContent={data?.value?.svgCode || pageHeadingFooter?.value?.svgCode || null}
                />
            )}
            </div>
          </div>
          {data?.name === 'Cover page 1' && (
            <ImageDisplay
              imageUrl={
                data?.value?.companyLogoUrl ||
                'https://aris-public-assets-dev.s3.us-east-2.amazonaws.com/ARIS+Logo/aris-investing-logo.svg'
              }
              alt='Logo'
              className='logo-cover-page'
              style={logoStyle}
              height={30}
              pageIdx={index}
              chartIdx={chartIndex}
              name={data?.name}
              svgContent={data?.value?.svgCode || pageHeadingFooter?.value?.svgCode || null}
            />
          )}
          </>}
    </div>
  )
}

export default CoverPage
