import React, { useContext } from 'react'
import { Grid, Card, CardContent, Typography, Box } from '@mui/material'
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import { accountDataContext } from '../Evidence/index'

export const DownloadEvidence = () => {
  const accountData = useContext(accountDataContext)

  function generateCSV (accountData) {
    let headerData = ''
    accountData?.columns?.forEach((header, index) => {
      headerData += header.headerName
      if (index !== accountData?.columns?.length - 1) {
        headerData += ', '
      } else {
        headerData += '\n'
      }
    })

    let data = ''
    accountData?.rows?.forEach((obj, index) => {
      const objValues = Object.values(obj)
      objValues.forEach((value, i) => {
        data += value
        if (i !== objValues.length - 1) {
          data += ', '
        } else if (index !== accountData?.rows?.length - 1) {
          data += '\n'
        }
      })
    })
    return headerData + data
  }

  function handleDownload () {
    const csvData = generateCSV(accountData)
    const blob = new Blob([csvData], { type: 'text/csv' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'Evidence.csv')
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <Grid xs={12} sm={4} mt={4}>
      <Card className='report-card'>
        <CardContent>
          <Grid xs={12} sm={12} container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Grid item sm={3}>
              <Box className='download-icon-container' sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <FolderOpenOutlinedIcon sx={{
                  width: 40,
                  height: 40,
                  color: 'black'
                }}
                />
              </Box>
            </Grid>
            <Grid item sm={6}>
              <Typography className='filename'>
                Evidence.csv
              </Typography>
              <Typography className='file-size'>
                1.2KB
              </Typography>
            </Grid>
            <Grid item sm={3}>
              <FileDownloadOutlinedIcon
                sx={{
                  width: 30,
                  height: 30
                }}
                onClick={handleDownload}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  )
}
