import React, { useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CircleIcon from '@mui/icons-material/Circle'
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined'
import { Box, Step, StepLabel, Stepper } from '@mui/material'
import * as Sentry from '@sentry/react'
import { useAuth } from '../../../../contexts/AuthContext'
import { useErrorToast } from '../../../../hooks/useErrorToast'
import { TIMELINE_STEPS } from './Steps'

const StatusTimeline = ({ row }) => {
  const [activeStep, setActiveStep] = useState(-1)
  const { user } = useAuth()
  const { showError } = useErrorToast()
  const [completedSteps, setCompletedSteps] = useState([])

  const findByStatusCd = (status) => {
    const statusCd = status === 'REVIEW_COMPLETED' ? 'REVIEW' : status
    const matchingStep = TIMELINE_STEPS.find(step => step.statusCd === statusCd)
    // if status is review-completed then show review step as completed
    return matchingStep ? (status === 'REVIEW_COMPLETED') ? matchingStep.id : matchingStep.id - 1 : -1
  }

  useEffect(() => {
    API.get('baseUriSubAdvisoryPolicy', `sub-advisory-policy/v1/${user?.userGroup}/ips/documents/status/${row?.documentId}`)
      .then(response => {
        if (response?.data && response?.data?.length) {
          const completedStepList = response.data
          setCompletedSteps(completedStepList)
          if (completedStepList[0].statusCd !== 'ACTIVE' && row.requestStatusCd !== 'APPROVED') {
            // if statement is inactive then check status of previous step
            const firstStep = completedStepList[0].statusCd === 'INACTIVE' ? completedStepList[1] : completedStepList[0]
            setActiveStep(findByStatusCd(firstStep.statusCd))
          } else {
            // if statement is active then show all step as completed
            setActiveStep(5)
          }
        }
      })
      .catch(error => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
  }, [])

  return (
    <Box p={4}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
      >
        {TIMELINE_STEPS.map((step) => (
          <Step key={step.id}>
            <StepLabel
              sx={{
                '.MuiStepLabel-label': {
                  fontSize: '14px',
                  marginTop: '8px',
                  fontWeight: 600,
                  fontFamily: 'Open Sans',
                  color: '#33A68D !important'
                },
                '.Mui-completed': {
                  color: '#33A68D'
                },
                '.Mui-active': {
                  color: '#002a59 !important'
                },
                '.Mui-disabled': {
                  color: '#7B7B7B !important'
                },
                '.Mui-error': {
                  color: '#d32f2f !important'
                },
                '.MuiSvgIcon-root': {
                  height: 25,
                  width: 25
                }
              }}
              error={(activeStep + 1) === step.id && completedSteps[0].statusCd === 'INACTIVE'}
              StepIconComponent={(props) => (
                // show cancel icon for current active step if statement is inactive
                ((activeStep + 1) === step.id && completedSteps[0].statusCd === 'INACTIVE')
                  ? <CancelIcon {...props} />
                  : (activeStep + 1) === step.id
                      ? <CircleIcon {...props} />
                      : (activeStep + 1) < step.id
                          ? <RadioButtonUncheckedOutlinedIcon {...props} />
                          : <CheckCircleIcon {...props} />
              )}
            >
              {step.label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  )
}

export default StatusTimeline
