import React, { useState } from 'react'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Tab from '@mui/material/Tab'
import Account from '../Monitoring/Account'
import Policy from '../Monitoring/Policy'

const MonitoringTabs = () => {
  const [value, setValue] = useState(0)
  const [selectedPolicyRow, setSelectedPolicyRow] = useState(null)

  const handleCellClick = (params) => {
    if (params?.field === 'policyApplied') {
      setSelectedPolicyRow(params.row)
      setValue(1)
    } else if (params?.field === 'actionRequired') {
      setSelectedPolicyRow({ ...params.row, reviewerStatus: 'Review Required' })
      setValue(1)
    }
  }
  const handleChange = (event, newValue) => {
    if (newValue === 0) {
      setSelectedPolicyRow([])
    }
    setValue(newValue)
  }

  const repTabs = ['Policy', 'Account']

  const renderTabPanel = (item) => {
    switch (item) {
      case 'Policy':
        return <Policy onCellClick={handleCellClick} />
      case 'Account':
        return <Account selectedPolicyRow={selectedPolicyRow} />
      default:
        return 'Something went wrong'
    }
  }

  return (
    <Grid container xs={12} sm={12}>
      <Grid xs={12} sm={12}>
        <Box
          className='card-layout'
          sx={{
            flexGrow: 1,
            maxWidth: { xs: '100%' },
            bgcolor: 'white'
          }}
        >
          <TabContext value={value}>
            <TabList
              onChange={handleChange}
              variant='scrollable'
              scrollButtons={false}
              className='report-tab-header'
              TabIndicatorProps={{
                style: {
                  backgroundColor: '#34475A'
                }
              }}
            >
              {repTabs.map((item, index) => (
                <Tab className='reportCardNav' key={index} value={index} label={item} indicatorColor='#34475A' />
              ))}
            </TabList>
            <Divider sx={{
              bgcolor: 'rgba(116, 120, 141, 0.15)',
              width: '100%'
            }}
            />
            {repTabs.map((item, index) => (
              <TabPanel
                className='scroll-left-report'
                key={index}
                value={index}
                sx={{ height: 'auto', p: item === 'Methodology1' ?? -24 }}
              >
                {renderTabPanel(item)}
              </TabPanel>
            ))}
          </TabContext>
        </Box>
      </Grid>
    </Grid>
  )
}

export default MonitoringTabs
