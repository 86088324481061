import { Divider, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, styled, tooltipClasses } from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import ReviewsOutlinedIcon from '@mui/icons-material/ReviewsOutlined'

// custom tooltip for error and cause icon
const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FFFFFF',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 250,
    fontSize: theme.typography.pxToRem(12),
    padding: '16px',
    boxShadow: '0px 7px 29px rgba(100, 100, 111, 0.2)',
    borderRadius: '10px'
  }
}))

const ProcessMetricCard = ({ processData }) => {
  return (
    <TableContainer>
      <Table
        size='small' sx={{
          border: '1px solid #B6B6B6',
          '& .MuiTableCell-root': {
            color: '#727474 !important',
            fontFamily: 'Open Sans'
          },
          '& .MuiTableCell-body': {
            color: '#34475A !important'
          },
          '& .MuiTableCell-head': {
            fontWeight: '600 !important',
            fontSize: '14px !important'
          }
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Function Name</TableCell>
            <TableCell>Start Time</TableCell>
            <TableCell>End Time</TableCell>
            <TableCell>Duration</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Error</TableCell>
            <TableCell>Cause</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {processData.map((processObj, index) => {
            return (
              <TableRow key={index} sx={{ ' td': { border: 'none' } }}>
                <TableCell>{processObj.subProcessName}</TableCell>
                <TableCell>{processObj.subProcessData?.functionName}</TableCell>
                <TableCell>{new Date(processObj.subProcessData?.startTime).toLocaleString()}</TableCell>
                <TableCell>{new Date(processObj.subProcessData?.endTime).toLocaleString()}</TableCell>
                <TableCell>{processObj.subProcessData?.duration}</TableCell>
                <TableCell>
                  <Typography sx={{
                    fontSize: '14px !important',
                    color:
                      processObj.subProcessData?.status === 'SUCCEEDED'
                        ? '#3BBFA3'
                        : (processObj.subProcessData?.status === 'SOFT_FAIL' || processObj.subProcessData?.status === 'TIMED_OUT')
                            ? '#EFAE3A'
                            : 'red',
                    textTransform: 'capitalize'
                  }}
                  >
                    {processObj.subProcessData?.status?.toUpperCase() || ''}
                  </Typography>
                </TableCell>
                <TableCell>
                  <HtmlTooltip
                    title={
                      <>
                        <Typography color='inherit' fontFamily='Open Sans' fontWeight={500}>Error</Typography>
                        <Divider sx={{ backgroundColor: '#000000' }} />
                        <Typography sx={{ fontSize: '12px' }} my={1}>{processObj.subProcessData?.error}</Typography>
                      </>
                    }
                  >
                    <IconButton
                      disabled={processObj.subProcessData?.error === 'NA'}
                      sx={{
                        opacity: (processObj.subProcessData?.error === 'NA') ? 0.4 : 1
                      }}
                    >
                      <ErrorOutlineIcon sx={{ fontSize: '18px' }} />
                    </IconButton>
                  </HtmlTooltip>
                </TableCell>
                <TableCell>
                  <HtmlTooltip
                    title={
                      <>
                        <Typography color='inherit' fontFamily='Open Sans' fontWeight={500}>Cause</Typography>
                        <Divider sx={{ backgroundColor: '#000000' }} />
                        <Typography sx={{ fontSize: '12px' }} my={1}>{processObj.subProcessData?.cause}</Typography>
                      </>
                    }
                  >
                    <IconButton
                      disabled={processObj.subProcessData?.cause === 'NA'}
                      sx={{
                        opacity: (processObj.subProcessData?.cause === 'NA') ? 0.4 : 1

                      }}
                    >
                      <ReviewsOutlinedIcon sx={{ fontSize: '18px' }} />
                    </IconButton>
                  </HtmlTooltip>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ProcessMetricCard
