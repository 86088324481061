import React, { useState, useEffect } from 'react'
import { API } from 'aws-amplify'
import * as Sentry from '@sentry/react'
import { useAuth } from '../../contexts/AuthContext'
import { moduleConfig } from '../../contexts/data'
import { ACCESS_LEVEL } from '../../contstants/constants'
import { useErrorToast } from '../../hooks/useErrorToast'
import ExportAccount from './ExportAccount'
import ImportAccount from './ImportAccount'

const AccountMigration = () => {
  const { checkAccess } = useAuth()
  const { user } = useAuth()
  const { showError } = useErrorToast()
  const [accounts, setAccounts] = useState([])
  const [copyAccountData, setCopyAccountData] = useState([])
  const [accountsLoading, setAccountsLoading] = useState(false)

  // Fetch the list of accounts based on user group
  const getAccountsList = () => {
    setAccountsLoading(true)
    API
      .get('baseURL', `account-master/v1/${user?.userGroup}/accounts/summary/details`, {
        queryStringParameters: {
          page: 1,
          perpage: 300,
          ...{
            ...user?.userGroup === 'adv-classic'
              ? {
                resources: encodeURIComponent(JSON.stringify({
                  serviceId: 'book-of-business',
                  resourceId: 'book-of-business'
                }))
              }
              : {}
          }
        }
      })
      .then(response => {
        if (response?.data?.accountsSummaryDetails) {
          setAccounts([...response?.data?.accountsSummaryDetails])
          setCopyAccountData([...response?.data?.accountsSummaryDetails])
        }
      })
      .catch(error => {
        showError(error?.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => {
        setAccountsLoading(false)
      })
  }

  useEffect(() => {
    if (user) {
      getAccountsList()
    }
  }, [user])

  // checks for ACL for import and export account access
  const hasImportAccountAccess = checkAccess(moduleConfig.ACCOUNT_MIGRATION, ACCESS_LEVEL.COMPONENT_ACCESS,
    { subModuleName: moduleConfig.ACCOUNT_MIGRATION, component_name: moduleConfig.IMPORT_ACCOUNT })
  
  const hasExportAccountAccess = checkAccess(moduleConfig.ACCOUNT_MIGRATION, ACCESS_LEVEL.COMPONENT_ACCESS,
    { subModuleName: moduleConfig.ACCOUNT_MIGRATION, component_name: moduleConfig.EXPORT_ACCOUNT })

  return (
    <>
      {
        hasImportAccountAccess
          ? <ImportAccount accounts={accounts} accountsLoading={accountsLoading} />
          : ''
      }
      {
        hasExportAccountAccess
          ? <ExportAccount accounts={accounts} accountsLoading={accountsLoading} setAccounts={setAccounts} copyAccountData={copyAccountData} hasImportAccountAccess={hasImportAccountAccess} />
          : ''
      }
    </>
  )
}

export default AccountMigration
