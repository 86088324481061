import React, { useEffect, useState } from 'react'
import './taxManagementSummary.css'
import { taSavingsData } from '../APIResponseConverts/chartDefaultsData'
import { taxSavingsData } from '../../../../utils/pdf-customizer/_data'
import { useDispatch, useSelector } from 'react-redux'
import { fetchDataAndDispatch, formatCurrencyWithSymbol } from '../APIResponseConverts/APIResponseConverts'
import { addChartValues } from '../../../../store/pdf-customizer-reducer/Action/chartDropAction'
import { useAuth } from '../../../../contexts/AuthContext'

const TaxManagementSummary = ({
  data,
  chartIndex,
  index,
  chartHeight,
  clickEvent
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const { taxSavingDataResponse, isResponseElements } = useSelector(
    (state) => state.chartState
  )
  const templateData = useSelector(state => state.templateData)
  const dispatch = useDispatch()
  const { user } = useAuth()

  useEffect(() => {
    if (isResponseElements && !taxSavingDataResponse && !isLoading) {
      setIsLoading(true)
      fetchDataAndDispatch(() => taxSavingsData(templateData, user?.userGroup), 'TAX_SAVING_DATA', dispatch)
    }
    if (isResponseElements && taxSavingDataResponse) {
      setIsLoading(false)
    }
  }, [isResponseElements, taxSavingDataResponse, dispatch])

  const itemsResponse = taxSavingDataResponse?.data || taSavingsData?.data

  const chartStyle = {
    height: '100%',
    width: '100%',
  }

  useEffect(() => {
    if (data.value || !itemsResponse) return

    const mappedDatasets = [
      itemsResponse?.totalTaxSaved,
      itemsResponse?.ytdEstimatedTax,
      itemsResponse?.ytdEstimatedTaxWithoutTaxSaving
    ].map((label, index) => ({
      label: index + 1,
      hidden: false,
      backgroundColor:
        index === 2 ? 'rgba(98,184,168,1)' : 'rgba(81,125,191,1)'
    }))

    const chartValues = {
      labels: [],
      datasets: mappedDatasets,
      chartIndex: data?.chartIndex,
      pageIndex: data?.pageIndex
    }

    dispatch(addChartValues(chartValues))
  }, [itemsResponse, dispatch, data])

  return (
    <div
      className={'group'}
      key={`${data?.chartIndex}${data?.pageIndex}`}
      style={chartStyle}
    >
      {isLoading
        ? <span className='loading-text'>Loading...</span>
        : <div className='tax-management-summary-box height-100'>
          <div className='width-90'>
            <p>
              An estimated $
              {itemsResponse?.totalTaxSaved !== undefined
                ? formatCurrencyWithSymbol(itemsResponse?.totalTaxSaved || 0, 2, '')
                : '15,800'}
              &nbsp;({formatCurrencyWithSymbol(itemsResponse?.totalTaxSavedInPercentage || 0, 2, '')} of your account value) has been saved this year due to
              the implementation of active tax management strategies
            </p>
          </div>

          <div className='flexOrAlign showInCenter'>
            <div className='relative-container'>
              <div className='circle-container'>
                {['Tax', 'TaxWithoutTaxSaving'].map((circleIndex, index) => (
                  <div
                    key={circleIndex}
                    className={`circle${index + 1}`}
                    style={
                      index === 0
                        ? {
                            backgroundColor: Array.isArray(data?.value?.datasets)
                              ? data?.value?.datasets[0].backgroundColor
                              : 'rgba(81,125,191,1)'
                          }
                        : {
                            backgroundColor: Array.isArray(data?.value?.datasets)
                              ? data?.value.datasets[1]?.backgroundColor
                              : 'rgba(81,125,191,1)'
                          }
                    }
                  >
                    <p className='header'>
                      $&nbsp;
                      {itemsResponse?.[`ytdEstimated${circleIndex}`] !== undefined
                        ? formatCurrencyWithSymbol(itemsResponse?.[`ytdEstimated${circleIndex}`] || 0, 2, '')
                        : `${index === 0 ? 19712 : 3912}`}
                    </p>
                    <p className='paragraph'>
                      {index === 0
                        ? 'Est. Tax Bill without Tax Management'
                        : 'Current Estimated tax'}
                    </p>
                  </div>
                ))}
              </div>
              <div className='absolute'>-</div>
            </div>
            <div className='padding-right-left-2'>=</div>
            <div
              className='circle3'
              style={{
                background: Array.isArray(data?.value?.datasets)
                  ? data?.value.datasets[2]?.backgroundColor
                  : '#33a68dbd'
              }}
            >
              <p className='header'>
                $&nbsp;
                {itemsResponse?.totalTaxSaved !== undefined
                  ? formatCurrencyWithSymbol(itemsResponse?.totalTaxSaved || 0, 2, '')
                  : '15,800'}
              </p>
              <p className='paragraph'>Estimated Tax Savings for {new Date(itemsResponse?.runDate).getFullYear()}(YTD)</p>
            </div>
          </div>
          </div>}
    </div>
  )
}

export default TaxManagementSummary
