import { useRef } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material'
import UMATradingPortfolioTab from './UMATradingPortfolioTab'

const UploadPortfolioPopup = ({ open, closeModal, setTransitionUploadPortfolioData, setTaxlotInformation, umaDetails }) => {
  const formRef = useRef(null)

  const handleFormSubmit = () => {
    if (formRef.current) {
      formRef.current()
    }
  }

  return (
    <>
      <Dialog fullScreen open={open} onClose={closeModal}>
        <DialogTitle>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Typography variant='h6' style={{ fontWeight: 400, color: '#34475A' }}>Upload Portfolio</Typography>
            <Box display='flex' justifyContent='flex-end' alignItems='center'>
              {
                (umaDetails?.accountType === 'transition') && (
                  <Button color='primary' variant='contained' type='submit' display='flex' justifyContent='flex-end' onClick={handleFormSubmit} sx={{ marginRight: '15px' }}>
                    Submit
                  </Button>
                )
              }
              <IconButton
                onClick={() => closeModal()}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <UMATradingPortfolioTab closeUploadModel={closeModal} setPortfolioData={setTransitionUploadPortfolioData} setData={setTaxlotInformation} umaDetails={umaDetails} formRef={formRef} />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default UploadPortfolioPopup
