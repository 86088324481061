import { DeleteAPI } from '../components/Actions'
import { getUserRole } from '../../../utils/getUserRole'

export const DeleteSponsorData = async (deleteRow, tabIndex) => {
  switch (tabIndex) {
    case 'sponsor':
      return await DeleteSponsor(deleteRow)
    case 'strategy-owner':
      return await DeleteStrategyOwner(deleteRow)
    case 'sponsor-financial-advisors':
      return await DeleteSponsorFinancialAdvisors(deleteRow)
    default:
      return {}
  }
}

const DeleteSponsor = async (deleteRow) => {
  const userRole = getUserRole()
  return DeleteAPI(
    'baseSponserURL',
    `data-maintenance/v1/${userRole}/sponsors/${deleteRow?.sponsorId}`)
}

const DeleteStrategyOwner = async (deleteRow) => {
  const userRole = getUserRole()
  return DeleteAPI(
    'baseSponserURL',
    `data-maintenance/v1/${userRole}/strategy-owners/${deleteRow?.strategyOwnerId}`)
}

const DeleteSponsorFinancialAdvisors = async (deleteRow) => {
  const userRole = getUserRole()
  return DeleteAPI(
    'baseSponserURL',
    `data-maintenance/v1/${userRole}/sponsors/financial-advisor/${deleteRow?.faId}`)
}
