import React from 'react'
import { Box, Typography, Modal } from '@mui/material'

import { MdOutlineClose } from 'react-icons/md'
import './dragDrop.css'
import { useDispatch } from 'react-redux'
import { pdfModalClose } from '../../../../store/pdf-customizer-reducer/Action/chartDropAction'
import FileUpload from '../../common/FileUpload/FileUpload'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '100%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '750px',
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  px: 2,
  py: 2,
}
const PDFHolder = () => {
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(pdfModalClose())
  }

  return (
    <Modal
      open={true}
      // onClose={handleClose}
      className="upload-modal"
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} className="modal_body_padding">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography sx={{ p: 0 }} variant="h6" component="h3">
            Upload your File :
          </Typography>
          <Box sx={{ cursor: 'pointer' }} onClick={handleClose}>
            <MdOutlineClose color="#000000" size={20} />
          </Box>
        </Box>
        <FileUpload />
      </Box>
    </Modal>
  )
}

export default PDFHolder
