import { getUserRole } from '../../../utils/getUserRole'
import { GetAPI } from '../components/Actions'

export const masterDataApi = async (parameter) => {
  const userRole = getUserRole()
  return GetAPI('baseUriMasterData', `data-maintenance/v1/${userRole}/${parameter}`)
}

export const GetMasterData = async () => {
  const apiCalls = [
    masterDataApi('account-status-codes'),
    masterDataApi('restriction-codes'),
    masterDataApi('sell-logic-codes'),
    masterDataApi('tax-states'),
    masterDataApi('property-codes')
  ]

  const response = await Promise.allSettled(apiCalls)

  const [accountStatusCodes, restrictionCodes, sellLogicCodes, taxStates, propertyCodes] = response

  return [accountStatusCodes?.value, restrictionCodes?.value, sellLogicCodes?.value, taxStates?.value, propertyCodes?.value]
}
