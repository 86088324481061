import React from 'react'
import { TextField } from '@mui/material'

export const FormikDateTimeLocal = ({ label, name, onHandleChange, fieldValues, disabled }) => {
  return (
    <>
      <TextField
        disabled={disabled}
        margin='dense'
        id={name}
        name={name}
        label={label}
        type='datetime-local'
        fullWidth
        variant='standard'
        value={fieldValues}
        onChange={onHandleChange}
        InputLabelProps={{ shrink: true }}
      />
    </>
  )
}
