import { FormatDateAPI } from '../components/FormatAPIRequest'
import { GetAPI } from '../components/Actions'
import { getUserRole } from '../../../utils/getUserRole'

export const GetNextTradeStatusId = async () => {
  try {
    const tradeStatusList = await GetTradeStatusMasterDataResponse('status-master')
    const highestId = tradeStatusList.data.data.reduce((maxId, tradeStatus) => {
      return Math.max(maxId, tradeStatus.tradeStatusId)
    }, 0)
    const nextId = highestId + 1
    return nextId
  } catch (error) {
    return -1
  }
}

export const GetTradeDataResponse = async (param) => {
  const userRole = getUserRole()
  return GetAPI('tradeMaintainURL', `data-maintenance/v1/${userRole}/trading/${param}`, { date: FormatDateAPI(new Date()) })
}

export const GetTradeStatusMasterDataResponse = async (param) => {
  const userRole = getUserRole()
  return GetAPI('tradeMaintainURL', `data-maintenance/v1/${userRole}/trading/${param}`)
}

export const GetTradeData = async () => {
  const apiCalls = [
    GetTradeDataResponse('trade-status'),
    GetTradeStatusMasterDataResponse('status-master')
  ]
  const response = await Promise.allSettled(apiCalls)
  const [tradeMasterAPIData, tradeStatusMasterAPIData] = response
  return [tradeMasterAPIData?.value, tradeStatusMasterAPIData?.value]
}
