import React from 'react'
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'

export const PerformanceTable = (props) => {
  return (
    <>
      <Box className='trade-table-list-header' mb={0}>
        {props?.tableHeader}
      </Box>
      <TableContainer component={Paper} className='table-body'>
        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
          <TableHead>
            <TableRow>
              {props?.columns?.map((colData, index) => {
                return <TableCell key={index} width={colData?.width} sx={{ fontSize: '14px !important', color: '#74788D', textAlign: colData?.align, fontFamily: 'Open Sans', fontWeight: 600, padding: 1 }}>{colData?.name}</TableCell>
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {props?.rows?.map((row) => (
              <TableRow
                key={row?.name}
                sx={{
                  '&:last-child td, &:last-child th': {
                    border: 0,
                    fontWeight: props?.total ?? 600,
                    fontSize: props?.total ?? '15px'
                  }
                }}
              >
                {props?.columns?.map((column, index) => (
                  <TableCell
                    key={index}
                    scope='row'
                    className='table-cell'
                    sx={{ textAlign: column?.align, fontFamily: 'Open Sans', fontWeight: 600, padding: 1 }}
                  >
                    {row[column?.fieldLabel]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
