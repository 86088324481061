import { getUserRole } from '../../../utils/getUserRole'
import { CreateAPI } from '../components/Actions'
import { FormatDateAPI, FormatPayload } from '../components/FormatAPIRequest'

export const CreateOptimizationData = async (newRow, tabIndex) => {
  switch (tabIndex) {
    case 'opt-status-master':
      return await CreateOptimizationStatusMaster(newRow)
    case 'opt-scenario-list':
      return await CreateOptimizationScenarioList(newRow)
    case 'opt-scenario-properties':
      return await CreateOptimizationScenarioProperty(newRow)
    default:
      return {}
  }
}

const CreateOptimizationStatusMaster = async (newRow) => {
  const userRole = getUserRole()
  let payload = {
    optStatusCode: newRow?.optStatusCode?.toUpperCase(),
    optStatusName: newRow?.optStatusName,
    optStatusDesc: newRow?.optStatusDesc
  }

  payload = FormatPayload(payload)

  return CreateAPI(
    'baseUriOptimizationDataMaintenance',
    `data-maintenance/v1/${userRole}/optimizations/status-master`,
    payload
  )
}

const CreateOptimizationScenarioList = async (newRow) => {
  const userRole = getUserRole()
  let payload = {
    scenarioGroupId: newRow?.scenarioGroupId,
    scenarioCode: newRow?.scenarioCode?.toUpperCase(),
    scenarioDescription: newRow?.scenarioDescription,
    isDefault: newRow?.isDefault
  }

  payload = FormatPayload(payload)

  return CreateAPI(
    'baseUriOptimizationDataMaintenance',
    `data-maintenance/v1/${userRole}/optimizations/scenario-list`,
    payload
  )
}

const CreateOptimizationScenarioProperty = async (newRow) => {
  const userRole = getUserRole()
  const startDateValue = newRow?.startDate ? FormatDateAPI(newRow?.startDate) : null
  const endDateValue = newRow?.endDate ? FormatDateAPI(newRow?.endDate) : null

  let payload = {
    scenarioId: newRow?.scenarioId,
    propertyCode: newRow?.propertyCode?.toUpperCase(),
    propertyValue: newRow?.propertyValue,
    startDate: startDateValue,
    endDate: endDateValue
  }

  payload = FormatPayload(payload)

  return CreateAPI(
    'baseUriOptimizationDataMaintenance',
    `data-maintenance/v1/${userRole}/optimizations/scenario-properties`,
    payload
  )
}
