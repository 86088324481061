import { Divider, Skeleton, Stack, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material'
import { AllocationVerticalBarChart } from './AllocationVerticalBarChart'
import { DataGridPro } from '@mui/x-data-grid-pro'

const RenderTableSkeleton = () => {
  return (
    <Table>
      <TableBody>
        {Array.from({ length: 5 }).map((_, index) => (
          <TableRow key={index}>
            {Array.from({ length: 4 }).map((_, index) => (
              <TableCell key={index}>
                <Skeleton variant='text' sx={{ fontSize: '1rem' }}/>
              </TableCell>))}
          </TableRow>))}
      </TableBody>
    </Table>
  )
}

const FactorTab = ({ allocationData, sectorAllocationLoading, factorAllocationLoading }) => {
  const { marketCapData, sectorData, factorData, labelsBackgroundColor, marketCapLabels, sectorColumns, factorLabels } = allocationData

  return (
    <>
      {marketCapData?.length
        ? <AllocationVerticalBarChart
          labels={marketCapData?.map(dataObj => dataObj?.label)}
          backgroundColor={labelsBackgroundColor}
          chartHeader='By Marketcap'
          options={{
            plugins: {
              tooltip: {
                enabled: true,
                callbacks: {
                  label: (context) => {
                    const label = context.dataset.label || ''
                    const value = context.dataset.data[context.dataIndex]
                    return [`${label} :  ${value.toFixed(1)}%`]
                  }
                }
              },
              legend: {
                display: false
              },
              datalabels: {
                display: false
              },
              title: {
                display: true
              }
            },
            responsive: true,
            interaction: {
              mode: 'index',
              intersect: false
            },
            scales: {
              x: {
                grid: {
                  display: false
                }
              },
              y: {
                display: true,
                grid: {
                  display: false
                }
              }
            }
          }}
          data={{
            labels: marketCapLabels || [],
            datasets: marketCapData
          }}
        />
        : <Stack>
          <Typography sx={{ pt: 2.5, fontWeight: 600, color: '#74788D' }}>
            By Marketcap
          </Typography>
          {
            factorAllocationLoading ? <Skeleton variant='rectangular' height={300} width='100%' /> :

              <Typography className='no-data-text' my={2} textAlign={'left'}>
                No marketcap data available.
              </Typography>
          }
        </Stack>}

      <Divider sx={{ bgcolor: 'rgba(116, 120, 141, 0.15)', marginTop: '22px', mx: -2 }} />
      <Stack>
        <Typography sx={{ pt: 2.5, fontWeight: 600, color: '#74788D' }}>
          By Sector
        </Typography>
        {
          sectorData?.length
            ? <DataGridPro
              rows={sectorData}
              columns={sectorColumns || []}
              autoHeight
              hideFooter
              density='compact'
            />
            :
            sectorAllocationLoading 
              ? <RenderTableSkeleton/>
              :<Typography className='no-data-text' my={2} textAlign={'left'}>
              No sector data available.
            </Typography>
        }
      </Stack>

      <Divider sx={{ bgcolor: 'rgba(116, 120, 141, 0.3)', marginTop: '22px', mx: -2 }} />
      {factorData?.length
        ? <AllocationVerticalBarChart
          chartHeader='By Factors'
          labels={factorData?.map(dataObj => dataObj?.label)}
          backgroundColor={labelsBackgroundColor}
          options={{
            plugins: {
              tooltip: {
                enabled: true,
                callbacks: {
                  label: (context) => {
                    const label = context.dataset.label || ''
                    const value = context.dataset.data[context.dataIndex]
                    return [`${label} :  ${value.toFixed(1)}`]
                  }
                }
              },
              legend: {
                display: false
              },
              datalabels: {
                display: false
              },
              title: {
                display: true
              }
            },
            categoryPercentage: 0.6,
            responsive: true,
            interaction: {
              mode: 'index',
              intersect: false
            },
            scales: {
              x: {
                display: true,
                grid: {
                  display: false,
                  borderColor: 'white'
                },
                gridLines: {
                  zeroLineColor: 'rgba(0, 0, 0, 1)',
                  drawOnChartArea: false,
                  drawTicks: false
                }
              },
              y: {
                ticks: {
                  beginAtZero: true
                },
                display: true,
                grid: {
                  beginAtZero: true,
                  display: true,
                  color: (context) => {
                    const ZeroLine = context.tick.value
                    const gridColor = ZeroLine === 0 ? '#74788D4D' : 'transparent'
                    return gridColor
                  }
                }
              }
            }
          }}
          data={{
            labels: factorLabels || [],
            datasets: factorData
          }}
        />
        : <Stack>
          <Typography sx={{ pt: 2.5, fontWeight: 600, color: '#74788D' }}>
            By Factors
          </Typography>
          {
            factorAllocationLoading ? <Skeleton variant='rectangular' height={300} width='100%' /> :
              <Typography className='no-data-text' my={2} textAlign={'left'}>
                No factor allocation available.
              </Typography>
          }
        </Stack>}
    </>
  )
}

export default FactorTab
