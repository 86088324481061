import React, { useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import { Box, Button, Card, CardContent, Grid, Typography } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import * as Sentry from '@sentry/react'
import dayjs from 'dayjs'
import { useAuth } from '../../../contexts/AuthContext'
import { useSponsorIdContext } from '../../../contexts/IPSSponsorContext'
import Loader from '../../Loader'
import { DownloadEvidence } from '../components/DownloadEvidence'

export const accountDataContext = React.createContext()

export const Evidence = () => {
  const [startDate, setStartDate] = useState(dayjs())
  const [endDate, setEndDate] = useState(null)
  const [minDate, setMinDate] = useState(dayjs())
  const maxDate = dayjs()

  const [isLoading, setIsLoading] = useState(false)
  const [accountData, setAccountData] = useState([])
  const { user } = useAuth()
  const { selectedSponsorId } = useSponsorIdContext()

  const getMonitoringData = async () => {
    API
      .get('baseUriSubAdvisoryPolicy', `sub-advisory-policy/v1/${user.userGroup}/ips/monitoring/${selectedSponsorId.sponsorId || ''}`)
      .then(response => {
        setAccountData(response?.data?.accountData)
        setIsLoading(false)
      })
      .catch(error => {
        setIsLoading(false)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
  }

  useEffect(() => {
    if (user && selectedSponsorId?.sponsorId && selectedSponsorId?.sponsorId !== '-1') {
      setIsLoading(true)
      getMonitoringData()
    }
  }, [selectedSponsorId?.sponsorId, user])

  useEffect(() => {
    setMinDate(startDate)
  }, [startDate])

  const handleGenerate = () => {
    setShowCard(true)
  }
  const [showCard, setShowCard] = useState(false)
  const isGenerateDisabled = !startDate || !endDate

  return (
    <>
      {
        isLoading
          ? <Loader />
          : <>
            <Box>
              {!isLoading
                ? (selectedSponsorId?.sponsorId !== '-1'
                  ? (!accountData.rows?.length)
                    ? <p style={{ marginTop: '10px' }}>No data found</p>
                    : (<Grid container>
                      <Grid xs={12} sm={9}>
                        <Card className='card-layout'>
                          <CardContent>
                            <Grid sm={12} mt={4}>
                              <Typography sx={{ fontWeight: 600, fontSize: '17px', color: '#34475A', fontFamily: 'Open Sans' }}>Evidence is the information collected for review of a company's financial transactions, internal control practices, and other items necessary for the certification of financial statements by an auditor or certified public accountant.</Typography>
                            </Grid>
                            <Grid sm={12} mt={4} sx={{ marginBottom: '32px' }}>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Grid sm={12} container sx={{ display: 'flex', flexDirection: 'row', gap: '20px', alignItems: 'center' }}>
                                  <Grid sm={3} item display='flex' flexDirection='column'>
                                    <Typography pt={1} sx={{ fontWeight: 600, fontSize: '16px', color: '#34475A', fontFamily: 'Open Sans' }}>Start Date</Typography>
                                    <DatePicker
                                      defaultValue={startDate}
                                      onChange={setStartDate}
                                      maxDate={endDate || maxDate}
                                    />
                                  </Grid>
                                  <Grid sm={3} item display='flex' flexDirection='column'>
                                    <Typography pt={1} sx={{ fontWeight: 600, fontSize: '16px', color: '#34475A', fontFamily: 'Open Sans' }}>  End Date</Typography>
                                    <DatePicker
                                      minDate={minDate}
                                      maxDate={maxDate}
                                      value={endDate}
                                      onChange={setEndDate}
                                    />
                                  </Grid>
                                  <Grid sm={3} item>
                                    <Button
                                      variant='contained'
                                      onClick={handleGenerate}
                                      disabled={isGenerateDisabled}
                                      sx={{ width: '100px', height: '50px', marginTop: '27px' }}
                                    >
                                      <Typography sx={{ fontWeight: 600, fontSize: '13px', fontFamily: 'Open Sans' }}>
                                        Generate
                                      </Typography>
                                    </Button>
                                  </Grid>
                                </Grid>
                              </LocalizationProvider>
                            </Grid>
                            {showCard && (
                              <accountDataContext.Provider value={accountData}>
                                <DownloadEvidence />
                              </accountDataContext.Provider>
                            )}
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>)
                  : (user?.userGroup === 'pm' && <p style={{ marginTop: '10px' }}>Select sponsor</p>))
                : ''}
            </Box>
          </>
      }
    </>
  )
}
