import { Typography } from '@mui/material'
import { randomId } from '@mui/x-data-grid-generator'
import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro'

const TradeSectorTable = ({ sectorDataObj, showCompact = false }) => {
  const sectorData = (sectorDataObj?.sectorData || []).map((item) => ({ ...item, id: randomId() }))
  // iterate over datatable column mapping and add field name
  const sectorLabels = (sectorDataObj?.sectorLabels || []).map((item, index) => {
    return {
      field: sectorDataObj?.sectorDataTableColumnMapping && Object.keys(sectorDataObj?.sectorDataTableColumnMapping).length && sectorDataObj?.sectorDataTableColumnMapping[item] ? sectorDataObj?.sectorDataTableColumnMapping[item] : item,
      headerName: item,
      type: item !== 'Sector' ? 'number' : '',
      flex: 1,
      headerAlign: item !== 'Sector' ? 'right' : 'left',
      align: item !== 'Sector' ? 'right' : 'left',
      valueFormatter: (params) => {
        return (item !== 'Sector')
          ? `${(Math.abs((params.value) * 100)).toFixed(2)} `
          : params.value
      }
    }
  })
  return (
    <>
      {sectorData.length
        ? (<DataGridPro
            density={showCompact ? 'standard' : 'compact'}
            rows={sectorData}
            columns={sectorLabels}
            getRowId={(row) => row.id}
            pagination
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } }
          }}
          sx={
            showCompact ? {
              [`.${gridClasses.cell}, .${gridClasses.cellContent}, .${gridClasses.columnHeaderTitle}, .${gridClasses.columnHeaders}`]: {
              fontSize: '12px'
            },
          } : {}}
          {
          ...(
            showCompact ? {
            rowHeight: 30,
            columnHeaderHeight:39,
            }
              : {}
          ) 
          }
           />)
        : (
          <Typography className='no-data-text' my={2} textAlign='left'>
            No sector data available.
          </Typography>
          )}
    </>
  )
}

export default TradeSectorTable
