import {
  CHANGE_MODE,
  COLOR_CHANGE,
  FONT_CHANGE,
  PAGE_CHANGE,
  RE_SET_DATA,
  IS_LOADING,
  PROGRESS_SET,
  PADDING_CHANGE,
  FETCH_CUSTOM_TABLE_METADATA,
  CHANGE_CURRENT_DRAGGABLE_ELEMENT,
} from './allPageAction.types'

export const changeColor = (data) => ({
  type: COLOR_CHANGE,
  payload: data
})

export const changePadding = (data) => ({
  type: PADDING_CHANGE,
  payload: data
})

export const isProgressSet = (data) => ({
  type: PROGRESS_SET,
  payload: data,
})

export const fontChange = (data) => ({
  type: FONT_CHANGE,
  payload: data
})
export const pageChange = (data) => ({
  type: PAGE_CHANGE,
  payload: data
})
export const userClickMode = (data) => ({
  type: CHANGE_MODE,
  payload: data
})
export const resetThemeCustomization = (data) => ({
  type: RE_SET_DATA,
  payload: data
})
export const isLoadingSet = (data) => ({
  type: IS_LOADING,
  payload: data
})

export const changeDraggableElement = (data) => ({
  type: CHANGE_CURRENT_DRAGGABLE_ELEMENT,
  payload: data
})
export const fetchCustomTableMetadata = (data) => ({
  type: FETCH_CUSTOM_TABLE_METADATA,
  payload: data
})
