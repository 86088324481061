import React from 'react'
import { Box, CardContent, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import PropTypes from 'prop-types'

function createData (no, sector, snf, minTax, balance, model) {
  return {
    no,
    sector,
    snf,
    minTax,
    balance,
    model
  }
}

const sectorLabels = ['Sector', 'Portfolio (%)', 'Min Tax (%)', 'Balanced (%)', 'Model (%)', 'Strategy (%)']

const rows = [
  createData(1, 'Materials', 1.1, 3, 6, 4),
  createData(2, 'Consumer discretionary', 1.1, 2, 5, 4),
  createData(3, 'Financial Services', 1.1, 1, 2, 6),
  createData(4, 'Real Estate', 1.1, 6, 2, 4),
  createData(5, 'Communication Services', 1.1, 1, 4, 3),
  createData(6, 'Energy', 1.1, 3, 8, 6),
  createData(7, 'Industries', 1.1, 9, 3, 4),
  createData(8, 'Information Technology', 1.2, 0, 9, 0),
  createData(9, 'Consumer Staples', 1.2, 2, 6, 7),
  createData(10, 'Healthcare', 1.2, 2, 9, 0),
  createData(11, 'Energy', 1.1, 0, 8, 2),
  createData(12, 'Utilities', 1.1, 1, 9, 3),
  createData(13, 'Healthcare', 1.0, 1, 6, 4)
]

function descendingComparator (a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator (order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort (array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

function EnhancedTableHead (props) {
  const { order, orderBy, onRequestSort, rowData } =
    props
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {props.rowData.map((element) => {
          return <TableCell key={element} className='table-head' sx={{ fontWeight: 600, fontFamily: 'Open Sans', fontSize: '14px', padding: 1 }}>{element}</TableCell>
        })}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
}
function EnhancedTableToolbar (props) {
  const { numSelected } = props

  return (
    <Box className='trade-table-list-header'>{props.tableHeader}</Box>
  )
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired
}

export default function EnhancedTable (props) {
  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState('Sector')
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(15)
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0
  return (
    <Box sx={{ width: '100%' }}>
      <Paper className='table-body'>
        <EnhancedTableToolbar tableHeader='By Sector' />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby='tableTitle'
            className='table-cell-allocation'
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              rowData={props?.rows?.sectorLabels || sectorLabels}
            />
            <TableBody>
              {stableSort(props?.rows?.sectorData, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow hover key={index}>
                      {
                        Object.values(row).map((item, index) => {
                          return (
                            <TableCell key={index} align='left' className='table-cell' sx={{ fontWeight: 600, fontFamily: 'Open Sans', padding: 1 }}>
                              {typeof (item) === 'number'
                                ? `${(Math.abs((item) * 100)).toFixed(2)} `
                                : item}
                            </TableCell>
                          )
                        })
                      }
                    </TableRow>
                  )
                })}
              {emptyRows > 0 && (
                <TableRow>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  )
}
