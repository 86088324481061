import { allExpanded, defaultStyles, JsonView } from 'react-json-view-lite'
import { Box, Button, IconButton, Modal, Skeleton, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import 'react-json-view-lite/dist/index.css'

const customStyles = {
  ...defaultStyles,
  container: {
    ...defaultStyles.container,
    backgroundColor: '#ffffff'
  },
  label: {
    ...defaultStyles.label,
    fontWeight: 'normal'
  },
  punctuation: {
    ...defaultStyles.punctuation,
    fontWeight: 'normal'
  },
  quotesForFieldNames: true
}

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  px: 2,
  py: 2,
  borderRadius: '4px',
  outline: 'none',
  width: '100vw',
  height: '100vh'
}

const renderOptDataSkeleton = () => {
  return (
    <Box sx={{ my: 3 }}>
      <Skeleton variant='rectangle' sx={{ height: '30px', width: '50%', my: 2 }} />
      <Skeleton variant='rectangle' sx={{ height: '30px', width: '30%', my: 2 }} />
      <Skeleton variant='rectangle' sx={{ height: '30px', width: '40%', my: 2 }} />
      <Skeleton variant='rectangle' sx={{ height: '30px', width: '20%', my: 2 }} />
      <Skeleton variant='rectangle' sx={{ height: '30px', width: '40%', my: 2 }} />
    </Box>
  )
}

const OptimizationDataModal = ({ loading, jsonData, isOpen, onClose, triggerOptimization, showDownloadButton }) => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
    >
      <Box sx={modalStyle}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: '5px', position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 2 }}>
          <Typography variant='h6' sx={{ ml: '5px', fontWeight: 400, color: '#34475A' }}>UMA Optimization Details</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {
              showDownloadButton && !loading
                ? (
                  <Button
                    variant='contained'
                    size='small'
                    onClick={() => triggerOptimization(false)}
                  >
                    Run Optimization
                  </Button>
                  )
                : ''
            }
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Box sx={{ maxHeight: 'calc(100vh - 16px - 40px - 16px)', border: '1px solid #c9c9c9', borderRadius: '5px', overflowY: 'auto', paddingInline: '5px' }}>
          {
            loading
              ? (
                  renderOptDataSkeleton()
                )
              : (
                <JsonView data={jsonData} shouldExpandNode={allExpanded} style={customStyles} clickToExpandNode />
                )
          }
        </Box>
      </Box>
    </Modal>
  )
}

export default OptimizationDataModal
