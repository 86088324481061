import { getUserRole } from '../../../utils/getUserRole'
import { CreateAPI } from '../components/Actions'
import { FormatDateAPI, FormatPayload } from '../components/FormatAPIRequest'

export const CreateInstrumentData = async (newRow, tabIndex) => {
  switch (tabIndex) {
    case 'instrument-split':
      return await CreateInstrumentSplit(newRow)
    case 'instrument-delist':
      return await CreateInstrumentDelistCal(newRow)
    default:
      return {}
  }
}

const CreateInstrumentSplit = async (newRow) => {
  const userRole = getUserRole()
  const announcementDate = newRow?.announcementDate ? FormatDateAPI(newRow?.announcementDate) : null
  const effectiveDate = newRow?.effectiveDate ? FormatDateAPI(newRow?.effectiveDate) : null
  const endDate = newRow?.endDate ? FormatDateAPI(newRow?.endDate) : null
  const startDate = newRow?.startDate ? FormatDateAPI(newRow?.startDate) : null

  let payload = {
    announcementDate,
    instrId: newRow.instrId,
    localSymbol: newRow?.localSymbol?.toUpperCase(),
    ratio: newRow.ratio,
    endDate,
    effectiveDate,
    adjustmentFactor: newRow.adjustmentFactor,
    startDate
  }
  payload = FormatPayload(payload)

  return await CreateAPI(
    'baseInstrumentMaintainURL',
        `data-maintenance/v1/${userRole}/instruments/splits-cal`,
        payload
  )
}

const CreateInstrumentDelistCal = async (newRow) => {
  const userRole = getUserRole()
  const effectiveDate = newRow?.effectiveDate ? FormatDateAPI(newRow?.effectiveDate) : null
  const startDate = newRow?.startDate ? FormatDateAPI(newRow?.startDate) : null
  const endDate = newRow?.endDate ? FormatDateAPI(newRow?.endDate) : null

  let payload = {
    instrId: newRow.instrId,
    localSymbol: newRow?.localSymbol?.toUpperCase(),
    startDate,
    endDate,
    effectiveDate
  }
  payload = FormatPayload(payload)

  return await CreateAPI(
    'baseInstrumentMaintainURL',
        `data-maintenance/v1/${userRole}/instruments/delist-cal`,
        payload
  )
}
