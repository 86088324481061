import { JsonView, allExpanded, defaultStyles } from 'react-json-view-lite'
import 'react-json-view-lite/dist/index.css'

const customStyles = {
  ...defaultStyles,
  container: {
    ...defaultStyles.container,
    backgroundColor: '#ffffff'
  },
  label: {
    ...defaultStyles.label,
    fontWeight: 'normal'
  },
  punctuation: {
    ...defaultStyles.punctuation,
    fontWeight: 'normal'
  },
  quotesForFieldNames: true
}

const TradeFeasibilityReportTab = ({ optFeasibilityReport }) => {
  return (
    <JsonView data={optFeasibilityReport} shouldExpandNode={allExpanded} style={customStyles} />
  )
}

export default TradeFeasibilityReportTab
