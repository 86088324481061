import React, { useEffect, useState } from 'react'
import './portfolioStatistics.css'
import { useDispatch, useSelector } from 'react-redux'
import {
  decTwoPercentage,
  fetchDataAndDispatch
} from '../APIResponseConverts/APIResponseConverts'
import { diversificationDataApi, portfolioStatisticsApi } from '../../../../utils/pdf-customizer/_data'
import { diversificationStatistics, portfolioStatistics } from '../APIResponseConverts/chartDefaultsData'
import { useAuth } from '../../../../contexts/AuthContext'

const PortfolioStatistics = ({
  data,
  chartIndex,
  index,
  chartHeight,
  chartWidth,
  clickEvent
}) => {
  const { portfolioResponse, diversificationResponse, isResponseElements } = useSelector(
    (state) => state.chartState
  )
  const templateData = useSelector(state => state.templateData)
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const { user } = useAuth()
  useEffect(() => {
    if (isResponseElements && !portfolioResponse && !isLoading) {
      setIsLoading(true)
      fetchDataAndDispatch(() => portfolioStatisticsApi(templateData, user?.userGroup), 'PORTFOLIO_STATISTICS', dispatch)
    }
    if (isResponseElements && !diversificationResponse && !isLoading) {
      setIsLoading(true)
      fetchDataAndDispatch(() => diversificationDataApi(templateData, user?.userGroup), 'DIVERSIFICATION_STATISTICS', dispatch)
    }
    if (isResponseElements && portfolioResponse && diversificationResponse) {
      setIsLoading(false)
    }
  }, [isResponseElements, portfolioResponse, diversificationResponse, dispatch])

  const itemsResponse = portfolioResponse?.data?.accountParameters[0] || portfolioStatistics?.data?.accountParameters[0]
  const diversification = diversificationResponse?.data || diversificationStatistics?.data

  const map = new Map()
  diversification?.accountRiskAttributes.map(item => {
    map.set(item.statsCode, item.statsValue)
  })

  const valueShowPercentage = (value) => {
    if (!value)
      return 'NA'
    return (Number(value) * 100).toFixed(2) + '%'
  }

  const totalPortfolioRisk = valueShowPercentage(map.get('PORT_RISK_STD'))
  const relativeVolatility = valueShowPercentage(map.get('PORT_REL_VOL'))
  const trackingError = valueShowPercentage(map.get('PORT_TRACKING_ERROR'))
  const activeWeight = valueShowPercentage(map.get('AA_DRIFT'))
  const targetPortfolioRisk = valueShowPercentage(diversification?.strategyRiskAttributes.find(obj => obj.statsCode === 'PORT_RISK_STD')?.statsValue) || 'NA'

  const totalMarketValue = decTwoPercentage(itemsResponse[0]?.totalMarketValue, 0)
  const cashLevel = valueShowPercentage(itemsResponse[0]?.cashLevel)
  const totalUrgl = decTwoPercentage(itemsResponse[0]?.unrealizedGainLoss, 2)


  return (
    <div
      className={'portfolio-statistics showInRow'}
    >
      {isLoading
      ? <span className='loading-text'>Loading...</span>
        : <>
            <div className='showInRow'>
              <div className='left-chevron'>
                <p className='header'>{totalPortfolioRisk}</p>
                <p className='paragraph text-align-center'>
                  Total Portfolio
                  <br /> Risk
                </p>
              </div>

              <div className='left-point'>
                <p className='header'>
                  {trackingError}
                </p>
                <p className='paragraph text-align-center'>Tracking Error</p>
              </div>
            </div>
            <div className='box-items'>
              <div className='items'>
                <p className='header'>
                  {totalMarketValue < 0 ? '-$'+Math.abs(totalMarketValue) : '$'+totalMarketValue}
                </p>
                <p className='paragraph text-align-center'>Total Market Value</p>
              </div>
              <div className='items-border' />
              <div className='items'>
                <p className='header'>{totalUrgl < 0 ? '-$'+Math.abs(totalUrgl) : '$'+totalUrgl}</p>
                <p className='paragraph text-align-center'>
                  Total Unrealized gain/loss
                </p>
              </div>
              <div className='items-border' />
              <div className='items'>
                <p className='header'>
                  {cashLevel}
                </p>
                <p className='paragraph text-align-center'>Cash Level</p>
              </div>
            </div>
          </>
      }
    </div>
  )
}

export default PortfolioStatistics
