import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth } from '../../../../contexts/AuthContext'
import { formatNumbersWithSymbol } from '../../../Report/components/dataProcess/DataProcess'
import { fetchDataAndDispatch } from '../APIResponseConverts/APIResponseConverts'
import './CashFlowSummary.css'
import { ACTIVITY_SUMMARY_TYPE } from '../../../../store/pdf-customizer-reducer/Action/chartState.types'
import { activitySummaryData } from '../APIResponseConverts/chartDefaultsData'
import { activitySummaryApi } from '../../../../utils/pdf-customizer/_data'

const ActivitySummary = ({ data }) => {
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const { user } = useAuth()
  const templateData = useSelector(state => state.templateData)
  const { isResponseElements, activitySummaryResponse } = useSelector((state) => state.chartState)

  useEffect(() => {
    if (isResponseElements && !isLoading && !activitySummaryResponse) {
      setIsLoading(true)
      fetchDataAndDispatch(() => activitySummaryApi(templateData?.apiData, user?.userGroup), ACTIVITY_SUMMARY_TYPE, dispatch)
    }
    if (isResponseElements && activitySummaryResponse) {
      setIsLoading(false)
    }
  }, [isResponseElements, activitySummaryResponse])

  const result = isResponseElements ? (activitySummaryResponse || {}) : (activitySummaryData?.data || {})

  const tableRows = []
  const columns = ['', 'Since Inception']
  const tableTextAlign = ['text-left', 'text-right']
  const tableWidth = [70, 30]

  tableRows.push(['Beginning Market Value', !isNaN(result?.beginningMarketValue?.total) ? formatNumbersWithSymbol(result?.beginningMarketValue?.total, 0, '$') : '-'])
  tableRows.push(['Contribution', !isNaN(result?.contribution?.total) ? formatNumbersWithSymbol(result?.contribution?.total, 0, '$') : '-'])
  tableRows.push(['Distribution', !isNaN(result?.distribution?.total) ? formatNumbersWithSymbol(result?.distribution?.total, 0, '$') : '-'])
  if (result?.internalDeposit || result?.internalWithdrawal) {
    tableRows.push(['Internal Deposit', !isNaN(result?.internalDeposit) ? formatNumbersWithSymbol(result?.internalDeposit, 0, '$') : '-'])
    tableRows.push(['Internal Withdrawal', !isNaN(result?.internalWithdrawal) ? formatNumbersWithSymbol(result?.internalWithdrawal, 0, '$') : '-'])
  }
  tableRows.push(['All Income (Div + Int)', !isNaN(result?.allIncome?.total) ? formatNumbersWithSymbol(result?.allIncome?.total, 0, '$') : '-'])
  tableRows.push(['Net Changes in MV', !isNaN(result?.netChangeInMv) ? formatNumbersWithSymbol(result?.netChangeInMv, 0, '$') : '-'])
  tableRows.push(['Advisory Fees Paid', !isNaN(result?.advisoryFeesPaid?.total) ? formatNumbersWithSymbol(result?.advisoryFeesPaid?.total, 0, '$') : '-'])
  tableRows.push(['Ending Market Value', !isNaN(result?.endingMarketValue) ? formatNumbersWithSymbol(result?.endingMarketValue, 0, '$') : '-'])

  return (
    <div
      className='group'
      style={{
        height: '100%',
        width: '100%'
      }}
    >
      <p style={{ fontSize: '10px', fontWeight: 500, color: '#47546a', margin: 0 }}>{data?.headingText}</p>
      {
        isLoading ?
          <span className='loading-text'>Loading...</span>
          : <div className='activity-summary'>
            <table className='width-100' style={{ borderCollapse: 'collapse' }}>
              <thead>
                <tr className='headingBorder'>
                  {columns.map((column, columnIndex) => (
                    <th key={columnIndex} className={`width-${tableWidth[columnIndex]} ${tableTextAlign[columnIndex]}`}>
                      {column}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {
                  tableRows.map((tableRow, rowIndex) => (
                    <tr className={`${rowIndex >= tableRows.length - 2 ? 'total-row' : 'borderBottom'} fontsize8`} key={rowIndex}>
                      {
                        tableRow.map((element, index) => (
                          <td
                            key={index}
                            className={`width-${tableWidth[index]} ${tableTextAlign[index]}`}
                          >
                            {element}
                          </td>
                        ))
                      }
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
      }
    </div>
  )
}

export default ActivitySummary