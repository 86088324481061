import { BtnBold, BtnBulletList, BtnClearFormatting, BtnItalic, BtnNumberedList, BtnUnderline, Editor, EditorProvider, Toolbar } from 'react-simple-wysiwyg'
import sanitize from 'sanitize-html'

const TextAreaEditor = ({ message, setMessage, placeholder, containerProps = {}, rightHeaderComponent = null, toolbarStyle = null }) => {
  return (
    <EditorProvider>
      <Editor
        value={message}
        placeholder={placeholder}
        containerProps={containerProps}
        onChange={(e) => {
          setMessage(e?.target?.value)
        }}
        onPaste={(e) => {
          // Prevent default paste behavior
          e.preventDefault()
          const clipboardData = e.clipboardData || window.clipboardData
          const pastedData = clipboardData.getData('text/html') || clipboardData.getData('text/plain')
          // Sanitize the pasted content
          const cleanHtml = sanitize(pastedData, {
            allowedTags: ['b', 'i', 'u', 'br', 'ul', 'ol', 'li', 'p'], // Allow formatting tags
            exclusiveFilter: (frame) => !frame.text?.trim()
          })

          // find cursor position
          const selection = window.getSelection()
          if (!selection || !selection?.rangeCount) return

          const range = selection?.getRangeAt(0)

          // Parse the sanitized HTML into a temporary DOM structure
          const tempDiv = document.createElement('div')
          tempDiv.innerHTML = cleanHtml.replaceAll('<p>', "<p style='margin:0;'>")

          // Insert each child node of the temporary div into the range
          const fragment = document.createDocumentFragment()
          Array.from(tempDiv.childNodes).forEach((node) => fragment.appendChild(node))

          // Replace the selection or insert at the cursor
          range.deleteContents()
          range.insertNode(fragment)

          // Adjust the caret position to after the inserted content
          range.collapse(false)
          selection.removeAllRanges()
          selection.addRange(range)
        }}
      >
        <Toolbar style={toolbarStyle || { display: 'flex', alignItems: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
            <BtnBold />
            <BtnItalic />
            <BtnUnderline />
            <BtnNumberedList />
            <BtnBulletList />
            <BtnClearFormatting />
          </div>
          {
            rightHeaderComponent || ''
          }
        </Toolbar>
      </Editor>
    </EditorProvider>
  )
}

export default TextAreaEditor
