import React, { useEffect, useState } from 'react'
import './ytdTable.css'
import { useDispatch, useSelector } from 'react-redux'
import { taxSavingsData } from '../../../../utils/pdf-customizer/_data'
import {
  fetchDataAndDispatch,
  formatCurrencyWithSymbol
} from '../APIResponseConverts/APIResponseConverts'
import { taSavingsData } from '../APIResponseConverts/chartDefaultsData'
import { useAuth } from '../../../../contexts/AuthContext'

const YTDTable = ({
  data,
  chartIndex,
  index,
  chartHeight,
  chartWidth,
  clickEvent
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const { isResponseElements, taxSavingDataResponse } = useSelector(
    (state) => state.chartState
  )
  const templateData = useSelector(state => state.templateData)
  const [isDragging, setIsDragging] = useState(false)
  const {user}=useAuth()
  useEffect(() => {
    const fetchData = (api, type, responseData) => {
      if (isResponseElements && !responseData && !isLoading) {
        setIsLoading(true)
        fetchDataAndDispatch(api, type, dispatch)
      }
      if (isResponseElements && responseData) {
        setIsLoading(false)
      }
    }
    // if (isResponseElements && !taxSavingDataResponse && !isLoading) {
    //   fetchDataAndDispatch(() => taxSavingsData(templateData), 'TAX_SAVING_DATA', dispatch)
    // }

    switch (data.name) {
      case 'YTD tax saving details table':
        return fetchData(
          () => taxSavingsData(templateData, user?.userGroup),
          'TAX_SAVING_DATA',
          taxSavingDataResponse
        )

      default:
        return null
    }
  }, [taxSavingDataResponse, isResponseElements, dispatch, data])
  const getDataTable = (name) => {
    switch (name) {
      case 'YTD tax saving details table':
        return taxSavingDataResponse?.data || taSavingsData?.data

      default:
        return null
    }
  }
  const result = getDataTable(data.name) || []

  return (
    <div>
      {isLoading
      ? <span className='loading-text'>Loading...</span>
      : <div className='main_div'>
          <ul className='table_title'>
            <li>{data.headingText || data.name}</li>
          </ul>
          <ul className='table_td'>
            <li className='flexOrBetWeen table_containt'>
              <p className=''>YTD Tax Savings from Unrealized Gains</p>
              <p className=''>
                {formatCurrencyWithSymbol(
                  result?.taxSavedBasedOnOverWeightedSecurities || 0,
                  2,
                  ''
                )}
              </p>
            </li>
            <li className='flexOrBetWeen table_containt table_containt_color'>
              <p className=''>
                YTD Tax Savings from Short-Term to Long-Term events
              </p>
              <p className=''>
                {formatCurrencyWithSymbol(
                  result?.taxSavedBasedOnSortTermToLongTermConvertedHoldings || 0,
                  2,
                  ''
                )}
              </p>
            </li>
            <li className='flexOrBetWeen table_containt'>
              <p className=''>YTD Tax Savings from Net Loss Harvesting</p>
              <p className=''>
                {formatCurrencyWithSymbol(
                  result?.taxSavedBasedOnYTDGainLossRecognized || 0,
                  2,
                  ''
                )}
              </p>
            </li>
          </ul>
          <ul className='table_footer'>
            <li className='flexOrBetWeen table_footer_containt'>
              <p className=''>Total YTD Estimated Tax Savings</p>
              <p className=''>
                {formatCurrencyWithSymbol(result?.totalTaxSaved || 0, 2, '')}
              </p>
            </li>
          </ul>
        </div>
      }
    </div>
  )
}

export default YTDTable
