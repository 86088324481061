import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addChartValues } from '../../../../store/pdf-customizer-reducer/Action/chartDropAction'
import { personalizationApi } from '../../../../utils/pdf-customizer/_data'
import { useAuth } from '../../../../contexts/AuthContext'
import {
  dataTableData,
  decTwoPercentage,
  fetchDataAndDispatch,
  isNumeric
} from '../APIResponseConverts/APIResponseConverts'
import { personalisationData } from '../APIResponseConverts/chartDefaultsData'
import '../DataTable/dataTablePrimary.css'

const PersonalizationConstraints = ({
  data,
  chartIndex,
  index,
  chartHeight,
  chartWidth,
  clickEvent
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const { personalizationResponse, isResponseElements } = useSelector(
    (state) => state.chartState
  )
  const templateData = useSelector(state => state.templateData)
  const { user } = useAuth()
  useEffect(() => {
    if (isResponseElements && !personalizationResponse && !isLoading) {
      setIsLoading(true)
      fetchDataAndDispatch(() => personalizationApi(templateData, user?.userGroup), 'PERSONALIZATION_DATA', dispatch)
    }
    if (isResponseElements && personalizationResponse) {
      setIsLoading(false)
    }
  }, [isResponseElements, dispatch, data, personalizationResponse])

  const { labels = [], datasets = [] } = data?.value || {}
  const { headingColumns, tableRows, tableWidth, tableTextAlign } = dataTableData(
    personalizationResponse || personalisationData?.data,
    data.name
  )
  const processedColumns = useMemo(() => {
    return Array.isArray(headingColumns)
      ? headingColumns
      : Object.keys(headingColumns) || []
  }, [headingColumns])

  useEffect(() => {
    if (data.value || !processedColumns.length) return

    const mappedLabels = processedColumns.map((label) => ({
      label: label?.field ? label.field : label,
      hidden: false,
      backgroundColor: 'rgba(255, 255, 255  , 1)'
    }))
    const mappedDatasets = tableRows.map((label) => ({
      label: label?.Constraints ? label?.Constraints : label,
      hidden: false
    }))

    const chartValues = {
      labels: mappedLabels,
      datasets: mappedDatasets,
      chartIndex: data?.chartIndex,
      pageIndex: data?.pageIndex
    }

    dispatch(addChartValues(chartValues))
  }, [processedColumns, dispatch, data, tableRows])

  const colWidth = 100 / Object.keys(headingColumns).length

  return (
    <div
      className={'group'}
    >
      <p style={{ margin: '0', fontSize: '10px', fontWeight: 500 }}>
        {data.headingText || data.name}
      </p>
      {isLoading
        ? <span className='loading-text'>Loading...</span>
        : <div className='table-container-primary'>
          <div className='table-border'>
            <div className=''>
              {tableRows.length
                ? (
                  <table className='width-100' style={{ borderCollapse: 'collapse' }}>
                    <thead>
                      <tr className='headingBorder'>
                        {Array.isArray(labels) &&
                          labels.map(
                            (columnKey, columnIndex) =>
                              !columnKey.hidden && (
                                <th
                                  className={`text-header width-${tableWidth ? tableWidth[columnIndex] : colWidth
                                    } ${tableTextAlign
                                      ? tableTextAlign[columnIndex]
                                      : 'text-align-center'
                                    }`}
                                  key={columnIndex}
                                >
                                  {columnKey?.label !== 'noNameShow' &&
                                    columnKey?.label
                                    ? columnKey.label
                                      .toString()
                                      .toLowerCase()
                                      .replace(/_/g, ' ')
                                      .split(' ')
                                      .map(
                                        (word) =>
                                          word.charAt(0).toUpperCase() +
                                          word.slice(1)
                                      )
                                      .join(' ')
                                    : ''}
                                </th>
                              )
                          )}
                      </tr>
                    </thead>
                    <tbody className='table-body'>
                      {datasets.map(
                        (row, rowIndex) =>
                          !row.hidden && (
                            <tr
                              key={rowIndex}
                              className='borderBottom'
                            >
                              {Array.isArray(labels) &&
                                labels.map(
                                  (columnKey, columnIndex) =>
                                    !columnKey.hidden && (
                                      <td
                                        key={columnIndex}
                                        className={`
                                          ${tableTextAlign
                                            ? tableTextAlign[columnIndex]
                                            : 'text-align-center'
                                          } common-text-paragraph`}
                                        style={
                                          columnKey?.label !== 'noNameShow'
                                            ? {
                                              margin: '0',
                                              fontSize: '8px',
                                              fontWeight: 600,
                                              // fontFamily: 'Open Sans',
                                              color: '#002A59',
                                              verticalAlign: 'bottom',
                                              padding: '1px 5px'
                                            }
                                            : {
                                              padding: '1px 5px'
                                            }
                                        }
                                      >
                                        {isNumeric(
                                          tableRows[rowIndex][columnKey?.label] * 1
                                        )
                                          ? decTwoPercentage(
                                            tableRows[rowIndex][
                                            columnKey?.label
                                            ] * 1,
                                            2
                                          )
                                          : tableRows[rowIndex][columnKey?.label] ||
                                          '-'}
                                      </td>
                                    )
                                )}
                            </tr>
                          )
                      )}
                    </tbody>
                  </table>
                )
                : (
                  <p>No data available</p>
                )}
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default PersonalizationConstraints
