import { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro'
import { randomId } from '@mui/x-data-grid-generator'
import 'rc-slider/assets/index.css'
import { ErrorBoundary } from 'react-error-boundary'
import redirect from '../../assets/images/pop-out-line.svg'
import ErrorFallback from '../ErrorFallback'
import { CardBodyRisk } from './RiskCardBody'
import { RiskTable } from './RiskTable'
import { RiskTableloader } from './RiskTableLoader'

const SectorwiseRisk = ({ loading, sectorWiseAccounts, screen }) => {
  const [showSectorModal, setShowSectorModal] = useState(false)
  const [sectorRisk, setSectorRisk] = useState([])

  const openSectorModal = () => {
    document.body.classList.add('modal-open', 'pe-0')
    setShowSectorModal(true)
  }

  useEffect(() => {
    setSectorRisk(sectorWiseAccounts?.map((item, index) => ({ id: randomId(), srNo: index + 1, ...item })))
  }, [sectorWiseAccounts])

  const closeSectorModal = () => {
    setShowSectorModal(false)
    setTimeout(() => {
      document.body.classList.remove('modal-open', 'pe-0')
    }, 200)
  }

  const getRowId = (row) => row.id

  const columns = [
    {
      field: 'srNo',
      headerName: 'Sr No',
      flex: 1,
      valueGetter: (props) => (props.row.srNo)
    },
    {
      field: 'assetClassDesc',
      headerName: 'Sector',
      flex: 1,
      renderCell: (props) => props?.row?.assetClassDesc || ''
    },
    {
      field: 'weight',
      headerName: 'Weight(%)',
      flex: 1,
      headerAlign: 'right',
      align: 'right',
      type: 'number',
      renderCell: (props) => props?.row?.weight !== undefined ? ((props?.row?.weight) * 100).toFixed(2) : '0.00',
      valueGetter: (props) => props?.row?.weight !== undefined
        ? Number(((props?.row?.weight) * 100).toFixed(2))
        : 0.00
    },
    {
      field: 'riskColor',
      headerName: 'Risk',
      width: 150,
      sortComparator: (v1, v2, params1, params2) => {
        const order = { G: 1, Y: 2, R: 3 }
        return order[params1.value] - order[params2.value]
      },
      sortingOrder: ['asc', 'desc'],
      renderCell: (params) => (
        <Grid display='flex' alignItems='center' justifyContent='center'>
          <Grid item>
            <Box
              className={
                params.row.riskColor === 'G'
                  ? 'progressbar me-2 bg-green'
                  : params.row.riskColor === 'Y'
                    ? 'progressbar me-2 bg-yellow'
                    : params.row.riskColor === 'R'
                      ? 'progressbar me-2 bg-red'
                      : 'progressbar me-2'
              }
            />
          </Grid>
          <Grid item>
            <Box
              className={
                params.row.riskColor === 'Y'
                  ? 'progressbar me-2 bg-yellow'
                  : params.row.riskColor === 'R'
                    ? 'progressbar me-2 bg-red'
                    : 'progressbar me-2'
              }
            />
          </Grid>
          <Grid item>
            <Box
              className={
                params.row.riskColor === 'R'
                  ? 'progressbar me-2 bg-red'
                  : 'progressbar me-2'
              }
            />
          </Grid>
        </Grid>
      )
    },
    {
      field: 'statsValue',
      headerName: 'MCTE',
      flex: 1,
      type: 'number',
      headerAlign: 'right',
      align: 'right',
      renderCell: (props) => props?.row?.statsValue !== undefined ? ((props?.row?.statsValue) * 100).toFixed(4) : '0.0000',
      valueGetter: (props) => props?.row?.statsValue !== undefined
        ? Number(((props?.row?.statsValue) * 100).toFixed(4))
        : 0.0000
    }
  ]

  return (
    <CardBodyRisk>
      <Box display='flex' alignItems='center' justifyContent='space-between'>
        <Typography component='p' className='text-title header-text'>Sector Wise Risk</Typography>

        {loading
          ? (
              ''
            )
          : <IconButton
              onClick={openSectorModal}
              className='tooltip-trade'
            >
            <img src={redirect} alt='' />
            <span
              className='tooltiptext'
              style={{ minWidth: '75px', marginLeft: '-20px' }}
            >
              Sector Wise Risk
            </span>
          </IconButton>}
      </Box>
      <ErrorBoundary fallbackRender={(props) => (<ErrorFallback {...props} screen={screen} />)}>
        <RiskTable headers='Sector' loading={loading}>
          {loading
            ? (
              <RiskTableloader headers='Sector' />
              )
            : (
              <>
                <TableBody>
                  {sectorWiseAccounts?.length > 0
                    ? <>
                      {(sectorWiseAccounts.slice(0, 10) || [])?.map(
                        (item, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.assetClassDesc || ''}</TableCell>
                              <TableCell align='right'>
                                {item.weight !== undefined
                                  ? (Number(item.weight) * 100)
                                      .toFixed(2)
                                      .toString()
                                  : '0.00'}
                              </TableCell>
                              <TableCell>
                                <Box display='flex' alignItems='center' justifyContent='center'>
                                  <div
                                    className={
                                      item.riskColor === 'G'
                                        ? 'progressbar me-2 bg-green'
                                        : item.riskColor === 'Y'
                                          ? 'progressbar me-2 bg-yellow'
                                          : item.riskColor === 'R'
                                            ? 'progressbar me-2 bg-red'
                                            : 'progressbar me-2'
                                    }
                                  />
                                  <div
                                    className={
                                      item.riskColor === 'Y'
                                        ? 'progressbar me-2 bg-yellow'
                                        : item.riskColor === 'R'
                                          ? 'progressbar me-2 bg-red'
                                          : 'progressbar me-2'
                                    }
                                  />
                                  <div
                                    className={
                                      item.riskColor === 'R'
                                        ? 'progressbar me-2 bg-red'
                                        : 'progressbar me-2'
                                    }
                                  />
                                </Box>
                              </TableCell>
                              <TableCell align='right'>
                                {item.statsValue
                                  ? (Number(item.statsValue) * 100)
                                      .toFixed(4)
                                      .toString()
                                  : '0.0000'}
                              </TableCell>
                            </TableRow>
                          )
                        }
                      )}
                    </>
                    : <TableRow>
                      <TableCell colSpan={5} style={{ textAlign: 'center' }}>
                        No Rows
                      </TableCell>
                    </TableRow>}
                </TableBody>
              </>
              )}
        </RiskTable>
      </ErrorBoundary>
      <Dialog
        fullWidth
        maxWidth='md'
        open={showSectorModal}
        onClose={() => closeSectorModal()}
      >
        <DialogTitle sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', pb: 0 }}>
          <Typography
            variant='h6'
            textAlign='left'
            fontWeight={400}
            color='#34475A'
          >
            Sector Wise Risk
          </Typography>

          <IconButton
            aria-label='close'
            onClick={closeSectorModal}
            sx={{
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <ErrorBoundary fallbackRender={(props) => (<ErrorFallback {...props} screen={screen} />)}>
            <Box style={{ maxHeight: 650, width: '100%' }}>
              <DataGridPro
                columns={columns}
                rows={sectorRisk}
                pagination
                getRowId={getRowId}
                pageSizeOptions={[10]}
                initialState={{
                  ...sectorRisk?.initialState,
                  pagination: { paginationModel: { pageSize: 10 } }
                }}
                autoHeight
                sx={(theme) => ({
                  [`.${gridClasses.main}`]: {
                    overflow: 'unset'
                  },
                  [`.${gridClasses.columnHeaders}`]: {
                    position: 'sticky',
                    top: 0,
                    backgroundColor: theme.palette.background.paper,
                    zIndex: 1
                  },
                  [`.${gridClasses.footerContainer}`]: {
                    position: 'sticky',
                    bottom: -20,
                    backgroundColor: theme.palette.background.paper,
                    zIndex: 1
                  }
                })}
              />
            </Box>
          </ErrorBoundary>
        </DialogContent>
      </Dialog>
    </CardBodyRisk>
  )
}

export default SectorwiseRisk