import { getUserRole } from '../../../utils/getUserRole'
import { DeleteAPI } from '../components/Actions'

export const DeleteMasterData = async (deleteRow, tabIndex) => {
  switch (tabIndex) {
    case 'account-status-codes':
      return await DeleteAccountStatusCode(deleteRow)
    case 'restriction-codes':
      return await DeleteRestrictionCode(deleteRow)
    case 'sell-logic-codes':
      return await DeleteSellLogicCode(deleteRow)
    case 'tax-states':
      return await DeleteTaxCode(deleteRow)
    case 'property-codes':
      return await DeletePropertyCode(deleteRow)
    default:
      return {}
  }
}

const DeleteAccountStatusCode = async (deleteRow) => {
  const userRole = getUserRole()
  return DeleteAPI(
    'baseUriMasterData',
    `data-maintenance/v1/${userRole}/account-status-codes/${deleteRow?.accountStatusId}`
  )
}

const DeleteRestrictionCode = async (deleteRow) => {
  const userRole = getUserRole()
  return DeleteAPI(
    'baseUriMasterData',
    `data-maintenance/v1/${userRole}/restriction-codes/${deleteRow?.restrictionId}`
  )
}

const DeleteSellLogicCode = async (deleteRow) => {
  const userRole = getUserRole()
  return DeleteAPI(
    'baseUriMasterData',
    `data-maintenance/v1/${userRole}/sell-logic-codes/${deleteRow?.sellLogicId}`
  )
}

const DeleteTaxCode = async (deleteRow) => {
  const userRole = getUserRole()
  return DeleteAPI(
    'baseUriMasterData',
    `data-maintenance/v1/${userRole}/tax-states/${deleteRow?.stateId}`
  )
}

const DeletePropertyCode = async (deleteRow) => {
  const userRole = getUserRole()
  return DeleteAPI(
    'baseUriMasterData',
    `data-maintenance/v1/${userRole}/property-codes/${deleteRow?.propertyCode}`
  )
}
