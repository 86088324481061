import React from 'react'
import { useGridApiContext, GridRowModes } from '@mui/x-data-grid-pro'
import { validateExpression } from '../../../utils/ZscoreValidator'

export const CustomEditZscoreField = (props) => {
  const { id, value, field, row, disabled, checkboxFieldName } = props
  const apiRef = useGridApiContext()

  const handleValueChange = (event) => {
    const newValue = event.target.value
    let error = false
    error = validateExpression(newValue)
    apiRef.current.setEditCellValue({ id, field, value: newValue })
    if (field === 'statsValue') {
      apiRef.current.updateRows([{ id, error }])
    } else {
      apiRef.current.updateRows([{ id, error: error || (field === 'min' ? (row.maxError || (row.max === null || row.max === '')) : (row.minError || (row.min === null || row.min === ''))), [`${field === 'min' ? 'minError' : 'maxError'}`]: error }])
    }
  }

  return (
    <input
      className={'custom-textfield' + ((row[`${field === 'statsValue' ? 'error' : field === 'min' ? 'minError' : 'maxError'}`] && value) ? ' error-border' : '')}
      placeholder={field === 'statsValue' ? 'Z-score' : 'Weight'}
      type='text'
      name={field}
      disabled={disabled || row[`${checkboxFieldName}`] === 1}
      value={(row[`${checkboxFieldName}`] === 1 && checkboxFieldName !== 'isDefault') ? checkboxFieldName === 'sectorExclude' ? 0 : '' : (value || '')}
      onChange={(event) => handleValueChange(event)}
      style={{
        border: row[`${field === 'statsValue' ? 'error' : field === 'min' ? 'minError' : 'maxError'}`] && value ? '1px solid red' : ''
      }}
    />
  )
}

export const CustomZscoreField = ({ params, rowModesModel, checkboxFieldName, deleteFieldName }) => {
  return (
    <input
      className={'custom-textfield' + (params.row.minError ? ' error-border' : '') + ((params.row[`${deleteFieldName}`] && rowModesModel[params.row.id]?.mode !== GridRowModes.Edit) ? ' is-inserted' : '')}
      placeholder={params.field === 'statsValue' ? 'Z-score' : 'Weight'}
      type='text'
      disabled={rowModesModel[params.row.id]?.mode !== GridRowModes.Edit || params.row[`${checkboxFieldName}`] === 1}
      value={(params.row[`${checkboxFieldName}`] === 1 && checkboxFieldName !== 'isDefault') ? checkboxFieldName === 'sectorExclude' ? 0 : '' : (params.value || '')}
      style={{
        border: params.row.minError ? '1px solid red' : ''
      }}
    />
  )
}
