import React, { useState } from 'react'
import { Box, Chip, Grid, IconButton, Typography } from '@mui/material'
import closeIcon from '../../assets/images/close.svg'
import gridFilledIcon from '../../assets/images/icon-grid-filled.svg'
import gridIcon from '../../assets/images/icon-grid.svg'
import listFilledIcon from '../../assets/images/icon-list-filled.svg'
import listIcon from '../../assets/images/icon-list.svg'
import AccountGrid from './AccountGrid'
import AccountListTable from './AccountListTable'

const AllAccounts = ({ selectedFilters, removeFilter, isLoading }) => {
  const [viewMode, setViewMode] = useState('list')

  return (
    <>
      <Grid container display='flex' flexWrap='nowrap' justifyContent='space-between' mt={2} alignItems='center'>
        <Grid item display='flex' flexWrap='wrap' alignItems='center'>
          <Typography
            component='div'
            className='text-title header-text'
            mb={0}
          >
            All Accounts
          </Typography>
          <Box>
            {
            selectedFilters?.length
              ? selectedFilters.map((filter, index) => {
                return (
                  <Chip
                    key={index}
                    sx={{
                      ml: 1,
                      borderRadius: '5px',
                      background: 'rgba(116,120,141,.102)',
                      color: '#74788D',
                      '.MuiChip-icon': {
                        mx: 0
                      }
                    }}
                    size='small'
                    icon={
                      <IconButton
                        onClick={() => removeFilter(filter)}
                        sx={{
                          borderRadius: '0px',
                          borderRight: '0.5px solid #74788D40'
                        }}
                      > <img src={closeIcon} alt='' height={8} width={8} />
                      </IconButton>
                            }
                    label={filter.filterType === 'strategy' ? filter.strategyName : `${filter.filterName} ${filter.value[0]}-${filter.value[1]}`}
                    className='chip'
                  />
                )
              })
              : ''
        }
          </Box>
        </Grid>
        <Grid item>
          <Grid container flexWrap='nowrap'>
            <Grid item>
              <span className='tooltip-trade'>
                <img src={viewMode === 'list' ? listFilledIcon : listIcon} height={30} width={30} alt='listview' onClick={() => setViewMode('list')} className='cursor-pointer' />
                <span
                  className='tooltiptext'
                  style={{ marginLeft: '-45px' }}
                >
                  List View
                </span>
              </span>
            </Grid>
            <Grid item>
              <span className='tooltip-trade'>
                <img src={viewMode === 'grid' ? gridFilledIcon : gridIcon} height={30} width={30} alt='gridview' onClick={() => setViewMode('grid')} className='cursor-pointer' />
                <span
                  className='tooltiptext'
                  style={{ marginLeft: '-45px' }}
                >
                  Grid View
                </span>
              </span>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container sx={{ marginTop: '10px' }}>
        <Grid item xs={12}>
          {
                viewMode === 'list'
                  ? <AccountListTable isLoading={isLoading} />
                  : <AccountGrid />
        }
        </Grid>
      </Grid>
    </>
  )
}

export default AllAccounts
