import { DeleteAPI } from '../components/Actions'
import { getUserRole } from '../../../utils/getUserRole'
import { FormatDateAPI } from '../components/FormatAPIRequest'

export const DeleteStrategyData = async (deleteRow, tabIndex) => {
  switch (tabIndex) {
    case 'strategy-parameter':
      return await DeleteStrategyParameter(deleteRow?.strategyId)
    case 'strategy-benchmark-map':
      return await DeleteStrategyBenchmarkMap(deleteRow?.strategyId)
    case 'strategy-instrument-restriction':
      return await DeleteStrategyInstrumentRestriction(deleteRow)
    case 'strategy-properties-master':
      return await DeleteStrategyPropertyMaster(deleteRow?.strategyPropertiesId)
    case 'strategy-benchmark-master':
      return await DeleteStrategyBenchmarkMaster(deleteRow?.benchmarkId)
    default:
      return {}
  }
}

const DeleteStrategyParameter = async (strategyId) => {
  const userRole = getUserRole()
  return DeleteAPI(
    'baseStrategyMaintainURL',
    `data-maintenance/v1/${userRole}/strategies/strategy-parameter/${strategyId}`)
}

const DeleteStrategyBenchmarkMap = async (strategyId) => {
  const userRole = getUserRole()
  return DeleteAPI(
    'baseStrategyMaintainURL',
    `data-maintenance/v1/${userRole}/strategies/benchmark-map/${strategyId}`)
}

const DeleteStrategyInstrumentRestriction = async (deleteRow) => {
  const userRole = getUserRole()
  return DeleteAPI(
    'baseStrategyMaintainURL',
    `data-maintenance/v1/${userRole}/strategies/instruments/restrictions/${deleteRow?.strategyInstrRestrId}`)
}

const DeleteStrategyPropertyMaster = async (strategyPropertiesId) => {
  const userRole = getUserRole()
  return DeleteAPI(
    'baseStrategyMaintainURL',
    `data-maintenance/v1/${userRole}/strategies/properties/${strategyPropertiesId}`)
}

const DeleteStrategyBenchmarkMaster = async (benchmarkId) => {
  const userRole = getUserRole()
  return DeleteAPI(
    'baseStrategyMaintainURL',
    `data-maintenance/v1/${userRole}/strategies/bench-master/${benchmarkId}`)
}
