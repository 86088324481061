import React, { createContext, useContext, useState, useEffect } from 'react'
const TourContext = createContext()

export function useTourContext () {
  return useContext(TourContext)
}

export function TourContextProvider ({ children }) {
  const [fetchedAllApiResponse, setFetchedAllApiResponse] = useState(false)
  const [isNewUser, setIsNewUser] = useState(false)
  const [startTour, setStartTour] = useState(false)

  const newUserTour = () => {
    if (isNewUser && fetchedAllApiResponse) {
      setStartTour(true)
    } else {
      setStartTour(false)
    }
  }

  const tourContextValue = {
    setFetchedAllApiResponse,
    setIsNewUser,
    startTour
  }

  useEffect(() => {
    newUserTour()
  }, [isNewUser, fetchedAllApiResponse])

  return (
    <TourContext.Provider value={tourContextValue}>
      {children}
    </TourContext.Provider>
  )
}
