import { aggregatePortfolioData, industryTable, sectorTable, sectorWiseRisk, securityTable, securityWiseRisk, substitutionTable } from '../droppableElements/APIResponseConverts/chartDefaultsData'

export const DATA_TYPE = {
  STRING: 'STRING',
  NUMBER: 'NUMBER',
  DATE: 'DATE',
}

export const DISPLAY_DATA_TYPE = {
  STRING: ['Text'],
  NUMBER: ['Percentage', 'Currency', 'Number'],
  DATE: ['Date']
}

export const DATE_FORMAT = ['YYYY-MM-DD', 'MM-DD-YYYY', 'DD-MM-YYYY', 'DD MMM YYYY', 'DD-MM-YY']

export const DEFAULT_FORMAT_FIELDS = {
  'Percentage': {
    symbol: true,
    decimal: 2
  },
  'Currency': {
    commas: true,
    decimal: 0
  },
  'Number': {
    decimal: 0
  },
  'Date': {
    type: 'YYYY-MM-DD'
  },
  'Text': {}
}

export const DATE_FILTERS = ['Equals', 'Does not equal', 'Before', 'After', 'Between', 'Blank', 'Not blank']
export const STRING_FILTERS = ['Equals', 'Does not equal', 'Starts with', 'Ends with', 'Contains', 'Does not contain', 'Blank', 'Not blank']
export const NUMBER_FILTERS = ['Equals', 'Does not equal', 'Greater than', 'Greater than or equal to', 'Less than', 'Less than or equal to', 'Between', 'Blank', 'Not blank']

export const TYPE_WISE_FILTERS = {
  DATE: DATE_FILTERS,
  STRING: STRING_FILTERS,
  NUMBER: NUMBER_FILTERS
}

export const AGGREGATE_FUNCTIONS = {
  NUMBER: ['sum', 'average', 'min', 'max', 'first', 'last', 'blank'],
  STRING: ['first', 'last', 'blank'],
  DATE: ['first', 'last', 'blank']
}

export const CUSTOM_TABLE_EXAMPLE_DATA = {
  'Aggregate Portfolio': aggregatePortfolioData.data,
  'Industry Constraints': industryTable.data.data,
  'Risk By Sector': sectorWiseRisk.data.data,
  'Risk By Security': securityWiseRisk.data.data,
  'Sector Constraints': sectorTable.data.data,
  'Security Constraints': securityTable.data.data,
  'Substitution Constraints': substitutionTable.data.data
}


export const headers = {
  'Aggregate Assets Table': {
    columns: [
      {
        field: 'accountName',
        headerName: 'Name',
        headerType: 'STRING',
        defaultDisplayType: 'Text'
      },
      {
        field: 'allocationPercentage',
        headerName: 'Allocation Percentage',
        headerType: 'NUMBER',
        defaultDisplayType: 'Percentage'
      },
      {
        field: 'marketValue',
        headerName: 'Market Value',
        headerType: 'NUMBER',
        defaultDisplayType: 'Currency'
      }
    ]
  },
  'Aggregate Performance': {
    columns: [
      {
        field: 'accountName',
        headerName: 'Name',
        headerType: 'STRING',
        defaultDisplayType: 'Text'
      },
      {
        field: '1M',
        headerName: '1M',
        headerType: 'NUMBER',
        defaultDisplayType: 'Percentage'
      },
      {
        field: '3M',
        headerName: '3M',
        headerType: 'NUMBER',
        defaultDisplayType: 'Percentage'
      },
      {
        field: '1Y',
        headerName: '1Y',
        headerType: 'NUMBER',
        defaultDisplayType: 'Percentage'
      },
      {
        field: '3Y',
        headerName: '3Y',
        headerType: 'NUMBER',
        defaultDisplayType: 'Percentage'
      },
      {
        field: 'YTD',
        headerName: 'YTD',
        headerType: 'NUMBER',
        defaultDisplayType: 'Percentage'
      },
      {
        field: 'performanceStartDate',
        headerName: 'Inception Date',
        headerType: 'DATE',
        defaultDisplayType: 'Date'
      },
    ]
  }
}