import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { randomId } from '@mui/x-data-grid-generator'

export function ExpandAssetAllocation (props) {
  const { row } = props
  const [securityAllocationRows, setSecurityAllocationRows] = useState([])

  useEffect(() => {
    if (typeof row === 'object' && Array.isArray(row?.securityLevelAllocation)) {
      // Flatten the securityLevelAllocation array
      setSecurityAllocationRows(row?.securityLevelAllocation?.map((security, index) => ({
        id: randomId(),
        assetClass: row?.assetClass,
        securityName: security?.name,
        currentPortfolioWeight: (Number(security?.currentPortfolioWeight) * 100).toFixed(2),
        targetPortfolioWeight: (Number(security?.targetPortfolioWeight) * 100).toFixed(2),
        difference: (Number(security?.difference) * 100).toFixed(2)
      })))
    }
  }, [row])

  const columns = [
    { field: 'id', headerName: '', width: 50, valueFormatter: () => '' },
    { field: 'securityName', headerName: 'Security Name', flex: 1 },
    {
      field: 'currentPortfolioWeight',
      headerName: 'Account Weight(%)',
      align: 'right',
      flex: 1
    },
    {
      field: 'targetPortfolioWeight',
      headerName: 'Model Weight(%)',
      align: 'right',
      flex: 1
    },
    { field: 'difference', headerName: 'Difference', align: 'right', flex: 1 }
  ]

  return (
    <Box sx={{ width: '100%', height: '800' }}>
      <DataGridPro
        autoHeight
        rows={securityAllocationRows}
        columns={columns}
        columnHeaderHeight={0}
        getRowId={(row) => row.id}
        hideFooter
        initialState={{
          ...securityAllocationRows?.initialState
        }}
      />
    </Box>
  )
}
