import { gridFilteredDescendantCountLookupSelector, useGridApiContext, useGridSelector } from '@mui/x-data-grid'
import { Box, IconButton } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'

const CustomGridTreeDataGroupingCell = (props) => {
  const { id, field, rowNode, groupType } = props
  const apiRef = useGridApiContext()
  const filteredDescendantCountLookup = useGridSelector(
    apiRef,
    gridFilteredDescendantCountLookupSelector
  )
  const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0

  const currentGrp = groupType === 'sector' ? props?.row?.name : props?.row?.name

  const handleClick = (event) => {
    if (rowNode.type !== 'group') {
      return
    }

    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded)
    apiRef.current.setCellFocus(id, field)
    event.stopPropagation()
  }
  return (
    <Box sx={{ ml: 0 }}>
      <div>
        {filteredDescendantCount > 0 && !props?.row?.isLeaf
          ? (
            <>
              <IconButton
                size='small'
                onClick={handleClick}
                tabIndex={-1}
                aria-label={rowNode.childrenExpanded ? 'Close' : 'Open'}
              >
                <ExpandMore
                  sx={{
                    transform: `rotateZ(${rowNode.childrenExpanded ? 360 : 270}deg)`,
                    transition: (theme) =>
                      theme.transitions.create('transform', {
                        duration: theme.transitions.duration.shortest
                      })
                  }}
                  fontSize='inherit'
                />
              </IconButton>
              <span>
                {groupType === 'sector' ? props?.row?.sector : props?.row?.company}
              </span>
            </>
            )
          : (
            <span style={{ marginLeft: rowNode.depth * 27 }}>
              {currentGrp}
            </span>
            )}
      </div>
    </Box>
  )
}

export default CustomGridTreeDataGroupingCell
