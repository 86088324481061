import { useEffect, useState } from 'react'
import { Box, Button, Divider, IconButton, Modal, Tooltip, Typography } from '@mui/material'
import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro'
import { randomId } from '@mui/x-data-grid-generator'
import CircleIcon from '@mui/icons-material/Circle'
import CloseIcon from '@mui/icons-material/Close'
import AssignmentLateOutlinedIcon from '@mui/icons-material/AssignmentLateOutlined'
import { HtmlTooltip } from './HtmlTooltip'
import ViewPolicyJson from './ViewPolicyJson'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  px: 2,
  py: 2,
  width: '100%',
  borderRadius: '4px'
}

const getStatusColor = (color) => {
  if (color === 'green')
    return '#3BBFA3'
  else if (color === 'red')
    return '#FF6161'
  else if (color === 'yellow')
    return '#D29922'
  else if (color === 'gray')
    return '#0000004d'
  return '#c9c9c9'
}

const policyColumns = [
  {
    headerName: 'Policy',
    field: 'policyDesc',
    flex: 2
  },
  {
    headerName: 'Policy Status',
    field: 'policyStatus',
    width: 100,
    align: 'center',
    headerAlign: 'center',
    renderCell: (params) => (
      <Tooltip title={params?.row?.policyStatus}>
        <CircleIcon sx={{ color: getStatusColor(params?.row?.policyColorStatus), height: '12px', width: '12px' }} />
      </Tooltip>
    )
  },
  {
    headerName: 'Last Policy Check',
    field: 'lastUpdatedDate',
    flex: 1,
    type: 'date',
    valueGetter: (params) => {
      if (!params?.value) return params?.value
      const date = new Date(params?.value)
      return new Date(date?.getTime() + date?.getTimezoneOffset() * 1000 * 60)
    },
    renderCell: (params) => params?.row?.lastUpdatedDate ? params?.row?.lastUpdatedDate?.split('T')[0] : ''
  },
  {
    headerName: 'Reason',
    field: 'reason',
    align: 'center',
    width: 100,
    renderCell: (params) => (
      params.row.reason ? (
        <HtmlTooltip
          title={
            <>
              <Typography color='inherit' fontFamily='Open Sans' fontWeight={600}>Reason</Typography>
              <Divider sx={{ backgroundColor: '#000000' }} />
              <Typography sx={{ fontSize: '14px' }} my={1}>{params.row.reason}</Typography>
            </>
          }
        >
          <AssignmentLateOutlinedIcon sx={{ cursor: 'pointer' }} />
        </HtmlTooltip >
      ) : ''
    )
  }
]

const PolicyStatusPopup = ({ showPolicyPopup, onClose, data, canDownloadJson, title }) => {
  const [policyData, setPolicyData] = useState([])
  const [showReportPopup, setShowReportPopup] = useState(false)

  useEffect(() => {
    if (data?.policies?.length) {
      setPolicyData(data?.policies?.map(policy => ({
        id: randomId(),
        policyCode: policy?.policyCode,
        policyDesc: policy?.policyDesc,
        policyStatus: policy?.policyStatus,
        policyColorStatus: policy?.policyColorStatus,
        lastUpdatedDate: policy?.lastUpdatedDate,
        reason: policy?.reason
      })))
    }
  }, [data])

  return (
    <>
      <Modal
        onClose={onClose}
        open={showPolicyPopup}
      >
        <Box sx={{ ...modalStyle, outline: 'none', minWidth: '400px', maxWidth: '800px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: '5px', position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 2 }}>
            <Typography variant='h6' sx={{ ml: '5px', fontWeight: 400, color: '#34475A' }}>{`${title === 'Market Value' ? title + ' ' : ''}`}Policy Check</Typography>
            <Box sx={{ display: 'flex', gap: '10px' }}>
              {
                policyData.length && canDownloadJson ? (
                  <Button
                    variant='text'
                    onClick={() => setShowReportPopup(true)}
                    sx={{ textTransform: 'capitalize' }}
                  >
                    View Policy Report
                  </Button>
                ) : ''
              }
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <DataGridPro
            autoHeight
            density='compact'
            rows={policyData}
            columns={policyColumns}
            getRowId={(row) => row?.id}
            pagination
            pageSizeOptions={[10]}
            initialState={{
              ...policyData?.initialState,
              pagination: { paginationModel: { pageSize: 10 } }
            }}
            slots={{
              noRowsOverlay: CustomNoRowsOverlay
            }}
            slotProps={{
              noRowsOverlay: { msg: 'No Policies Found.' }
            }}
            sx={(theme) => ({
              '& .MuiDataGrid-cell:focus': {
                outline: 'none'
              },
              [`.${gridClasses.main}`]: {
                height: 'min(100vh - 16px - 45px - 16px - 53px, 600px - 16px - 45px - 16px - 53px)',
              },
              [`.${gridClasses.columnHeaders}`]: {
                position: 'sticky',
                backgroundColor: theme.palette.background.paper,
                top: 0,
                zIndex: 1,
              },
              [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
                color: '#74788d',
                fontWeight: 600
              },
              [`.${gridClasses.virtualScroller}`]: {
                overflowY: 'auto !important',
                scrollbarGutter: 'stable',
                scrollbarWidth: 'none'
              }
            })}
          />
        </Box>
      </Modal>
      <ViewPolicyJson policyData={data} showReportPopup={showReportPopup} onClose={() => setShowReportPopup(false)} title={title} />
    </>
  )
}

const CustomNoRowsOverlay = ({ msg }) => (
  <Typography sx={{ textAlign: 'center', margin: '16px auto' }}>
    {msg}
  </Typography>
)

export default PolicyStatusPopup