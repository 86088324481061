import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addChartValues } from '../../../store/pdf-customizer-reducer/Action/chartDropAction'
import { summaryDataApi } from '../../../utils/pdf-customizer/_data'
import { useAuth } from '../../../contexts/AuthContext'
import {
  decTwoPercentage,
  fetchDataAndDispatch,
  summeryTableConvertResponse
} from './APIResponseConverts/APIResponseConverts'
import { SummaryTable } from './APIResponseConverts/chartDefaultsData'
import './table.css'

const TABLE_WIDTH = '100%'

function isNumeric(num) {
  return !isNaN(num * 1) && !Number.isInteger(num * 1)
}

const TableCell = ({ content, label, backgroundColor, tableTextAlign }) => {
  const width = {
    'Scenario Name': '15%',
    'Tracking Error(%)': '15%',
    'No. of Stocks (Turnover)': '15%',
    Buy: '10%',
    Sell: '10%',
    'Proposed Gain/Loss($)': '20%',
    'Total Taxes($)': '15%'
  }

  return (
    <td
      width={width[label]} style={{
        // background: backgroundColor
        // backgroundImage: 'linear-gradient(to bottom, #FFFFFF, #FFFFFF 50%, #E9E9E9 50% 100%)',
        // backgroundPosition: '0 100%',
        // backgroundRepeat: 'no-repeat',
        // backgroundSize: '100% 1px'
      }} className={`${tableTextAlign}`}
    >
      <div style={{ background: backgroundColor }}>
        {Array.isArray(content)
          ? (
            <ul className='width-100 padding-0 gain_loss_ul'>
              {content.map((item, itemIndex) => (
                <li
                  key={itemIndex}
                  className={`width-100 flexOrAlignOrBetWeen ${item.name === 'Total' ? 'isTotal' : ''
                    }`}
                >
                  <div className='inline-block gain_loss_ul_1 '>{item.name}</div>
                  <div className='text-right inline-block gain_loss_ul_2'>
                    {isNumeric(item.value)
                      ? decTwoPercentage(item.value * 1, 2)
                      : item.value}
                  </div>
                </li>
              ))}
            </ul>
          )
          : (
            isNumeric(content) ? decTwoPercentage(content * 1, 2) : content
          )}
      </div>
    </td>
  )
}

const Table = ({ data, chartIndex, pageIndex, chartWidth, chartHeight }) => {
  const { summaryResponse, isResponseElements } = useSelector(
    (state) => state.chartState
  )
  const templateData = useSelector(state => state.templateData)
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const datasets = data?.value?.datasets || []
  const { user } = useAuth()

  useEffect(() => {
    if (isResponseElements && !summaryResponse && !isLoading) {
      setIsLoading(true)
      fetchDataAndDispatch(() => summaryDataApi(templateData, user?.userGroup), 'SUMMARY_DATA', dispatch)
    }
    if (isResponseElements && summaryResponse) {
      setIsLoading(false)
    }
  }, [isResponseElements, summaryResponse, dispatch])

  const { headingColumns, tableRows, tableTextAlign } = summeryTableConvertResponse(
    summaryResponse || SummaryTable.data
  )

  useEffect(() => {
    if (data.value || !headingColumns.length) return

    const mappedDatasets = headingColumns.map((label) => ({
      label,
      hidden: false,
      backgroundColor: 'rgba(255, 255, 255, 1)'
    }))

    const chartValues = {
      labels: [],
      datasets: mappedDatasets,
      chartIndex: data?.chartIndex,
      pageIndex: data?.pageIndex
    }

    dispatch(addChartValues(chartValues))
  }, [headingColumns, data])

  const chartStyle = {
    width: '100%',
    height: '100%'
  }

  return (
    <div
      className={'group pdf-customizer-summary-table'}
      style={chartStyle}
    >
      <p style={{ margin: '0', fontSize: '10px', fontWeight: 500, textTransform: 'capitalize' }}>
        {data.headingText || data.name}
      </p>
      {isLoading
        ? <span className='loading-text'>Loading...</span>
        : <div className='table-component'>
          <table width={TABLE_WIDTH} style={{ borderCollapse: 'collapse' }}>
            <thead>
              <tr
                className='headingBorder' style={{
                  // backgroundImage: 'linear-gradient(to bottom, #FFFFFF, #FFFFFF 50%, #E9E9E9 50% 100%)',
                  // backgroundPosition: '0 100%',
                  // backgroundRepeat: 'no-repeat',
                  // backgroundSize: '100% 1px'
                }}
              >
                {tableRows.length > 0 &&
                  Array.isArray(datasets) &&
                  datasets.map(
                    (header, idx) =>
                      !header.hidden && (
                        <th key={idx} className={`${tableTextAlign ? tableTextAlign[idx] : 'text-align-center'}`}>
                          {header.label}
                        </th>
                      )
                  )}
              </tr>
            </thead>
            <tbody>
              {tableRows.length > 0 &&
                Array.isArray(tableRows) &&
                tableRows.map((row, rowIndex) => (
                  <tr key={rowIndex} className='borderBottom'>
                    {datasets.map(
                      (key, cellIndex) =>
                        !key?.hidden && (
                          <TableCell
                            key={cellIndex}
                            content={row[key.label]}
                            label={key.label}
                            tableTextAlign={tableTextAlign ? tableTextAlign[cellIndex] : 'text-align-center'}
                            backgroundColor={key.backgroundColor}
                          />
                        )
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>}
    </div>
  )
}

export default Table
