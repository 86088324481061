import React, {
  Fragment} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  addPage,
  isActiveHandle,
  itemDragging,
  onDragChange,
  addCoverPage} from '../../../store/pdf-customizer-reducer/Action/chartDropAction.js'
  import PageComponent from './Page.js'
  import './page.css'

const ReportPage = ({ pdfExportComponent }) => {
  const dispatch = useDispatch()
  const { pages, activeElement } = useSelector(
    (state) => state.elementStates
  )

  const { color, fontFamily, pageSize } = useSelector(
    (state) => state.allPageStyle
  )

  const handleAddPage = (position) => {
    dispatch(addPage(position))
  }

  const handleDrop = (e, dropIndex) => {
    e.preventDefault()

    if (e.dataTransfer.getData('text/plain')) {
      const droppedData = JSON.parse(e.dataTransfer.getData('text/plain'))
      const {
        chartIndex,
        index: dragIndex,
        isEdit,
        headingText,
        name,
        size,
        coverPage,
        width
      } = droppedData
      // if (e.target === e.currentTarget) {
      if (name) {
        const elementStates = {
          name,
          size: size || 0,
          dropIndex,
          width,
          isEdit,
          headingText
        }

        if (coverPage) {
          Object.assign(elementStates)
          dispatch(addCoverPage(elementStates))
        } else {
          dispatch(itemDragging(elementStates))
        }
      } else {
        const isPageDrop = e.target.getAttribute('name') === 'page'
        if (isPageDrop) {
          const data = { chartIndex, dragIndex, dropIndex, onPage: true }
          dispatch(onDragChange(data))
        }
      }
    }
  }

  return (
    <div
      className='main-page relative'
      id='drop-area'
      style={{ color, fontFamily }}
      onClick={(e) => {
        if ((e?.target?.className === e?.currentTarget?.className || e?.currentTarget === null) && activeElement) {
          dispatch(isActiveHandle({ clearActiveElement: true }))
        }
      }}
    >
      {/* Add Page Button */}
      <button
        onClick={() => handleAddPage('top')}
        className='add-page-btn margin-top-3 margin-bottom-1'
        style={{ width: `${pageSize?.width}px` }}
      >
        Add Page
      </button>

      {/* Render Pages and Charts */}
      <div
        ref={pdfExportComponent}
        className='showInCenter flexOrAlign directionColumn'
      >
        {Array.isArray(pages) &&
          pages.map((page, index) => {
            const pageKey = page?.id || index + new Date()
            return (
              <Fragment key={pageKey}>
                <PageComponent pageKey={pageKey} page={page} pageIndex={index} />
              </Fragment>
            )
          })}
      </div>
      {/* Add Page Button (Bottom) */}
      {Array.isArray(pages) && pages.length > 0 && (
        <button
          onClick={() => handleAddPage('bottom')}
          className='add-page-btn margin-top-1  margin-bottom-3'
          style={{ width: `${pageSize?.width}px` }}
        >
          Add Page
        </button>
      )}
    </div>
  )
}

export default ReportPage
