import React, { useEffect, useState } from 'react'
import { Box, Checkbox, TextField } from '@mui/material'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { validateExpression } from '../../utils/ZscoreValidator'
import CustomTooltipIcon from '../Personalization/components/CustomTooltipIcon'

const Sector = ({ data, setData, setError }) => {
  const [sectorData, setSectorData] = useState(data)
  const [isError, setIsError] = useState({})
  const [selectedRows, setSelectedRows] = useState([])

  useEffect(() => {
    setData(sectorData)
  }, [sectorData])

  useEffect(() => {
    for (const key in isError) {
      if (isError[key]?.min || isError[key]?.max) {
        setError(prevError => ({ ...prevError, sector: true }))
        break
      } else {
        setError(prevError => ({ ...prevError, sector: false }))
      }
    }
  }, [isError])

  const columns = [
    { field: 'sectorName', headerName: 'Sector', flex: 2, editable: false },
    { field: 'count', headerName: 'No. of Securities', flex: 1, editable: false, type: 'number', align: 'right' },
    { field: 'sumWeight', headerName: 'Weight(%)', flex: 1, editable: false, type: 'number', align: 'right' },
    {
      field: 'exclude',
      headerName: 'Exclude',
      flex: 1,
      renderCell: (params) => (
        <Checkbox
          checked={params.value}
          onClick={(event) => event.stopPropagation()}
          disabled={params?.row?.sectorName === 'All Sectors'}
          onChange={() => handleConfirmChange(params?.row?.sectorName)}
        />
      )
    },
    {
      field: 'min',
      headerName: 'Min',
      type: 'text',
      flex: 1,
      description: 'Min value',
      sortable: false,
      renderHeader: (params) => (
        <>
          Min
          <CustomTooltipIcon text='You can indicate a relative value by using the format B(+/-)(value). For example,  B-3 or B+3.' action='help' />
        </>
      ),
      renderCell: (params) => {
        const rowId = params?.id
        const minIsInvalid = isError[rowId]?.min || false

        return (
          <TextField
            label='Weight'
            size='small'
            disabled={params?.row?.exclude || params?.row?.sectorName === 'All Sectors'} // Disable for 'All Sectors'
            value={params?.row?.exclude ? 0 : params?.value}
            onChange={(event) => {
              handleCellEditCommit('min', event.target.value, params?.row?.sectorName, rowId)
            }}
            error={!!((minIsInvalid && params?.value))}
          />
        )
      }
    },
    {
      field: 'max',
      headerName: 'Max',
      type: 'text',
      flex: 1,
      description: 'Max value',
      sortable: false,
      renderHeader: (params) => (
        <>
          Max
          <CustomTooltipIcon text='You can indicate a relative value by using the format B(+/-)(value). For example,  B-3 or B+3.' action='help' />
        </>
      ),
      renderCell: (params) => {
        const rowId = params?.id
        const maxIsInvalid = isError[rowId]?.max || false

        return (
          <TextField
            label='Weight'
            size='small'
            disabled={params?.row?.exclude || params?.row?.sectorName === 'All Sectors'} // Disable for 'All Sectors'
            value={params?.row?.exclude ? 0 : params?.value}
            onChange={(event) => {
              handleCellEditCommit('max', event.target.value, params?.row?.sectorName, rowId)
            }}
            error={!!((maxIsInvalid && params?.value))}
          />
        )
      }
    }
  ]

  function getRowId (row) {
    return row?.sectorName
  }

  // handle onChange for sector Data
  function handleCellEditCommit (field, value, sectorName, rowId) {
    if ((field === 'min' || field === 'max') && sectorName === 'All Sectors') {
      const updatedData = data?.map((row) => {
        return { ...row, [field]: value }
      })
      setSectorData(updatedData)
    } else if (selectedRows?.length > 0 && selectedRows?.filter((row) => row?.sectorName === sectorName)?.length > 0) {
      const updatedData = data?.map((row) => {
        if (selectedRows?.length > 0 && selectedRows?.filter((item) => item?.sectorName === row?.sectorName)?.length > 0) {
          return { ...row, [field]: value }
        } else {
          return { ...row }
        }
      })
      setSectorData(updatedData)
    } else {
      setSectorData((prevData) => {
        const index = prevData?.findIndex((row) => row?.sectorName === sectorName)
        const updatedRow = { ...prevData[index], [field]: value }
        const updatedData = [...prevData]
        updatedData[index] = updatedRow
        return updatedData
      })
    }
    // validations for sector Data, values to be between B+3 to B-3 or -3 to +3
    const isValidExpression = validateExpression(value)
    setIsError((prevErrors) => ({
      ...prevErrors,
      [rowId]: {
        ...prevErrors[rowId],
        [field]: isValidExpression
      }
    }))

    if (!value.trim()) {
      setIsError((prevErrors) => ({
        ...prevErrors,
        [rowId]: {
          ...prevErrors[rowId],
          [field]: false
        }
      }))
    }
  }

  function handleConfirmChange (clickedRow) {
    const updatedData = data?.map((x) => {
      if (x?.sectorName === clickedRow) {
        return {
          ...x,
          exclude: !x?.exclude
        }
      }
      return x
    })
    setSectorData(updatedData)
    setIsError((prevErrors) => ({
      ...prevErrors,
      [clickedRow]: {
        ...prevErrors[clickedRow],
        min: false,
        max: false
      }
    }))
  }

  const handleRowSelection = (sectorName) => {
    const selectedIDs = new Set(sectorName)
    const selectedRows = data?.filter((row) => selectedIDs.has(row?.sectorName)) || []
    setSelectedRows(selectedRows)
  }

  return (
    <Box
      sx={{
        '.MuiDataGrid-footerContainer': {
          border: 'none'
        },
        '.MuiDataGrid-root': {
          border: 'none'
        },
        '.MuiDataGrid-columnHeaderTitle': {
          fontFamily: 'Open Sans',
          color: '#74788D !important',
          fontWeight: 600
        },
        '.MuiDataGrid-cellContent': {
          fontFamily: 'Open Sans',
          color: '#34475A',
          fontWeight: 600
        }
      }}
    >
      <div style={{ width: '100%' }}>
        <DataGridPro
          autoHeight
          rows={sectorData}
          columns={columns}
          onRowSelectionModelChange={handleRowSelection}
          getRowId={getRowId}
          pagination
          pageSizeOptions={[10]}
          initialState={{
            ...sectorData?.initialState,
            pagination: { paginationModel: { pageSize: 10 } }
          }}
          disableRowSelectionOnClick
          disableMultipleRowSelection
          sx={{
            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: 600,
              fontFamily: 'Open Sans',
              color: '#34475A'
            },
            '& .MuiDataGrid-columnHeaderTitleContainerContent': {
              fontWeight: 600,
              fontFamily: 'Open Sans',
              color: '#74788d'
            }
          }}
        />
      </div>
    </Box>
  )
}

export default Sector
