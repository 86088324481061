import React from 'react'
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule'
import { Box, Card, CardContent, Grid, Typography } from '@mui/material'
import Slider from '@mui/material/Slider'
import ActiveWeightIcon from '../../assets/images/icons/active-weight.svg'
import TargetPortfolioRiskIcon from '../../assets/images/icons/target-portfolio-risk.svg'
import TotalPortfolioRiskIcon from '../../assets/images/icons/total-portfolio-risk.svg'
import TrackingErrorIcon from '../../assets/images/icons/tracking-error.svg'
import StatsWidget from '../../components/StatsWidget'

export const RiskAttributes = ({ riskAttri, riskStrAttri }) => {
  // Default value for the slider
  const defaultValue = riskAttri.PORT_REL_VOL
  // Custom thumb icon for the slider
  const ThumbIcon = React.forwardRef(() => (
    <div class='relVol-chart'>
      <div class='chat-icon' style={{ position: 'absolute', left: `calc(${defaultValue * 49}% - 44px)`, top: 0 }}>
        <svg width='50' height='50' viewBox='0 0 43 54' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path d='M0 0H35C39.4183 0 43 3.58172 43 8V44.3894V51.8174C43 53.6467 40.7473 54.516 39.5185 53.1609L31.5638 44.3894H8C3.58172 44.3894 0 40.8077 0 36.3894V0Z' fill='#002A59' />
          <text x='50%' y='45%' textAnchor='middle' dy='.3em' fontSize='16' fontWeight='600' fill='white'>
            {riskAttri.PORT_REL_VOL ? Number(riskAttri.PORT_REL_VOL).toFixed(2) : '0.00'}
          </text>
        </svg>
      </div>
    </div>
  ))

  // Function to create ruler lines for the slider
  const createRulerLines = () => {
    const numIntervals = 30
    const lines = []

    // Pushing the Start line (0 line) to the lines array
    lines.push(
      <div
        key='start_line'
        style={{
          position: 'absolute',
          left: 0,
          width: 3,
          height: 50,
          backgroundColor: '#7274744D',
          fontWeight: 'normal'
        }}
      />
    )

    // Pushing the Low label for the line 0 to the lines array
    lines.push(
      <div
        key='low_label'
        style={{
          position: 'absolute',
          left: 0,
          top: '60px',
          fontWeight: '600',
          fontSize: '13px',
          color: '#72747480'
        }}
      >
        Low
      </div>
    )

    // Pushing the 0 label for the line 0 to the lines array
    lines.push(
      <div
        key='high_label'
        style={{
          position: 'absolute',
          left: -1,
          top: '-24px',
          fontWeight: '600',
          fontSize: '13px',
          color: '#72747480'
        }}
      >
        0
      </div>
    )

    let nearestValue = 0
    // Generate intervals for the ruler lines
    for (let i = 1; i <= numIntervals; i++) {
      const intervalValue = i / (numIntervals + 1)
      const lineValue = intervalValue * 2

      const height =
        lineValue === 0 || lineValue === 2 || (lineValue > 0.999 && lineValue < 1.001)
          ? 50
          : 14

      if (Math.abs(lineValue - 1) < Math.abs(nearestValue - 1)) {
        nearestValue = lineValue
      }

      const lineColor = '#7274744D' // Default color

      // Render the ruler lines
      if (lineValue <= 2) {
        const position = `${(lineValue * (100 / 2) - 1.6)}%`
        // Pushing the ruler lines between 0 to 1 and 1 to 2 to lines array
        lines.push(
          <div
            key={`${lineValue}_${i}`}
            style={{
              position: 'absolute',
              left: position,
              top: 6,
              transform: 'rotate(90deg)', // Rotate the icon 90 degrees
              height
            }}
          >
            <HorizontalRuleIcon style={{ fontSize: 36, color: lineColor }} />
          </div>
        )
      }
    }

    // Pushing the Strategy label for Strategy line  to the lines array
    lines.push(
      <div
        key='nearest_line'
        style={{
          position: 'absolute',
          left: `${nearestValue * (100 / 2) - 0.8}%`,
          width: 5,
          height: 50,
          backgroundColor: '#002A59',
          fontWeight: 'normal',
          borderRadius: '12px 0px 12px 0px',
          marginTop: '-3px'
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: '60px',
            left: '50%',
            transform: 'translateX(-50%)',
            fontWeight: '600',
            fontSize: '13px',
            color: '#34475A'
          }}
        >
          Strategy
        </div>
      </div>
    )
    // Pushing the value 1 for Strategy line to the lines array
    lines.push(
      <div
        key='high_label'
        style={{
          position: 'absolute',
          left: '48%',
          transform: 'translateX(-50%)',
          top: '-24px',
          fontWeight: '600',
          fontSize: '13px',
          color: '#72747480'
        }}
      >
        1
      </div>
    )
    lines.push(
      <div
        key='end_line'
        style={{
          position: 'absolute',
          right: -4,
          transform: 'translateX(-100%)',
          width: 3,
          height: 50,
          backgroundColor: '#7274744D',
          fontWeight: 'normal'
        }}
      />
    )
    // Pushing the High Label to the lines array
    lines.push(
      <div
        key='high_label'
        style={{
          position: 'absolute',
          left: '100%',
          transform: 'translateX(-100%)',
          top: '60px',
          fontWeight: '600',
          fontSize: '13px',
          color: '#72747480'
        }}
      >
        High
      </div>
    )
    // Pushing the 2 label for the line 2 to the lines array
    lines.push(
      <div
        key='high_label'
        style={{
          position: 'absolute',
          right: -10,
          transform: 'translateX(-100%)',
          top: '-24px',
          fontWeight: '600',
          fontSize: '13px',
          color: '#72747480'
        }}
      >
        2
      </div>
    )
    return lines
  }

  // function for the slider's value label
  const valueLabelFormat = (value) => {
    if (value === 1) {
      return (
        <div style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)', width: 3, height: 50, backgroundColor: '#7274744D', fontWeight: 'normal' }} />
      )
    }
    return value
  }

  return (
    <Grid container mb={4} spacing={3} marginTop={1}>
      <Grid item xs={12} lg={7} sx={{ paddingTop: '8px !important' }}>
        <Grid container spacing={4} pr='3px'>
          <Grid item xs={6}>
            <StatsWidget
              title='Total Portfolio Risk'
              stats={
                riskAttri.PORT_RISK_STD === undefined
                  ? 'N/A'
                  : (Number(riskAttri.PORT_RISK_STD) * 100)
                      .toFixed(2)
                      .toString() + '%'
              }
              imgSrc={TotalPortfolioRiskIcon}
            />
          </Grid>
          <Grid item xs={6}>
            <StatsWidget
              title='Target Portfolio Risk'
              stats={
                riskStrAttri.PORT_RISK_STD === undefined
                  ? 'N/A'
                  : (Number(riskStrAttri.PORT_RISK_STD) * 100)
                      .toFixed(2)
                      .toString() + '%'
              }
              imgSrc={TargetPortfolioRiskIcon}
            />
          </Grid>
          <Grid item xs={6}>
            <StatsWidget
              title='Active Weight'
              stats={
                riskAttri.AA_DRIFT === undefined
                  ? 'N/A'
                  : (Number(riskAttri.AA_DRIFT) * 100).toFixed(2).toString() +
                  '%'
              }
              imgSrc={ActiveWeightIcon}
            />
          </Grid>
          <Grid item xs={6}>
            <StatsWidget
              title='Tracking Error'
              stats={
                riskAttri.PORT_TRACKING_ERROR === undefined
                  ? 'N/A'
                  : (Number(riskAttri.PORT_TRACKING_ERROR) * 100)
                      .toFixed(2)
                      .toString() + '%'
              }
              imgSrc={TrackingErrorIcon}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={5} my={{ xs: 4, lg: 0 }} sx={{ paddingTop: '8px !important' }}>
        <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', minHeight: 220 }} className='slider-card'>
          <CardContent sx={{ position: 'relative', width: '100%', height: '100%', padding: '0px' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
              <Typography sx={{ fontSize: '16px', fontWeight: 600, color: '#74788D' }}>
                Relative Volatility
              </Typography>
            </Box>
            <Box mt={1}>
              <Slider
                defaultValue={0.5}
                valueLabelDisplay='on'
                valueLabelFormat={valueLabelFormat}
                disabled
                components={{
                  Thumb: riskAttri.PORT_REL_VOL !== undefined ? ThumbIcon : () => null
                }}
                step={0.01}
                min={0}
                max={2}
                sx={{
                  '.MuiSlider-track': {
                    display: 'none'
                  },
                  '.MuiSlider-rail': {
                    display: 'none'
                  },
                  '.MuiSlider-markLabel': {
                    top: '42px !important'
                  }
                }}
              />
              {/* Ruler lines */}
              <div style={{ position: 'relative', height: 14, marginTop: 16, width: '100%' }}>
                {createRulerLines()}
              </div>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}
