import React from 'react'
import { Box } from '@mui/material'
import { DataGridPro } from '@mui/x-data-grid-pro'

export function TradeErrorTable ({ row }) {
  // convert errorMessage array into individual rows
  const errorRows = row?.errorMessage?.map((errorMsg, index) => ({
    id: `${row.id}_${index}`,
    errorMessage: errorMsg
  }))

  const columns = [
    { field: 'id', headerName: '', width: 50, valueFormatter: () => '' },
    {
      field: 'errorMessage',
      headerName: 'Error Message',
      flex: 1,
      renderCell: (params) => (
        <Box
          sx={{
            padding: 1
          }}
        >
          {params.value || 'N/A'}
        </Box>
      )
    }
  ]

  return (
    <Box sx={{ width: '100%' }}>
      <DataGridPro
        autoHeight
        density='compact'
        rows={errorRows}
        columns={columns}
        columnHeaderHeight={0}
        disableRowSelectionOnClick
        getRowId={(row) => row.id}
        getRowHeight={() => 'auto'}
        hideFooter
        initialState={{
          ...row?.initialState
        }}
        sx={{
          '.MuiDataGrid-cell': {
            borderBottom: '0.8px solid rgb(224, 224, 224) !important'
          }
        }}
      />
    </Box>
  )
}
