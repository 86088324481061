import React, { useEffect, useState } from 'react'
import './pageFooter.css'
import { useDispatch, useSelector } from 'react-redux'
import { fetchDataAndDispatch } from '../APIResponseConverts/APIResponseConverts'
import { accountDataApi } from '../../../../utils/pdf-customizer/_data'
import { accountData } from '../APIResponseConverts/chartDefaultsData'
import { upDatePageHeadingFooter } from '../../../../store/pdf-customizer-reducer/Action/chartDropAction'
import { useAuth } from '../../../../contexts/AuthContext'

const PageFooter = () => {
  const dispatch = useDispatch()
  const { accountDataResponse, isResponseElements } = useSelector(
    (state) => state.chartState
  )
  const { pageHeadingFooter } = useSelector((state) => state.elementStates)
  const { value } = pageHeadingFooter
  const [isLoading, setIsLoading] = useState(false)
  const templateData = useSelector(state => state.templateData)
  const { user } = useAuth()

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isResponseElements && !accountDataResponse && !isLoading) {
          setIsLoading(true)
          await fetchDataAndDispatch(
            () => accountDataApi(templateData, user?.userGroup),
            'ACCOUNT_DATA',
            dispatch,
            true
          )
        } else if (isResponseElements && accountDataResponse) {
          setIsLoading(false)
        } else {
          if (!value) {
            setIsLoading(false)
            const data = { ...accountData?.data, allUpdate: true }
            dispatch(upDatePageHeadingFooter(data))
          }
        }
      } catch (error) {
        console.error('Error fetching and dispatching data:', error)
      }
    }

    fetchData()
  }, [isResponseElements, accountDataResponse, dispatch, value])

  return (
    <div>
      <div className='footer_1' style={{ color: '#000' }}>
        {
          isLoading
            ? <span className='loading-text'>Loading...</span>
            : value?.textFooter || 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
        }
      </div>
    </div>
  )
}

export default PageFooter
