import { useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import { useSelector } from 'react-redux'
import { ErrorBoundary } from 'react-error-boundary'
import { useLocation, useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Button, Card, CardContent, Grid, Skeleton, styled, Tab, Tooltip } from '@mui/material'
import { CachedOutlined, VisibilityOutlined } from '@mui/icons-material'
import * as Sentry from '@sentry/react'
import { useAuth } from '../../../contexts/AuthContext'
import { moduleConfig } from '../../../contexts/data'
import { ACCESS_LEVEL } from '../../../contstants/constants'
import { useErrorToast } from '../../../hooks/useErrorToast'
import { useSuccessToast } from '../../../hooks/useSuccessToast'
import ErrorFallback from '../../ErrorFallback'
import Loader from '../../Loader'
import '../aggregate.scss'
import AccountInfo from '../components/AccountInfo'
import AggregateTitle from '../components/AggregateTitle'
import AllocationChart from '../components/AllocationChart'
import AssetAllocationTab from '../components/AssetAllocationTab'
import GroupCard from '../components/GroupCard'
import OptimizationDataModal from '../components/OptimizationDataModal'
import PortfolioTable from '../components/PortfolioTable'
import PortfolioUMA from '../components/PortfolioUMA'
import RestrictionGroupTable from '../components/RestrictionGroupTable'
import FactorTab from '../components/UMATabs/FactorTab'
import PerformanceTab from '../components/UMATabs/PerformanceTab'
import SummaryTab from '../components/UMATabs/SummaryTab'
import TaxlotTab from '../components/UMATabs/TaxlotTab'
import TradeList from '../components/UMATabs/TradeList'
import { allocationChartDataProcess, assetAllocationDataProcess, benchmarkDataProcess, portfolioDataProcess, sectorAllocationDataProcess, summaryTabDataProcess, taxlotDataProcess } from './aggregateDataProcessing'
import ExpandedGroupView from '../components/UMATabs/ExpandedGroupView'

const CustomTab = styled(Tab)(({ theme, selected }) => ({
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  textTransform: 'capitalize',
  fontSize: '16px',
  color: '#34475A',
  fontWeight: 400,
  '&.Mui-selected': {
    fontWeight: 400,
    color: '#34475A'
  }
}))

const AggregateDashboard = () => {
  const params = useParams()
  const { user, checkAccess } = useAuth()
  const location = useLocation()
  const navigate = useNavigate()
  const { showError } = useErrorToast()
  const { showLegacyView } = useSelector((state) => state.trade)
  const { showSuccess } = useSuccessToast()
  const [isLoading, setIsLoading] = useState(true)
  const [isTitleLoading, setIsTitleLoading] = useState(false)
  const [isGroupCardLoading, setIsGroupCardLoading] = useState(true)
  const [isGroupRestrictionLoading, setIsGroupRestrictionLoading] = useState(true)
  const [aggregateKPIs, setAggregateKPIs] = useState({})
  const [aggregateGroupData, setAggregateGroupData] = useState([])
  const [selectedGroup, setSelectedGroup] = useState({ aggGroupId: '', groupType: '', aggGroupCd: '', aggGroupName: '', umaType: '' })
  const [selectedGroupRestrictionData, setSelectedGroupRestrictionData] = useState([])
  const [tabList, setTabList] = useState([])
  const [aggGroupDetails, setAggGroupDetails] = useState({ allocationChart: [], portfolioData: [] })
  const [selectedTab, setSelectedTab] = useState('')
  const [assetAllocationData, setAssetAllocationData] = useState({})
  const [performanceData, setPerformanceData] = useState([])
  const [benchmarkData, setBenchmarkData] = useState([])
  const [marketValueData, setMarketValueData] = useState([])
  const [taxlotData, setTaxlotData] = useState({ taxlot: [], accountHierarchy: {}, rawData: [] })
  const [allocationData, setAllocationData] = useState({})
  const [originalUMASummary, setOriginalUMASummary] = useState([])
  const [factorAllocationLoading, setFactorAllocationLoading] = useState(false)
  const [sectorAllocationLoading, setSectorAllocationLoading] = useState(false)
  const [isSummaryLoading, setIsSummaryLoading] = useState(false)
  const [transitionUMASummary, setTransitionUMASummary] = useState({})
  const [isAllocationLoading, setIsAllocationLoading] = useState(false)
  const [tradeListData, setTradeListData] = useState([])
  const [isTradeListLoading, setIsTradeListLoading] = useState(false)
  const [isTopCardLoading, setIsTopCardLoading] = useState(false)
  const [isBenchmarkLoading, setIsBenchmarkLoading] = useState(false)
  const [isTaxlotLoading, setIsTaxlotLoading] = useState({ rawData: false, groupData: false })
  const [tradingUMAData, setTradingUMAData] = useState({ allocationChart: [], portfolioData: [] })
  const [showOptimizationPopup, setShowOptimizationPopup] = useState('')
  const [optimizationData, setOptimizationData] = useState([])
  const [isOptDataLoading, setIsOptDataLoading] = useState(false)
  const [isOptimizationLoading, setIsOptimizationLoading] = useState(false)
  const [expandSummaryChart, setExpandSummaryChart] = useState(false)

  const formatAggregateData = (aggData) => {
    const KPIObj = {
      'Total Market Value': { value: aggData?.totalMv },
      'Unrealized Gain/Loss': { value: aggData?.urgl },
      'Realized Gain/Loss': { value: aggData?.rgl },
      Cash: { value: aggData?.cash }
    }
    setAggregateKPIs(KPIObj)
  }

  const fetchPortfolioAccounts = (umaType) => {
    setIsLoading(true)
    setIsTopCardLoading(true)
    API.get(
      'baseUriAggregateMaster',
      `aggregate-master/v1/${user?.userGroup}/aggregate-portfolio/${params?.aggGroupId}?viewUntagged=true`
    )
      .then((response) => {
        if (response?.data?.accountData?.length) {
          setTradingUMAData({
            allocationChart: allocationChartDataProcess(response?.data?.accountData),
            portfolioData: portfolioDataProcess(response?.data?.accountData || [])
          })
        }
        formatAggregateData(response?.data?.agg)
      })
      .catch((error) => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => {
        setIsLoading(false)
        setIsTopCardLoading(false)
      })
  }

  const fetchGroupRestriction = () => {
    setIsGroupRestrictionLoading(true)
    API.get(
      'baseUriAggregateMaster',
      `aggregate-master/v1/${user.userGroup}/aggregate/group-restrictions/${params?.aggGroupId}`
    )
      .then((response) => {
        if (response?.data) {
          setSelectedGroupRestrictionData(response?.data)
        }
        setIsGroupRestrictionLoading(false)
      })
      .catch((error) => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
        setIsGroupRestrictionLoading(false)
      })
  }

  // UMA when type is transition/trading, SCENARIO_TRANSITION when type is same, else don't pass anything
  const fetchAggregateGrouping = (type) => {
    setIsGroupCardLoading(true)
    API.post(
      'baseUriAggregateMaster',
      `aggregate-master/v1/${user.userGroup}/account/aggregate-grouping`,
      {
        queryStringParameters: user?.userGroup === 'adv-classic'
          ? {
            resources: encodeURIComponent(JSON.stringify({
              serviceId: 'aggregate',
              resourceId: 'aggregate-dashboard'
            }))
          }
          : {},
        body: {
          aggGroupId: params?.aggGroupId,
          ...(type ? { type } : null)
        }
      }
    )
      .then((response) => {
        if (response?.data) {
          setAggregateGroupData(response?.data?.filter(obj => obj?.aggGroupId !== params?.aggGroupId))
        }
      })
      .catch((error) => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => {
        setIsGroupCardLoading(false)
      })
  }

  const fetchAggregateMarketValue = () => {
    setIsLoading(true)
    setIsTopCardLoading(true)
    API.get(
      'baseUriAggregateMaster',
      `aggregate-master/v1/${user?.userGroup}/aggregate-group-details/${params?.aggGroupId}`
    )
      .then((response) => {
        if (response?.data) {
          setAggGroupDetails(response.data)
          formatAggregateData({ totalMv: response?.data?.totalMarketValue, cash: response?.data?.totalCashValue, urgl: response?.data?.totalUnrealizedGainLoss })
        }
      })
      .catch((error) => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => {
        setIsLoading(false)
        setIsTopCardLoading(false)
      })
  }

  const fetchOriginalUMASummary = () => {
    setIsSummaryLoading(true)
    API.get(
      'baseUriAggregateMaster2',
      `aggregate-master/v1/${user?.userGroup}/dashboard/agg/summary/${params?.aggGroupId}`
    )
      .then(res => {
        if (res?.data?.length) {
          setOriginalUMASummary(res?.data?.map((row, index) => ({ ...row, id: index })))
        }
      })
      .catch(error => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => setIsSummaryLoading(false))
  }

  const fetchAggregateFamilyData = () => {
    return new Promise((resolve, reject) => {
      API.get(
        'baseUriAggregateMaster',
        `aggregate-master/v1/${user?.userGroup}/agg-group`,
        {
          queryStringParameters: {
            groupId: params?.aggGroupId,
            ...user?.userGroup === 'adv-classic'
              ? {
                resources: encodeURIComponent(JSON.stringify({
                  serviceId: 'aggregate',
                  resourceId: 'aggregate-dashboard'
                }))
              }
              : {}
          }
        }
      )
        .then((response) => {
          if (response?.data?.length) { resolve(response?.data[0]) } else { resolve(null) }
          setIsTitleLoading(false)
        })
        .catch((error) => {
          showError(error.response?.data?.errorInfo?.userMessage || error.message)
          Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
          setIsTitleLoading(false)
          reject(error)
        })
    })
  }

  const fetchTransitionUMASummary = () => {
    setIsSummaryLoading(true)
    API.get(
      'baseUriAggregateMaster2',
      `aggregate-master/v1/${user?.userGroup}/dashboard/agg/scenario/summary/${params?.aggGroupId}`
    )
      .then(res => {
        if (res?.data) {
          setTransitionUMASummary(summaryTabDataProcess(res?.data))
        }
      })
      .catch(error => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => setIsSummaryLoading(false))
  }

  const fetchTradeList = () => {
    setIsTradeListLoading(true)
    API.get(
      'baseUriAggregateMaster2',
      `aggregate-master/v1/${user?.userGroup}/uma/trade-list/${params?.aggGroupId}`
    )
      .then(res => {
        if (res?.data) {
          setTradeListData(res?.data?.map((row, index) => ({ ...row, id: index })))
        }
      })
      .catch(error => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => setIsTradeListLoading(false))
  }

  const fetchAllocationData = () => {
    setIsAllocationLoading(true)
    API.get(
      'baseUriAggregateMaster2',
      `aggregate-master/v1/${user?.userGroup}/agg-allocation-data/${params?.aggGroupId}`
    )
      .then(res => {
        if (res?.data) { setAllocationData(res?.data) }
      })
      .catch(error => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => setIsAllocationLoading(false))
  }

  const handleSelectedGroupIndexChange = (newGroup) => {
    const url = location.pathname.replace(params.aggGroupId, newGroup?.aggGroupId)
    navigate(url, { state: { ...newGroup } })
    window.scrollTo(0, 0)
  }

  const fetchFactorAllocationData = (isScenarioUMA) => {
    setFactorAllocationLoading(true)
    API.get(
      'baseUriAggregateMaster2',
      `aggregate-master/v1/${user?.userGroup}/dashboard/marketcap-factor/${params?.aggGroupId}`
    )
      .then(response => {
        if (response?.data) {
          setAssetAllocationData(prevState => ({ ...prevState, ...assetAllocationDataProcess(response, isScenarioUMA) }))
        }
      })
      .catch((error) => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => {
        setFactorAllocationLoading(false)
      })
  }

  const fetchSectorAllocationData = () => {
    setSectorAllocationLoading(true)
    API.get(
      'baseUriAggregateMaster2',
      `aggregate-master/v1/${user?.userGroup}/dashboard/sector/${params?.aggGroupId}`
    )
      .then(response => {
        if (response?.data) {
          setAssetAllocationData(prevState => ({ ...prevState, ...sectorAllocationDataProcess(response) }))
        }
      })
      .catch((error) => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => {
        setSectorAllocationLoading(false)
      })
  }

  const fetchTopCardsData = (isScenarioUMA) => {
    setIsTopCardLoading(true)
    API.get(
      'baseUriAggregateMaster2',
      `aggregate-master/v1/${user?.userGroup}/dashboard/agg/top-card/${params?.aggGroupId}`
    )
      .then(res => {
        const topCardsObj = res.data
        const KPIObj = {
          'Total Market Value': { value: topCardsObj?.totalMarketValue }
        }
        if (isScenarioUMA) {
          KPIObj['Tracking Error'] = { value: topCardsObj?.trackingError, type: 'Percentage', percentChange: topCardsObj?.changeInTrackingError }
          KPIObj['Realized Gain/Loss'] = { value: topCardsObj?.rgl, percentChange: topCardsObj?.changeInRgl }
          KPIObj['Tax Cost'] = { value: topCardsObj?.taxCost, percentChange: topCardsObj?.changeInTaxCost }
        } else {
          KPIObj['Tracking Error'] = { value: topCardsObj?.trackingError, type: 'Percentage' }
          KPIObj.Cash = { value: topCardsObj?.cashValue }
          KPIObj['Unrealized Gain/Loss'] = { value: topCardsObj?.ugl }
        }
        setAggregateKPIs(KPIObj)
      })
      .catch(error => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => {
        setIsTopCardLoading(false)
      })
  }

  const fetchBenchmarkData = () => {
    setIsBenchmarkLoading(true)
    API.get(
      'baseUriAggregateMaster2',
      `aggregate-master/v1/${user?.userGroup}/uma/benchmark-comparison/${params?.aggGroupId}`
    )
      .then((res) => {
        if (res.data?.length) {
          setBenchmarkData(benchmarkDataProcess(res?.data))
        }
      })
      .catch((error) => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => {
        setIsBenchmarkLoading(false)
      })
  }

  const fetchTaxlotData = (taxlot) => {
    setIsTaxlotLoading(prev => ({ ...prev, ...(taxlot ? { rawData: true } : { groupData: true }) }))
    API.get(
      'baseUriAggregateMaster2',
      `aggregate-master/v1/${user?.userGroup}/uma/taxlot/${params?.aggGroupId}`,
      taxlot
        ? {
          queryStringParameters: {
            taxlot
          }
        }
        : {}
    )
      .then((res) => {
        if (res.data?.length) {
          const taxlotObj = taxlotDataProcess(res?.data, taxlot)
          setTaxlotData(prev => ({ ...prev, ...(taxlot ? { accountHierarchy: taxlotObj?.accountHierarchy, rawData: taxlotObj?.rawData } : { taxlot: taxlotObj?.taxlotData }) }))
        }
      })
      .catch((error) => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => {
        setIsTaxlotLoading(prev => ({ ...prev, ...(taxlot ? { rawData: false } : { groupData: false }) }))
      })
  }

  const triggerOptimization = (generateFlag) => {
    if (generateFlag) {
      setIsOptDataLoading(true)
      setShowOptimizationPopup('rerun-btn')
    } else {
      setIsOptimizationLoading(true)
    }
    API.post(
      'baseUriAggregateMaster',
      `aggregate-master/v1/${user?.userGroup}/uma/opt/adhoc/${originalUMASummary[0]?.userRqstId}/${params?.aggGroupId}`, {
      queryStringParameters: {
        'generate-only': generateFlag ? true : false,
      },
      ...(!generateFlag ? {
        body: optimizationData
      } : {})
    }
    )
      .then((res) => {
        if (generateFlag) {
          if (res?.data?.length) {
            setOptimizationData(res?.data)
          } else {
            setOptimizationData([])
          }
        } else if (res?.success) {
          showSuccess(res?.message)
        }
      })
      .catch((error) => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => {
        if (generateFlag) {
          setIsOptDataLoading(false)
        } else {
          setShowOptimizationPopup('')
          setIsOptimizationLoading(false)
        }
      })
  }

  const fetchOptimizationData = () => {
    setIsOptDataLoading(true)
    API.get(
      'baseUriAggregateMaster2',
      `aggregate-master/v1/${user?.userGroup}/opt-request/${originalUMASummary[0]?.userRqstId}/${params?.aggGroupId}`
    )
      .then((res) => {
        if (res?.data?.length) {
          setOptimizationData(res?.data)
        } else {
          setOptimizationData([])
        }
      })
      .catch((error) => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => {
        setIsOptDataLoading(false)
      })
  }

  const fetchUMAData = (umaType) => {
    const isScenarioUMA = umaType === 'SCENARIO_TRANSITION'
    const isTrading = umaType === 'TRADING'
    setAggregateKPIs({
      'Total Market Value': null,
      ...(isTrading
        ? {
          'Realized Gain/Loss': null
        }
        : {
          'Tracking Error': null
        }),
      ...(isTrading || !isScenarioUMA
        ? {
          Cash: null,
          'Unrealized Gain/Loss': null
        }
        : isScenarioUMA
          ? {
            'Realized Gain/Loss': null,
            'Tax Cost': null
          }
          : {})
    })
    if (isTrading) {
      fetchPortfolioAccounts(umaType)
      fetchAggregateGrouping('UMA')
      return
    }
    setSelectedTab('Summary')
    fetchFactorAllocationData(isScenarioUMA)
    fetchSectorAllocationData()
    fetchAllocationData(isScenarioUMA)
    fetchTopCardsData(isScenarioUMA)
    if (isScenarioUMA) {
      fetchBenchmarkData()
      fetchTaxlotData(true)
      fetchTaxlotData()
      fetchTradeList()
      fetchTransitionUMASummary()
      fetchAggregateGrouping(umaType)
      setTabList((['Summary', 'Factors', 'Performance', 'Trade List', 'Allocation', 'Taxlot']))
    } else {
      fetchOriginalUMASummary()
      fetchAggregateGrouping('UMA')
      setTabList((['Summary', 'Factors', 'Allocation']))
    }
  }

  const fetchWashSaleData = () => {
    setSelectedTab('Asset Allocation')
    setTabList((['Asset Allocation', 'Restrictions']))
    fetchAggregateMarketValue()
    fetchGroupRestriction()
    fetchAggregateGrouping()
  }

  const fetchOtherGroupData = () => {
    fetchAggregateGrouping()
    fetchAggregateMarketValue()
  }

  useEffect(() => {
    if (user) {
      if (location.state) {
        setSelectedGroup({
          aggGroupId: params.aggGroupId,
          groupType: location.state?.groupType,
          aggGroupCd: location.state?.aggGroupCd,
          aggGroupName: location.state?.aggGroupName,
          groupLabel: location.state?.groupLabel,
          umaType: location?.state?.umaType || ''
        })
        if (location.state?.groupType === 'UMA') {
          fetchUMAData(location?.state?.umaType)
        } else if (location.state?.groupType === 'WASHSALE' || location.state?.groupType === 'SETTLEMENT') {
          fetchWashSaleData()
        } else {
          fetchOtherGroupData()
        }
      } else {
        setIsTitleLoading(true)
        setIsTopCardLoading(true)
        fetchAggregateFamilyData()
          .then((res) => {
            if (!res) {
              setIsLoading(false)
              setIsGroupCardLoading(false)
              setIsGroupRestrictionLoading(false)
              setIsTopCardLoading(false)
              return
            }
            setSelectedGroup({
              aggGroupId: res?.aggGroupId,
              aggGroupCd: res?.aggGroupCd,
              aggGroupName: res?.aggGroupName,
              groupLabel: res?.groupLabel,
              groupType: res?.type === 'UMA' ? res?.type : res?.groupType,
              umaType: res?.groupType || ''
            })
            if (res.type === 'UMA') {
              fetchUMAData(res?.groupType)
            } else if (res?.groupType === 'WASHSALE' || res?.groupType === 'SETTLEMENT') {
              fetchWashSaleData()
            } else {
              fetchOtherGroupData()
            }
          })
          .catch((error) => {
            setIsLoading(false)
            setIsGroupCardLoading(false)
            setIsGroupRestrictionLoading(false)
            setIsTopCardLoading(false)
            showError(error.response?.data?.errorInfo?.userMessage || error.message)
            Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
          })
      }
    }
  }, [location])

  const toggleSummaryChartExpandView = () => {
    setExpandSummaryChart(prevState => !prevState)
  }

  const renderTabPanel = (tab, index) => {
    switch (tab) {
      case 'Allocation':
        return <PortfolioTable data={allocationData} loading={isAllocationLoading} umaType={selectedGroup.umaType} />
      case 'Taxlot':
        return <TaxlotTab data={taxlotData} loading={isTaxlotLoading} />
      case 'Asset Allocation':
        return <AssetAllocationTab data={aggGroupDetails} loading={isLoading} />
      case 'Performance':
        return <PerformanceTab performance={performanceData} benchmark={benchmarkData} marketValue={marketValueData} umaType={selectedGroup?.umaType} loading={isBenchmarkLoading} />
      case 'Trade List':
        return <TradeList data={tradeListData || []} loading={isTradeListLoading} />
      case 'Restrictions':
        return <RestrictionGroupTable data={selectedGroupRestrictionData} loading={isGroupRestrictionLoading} />
      case 'Factors':
        return <FactorTab allocationData={assetAllocationData} factorAllocationLoading={factorAllocationLoading} sectorAllocationLoading={sectorAllocationLoading} />
      case 'Summary':
        return (
          <SummaryTab
            loading={isSummaryLoading}
            umaType={selectedGroup.umaType}
            setSelectedGroup={handleSelectedGroupIndexChange}
            toggleSummaryChartExpandView={toggleSummaryChartExpandView}
            {...(selectedGroup.umaType === 'TRANSITION' ? { originalUMASummary } : {})}
            {...(selectedGroup.umaType === 'SCENARIO_TRANSITION' ? { ...transitionUMASummary } : {})}
          />
        )
      default:
        return <></>
    }
  }

  const handleOptDetailClick = () => {
    setShowOptimizationPopup('view-json-btn')
    fetchOptimizationData()
  }

  return (
    expandSummaryChart
      ? <ExpandedGroupView data={transitionUMASummary.flowChart} toggleSummaryChartExpandView={toggleSummaryChartExpandView} />
      : <>
        {isOptimizationLoading ? <Loader /> : ''}
        <Box className='aggregate-page'>
          <Grid container>
            <Grid item xs={12} sm={9} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <AggregateTitle title={selectedGroup.groupType === 'UMA' ? 'UMA' : selectedGroup.groupLabel} name={selectedGroup.aggGroupName} number={selectedGroup.aggGroupCd} accountCodeShow loading={isTitleLoading} slug={params?.aggGroupId} chipText={selectedGroup.groupType === 'UMA' ? selectedGroup.umaType : ''} />
              {
                !isTitleLoading && !isSummaryLoading && originalUMASummary?.length && selectedGroup?.umaType === 'TRANSITION' ? (
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    {
                      checkAccess(moduleConfig.AGGREGATE, ACCESS_LEVEL.COMPONENT_ACCESS, {
                        subModuleName: moduleConfig.AGGREGATE_DASHBOARD, component_name: moduleConfig.RE_RUN_UMA_TRANSITION_OPTIMIZATION
                      }) ? (
                        <Tooltip title='Rerun Optimization'>
                          <Button
                            variant='outlined'
                            onClick={() => triggerOptimization(true)}
                            sx={{
                              border: '2px solid #dee2e6',
                              padding: 1,
                              minWidth: 'auto',
                              ':hover': {
                                background: 'transparent',
                                border: '2px solid #dee2e6'
                              },
                              marginRight: '8px',
                            }}
                          >
                            <CachedOutlined sx={{ width: 20, height: 20, color: '#34475A' }} />
                          </Button>
                        </Tooltip>
                      ) : ''
                    }
                    {
                      checkAccess(moduleConfig.AGGREGATE, ACCESS_LEVEL.COMPONENT_ACCESS, {
                        subModuleName: moduleConfig.AGGREGATE_DASHBOARD, component_name: moduleConfig.VIEW_UMA_TRANSITION_OPTIMIZATION_JSON
                      }) ? (
                        <Tooltip title='View Optimization JSON'>
                          <Button
                            variant='outlined'
                            onClick={handleOptDetailClick}
                            sx={{
                              border: '2px solid #dee2e6',
                              minWidth: 'auto',
                              ':hover': {
                                background: 'transparent',
                                border: '2px solid #dee2e6'
                              },
                              marginRight: '8px',
                              padding: 1
                            }}
                          >
                            <VisibilityOutlined sx={{ width: 20, height: 20, color: '#34475A' }} />
                          </Button>
                        </Tooltip>
                      ) : ''
                    }
                  </Box>
                ) : ''
              }
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={9}>
              <Grid container spacing={3}>
                <Grid item sm={12}>
                  <AccountInfo headers={Object.keys(aggregateKPIs)} values={Object.values(aggregateKPIs)} loading={isTopCardLoading} />
                </Grid>
                <Grid item sm={12}>
                  <Card>
                    {
                      isTitleLoading
                        ? (
                          <CardContent sx={{ height: 'calc(100vh - 48px - 15px - 20px - 72px - 10px - 92px - 24px)' }}>
                            <Skeleton variant='rectangular' height='100%' />
                          </CardContent>
                        )
                        : selectedGroup.groupType !== 'WASHSALE' && selectedGroup.groupType !== 'SETTLEMENT' && selectedGroup.groupType !== 'UMA' && selectedGroup.groupType !== ''
                          ? (
                            <CardContent sx={{ pt: '8px', pb: '16px !important' }}>
                              <Box sx={{ position: 'relative', height: 'calc(100vh - 48px - 15px - 20px - 72px - 5px - 92px - 24px - 5px - 8px - 16px)', overflowY: 'auto', scrollbarGutter: 'stable', overflowX: 'hidden' }}>
                                {renderTabPanel('Asset Allocation')}
                              </Box>
                            </CardContent>
                          )
                          : (
                            selectedGroup?.umaType === 'TRADING'
                              ? (
                                <CardContent>
                                  <AllocationChart data={tradingUMAData.allocationChart} loading={isLoading} />
                                  <PortfolioUMA data={tradingUMAData.portfolioData} loading={isLoading} />
                                </CardContent>
                              )
                              : (
                                <CardContent sx={{ pt: '8px', pb: '16px !important' }}>
                                  <TabContext value={selectedTab}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                      <TabList
                                        onChange={(_, value) => setSelectedTab(value)}
                                        TabIndicatorProps={{
                                          style: {
                                            backgroundColor: '#34475A'
                                          }
                                        }}
                                        indicatorColor='#34475A'
                                      >
                                        {tabList.map((tab, index) => (
                                          <CustomTab key={index} label={tab} value={tab} />
                                        ))}
                                      </TabList>
                                    </Box>
                                    {tabList.map((tab, index) => (
                                      <TabPanel key={index} value={tab} sx={{ px: 0, pt: '5px', pb: 0 }}>
                                        <ErrorBoundary fallbackRender={(props) => (<ErrorFallback {...props} screen='not main' />)}>
                                          <Box sx={{ position: 'relative', overflowY: 'auto', scrollbarGutter: 'stable', overflowX: 'hidden', scrollbarWidth: 'none' }}>
                                            {renderTabPanel(tab, index)}
                                          </Box>
                                        </ErrorBoundary>
                                      </TabPanel>
                                    ))}
                                  </TabContext>
                                </CardContent>
                              )
                          )
                    }
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Box sx={{ height: 'calc(100vh - 48px - 15px - 20px - 56px - 16px)', overflowY: 'auto', scrollbarGutter: 'stable', scrollbarWidth: 'none', pr: '1px', position: 'sticky', top: '63px' }}>
                {
                  isGroupCardLoading
                    ? Array.from({ length: 5 }).map((_, i) => (
                      <Grid item sm={12} key={i} mb={2}>
                        <Skeleton
                          variant='rounded'
                          sx={{ height: '70px' }}
                        />
                      </Grid>
                    ))
                    : aggregateGroupData?.map((data, index) => (
                      <Grid item sm={12} key={index} pt='1px'>
                        <GroupCard data={data} setSelectedGroup={handleSelectedGroupIndexChange} borderColor={data?.groupColor || '#6681B7'} />
                      </Grid>
                    ))
                }
              </Box>
            </Grid>
          </Grid>
        </Box>
        {
          showOptimizationPopup ? (
            <OptimizationDataModal
              loading={isOptDataLoading}
              jsonData={optimizationData}
              onClose={() => setShowOptimizationPopup('')}
              isOpen={Boolean(showOptimizationPopup)}
              triggerOptimization={triggerOptimization}
              showDownloadButton={showOptimizationPopup === 'rerun-btn'}
            />
          ) : ''
        }
      </>
  )
}

export default AggregateDashboard