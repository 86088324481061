import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { BiReset } from 'react-icons/bi'
import { Autocomplete, Box, MenuItem, TextField, Typography } from '@mui/material'
import { addChartValues, changeBackground, editLabelChart, onEditChart, resetBackground } from '../../../../store/pdf-customizer-reducer/Action/chartDropAction'
import { ARIS_COLORS } from '../../../../utils/pdf-customizer/common'
import { rgbaToHex } from '../../droppableElements/APIResponseConverts/APIResponseConverts'


const AggregateSidebarEdits = ({ activeChart }) => {
	const dispatch = useDispatch()
	const [selectedGroup, setSelectedGroup] = useState(null)
	const [availableGroups, setAvailableGroup] = useState([])

	useEffect(() => {
		if (activeChart?.value?.availableGroups)
			setAvailableGroup(activeChart?.value?.availableGroups)
		else
			setAvailableGroup([])
		if (activeChart?.value?.group)
			setSelectedGroup(activeChart?.value?.group)
		else
			setSelectedGroup(null)
	}, [activeChart])

	const handleBackground = (e, chartIndex) => {
		const newColor = e.target.value
		dispatch(changeBackground({ value: newColor, index: chartIndex }))
	}

	const bgReset = () => {
		dispatch(resetBackground({colorArray: ARIS_COLORS}))
	}

	useEffect(() => {
		if (activeChart?.value?.group)
			setSelectedGroup(activeChart?.value?.group)
		else
			setSelectedGroup(null)
	}, [activeChart])

	const handleInputChange = (value) => {
		setSelectedGroup(value)
		dispatch(addChartValues({ chartIndex: activeChart?.chartIndex, pageIndex: activeChart?.pageIndex, group: value }))
	}

	const toggleDatasetVisibility = (label, index, checked) => {
		dispatch(onEditChart({ label, index, check: checked }))
	}

	const renderCheckbox = (data, index) => (
		<div className='flexOrAlign margin-y-1' key={index}>
			<label className='cl-checkbox'>
				<input
					type='checkbox'
					name={data.name || ''}
					checked={!data.hidden}
					onChange={(e) => {
						toggleDatasetVisibility(data.name, index, e.target.checked)
					}}
				/>
				<div className='box-main'>
					<div />
				</div>
				<div className='checkbox-padding'>
					<span className='common-text-data'>{data.name}</span>
				</div>
			</label>
		</div>
	)

	const renderColorPicker = (data, index) => (
		<div className='flexOrAlignStartOrBetWeen width-100 margin-y-1' key={index}>
			<label className='cl-checkbox'>
				{(activeChart?.name !== 'Tax Management Summary Chart' && activeChart?.name !== 'Tax Management Comparison Block' && activeChart?.name !== 'Performance line chart' && activeChart.name !== 'Asset allocation chart') && (
					<>
						<input
							type='checkbox'
							name={data.label || ''}
							checked={!data.hidden || false}
							onChange={(e) =>
								dispatch(
									editLabelChart({
										label: data.label,
										index,
										checked: e.target.checked
									})
								)}
						/>
						<div className='box-main'>
							<div />
						</div>
					</>
				)}

				<div className='checkbox-padding' style={{ paddingLeft: activeChart?.name !== 'Tax Management Summary Chart' && activeChart?.name !== 'Tax Management Comparison Block' && activeChart?.name !== 'Performance line chart' && activeChart.name !== 'Asset allocation chart' ? '5px' : 0 }}>
					<span className='common-text-data'>{data.label}</span>
				</div>
			</label>
			<div>
				<input
					type='color'
					id='color-input'
					className='color-picker-input'
					value={rgbaToHex(data.backgroundColor)}
					onChange={(e) => handleBackground(e, index)}
				/>
			</div>
		</div>
	)

	return (
		<>
			<Box sx={{ mt: '10px' }}>
				<Typography sx={{ fontSize: '12px', color: '#34475A', fontWeight: '500' }}>Select Group</Typography>
				<Autocomplete
					filterSelectedOptions
					value={selectedGroup}
					onChange={(e, value) => handleInputChange(value)}
					options={availableGroups}
					getOptionLabel={option => option}
					isOptionEqualToValue={(option, value) => option === value}
					size='small'
					sx={{
						'& .MuiInputBase-input': {
							fontSize: '12px',
							textTransform: selectedGroup === 'uma' ? 'uppercase' : 'capitalize'
						}
					}}
					renderInput={(params) => {
						return <TextField {...params} />
					}}
					renderOption={(props, option) => (
						<MenuItem {...props} sx={{ textTransform: option === 'uma' ? 'uppercase' : 'capitalize', fontSize: 12, py: 1 }}>
							{option}
						</MenuItem>
					)}
				/>
			</Box>
			<Box sx={{ mt: '10px' }}>
				{
					Array.isArray(activeChart?.value?.labels)
					&&
					activeChart?.value?.labels?.map(renderCheckbox)
				}
				{
					activeChart && activeChart?.value && activeChart.value?.datasets && selectedGroup && (
						<>
							<div className='flexOrAlignOrBetWeen color_picker_spacing'>
								<label className='chart-label'>Change color</label>
								<div onClick={bgReset}>
									<BiReset color='#727474' size={20} />
								</div>
							</div>
							{
								Array.isArray(activeChart?.value?.datasets)
								&&
								activeChart?.value?.datasets.map(renderColorPicker)}
						</>
					)
				}
			</Box>
		</>
	)
}

export default AggregateSidebarEdits