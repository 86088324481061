import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'

const DataMetricCard = ({ processData }) => {
  return (
    <TableContainer>
      <Table
        size='small'
        sx={{
          border: '1px solid #B6B6B6',
          '& .MuiTableCell-root': {
            color: '#727474 !important',
            fontFamily: 'Open Sans'
          },
          '& .MuiTableCell-body': {
            color: '#34475A !important'
          }
        }}
      >
        <TableBody>
          {/* generate [key,value] pair from process object */}
          {(Object.entries(processData)).map((row, index) => {
            return (
              <TableRow key={index} sx={{ '& td': { border: '1px solid #B6B6B6' } }}>
                <TableCell>{row[0]}</TableCell>
                <TableCell>{row[1]}</TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default DataMetricCard
