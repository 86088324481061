import { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Box, List, Skeleton } from '@mui/material'
import 'rc-slider/assets/index.css'
import arisLogoA from '../assets/images/ArisLogoA.svg'
import NewArisLogoWhite from '../assets/images/arisNewLogoWhite.svg'
import { useAuth } from '../contexts/AuthContext'
import { useLogo } from '../contexts/SponsorLogoContext'
import { useMenuConfig } from '../hooks/useMenuConfig'
import useWindowDimensions from '../hooks/useWindowDimensions'
import NonSubmenuItems from './NonSubmenuItems'
import SubMenuItems from './SubMenuItems'
import { ACCESS_LEVEL } from '../contstants/constants'
import { useTourContext } from '../contexts/TourContext'

const LeftSidebar = ({
  menuNodeRef,
  activeMenuFromParent
}) => {
  const [activePath, setActivePath] = useState('')
  const location = useLocation()
  const { user, checkAccess } = useAuth()
  const { width } = useWindowDimensions()
  const { sponsorMainLogo, sponsorMobileLogo, backgroundColor } = useLogo()
  const [isMainLogoLoaded, setIsMainLogoLoaded] = useState(false)
  const [isMobileLogoLoaded, setIsMobileLogoLoaded] = useState(false)
  const [openSubmenuId, setOpenSubmenuId] = useState(null)
  const [isSidebarOpen, setIsSidebarOpen] = useState(true)
  const sidebarMenuList = useMenuConfig()
  const { userGroup } = useSelector((state) => state.user)
  const [userGrp, setUserGrp] = useState(user?.userGroup || userGroup)
  const { showLegacyView } = useSelector((state) => state.trade)
  const { startTour } = useTourContext()

  const handleSubmenuToggle = (event, id) => {
    if (!checkSubmenuToggle(event, id)) {
      if (openSubmenuId === id) {
        // user click on active submenu
        setOpenSubmenuId(null)
      } else {
        // user selects any icon other than active submenu
        setOpenSubmenuId(id)
      }
    } else {
      // if sidebar is closed and clicked on any icon
      setOpenSubmenuId(id)
    }
  }

  const handleOtherClick = useMemo(() => (e) => {
    // open sidebar on click of any part of sidemenu
    if (menuNodeRef && menuNodeRef.current && menuNodeRef.current.contains(e.target)) {
      sidebarMenuList.forEach(menuItem => {
        if (menuItem.hasSubMenu && menuItem.paths.some(path => window.location?.pathname?.includes(path.path))) {
          if (!document.getElementById('sidebar')?.classList.contains('open')) {
            if (e.target?.id === 'sidebar') {
              setOpenSubmenuId(menuItem.id)
            } else {
              if (e.target?.parentNode?.id?.endsWith('-icon')) {
                handleSubmenuToggle(e, e.target?.parentNode?.id)
              } else if (e.target?.id !== '') {
                handleSubmenuToggle(e, e.target?.id)
              } else {
                handleSubmenuToggle(e, menuItem.id)
              }
            }
          }
        }
      })
      document.getElementById('sidebar')?.classList?.add('open')
      setIsSidebarOpen(true)
    }
    // else hide the menubar
    else if (document.getElementById('sidebar')?.classList?.contains('open') && (!activeMenuFromParent || ((activeMenuFromParent === 'Personalization' || activeMenuFromParent === 'Report') && e.target.title === 'Next'))) {
      if (window.innerWidth < 1400 || showLegacyView) {
        setOpenSubmenuId(null)
        document.getElementById('sidebar')?.classList?.remove('open')
        setIsSidebarOpen(false)
      }
    }
  }, [showLegacyView, activeMenuFromParent])

  useEffect(() => {
    // on page refresh it will open sidebar
    if (!startTour) {
      if (!document.getElementById('sidebar')?.classList?.contains('open') && !showLegacyView) {
        document.getElementById('sidebar')?.classList?.add('open')
        setIsSidebarOpen(true)
      }
    }

    // bind mouse click events
    document.body.addEventListener('mousedown', handleOtherClick, false)

    return () => {
      document.body.removeEventListener('mousedown', handleOtherClick, false)
    }
  }, [handleOtherClick])

  useEffect(() => {
    // collapse left sidebar when showLegacyView is true
    if (showLegacyView && window.innerWidth > 1719) {
      if (document.getElementById('sidebar')?.classList?.contains('open')) {
        document.getElementById('sidebar')?.classList?.remove('open')
        setIsSidebarOpen(false)
        setOpenSubmenuId(null)
      }
    } else {
      // set active menu in left sidebar when showLegacyView is set to false and screen has normal view
      if (document.getElementById('sidebar')?.classList?.contains('open') && !showLegacyView) {
        sidebarMenuList.forEach(menuItem => {
          if (menuItem.hasSubMenu && menuItem.paths.some(path => window.location?.pathname?.includes(path.path))) {
            if (document.getElementById('sidebar')?.classList?.contains('open')) {
              setOpenSubmenuId(menuItem.id)
            }
          }
        })
      }
    }
  }, [showLegacyView])

  useEffect(() => {
    // open the submenu of selected item if sidebar is open and legacyview is off
    if (document.getElementById('sidebar')?.classList?.contains('open') && !showLegacyView) {
      sidebarMenuList.forEach(menuItem => {
        if (menuItem.hasSubMenu && menuItem.paths.some(path => window.location?.pathname?.includes(path.path))) {
          if (!document.getElementById('sidebar')?.classList?.contains('open')) {
            setOpenSubmenuId(menuItem.id)
          }
        }
      })
    }
  }, [])

  useEffect(() => {
    if (activeMenuFromParent) {
      document.getElementById('sidebar')?.classList?.add('open')
      setIsSidebarOpen(true)
    }
  }, [activeMenuFromParent])

  useEffect(() => {
    const autoMenuCloseHandler = () => {
      if (window.innerWidth < 768) {
        setOpenSubmenuId(null)
      } else if (window.innerWidth > 1400 && !showLegacyView) {
        // if resized when legacy view is on then we don't want to show sidebar opened every time
        sidebarMenuList.forEach(menuItem => {
          if (menuItem.hasSubMenu && menuItem.paths.some(path => window.location?.pathname?.includes(path.path))) {
            if (!document.getElementById('sidebar')?.classList?.contains('open')) {
              setOpenSubmenuId(menuItem.id)
              document.getElementById('sidebar')?.classList?.add('open')
              setIsSidebarOpen(true)
            }
          }
        })
      }
    }
    window.addEventListener('resize', autoMenuCloseHandler)
    return () => window.removeEventListener('resize', autoMenuCloseHandler)
  }, [showLegacyView])

  useEffect(() => {
    setActivePath(location.pathname)
  }, [location])

  useEffect(() => {
    sidebarMenuList.forEach(menuItem => {
      if (
        menuItem?.hasSubMenu &&
        menuItem?.paths?.some(path => activePath?.includes(path.path))
      ) {
        setTimeout(() => {
          if (document.getElementById('sidebar')?.classList?.contains('open')) {
            setOpenSubmenuId(menuItem.id)
          } else {
            setOpenSubmenuId(null)
          }
        }, 100)
      }
    })
  }, [activePath])

  useEffect(() => {
    if (user?.userGroup) {
      setUserGrp(user?.userGroup)
    }
  }, [user])

  const checkSubmenuToggle = (event, id) => {
    return ((width > 600 && width < 1400) || showLegacyView) && (event?.target?.nodeName === 'IMG' || (event.target?.id === id || event.target?.id === event.target?.id + '-icon') || (event.target?.parentNode?.id === 'sidebar')) && menuNodeRef?.current?.clientWidth < 240
  }

  return (
    <Box
      className='sidebar'
      sx={{
        backgroundColor: backgroundColor || '#002A59',
        overflowX: 'hidden'
      }}
      id='sidebar'
      ref={menuNodeRef}
    >
      <Box
        className='logo' sx={{
          width: '100%',
          backgroundColor: backgroundColor || '#002A59',
          position: 'sticky',
          top: 0,
          zIndex: 1
        }}
      >
        {userGrp
          ? (
            <>
              {sponsorMainLogo
                ? <img
                    src={sponsorMainLogo} alt='sponsor-logo' height={40} width={180} style={{ display: isMainLogoLoaded ? '' : 'none', margin: '16px 0 8px 0' }}
                    loading='eager'
                    onLoad={() => { setIsMainLogoLoaded(true) }}
                    ref={(img) => {
                      if (img && img?.complete) {
                        setIsMainLogoLoaded(true)
                      }
                    }}
                  />
                : null}
              {isMainLogoLoaded ? null : <Skeleton variant='rounded' sx={{ bgcolor: 'rgb(99,119,136)', margin: '16px 0px 8px 10px' }} height={40} width={180} />}
            </>
            )
          : (
            <img src={NewArisLogoWhite} alt='aris logo' height={40} width={180} style={{ margin: '16px 0 8px 0' }} loading='eager' />
            )}
      </Box>
      <Box
        className='logo-tablet'
        sx={{ backgroundColor: backgroundColor || '#002A59' }}
      >
        {userGrp
          ? (
            <>
              {sponsorMobileLogo
                ? <img
                    src={sponsorMobileLogo} alt='sponsor-logo' style={{ display: isMobileLogoLoaded ? '' : 'none' }} loading='eager'
                    onLoad={() => { setIsMobileLogoLoaded(true) }}
                    ref={(img) => {
                      if (img && img?.complete) {
                        setIsMobileLogoLoaded(true)
                      }
                    }}
                  />
                : null}
              {isMobileLogoLoaded ? null : <Skeleton variant='rounded' sx={{ bgcolor: 'rgb(99,119,136)' }} height={40} width={40} />}
            </>
            )
          : (
            <>
              <img src={arisLogoA} alt='aris-logo' loading='eager' />
            </>
            )}
      </Box>
      <List
        className='menu-list'
        sx={{
          '& .MuiListItemIcon-root': {
            minWidth: '30px',
            marginTop: '4px',
            marginBottom: '4px',
            lineHeight: '21px'
          }
        }}
        disablePadding
      >
        <>
          {sidebarMenuList?.map((menuItem, index) => (
            (user?.userGroup !== 'admin')
              ? (
                  checkAccess(menuItem.moduleCd, ACCESS_LEVEL.MODULE_ACCESS) && (
                    menuItem.hasSubMenu
                      ? (
                        <SubMenuItems
                          key={index}
                          id={menuItem.id}
                          menuId={menuItem.menuId}
                          paths={menuItem.paths}
                          activePath={activePath}
                          icon={menuItem.icon}
                          name={menuItem.name}
                          isOpen={openSubmenuId === menuItem.id}
                          handleSubmenuToggle={(event) => handleSubmenuToggle(event, menuItem.id)}
                          isSidebarOpen={isSidebarOpen}
                          parentModule={menuItem.moduleCd}
                        />
                        )
                      : (
                        <NonSubmenuItems
                          key={index}
                          activePath={activePath}
                          icon={menuItem.icon}
                          linkTo={menuItem.path}
                          label={menuItem.name}
                          id={menuItem.id}
                          isOpen={openSubmenuId === menuItem.id}
                          handleSubmenuToggle={(event) => handleSubmenuToggle(event, menuItem.id)}
                          isSidebarOpen={isSidebarOpen}
                        />
                        )
                  )
                )
              : (
                  menuItem?.roles?.includes(user?.userGroup) && (
                    menuItem?.hasSubMenu
                      ? (
                        <SubMenuItems
                          key={index}
                          id={menuItem.id}
                          menuId={menuItem.menuId}
                          paths={menuItem.paths}
                          activePath={activePath}
                          icon={menuItem.icon}
                          name={menuItem.name}
                          isOpen={openSubmenuId === menuItem.id}
                          handleSubmenuToggle={(event) => handleSubmenuToggle(event, menuItem.id)}
                          isSidebarOpen={isSidebarOpen}
                          parentModule={menuItem.moduleCd}
                        />
                        )
                      : (
                        <NonSubmenuItems
                          key={index}
                          activePath={activePath}
                          icon={menuItem.icon}
                          linkTo={menuItem.path}
                          label={menuItem.name}
                          id={menuItem.id}
                          isOpen={openSubmenuId === menuItem.id}
                          handleSubmenuToggle={(event) => handleSubmenuToggle(event, menuItem.id)}
                          isSidebarOpen={isSidebarOpen}
                        />
                        )
                  )
                )
          ))}

          {/* {(userGrp === 'pm' || userGrp === 'adv-classic') &&
              (
                <PolicyCheck
                  isSidebarOpen={isSidebarOpen}
                />
              )} */}
          {/* <SwitchUserRole
              isOpen={openSubmenuId === 'role-switch'}
              handleSubmenuToggle={(event) => handleSubmenuToggle(event, 'role-switch')}
              isSidebarOpen={isSidebarOpen}
            /> */}
          {/* {userGrp &&
              <SettingsMenuItem
                isOpen={openSubmenuId === 'settings-menu-item'}
                handleSubmenuToggle={(event) => handleSubmenuToggle(event, 'settings-menu-item')}
                isSidebarOpen={isSidebarOpen}
              />} */}
        </>
      </List>
    </Box>
  )
}

export default LeftSidebar
