import React, { useEffect, useState } from 'react'
import './diversificationStatistics.css'
import { useDispatch, useSelector } from 'react-redux'
import {
  decTwoPercentage,
  fetchDataAndDispatch
} from '../APIResponseConverts/APIResponseConverts'
import { diversificationDataApi } from '../../../../utils/pdf-customizer/_data'
import { diversificationStatistics } from '../APIResponseConverts/chartDefaultsData'
import { useAuth } from '../../../../contexts/AuthContext'

const DiversificationStatistics = ({
  data,
  chartIndex,
  index,
  chartHeight,
  chartWidth,
  clickEvent
}) => {
  const { diversificationResponse, isResponseElements } = useSelector(
    (state) => state.chartState
  )
  const templateData = useSelector(state => state.templateData)
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const { user } = useAuth()
  useEffect(() => {
    if (isResponseElements && !diversificationResponse && !isLoading) {
      setIsLoading(true)
      fetchDataAndDispatch(() => diversificationDataApi(templateData, user?.userGroup), 'DIVERSIFICATION_STATISTICS', dispatch)
    }
    if (isResponseElements && diversificationResponse) {
      setIsLoading(false)
    }
  }, [isResponseElements, diversificationResponse, dispatch])

  const itemsResponse = diversificationResponse?.data || diversificationStatistics?.data

  const valueShowPercentage = (value) => {
    if(!value)
      return 'NA'
    return (Number(value) * 100).toFixed(2)+'%'
  }

  const map = new Map()
  itemsResponse?.accountRiskAttributes.map(item => {
    map.set(item.statsCode, item.statsValue)
  })

  const totalPortfolioRisk = valueShowPercentage(map.get('PORT_RISK_STD'))
  const relativeVolatility = valueShowPercentage(map.get('PORT_REL_VOL'))
  const trackingError = valueShowPercentage(map.get('PORT_TRACKING_ERROR'))
  const activeWeight = valueShowPercentage(map.get('AA_DRIFT'))
  const targetPortfolioRisk = valueShowPercentage(itemsResponse?.strategyRiskAttributes.find(obj => obj.statsCode === 'PORT_RISK_STD')?.statsValue) || 'NA'

  return (
    <div
      style={{
        height: '100%',
      }}
    >
      {isLoading
      ? <span className='loading-text'>Loading...</span>
      : <div className='showInRow around diversification-statistics'>
          <div>
            <p className='common-text-header'>{`${totalPortfolioRisk}`}</p>
            <p className='common-text-paragraph'>Total Portfolio Risk</p>
          </div>
          <div>
            <p className='common-text-header'>
              {`${targetPortfolioRisk}`}
            </p>
            <p className='common-text-paragraph'>Target Portfolio Risk</p>
          </div>
          <div>
            <p className='common-text-header'>
              {`${activeWeight}`}
            </p>
            <p className='common-text-paragraph'>Active Weight</p>
          </div>
          <div>
            <p className='common-text-header'>{`${trackingError}`}</p>
            <p className='common-text-paragraph'>Tracking Error</p>
          </div>
        <div>
          <p className='common-text-header'>{`${relativeVolatility}`}</p>
          <p className='common-text-paragraph'>Relative Volatility</p>
        </div>
        </div>
      }
    </div>
  )
}

export default DiversificationStatistics
