import dayjs from 'dayjs'
import { Alert } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

const LivePriceAlert = ({ dateTime }) => {
  return (
    dateTime ? (
      <Alert
        severity='info'
        icon={<InfoOutlinedIcon sx={{ height: '18px', width: '18px' }} />}
        sx={{
          alignItems: 'center',
          padding: '0px 5px',
          '.MuiAlert-icon': {
            marginRight: 0
          },
          '.MuiAlert-message': {
            padding: '5px'
          }
        }}
      >
        Live prices are 15 minutes delayed of {dayjs(dateTime).format('YYYY-MM-DD HH:mm:ss')}.
      </Alert>
    ) : ''
  )
}

export default LivePriceAlert