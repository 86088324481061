import { getUserRole } from '../../../utils/getUserRole'
import { DeleteAPI } from '../components/Actions'

export const DeleteTradeData = async (deleteRow, tabIndex) => {
  switch (tabIndex) {
    case 'trade-status-master':
      return await DeleteTradeStatusMaster(deleteRow, tabIndex)
    default:
      return {}
  }
}

export const DeleteTradeStatusMaster = async (deleteRow) => {
  const userRole = getUserRole()
  return DeleteAPI('tradeMaintainURL',
    `data-maintenance/v1/${userRole}/trading/status-master/${deleteRow.tradeStatusId}`)
}
