import {
  COLOR_CHANGE,
  FONT_CHANGE,
  PAGE_CHANGE,
  CHANGE_MODE,
  RE_SET_DATA,
  IS_LOADING,
  PROGRESS_SET,
  PADDING_CHANGE,
  FETCH_CUSTOM_TABLE_METADATA,
  CHANGE_CURRENT_DRAGGABLE_ELEMENT
} from '../Action/allPageAction.types'

const initialState = {
  color: '#47546a',
  fontFamily: 'Open Sans',
  padding: 14.4,
  pageSize: { width: 457 + 8 + 20, height: 647 - 45 + 20, orientation: 'p' },
  userSelectMode: 'create',
  progress: 0,
  isLoading: false,
  currentDraggable: null,
  customTableMetadata: []
}

const allPageStyle = (state = initialState, action) => {
  switch (action.type) {
    case COLOR_CHANGE:
      return { ...state, color: action.payload }
    case PADDING_CHANGE:
      return { ...state, padding: action.payload }
    case FONT_CHANGE:
      return { ...state, fontFamily: action.payload }
    case PAGE_CHANGE:
      return { ...state, pageSize: action.payload }
    case CHANGE_MODE:
      return { ...state, userSelectMode: action.payload }
    case RE_SET_DATA:
      return reSetData(state)
    case PROGRESS_SET:
      return { ...state, progress: action.payload }
    case IS_LOADING:
      return handleLoading(state, action)
    case CHANGE_CURRENT_DRAGGABLE_ELEMENT:
      return { ...state, currentDraggable: action.payload }
    case FETCH_CUSTOM_TABLE_METADATA:
      return { ...state, customTableMetadata: action.payload }
    default:
      return state
  }
}

const handleLoading = (state, action) => {
  return { ...state, isLoading: action.payload }
}

const reSetData = (state) => {
  return {
    customTableMetadata: state.customTableMetadata,
    color: '#47546a',
    fontFamily: 'Open Sans',
    progress: 0,
    pageSize: { width: 457 + 8 + 20, height: 647 - 45 + 20, orientation: 'p' },
    userSelectMode: 'create',
    padding: 14.4,
    currentDraggable: null
  }
}
export default allPageStyle
