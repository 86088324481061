import React, { useRef, useState } from 'react'
import { Grid, Box, Typography, Button, Card, CardContent, IconButton, LinearProgress } from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'
import { LoadingButton } from '@mui/lab'
import * as Sentry from '@sentry/react'
import filesize from 'filesize'
import UploadCloudIcon from '../../../../assets/images/UploadCloudIcon.svg'
import PdfFile from '../../../../assets/images/PdfFile.svg'
import Cross from '../../../../assets/images/Cross.svg'
import { useErrorToast } from '../../../../hooks/useErrorToast'
import { useSuccessToast } from '../../../../hooks/useSuccessToast'

export const UploadModal = ({ data, requestId, updateData }) => {
  const { showError } = useErrorToast()
  const { showSuccess } = useSuccessToast()
  const fileInputRef = useRef(null)
  const [selectedFile, setSelectedFile] = useState(null)
  const [isFileUploading, setIsFileUploading] = useState(false)

  const handleUploadClick = () => {
    fileInputRef.current.click()
  }

  const handleDragEnter = (event) => {
    event.preventDefault()
  }

  const handleDragOver = (event) => {
    event.preventDefault()
  }

  const handleDragLeave = (event) => {
    event.preventDefault()
  }

  const handleDrop = (event) => {
    event.preventDefault()
    const file = event.dataTransfer.files[0]
    handleFileChange(file)
  }

  const handleCancel = () => {
    setSelectedFile(null)
    setUploadProgress(0)
    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }
  }

  const [uploadProgress, setUploadProgress] = useState(0)

  const handleFileChange = (file) => {
    if (!isFileUploading) {
      const maxSize = 5 * 1024 * 1024
      if (file && file.size <= maxSize) {
        setSelectedFile(file)
        const totalSize = file.size
        let uploadedSize = 0

        const interval = setInterval(() => {
          if (uploadedSize < totalSize) {
            uploadedSize += Math.floor(totalSize * 0.1)
            const progress = Math.floor((uploadedSize / totalSize) * 100)
            setUploadProgress(progress)
          } else {
            clearInterval(interval)
            setUploadProgress(100)
          }
        }, 500)
      } else {
        showError('Please select a file smaller than 5MB.')
      }
    }
  }

  const uploadFileToUrl = (data) => {
    setIsFileUploading(true)
    const formdata = new FormData()
    formdata.append('bucket', data?.preSignedURL?.fields?.bucket)
    formdata.append('key', data?.preSignedURL?.fields.key)
    formdata.append('Content-Type', data?.preSignedURL?.fields ? data?.preSignedURL?.fields['Content-Type'] : '')
    formdata.append('Policy', data?.preSignedURL?.fields?.Policy)
    formdata.append('X-Amz-Algorithm', data?.preSignedURL?.fields ? data?.preSignedURL?.fields['X-Amz-Algorithm'] : '')
    formdata.append(
      'X-Amz-Credential',
      data?.preSignedURL?.fields ? data?.preSignedURL?.fields['X-Amz-Credential'] : ''
    )
    formdata.append('X-Amz-Date', data?.preSignedURL?.fields ? data?.preSignedURL?.fields['X-Amz-Date'] : '')
    formdata.append(
      'X-Amz-Security-Token',
      data?.preSignedURL?.fields ? data?.preSignedURL?.fields['X-Amz-Security-Token'] : ''
    )
    formdata.append('X-Amz-Signature', data?.preSignedURL?.fields ? data?.preSignedURL?.fields['X-Amz-Signature'] : '')
    formdata.append('tagging', data?.preSignedURL?.fields?.tagging)
    formdata.append('file', selectedFile)

    fetch(data?.preSignedURL?.url, {
      method: 'POST',
      body: formdata
    })
      .then((response) => {
        if (response.ok) {
          showSuccess('Successfully Uploaded the document')
          updateData(requestId)
        }
      })
      .catch((error) => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
  }

  return (
    <>
      <Box>
        <Grid container>
          <Grid xs={12}>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} align='center'>
                  <Box py={1} sx={{ display: 'flex', cursor: 'pointer' }} justifyContent='center' alignItems='center'>
                    <Grid item xs={12} sm={12}>
                      <Typography style={{ padding: '5px 0px 0 10px', fontFamily: 'Lora', fontSize: '21px', fontWeight: 400, color: '#002A59' }}>
                        UPLOAD FILE
                      </Typography>
                    </Grid>
                  </Box>
                  <Grid item xs={12} sm={12} pb={1}>
                    <Typography>
                      Upload documents for {requestId}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item xs={12}
                  onDragEnter={handleDragEnter}
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                  onDrop={handleDrop}
                >
                  <Box
                    sx={{ position: 'relative', height: '182px', background: 'linear-gradient(189deg, rgba(0, 26, 89, 0.26) 0%, rgba(0, 42, 89, 0.00) 100%)' }} align='center' p={1}
                  >
                    <img src={UploadCloudIcon} alt='upload icon' />
                    <Typography sx={{
                      color: '#002A59',
                      textAlign: 'center',
                      fontFamily: 'Open Sans',
                      fontSize: '16px',
                      fontWeight: 400
                    }}
                    >Drag & drop your file here
                    </Typography>
                    <Typography
                      sx={{
                        color: '#002A59',
                        textAlign: 'center',
                        fontFamily: 'Open Sans',
                        fontSize: '16px',
                        fontWeight: 400
                      }} pb={2}
                    >Or
                    </Typography>
                    <Button variant='contained' size='small' sx={{ textTransform: 'none', backgroundColor: '#3369A6' }} onClick={handleUploadClick}>Browse Files</Button>
                    <input
                      ref={fileInputRef}
                      type='file'
                      sx={{ position: 'absolute', cursor: 'pointer' }}
                      onClick={(event) => event.stopPropagation()}
                      onChange={(event) => handleFileChange(event.target.files[0])}
                      accept='.pdf'
                      style={{ display: 'none' }}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid item md={12} mt={2}>
                {selectedFile && (
                  <>
                    <Typography sx={{ color: '#002A59', fontFamily: 'Open sans', fontSize: '18px', fontWeight: 500 }} py={2}>Uploaded File</Typography>
                    <Card className='card-layout'>
                      <CardContent sx={{ padding: '8px', paddingBottom: '8px !important' }}>
                        <Box className='uploaded-file'>
                          <Grid container alignItems='center'>
                            <Grid item xs={2}>
                              <IconButton color='primary' mr={2}>
                                <img src={PdfFile} />
                              </IconButton>
                            </Grid>
                            <Grid item xs={10} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                              <Box sx={{ width: '100%' }}>
                                <Typography sx={{ color: '#002A59', fontFamily: 'Open sans', fontSize: '14px', fontWeight: 500 }}>{selectedFile.name}</Typography>
                                {uploadProgress > 0 && uploadProgress < 100 && (
                                  <Box sx={{ width: '80%' }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                                      <Typography variant='caption'>{uploadProgress}%</Typography>
                                    </Box>
                                    <LinearProgress variant='determinate' value={uploadProgress} />
                                  </Box>
                                )}
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <Typography sx={{ color: '#74788D', fontFamily: 'Open sans', fontSize: '12px', fontWeight: 400 }}>
                                    last modified: {selectedFile.lastModifiedDate ? selectedFile?.lastModifiedDate?.toDateString() : ''}
                                  </Typography>
                                  {uploadProgress > 0 && uploadProgress < 100 && (
                                    <Typography
                                      sx={{
                                        color: '#74788D',
                                        fontSize: '12px',
                                        fontWeight: 400
                                      }}
                                      pr={5}
                                    >{filesize(selectedFile.size)}
                                    </Typography>)}
                                </Box>
                              </Box>
                              <img src={Cross} onClick={handleCancel} style={{ cursor: 'pointer' }} />
                            </Grid>
                          </Grid>
                        </Box>
                      </CardContent>
                    </Card>
                    {uploadProgress === 100
                      ? <Box textAlign='right' mt={2}>
                        {isFileUploading
                          ? <LoadingButton
                              loading
                              size='small'
                              loadingPosition='start'
                              startIcon={<SaveIcon />}
                              variant='outlined'
                              sx={{ mx: '10px' }}
                            >
                            Upload
                            </LoadingButton>
                          : <Button variant='contained' sx={{ textTransform: 'capitalize', backgroundColor: '#3369A6' }} onClick={() => uploadFileToUrl(data)}>Upload</Button>}
                      </Box>
                      : ''}
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
