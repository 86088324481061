import React, { useRef, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import CancelIcon from '@mui/icons-material/Close'
import { Button, DialogContent, DialogTitle, TextField, Grid, IconButton, Typography, Box } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import UploadCloudIcon from '../../assets/images//UploadCloudIcon.svg'
import { useErrorToast } from '../../hooks/useErrorToast'
import './acl.css'

const CreateSponsorInputFrom = ({ savedData, type, createApiLoading, setCreateApiLoading, prevOpen, setPrevOpen, setFormikData, handleCancelClose }) => {
  const { showError } = useErrorToast()
  const logoInputRefLightInMode = useRef(null)
  const logoInputRefDarkInMode = useRef(null)
  const logoInputRefLightModeMobile = useRef(null)
  const logoInputRefDarkModeMobile = useRef(null)
  const logoInputRefFaviConLightMode = useRef(null)
  const [btntype, setBtnType] = useState('')
  // const [prevOpen, setPrevOpen] = useState(false)

  const createFromValidationSchema = Yup.object().shape({
    sponsorName: Yup.string()
      .min(1, 'Sponsor Name must contain at least one character')
      .max(50, 'Sponsor Name is Too Long!')
      .required('Sponsor Name Required'),
    sponsorCd: Yup.string()
      .matches(/^[A-Z0-9]*$/, 'Invalid sponsor code. Only capital letters and numbers are allowed.')
      .min(1, 'Sponsor Code must contain at least one character')
      .max(50, 'Sponsor Code is Too Long!')
      .required('Sponsor Code Required'),
    opacity: Yup.number()
      .min(0.2, 'Opacity must be greater then 0.2')
      .max(0.8, 'Opacity must be between 0.2 and 0.8')
      .test(
        'is-less-than-opacity',
        'Opacity Limit 0.8',
        function (value) {
          return value === undefined || value === null || value <= 0.8
        }
      )
      .notRequired()
      .nullable()
  })

  const initialFormValues = {
    sponsorName: '',
    sponsorCd: '',
    primaryColor: '#002A59',
    secondaryColor: '#E6ECF3',
    brandTextColor: '#ffffff',
    mainLightLogo: '',
    mainLightLogoUrl: '',
    mainDarkLogo: '',
    mainDarkLogoUrl: '',
    mobLightLogo: '',
    mobLightLogoUrl: '',
    mobDarkLogo: '',
    mobDarkLogoUrl: '',
    favIcon: '',
    favIconUrl: '',
    opacity: 0.5
  }

  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema: btntype === 'Create' ? createFromValidationSchema : false,
    validate: values => {
      const errors = {}
      if (values.opacity < 0.2 || values.opacity > 0.8) {
        errors.opacity = 'Value must be between 0.2 and 0.8'
      } else if (!/^\d+(\.\d{1,2})?$/.test(values.opacity)) {
        errors.opacity = 'Value must have at most two decimal places'
      }
      return errors
    },
    onSubmit: (values) => {
      if (btntype === 'Create') {
        setCreateApiLoading(true)
        savedData(values)
      } else {
        setFormikData(values)
        setPrevOpen(true)
      }
    },
    validateOnBlur: true,
    validateOnChange: true
  })

  const handleLogoChange = (type, logo) => {
    if (!logo) return
    if (!logo.type.startsWith('image/')) {
      showError('Please upload an image file.')
      return
    }

    // if (logo.size > 2 * 1024 * 1024) {
    //   showError('Image size exceeds the limit of 2MB.')
    //   return
    // }

    // const maxWidth = type === 'mainLightLogo' || type === 'mainDarkLogo' ? 250 : type === 'favIcon' ? 25 : 100
    // const maxHeight = type === 'mainLightLogo' || type === 'mainDarkLogo' ? 250 : type === 'favIcon' ? 25 : 100

    const reader = new window.FileReader()
    reader.onload = () => {
      const img = new window.Image()
      img.onload = () => {
        // if (img.width > maxWidth || img.height > maxHeight) {
        //   img.src = ''
        //   clearImageData(type)
        //   showError(`Image dimensions exceed the limit of width ${maxWidth}px x height ${maxHeight}px.`)
        //   return
        // }
        // Set the field value and URL only if image dimensions are within limits
        switch (type) {
          case 'mainLightLogo':
            formik.setFieldValue('mainLightLogo', logo)
            formik.setFieldValue('mainLightLogoUrl', URL.createObjectURL(logo))
            break
          case 'mainDarkLogo':
            formik.setFieldValue('mainDarkLogo', logo)
            formik.setFieldValue('mainDarkLogoUrl', URL.createObjectURL(logo))
            break
          case 'mobLightLogo':
            formik.setFieldValue('mobLightLogo', logo)
            formik.setFieldValue('mobLightLogoUrl', URL.createObjectURL(logo))
            break
          case 'mobDarkLogo':
            formik.setFieldValue('mobDarkLogo', logo)
            formik.setFieldValue('mobDarkLogoUrl', URL.createObjectURL(logo))
            break
          case 'favIcon':
            formik.setFieldValue('favIcon', logo)
            formik.setFieldValue('favIconUrl', URL.createObjectURL(logo))
            break
          default:
            break
        }
      }
      img.src = URL.createObjectURL(logo)
    }
    reader.readAsDataURL(logo)
  }

  const handleCancel = (e, type) => {
    // e.stopPropagation()
    clearImageData(type)
  }

  const clearImageData = (type) => {
    switch (type) {
      case 'mainLightLogo': {
        formik.setFieldValue('mainLightLogoUrl', null)
        if (logoInputRefLightInMode.current) {
          logoInputRefLightInMode.current.value = ''
        }
        break
      }
      case 'mainDarkLogo': {
        formik.setFieldValue('mainDarkLogoUrl', null)
        if (logoInputRefDarkInMode.current) {
          logoInputRefDarkInMode.current.value = ''
        }
        break
      }
      case 'mobLightLogo': {
        formik.setFieldValue('mobLightLogoUrl', null)
        if (logoInputRefLightModeMobile.current) {
          logoInputRefLightModeMobile.current.value = ''
        }
        break
      }
      case 'mobDarkLogo': {
        formik.setFieldValue('mobDarkLogoUrl', null)
        if (logoInputRefDarkModeMobile.current) {
          logoInputRefDarkModeMobile.current.value = ''
        }
        break
      }
      case 'favIcon' : {
        formik.setFieldValue('favIconUrl', null)
        if (logoInputRefFaviConLightMode.current) {
          logoInputRefFaviConLightMode.current.value = ''
        }
        break
      }
      default:
        break
    }
  }

  // const ShowPreview = () => {
  //   return (
  //     <Modal open={prevOpen}>
  //       {/* <LeftSidebar leftSidebarProps={formik?.values} /> */}
  //       <IconButton
  //         sx={{ position: 'absolute', right: '0px', top: '0px' }}
  //         color='inherit'
  //         onClick={() => setPrevOpen(false)}
  //         aria-label='close'
  //       >
  //         <CancelIcon />
  //       </IconButton>
  //     </Modal>
  //   )
  // }

  return (
    <>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', paddingBottom: 0 }}>
        <Typography
          variant='h6'
          textAlign='center'
          color="#34475A"
          fontWeight={400}
        >Add {type} Data
        </Typography>
        <IconButton onClick={handleCancelClose}>
          <CancelIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <form>
            <Box sx={{ display: 'flex', gap: '1rem' }}>
              <TextField
                margin='dense'
                id='sponsorName'
                name='sponsorName'
                label='Sponsor Name'
                type='text'
                error={
                  Boolean(formik.errors.sponsorName && formik.touched.sponsorName)
                }
                helperText={
                  formik.errors.sponsorName &&
                  formik.touched.sponsorName &&
                  String(formik.errors.sponsorName)
                }
                fullWidth
                variant='standard'
                onChange={formik.handleChange}
                value={formik?.values?.sponsorName}
              />
              <TextField
                margin='dense'
                id='sponsorCd'
                name='sponsorCd'
                label='Sponsor Code'
                type='text'
                fullWidth
                error={
                  Boolean(formik.errors.sponsorCd && formik.touched.sponsorCd)
                }
                helperText={
                  formik.errors.sponsorCd &&
                  formik.touched.sponsorCd &&
                  String(formik.errors.sponsorCd)
                }
                variant='standard'
                onChange={formik.handleChange}
                value={formik?.values?.sponsorCd}
              />
            </Box>
            <Box sx={{ display: 'flex', marginTop: '2rem' }}>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={6}>
                  <Typography sx={{ color: '#74788d', fontSize: '14px', fontWeight: 400 }} mb={1}>Logo Image in Light Mode</Typography>
                  <Box
                    onDragEnter={(event) => event.preventDefault()}
                    onDragOver={(event) => event.preventDefault()}
                    onDragLeave={(event) => event.preventDefault()}
                    onDrop={(event) => {
                      event.preventDefault()
                      handleLogoChange('mainLightLogo', event.dataTransfer.files[0])
                    }}
                    onClick={() => {
                      logoInputRefLightInMode.current.click()
                    }}
                    sx={{ border: formik.errors.mainLightLogo ? '1px solid #d32f2f' : '1px solid #91b5d8', borderRadius: '6px', position: 'relative', height: '130px', background: '#f3f4f8' }} align='center' p={1}
                  >
                    {formik?.values?.mainLightLogoUrl
                      ? (
                        <>
                          <img src={formik?.values?.mainLightLogoUrl} alt='selected logo' style={{ maxHeight: '100%', maxWidth: '100%' }} />
                          <Box
                            onClick={(e) => {
                              e.stopPropagation()
                            }} sx={{ position: 'absolute', borderBottomLeftRadius: '6px', borderBottomRightRadius: '6px', bottom: 0, left: 0, width: '100%', zIndex: 1, background: '#ffffff', padding: '5px 6px 3px 32px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '30px' }}
                          >
                            <Typography variant='body2' sx={{ color: '#002A59' }}>{formik?.values?.mainLightLogo.name}</Typography>
                            <IconButton>
                              <CancelIcon onClick={(e) => handleCancel(e, 'mainLightLogo')} />
                            </IconButton>
                          </Box>
                        </>
                        )
                      : (
                        <Box
                          sx={{
                            position: 'absolute',
                            top: '28px',
                            left: '55px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                          align='center'
                        >
                          <img src={UploadCloudIcon} alt='upload icon' width='40' height='40' />
                          <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: '10px' }}>
                            <Typography
                              sx={{
                                color: '#002A59',
                                fontFamily: 'Open Sans',
                                fontSize: '16px',
                                fontWeight: 400,
                                marginTop: '10px'
                              }}
                            >
                              Drag & drop Or click to upload
                            </Typography>
                            <Typography
                              sx={{
                                color: '#002A59',
                                fontFamily: 'Open Sans',
                                fontSize: '12px',
                                marginRight: '10px',
                                fontWeight: 400,
                                marginTop: '5px'
                              }}
                            >
                              you may upload png, jpeg, jpg and svg
                            </Typography>
                          </Box>
                        </Box>

                        )}
                    <input
                      ref={logoInputRefLightInMode}
                      type='file'
                      sx={{ position: 'absolute', cursor: 'pointer' }}
                      onClick={(event) => event.stopPropagation()}
                      required
                      onChange={(event) => {
                        handleLogoChange('mainLightLogo', event.target.files[0])
                      }}
                      accept='image/*'
                      style={{ display: 'none' }}
                    />
                  </Box>
                  {/* <div>{(formik.errors.mainLightLogo) ? <p style={{ color: '#d32f2f', fontSize: '0.75rem' }}>{formik.errors.mainLightLogo}</p> : null}</div> */}
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ color: '#74788d', fontSize: '14px', fontWeight: 400 }} mb={1}>Logo Image in Dark Mode</Typography>
                  <Box
                    onDragEnter={(event) => event.preventDefault()}
                    onDragOver={(event) => event.preventDefault()}
                    onDragLeave={(event) => event.preventDefault()}
                    onDrop={(event) => {
                      event.preventDefault()
                      handleLogoChange('mainDarkLogo', event.dataTransfer.files[0])
                    }}
                    onClick={() => {
                      logoInputRefDarkInMode.current.click()
                    }}
                    sx={{ border: formik.errors.mainDarkLogo ? '1px solid #d32f2f' : '1px solid #91b5d8', borderRadius: '6px', position: 'relative', height: '130px', background: '#f3f4f8' }} align='center' p={1}
                  >
                    {formik?.values?.mainDarkLogoUrl
                      ? (
                        <>
                          <img src={formik?.values?.mainDarkLogoUrl} alt='selected logo' style={{ maxHeight: '100%', maxWidth: '100%' }} />
                          <Box
                            onClick={(e) => {
                              e.stopPropagation()
                            }} sx={{ position: 'absolute', bottom: 0, left: 0, borderBottomLeftRadius: '6px', borderBottomRightRadius: '6px', width: '100%', zIndex: 1, background: '#ffffff', padding: '5px 6px 3px 32px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '30px' }}
                          >
                            <Typography variant='body2' sx={{ color: '#002A59' }}>{formik?.values?.mainDarkLogo.name}</Typography>
                            <IconButton>
                              <CancelIcon onClick={(e) => handleCancel(e, 'mainDarkLogo')} />
                            </IconButton>
                          </Box>
                        </>
                        )
                      : (
                        <Box
                          sx={{
                            position: 'absolute',
                            top: '28px',
                            left: '55px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                          align='center'
                        >
                          <img src={UploadCloudIcon} alt='upload icon' width='40' height='40' />
                          <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: '10px' }}>
                            <Typography
                              sx={{
                                color: '#002A59',
                                fontFamily: 'Open Sans',
                                fontSize: '16px',
                                fontWeight: 400,
                                marginTop: '10px'
                              }}
                            >
                              Drag & drop Or click to upload
                            </Typography>
                            <Typography
                              sx={{
                                color: '#002A59',
                                fontFamily: 'Open Sans',
                                fontSize: '12px',
                                marginRight: '10px',
                                fontWeight: 400,
                                marginTop: '5px'
                              }}
                            >
                              you may upload png, jpeg, jpg and svg
                            </Typography>
                          </Box>
                        </Box>

                        )}
                    <input
                      ref={logoInputRefDarkInMode}
                      type='file'
                      sx={{ position: 'absolute', cursor: 'pointer' }}
                      onClick={(event) => {
                        event.stopPropagation()
                      }}
                      onChange={(event) => {
                        handleLogoChange('mainDarkLogo', event.target.files[0])
                      }}
                      accept='image/*'
                      style={{ display: 'none' }}
                    />
                  </Box>
                  {/* <div>{(formik.errors.mainDarkLogo) ? <p style={{ color: '#d32f2f', fontSize: '0.75rem' }}>{formik.errors.mainDarkLogo}</p> : null}</div> */}
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ color: '#002A59', fontSize: 'small', fontWeight: 400, marginTop: '1px' }} mt={1}>
                    Preferred Dimension: 250 x 250. Max file size: 2MB
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ color: '#74788d', fontSize: '14px', fontWeight: 400 }} mb={1}>Logo Image in Light Mode For Mobile</Typography>
                  <Box
                    onDragEnter={(event) => event.preventDefault()}
                    onDragOver={(event) => event.preventDefault()}
                    onDragLeave={(event) => event.preventDefault()}
                    onDrop={(event) => {
                      event.preventDefault()
                      handleLogoChange('mobLightLogo', event.dataTransfer.files[0])
                    }}
                    onClick={() => {
                      logoInputRefLightModeMobile.current.click()
                    }}
                    sx={{ border: formik.errors.mobLightLogo ? '1px solid #d32f2f' : '1px solid #91b5d8', borderRadius: '6px', position: 'relative', height: '130px', background: '#f3f4f8' }} align='center' p={1}
                  >
                    {formik?.values?.mobLightLogoUrl
                      ? (
                        <>
                          <img src={formik?.values?.mobLightLogoUrl} alt='selected logo' style={{ maxHeight: '100%', maxWidth: '100%' }} />
                          <Box
                            onClick={(e) => {
                              e.stopPropagation()
                            }} sx={{ position: 'absolute', bottom: 0, left: 0, width: '100%', borderBottomLeftRadius: '6px', borderBottomRightRadius: '6px', zIndex: 1, background: '#ffffff', padding: '5px 6px 3px 32px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '30px' }}
                          >
                            <Typography variant='body2' sx={{ color: '#002A59' }}>{formik?.values?.mobLightLogo.name}</Typography>
                            <IconButton>
                              <CancelIcon onClick={(e) => handleCancel(e, 'mobLightLogo')} />
                            </IconButton>
                          </Box>
                        </>
                        )
                      : (
                        <Box
                          sx={{
                            position: 'absolute',
                            top: '28px',
                            left: '55px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                          align='center'
                        >
                          <img src={UploadCloudIcon} alt='upload icon' width='40' height='40' />
                          <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: '10px' }}>
                            <Typography
                              sx={{
                                color: '#002A59',
                                fontFamily: 'Open Sans',
                                fontSize: '16px',
                                fontWeight: 400,
                                marginTop: '10px'
                              }}
                            >
                              Drag & drop Or click to upload
                            </Typography>
                            <Typography
                              sx={{
                                color: '#002A59',
                                fontFamily: 'Open Sans',
                                fontSize: '12px',
                                marginRight: '10px',
                                fontWeight: 400,
                                marginTop: '5px'
                              }}
                            >
                              you may upload png, jpeg, jpg and svg
                            </Typography>
                          </Box>
                        </Box>

                        )}
                    <input
                      ref={logoInputRefLightModeMobile}
                      type='file'
                      sx={{ position: 'absolute', cursor: 'pointer' }}
                      onClick={(event) => {
                        event.stopPropagation()
                      }}
                      onChange={(event) => {
                        handleLogoChange('mobLightLogo', event.target.files[0])
                      }}
                      accept='image/*'
                      style={{ display: 'none' }}
                    />
                  </Box>
                  {/* <div>{(formik.errors.mobLightLogo) ? <p style={{ color: '#d32f2f', fontSize: '0.75rem' }}>{formik.errors.mobLightLogo}</p> : null}</div> */}
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ color: '#74788d', fontSize: '14px', fontWeight: 400 }} mb={1}>Logo Image in Dark Mode For Mobile</Typography>
                  <Box
                    onDragEnter={(event) => event.preventDefault()}
                    onDragOver={(event) => event.preventDefault()}
                    onDragLeave={(event) => event.preventDefault()}
                    onDrop={(event) => {
                      event.preventDefault()
                      handleLogoChange('mobDarkLogo', event.dataTransfer.files[0])
                    }}
                    onClick={() => {
                      logoInputRefDarkModeMobile.current.click()
                    }}
                    sx={{ border: formik.errors.mobDarkLogo ? '1px solid #d32f2f' : '1px solid #91b5d8', borderRadius: '6px', position: 'relative', height: '130px', background: '#f3f4f8' }} align='center' p={1}
                  >
                    {formik?.values?.mobDarkLogoUrl
                      ? (
                        <>
                          <img src={formik?.values?.mobDarkLogoUrl} alt='selected logo' style={{ maxHeight: '100%', maxWidth: '100%' }} />
                          <Box
                            onClick={(e) => {
                              e.stopPropagation()
                            }} sx={{ position: 'absolute', bottom: 0, left: 0, borderBottomLeftRadius: '6px', borderBottomRightRadius: '6px', width: '100%', zIndex: 1, background: '#ffffff', padding: '5px 6px 3px 32px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '30px' }}
                          >
                            <Typography variant='body2' sx={{ color: '#002A59' }}>{formik?.values?.mobDarkLogo.name}</Typography>
                            <IconButton>
                              <CancelIcon onClick={(e) => handleCancel(e, 'mobDarkLogo')} />
                            </IconButton>
                          </Box>
                        </>
                        )
                      : (
                        <Box
                          sx={{
                            position: 'absolute',
                            top: '28px',
                            left: '55px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                          align='center'
                        >
                          <img src={UploadCloudIcon} alt='upload icon' width='40' height='40' />
                          <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: '10px' }}>
                            <Typography
                              sx={{
                                color: '#002A59',
                                fontFamily: 'Open Sans',
                                fontSize: '16px',
                                fontWeight: 400,
                                marginTop: '10px'
                              }}
                            >
                              Drag & drop Or click to upload
                            </Typography>
                            <Typography
                              sx={{
                                color: '#002A59',
                                fontFamily: 'Open Sans',
                                fontSize: '12px',
                                marginRight: '10px',
                                fontWeight: 400,
                                marginTop: '5px'
                              }}
                            >
                              you may upload png, jpeg, jpg and svg
                            </Typography>
                          </Box>
                        </Box>

                        )}
                    <input
                      ref={logoInputRefDarkModeMobile}
                      type='file'
                      sx={{ position: 'absolute', cursor: 'pointer' }}
                      onClick={(event) => {
                        event.stopPropagation()
                      }}
                      onChange={(event) => {
                        handleLogoChange('mobDarkLogo', event.target.files[0])
                      }}
                      accept='image/*'
                      style={{ display: 'none' }}
                    />
                  </Box>
                  {/* <div>{(formik.errors.mobDarkLogo) ? <p style={{ color: '#d32f2f', fontSize: '0.75rem' }}>{formik.errors.mobDarkLogo}</p> : null}</div> */}
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ color: '#002A59', fontSize: 'small', fontWeight: 400, marginTop: '1px' }}>
                    Preferred Dimension: 100 x 100 . Max file size: 2MB
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ color: '#74788d', fontSize: '14px', fontWeight: 400 }} mb={1}>Favicon Logo Image in Light Mode</Typography>
                  <Box
                    onDragEnter={(event) => event.preventDefault()}
                    onDragOver={(event) => event.preventDefault()}
                    onDragLeave={(event) => event.preventDefault()}
                    onDrop={(event) => {
                      event.preventDefault()
                      handleLogoChange('favIcon', event.dataTransfer.files[0])
                    }}
                    onClick={() => {
                      logoInputRefFaviConLightMode.current.click()
                    }}
                    sx={{ border: formik.errors.favIcon ? '1px solid #d32f2f' : '1px solid #91b5d8', borderRadius: '6px', position: 'relative', height: '130px', background: '#f3f4f8' }} align='center' p={1}
                  >
                    {formik?.values?.favIconUrl
                      ? (
                        <>
                          <img src={formik?.values?.favIconUrl} alt='selected logo' style={{ maxHeight: '100%', maxWidth: '100%' }} />
                          <Box
                            onClick={(e) => {
                              e.stopPropagation()
                            }} sx={{ position: 'absolute', bottom: 0, left: 0, borderBottomLeftRadius: '6px', borderBottomRightRadius: '6px', width: '100%', zIndex: 1, background: '#ffffff', padding: '5px 6px 3px 32px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '30px' }}
                          >
                            <Typography variant='body2' sx={{ color: '#002A59' }}>{formik?.values?.favIcon.name}</Typography>
                            <IconButton>
                              <CancelIcon onClick={(e) => handleCancel(e, 'favIcon')} />
                            </IconButton>
                          </Box>
                        </>
                        )
                      : (
                        <Box
                          sx={{
                            position: 'absolute',
                            top: '28px',
                            left: '55px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                          align='center'
                        >
                          <img src={UploadCloudIcon} alt='upload icon' width='40' height='40' />
                          <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: '10px' }}>
                            <Typography
                              sx={{
                                color: '#002A59',
                                fontFamily: 'Open Sans',
                                fontSize: '16px',
                                fontWeight: 400,
                                marginTop: '10px'
                              }}
                            >
                              Drag & drop Or click to upload
                            </Typography>
                            <Typography
                              sx={{
                                color: '#002A59',
                                fontFamily: 'Open Sans',
                                fontSize: '12px',
                                marginRight: '10px',
                                fontWeight: 400,
                                marginTop: '5px'
                              }}
                            >
                              you may upload png, jpeg, jpg and svg
                            </Typography>
                          </Box>
                        </Box>

                        )}
                    <input
                      ref={logoInputRefFaviConLightMode}
                      type='file'
                      sx={{ position: 'absolute', cursor: 'pointer' }}
                      onClick={(event) => {
                        event.stopPropagation()
                      }}
                      onChange={(event) => {
                        handleLogoChange('favIcon', event.target.files[0])
                      }}
                      accept='image/*'
                      style={{ display: 'none' }}
                    />
                  </Box>
                  {/* <div>{(formik.errors.favIcon) ? <p style={{ color: '#d32f2f', fontSize: '0.75rem' }}>{formik.errors.favIcon}</p> : null}</div> */}
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ color: '#002A59', fontSize: 'small', fontWeight: 400, marginTop: '1px' }}>
                    Preferred Dimension: 25 x 25. Max file size: 2MB
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: '2rem' }}>
              <Box sx={{ display: 'flex', gap: '3rem' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography sx={{ color: '#74788d', fontSize: '14px', fontWeight: 400, marginBottom: '0.5rem' }}>Primary Color</Typography>
                  <Box className='input-spn' sx={{ display: 'flex', flexDirection: 'row', border: '1px solid #ccc', padding: '2px', borderRadius: '5px' }}>
                    <input
                      type='text'
                      id='primaryColor'
                      name='primaryColor'
                      style={{ marginLeft: '5px', width: '80px', border: 'none', outline: 'none', marginRight: '0.5rem', borderRight: '1px solid #ccc' }}
                      onChange={formik.handleChange}
                      value={formik?.values?.primaryColor}
                    />
                    <input
                      type='color'
                      id='primaryColor'
                      name='primaryColor'
                      style={{ width: '85px' }}
                      onChange={formik.handleChange}
                      value={formik?.values?.primaryColor}
                    />
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography sx={{ color: '#74788d', fontSize: '14px', fontWeight: 400, marginBottom: '0.5rem' }}>Secondary Color</Typography>
                  <Box className='input-spn' sx={{ display: 'flex', flexDirection: 'row', border: '1px solid #ccc', padding: '2px', borderRadius: '5px' }}>
                    <input
                      type='text'
                      id='secondaryColor'
                      name='secondaryColor'
                      style={{ marginLeft: '5px', width: '80px', border: 'none', outline: 'none', marginRight: '0.5rem', borderRight: '1px solid #ccc' }}
                      onChange={formik.handleChange}
                      value={formik?.values?.secondaryColor}
                    />
                    <input
                      type='color'
                      id='secondaryColor'
                      name='secondaryColor'
                      style={{ width: '85px' }}
                      onChange={formik.handleChange}
                      value={formik?.values?.secondaryColor}
                    />
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography sx={{ color: '#74788d', fontSize: '14px', fontWeight: 400, marginBottom: '0.5rem' }}>Body Text Color</Typography>
                  <Box className='input-spn' sx={{ display: 'flex', flexDirection: 'row', border: '1px solid #ccc', padding: '2px', borderRadius: '5px' }}>
                    <input
                      type='text'
                      id='brandTextColor'
                      name='brandTextColor'
                      style={{ marginLeft: '5px', width: '80px', border: 'none', outline: 'none', marginRight: '0.5rem', borderRight: '1px solid #ccc' }}
                      onChange={formik.handleChange}
                      value={formik?.values?.brandTextColor}
                    />
                    <input
                      type='color'
                      id='brandTextColor'
                      name='brandTextColor'
                      style={{ width: '85px' }}
                      onChange={formik.handleChange}
                      value={formik?.values?.brandTextColor}
                    />
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography sx={{ color: '#74788d', fontSize: '14px', fontWeight: 400, marginBottom: '0.5rem' }}>Opacity</Typography>
                  <TextField
                    type='number'
                    id='opacity'
                    name='opacity'
                    placeholder='0.5'
                    sx={{ marginLeft: '5px', width: '140px', marginRight: '0.5rem', marginTop: '4px' }}
                    InputProps={{
                      inputProps: {
                        type: 'number',
                        step: '0.10',
                        min: 0.2,
                        max: 0.8
                      }
                    }}
                    defaultValue={0.5}
                    error={
                      Boolean(formik.errors.opacity)
                    }
                    helperText={
                      formik.errors.opacity &&
                      String(formik.errors.opacity)
                    }
                    onChange={formik.handleChange}
                    value={formik?.values?.opacity}
                    InputLabelProps={{
                      shrink: true
                    }}
                    variant='standard'
                  />
                </Box>
              </Box>
            </Box>
          </form>
          {
                createApiLoading
                  ? (
                    <LoadingButton
                      loading
                      loadingPosition='start'
                      variant='contained'
                      fullWidth
                      type='submit'
                      sx={{ mt: 5 }}
                    >
                      Create
                    </LoadingButton>
                    )
                  : (
                    <>
                      <Button
                        color='primary' variant='contained' type='submit' sx={{ mt: 5, width: '45%' }} onClick={() => setBtnType('Preview')}
                      >
                        Preview
                      </Button>
                      <Button
                        disabled={!formik?.isValid} color='primary' variant='contained' type='submit' sx={{ marginLeft: '84px', mt: 5, width: '45%' }} onClick={() => setBtnType('Create')}
                      >
                        Create
                      </Button>
                    </>
                    )
              }
        </form>
      </DialogContent>
      {/* <ShowPreview /> */}
    </>
  )
}

export default CreateSponsorInputFrom
