import { getUserRole } from '../../../utils/getUserRole'
import { GetAPI } from '../components/Actions'

export const sponsorAPI = () => {
  const userRole = getUserRole()

  return GetAPI('baseSponserURL', `data-maintenance/v1/${userRole}/sponsors`)
}

const strategyOwnersAPI = () => {
  const userRole = getUserRole()

  return GetAPI('baseSponserURL', `data-maintenance/v1/${userRole}/strategy-owners`)
}

const financialAdvisorAPI = () => {
  const userRole = getUserRole()

  return GetAPI('baseSponserURL', `data-maintenance/v1/${userRole}/sponsors/financial-advisor`)
}

export const GetSponsorData = async () => {
  const apiCalls = [
    sponsorAPI(),
    strategyOwnersAPI(),
    financialAdvisorAPI()
  ]
  const responses = await Promise.allSettled(apiCalls)

  const [
    sponsorAPIData,
    strategyOwnersAPIData,
    financialAdvisorAPIData
  ] = responses

  return [sponsorAPIData?.value, strategyOwnersAPIData?.value, financialAdvisorAPIData?.value]
}
