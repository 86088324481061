import { CircularProgress } from '@mui/material'
import React, { useRef, useState } from 'react'

const PageCover = ({
  data,
  chartIndex,
  index,
  chartHeight,
  chartWidth,
  clickEvent,
}) => {
  const canvasRef = useRef(null)

  if (data?.value) {
    if (data?.value === 'processing') {
      return (
        <div
          className={'pdf-upload-page'}
          style={{ textAlign: 'center', display: 'flex', paddingTop: 200, alignItems: 'center', flexDirection: 'column', fontSize: '12px' }}
        >
          <CircularProgress size='30px' />
          Processing PDF
        </div>
      )
    }
    return (
      <img
        alt='pdf-img'
        src={data?.value}
        className='pdf-upload-page'
        ref={canvasRef}
      />
    )
  } else {
    return null
  }
}

export default PageCover
