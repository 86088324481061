import { FormatPayload } from '../components/FormatAPIRequest'
import { UpdateAPI } from '../components/Actions'
import { getUserRole } from '../../../utils/getUserRole'

export const UpdateTradeData = async (updateRow, tabIndex) => {
  switch (tabIndex) {
    case 'trade-status-master':
      return await UpdateTradeStatusMaster(updateRow)
    default:
      return {}
  }
}

const UpdateTradeStatusMaster = async (updateRow) => {
  const userRole = getUserRole()
  let payload = {
    tradeStatusCode: updateRow.tradeStatusCode?.toUpperCase(),
    tradeStatusName: updateRow.tradeStatusName,
    tradeStatusDesc: updateRow.tradeStatusDesc
  }

  payload = FormatPayload(payload)

  return UpdateAPI('tradeMaintainURL', `data-maintenance/v1/${userRole}/trading/status-master/${updateRow.tradeStatusId}`, payload)
}
