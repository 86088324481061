export const accountOnboardingData = (data, personalization, selectedStrategy, accountDetails, taxlotData) => {
  const { securityData, substitutionData, sectorData, industryData, factorUtilData, taxConstraintData } = personalization || {}

  if (securityData) {
    data.restrictedSecurities = securityData?.map(item => ({
      instrumentId: item?.actionKey,
      restrictionId: item?.secKey,
      ...(item?.action === 'Hold Minimum' || item?.action === 'Hold Maximum'
        ? { weight: Number(Number(item?.weight / 100).toFixed(7)) }
        : {})
    }))
  } else if (data?.restrictedSecurities?.length === 0) {
    delete data.restrictedSecurities
  }

  if (substitutionData?.length) {
    data.substitution = substitutionData?.map(item => ({
      sourceInstrumentId: item?.srcId,
      targetInstrumentId: item?.trgId
    }))
  }

  if (sectorData) {
    const sectorRestrictions = sectorData?.filter(item => item?.exclude || item?.min !== '' || item?.max !== '')
      .map(item => ({
        sectorId: item?.assetClass,
        sectorExclude: item?.exclude,
        ...(item?.min && { min: item.min }),
        ...(item?.max && { max: item.max })
      }))

    if (sectorRestrictions?.length) {
      data.sectorRestrictions = sectorRestrictions
    }
  }

  const industryRestrictions = industryData?.filter(item => item?.exclude || item?.min !== '' || item?.max !== '')
  if (industryRestrictions?.length) {
    const industryRestrictionsData = industryRestrictions?.map((item) => {
      return {
        sectorId: item.assetClass,
        industryId: item.industry,
        industryExclude: item.exclude,
        ...(item?.min && { min: item.min }),
        ...(item?.max && { max: item.max })
      }
    })
    if (industryRestrictionsData?.length) {
      data.industryRestrictions = industryRestrictionsData
    }
  }

  const accFactorTilts = factorUtilData?.filter(item => item?.selected || item?.value !== '')
    .map(item => ({
      default: item?.selected,
      statsLevelValue: item?.statsLevelValue,
      statsValue: item?.value || 0
    }))

  if (accFactorTilts?.length) {
    data.factorTiltsConstraints = { riskModelVerId: factorUtilData[0]?.riskModelVerId, accFactorTilts }
  }

  delete data.minCashLevel
  delete data.advLongTermTaxRate
  delete data.advShortTermTaxRate

  data.maxShortTermGain = 0
  data.maxLongTermGain = 0
  taxConstraintData?.forEach(item => {
    if (item?.name === 'Max. Short Term Gain') {
      data.maxShortTermGain = item.value || 0
    } else if (item?.name === 'Max. Long Term Gain') {
      data.maxLongTermGain = item.value || 0
    } else if (item?.name === 'Tax Sensitivity') {
      data.taxSensitivity = item.value.toUpperCase() || ''
    }
  })

  if (data?.taxSensitivity === '') {
    delete data.taxSensitivity
  }

  if (data.taxSensitivity === undefined) {
    if (data.isTaxManaged) {
      data.taxSensitivity = 'MEDIUM'
    } else {
      data.taxSensitivity = 'LOW'
    }
  }

  if (personalization?.cashConstraintData) {
    const cashConstraints = personalization?.cashConstraintData?.reduce((acc, item) => {
      const propertyCode = item.propertyCode
      const value = item?.value
      if (value !== undefined && value?.length > 0) {
        acc.push({ propertyCode, propertyValue: Number(item?.value) })
      }
      return acc
    }, [])
    if (cashConstraints?.length) {
      data.cashConstraints = cashConstraints
    }
  }

  if (personalization?.otherConstraintData) {
    const otherConstraints = personalization?.otherConstraintData?.reduce((acc, item) => {
      const propertyCode = item.propertyCode
      const value = item?.value
      if (propertyCode === 'DEFAULT_SCENARIO_CODE') {
        acc.push({ propertyCode, propertyValue: item?.value })
      } else if (value !== undefined && value?.length > 0) {
        acc.push({ propertyCode, propertyValue: Number(item?.value) })
      }
      return acc
    }, [])

    if (otherConstraints?.length) {
      data.otherConstraints = otherConstraints
    }
  }
  return data
}
