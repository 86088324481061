import { useState } from 'react'
import { Box, IconButton, Modal, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import TableViewIcon from '@mui/icons-material/TableView'
import { tradeModalStyle } from './tradeModalStyle'
import StyledTooltip from './StyledTooltip'
import ViewOptProperties from '../ViewOptproperties'

const CustomPropertiesDetailsCell = (params) => {
  const [editIndex, setEditIndex] = useState()
  const [optId, setOptId] = useState()
  const [isViewOptPro, setViewOptPro] = useState(false)

  const viewOptProShow = (index, opt, accId) => {
    setEditIndex(index)
    setOptId(opt)
    setViewOptPro(true)
  }

  return (
    <>
      <IconButton
        className='action-clock-btn'
        size='small'
        onClick={() => {
          viewOptProShow(
            params.row?.accOptMapId,
            params.row?.account?.optId,
            params?.row?.account?.accountId
          )
        }}
      >
        <StyledTooltip title='View Personalization'>
          <TableViewIcon
            fontSize='small'
            style={{
              color: '#74788D'
            }}
          />
        </StyledTooltip>
      </IconButton>

      <Modal
        open={isViewOptPro}
        onClose={() => setViewOptPro(false)}
      >
        <Box sx={{
          ...tradeModalStyle,
          maxWidth: '1000px',
          height: '700px',
          maxHeight: '100vh',
          '.MuiDataGrid-main': {
            height: 'calc(min(700px, 100vh) - 16px - 16px - 40px - 48px - 53px)'
          }
        }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography style={{ fontSize: '22px', fontWeight: 400 }}>View Personalization</Typography>
            <IconButton aria-label='close' style={{ marginRight: '-10px' }} onClick={() => setViewOptPro(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
          <ViewOptProperties
            index={editIndex}
            // fArray={data}
            optId={optId}
          />
        </Box>
      </Modal>
    </>
  )
}

export default CustomPropertiesDetailsCell
