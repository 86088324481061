import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { BsBarChartLine } from 'react-icons/bs'
import CircleIcon from '@mui/icons-material/Circle'
import CloseIcon from '@mui/icons-material/Close'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined'
import SaveIcon from '@mui/icons-material/Save'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material'
import Skeleton from '@mui/material/Skeleton'
import { randomId } from '@mui/x-data-grid-generator'
import { DataGridPro, gridClasses, useGridApiRef } from '@mui/x-data-grid-pro'
import approveIcon from '../../../assets/images/approveIcon.svg'
import closeIcon from '../../../assets/images/close.svg'
import filterIcon from '../../../assets/images/filter.svg'
import showAllAccount from '../../../assets/images/icon-show-all-account.svg'
import PolicyStatusPopup from '../../../components/PolicyStatusPopup'
import { useAuth } from '../../../contexts/AuthContext'
import { moduleConfig } from '../../../contexts/data.js'
import { ACCESS_LEVEL } from '../../../contstants/constants.js'
import { useErrorToast } from '../../../hooks/useErrorToast'
import { useSuccessToast } from '../../../hooks/useSuccessToast'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import { enableLegacyViewReducer } from '../../../store/trade-reducer/trade-reducer.js'
import Loader from '../../Loader'
import { formatCurrencyWithSymbol } from '../../Report/components/dataProcess/DataProcess.js'
import LivePriceButton from '../components/LivePriceButton'
import DownloadTransButton from './DownloadTransButton'
import TradeApprovalFilterMenu from './TradeApprovalFilterMenu'
import TradeApprovalsHeaderMain from './TradeApprovalsHeaderMain'
import { TradeErrorTable } from './TradeErrorTable.js'
import TradeLegacyViewTable from './TradeLegacyViewTable'
import './Trade.css'
import TradeBlotterPopUp from './TradeBlotterPopUp.js'

function getStatusColor(status) {
  if (status === 'green')
    return '#3BBFA3'
  else if (status === 'red')
    return '#FF6161'
  else if (status === 'yellow')
    return '#D29922'
  else if (status === 'gray')
    return '#0000004d'
  return '#c9c9c9'
}

const TradeApprovalsMain = () => {
  const apiRef = useGridApiRef()
  const { user, checkAccess } = useAuth()
  const dispatch = useDispatch()
  const [optmRun, setOptmRun] = useState()
  const [optmRunSum, setOptmRunSum] = useState()
  const { showError } = useErrorToast()
  const [finalArray, setFinalArray] = useState([])
  const [tradeArray, setTradeArray] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [loading, setLoading] = useState(true)
  const [optApprovals, setOptApprovals] = useState([])
  const [chartLabelData, setChartLabelData] = useState([])
  const { showSuccess } = useSuccessToast()
  const { width } = useWindowDimensions()
  const [showFilters, setShowFilters] = useState(false)
  // const [scenarioList, setScenarioList] = useState([])
  const [delistData, setDelistData] = useState([])
  const [tradeDatagridState, setTradeDatagridState] = useState({})
  const [scenarioMappingWithLegend, setScenarioMappingWithLegend] = useState({
    balanced: 'Balanced',
    mintax: 'Min Tax',
    model: 'Model'
  })
  const [legendState, setLegendState] = useState({
    balanced: false,
    model: false,
    mintax: false
  })
  const [strategies, setStrategies] = useState([])
  const [tradeStatusFilters, setTradeStatusFilters] = useState([
    {
      id: 1,
      name: 'Failed Scenario',
      colorNumber: 99,
      selected: true
    },
    {
      id: 2,
      name: 'Success Scenario',
      colorNumber: 2,
      selected: true
    },
    {
      id: 3,
      name: 'Inprogress Scenario',
      colorNumber: 1,
      selected: true
    },
    {
      id: 4,
      name: 'Pending Scenario',
      colorNumber: 0,
      selected: true
    }
  ])

  const [allScenarioList, setAllScenarioList] = useState({})
  const { showLegacyView } = useSelector((state) => state.trade)
  const location = useLocation()
  const navigate = useNavigate()
  const [selectedFilters, setSelectedFilters] = useState([])
  const [isDefaultChecked, setIsDefaultChecked] = useState(false)
  const [isApprovalDataChanged, setIsApprovalDataChanged] = useState(false)
  const [openTradeErrorModal, setOpenTradeErrorModal] = useState(false)
  const [tradeErrorRows, setTradeErrorRows] = useState([])
  const [tradeBlotterPopUpOpen, setTradeBlotterPopUpOpen] = useState(false)
  const [policyData, setPolicyData] = useState({})
  const [showPolicyPopup, setShowPolicyPopup] = useState(false)
  const [filterModel, setFilterModel] = useState({ items: [] })

  const canAccessLivePrice = checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS,
    { subModuleName: moduleConfig.TRADE_APPROVAL, component_name: moduleConfig.VIEW_LIVE_PRICE })

  const handleOpenTradeErrorModal = () => setOpenTradeErrorModal(true)
  const handleCloseTradeErrorModal = () => setOpenTradeErrorModal(false)

  const restoreDatagridState = () => {
    if (apiRef?.current?.restoreState && tradeDatagridState) {
      apiRef?.current?.restoreState({
        pinnedColumns: tradeDatagridState.pinnedColumns,
        columns: tradeDatagridState.columns,
        sorting: tradeDatagridState.sorting
      })
    }
  }

  // restore the datagrid state whenever the state variables updates
  // add new state variables in the dependency array to prevent columns from getting resize and reorder
  useEffect(() => {
    restoreDatagridState()
  }, [isLoading, loading, tradeArray, finalArray,
    optmRun, optApprovals, isApprovalDataChanged,
    tradeErrorRows, showFilters, delistData,
    openTradeErrorModal, tradeBlotterPopUpOpen])

  const handleChartLabel = (datapoints, label, clearChartLabel=false) => {
    const findMinMax = (data, property) => {
      const values = data.map(item => item[property])
      return {
        min: Math.min(...values),
        max: Math.max(...values)
      }
    }
    const xRange = findMinMax(datapoints, 'x')
    const yRange = findMinMax(datapoints, 'y')
    const newLabel = `${yRange.min} <= ${label} <= ${yRange.max} / ${xRange.min} <= Tax($) ${xRange.max}`
    const uniqueAccountIds = Array.from(new Set(datapoints.map(item => item.accountId)))
    setChartLabelData([
      {
        xRange,
        yRange,
        label: newLabel,
        accountIds: uniqueAccountIds
      }
    ])
  }

  const handleLegendsClick = (legends) => {
    // Filter out items with a value of false
    const selectedScenario = Object.keys(legends).filter(key => !legends[key])
    // Update state with selected legends
    setLegendState(prevState => ({
      ...legends,
      selectedScenario
    }))
  }

  const updatePendingApprovalIds = (formattedData) => {
    setOptApprovals(formattedData)
  }

  const fetchDelistActions = async () => {
    if (checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.SUB_MODULE_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL }) &&
      checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL, id: null, component_name: moduleConfig.DELIST_INSTRUMENTS })) {
      API.get(
        'baseUriCorporate',
        `corporate-action/v1/${user?.userGroup}/corporate-action-data`,
        {
          queryStringParameters: {
            'action-type': 'DELIST',
            ...(user?.userGroup === 'adv-classic')
              ? {
                  resources: encodeURIComponent(JSON.stringify({ serviceId: 'trade', resourceId: 'trade-approvals' }))
                }
              : ''
          }
        }
      )
        .then((response) => {
          if (response.success) {
            // Filter data to include only items with proposedAccounts or pendingAccounts
            const filteredData = response?.data?.delist?.filter(item =>
              item.proposedAccounts?.length > 0 || item.pendingAccounts?.length > 0
            )
            setDelistData(filteredData)
          }
        })
        .catch(error => {
          showError(error?.response?.data?.errorInfo?.userMessage || error?.message)
          Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
        })
    }
  }

  const fetchMarketValuePolicy = () => {
    if (!checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL, component_name: moduleConfig.VIEW_MARKET_VALUE_POLICY_CHECK }))
      return
    API.post('baseDataValidation', `data-validation/v1/${user.userGroup}/policies/market`)
      .then(res => {
        if (res?.data) {
          setPolicyData(res.data)
        }
      })
      .catch((error) => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
  }

  useEffect(() => {
    if (user) {
      getOptimRun()
      getAllScenarioList()
      getStrategyList()
      fetchDelistActions()
      fetchMarketValuePolicy()
    }
  }, [user])

  const resetLegends = () => {
    const originalLegends = {}
    Object.keys(scenarioMappingWithLegend).forEach(key => {
      originalLegends[key] = false
    })
    setLegendState(originalLegends)
  }

  useEffect(() => {
    if (optmRun?.length) {
      // store summary for each opt run
      const updatedOptRun = optmRun
      let scenarioList = []
      updatedOptRun.forEach((account) => {
        account.optDetails.forEach((run) => {
          run.summary = run.summaryDetails
          scenarioList.push(run.scenarioDesc)
        })
      })
      // setScenarioList([...new Set(scenarioList)])
      scenarioList = [...new Set(scenarioList)]
      const mappingObj = {}
      const legendStateObj = {}

      for (let i = 0; i < scenarioList.length; i++) {
        const key = scenarioList[i].toLowerCase().replace(/\s+/g, '') // Remove spaces for valid object keys
        legendStateObj[key] = legendState[key] || false
        mappingObj[key] = scenarioList[i]
      }
      // keep old state of legend on refreshing trade list
      if (legendState.isLegendClicked) {
        legendStateObj.isLegendClicked = legendState.isLegendClicked
      }

      setLegendState(legendStateObj)
      setScenarioMappingWithLegend(mappingObj)
      setOptmRun(updatedOptRun)
      setFinalArray(updatedOptRun)
      const accountList = location?.state?.tradeData || []
      if (accountList.length > 0) {
        const filteredAccounts = updatedOptRun.filter((account) => accountList.includes(account.accountCd))
        setFinalArray(filteredAccounts)
        if (filteredAccounts.length > 0) {
          dispatch(enableLegacyViewReducer())
        }
      } else {
        setFinalArray(updatedOptRun)
      }
      setTradeArray(updatedOptRun)
    }
    setIsLoading(false)
  }, [optmRun, location?.state?.tradeData])


  const handleTradeBlotterClick = () => {
    setTradeBlotterPopUpOpen(true)
  }

  const getOptimRun = async (clearCacheFlag = false) => {
    // setOptmRun([])
    setOptmRunSum(null)
    if (
      checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.SUB_MODULE_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL })) {
      try {
        // const arr = []
        const response = await API.get(
          'baseOptimizationURL',
          `optimization/v1/${user?.userGroup}/opt-runs`,
          {
            queryStringParameters: {
              approval_details: true,
              status_flag: true,
              ...{ ...clearCacheFlag ? { 'cache-override': true } : {} },
              ...{ ...user?.userGroup === 'adv-classic' ? { resources: encodeURIComponent(JSON.stringify({ "serviceId": "trade", "resourceId": "trade-approvals" })) } : {} }
            }
          }
        )
        if (response.data?.length === 0) {
          setLoading(false)
          setIsLoading(false)
        } else {
          setOptmRun(response.data)
          setLoading(false)
        }
      } catch (error) {
        setLoading(false)
        if (Array.isArray(error.response?.data?.errorInfo?.userMessage)) {
          error.response?.data?.errorInfo?.userMessage?.forEach((element) => {
            showError(element.message)
          })
        } else {
          showError(error.response?.data?.errorInfo?.userMessage || error.message)
        }
        setIsLoading(false)
        setLoading(false)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      }
    }
  }

  const getAllScenarioList = async () => {
    if (checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.SUB_MODULE_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL }) &&
      checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL, id: null, component_name: moduleConfig.RUN_ADHOC })) {
      API.get('baseUriOptimizationDataMaintenance', `data-maintenance/v1/${user?.userGroup}/optimizations/all-scenario-list`)
        .then((response) => {
          if (response.data?.data?.length === 0) {
            setLoading(false)
            setIsLoading(false)
          } else {
            const scenarioGroupDictionary = response?.data?.data?.reduce((acc, curr) => {
              if (!acc[curr.scenarioGroupId]) {
                acc[curr.scenarioGroupId] = [{ scenarioId: curr.scenarioId, scenarioGroupId: curr.scenarioCode, scenarioDesc: curr.scenarioDescription }]
              } else {
                acc[curr.scenarioGroupId].push({ scenarioId: curr.scenarioId, scenarioGroupId: curr.scenarioCode, scenarioDesc: curr.scenarioDescription })
              }
              return acc
            }, {})
            setAllScenarioList(scenarioGroupDictionary)
          }
        }).catch((error) => {
          showError(error.response?.data?.errorInfo?.userMessage || error.message)
          Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
        })
    }
  }

  const handleApproveTrade = async (event) => {
    if (optApprovals?.length === 0) {
      showError('No account selected for approval')
    } else {
      setIsLoading(true)
      event.preventDefault()
      const data = optApprovals?.map(item => ({
        optApprovalId: item?.approvalId,
        status: 'APPROVED',
        remarks: 'optimization approved'
      }))
      try {
        const response = await API.patch(
          'baseOptimizationURL',
          `optimization/v1/${user?.userGroup}/opt-approvals`,
          { body: data }
        )
        showSuccess(response.message)
        updatePendingApprovalIds([])
        setIsApprovalDataChanged(true)
        getOptimRun(true)
      } catch (error) {
        if (Array.isArray(error.response?.data?.errorInfo?.userMessage)) {
          error.response?.data?.errorInfo?.userMessage.forEach((element) => {
            showError(element.message)
          })
        } else {
          showError(error.response?.data?.errorInfo?.userMessage)
        }
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      } finally {
        setIsLoading(false)
      }
    }
  }

  // fetch settlement errors API
  const getSettlementData = async () => {
    if (optApprovals?.length === 0) {
      return []
    }
    // Pass accountId and accOptMapId from optApprovals as payload
    const payload = optApprovals?.map(item => ({
      accountId: item?.accountId,
      accOptMapId: item?.accOptMapId
    }))
    try {
      const response = await API.post(
        'baseDataValidation',
        `data-validation/v1/${user?.userGroup}/validate/aggregate-settlement`,
        {
          body: payload
        }
      )
      return response?.data || []
    } catch (error) {
      showError(error.response?.data?.errorInfo?.userMessage || error.message)
      Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
    }
  }

  // fetch fractional errors API
  const getFractionalData = async () => {
    if (optApprovals?.length === 0) {
      return []
    }
    try {
      // Pass accountId and accOptMapId from optApprovals as payload
      const payload = optApprovals?.map(item => ({
        accountId: item?.accountId,
        accOptMapId: item?.accOptMapId
      }))
      const response = await API.post(
        'baseDataValidation',
        `data-validation/v1/${user?.userGroup}/validate/fractional-trades`,
        {
          body: payload
        }
      )
      return response?.data || []
    } catch (error) {
      showError(error.response?.data?.errorInfo?.userMessage || error.message)
      Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
    }
  }

  // fetch washsale errors API
  const getWashsaleData = async () => {
    if (optApprovals?.length === 0) {
      return []
    }
    // Pass accountId and accOptMapId from optApprovals as payload
    const payload = optApprovals?.map(item => ({
      accountId: item?.accountId,
      accOptMapId: item?.accOptMapId
    }))
    try {
      const response = await API.post(
        'baseDataValidation',
        `data-validation/v1/${user?.userGroup}/validate/washsale-settlement`,
        {
          body: payload
        }
      )
      return response?.data || []
    } catch (error) {
      showError(error.response?.data?.errorInfo?.userMessage || error.message)
      Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
    }
  }

  const handleApprove = async (event) => {
    try {
      if (optApprovals?.length === 0) {
        showError('No account selected for approval')
      }
      // Check if there are any approvals
      if (optApprovals?.some(id => id !== null)) {
        setIsLoading(true)
        // Execute both API calls concurrently
        const apiCalls = [
          getSettlementData(),
          getFractionalData(),
          getWashsaleData()
        ]
        // Await all promises to settle
        const [settlementResult, fractionalResult, washsaleResult] = await Promise.allSettled(apiCalls)
        // Extract settlement and fractional errors from results
        const settlementErrors = settlementResult?.status === 'fulfilled' ? settlementResult?.value : []
        const fractionalErrors = fractionalResult?.status === 'fulfilled' ? fractionalResult?.value : []
        const washsaleErrors = washsaleResult?.status === 'fulfilled' ? washsaleResult?.value : []
        const combinedErrors = [
          ...(Array.isArray(settlementErrors) ? settlementErrors.map(error => ({ ...error, errorType: 'settlementError' })) : []),
          ...(Array.isArray(fractionalErrors) ? fractionalErrors.map(error => ({ ...error, errorType: 'fractionalError' })) : []),
          ...(Array.isArray(washsaleErrors) ? washsaleErrors.map(error => ({ ...error, errorType: 'washsaleErrors' })) : [])
        ]
        if (settlementResult.status === 'rejected' || fractionalResult.status === 'rejected' || washsaleResult.status === 'rejected') {
          setIsLoading(false)
        }
        setTradeErrorRows(combinedErrors?.map((item) => ({
          id: randomId(),
          ...item
        })))
        // Check if there are any errors; open modal if true, otherwise approve trade
        if (combinedErrors?.length > 0) {
          setIsLoading(false)
          handleOpenTradeErrorModal() // Open modal if there are any errors
        } else {
          handleApproveTrade(event) // Proceed with trade approval
        }
      }
    } catch (error) {
      setIsLoading(false)
      showError(error)
      Sentry.captureException(error)
    }
  }

  const tradeErrorColumns = [
    { field: 'accountName', headerName: 'Account Name', flex: 1 },
    {
      field: 'errorType',
      headerName: 'Policy',
      flex: 1,
      valueGetter: (params) => {
        if (params.value === 'settlementError') {
          return 'Settlement'
        } else if (params.value === 'fractionalError') {
          return 'Fractional Check'
        } else if (params.value === 'washsaleErrors') {
          return 'Washsale'
        } else {
          return params.value
        }
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => (
        <Box sx={{ color: '#F46A6A' }}>
          {params.value}
        </Box>
      )
    }]

  const getStrategyList = async () => {
    if (checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.SUB_MODULE_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL })) {
      API
        .get('baseStrategyURL', `strategy/v1/${user?.userGroup}/strategies`, {
          queryStringParameters: {
            page: 1,
            perpage: 100,
            ...{
              ...user?.userGroup === 'adv-classic'
                ? {
                    resources: encodeURIComponent(JSON.stringify({
                      serviceId: 'trade',
                      resourceId: 'trade-approvals'
                    }))
                  }
                : {}
            }
          }
        })
        .then(response => {
          if (response.data.strategies) {
            setStrategies(response.data.strategies)
          }
        })
        .catch(error => {
          showError(error.message)
          Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
        })
    }
  }

  useEffect(() => {
    if (isApprovalDataChanged) {
      if (selectedFilters?.length > 0) {
        applyFilter(selectedFilters, legendState.isDefaultScenario, legendState.isLegendClicked, legendState, true)
        setIsApprovalDataChanged(false)
      }
    }
  }, [tradeArray])

  const openFilters = () => {
    setShowFilters(true)
  }

  const closeFilters = () => {
    setShowFilters(false)
  }

  const removeAllFilters = () => {
    setFinalArray(tradeArray)
    setShowFilters(false)
    setSelectedFilters([])
    setChartLabelData([])
    const originalLegends = {}
    Object.keys(scenarioMappingWithLegend).forEach(key => {
      originalLegends[key] = false
    })
    setLegendState(originalLegends)
    // setLegendState({
    //   balanced: false,
    //   model: false,
    //   mintax: false
    // })
  }

  const getFilteredOptDetailsBasedOnStatus = (optDetails, colorNumber) => {
    if (colorNumber === 0) { // scenario is pending if one approval object is present status is null
      return optDetails?.filter(optObj => optObj?.approvalDetails?.length === 1 && optObj?.approvalDetails[0].approvalStatus === null)
    }
    else if (colorNumber === 1) { // scenario is inprogress if both approval objects are present and one is approved and other is null
      return optDetails?.filter(optObj => optObj?.approvalDetails?.length === 2 && optObj?.approvalDetails[0].approvalStatus === 'APPROVED' && optObj?.approvalDetails[1].approvalStatus === null)
    }
    else if (colorNumber === 2) { // scenario is success if both approval objects are present and approved
      return optDetails?.filter(optObj => optObj?.approvalDetails?.length === 2 && optObj?.approvalDetails[0].approvalStatus === 'APPROVED' && optObj?.approvalDetails[1].approvalStatus === 'APPROVED')
    }
    else if (colorNumber === 99) { // scenario is fail if none of the approval objects is present
      return optDetails?.filter(optObj => !optObj?.approvalDetails?.length)
    }
    return optDetails
  }

  const removeFilter = (filterValue, type) => {
    let selectedFiltersTemp = [...selectedFilters]
    if (type === 'zoomedAccountIds') {
      selectedFiltersTemp = selectedFiltersTemp.filter(filterObj => filterObj.name !== 'zoomedAccountIds')
      // resetLegends()
      // setZoomedAccountIds([])
    } else if (type === 'strategy') {
      selectedFiltersTemp = selectedFiltersTemp.map(filterObj => {
        if (filterObj.name === 'strategy') {
          return {
            ...filterObj,
            value: filterObj.value.filter(strategy => strategy.strategyId !== filterValue.strategyId)
          }
        }
        return filterObj
      })
    } else if (type === 'taxStatus') {
      selectedFiltersTemp = selectedFiltersTemp.map(filterObj => {
        if (filterObj.name === 'taxStatus') {
          return {
            ...filterObj,
            value: filterObj.value.filter(taxStatus => taxStatus !== filterValue)
          }
        }
        return filterObj
      })
    } else if (type === 'tradeStatus') {
      selectedFiltersTemp = selectedFiltersTemp.map(filterObj => {
        if (filterObj.name === 'tradeStatus') {
          return {
            ...filterObj,
            value: filterObj.value.filter(tradeStatus => tradeStatus.id !== filterValue.id)
          }
        }
        return filterObj
      })
    } else if (type === 'trackingError') {
      selectedFiltersTemp = selectedFiltersTemp.map(filterObj => {
        if (filterObj.name === 'trackingError') {
          const teImprovementToRemove = filterValue.teImprovement
          if (filterObj.value && filterObj.value.teImprovement === teImprovementToRemove) {
            filterObj.value = null
          }
        }
        return filterObj
      })
    } else if (type === 'tradingAge') {
      selectedFiltersTemp = selectedFiltersTemp.map(filterObj => {
        if (filterObj.name === 'tradingAge') {
          const ageToRemove = filterValue.tradingAge
          if (filterObj.value && filterObj.value.tradingAge === ageToRemove) {
            filterObj.value = null
          }
        }
        return filterObj
      })
    } else if (type === 'isModelChange') {
      selectedFiltersTemp = selectedFiltersTemp.map(filterObj => {
        if (filterObj.name === 'isModelChange') {
          const modelChangeRemove = filterValue.modelChange
          if (filterObj.value !== null && filterObj.value.isModelChanged === modelChangeRemove) {
            filterObj.value = null
          }
        }
        return filterObj
      })
    }
    if (type === 'delist') {
      const filterIndex = selectedFiltersTemp.findIndex(filterObj => filterObj.name === type)
      if (filterIndex !== -1) {
        selectedFiltersTemp[filterIndex].value = selectedFiltersTemp[filterIndex].value.filter(item => item !== filterValue)
        if (selectedFiltersTemp[filterIndex].value.length === 0) {
          selectedFiltersTemp.splice(filterIndex, 1)
        }
        setSelectedFilters(selectedFiltersTemp)
      }
    } else if (type === 'taxCostBenefit') {
      selectedFiltersTemp = selectedFiltersTemp.map(filterObj => {
        if (filterObj.name === 'taxCostBenefit') {
          const taxCostBenefitRemove = filterValue.taxCostBenefit
          if (filterObj.value && filterObj.value.taxCostBenefit === taxCostBenefitRemove) {
            filterObj.value = null
          }
        }
        return filterObj
      })
    }
    setSelectedFilters(selectedFiltersTemp)
    // if scenarios are selected, pass scenario data in filters
    if (legendState?.selectedScenario?.length) {
      applyFilter(selectedFiltersTemp, isDefaultChecked, legendState.isLegendClicked, legendState)
    }
    else { applyFilter(selectedFiltersTemp, isDefaultChecked) }
  }

  const removeLegendFilter = (scenario) => {
    let updatedLegendState = { ...legendState, selectedScenario: legendState.selectedScenario.filter(scenarioObj => scenarioObj !== scenario), [scenario]: !legendState.scenario }
    const updatedSelectedFilters = [...selectedFilters]
    if (Object.keys(updatedLegendState).filter(key => key !== 'selectedScenario' && key !== 'isDefaultScenario' && key !== 'isLegendClicked').every((legendKey) => updatedLegendState[legendKey]) || Object.keys(updatedLegendState).filter(key => key !== 'selectedScenario' && key !== 'isDefaultScenario' && key !== 'isLegendClicked').every((legendKey) => !updatedLegendState[legendKey])) {
      const originalLegends = {}
      Object.keys(scenarioMappingWithLegend).forEach(key => {
        originalLegends[key] = false
      })
      updatedLegendState = originalLegends
      setLegendState(originalLegends)
      // if all legends are removed, and there is no zoomed Ids, remove zoomed ID filter from selected filters list
      const zoomFilterIndex = updatedSelectedFilters.findIndex(filter => filter.name === 'zoomedAccountIds')
      if (zoomFilterIndex > -1 && !updatedSelectedFilters[zoomFilterIndex]?.value?.length) {
        updatedSelectedFilters.splice(zoomFilterIndex, 1)
        setSelectedFilters(updatedSelectedFilters)
      }
    } else {
      setLegendState(prevState => ({ ...prevState, selectedScenario: prevState.selectedScenario.filter(scenarioObj => scenarioObj !== scenario), [scenario]: !prevState.scenario }))
    }
    const selectedScenario = updatedLegendState.selectedScenario ? Object.keys(updatedLegendState).filter(key => key !== 'selectedScenario' && key !== 'isDefaultScenario' && key !== 'isLegendClicked').filter(key => !updatedLegendState[key]) : []
    if (updatedSelectedFilters.length) {
      const chartFilter = updatedSelectedFilters.find(filter => filter.name === 'zoomedAccountIds')
      if (chartFilter?.value?.length) {
        if (selectedScenario && selectedScenario.length) {
          const filterBasedOnScenario = selectedScenario.map(scenario => scenarioMappingWithLegend[scenario])
          setFinalArray(tradeArray.filter((account) => chartFilter?.value?.includes(account.accountId))
            .map((acc) => ({
              ...acc,
              optDetails: acc.optDetails?.filter(
                (opt) =>
                  filterBasedOnScenario.includes(opt.scenarioDesc) &&
                  (isDefaultChecked ? opt.isDefault : true) &&
                  !opt.failedScenario
              )
            }))
            .filter((acc) => acc.optDetails?.length))
        } else {
          setFinalArray(tradeArray.filter((account) => chartFilter?.value?.includes(account.accountId)))
        }
      } else {
        let selectedAccounts = [...tradeArray]
        updatedSelectedFilters.filter(filterObj => filterObj.name === 'isModelChange'
          ? filterObj.value !== undefined && filterObj.value !== null
          : filterObj.value?.length).forEach((filter) => {
          if (filter.name === 'tradeStatus' && filter.value) {
            const selectedTradeFilters = filter.value
              .filter((filter) => filter.selected)
              .map((filter) => filter.colorNumber) || []
            const filteredAccounts = selectedAccounts.filter((account) => selectedTradeFilters.includes(account.colorNumber))
              .map(acc => ({
                ...acc,
                optDetails: getFilteredOptDetailsBasedOnStatus(acc.optDetails, acc.colorNumber)
              }))
            selectedAccounts = [...filteredAccounts]
          } else if (filter.name === 'strategy' && filter.value) {
            const filteredAccounts = selectedAccounts.filter((account) => (filter.value)?.map(str => str.strategyId).includes(account.strategyId))
            selectedAccounts = [...filteredAccounts]
          } else if (filter.name === 'taxStatus' && filter.value) {
            const selectedTaxStatus = []
            if (filter.value.includes('Taxable')) {
              selectedTaxStatus.push(1)
            } if (filter.value.includes('Tax Exempt')) {
              selectedTaxStatus.push(0)
            }
            const filteredAccounts = selectedAccounts.filter((account) => selectedTaxStatus?.includes(account.isTaxable))
            selectedAccounts = [...filteredAccounts]
          } else if (filter.name === 'trackingError' && filter.value) {
            const filteredAccounts = selectedAccounts.filter((account) => account.optDetails.some(opt =>
              parseFloat(opt.summaryDetails?.teImprovement) >= filter?.value))
            selectedAccounts = [...filteredAccounts]
          } else if (filter.name === 'tradingAge' && filter?.value) {
            const filteredAccounts = selectedAccounts = selectedAccounts.filter(account =>
              account?.optDetails?.some(opt =>
                opt?.summaryDetails?.tradeAge === 'NA' || parseFloat(opt?.summaryDetails?.tradeAge) >= filter?.value
              )
            )
            selectedAccounts = [...filteredAccounts]
          } else if (filter.name === 'isModelChange' && filter.value !== null) {
            const filteredAccounts = selectedAccounts.filter(account =>
              account.optDetails.some(opt =>
                opt.summaryDetails?.isModelChanged === filter.value
              )
            )
            selectedAccounts = [...filteredAccounts]
          } else if (filter.name === 'delist' && filter.value) {
            const allAccounts = filter.value?.flatMap(group => {
              const accounts = []
              if (group?.proposedAccounts) accounts.push(...group.proposedAccounts)
              if (group?.pendingAccounts) accounts.push(...group.pendingAccounts)
              return accounts
            })
            const delistAccountIds = new Set(allAccounts?.map(account => account.accountId))
            const filteredAccounts = selectedAccounts.filter(account =>
              delistAccountIds?.has(account.accountId)
            )
            selectedAccounts = [...filteredAccounts]
          } else if (filter.name === 'taxCostBenefit' && filter.value) {
            const taxCostBenefitValue = parseFloat(filter.value * -1)
            const filteredAccounts = selectedAccounts.filter(account =>
              account?.optDetails?.some(opt =>
                opt?.summaryDetails?.taxCost === null || parseFloat(opt?.summaryDetails?.taxCost) <= taxCostBenefitValue
              )
            )
            selectedAccounts = [...filteredAccounts]
          }
        })
        if (selectedScenario && selectedScenario.length) {
          const filterBasedOnScenario = selectedScenario.map(scenario => scenarioMappingWithLegend[scenario])
          selectedAccounts = [...updatedSelectedFilters.filter(filterObj => filterObj.name === 'isModelChange'
            ? filterObj.value !== undefined && filterObj.value !== null
            : filterObj.value?.length)?.length
            ? selectedAccounts
            : finalArray]
            .map(acc => ({
              ...acc,
              optDetails: acc.optDetails?.filter(opt => filterBasedOnScenario.includes(opt.scenarioDesc) && (isDefaultChecked ? opt.isDefault : true) && !opt.failedScenario)
            }))
            .filter((acc) => acc.optDetails?.length)
        } else {
          selectedAccounts = [...updatedSelectedFilters.filter(filterObj => filterObj.name === 'isModelChange'
            ? filterObj.value !== undefined && filterObj.value !== null
            : filterObj.value?.length)?.length
            ? selectedAccounts
            : finalArray]
        }
        setFinalArray(selectedAccounts)
      }
    } else {
      if (selectedScenario && selectedScenario.length) {
        const filterBasedOnScenario = selectedScenario.map(scenario => scenarioMappingWithLegend[scenario])
        setFinalArray(tradeArray.map(acc => ({
          ...acc,
          optDetails: acc.optDetails?.filter(opt => filterBasedOnScenario.includes(opt.scenarioDesc) && (isDefaultChecked ? opt.isDefault : true) && !opt.failedScenario)
        }))
          .filter((acc) => acc.optDetails?.length)
        )
      } else {
        setFinalArray(tradeArray)
      }
    }
  }

  const applyFilter = (filters, isDefaultScenario, isLegendClicked = false, legends = null, updateLegends = false) => {
    setIsDefaultChecked(isDefaultScenario)
    let selectedScenario = []
    if (isLegendClicked || filters.find(filter => filter.name === 'zoomedAccountIds')?.activeFilterNames?.length) {
      if (legends) {
        selectedScenario = Object.keys(legends).filter(key => !legends[key] && key !== 'isDefaultScenario')
        // Update state with selected legends
        if (updateLegends) {
          setLegendState({
            ...legends,
            selectedScenario,
            isDefaultScenario,
            isLegendClicked
          })
        }
      }
    } else {
      if (updateLegends) {
        setLegendState(prevState => ({
          ...(legends || prevState),
          isDefaultScenario,
          isLegendClicked: false
        }))
      }
    }
    if (filters.length) {
      setSelectedFilters(filters.filter(filterObj =>
        filterObj.name === 'isModelChange'
          ? filterObj.value !== undefined && filterObj.value !== null
          : (filterObj.value?.length || filterObj?.activeFilterNames?.length)
      ))
      // apply chart filter as first priority
      const chartFilter = filters.find(filter => filter.name === 'zoomedAccountIds')
      let selectedAccounts = [...tradeArray]
      if (chartFilter?.value?.length) {
          selectedAccounts = tradeArray.filter((account) => chartFilter?.value?.includes(account.accountId))
      }
      // apply other filters
      filters.filter(filterObj =>
        filterObj.name === 'isModelChange'
          ? filterObj.value !== undefined && filterObj.value !== null
          : filterObj.value?.length
      )
        .forEach((filter) => {
          if (filter.name === 'tradeStatus' && filter.value) {
            const selectedTradeFilters = filter.value
              .filter((filter) => filter.selected)
              .map((filter) => filter.colorNumber) || []
            const filteredAccounts = selectedAccounts.filter((account) => selectedTradeFilters.includes(account.colorNumber))
              .map(acc => ({
                ...acc,
                optDetails: getFilteredOptDetailsBasedOnStatus(acc.optDetails, acc.colorNumber)
              }))
            selectedAccounts = [...filteredAccounts]
          } else if (filter.name === 'strategy' && filter.value) {
            const filteredAccounts = selectedAccounts.filter((account) => (filter.value)?.map(str => str.strategyId).includes(account.strategyId))
            selectedAccounts = [...filteredAccounts]
          } else if (filter.name === 'delist' && filter.value) {
            const allAccounts = filter.value?.flatMap(group => {
              const accounts = []
              if (group?.proposedAccounts) accounts.push(...group.proposedAccounts)
              if (group?.pendingAccounts) accounts.push(...group.pendingAccounts)
              return accounts
            })
            const delistAccountIds = new Set(allAccounts?.map(account => account.accountId))
            const filteredAccounts = selectedAccounts.filter(account =>
              delistAccountIds?.has(account.accountId)
            )
            selectedAccounts = [...filteredAccounts]
          } else if (filter.name === 'taxStatus' && filter.value) {
            const selectedTaxStatus = []
            if (filter.value.includes('Taxable')) {
              selectedTaxStatus.push(1)
            } if (filter.value.includes('Tax Exempt')) {
              selectedTaxStatus.push(0)
            }
            const filteredAccounts = selectedAccounts.filter((account) => selectedTaxStatus?.includes(account.isTaxable))
            selectedAccounts = [...filteredAccounts]
          } else if (filter.name === 'trackingError' && filter.value) {
            const minTeImprovement = parseFloat(filter.value)
            selectedAccounts = selectedAccounts.filter(account =>
              account.optDetails.some(opt =>
                parseFloat(opt.summaryDetails?.teImprovement) >= minTeImprovement
              )
            )
          } else if (filter.name === 'tradingAge' && filter.value) {
            const minAge = parseFloat(filter.value)
            selectedAccounts = selectedAccounts.filter(account =>
              account?.optDetails?.some(opt =>
                opt?.summaryDetails?.tradeAge === 'NA' || parseFloat(opt?.summaryDetails?.tradeAge) >= minAge
              )
            )
          } else if (filter.name === 'isModelChange' && filter.value !== null) {
            const modelChangedValue = filter.value
            selectedAccounts = selectedAccounts.filter(account =>
              account.optDetails.some(opt =>
                opt.summaryDetails?.isModelChanged === modelChangedValue
              )
            )
          } else if (filter.name === 'taxCostBenefit' && filter.value) {
            const taxCostBenefitValue = parseFloat(filter.value * -1)
            selectedAccounts = selectedAccounts.filter(account =>
              account?.optDetails?.some(opt =>
                opt?.summaryDetails?.taxCost === null || parseFloat(opt?.summaryDetails?.taxCost) <= taxCostBenefitValue
              )
            )
          }
        })
      // apply selected scenario filters. show opt entries which has selected scenarios
      if (selectedScenario && selectedScenario.length) {
        const filterBasedOnScenario = selectedScenario.map(scenario => scenarioMappingWithLegend[scenario])
        selectedAccounts = [...filters.filter(filterObj => filterObj.name === 'isModelChange'
          ? filterObj.value !== undefined && filterObj.value !== null
          : filterObj.value?.length)?.length ? selectedAccounts : tradeArray].map(acc => ({
          ...acc,
          optDetails: acc.optDetails?.filter(opt => filterBasedOnScenario.includes(opt.scenarioDesc) && (isDefaultScenario ? opt.isDefault : true) && !opt.failedScenario)
        }))
          .filter((acc) => acc.optDetails?.length > 0)
      } else {
        selectedAccounts = [...filters.filter(filterObj =>
          filterObj.name === 'isModelChange'
            ? filterObj.value !== undefined && filterObj.value !== null
            : filterObj.value?.length
        )?.length
          ? selectedAccounts
          : tradeArray]
      }
      setFinalArray(selectedAccounts)
    } else if (selectedScenario && selectedScenario.length) {
      const filterBasedOnScenario = selectedScenario.map(scenario => scenarioMappingWithLegend[scenario])
      setFinalArray(tradeArray.map(acc => ({
        ...acc,
        optDetails: acc.optDetails?.filter(opt => filterBasedOnScenario.includes(opt.scenarioDesc) && (isDefaultScenario ? opt.isDefault : true) && !opt.failedScenario)
      }))
        .filter((acc) => acc.optDetails?.length > 0))
    } else {
      setFinalArray([...tradeArray])
    }
    closeFilters()
  }

  const handleRefreshClick = () => {
    setLoading(true)
    setIsApprovalDataChanged(true)
    getOptimRun()
  }

  const getDetailPanelContent = useCallback(
    ({ row }) => <TradeErrorTable row={row} />,
    []
  )
  const getDetailPanelHeight = useCallback(() => 'auto', [])

  return (
    <>
      {tradeBlotterPopUpOpen && <TradeBlotterPopUp open={tradeBlotterPopUpOpen} setOpen={setTradeBlotterPopUpOpen} apiRef={apiRef} />}
      <Box className='Account-trade'>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
          className='tradeMainhead'
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            <Typography component='h3' className='page-title'>Trade Approval</Typography>
            {
              policyData?.overallPolicyColor && checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL, component_name: moduleConfig.VIEW_MARKET_VALUE_POLICY_CHECK }) ? (
                <Tooltip title={'Market Value Policies'}>
                  <IconButton onClick={() => setShowPolicyPopup(true)} sx={{ p: '6px' }}>
                    <CircleIcon sx={{ color: getStatusColor(policyData?.overallPolicyColor), height: '12px', width: '12px' }} />
                  </IconButton>
                </Tooltip>
              ) : ''
            }
            <PolicyStatusPopup data={policyData} title='Market Value' onClose={() => setShowPolicyPopup(false)} showPolicyPopup={showPolicyPopup} canDownloadJson={checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL, component_name: moduleConfig.DOWNLOAD_MARKET_VALUE_POLICY_CHECK })} />
          </Box>
          <Box
            className='tradeApprove-btn'
            sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}
          >
            {
              canAccessLivePrice ? (
                <LivePriceButton />
              ) : ''
            }
            {
              tradeArray.length ? (
                <>
                  {location?.state?.tradeData && location?.state?.tradeData?.length
                    ? <Button
                      variant='text'
                      onClick={() => {
                        setFinalArray(tradeArray)
                        navigate('.', { state: null })
                      }}
                      startIcon={<img src={showAllAccount} alt='' />}
                    >Show All Accounts
                    </Button>
                    : ''}
                  {checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS,
                    { subModuleName: moduleConfig.TRADE_APPROVAL, component_name: moduleConfig.VIEW_TRADE_BLOTTER }) && finalArray && finalArray?.length
                    ? <Button
                      onClick={handleTradeBlotterClick}
                      startIcon={<BsBarChartLine size={16} color='gray' />}
                    >
                      Blotter
                    </Button>
                    : ''}
                  {finalArray.length
                    ? <>
                      {checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL, id: null, component_name: moduleConfig.DOWNLOAD_TRANSACTION })
                        ? <DownloadTransButton />
                        : ''}
                      {
                        checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL, id: null, component_name: moduleConfig.APPROVAL1 }) || checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL, id: null, component_name: moduleConfig.APPROVAL2 })
                          ? isLoading
                            ? (
                              <LoadingButton
                                loading
                                loadingPosition='start'
                                startIcon={<SaveIcon />}
                                variant='outlined'
                                sx={{
                                  fontSize: '16px',
                                  width: '145px'
                                }}
                              >
                                Approve
                              </LoadingButton>
                            )
                            : (
                              <Button
                                className='tardeApprove'
                                variant='contained'
                                onClick={(event) => handleApprove(event)}
                                startIcon={<img src={approveIcon} alt='approve' />}
                              >
                                Approve
                              </Button>)
                          : ''
                      }
                    </>
                    : ''}
                  <IconButton
                    onClick={openFilters}
                    sx={{
                      padding: 1,
                      borderRadius: '10px',
                      border: '2px solid #dee2e6',
                      marginLeft: '8px'
                    }}
                  >
                    <img src={filterIcon} alt='filter' height={20} width={20} />
                  </IconButton>
                </>
              ) : ''
            }
            <Button
              variant='outlined'
              onClick={handleRefreshClick}
              sx={{
                border: '2px solid #dee2e6',
                padding: '8px',
                minWidth: 'auto',
                ':hover': {
                  background: 'transparent',
                  border: '2px solid #dee2e6'
                },
                '&.Mui-disabled': {
                  border: '2px solid #dee2e6',
                }
              }}
              disabled={loading}
            >
              <RefreshOutlinedIcon fontSize='small' sx={{ color: '#74788D' }} />
            </Button>
          </Box>
        </Box>
        <Box className='tradeMainbody'>
          {
            loading
              ? (
                <Box className='tradetable'>
                  <TradeApprovalsHeaderMain />
                  <ul>
                    {[...Array(4)]?.map((e, i) => (
                      <Skeleton key={i} height={90} width={2700} />
                    ))}
                  </ul>
                </Box>
                )
              : (
                <>
                  {/* Display collapsible rows for trade list */}
                  {tradeArray.length && (location?.state?.policyName || location?.state?.tradeData?.length)
                    ? <Chip
                        sx={{
                          mb: 1,
                          borderRadius: '5px',
                          background: 'rgba(116,120,141,.102)',
                          color: '#74788D'
                        }}
                        size='small'
                        label={location?.state?.filterName ? location?.state?.filterName : ''}
                        className='chip'
                      />
                    : ''}
                  {
                    tradeArray.length && selectedFilters.length
                      ? <>
                        {
                          selectedFilters.map((filterObj, index) => {
                            if (filterObj.name === 'zoomedAccountIds') {
                              return (
                                <div key={index} style={{ display: 'inline-block' }}>
                                  {
                                    chartLabelData?.map((chartLabel, chartIndex) => (
                                      <Chip
                                        key={chartIndex}
                                        sx={{
                                          ml: 1,
                                          mb: 1,
                                          borderRadius: '5px',
                                          background: 'rgba(116,120,141,.102)',
                                          color: '#74788D',
                                          '.MuiChip-icon': {
                                            mx: 0
                                          }
                                        }}
                                        size='small'
                                        icon={
                                          <IconButton
                                            onClick={() => {
                                              removeFilter(chartLabel, 'zoomedAccountIds')
                                            }}
                                            sx={{
                                              borderRadius: '0px',
                                              borderRight: '0.5px solid #74788D40'
                                            }}
                                          >
                                            <img src={closeIcon} alt='' height={8} width={8} />
                                          </IconButton>
                                        }
                                        label={chartLabel.label}
                                        className='chip'
                                      />
                                    ))
                                  }
                                </div>
                              )
                            } else if (filterObj.name === 'strategy') {
                              return (
                                <div key={index} style={{ display: 'inline-block' }}>
                                  {filterObj.value?.map((strategy) => (
                                    <Chip
                                      key={strategy.straegyId}
                                      sx={{
                                        ml: 1,
                                        mb: 1,
                                        borderRadius: '5px',
                                        background: 'rgba(116,120,141,.102)',
                                        color: '#74788D',
                                        '.MuiChip-icon': {
                                          mx: 0
                                        }
                                      }}
                                      size='small'
                                      icon={
                                        <IconButton
                                          onClick={() => {
                                            removeFilter(strategy, 'strategy')
                                          }}
                                          sx={{
                                            borderRadius: '0px',
                                            borderRight: '0.5px solid #74788D40'
                                          }}
                                        >
                                          <img src={closeIcon} alt='' height={8} width={8} />
                                        </IconButton>
                                      }
                                      label={`Strategy : ${strategy.strategyName}`}
                                      className='chip'
                                    />
                                  ))}
                                </div>
                              )
                            } else if (filterObj.name === 'tradeStatus') {
                              return (
                                <div key={index} style={{ display: 'inline-block' }}>
                                  {filterObj.value?.map((tradeStatus) => (
                                    <Chip
                                      key={tradeStatus.id}
                                      sx={{
                                        ml: 1,
                                        mb: 1,
                                        borderRadius: '5px',
                                        background: 'rgba(116,120,141,.102)',
                                        color: '#74788D',
                                        '.MuiChip-icon': {
                                          mx: 0
                                        }
                                      }}
                                      size='small'
                                      icon={
                                        <IconButton
                                          onClick={() => {
                                            removeFilter(tradeStatus, 'tradeStatus')
                                          }}
                                          sx={{
                                            borderRadius: '0px',
                                            borderRight: '0.5px solid #74788D40'
                                          }}
                                        >
                                          <img src={closeIcon} alt='' height={8} width={8} />
                                        </IconButton>
                                      }
                                      label={`${tradeStatus.name}`}
                                      className='chip'
                                    />
                                  ))}
                                </div>
                              )
                            } else if (filterObj.name === 'taxStatus') {
                              return (
                                <div key={index} style={{ display: 'inline-block' }}>
                                  {filterObj.value?.map((taxStatus, taxIndex) => (
                                    <Chip
                                      key={taxIndex}
                                      sx={{
                                        ml: 1,
                                        mb: 1,
                                        borderRadius: '5px',
                                        background: 'rgba(116,120,141,.102)',
                                        color: '#74788D',
                                        '.MuiChip-icon': {
                                          mx: 0
                                        }
                                      }}
                                      size='small'
                                      icon={
                                        <IconButton
                                          onClick={() => {
                                            removeFilter(taxStatus, 'taxStatus')
                                          }}
                                          sx={{
                                            borderRadius: '0px',
                                            borderRight: '0.5px solid #74788D40'
                                          }}
                                        >
                                          <img src={closeIcon} alt='' height={8} width={8} />
                                        </IconButton>
                                      }
                                      label={`${taxStatus}`}
                                      className='chip'
                                    />
                                  ))}
                                </div>
                              )
                            } else if (filterObj.name === 'trackingError') {
                              return (
                                <div key={index} style={{ display: 'inline-block' }}>
                                  <Chip
                                    key={index}
                                    sx={{
                                      ml: 1,
                                      mb: 1,
                                      borderRadius: '5px',
                                      background: 'rgba(116,120,141,.102)',
                                      color: '#74788D',
                                      '.MuiChip-icon': {
                                        mx: 0
                                      }
                                    }}
                                    size='small'
                                    icon={
                                      <IconButton
                                        onClick={() => {
                                          removeFilter(filterObj.value, 'trackingError')
                                        }}
                                        sx={{
                                          borderRadius: '0px',
                                          borderRight: '0.5px solid #74788D40'
                                        }}
                                      >
                                        <img src={closeIcon} alt='' height={8} width={8} />
                                      </IconButton>
                                    }
                                    label={`Tracking Error Improvement >= ${filterObj.value} bps`}
                                    className='chip'
                                  />
                                </div>
                              )
                            } else if (filterObj.name === 'tradingAge') {
                              return (
                                <div key={index} style={{ display: 'inline-block' }}>
                                  <Chip
                                    key={index}
                                    sx={{
                                      ml: 1,
                                      mb: 1,
                                      borderRadius: '5px',
                                      background: 'rgba(116,120,141,.102)',
                                      color: '#74788D',
                                      '.MuiChip-icon': {
                                        mx: 0
                                      }
                                    }}
                                    size='small'
                                    icon={
                                      <IconButton
                                        onClick={() => {
                                          removeFilter(filterObj.value, 'tradingAge')
                                        }}
                                        sx={{
                                          borderRadius: '0px',
                                          borderRight: '0.5px solid #74788D40'
                                        }}
                                      >
                                        <img src={closeIcon} alt='' height={8} width={8} />
                                      </IconButton>
                                    }
                                    label={`Trading Age >= ${filterObj.value} days`}
                                    className='chip'
                                  />
                                </div>
                              )
                            } else if (filterObj.name === 'isModelChange') {
                              return (
                                <div key={index} style={{ display: 'inline-block' }}>
                                  <Chip
                                    key={index}
                                    sx={{
                                      ml: 1,
                                      mb: 1,
                                      borderRadius: '5px',
                                      background: 'rgba(116,120,141,.102)',
                                      color: '#74788D',
                                      '.MuiChip-icon': {
                                        mx: 0
                                      }
                                    }}
                                    size='small'
                                    icon={
                                      <IconButton
                                        onClick={() => {
                                          removeFilter(filterObj.value, 'isModelChange')
                                        }}
                                        sx={{
                                          borderRadius: '0px',
                                          borderRight: '0.5px solid #74788D40'
                                        }}
                                      >
                                        <img src={closeIcon} alt='' height={8} width={8} />
                                      </IconButton>
                                    }
                                    label={`Model Change: ${filterObj.value ? 'Yes' : 'No'}`}
                                    className='chip'
                                  />
                                </div>
                              )
                            } else if (filterObj.name === 'delist') {
                              return (
                                <div key={index} style={{ display: 'inline-block' }}>
                                  {filterObj.value?.map((item, chipIndex) => (
                                    <Chip
                                      key={chipIndex}
                                      sx={{
                                        ml: 1,
                                        mb: 1,
                                        borderRadius: '5px',
                                        background: 'rgba(116,120,141,.102)',
                                        color: '#74788D',
                                        '.MuiChip-icon': {
                                          mx: 0
                                        }
                                      }}
                                      size='small'
                                      icon={
                                        <IconButton
                                          onClick={() => {
                                            removeFilter(item, 'delist')
                                          }}
                                          sx={{
                                            borderRadius: '0px',
                                            borderRight: '0.5px solid #74788D40'
                                          }}
                                        >
                                          <img src={closeIcon} alt='' height={8} width={8} />
                                        </IconButton>
                                      }
                                      label={`Delist: ${item.instrId}`}
                                      className='chip'
                                    />
                                  ))}
                                </div>
                              )
                            } else if (filterObj.name === 'taxCostBenefit') {
                              return (
                                <div key={index} style={{ display: 'inline-block' }}>
                                  <Chip
                                    key={index}
                                    sx={{
                                      ml: 1,
                                      mb: 1,
                                      borderRadius: '5px',
                                      background: 'rgba(116,120,141,.102)',
                                      color: '#74788D',
                                      '.MuiChip-icon': {
                                        mx: 0
                                      }
                                    }}
                                    size='small'
                                    icon={
                                      <IconButton
                                        onClick={() => {
                                          removeFilter(filterObj.value, 'taxCostBenefit')
                                        }}
                                        sx={{
                                          borderRadius: '0px',
                                          borderRight: '0.5px solid #74788D40'
                                        }}
                                      >
                                        <img src={closeIcon} alt='' height={8} width={8} />
                                      </IconButton>
                                    }
                                    label={`Tax Cost Benefit <= ${formatCurrencyWithSymbol(filterObj.value, 0, '$')}`}
                                    className='chip'
                                  />
                                </div>
                              )
                            } else return (<Fragment key={index} />)
                          })
                        }

                      </>
                      : null
                  }
                  {
                    tradeArray.length && legendState?.selectedScenario && legendState?.selectedScenario?.length
                      ? legendState.selectedScenario.filter(scenario => scenarioMappingWithLegend[scenario]).map((scenario, index) => (
                        <div key={index} style={{ display: 'inline-block' }}>
                          <Chip
                            sx={{
                              ml: 1,
                              mb: 1,
                              borderRadius: '5px',
                              background: 'rgba(116,120,141,.102)',
                              color: '#74788D',
                              '.MuiChip-icon': {
                                mx: 0
                              }
                            }}
                            size='small'
                            icon={
                              <IconButton
                                onClick={() => {
                                  removeLegendFilter(scenario)
                                }}
                                sx={{
                                  borderRadius: '0px',
                                  borderRight: '0.5px solid #74788D40'
                                }}
                              >
                                <img src={closeIcon} alt='' height={8} width={8} />
                              </IconButton>
                            }
                            label={scenarioMappingWithLegend[scenario]}
                            className='chip'
                          />
                        </div>
                      ))
                      : ''
                  }
                  {finalArray.length > 0
                    ? (
                      <>
                        {isLoading ? <Loader /> : ''}
                        <TradeLegacyViewTable
                          apiRef={apiRef}
                          dataArray={finalArray || []}
                          updatePendingApprovalIds={updatePendingApprovalIds}
                          allScenarioList={allScenarioList}
                          legendState={(legendState?.selectedScenario && legendState?.selectedScenario?.length) ? legendState : {}}
                          scenarioMappingWithLegend={scenarioMappingWithLegend}
                          setTradeDatagridState={setTradeDatagridState}
                          tradeDatagridState={tradeDatagridState}
                          filterModel={filterModel}
                          setFilterModel={setFilterModel}
                        />
                      </>
                      )
                    : (
                      <>
                        {/* No account available for approval case */}
                        <Grid container sx={{ my: '20px' }}>
                          <Grid item xs={12}>
                            <Card>
                              <CardContent>
                                <Box className='table-responsive'>
                                  No account available for approval.
                                </Box>
                              </CardContent>
                            </Card>
                          </Grid>
                        </Grid>
                      </>
                      )}
                </>
                )
          }
          <TradeApprovalFilterMenu
            optmRun={optmRun}
            showFilters={showFilters}
            closeFilters={closeFilters}
            removeAll={removeAllFilters}
            tradeStatusFilters={tradeStatusFilters}
            tradeArray={tradeArray}
            getChartLabel={handleChartLabel}
            updateLegends={handleLegendsClick}
            appliedLegend={legendState}
            resetLegends={resetLegends}
            strategies={strategies}
            applyFilter={applyFilter}
            selectedFilters={selectedFilters}
            isDefaultScenarioChecked={isDefaultChecked}
            scenarioMappingWithLegend={scenarioMappingWithLegend}
            delistData={delistData}
          />
        </Box>
        <Dialog
          open={openTradeErrorModal}
          onClose={handleCloseTradeErrorModal}
          fullWidth
          maxWidth='md'
        >
          <DialogTitle>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant='h6' component='h2'>
                Policy Check
              </Typography>
              <IconButton aria-label='close' onClick={handleCloseTradeErrorModal}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box
              sx={{
                '& .MuiDataGrid-columnHeaderTitle': {
                  color: '#74788D'
                },
                '& .MuiDataGrid-footerContainer': {
                  border: 'none'
                },
                '& .MuiDataGrid-root': {
                  border: 'none'
                },
                '& .MuiTablePagination-toolbar': {
                  alignItems: 'baseline'
                }
              }}
            >
              <DataGridPro
                autoHeight
                rows={tradeErrorRows}
                columns={tradeErrorColumns}
                density='compact'
                getDetailPanelContent={getDetailPanelContent}
                getDetailPanelHeight={getDetailPanelHeight}
                pagination
                pageSizeOptions={[10]}
                disableRowSelectionOnClick
                slots={{
                  detailPanelExpandIcon: ExpandMoreIcon,
                  detailPanelCollapseIcon: ExpandLessIcon
                }}
                initialState={{
                  ...tradeErrorRows?.initialState,
                  pagination: { paginationModel: { pageSize: 10 } }
                }}
                sx={(theme) => ({
                  '&.MuiDataGrid-root': {
                    height: 450,
                    overflowY: 'auto',
                    scrollbarGutter: 'stable',
                    scrollbarWidth: 'none'
                  },
                  [`.${gridClasses.main}`]: {
                    overflow: 'unset'
                  },
                  [`.${gridClasses.columnHeaders}`]: {
                    position: 'sticky',
                    top: 0,
                    backgroundColor: theme.palette.background.paper,
                    zIndex: 3
                  },
                  [`.${gridClasses.footerContainer}`]: {
                    position: 'sticky',
                    bottom: 0,
                    backgroundColor: theme.palette.background.paper,
                    zIndex: 2
                  },
                  [`.${gridClasses.toolbarContainer}`]: {
                    position: 'relative',
                    top: 0,
                    backgroundColor: theme.palette.background.paper,
                    zIndex: 1
                  }
                })}
              />
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    </>
  )
}

export default TradeApprovalsMain
