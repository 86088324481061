import { useRef } from 'react'
import Chart from 'react-apexcharts'
import moveSVGIcon from '../../assets/images/move.svg'
import homeSVGIcon from '../../assets/images/home.svg'
import zoomSVGIcon from '../../assets/images/zoom-in.svg'
import exportMenuSVGIcon from '../../assets/images/export-menu.svg'

const TaxPerformanceChart = ({ data, resetKey }) => {
  const chartRef = useRef(null)

  const preTaxSeries = {
    name: 'Pre Tax Returns',
    data: data?.map((point) => ({ x: point?.x, y: point?.y !== null ? (point?.y || 0) * 100 : point?.y }))
  }

  const postTaxSeries = {
    name: 'Post Tax Returns',
    data: data?.find((point) => point?.postTaxReturns !== null)
      ? data?.map((point) => ({
        x: point?.x,
        y: point?.postTaxReturns !== null ? (point?.postTaxReturns || 0) * 100 : point?.postTaxReturns
      }))
      : []
  }

  const series = data?.find((point) => point?.postTaxReturns !== null) ? [preTaxSeries, postTaxSeries] : [preTaxSeries]
  const maxY = series?.length ? Math.max(...series?.flatMap(point => point.data.flatMap(obj => obj.y))) : null

  const options = {
    xaxis: {
      type: 'datetime',
      tooltip: {
        enabled: true
      }
    },
    yaxis: [
      {
        labels: {
          formatter: function (value) {
            return value?.toFixed(2)
          }
        },
        ...{ ...maxY !== null ? { max: maxY } : {} },
        tickAmount: 10

      }
    ],
    markers: {
      size: 0
    },
    dataLabels: {
      enabled: false
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return ''
      }
    },
    chart: {
      offsetX: -15,
      animations: {
        enabled: false
      },
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: -35,
        tools: {
          download: `<img src="${exportMenuSVGIcon}" />`,
          selection: true,
          zoom: `<img src="${zoomSVGIcon}" />`,
          zoomin: false,
          zoomout: false,
          pan: `<img src="${moveSVGIcon}" />`,
          reset: `<img src="${homeSVGIcon}" />`
        },
        export: {
          csv: {
            filename: undefined,
            columnDelimiter: ',',
            headerCategory: 'Date',
            headerValue: 'value',
            dateFormatter (timestamp) {
              const date = new Date(timestamp)
              const offset = date.getTimezoneOffset()
              const adjustedDate = new Date(date.getTime() + offset * 60000)
              const formattedDate = `${adjustedDate.getUTCFullYear()}-${(adjustedDate.getUTCMonth() + 1).toString().padStart(2, '0')}-${date.getUTCDate().toString().padStart(2, '0')}`
              return formattedDate
            }
          }
        }
      }
    },
    legend: {
      show: true, // Show legend only if postTax data is present
      showForSingleSeries: true,
      position: 'left',
      horizontalAlign: 'left',
      floating: true,
      fontSize: '12px',
      offsetX: 40,
      offsetY: -10,
      markers: {
        width: 15,
        height: 2,
        radius: 0,
        offsetY: -4
      },
      tooltipHoverFormatter: function (seriesName, opts) {
        if (opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] >= 0) {
          return seriesName + ' - <span style="color: green;font-weight:600;">' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex]?.toFixed(2) + ' %' + '</span>'
        }
        return seriesName + ' - <span style="color: red;font-weight:600;">' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex]?.toFixed(2) + ' %' + '</span>'
      },
      onItemClick: {
        toggleDataSeries: false
      }
    },
    stroke: {
      curve: 'straight',
      width: 1.8
    },
    colors: ['#002A59', '#33A68D'],
    noData: {
      text: 'No performance data available',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: '18px',
        fontFamily: undefined
      }
    },
    grid: {
      borderColor: '#74788d33',
      position: 'back'
    }
  }

  return <Chart ref={chartRef} options={options} series={series} type='line' height='100%' width='100%' />
}

export default TaxPerformanceChart
