import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { API } from 'aws-amplify'
import { Close, Save } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Autocomplete, Box, Button, Grid, IconButton, Modal, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import { randomId } from '@mui/x-data-grid-generator'
import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import * as Sentry from '@sentry/react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc' // Import the UTC plugin
import { ErrorMessage, Field, Formik } from 'formik'
import propTypes from 'prop-types'
import * as Yup from 'yup'
import { useAuth } from '../../../contexts/AuthContext'
import { useErrorToast } from '../../../hooks/useErrorToast'
import { useSuccessToast } from '../../../hooks/useSuccessToast'
import { formatCurrency } from '../../../utils/FormateCurrenyInMilion'
import { moduleConfig } from '../../../contexts/data'
import { ACCESS_LEVEL } from '../../../contstants/constants'
import { checkInstrumentSearchQuery } from '../../../utils/searchQueryUtils'
import Loader from '../../Loader'
import LivePriceButton from '../components/LivePriceButton'
import StyledTradePopupBox from '../components/StyledTradePopupBox'
import CustomActionButton from './CustomActionButton'

dayjs.extend(utc)

const corporateModalStyle = {
  boxSizing: 'border-box',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  px: 2,
  py: 2,
  width: '100%',
  minWidth: '600px',
  maxWidth: '900px',
  borderRadius: '4px',
  '.MuiDataGrid-root': {
    border: 'none'
  },
  '.MuiDataGrid-main': {
    // remove overflow hidden overwise sticky does not work
    overflow: 'unset'
  },
  '.MuiDataGrid-columnHeaders': {
    position: 'sticky',
    top: '-4px',
    zIndex: 99,
    background: 'white'
  },
  '.MuiDataGrid-virtualScroller': {
    // remove the space left for the header
    marginTop: '-4px!important'
  }
}

const renderTableSkeleton = (header, hiddenColumns) => {
  if (hiddenColumns) { header = header.filter(col => !hiddenColumns.includes(col.field)) }
  return (
    <TableContainer mt={5}>
      <Table className='risk-page-table'>
        <TableHead>
          <TableRow>
            {header.map((item, index) => {
              return (
                <TableCell key={index}>{item.headerName}</TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.from({ length: 5 }).map((_, index) => (
            <TableRow key={index}>
              {Array.from({ length: header.length }).map((_, index) => (
                <TableCell key={index}>
                  <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
                </TableCell>))}
            </TableRow>))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const renderDataGridPro = (rowData, cols, getRowId, columnVisibility, sortModel, extraProps, styleProps) => {
  return (
    <DataGridPro
      density='compact'
      autoHeight
      rows={rowData || []}
      columns={cols}
      disableRowSelectionOnClick
      getRowId={getRowId}
      pagination
      pageSizeOptions={[20]}
      initialState={{
        ...rowData?.initialState,
        pagination: { paginationModel: { pageSize: 20 } },
        sorting: {
          sortModel: sortModel || []
        },
        columns: {
          columnVisibilityModel:
            columnVisibility || {}
        }
      }}
      {...extraProps}
      sx={(theme) => ({
        ...styleProps,
        '& .MuiDataGrid-cell:focus': {
          outline: 'none'
        },
        [`.${gridClasses.main}`]: {
          overflow: 'unset'
        },
        [`.${gridClasses.columnHeaders}`]: {
          position: 'sticky',
          backgroundColor: theme.palette.background.paper,
          top: 0,
          zIndex: 1
        },
        [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
          color: '#74788d',
          fontWeight: 600
        },
        [`.${gridClasses.footerContainer}`]: {
          position: 'sticky',
          bottom: '-1px',
          backgroundColor: theme.palette.background.paper,
          zIndex: 1
        }
      })}
    />
  )
}

const SplitsTable = ({ rows, getUpdatedRows, fetchSplitsActions }) => {
  const { user, checkAccess } = useAuth()
  const { showError } = useErrorToast()
  const { showSuccess } = useSuccessToast()
  const [splitsData, setSplitsData] = useState({ loading: false, data: [] })
  const [whichModal, setWhichModal] = useState({ name: '', open: false })
  const [modalData, setModalData] = useState({ loading: false, data: [] })
  const [showLoader, setShowLoader] = useState(false)
  const [rowSelectionModel, setRowSelectionModel] = useState([])
  const [selectedRow, setSelectedRow] = useState(null)
  const [selectedAccountPopup, setSelectedAccountPopup] = useState('')
  const navigate = useNavigate()

  const canAccessLivePrice = checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS,
    { subModuleName: moduleConfig.TRADE_APPROVAL, component_name: moduleConfig.VIEW_LIVE_PRICE })

  const splitTableColumns = [
    { field: 'instrId', headerName: 'Instrument Id', flex: 1 },
    { field: 'localSymbol', headerName: 'Local Symbol', flex: 1 },
    { field: 'instrName', headerName: 'Instrument Name', flex: 1 },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => {
        const data = params?.row?.status
        return <><Box sx={{ borderRadius: '20px', fontSize: '10px', px: '10px', py: '2px', ...getColorProperties(data), fontWeight: 600 }}>{data}</Box></>
      }
    },
    { field: 'announcementDate', headerName: 'Announcement Date', flex: 1, headerAlign: 'right', align: 'right', valueFormatter: (params) => params.value ? dayjs.utc(params.value).format('YYYY-MM-DD') : 'NA' },
    {
      field: 'lastClosePrice',
      headerName: 'Last Closed Price',
      flex: 1,
      headerAlign: 'right',
      align: 'right',
      renderCell: (params) => params?.row?.lastClosePrice !== null && params?.row?.lastClosePrice !== undefined
        ? params?.row?.lastClosePrice < 0
          ? `-$${formatCurrency(params?.row?.lastClosePrice, 2)}`
          : `$${formatCurrency(params?.row?.lastClosePrice, 2)}`
        : 'N/A'
    },
    {
      field: 'priceSplitsStatus',
      headerName: 'Price Splits Status',
      flex: 1,
      renderCell: (params) => <Box sx={{ borderRadius: '20px', fontSize: '10px', px: '10px', py: '2px', ...getColorProperties(params?.row?.priceSplitsStatus), fontWeight: 600 }}>{params?.row?.priceSplitsStatus}</Box>
    },
    { field: 'adjustmentFactor', headerName: 'Adjustment Factor', type: 'number', flex: 1, headerAlign: 'right', align: 'right' },
    {
      field: 'liveMarketPrice',
      headerName: 'Live Price',
      flex: 1,
      headerAlign: 'right',
      align: 'right',
      valueGetter: (params) => params?.value ? parseFloat(params?.value?.toFixed(2)) : params?.value,
      renderCell: (params) => (
        <Tooltip title={
          params?.row?.livePriceTs
            ? `last updated: ${dayjs(params?.row?.livePriceTs).format('YYYY-MM-DD HH:mm:ss')}`
            : ''
        }
          placement='top'
        >
          {
            params?.row?.liveMarketPrice !== undefined
              ? params?.row?.liveMarketPrice !== null
                ? params?.row?.liveMarketPrice < 0
                  ? '-$' + Math.abs(parseFloat(params?.value?.toFixed(2)))?.toLocaleString()
                  : '$' + parseFloat(params?.value?.toFixed(2))?.toLocaleString()
                : ''
              : ''
          }
        </Tooltip>
      )
    },
    {
      field: 'pendingAccounts',
      headerName: 'Pre Trade Accounts',
      width: 150,
      headerAlign: 'right',
      align: 'right',
      renderCell: (params) => {
        return (
          <Box onClick={(e) => params?.row?.pendingAccounts?.length > 0 ? handleSplitsCountClick(e, params?.row, 'Pre Trade Accounts') : ''}>
            <Typography sx={{
              color: '#0066C7',
              fontWeight: 600,
              fontFamily: 'Open Sans',
              cursor: params?.row?.pendingAccounts?.length > 0 ? 'pointer' : 'not-allowed'
            }}
            >
              {params?.row?.pendingAccounts?.length ?? 0}
            </Typography>
          </Box>
        )
      }
    },
    {
      field: 'proposedAccounts',
      headerName: 'Proposed Accounts',
      width: 150,
      headerAlign: 'right',
      align: 'right',
      renderCell: (params) => {
        return (
          <Box onClick={(e) => params?.row?.proposedAccounts?.length > 0 ? handleSplitsCountClick(e, params?.row, 'Proposed Accounts') : ''}>
            <Typography sx={{
              color: '#0066C7',
              fontWeight: 600,
              fontFamily: 'Open Sans',
              cursor: params?.row?.proposedAccounts?.length > 0 ? 'pointer' : 'not-allowed'
            }}
            >
              {params?.row?.proposedAccounts?.length ?? 0}
            </Typography>
          </Box>
        )
      }
    },
    {
      field: 'models',
      headerName: 'Models',
      headerAlign: 'right',
      align: 'right',
      flex: 1,
      renderCell: (params) => {
        return (
          <Box onClick={(e) => params?.row?.models?.length ? handleSplitsCountClick(e, params?.row, 'Models') : ''}>
            <Typography sx={{
              color: '#0066C7',
              fontWeight: 600,
              fontFamily: 'Open Sans',
              cursor: params?.row?.models?.length > 0 ? 'pointer' : 'not-allowed'
            }}
            >
              {params?.row?.models?.length ?? 0}
            </Typography>
          </Box>
        )
      }
    },
    {
      field: 'effectiveDate',
      headerName: 'Effective Date',
      headerAlign: 'right',
      align: 'right',
      flex: 1,
      type: 'date',
      valueGetter: (params) => {
        if (!params?.value) return params?.value
        const date = new Date(params?.value)
        return new Date(date?.getTime() + date?.getTimezoneOffset() * 1000 * 60)
      },
      renderCell: (params) => params?.row?.effectiveDate ? params?.row?.effectiveDate?.split('T')[0] : ''
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      maxWidth: 80,
      align: 'center',
      renderCell: (params) => (
        params.row ? <CustomActionButton params={params} handleShowModal={handleShowModal} name='splits' /> : ''
      )
    }
  ]

  const [accountListModalColumns] = useState([
    { field: 'accountId', headerName: 'Account Id', flex: 1 },
    { field: 'accountName', headerName: 'Account Name', flex: 1 },
    { field: 'accountCd', headerName: 'Account Code', flex: 1 }
  ])

  const [currentAccountsColumns] = useState([
    { field: 'accountId', headerName: 'Account Id', flex: 1 },
    { field: 'accountName', headerName: 'Account Name', flex: 1 },
    { field: 'accountCd', headerName: 'Account Code', flex: 1 },
    {
      field: 'status',
      headerName: 'Applied',
      flex: 1,
      renderCell: (params) => params?.row?.status ? 'Completed' : 'Pending'
    }
  ])

  const [strategyListModalColumns] = useState([
    { field: 'strategyId', headerName: 'Strategy Id', flex: 1 },
    { field: 'strategyName', headerName: 'Strategy Name', flex: 1 },
    { field: 'strategyCd', headerName: 'Strategy Code', flex: 1 }
  ])

  useEffect(() => {
    setSplitsData({
      loading: rows.loading,
      data: !rows.loading && rows.data ? rows?.data?.splits?.map(obj => ({ ...obj, id: randomId() })) : []
    })
  }, [rows])

  const handleSplitsCountClick = (e, row, column) => {
    setSelectedRow(row)
    setWhichModal({ name: 'Accounts', open: true })
    if (column === 'Pre Trade Accounts') {
      setSelectedAccountPopup('Pre Trade Accounts')
      setModalData({ loading: false, data: row?.pendingAccounts?.map(obj => ({ ...obj, id: randomId() })) })
    } else if (column === 'Proposed Accounts') {
      setSelectedAccountPopup('Proposed Accounts')
      setModalData({ loading: false, data: row?.proposedAccounts?.map(obj => ({ ...obj, id: randomId() })) })
    } else if (column === 'Models') {
      setWhichModal({ name: 'Models', open: true })
      setModalData({ loading: false, data: row?.models?.map(obj => ({ ...obj, id: randomId() })) })
    }
  }

  const fetchAccountsAndStrategiesList = async (modal, row) => {
    setModalData({ loading: true, data: [] })
    API.get(
      'baseUriCorporate',
      `corporate-action/v1/${user?.userGroup}/split-instr/acc-list/${row.instrId}`,
      user?.userGroup === 'adv-classic'
        ? {
            queryStringParameters: {
              resources: encodeURIComponent(JSON.stringify({ serviceId: 'trade', resourceId: 'mandatory-trades' }))
            }
          }
        : {}
    )
      .then((response) => {
        if (response.success) {
          if (modal === 'Accounts') {
            setModalData({ loading: false, data: response?.data })
          }
        }
      })
      .catch(error => {
        setModalData({ loading: false, data: [] })
        showError(error?.response?.data?.errorInfo?.userMessage || error?.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
  }

  const getColorProperties = (data) => {
    if (data === 'APPLIED') {
      return {
        color: '#446b60',
        backgroundColor: '#3BBFA399',
        border: '1px solid #446b6022'
      }
    } else if (data === 'PENDING') {
      return {
        color: '#aaab29',
        backgroundColor: '#fbfa6c99',
        border: '1px solid #aaab2922'
      }
    } else if (data === 'PARTIALLY COMPLETED') {
      return {
        color: '#816f36',
        backgroundColor: '#d2992299',
        border: '1px solid #816f3622'
      }
    } else if (data === 'NOT APPLICABLE') {
      return {
        color: '#969696',
        backgroundColor: '#C0C0C099',
        border: '1px solid #96969622'
      }
    } else {
      return {
        color: '#969696',
        backgroundColor: '#C0C0C099',
        border: '1px solid #96969622'
      }
    }
  }

  const deleteSplit = async (row) => {
    setShowLoader(true)
    API.patch(
      'baseUriCorporate',
      `corporate-action/v1/${user.userGroup}/instruments/splits-cal/${row.announcementDate.split('T')[0]}/${row.instrId}/${row.startDate.split('T')[0]}/`,
      {
        queryStringParameters: {
          corporate: true,
          'instr-splits-status': row?.status
        },
        body: {
          ratio: row.ratio,
          effectiveDate: row.effectiveDate.split('T')[0],
          adjustmentFactor: row.adjustmentFactor,
          endDate: dayjs().format('YYYY-MM-DD')
        }
      }
    )
      .then(response => {
        if (response.success) {
          showSuccess(response.message)
          getUpdatedRows({ loading: false, data: { splits: splitsData.data.filter(obj => row.id !== obj.id) } })
          setShowLoader(false)
        }
      })
      .catch(error => {
        setShowLoader(false)
        showError(error?.response?.data?.errorInfo?.userMessage || error?.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
  }

  const applySplit = async (row) => {
    setShowLoader(true)
    API.post(
      'baseUriCorporate',
      `corporate-action/v1/${user.userGroup}/instruments/apply-split/`,
      {
        body: {
          instrId: row?.instrId,
          effectiveDate: row?.effectiveDate?.split('T')[0]
        }
      }
    )
      .then(response => {
        if (response.success) {
          showSuccess(response.message)
          fetchSplitsActions()
          setShowLoader(false)
        }
      })
      .catch(error => {
        setShowLoader(false)
        showError(error?.response?.data?.errorInfo?.userMessage || error?.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
  }

  const removeSplit = async (row) => {
    setShowLoader(true)
    API.post(
      'baseUriCorporate',
      `corporate-action/v1/${user.userGroup}/instruments/remove-split`,
      {
        body: {
          instrId: row?.instrId,
          effectiveDate: row?.effectiveDate?.split('T')[0]
        }
      }
    )
      .then(response => {
        if (response.success) {
          showSuccess(response.message)
          fetchSplitsActions()
          setShowLoader(false)
        }
      })
      .catch(error => {
        setShowLoader(false)
        showError(error?.response?.data?.errorInfo?.userMessage || error?.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
  }

  const runOptimizationForAccounts = async () => {
    setShowLoader(true)
    const accountIds = new Set()
    for (let i = 0; i < splitsData.data.length; i++) {
      if (rowSelectionModel.includes(splitsData.data[i].id)) {
        if (Array.isArray(splitsData.data[i].currentAccounts) && splitsData.data[i].currentAccounts.length > 0) {
          splitsData?.data[i].currentAccounts?.map(accounts => accounts?.accountId).forEach(id => {
            accountIds.add(id)
          })
        }
      }
    }

    API.post(
      'baseOptimizationURL',
      `optimization/v1/${user?.userGroup}/run-optimization`,
      {
        body: {
          accountIds: Array.from(accountIds)
        }
      }
    )
      .then((response) => {
        if (response?.success) {
          showSuccess(response?.message)
        }
      })
      .catch(error => {
        showError(error?.response?.data?.errorInfo?.userMessage || error?.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => {
        setRowSelectionModel([])
        setShowLoader(false)
      })
  }

  const handleViewTradeClick = () => {
    navigate('/trade/trade-approvals', { state: { tradeData: modalData.data.map(obj => obj.accountCd), filterName: `Splits: ${selectedRow.instrId}` } })
  }

  const handleShowModal = (modal, row) => {
    // fetching same api on all button click maybe prefetch this on action click
    switch (modal) {
      case 'Accounts':
        if (user) {
          fetchAccountsAndStrategiesList(modal, row)
          setSelectedAccountPopup('Accounts')
          setWhichModal({ name: 'Accounts', open: true })
        }
        break
      case 'Delete Record':
        if (user) {
          deleteSplit(row)
        }
        break
      case 'Apply Split':
        if (user) {
          applySplit(row)
        }
        break
      case 'Withdraw Split':
        if (user) {
          removeSplit(row)
        }
        break
      default:
        setWhichModal({ name: '', open: false })
    }
  }

  const renderModal = () => {
    // render which modal to show
    switch (whichModal.name) {
      case 'Accounts':
        return (
          <Modal
            onClose={() => setWhichModal({ name: '', open: false })}
            open={whichModal.open}
          >
            <Box sx={{ ...corporateModalStyle, outline: 'none' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: '5px' }}>
                <Typography variant='h6' sx={{ ml: '5px', fontWeight: 400, color: '#34475A' }}>{selectedAccountPopup}</Typography>
                <IconButton aria-label='close' edge='end' onClick={() => setWhichModal({ name: '', open: false })}>
                  <Close />
                </IconButton>
              </Box>
              <StyledTradePopupBox
                className='table-responsive' sx={{
                  height: '600px',
                  scrollbarGutter: 'stable'
                }}
              >
                {
                modalData.loading
                  ? renderTableSkeleton(selectedAccountPopup === 'Accounts' ? currentAccountsColumns : accountListModalColumns, ['accountId'])
                  : selectedAccountPopup === 'Proposed Accounts'
                    ? renderDataGridPro(modalData.data, accountListModalColumns, (row) => row.id, { accountId: false })
                    : selectedAccountPopup === 'Accounts'
                      ? renderDataGridPro(modalData.data, currentAccountsColumns, (row) => row.accountId, { accountId: false })
                      : selectedAccountPopup === 'Pre Trade Accounts'
                        ? renderDataGridPro(modalData.data, accountListModalColumns, (row) => row.id, { accountId: false })
                        : ''
              }
              </StyledTradePopupBox>
              {
                // View Trades button will only be visible if checkAccess is true
                            checkAccess('trade-approvals', ACCESS_LEVEL.MODULE_ACCESS)
                              ? (
                                  selectedAccountPopup === 'Proposed Accounts' || selectedAccountPopup === 'Pre Trade Accounts'
                                    ? <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', mt: '5px' }}>
                                      <Button onClick={handleViewTradeClick} variant='contained' disabled={modalData.data.length === 0}>
                                        View Trades
                                      </Button>
                                    </Box>
                                    : ''
                                )
                              : (
                                  null
                                )
            }
            </Box>
          </Modal>
        )
      case 'Models':
        return (
          <Modal
            onClose={() => setWhichModal({ name: '', open: false })}
            open={whichModal.open}
          >
            <Box sx={{ ...corporateModalStyle }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant='h6' sx={{ ml: '5px', fontWeight: 400, color: '#34475A' }}>Models</Typography>
                <IconButton aria-label='close' edge='end' onClick={() => setWhichModal({ name: '', open: false })}>
                  <Close />
                </IconButton>
              </Box>
              <StyledTradePopupBox
                className='table-responsive' sx={{
                  height: '600px',
                  scrollbarGutter: 'stable'
                }}
              >
                {
                modalData?.loading
                  ? renderTableSkeleton(strategyListModalColumns, ['strategyId'])
                  : renderDataGridPro(modalData.data, strategyListModalColumns, (row) => row?.strategyId, { strategyId: false })
              }
              </StyledTradePopupBox>
            </Box>
          </Modal>
        )
      case 'Add Record':
        return (
          <>
            <Modal
              onClose={() => setWhichModal({ name: '', open: false })}
              open={whichModal.open}
            >
              <Box sx={{ ...corporateModalStyle, outline: 'none', maxWidth: '600px', minWidth: '400px', height: 'auto', maxHeight: '90%', overflow: 'auto' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: '5px', position: 'sticky', top: '-16px', backgroundColor: 'white', zIndex: 2 }}>
                  <Typography variant='h6' sx={{ ml: '5px', fontWeight: 400, color: '#34475A' }}>Add Splits</Typography>
                  <IconButton aria-label='close' edge='end' onClick={() => setWhichModal({ name: '', open: false })}>
                    <Close />
                  </IconButton>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                  <AddSplitsRecord closePopup={closeAddRecordPopup} fetchSplits={fetchSplitsActions} />
                </Box>
              </Box>
            </Modal>
          </>
        )
      default:
        return <></>
    }
  }

  const closeAddRecordPopup = () => {
    setWhichModal({ name: '', open: false })
  }

  const openAddRecordPopup = () => {
    setWhichModal({ name: 'Add Record', open: true })
  }

  return (
    <>
      {showLoader ? <Loader /> : ''}
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button variant='text' onClick={openAddRecordPopup}>
          + Add Record
        </Button>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {
            canAccessLivePrice ? (
              <LivePriceButton />
            ) : ''
          }
          <Button
            variant='contained'
            disabled={rows.loading || (rowSelectionModel.length === 0)}
            size='small'
            onClick={runOptimizationForAccounts}
          >
            Run Optimization
          </Button>
        </Box>
      </Box>
      <Box sx={{
        position: 'relative',
        overflowY: 'auto',
        scrollbarWidth: 'none',
        height: 'calc(100vh - 48px - 15px - 39px - 16px - 16px - 48px - 36.5px - 24px - 20px - 10px)'
      }}
      >
        {splitsData.loading
          ? renderTableSkeleton(splitTableColumns, ['localSymbol', 'instrName', 'announcementDate'])
          : renderDataGridPro(
            splitsData.data,
            splitTableColumns,
            (row) => row?.id,
            { localSymbol: false, instrName: false, announcementDate: false },
            [{ field: 'effectiveDate', sort: 'desc' }],
            {
              checkboxSelection: true,
              isRowSelectable: (params) => params?.row?.isActive && params?.row?.status !== 'NOT APPLICABLE' && params?.row?.currentAccounts?.length > 0 && dayjs(params?.row?.effectiveDate?.split('T')[0]).isSame(dayjs(), 'day'),
              rowSelectionModel,
              onRowSelectionModelChange: (newRowSelectionModel) => setRowSelectionModel(newRowSelectionModel)
            }
          )}
      </Box>
      {
        whichModal.open
          ? renderModal()
          : <></>
      }
    </>
  )
}

const AddSplitsRecord = ({ closePopup, fetchSplits }) => {
  const { user } = useAuth()
  const { showError } = useErrorToast()
  const { showSuccess } = useSuccessToast()
  const [instrumentIdOptions, setInstrumentIdOptions] = useState([])
  const [instrIdInput, setInstrIdInput] = useState('')
  const [isLoadingSource, setIsLoadingSource] = useState(false)

  const fetchInstrumentName = (values) => {
    if (checkInstrumentSearchQuery(values)) {
      setIsLoadingSource(true)
      API.get('baseUriTransactionalMaster', `transactional-master/v1/${user?.userGroup}/instruments`, {
        queryStringParameters: { search: values }
      })
        .then((response) => {
          if (response?.data) {
            setInstrumentIdOptions(response.data)
          }
        })
        .catch((error) => {
          showError(error?.response?.data?.errorInfo?.userMessage || error.message)
          Sentry.captureException(error?.response?.data?.errorInfo?.userMessage || error)
        })
        .finally(() => {
          setIsLoadingSource(false)
        })
    }
  }

  useEffect(() => {
    const id = setTimeout(() => {
      fetchInstrumentName(instrIdInput.trim())
    }, [300])
    return () => clearTimeout(id)
  }, [instrIdInput])

  const handleInstrumentIdChange = (e, newValue) => {
    setInstrIdInput(newValue)
  }

  const handleFormSubmit = (values) => {
    API.post(
      'baseInstrumentMaintainURL',
      `data-maintenance/v1/${user?.userGroup}/instruments/splits-cal`,
      {
        body: {
          instrId: values.instrId.instrId,
          localSymbol: values.instrId.localSymbol,
          startDate: values.startDate.format('YYYY-MM-DD'),
          endDate: values.endDate.format('YYYY-MM-DD'),
          effectiveDate: values.effectiveDate.format('YYYY-MM-DD'),
          announcementDate: values.announcementDate.format('YYYY-MM-DD'),
          ratio: values.ratio,
          adjustmentFactor: values.adjustmentFactor
        }
      }
    )
      .then((response) => {
        if (response.success && response?.data) {
          showSuccess(response?.message)
          closePopup()
          fetchSplits()
          values.resetForm()
        }
      })
      .catch((error) => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => {
        values.setSubmitting(false)
      })
  }

  const validationSchema = Yup.object({
    instrId: Yup.object().required('Select an instrument id'),
    localSymbol: Yup.string().required(''),
    ratio: Yup.string().trim().required('Ratio is required')
      .matches(/^\d+(.\d+)?:\d+(.\d+)?$/, 'Ratio format is not correct'),
    adjustmentFactor: Yup.number().required('Adjustment factor is required'),
    // all the dates are in dayjs format
    startDate: Yup.mixed().required('Start date is required')
      .test('valid', 'Start date must be same or later than today', value => {
        return value.isSame(dayjs().subtract(1, 'day'), 'day') || value.isAfter(dayjs().subtract(1, 'day'), 'day')
      }),
    announcementDate: Yup.mixed().required('Announcement date is required')
      .when('startDate', (startDate, schema) => {
        return schema.test('valid', 'Announcement date must be same or earlier than start date', value => {
          return value.isBefore(startDate, 'day') || value.isSame(startDate, 'day')
        })
      }),
    endDate: Yup.mixed().required('End date is required')
      .when(['startDate'], ([startDate], schema) => {
        return schema.test('valid', 'End date must be later than start date', value => {
          return value.isAfter(startDate, 'day')
        })
      }),
    effectiveDate: Yup.mixed().required('Effective date is required')
      .when(['startDate', 'endDate'], ([startDate, endDate], schema) => {
        return schema.test('not weekend', 'Effective must not be weekend', value => {
          return value.day() !== 0 && value.day() !== 6
        })
          .test('valid', 'Effective must be later than start date and earlier than end date', value => {
            return value.isAfter(startDate, 'day') && value.isBefore(endDate, 'day')
          })
      })
  })

  const handleAutocompleteChange = (e, newValue, props) => {
    if (newValue) {
      props.setFieldValue('localSymbol', newValue.localSymbol)
      props.setFieldValue('instrId', newValue)
    } else {
      props.setFieldValue('localSymbol', '')
      props.setFieldValue('instrId', '')
    }
  }

  const disableWeekends = (date) => {
    return date.day() === 0 || date.day() === 6
  }

  return (
    <Formik
      initialValues={{ instrId: '', localSymbol: '', startDate: dayjs(), endDate: dayjs().add(2, 'day'), announcementDate: dayjs(), ratio: '', adjustmentFactor: '', effectiveDate: dayjs().add(1, 'day') }}
      validateOnChange
      validationSchema={validationSchema}
      onSubmit={({ instrId, localSymbol, ratio, adjustmentFactor, startDate, endDate, announcementDate, effectiveDate }, { setSubmitting, resetForm }) => {
        handleFormSubmit({ instrId, localSymbol, ratio, adjustmentFactor, startDate, endDate, announcementDate, effectiveDate, setSubmitting, resetForm })
      }}
    >
      {(props) => (
        <form onSubmit={props.handleSubmit}>
          <Grid container spacing={2} direction='column'>
            <Grid item>
              <Field name='instrId'>
                {({ field }) => (
                  <Autocomplete
                    inputValue={instrIdInput}
                    value={field.value}
                    loading={isLoadingSource}
                    loadingText='Loading'
                    isOptionEqualToValue={(option, value) => option.instrId === value.instrId}
                    options={instrumentIdOptions}
                    getOptionLabel={(option) => option ? `${option?.instrId} (${option?.name})` : ''}
                    size='small'
                    renderInput={(params) => <TextField {...params} label='Instrument Id' variant='outlined' />}
                    onChange={(e, newValue) => handleAutocompleteChange(e, newValue, props)}
                    onBlur={(e) => setInstrumentIdOptions([])}
                    onInputChange={handleInstrumentIdChange}
                  />
                )}
              </Field>
              <ErrorMessage name='instrId' component={Typography} sx={{ color: '#f05f5f', fontSize: '12px' }} />
            </Grid>
            <Grid item>
              <TextField
                id='localSymbol'
                name='localSymbol'
                size='small'
                fullWidth
                disabled
                value={props.values.localSymbol}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                label='Local Symbol'
                variant='outlined'
              />
              {/* <ErrorMessage name='localSymbol' component={Typography} sx={{ color: '#f05f5f', fontSize: '12px' }} /> */}
            </Grid>
            <Grid item>
              <TextField
                id='ratio'
                name='ratio'
                size='small'
                fullWidth
                value={props.values.ratio}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                label='Ratio'
                variant='outlined'
              />
              <Box>
                <ErrorMessage name='ratio' component={Typography} sx={{ color: '#f05f5f', fontSize: '12px', float: 'left' }} />
                <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: '12px', float: 'right' }}>Ratio must be in a:b format. (e.g. 1:2, 2:5, 11:13)</Typography>
              </Box>
            </Grid>
            <Grid item>
              <TextField
                id='adjustmentFactor'
                name='adjustmentFactor'
                size='small'
                fullWidth
                value={props.values.adjustmentFactor}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                label='Adjustment Factor'
                variant='outlined'
              />
              <ErrorMessage name='adjustmentFactor' component={Typography} sx={{ color: '#f05f5f', fontSize: '12px' }} />
            </Grid>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid item>
                <Field name='startDate'>
                  {({ field }) => (
                    <DatePicker
                      label='Start Date'
                      minDate={dayjs().subtract(1, 'day')}
                      value={field.value}
                      slotProps={{ textField: { variant: 'outlined', size: 'small', fullWidth: true } }}
                      onChange={(newValue) => props.setFieldValue('startDate', newValue)}
                    />
                  )}
                </Field>
                <ErrorMessage name='startDate' component={Typography} sx={{ color: '#f05f5f', fontSize: '12px' }} />
              </Grid>
              <Grid item>
                <Field name='endDate'>
                  {({ field }) => (
                    <DatePicker
                      label='End Date'
                      disablePast
                      minDate={props.values.startDate}
                      value={field.value}
                      slotProps={{ textField: { variant: 'outlined', size: 'small', fullWidth: true } }}
                      onChange={(newValue) => props.setFieldValue('endDate', newValue)}
                    />
                  )}
                </Field>
                <ErrorMessage name='endDate' component={Typography} sx={{ color: '#f05f5f', fontSize: '12px' }} />
              </Grid>
              <Grid item>
                <Field name='effectiveDate'>
                  {({ field }) => (
                    <DatePicker
                      label='Effective Date'
                      disablePast
                      shouldDisableDate={disableWeekends}
                      maxDate={props.values.endDate}
                      minDate={props.values.startDate}
                      value={field.value}
                      slotProps={{ textField: { variant: 'outlined', size: 'small', fullWidth: true } }}
                      onChange={(newValue) => props.setFieldValue('effectiveDate', newValue)}
                    />
                  )}
                </Field>
                <ErrorMessage name='effectiveDate' component={Typography} sx={{ color: '#f05f5f', fontSize: '12px' }} />
              </Grid>
              <Grid item>
                <Field name='announcementDate'>
                  {({ field }) => (
                    <DatePicker
                      label='Announcement Date'
                      value={field.value}
                      maxDate={props.values.startDate}
                      slotProps={{ textField: { variant: 'outlined', size: 'small', fullWidth: true } }}
                      onChange={(newValue) => props.setFieldValue('announcementDate', newValue)}
                    />
                  )}
                </Field>
                <ErrorMessage name='announcementDate' component={Typography} sx={{ color: '#f05f5f', fontSize: '12px' }} />
              </Grid>
            </LocalizationProvider>
            <Grid item>
              {props.isSubmitting
                ? (<LoadingButton
                    loading
                    fullWidth
                    loadingPosition='start'
                    startIcon={<Save />}
                    variant='outlined'
                    size='small'
                    sx={{
                      padding: 1
                    }}
                   >
                  Submit
                   </LoadingButton>)
                : <Button
                    type='submit'
                    size='small'
                    fullWidth
                    variant='contained'
                  // disabled={Boolean(!props.isValid || props.isSubmitting)}
                    sx={{
                      padding: 1
                    }}
                  >
                  Submit
                  </Button>}
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  )
}

SplitsTable.propTypes = {
  rows: propTypes.object.isRequired,
  getUpdatedRows: propTypes.func.isRequired,
  fetchSplitsActions: propTypes.func.isRequired
}

export default SplitsTable
