import { getUserRole } from '../../../../utils/getUserRole'
import { CreateAPI } from '../../components/Actions'
import { FormatPayload } from '../../components/FormatAPIRequest'

export const CreateDirectIndexingData = async (newRow, tabIndex) => {
  switch (tabIndex) {
    case 'type-code':
      return await CreateStrategyTypeCode(newRow)
    default:
      return {}
  }
}

const CreateStrategyTypeCode = async (newRow) => {
  const userRole = getUserRole()
  let payload = {
    strTypeCode: newRow?.strTypeCode?.toUpperCase(),
    desc: newRow?.desc
  }

  payload = FormatPayload(payload)
  return CreateAPI(
    'baseStrategyMaintainURL',
    `data-maintenance/v1/${userRole}/strategies/type-codes`,
    payload)
}
