export const formatCurrency = (amount) => {
  // Nine Zeroes for Billions
  return Math.abs(Number(amount)) >= 1.0e9
    ? (Math.abs(Number(amount)) / 1.0e9).toFixed(2) + 'B'
    : // Six Zeroes for Millions
    Math.abs(Number(amount)) >= 1.0e6
      ? (Math.abs(Number(amount)) / 1.0e6).toFixed(2) + 'M'
      : // Three Zeroes for Thousands
      Math.abs(Number(amount)) >= 1.0e3
        ? (Math.abs(Number(amount)) / 1.0e3).toFixed(2) + 'K'
        : Math.abs(Number(amount)).toFixed(2)
}

export const formatCurrencyWithSymbol = (amount, decimal, symbol) => {
  const absAmount = Math.abs(Number(amount))
  const sign = Math.sign(amount) === -1 ? '-' : ''
  if (absAmount >= 1.0e9) {
    return sign + symbol + (absAmount / 1.0e9).toFixed(decimal) + 'B'
  } else if (absAmount >= 1.0e6) {
    return sign + symbol + (absAmount / 1.0e6).toFixed(decimal) + 'M'
  } else if (absAmount >= 1.0e3) {
    return sign + symbol + (absAmount / 1.0e3).toFixed(decimal) + 'K'
  } else if (absAmount === 0) {
    return symbol + (absAmount).toFixed(decimal)
  } else {
    return sign + symbol + absAmount.toFixed(decimal)
  }
}

export const formatCurrencyWithoutSign = (amount, decimal) => {
  const absAmount = Math.abs(Number(amount))
  const sign = Math.sign(amount) === -1 ? '-' : ''
  if (absAmount >= 1.0e9) {
    return sign + (absAmount / 1.0e9).toFixed(decimal) + 'B'
  } else if (absAmount >= 1.0e6) {
    return sign + (absAmount / 1.0e6).toFixed(decimal) + 'M'
  } else if (absAmount >= 1.0e3) {
    return sign + (absAmount / 1.0e3).toFixed(decimal) + 'K'
  } else if (absAmount === 0) {
    return (absAmount).toFixed(decimal)
  } else {
    return sign + absAmount.toFixed(decimal)
  }
}
