import React from 'react'
import { NavLink } from 'react-router-dom'
import { Collapse, List, ListItem, ListItemIcon, ListItemText, Tooltip } from '@mui/material'
import 'rc-slider/assets/index.css'
import { StyledChevronRightIcon, StyledExpandMoreIcon } from './components/ArrowButtons'
import { useAuth } from '../contexts/AuthContext'
import { useTheme } from '@mui/material/styles'
import { ACCESS_LEVEL } from '../contstants/constants'
import { hexToRgba } from '../utils/HexOperations'
import { useSelector } from 'react-redux'

const SubMenuItems = (props) => {
  const { id, menuId, paths, activePath, icon, name, isOpen, handleSubmenuToggle, isSidebarOpen, parentModule, leftSidebarProps } = props
  const { user, checkAccess } = useAuth()
  const themeData = useSelector((state) => state.theme)
  const isActive = paths.some(path => activePath?.includes(path.path))

  const theme = useTheme()

  // set dynamic colors for leftside bar service name text
  const primaryText = user?.userGroup === 'adv-classic' && theme.palette.primaryPalette.contrastText ? theme.palette.primaryPalette.contrastText : user?.userGroup === 'admin' && leftSidebarProps ? leftSidebarProps?.brandTextColor : 'white'
  const secondaryText = user?.userGroup === 'adv-classic' && theme.palette.paletteTextColor.contrastText
    ? hexToRgba(theme.palette.paletteTextColor.contrastText, themeData?.opacity)
    : user?.userGroup === 'admin' && leftSidebarProps ? hexToRgba(leftSidebarProps?.brandTextColor, leftSidebarProps.opacity) : 'rgba(255, 255, 255, 0.5)'

  return (
    <>
      <ListItem
        className='menu-item'
        component='div'
        onClick={(e) => handleSubmenuToggle(e)}
        id={id}
      >
        <Tooltip PopperProps={{ style: { zIndex: 90 } }} title={isSidebarOpen ? '' : name} placement='right'>
          <ListItemIcon id={id + '-icon'} sx={{ color: isActive ? primaryText : secondaryText }}>
            {
              icon.type === 'svg'
                ? <icon.inactive sx={{ width: '20px', height: '20px', color: isActive ? primaryText : secondaryText }} />
                : icon.type === 'component'
                  ? <icon.component color={isActive ? primaryText : secondaryText} size='18px' />
                  : <img
                      src={isActive ? icon.active : icon.inactive}
                      alt='menu-icon'
                      className='inline-block'
                      style={{ width: '18px', height: '18px' }}
                    />
            }
          </ListItemIcon>
        </Tooltip>
        <ListItemText
          className='menu-link'
          sx={{ color: paths.some(path => activePath?.includes(path.path)) ? primaryText : secondaryText }}
          primary={name}
        />
        {isOpen ? <StyledExpandMoreIcon className='arrow-icon' style={{ color: paths.some(path => activePath?.includes(path.path)) ? primaryText : secondaryText }} /> : <StyledChevronRightIcon style={{ color: paths.some(path => activePath?.includes(path.path)) ? primaryText : secondaryText }} />}

      </ListItem>
      <Collapse in={isOpen}>
        <List
          className='sub-menu'
          id={menuId}
          disablePadding
        >
          <>
            {paths?.map((path, index) => (
              (user?.userGroup !== 'admin')
                ? (
                    checkAccess(parentModule, ACCESS_LEVEL.SUB_MODULE_ACCESS, { subModuleName: path.moduleCd }) &&
                      <ListItem
                        key={index}
                        className='menu-item'
                        component={NavLink}
                        to={path.path}
                        sx={{
                          marginLeft: '4px'
                        }}
                      >
                        <ListItemText primary={path.name} sx={{ color: path.path.split('/').every(p => activePath.split('/').includes(p)) ? primaryText : secondaryText }} className='menu-link' />
                      </ListItem>
                  )
                : (
                  <ListItem
                    key={index}
                    className='menu-item'
                    component={NavLink}
                    to={path.path}
                    sx={{
                      marginLeft: '4px'
                    }}
                  >
                    <ListItemText primary={path.name} sx={{ color: path.path.split('/').every(p => activePath.split('/').includes(p)) ? primaryText : secondaryText }} className='menu-link' />
                  </ListItem>
                  )
            ))}
          </>
        </List>
      </Collapse>
    </>
  )
}

export default SubMenuItems
