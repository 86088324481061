import { useState } from 'react'
import { TabContext, TabPanel } from '@mui/lab'
import { Box, Button, Chip, Divider, Skeleton, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Tooltip, Typography } from '@mui/material'
import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro'
import { randomId } from '@mui/x-data-grid-generator'
import AssignmentLateOutlinedIcon from '@mui/icons-material/AssignmentLateOutlined'
import ErrorIcon from '@mui/icons-material/Error'
import CircleIcon from '@mui/icons-material/Circle'
import { useAuth } from '../../../../../contexts/AuthContext'
import { HtmlTooltip } from '../../../../../components/HtmlTooltip'
import ViewPolicyJson from '../../../../../components/ViewPolicyJson'
import { moduleConfig } from '../../../../../contexts/data'
import { ACCESS_LEVEL } from '../../../../../contstants/constants'

const renderTableSkeleton = (header) => {
  return (
    <TableContainer mt={5}>
      <Table className='table-responsive'>
        <TableHead>
          <TableRow sx={{ height: '39px' }}>
            {header.map((item, index) => {
              return (
                <TableCell key={index} sx={{ fontSize: '12px !important', whiteSpace: 'nowrap', paddingBlock: 0 }}>{item.headerName}</TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.from({ length: 5 }).map((_, index) => (
            <TableRow key={index} sx={{ height: '30px' }}>
              {Array.from({ length: header.length }).map((_, index) => (
                <TableCell key={index} sx={{ paddingBlock: 0 }}>
                  <Skeleton variant='text' sx={{ fontSize: '0.8rem' }} />
                </TableCell>))}
            </TableRow>))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const getStatusColor = (color) => {
  if (color === 'green') { return '#3BBFA3' } else if (color === 'red') { return '#FF6161' } else if (color === 'yellow') { return '#D29922' } else if (color === 'gray') { return '#0000004d' }
  return '#c9c9c9'
}

const getColorFromStatus = (status) => {
  if (status === 'SUCCESS') { return '#3BBFA3' } else if (status === 'FAILURE') { return '#FF6161' } else if (status === 'IN_PROGRESS') { return '#0000004d' }
  return '#c9c9c9'
}

const policyColumns = [
  {
    headerName: 'Policy',
    field: 'policyDesc',
    flex: 2
  },
  {
    headerName: 'Policy Status',
    field: 'policyStatus',
    flex: 1,
    align: 'center',
    headerAlign: 'center',
    renderCell: (params) => (
      <Tooltip title={params?.row?.policyStatus}>
        <CircleIcon sx={{ color: getStatusColor(params?.row?.policyColorStatus), height: '6px', width: '6px' }} />
      </Tooltip>
    )
  },
  {
    headerName: 'Last Policy Check',
    field: 'lastUpdatedDate',
    flex: 1,
    type: 'date',
    valueGetter: (params) => {
      if (!params?.value) return params?.value
      const date = new Date(params?.value)
      return new Date(date?.getTime() + date?.getTimezoneOffset() * 1000 * 60)
    },
    renderCell: (params) => params?.row?.lastUpdatedDate ? params?.row?.lastUpdatedDate?.split('T')[0] : ''
  },
  {
    headerName: 'Reason',
    field: 'reason',
    align: 'center',
    width: 100,
    renderCell: (params) => (
      params.row.reason
        ? (
          <HtmlTooltip
            title={
              <>
                <Typography color='inherit' fontFamily='Open Sans' fontWeight={600}>Reason</Typography>
                <Divider sx={{ backgroundColor: '#000000' }} />
                <Typography sx={{ fontSize: '14px' }} my={1}>{params.row.reason}</Typography>
              </>
          }
          >
            <AssignmentLateOutlinedIcon sx={{ cursor: 'pointer' }} />
          </HtmlTooltip>
          )
        : ''
    )
  }
]

const cashCheckCols = [
  { field: 'scenarioDesc', headerName: 'Scenario', flex: 1 },
  { field: 'policyCode', headerName: 'Policy Code', flex: 1 },
  { field: 'lastClosePrice', headerName: 'As per Last Close Price', flex: 1 },
  { field: 'livePrice', headerName: 'As per Live Price', flex: 1 }
]

const TradePolicyStatus = ({ loading, policyData }) => {
  const { checkAccess } = useAuth()
  const [value, setValue] = useState({ name: 'Cash Check', index: 0 })
  const [showReportPopup, setShowReportPopup] = useState(false)
  const policyStatusTabs = ['Cash Check', 'Account', 'Trade', 'Strategy']

  const handleTabChange = (e, newValue) => {
    const indexOfActiveTab = policyStatusTabs.findIndex(tab => tab === newValue)
    if (indexOfActiveTab !== -1) {
      setValue({ name: newValue, index: indexOfActiveTab })
    }
  }

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: '5px', position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 2 }}>
        <Typography variant='h8' sx={{ color: '#74788D', fontWeight: '600' }}>Policy</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          {
            (value?.index >= 1) && (policyData[value.index]?.policyRunStatus === 'IN_PROGRESS' || policyData[value?.index]?.policyRunStatus === 'FAILED') ? (
              <Chip
                variant='outlined'
                sx={{
                  backgroundColor: '#F9F4D8',
                  color: '#947330',
                  borderRadius: '5px',
                  border: '1px solid #947330',
                  fontSize: '10px',
                  fontWeight: 600,
                  maxWidth: '500px',
                  height: 'auto',
                  minHeight: '24px',
                  '& .MuiChip-label': {
                    whiteSpace: 'break-spaces'
                  }
                }}
                icon={<ErrorIcon sx={{ color: '#947330 !important', height: '18px', width: '18px' }} />}
                label={policyData[value.index]?.reason || ''}
              />
            ) :
              value?.index >= 1 && policyData[value.index]?.policies?.length && checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL, component_name: moduleConfig.DOWNLOAD_POLICY_STATUS }) ? (
                <Button
                  variant='text'
                  onClick={() => setShowReportPopup(true)}
                  sx={{ textTransform: 'capitalize' }}
                >
                  View Report
                </Button>
              ) : ''
          }
        </Box>
      </Box>
      <TabContext value={value.name}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Tabs
            value={value.name}
            onChange={handleTabChange}
            variant='standard'
            scrollButtons
            className='report-tab-header'
            TabIndicatorProps={{
              style: { backgroundColor: '#34475A' }
            }}
            sx={{ minHeight: '32px' }}
            indicatorColor='#34475A'
          >
            {
              policyStatusTabs?.map((item, index) => (
                <Tab
                  className='reportCardNav'
                  icon={<CircleIcon sx={{ color: getColorFromStatus(policyData[value.index]?.policyRunStatus), height: '12px', width: '12px' }} />}
                  iconPosition='end'
                  key={index}
                  value={item}
                  label={item}
                  sx={{
                    fontSize: '14px !important', padding: '6px 8px', minHeight: '32px'
                  }}
                />
              ))
            }
          </Tabs>
          <Divider sx={{
            bgcolor: 'rgba(116, 120, 141, 0.15)',
            width: '100%'
          }}
          />
        </Box>
        <Box>
          {policyStatusTabs?.map((item, index) => (
            <TabPanel
              key={index}
              index={index}
              value={item}
              sx={{ padding: 0 }}
            >
              {
                loading[value.index]
                  ? renderTableSkeleton(policyColumns)
                  : <DataGridPro
                    autoHeight
                    rows={policyData[value?.index]?.policies?.map(policy => ({ ...policy, id: randomId() })) || []}
                    columns={value?.index === 0 ? cashCheckCols || [] : policyColumns || []}
                    getRowId={(row) => row?.id}
                    rowHeight={30}
                    columnHeaderHeight={39}
                    pagination
                    pageSizeOptions={[10]}
                    initialState={{
                      pagination: { paginationModel: { pageSize: 10 } }
                    }}
                    slots={{
                      noRowsOverlay: CustomNoRowsOverlay
                    }}
                    slotProps={{
                      noRowsOverlay: { msg: 'No Policies Found.' }
                    }}
                    sx={(theme) => ({
                      '& .MuiDataGrid-cell:focus': {
                        outline: 'none'
                      },
                      [`.${gridClasses.main}`]: {
                        height: 'min(100vh - 16px - 45px - 16px - 53px, 600px - 16px - 45px - 16px - 53px)',
                      },
                      [`.${gridClasses.cell}, .${gridClasses.cellContent}, .${gridClasses.columnHeaderTitle}`]: {
                        fontSize: '12px'
                      },
                      [`.${gridClasses.columnHeaders}`]: {
                        fontSize: '12px',
                        position: 'sticky',
                        backgroundColor: theme.palette.background.paper,
                        top: 0,
                        zIndex: 1,
                      },
                      [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
                        color: '#74788d',
                        fontWeight: 600
                      },
                      [`.${gridClasses.virtualScroller}`]: {
                        overflowY: 'auto !important',
                        scrollbarGutter: 'stable',
                        scrollbarWidth: 'none'
                      }
                    })}
                  />
              }
            </TabPanel>
          ))}
        </Box>
      </TabContext>
      <ViewPolicyJson policyData={policyData[value.index]} showReportPopup={showReportPopup} onClose={() => setShowReportPopup(false)} title={value.name} />
    </>
  )
}

const PolicyTable = (data, cols) => (
  <DataGridPro
    autoHeight
    rows={data?.policies?.map(policy => ({ ...policy, id: randomId() })) || []}
    columns={cols || []}
    getRowId={(row) => row?.id}
    rowHeight={30}
    columnHeaderHeight={39}
    pagination
    pageSizeOptions={[10]}
    initialState={{
      pagination: { paginationModel: { pageSize: 10 } }
    }}
    slots={{
      noRowsOverlay: CustomNoRowsOverlay
    }}
    slotProps={{
      noRowsOverlay: { msg: 'No Policies Found.' }
    }}
    sx={(theme) => ({
      '& .MuiDataGrid-cell:focus': {
        outline: 'none'
      },
      [`.${gridClasses.main}`]: {
        height: 'min(100vh - 16px - 45px - 16px - 53px, 600px - 16px - 45px - 16px - 53px)'
      },
      [`.${gridClasses.cell}, .${gridClasses.cellContent}, .${gridClasses.columnHeaderTitle}`]: {
        fontSize: '12px'
      },
      [`.${gridClasses.columnHeaders}`]: {
        fontSize: '12px',
        position: 'sticky',
        backgroundColor: theme.palette.background.paper,
        top: 0,
        zIndex: 1
      },
      [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
        color: '#74788d',
        fontWeight: 600
      },
      [`.${gridClasses.virtualScroller}`]: {
        overflowY: 'auto !important',
        scrollbarGutter: 'stable',
        scrollbarWidth: 'none'
      }
    })}
  />
)

const CustomNoRowsOverlay = ({ msg }) => (
  <Typography sx={{ textAlign: 'center', margin: '16px auto', fontSize: '12px' }}>
    {msg}
  </Typography>
)

export default TradePolicyStatus
