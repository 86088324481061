import React, { useEffect, useRef, useState } from 'react'
import { Checkbox, FormControlLabel, FormGroup, Grid } from '@mui/material'
import { decTwoPlaces, formatCurrency, formatCurrencyWithSymbol } from '../../../Report/components/dataProcess/DataProcess'
import { Chart } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'

let percentageChart = false
let logChart = false
let normalChart = true
let logPerChart = false
const convertedDataBubbleChart = (data, selectedScenario, highlighIntialScenario, portMv, logCheck, taxCheck) => {
  if (!logCheck && !taxCheck) {
    normalChart = true
    logChart = false
    logPerChart = false
    percentageChart = false
  } else if (logCheck && !taxCheck) {
    normalChart = false
    logChart = true
    logPerChart = false
    percentageChart = false
  } else if (!logCheck && taxCheck) {
    normalChart = false
    logChart = false
    logPerChart = false
    percentageChart = true
  } else {
    normalChart = false
    logChart = false
    logPerChart = true
    percentageChart = false
  }
  const convertedDataBubble = data?.optDetails?.map((item) => ({
    x: logPerChart || percentageChart ? (item?.summary?.taxCost / portMv) * 100 : item?.summary?.taxCost,
    y: decTwoPlaces(item?.summary?.propTe, 2) >= 10 ? 10 : decTwoPlaces(item?.summary?.propTe, 2),
    r: selectedScenario === item.scenarioDesc
      ? 25
      : (selectedScenario === undefined || selectedScenario === '') && item.scenarioDesc === highlighIntialScenario ? 25 : 18,
    name: item.scenarioDesc
  }))

  /// /////////////////////////// Bubble ////////////////////////////
  let minNegativeX = 0
  if (logPerChart || percentageChart || logChart) {
    // Find the minimum negative value for x
    for (let i = 0; i < convertedDataBubble?.length; i++) {
      if (convertedDataBubble[i].x < 0 && convertedDataBubble[i].x < minNegativeX) {
        minNegativeX = convertedDataBubble[i].x
      }
    }
  }

  if (logPerChart || percentageChart || logChart) {
    // Add the absolute value of the minimum negative value to all x values
    for (let i = 0; i < convertedDataBubble?.length; i++) {
      convertedDataBubble[i].x += Math.abs(minNegativeX)
    }
  }

  if (logPerChart || logChart) {
    // Add the absolute value of the minimum negative value to all x values
    for (let i = 0; i < convertedDataBubble?.length; i++) {
      if (convertedDataBubble[i].y === '0.00') {
        convertedDataBubble[i].y = 0.001
      }
    }
  }

  let maxXY = 0
  if (logPerChart || percentageChart) {
    for (let i = 0; i < convertedDataBubble?.length; i++) {
      if (convertedDataBubble[i].x > maxXY) {
        maxXY = convertedDataBubble[i].x
      }
      if (convertedDataBubble[i].y > maxXY) {
        maxXY = convertedDataBubble[i].y
      }
    }
    maxXY += 3
    maxXY = Math.ceil(maxXY)
  }

  return { convertedDataBubble, maxXY }
}

const convertedDataLineChart = (data, portMv, logCheck, taxCheck) => {
  if (!logCheck && !taxCheck) {
    normalChart = true
    logChart = false
    logPerChart = false
    percentageChart = false
  } else if (logCheck && !taxCheck) {
    normalChart = false
    logChart = true
    logPerChart = false
    percentageChart = false
  } else if (!logCheck && taxCheck) {
    normalChart = false
    logChart = false
    logPerChart = false
    percentageChart = true
  } else {
    normalChart = false
    logChart = false
    logPerChart = true
    percentageChart = false
  }
  const convertedDataLine = []
  const convertedDataLineExcluded = []
  data?.optDetails?.forEach(item => {
    let lineChartData = false
    data?.optDetails?.forEach(element => {
      if (item?.summary?.propTe > element?.summary?.propTe && item?.summary?.taxCost > element?.summary?.taxCost) {
        lineChartData = true
        convertedDataLineExcluded.push(item.scenarioDesc)
        return false
      }
    })
    if (!lineChartData) {
      convertedDataLine.push({
        x: logPerChart || percentageChart ? (item?.summary?.taxCost / portMv) * 100 : item?.summary?.taxCost,
        y: decTwoPlaces(item?.summary?.propTe, 2) >= 10 ? 10.00 : decTwoPlaces(item?.summary?.propTe, 2)
      })
    }
  })

  /// /////////////////////////// Line ////////////////////////////
  let minNegativeX = 0
  if (logPerChart || percentageChart || logChart) {
    // Find the minimum negative value for x
    for (let i = 0; i < convertedDataLine?.length; i++) {
      if (convertedDataLine[i].x < 0 && convertedDataLine[i].x < minNegativeX) {
        minNegativeX = convertedDataLine[i].x
      }
    }
  }
  if (logPerChart || percentageChart || logChart) {
    // Add the absolute value of the minimum negative value to all x values
    for (let i = 0; i < convertedDataLine?.length; i++) {
      convertedDataLine[i].x += Math.abs(minNegativeX)
    }
  }

  if (logPerChart || logChart) {
    // Add the absolute value of the minimum negative value to all x values
    for (let i = 0; i < convertedDataLine?.length; i++) {
      if (convertedDataLine[i].y === '0.00') {
        convertedDataLine[i].y = 0.001
      }
    }
  }
  convertedDataLine.sort((a, b) => parseFloat(a.y) - parseFloat(b.y))
  return { convertedDataLine, convertedDataLineExcluded }
}

const bubbleColorChart = (data, selectedScenario, highlighIntialScenario, convertedDataLineExcluded) => {
  if (selectedScenario) {
    highlighIntialScenario = ''
  }
  const bubbleColorChartData = data?.optDetails?.map((item) => {
    if (selectedScenario === item.scenarioDesc || highlighIntialScenario === item.scenarioDesc) {
      return '#34C38F'
    } else if (convertedDataLineExcluded.includes(item.scenarioDesc)) {
      return '#74788D90'
    } else {
      return '#8BAEE1'
    }
  })
  return bubbleColorChartData
}
const TradeSummaryChart = ({ accountData, fArray, hideCheckbox = false, chartHeight = 'inherit' }) => {
  const chartRefBub = useRef(null)
  const [taxCheck, setTaxCheck] = useState(false)
  const [logCheck, setLogCheck] = useState(false)

  // summary data fetched from DB
  const bubbleLineData = convertedDataLineChart(fArray[accountData[2]], fArray[accountData[2]].optDetails[0]?.summary?.portMv, logCheck, taxCheck)
  const bubbleChartDataStr = convertedDataBubbleChart(fArray[accountData[2]], accountData[1], accountData[1], fArray[accountData[2]].optDetails[0]?.summary?.portMv, logCheck, taxCheck, bubbleLineData.convertedDataLineExcluded)
  const bubbleColorChartData = bubbleColorChart(fArray[accountData[2]], accountData[1], accountData[1], bubbleLineData.convertedDataLineExcluded)

  useEffect(() => {
    if (!logCheck && !taxCheck) {
      normalChart = true
      logChart = false
      logPerChart = false
      percentageChart = false
    } else if (logCheck && !taxCheck) {
      normalChart = false
      logChart = true
      logPerChart = false
      percentageChart = false
    } else if (!logCheck && taxCheck) {
      normalChart = false
      logChart = false
      logPerChart = false
      percentageChart = true
    } else {
      normalChart = false
      logChart = false
      logPerChart = true
      percentageChart = false
    }
    const chartCanvas = chartRefBub.current
    Chart.register(ChartDataLabels)
    if (logPerChart) {
      if (chartCanvas) {
        const myChartBub = new Chart(chartCanvas.getContext('2d'), {
          type: 'bubble',
          data: {
            datasets: [
              {
                label: 'Bubble Dataset',
                data: bubbleChartDataStr.convertedDataBubble,
                backgroundColor: bubbleColorChartData
              },
              {
                type: 'line',
                label: 'line',
                data: bubbleLineData.convertedDataLine,
                fill: false,
                borderColor: '#8BAEE1',
                pointRadius: 0,
                borderWidth: 2
              }
            ]
          },
          options: {
            responsive: true,
            tension: 0.1, // adjust curve shape
            cubicInterpolationMode: 'monotone', // set interpolation mode
            maintainAspectRatio: false,
            devicePixelRatio: 2,
            animation: { duration: 0 },
            hover: { mode: null },
            plugins: {
              tooltip: {
                enabled: true,
                callbacks: {
                  label: (context) => {
                    const value = context.dataset.data[context.dataIndex]
                    return [`Scenario : ${value.name || 'N/A'}`, `Tax Cost :  ${formatCurrencyWithSymbol(value.x || 0, 2, '$')}`, `Tracking Error :  ${value.y}%`]
                  }
                }
              },
              datalabels: {
                color: 'white',
                formatter: (context) => {
                  if (context.r) {
                    return context.y < 10 ? context.y : '>10'
                  } else {
                    return ''
                  }
                },
                font: (context) => {
                  const value = context.dataset.data[context.dataIndex]
                  if (value.r) {
                    if (value.r === 25) {
                      return {
                        size: '18px'
                      }
                    } else {
                      return {
                        size: '12px'
                      }
                    }
                  }
                }
              },
              legend: { display: false }
            },
            elements: {
              point: { radius: 25 }
            },
            scales: {
              x: {
                max: bubbleChartDataStr.maxXY,
                type: 'logarithmic',
                ticks: {
                  callback: function (value, index, values) {
                    return logCheck ? value : formatCurrency(value)
                  },
                  maxTicksLimit: 6
                },
                title: {
                  display: true,
                  text: percentageChart || logPerChart ? 'Total Taxes (% in respective of total portfolio value)' : 'Total Taxes ($)',
                  padding: {
                    top: 16 // Set the top padding to 16 pixels
                  },
                  font: {
                    size: 13 // Set the font size to 14 pixels
                  }
                }
              },
              y: {
                type: 'logarithmic',
                ticks: {
                  beginAtZero: true,
                  callback: function (value, index, values) {
                    return logCheck ? value : formatCurrency(value)
                  },
                  maxTicksLimit: 6 // Set the maximum number of tick marks to 3
                },
                title: {
                  display: true,
                  text: 'Tracking Error (%)',
                  padding: {
                    bottom: 16 // Set the top padding to 16 pixels
                  },
                  font: {
                    size: 13 // Set the font size to 14 pixels
                  }
                }
              }
            }
          }
        })
        return () => {
          myChartBub?.destroy()
        }
      }
    } else if (normalChart) {
      if (chartCanvas) {
        const myChartBub = new Chart(chartCanvas.getContext('2d'), {
          type: 'bubble',
          data: {
            datasets: [
              {
                label: 'Bubble Dataset',
                data: bubbleChartDataStr.convertedDataBubble,
                backgroundColor: bubbleColorChartData
              },
              {
                type: 'line',
                label: 'line',
                data: bubbleLineData.convertedDataLine,
                fill: false,
                borderColor: '#8BAEE1',
                pointRadius: 0,
                borderWidth: 2
              }
            ]
          },
          options: {
            responsive: true,
            tension: 0.1, // adjust curve shape
            cubicInterpolationMode: 'monotone', // set interpolation mode
            maintainAspectRatio: false,
            animation: { duration: 0 },
            hover: { mode: null },
            devicePixelRatio: 2,
            plugins: {
              tooltip: {
                enabled: true,
                callbacks: {
                  label: (context) => {
                    const value = context.dataset.data[context.dataIndex]
                    return [`Scenario : ${value.name || 'N/A'}`, `Tax Cost :  ${formatCurrencyWithSymbol(value.x || 0, 2, '$')}`, `Tracking Error :  ${value.y}%`]
                  }
                }
              },
              datalabels: {
                color: 'white',
                formatter: (context) => {
                  if (context.r) {
                    return context.y < 10 ? context.y : '>10'
                  } else {
                    return ''
                  }
                },
                font: (context) => {
                  const value = context.dataset.data[context.dataIndex]
                  if (value.r) {
                    if (value.r === 25) {
                      return {
                        size: '18px'
                      }
                    } else {
                      return {
                        size: '12px'
                      }
                    }
                  }
                }
              },
              legend: { display: false }
            },
            elements: {
              point: { radius: 25 }
            },
            scales: {
              x: {
                ticks: {
                  callback: function (value, index, values) {
                    return logCheck ? value : formatCurrency(value)
                  },
                  maxTicksLimit: 6
                },
                title: {
                  display: true,
                  text: 'Total Taxes ($)',
                  padding: {
                    top: 16 // Set the top padding to 16 pixels
                  },
                  font: {
                    size: 13 // Set the font size to 14 pixels
                  }
                }
              },
              y: {
                ticks: {
                  beginAtZero: true,
                  callback: function (value, index, values) {
                    return logCheck ? value : formatCurrency(value)
                  },
                  maxTicksLimit: 6 // Set the maximum number of tick marks to 3
                },
                title: {
                  display: true,
                  text: 'Tracking Error (%)',
                  padding: {
                    bottom: 16 // Set the top padding to 16 pixels
                  },
                  font: {
                    size: 13 // Set the font size to 14 pixels
                  }
                }
              }
            }
          }
        })
        return () => {
          myChartBub.destroy()
        }
      }
    } else if (percentageChart) {
      if (chartCanvas) {
        const myChartBub = new Chart(chartCanvas.getContext('2d'), {
          type: 'bubble',
          data: {
            datasets: [
              {
                label: 'Bubble Dataset',
                data: bubbleChartDataStr.convertedDataBubble,
                backgroundColor: bubbleColorChartData
              },
              {
                type: 'line',
                label: 'line',
                data: bubbleLineData.convertedDataLine,
                fill: false,
                borderColor: '#8BAEE1',
                pointRadius: 0,
                borderWidth: 2
              }
            ]
          },
          options: {
            responsive: true,
            tension: 0.1, // adjust curve shape
            cubicInterpolationMode: 'monotone', // set interpolation mode
            maintainAspectRatio: false,
            devicePixelRatio: 2,
            animation: { duration: 0 },
            hover: { mode: null },
            plugins: {
              tooltip: {
                enabled: true,
                callbacks: {
                  label: (context) => {
                    const value = context.dataset.data[context.dataIndex]
                    return [`Scenario : ${value.name || 'N/A'}`, `Tax Cost :  ${formatCurrencyWithSymbol(value.x || 0, 2, '$')}`, `Tracking Error :  ${value.y}%`]
                  }
                }
              },
              datalabels: {
                color: 'white',
                formatter: (context) => {
                  if (context.r) {
                    return context.y < 10 ? context.y : '>10'
                  } else {
                    return ''
                  }
                },
                font: (context) => {
                  const value = context.dataset.data[context.dataIndex]
                  if (value.r) {
                    if (value.r === 25) {
                      return {
                        size: '18px'
                      }
                    } else {
                      return {
                        size: '12px'
                      }
                    }
                  }
                }
              },
              legend: { display: false }
            },
            elements: {
              point: { radius: 25 }
            },
            scales: {
              x: {
                max: bubbleChartDataStr.maxXY,
                ticks: {
                  callback: function (value, index, values) {
                    return logCheck ? value : formatCurrency(value)
                  },
                  maxTicksLimit: 6
                },
                title: {
                  display: true,
                  text: percentageChart || logPerChart ? 'Total Taxes (% in respective of total portfolio value)' : 'Total Taxes ($)',
                  padding: {
                    top: 16 // Set the top padding to 16 pixels
                  },
                  font: {
                    size: 13 // Set the font size to 14 pixels
                  }
                }
              },
              y: {
                max: bubbleChartDataStr.maxXY,
                ticks: {
                  beginAtZero: true,
                  callback: function (value, index, values) {
                    return logCheck ? value : formatCurrency(value)
                  },
                  maxTicksLimit: 6 // Set the maximum number of tick marks to 3
                },
                title: {
                  display: true,
                  text: 'Tracking Error (%)',
                  padding: {
                    bottom: 16 // Set the top padding to 16 pixels
                  },
                  font: {
                    size: 13 // Set the font size to 14 pixels
                  }
                }
              }
            }
          }
        })
        return () => {
          myChartBub?.destroy()
        }
      }
    } else {
      if (chartCanvas) {
        const myChartBub = new Chart(chartCanvas.getContext('2d'), {
          type: 'bubble',
          data: {
            datasets: [
              {
                label: 'Bubble Dataset',
                data: bubbleChartDataStr.convertedDataBubble,
                backgroundColor: bubbleColorChartData
              },
              {
                type: 'line',
                label: 'line',
                data: bubbleLineData.convertedDataLine,
                fill: false,
                borderColor: '#8BAEE1',
                pointRadius: 0,
                borderWidth: 2
              }
            ]
          },
          options: {
            responsive: true,
            tension: 0.1, // adjust curve shape
            cubicInterpolationMode: 'monotone', // set interpolation mode
            maintainAspectRatio: false,
            devicePixelRatio: 2,
            animation: { duration: 0 },
            hover: { mode: null },
            plugins: {
              tooltip: {
                enabled: true,
                callbacks: {
                  label: (context) => {
                    const value = context.dataset.data[context.dataIndex]
                    return [`Scenario : ${value.name || 'N/A'}`, `Tax Cost :  ${formatCurrencyWithSymbol(value.x || 0, 2, '$')}`, `Tracking Error :  ${value.y}%`]
                  }
                }
              },
              datalabels: {
                color: 'white',
                formatter: (context) => {
                  if (context.r) {
                    return context.y < 10 ? context.y : '>10'
                  } else {
                    return ''
                  }
                },
                font: (context) => {
                  const value = context.dataset.data[context.dataIndex]
                  if (value.r) {
                    if (value.r === 25) {
                      return {
                        size: '18px'
                      }
                    } else {
                      return {
                        size: '12px'
                      }
                    }
                  }
                }
              },
              legend: { display: false }
            },
            elements: {
              point: { radius: 25 }
            },
            scales: {
              x: {
                type: 'logarithmic',
                ticks: {
                  callback: function (value, index, values) {
                    return formatCurrency(value)
                  },
                  maxTicksLimit: 6
                },
                title: {
                  display: true,
                  text: 'Total Taxes ($)',
                  padding: {
                    top: 16 // Set the top padding to 16 pixels
                  },
                  font: {
                    size: 13 // Set the font size to 13 pixels
                  }
                }
              },
              y: {
                ticks: {
                  beginAtZero: true,
                  callback: function (value, index, values) {
                    return value
                  },
                  maxTicksLimit: 6 // Set the maximum number of tick marks to 3
                },
                title: {
                  display: true,
                  text: 'Tracking Error (%)',
                  padding: {
                    bottom: 16 // Set the top padding to 16 pixels
                  },
                  font: {
                    size: 13 // Set the font size to 13 pixels
                  }
                }
              }
            }
          }
        })
        return () => {
          myChartBub.destroy()
        }
      }
    }
  }, [bubbleLineData, bubbleChartDataStr, logCheck, taxCheck])

  const handleTaxCheckChange = (event) => {
    setTaxCheck(event.target.checked)
  }

  const handleLogCheckChangeLog = (event) => {
    setLogCheck(event.target.checked)
  }
  return (
    <>
      {
        !hideCheckbox
          ? <Grid container justifyContent='flex-end'>
            <Grid item>
              <FormGroup>
                <FormControlLabel control={<Checkbox checked={taxCheck} />} sx={{ fontSize: '14px', fontFamily: 'Open Sans' }} onChange={handleTaxCheckChange} label='Tax Cost in %' />
              </FormGroup>
            </Grid>
            <Grid item>
              <FormGroup>
                <FormControlLabel control={<Checkbox checked={logCheck} />} sx={{ fontFamily: 'Open Sans' }} onChange={handleLogCheckChangeLog} label='Logarithmic' />
              </FormGroup>
            </Grid>
          </Grid>
          : ''
      }
      <Grid container p={hideCheckbox ? 0 : 2} height={hideCheckbox ? chartHeight : 'auto'}>
        <Grid xs={12} sm={12} sx={{
          pt: 1,
          width: '100%',
          height: chartHeight
        }}>
          <canvas id='myChartBubble' ref={chartRefBub} />
        </Grid>
      </Grid>
    </>
  )
}

export default TradeSummaryChart
