import { useState } from 'react'
import { Box, IconButton, Menu, MenuItem, Modal, Tooltip, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import MoreVert from '@mui/icons-material/MoreVert'
import { DataGridPro, gridClasses, useGridApiRef } from '@mui/x-data-grid-pro'
import { useAuth } from '../../../../contexts/AuthContext'
import { moduleConfig } from '../../../../contexts/data'
import { ACCESS_LEVEL } from '../../../../contstants/constants'
import { formatCurrency } from '../../../../utils/FormateCurrenyInMilion'
import DownloadTradeLogsCell from './DownloadTradeLogsCell'

const detailsPopupStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  maxWidth: '100%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2,
  borderRadius: '4px',
  outline: 'none'
}

const getStatusColor = (status) => {
  if (status === 'TRADE_COMPLETED') { return '#3BBFA3' } else if (status === 'TRADE_FAILED') { return '#FF6161' } else if (status === 'TRADE_INPROGRESS' || status === 'TRADE_SENT') { return '#D29922' } else { return 'inherit' }
}

const StepDetails = ({ showDetailsPopup, closeDetailsPopup, title, stats, rows, activeStepDetails = null }) => {
  const apiRef = useGridApiRef()
  const { checkAccess } = useAuth()
  const [openCopyTooltip, setOpenCopyTooltip] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  const exportCsv = () => {
    const fileName = title?.replaceAll(' ', '-')
    let dataCsv = null

    const headers = ['Local Symbol', 'Side', 'Quantities', 'Market Value', 'Pre Trade Cash', 'Post Trade Cash', 'Trade Status']
    dataCsv = [headers.join(',')]

    for (let i = 0; i < rows?.length; i++) {
      const { localSymbol, trdCode, trdQty, propMarketValue, preTradeCash, postTradeCash, tradeStatusCode } = rows[i]
      dataCsv.push([localSymbol || '', trdCode || '', trdQty, propMarketValue, preTradeCash, postTradeCash, tradeStatusCode || ''].join(','))
    }

    const csvBlob = new Blob([dataCsv.join('\n')], { type: 'text/csv' })
    const csvUrl = URL.createObjectURL(csvBlob)

    // Create a temporary link and trigger the download
    const tempLink = document.createElement('a')
    tempLink.href = csvUrl
    tempLink.setAttribute('download', `${fileName?.toUpperCase()}.csv`)
    tempLink.click()

    // Clean up the URL object
    URL.revokeObjectURL(csvUrl)
    handleMoreOptionClose()
  }

  const handleMoreOptionClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMoreOptionClose = () => {
    setAnchorEl(null)
  }

  const viewResourceIdAccess = checkAccess(
    moduleConfig.TRADE,
    ACCESS_LEVEL.COMPONENT_ACCESS,
    { subModuleName: moduleConfig.TRADE_HISTORY, component_name: moduleConfig.SHOW_RESOURCE_ID }
  )

  const downloadTradeLogAccess = checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, {
    subModuleName: moduleConfig.TRADE_HISTORY,
    component_name: moduleConfig.VIEW_TRADE_EXECUTION_LOGS
  })

  const stepDetailsColumns = [
    { field: 'localSymbol', headerName: 'Local Symbol', flex: 1 },
    { field: 'trdCode', headerName: 'Side', flex: 1 },
    {
      field: 'trdQty',
      headerName: 'Quantities',
      type: 'number',
      flex: 1,
      align: 'right',
      headerAlign: 'right',
      valueGetter: (params) => {
        return params?.row?.trdQty !== null && params?.row?.trdQty !== undefined ? params?.row?.trdQty : null
      },
      valueFormatter: (params) => {
        return params?.value != null ? params?.value?.toString() : ''
      }
    },
    {
      field: 'propMarketValue',
      headerName: 'Market Value',
      type: 'number',
      align: 'right',
      flex: 1,
      valueGetter: (params) => params?.value ? params?.value?.toFixed(0) : params?.value,
      renderCell: (params) => (
        params?.row?.propMarketValue !== undefined
          ? params?.row?.propMarketValue !== null
            ? params?.row?.propMarketValue < 0
              ? '-$' + Math.abs(Number(params?.value))?.toLocaleString()
              : '$' + Number(params?.value)?.toLocaleString()
            : ''
          : ''
      )
    },
    {
      field: 'preTradeCash',
      headerName: 'Pre Trade Cash',
      type: 'number',
      align: 'right',
      flex: 1,
      valueGetter: (params) => params?.value ? params?.value?.toFixed(0) : params?.value,
      renderCell: (params) => (
        params?.row?.preTradeCash !== undefined
          ? params?.row?.preTradeCash !== null
            ? params?.row?.preTradeCash < 0
              ? '-$' + Math.abs(Number(params?.value))?.toLocaleString()
              : '$' + Number(params?.value)?.toLocaleString()
            : ''
          : ''
      )
    },
    {
      field: 'postTradeCash',
      headerName: 'Post Trade Cash',
      type: 'number',
      align: 'right',
      flex: 1,
      valueGetter: (params) => params?.value ? params?.value?.toFixed(0) : params?.value,
      renderCell: (params) => (
        params?.row?.postTradeCash !== undefined
          ? params?.row?.postTradeCash !== null
            ? params?.row?.postTradeCash < 0
              ? '-$' + Math.abs(Number(params?.value))?.toLocaleString()
              : '$' + Number(params?.value)?.toLocaleString()
            : ''
          : ''
      )
    },
    {
      field: 'tradeStatusCode',
      headerName: 'Trade Status',
      flex: 1,
      renderCell: (params) => {
        const data = params?.value
        return (
          <Box sx={{ color: getStatusColor(data) }}>
            {data || 'N/A'}
          </Box>
        )
      }
    },
    ...(downloadTradeLogAccess
      ? [{
        field: 'actions',
        headerName: 'Trade Logs',
        width: 50,
        renderCell: (params) => <DownloadTradeLogsCell {...params} />
      }]
      : []
    )
  ]

  const handleCopyResourceClick = () => {
    navigator.clipboard.writeText(activeStepDetails?.awsRsrcExecId)
    setOpenCopyTooltip(true)
    setTimeout(() => {
      setOpenCopyTooltip(false)
      handleMoreOptionClose()
    }, 1000)
  }

  return (
    <>
      <Modal
        open={showDetailsPopup}
        onClose={() => closeDetailsPopup(false)}
      >
        <Box sx={detailsPopupStyle}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: '5px', position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 2 }}>
            <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
              <Typography variant='h6' sx={{ ml: '5px', mr: '5px', fontWeight: 400, color: '#002A59' }}>{title}</Typography>
              <Typography variant='span' sx={{ fontWeight: 400, color: '#34475A' }}>({stats})</Typography>
            </Box>
            <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
              <Box sx={{ display: 'inline-flex', alignItems: 'center', mr: '16px' }}>
                <Typography color='#74788d'>Pre Trade Cash:</Typography>
                <Tooltip title={activeStepDetails?.preTradeCashSummary !== null && activeStepDetails?.preTradeCashSummary !== undefined ? activeStepDetails?.preTradeCashSummary : ''}>
                  <Typography color={activeStepDetails?.preTradeCashSummary !== null && activeStepDetails?.preTradeCashSummary !== undefined && activeStepDetails?.preTradeCashSummary < 0 ? 'red' : '#34475a'} fontWeight='500' ml={1}>{activeStepDetails?.preTradeCashSummary !== null && activeStepDetails?.preTradeCashSummary !== undefined ? activeStepDetails?.preTradeCashSummary < 0 ? `-$${formatCurrency(Math.abs(activeStepDetails?.preTradeCashSummary))}` : `$${formatCurrency(activeStepDetails?.preTradeCashSummary)}` : '-'}</Typography>
                </Tooltip>
              </Box>
              <Box sx={{ display: 'inline-flex', alignItems: 'center', mr: '10px' }}>
                <Typography color='#74788d'>Post Trade Cash:</Typography>
                <Tooltip title={activeStepDetails?.postTradeCashSummary !== null && activeStepDetails?.postTradeCashSummary !== undefined ? activeStepDetails?.postTradeCashSummary : ''}>
                  <Typography color={activeStepDetails?.postTradeCashSummary !== null && activeStepDetails?.postTradeCashSummary !== undefined && activeStepDetails?.postTradeCashSummary < 0 ? 'red' : '#34475a'} fontWeight='500' ml={1}>{activeStepDetails?.postTradeCashSummary !== null && activeStepDetails?.postTradeCashSummary !== undefined ? activeStepDetails?.postTradeCashSummary < 0 ? `-$${formatCurrency(Math.abs(activeStepDetails?.postTradeCashSummary))}` : `$${formatCurrency(activeStepDetails?.postTradeCashSummary)}` : '-'}</Typography>
                </Tooltip>
              </Box>
              <IconButton
                aria-label='more'
                size='small'
                onClick={handleMoreOptionClick}
              >
                <MoreVert />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMoreOptionClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
              >
                <MenuItem onClick={() => exportCsv()}>
                  Export CSV
                </MenuItem>
                {
                  viewResourceIdAccess
                    ? (
                      <MenuItem onClick={handleCopyResourceClick}>
                        <Tooltip
                          PopperProps={{
                            disablePortal: true
                          }}
                          onClose={() => setOpenCopyTooltip(false)}
                          open={openCopyTooltip}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          title='Execution ID copied'
                        >
                          Copy Execution ID
                        </Tooltip>
                      </MenuItem>
                      )
                    : ''
                }
              </Menu>
              <IconButton onClick={() => closeDetailsPopup(false)} size='small'>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <DataGridPro
            autoHeight
            density='compact'
            apiRef={apiRef}
            rows={rows || []}
            columns={stepDetailsColumns}
            getRowId={(row) => row?.id}
            pagination
            rowSelection={false}
            pageSizeOptions={[15, 25, 50, 75, 100]}
            initialState={{
              pagination: { paginationModel: { pageSize: 15 } }
            }}
            sx={(theme) => ({
              '& .MuiDataGrid-cell:focus': {
                outline: 'none'
              },
              [`.${gridClasses.main}`]: {
                height: 'calc(100vh - 16px - 45px - 16px - 39px - 53px)'
              },
              [`.${gridClasses.columnHeaders}`]: {
                position: 'sticky',
                backgroundColor: theme.palette.background.paper,
                top: 0,
                zIndex: 1
              },
              [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
                color: '#74788d',
                fontWeight: 600
              },
              [`.${gridClasses.virtualScroller}`]: {
                overflowY: 'auto !important',
                scrollbarGutter: 'stable',
                scrollbarWidth: 'none'
              }
            })}
          />
        </Box>
      </Modal>
    </>
  )
}

// const StepDetailsWithConfirmation = ({ showDetailsPopup, closeDetailsPopup, title, columns }) => {
//   return (
//     <Modal
//       open={showDetailsPopup}
//       onClose={() => closeDetailsPopup(false)}
//     >
//       <Box sx={detailsPopupStyle}>
//         <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: '5px', position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 2 }}>
//           <Typography variant='h6' sx={{ ml: '5px', mr: '5px', fontWeight: 400, color: '#002A59' }}>{title}</Typography>
//           <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
//             <Button variant='text'>Export CSV</Button>
//             <IconButton onClick={() => closeDetailsPopup(false)}>
//               <CloseIcon />
//             </IconButton>
//           </Box>
//         </Box>
//         <DataGridPro
//           autoHeight
//           density='compact'
//           rows={[]}
//           columns={columns}
//           getRowId={(row) => row?.id}
//           pagination
//           pageSizeOptions={[10]}
//           initialState={{
//             pagination: { paginationModel: { pageSize: 10 } },
//           }}
//           sx={(theme) => ({
//             '& .MuiDataGrid-cell:focus': {
//               outline: 'none'
//             },
//             [`.${gridClasses.main}`]: {
//               // height: 'min(100vh - 16px - 45px - 16px - 53px, 600px - 16px - 45px - 16px - 40px - 53px)',
//             },
//             [`.${gridClasses.columnHeaders}`]: {
//               position: 'sticky',
//               backgroundColor: theme.palette.background.paper,
//               top: 0,
//               zIndex: 1,
//             },
//             [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
//               color: '#74788d',
//               fontWeight: 600
//             },
//             [`.${gridClasses.virtualScroller}`]: {
//               overflowY: 'auto !important',
//               scrollbarGutter: 'stable',
//               scrollbarWidth: 'none'
//             }
//           })}
//         />
//         <Box textAlign={'right'}>
//           <Button variant='contained'>Confirm</Button>
//         </Box>
//       </Box>
//     </Modal>
//   )
// }

export { StepDetails }
