import { getUserRole } from '../../../utils/getUserRole'
import { FormatDateAPI, FormatPayload } from '../components/FormatAPIRequest'
import { CreateAPI } from '../components/Actions'

export const CreateRequestData = async (newRow, tabIndex) => {
  switch (tabIndex) {
    case 'type-code':
      return await CreateRequestTypeCode(newRow)
    case 'status-code':
      return await CreateRequestStatusCode(newRow)
    default:
      return {}
  }
}

const CreateRequestTypeCode = async (newRow) => {
  const userRole = getUserRole()
  const startDateValue = newRow.startDate ? FormatDateAPI(newRow.startDate) : null
  const endDateValue = newRow.endDate ? FormatDateAPI(newRow.endDate) : null

  let payload = {
    typeCd: newRow?.typeCd?.toUpperCase(),
    label: newRow?.label,
    desc: newRow?.desc,
    etaHours: newRow?.etaHours,
    priority: newRow?.priority,
    startDate: startDateValue,
    endDate: endDateValue,
    requestEntity: newRow.requestEntity
  }

  payload = FormatPayload(payload)
  return CreateAPI(
    'baseRequestMaintainUrl',
    `data-maintenance/v1/${userRole}/requests/type-code`,
    payload)
}

const CreateRequestStatusCode = async (newRow) => {
  const userRole = getUserRole()
  const startDateValue = newRow.startDate ? FormatDateAPI(newRow.startDate) : null
  const endDateValue = newRow.endDate ? FormatDateAPI(newRow.endDate) : null

  let payload = {
    statusCd: newRow.statusCd?.toUpperCase(),
    label: newRow.label,
    desc: newRow.desc,
    startDate: startDateValue,
    endDate: endDateValue,
    requestEntity: newRow.requestEntity
  }

  payload = FormatPayload(payload)
  return CreateAPI(
    'baseRequestMaintainUrl',
    `data-maintenance/v1/${userRole}/requests/status-code`,
    payload)
}
