import React, { useContext } from 'react'
import { Box, Checkbox, FormControlLabel, FormGroup, Grid, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'
import { styled } from '@mui/material/styles'
import { accountDataContext, summaryDataContext } from '../Index'
import { decTwoPercentage, decTwoPercentageBeta, formatCurrencyWithSymbol } from './dataProcess/DataProcess'
import { BubbleChartTaxTE } from './summaryComponents/BubbleChartTaxTE'
import { ChartDetails } from './summaryComponents/ChartDetails'
import { useAuth } from '../../../contexts/AuthContext'

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8'
  }
}))

const BorderLinearProgressGreen = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#34C38F'
  }
}))

const scenarioSensitivity = {
  HIGH: 'Min Tax',
  MEDIUM: 'Balanced',
  LOW: 'Model'
}

const findMinTotalTax = (data) => {
  if (data) {
    return Math.min(...data?.map(item => item.taxCost))
  }
}

const findMaxTotalTax = (data) => {
  if (data) {
    return Math.max(...data?.map(item => item.taxCost))
  }
}

const findMinTrackingError = (data) => {
  if (data) {
    return Math.min(...data?.map(item => item.te))
  }
}

const findMaxTrackingError = (data) => {
  if (data) {
    return Math.max(...data?.map(item => item.te))
  }
}

export const Summary = (props) => {
  const summaryData = useContext(summaryDataContext)
  const accountData = useContext(accountDataContext)
  const [checked, setChecked] = React.useState(false)
  const [checkedLog, setCheckedLog] = React.useState(false)
  const { user } = useAuth()

  const { selectedScenario } = props
  // const highlighIntialScenario = scenarioSensitivity[accountData?.taxSensitivity]
  // selects first scenario from API response and highlights it or selects based on tax sensitivity
  const scenarioDesc = summaryData?.data?.optimizationData?.map((item) => item.scenarioDesc)
  const highlighIntialScenario = scenarioDesc && scenarioDesc.includes(scenarioSensitivity[accountData?.taxSensitivity]) ? scenarioSensitivity[accountData?.taxSensitivity] : summaryData?.data?.optimizationData?.filter((item) => item.scenarioDesc !== 'No Trade' && item.scenarioDesc !== 'Liquidation')[0]?.scenarioDesc

  const minTotalTax = findMinTotalTax(summaryData?.data?.optimizationData)
  const maxTotalTax = findMaxTotalTax(summaryData?.data?.optimizationData)

  const minTrackingError = findMinTrackingError(summaryData?.data?.optimizationData)
  const maxTrackingError = findMaxTrackingError(summaryData?.data?.optimizationData)

  const calculateTaxPercentage = (tax) => {
    if (!tax) return tax
    const percentage = (((tax - minTotalTax) * 100) / (maxTotalTax - minTotalTax))
    return percentage
  }

  const calculateTrackingErrorPercentage = (trackingError) => {
    if (!trackingError) return trackingError
    const percentage = (((trackingError - minTrackingError) * 100) / (maxTrackingError - minTrackingError))
    return percentage
  }

  const handleChange = (event) => {
    setChecked(event.target.checked)
  }

  const handleChangeLog = (event) => {
    setCheckedLog(event.target.checked)
  }

  return (
    <Box>
      <Stack>
        <Box className='trade-table-list-header'>
          <Grid xs={12} container>
            <Grid xs={8}>
              Strategy: {accountData?.strategyName || 'N/A'}
            </Grid>
            <Grid xs={4} container alignItems='top'>
              <Grid xs={6}>
                <FormGroup>
                  <FormControlLabel control={<Checkbox checked={checked} />} sx={{ fontSize: '14px', fontFamily: 'Open Sans' }} onChange={handleChange} label='Tax Cost in %' />
                </FormGroup>
              </Grid>
              <Grid xs={6}>
                <FormGroup>
                  <FormControlLabel control={<Checkbox checked={checkedLog} />} sx={{ fontFamily: 'Open Sans' }} onChange={handleChangeLog} label='Logarithmic' />
                </FormGroup>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Grid xs={12} sm={12} mt={1} container>
          <Grid xs={8} sm={8}>
            <BubbleChartTaxTE selectedScenario={selectedScenario} highlighIntialScenario={highlighIntialScenario} logCheck={checkedLog} taxCheck={checked} />
          </Grid>
          {
            (user?.userGroup === 'adv-classic' || user?.userGroup === 'spn-pm') && summaryData?.data?.showOptReport === false
              ? ''
              : <Grid xs={4} sm={4}>
                <ChartDetails selectedScenario={selectedScenario} highlighInitialScenario={highlighIntialScenario} />
                </Grid>
          }
        </Grid>
      </Stack>
      <TableContainer component={Paper} className='table-body summaryReportWrapper' sx={{ marginTop: 3 }}>
        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
          <TableHead>
            <TableRow sx={{ fontFamily: 'Open Sans', fontWeight: 600 }}>
              <TableCell className='table-head-summary'>Scenario Name</TableCell>
              <TableCell className='table-head-summary' align='center'>Tracking Error(%)</TableCell>
              <TableCell className='table-head-summary' align='center'>Standard Deviation</TableCell>
              <TableCell className='table-head-summary' align='center'>Beta</TableCell>
              <TableCell className='table-head-summary' align='center'>No. of Stocks</TableCell>
              <TableCell className='table-head-summary' align='center'>Buy</TableCell>
              <TableCell className='table-head-summary' align='center'>Sell</TableCell>
              <TableCell className='table-head-summary' align='center' colSpan={2} sx={{ paddingBottom: '0px !important' }}>
                <Table>
                  <TableRow>
                    <TableCell className='table-head-summary unrealized-column-header top-column' align='center' colSpan={2}>Realized Gain ($)</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className='table-head-summary unrealized-column-header' align='center'>LT</TableCell>
                    <TableCell className='table-head-summary unrealized-column-header' align='center'>ST</TableCell>
                  </TableRow>
                </Table>
              </TableCell>
              <TableCell className='table-head-summary' align='center' colSpan={2} sx={{ paddingBottom: '0px !important' }}>
                <Table>
                  <TableRow>
                    <TableCell className='table-head-summary unrealized-column-header top-column' align='center' colSpan={2}>Realized Loss ($)</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className='table-head-summary unrealized-column-header' align='center'>LT</TableCell>
                    <TableCell className='table-head-summary unrealized-column-header' align='center'>ST</TableCell>
                  </TableRow>
                </Table>
              </TableCell>
              <TableCell className='table-head-summary' align='center'>Total Taxes($)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(!summaryData?.data?.optimizationData || summaryData.data.optimizationData.length === 0)
              ? (
                <TableRow>
                  <TableCell colSpan={12} align='center'>
                    No rows
                  </TableCell>
                </TableRow>)
              : (
                  summaryData.data.optimizationData?.map((row) => (
                    <TableRow
                      key={row.scenarioDesc}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component='th' scope='row' className='table-cell'>
                        {row.scenarioDesc}
                      </TableCell>
                      <TableCell align='center' className='table-cell'>
                        {decTwoPercentage(row.te, 2)}
                        {row.scenarioDesc === selectedScenario || (selectedScenario === '' && row.scenarioDesc === highlighIntialScenario)
                          ? <BorderLinearProgressGreen variant='determinate' value={calculateTrackingErrorPercentage(row.te)} />
                          : <BorderLinearProgress variant='determinate' value={calculateTrackingErrorPercentage(row.te)} />}
                      </TableCell>
                      <TableCell align='center' className='table-cell'>
                        {decTwoPercentage(row.risk, 2)}
                      </TableCell>
                      <TableCell align='center' className='table-cell'>
                        {decTwoPercentageBeta(row.modelBeta, 2)}
                      </TableCell>
                      <TableCell align='center' className='table-cell'>{row.numHoldings}</TableCell>
                      <TableCell align='center' className='table-cell'>{row.numBuy}</TableCell>
                      <TableCell align='center' className='table-cell'>{row.numSell}</TableCell>
                      <TableCell align='center' className='table-cell'>
                        {row?.ltRg !== null && row?.ltRg !== undefined ? formatCurrencyWithSymbol(row?.ltRg, 2, '') : ''}
                      </TableCell>
                      <TableCell align='center' className='table-cell'>
                        {row?.stRg !== null && row?.stRg !== undefined ? formatCurrencyWithSymbol(row?.stRg, 2, '') : ''}
                      </TableCell>
                      <TableCell align='center' className='table-cell'>
                        {row?.ltRl !== null && row?.ltRl !== undefined ? formatCurrencyWithSymbol(row?.ltRl, 2, '') : ''}
                      </TableCell>
                      <TableCell align='center' className='table-cell'>
                        {row?.stRl !== null && row?.stRl !== undefined ? formatCurrencyWithSymbol(row?.stRl, 2, '') : ''}
                      </TableCell>
                      <TableCell align='center' className='table-cell'>{formatCurrencyWithSymbol(row.taxCost, 2, '')}
                        {row.scenarioDesc === selectedScenario || (selectedScenario === '' && row.scenarioDesc === highlighIntialScenario)
                          ? <BorderLinearProgressGreen variant='determinate' value={calculateTaxPercentage(row.taxCost)} />
                          : <BorderLinearProgress variant='determinate' value={calculateTaxPercentage(row.taxCost)} />}
                      </TableCell>
                    </TableRow>
                  )
                  )
                )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ fontSize: '12px', marginLeft: '10px', color: '#34475A' }}>* No Trade is current portfolio, Liquidation is moving to strategy portfolio.</Box>
    </Box>
  )
}
