import { memo } from 'react'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined'
import SwapHorizOutlinedIcon from '@mui/icons-material/SwapHorizOutlined'
import { Box, Divider, Tooltip, Typography } from '@mui/material'
import { Handle, Position } from '@xyflow/react'
import { formatCurrencyWithSymbol } from '../../../../utils/FormateCurrenyInMilion'

export default memo(({ data, isConnectable }) => {
  return (
    <>
      <Handle
        type='source'
        position={Position.Right}
        isConnectable={isConnectable}
      />
      <Handle
        type='target'
        position={Position.Left}
        isConnectable={isConnectable}
      />
      <Box sx={{ fontFamily: 'Open Sans' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box>
            <Tooltip title={data.accountName || ''}><Typography sx={{ fontSize: '20px', fontWeight: 600, color: '#2F2F2F', maxWidth: '300px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{data.accountName}</Typography></Tooltip>
            <Typography sx={{ fontSize: '18px', fontWeight: 600, color: '#2F2F2F' }}>{formatCurrencyWithSymbol(data.portMv, 2, '$')}</Typography>
          </Box>
          <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '3px' }}>
              <Typography sx={{ fontSize: '16px', fontWeight: 400, color: '#2F2F2F' }}>  {data.transferredShares}</Typography>
              <SwapHorizOutlinedIcon sx={{ fontSize: '18px', color: '#3BBFA3' }} />
            </Box>
            {
              data.type === 'SCENARIO_TRANSITION'
                ? (
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '3px' }}>
                    <Typography sx={{ fontSize: '16px', fontWeight: 400, color: '#2F2F2F' }}>  {data.buyShares}</Typography>
                    <AddCircleOutlineOutlinedIcon sx={{ fontSize: '16px', color: '#3A76D4' }} />
                  </Box>
                )
                : (
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '3px' }}>
                    <Typography sx={{ fontSize: '16px', fontWeight: 400, color: '#2F2F2F' }}>  {data.sellShares}</Typography>
                    <RemoveCircleOutlineOutlinedIcon sx={{ fontSize: '16px', color: '#FF6161' }} />
                  </Box>
                )
            }
          </Box>
        </Box>
        <Divider sx={{ my: '8px' }} />
        {
          data.type === 'SCENARIO_TRANSITION'
            ? <Box sx={{ marginTop: '10px' }}>
              {
                !data.isVirtualAccount ? (
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box sx={{ fontSize: '16px', fontWeight: 400, color: '#74788D' }}>Tracking Error</Box>
                    <Box sx={{ fontSize: '16px', fontWeight: 600, color: '#2F2F2F' }}>
                      {((data.propTe || 0) * 100)?.toFixed(2)}%
                    </Box>
                  </Box>
                ) : ''
              }
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box sx={{ fontSize: '16px', fontWeight: 400, color: '#74788D' }}>Unrealized Gain/Loss</Box>
                <Box sx={{ fontSize: '16px', fontWeight: 600, color: '#2F2F2F' }}>
                  {formatCurrencyWithSymbol(data.totalUrgl, 2, '$')}
                </Box>
              </Box>
            </Box>
            : <Box sx={{ marginTop: '10px' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box sx={{ fontSize: '16px', fontWeight: 400, color: '#74788D' }}>Tax Cost</Box>
                <Box sx={{ fontSize: '16px', fontWeight: 600, color: '#2F2F2F' }}>
                  {formatCurrencyWithSymbol(data.taxCost, 2, '$')}
                </Box>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box sx={{ fontSize: '16px', fontWeight: 400, color: '#74788D' }}>Realized Gain/Loss</Box>
                <Box sx={{ fontSize: '16px', fontWeight: 600, color: '#2F2F2F' }}>
                  {formatCurrencyWithSymbol(data.rgl, 2, '$')}
                </Box>
              </Box>
            </Box>
        }
      </Box>
    </>
  )
})
