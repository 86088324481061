import { Button, Checkbox, FormControlLabel, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'

const AnnotationMapping = {
  NET_MV_PLUS_CASH: 'Net Market Value',
  TRADE_DETAILS: 'Trade details',
  DIV_AND_INT: 'Dividend',
  DEP_AND_WIT: 'Withdrawal'
}

const AnnotationCheckboxList = ({ chartData, applySelectedAnnotations }) => {
  const [selectedAnnotations, setSelectedAnnotations] = useState(chartData?.selectedAnnotations || [])

  const handleCheckboxChange = (value) => {
    const currentIndex = selectedAnnotations.indexOf(value)
    const newChecked = [...selectedAnnotations]

    if (currentIndex === -1) {
      if (selectedAnnotations.length <= 8) {
        newChecked.push(value)
      }
    } else {
      if (selectedAnnotations.length >= 2) {
        newChecked.splice(currentIndex, 1)
      }
    }

    setSelectedAnnotations(newChecked)
  }

  return (
    <>
      {chartData?.annotationList?.filter(key => key !== 'NET_MV_PLUS_CASH')?.length
        ? <>
          <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Select Annotation</Typography>
          {chartData?.annotationList?.filter(key => key !== 'NET_MV_PLUS_CASH').map((option) => (
            <div key={option}>
              <FormControlLabel
                sx={{
                  '& .MuiCheckbox-root': {
                    padding: '2px 9px'
                  },
                  '.MuiFormControlLabel-label': {
                    fontSize: '14px',
                    textTransform: 'capitalize'
                  }
                }}
                control={
                  <Checkbox
                    size='12px'
                    checked={selectedAnnotations.includes(option)}
                    onChange={() => handleCheckboxChange(option)}
                    sx={{
                      borderRadius: '10px'
                    }}
                  />
                }
                label={AnnotationMapping[option]}
              />
            </div>
          ))}
          <Box textAlign='right'>
            <Button
              variant='contained'
              size='small'
              sx={{
                marginTop: '15px'
              }}
              onClick={() => { applySelectedAnnotations(selectedAnnotations) }}
            >Apply
            </Button>
          </Box>
          </>
        : ''}
    </>
  )
}

export default AnnotationCheckboxList
