import { getUserRole } from '../../../utils/getUserRole'
import { GetAPI } from '../components/Actions'

const reportDetailsAPI = async (parameter) => {
  const userRole = getUserRole()
  return GetAPI('baseReportingMaintainURL', `data-maintenance/v1/${userRole}/reports/${parameter}`)
}

export const GetReportData = async () => {
  const apiCalls = [
    reportDetailsAPI('stats-level-code'),
    reportDetailsAPI('stats-mast')
  ]

  const response = await Promise.allSettled(apiCalls)
  const [statsLevelCodeAPIData, statsMasterAPIData] = response
  return [statsLevelCodeAPIData?.value, statsMasterAPIData?.value]
}
