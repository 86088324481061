import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DateRangePicker } from '@mui/x-date-pickers-pro'
import { Box, Button, Typography } from '@mui/material'

const DateSelection = ({ chartName, selectedStartDate, selectedEndDate, minStartDate, minEndDate, applyDateFilter }) => {
  const [dateRange, setDateRange] = useState([
    dayjs(selectedStartDate),
    dayjs(selectedEndDate)
  ])

  useEffect(() => {
    setDateRange([
      dayjs(selectedStartDate),
      dayjs(selectedEndDate)
    ])
  }, [selectedStartDate, selectedEndDate])

  const resetDateRange = () => {
    applyDateFilter([dayjs(minStartDate),
      dayjs(minEndDate)], true)
    setDateRange([dayjs(minStartDate),
      dayjs(minEndDate)])
  }

  return (
    <>
      <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Select Date Range</Typography>
      <Box sx={{ marginTop: '15px' }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateRangePicker
            startText='Start Date'
            endText='End Date'
            format='YYYY-MM-DD'
            value={dateRange}
            minDate={dayjs(minStartDate)}
            calendars={1}
            maxDate={dayjs(minEndDate)}
            sx={{
              '.MuiOutlinedInput-input': {
                height: '1.2em'
              },
              '.MuiTextField-root': {
                marginLeft: '0'
              },
              '.MuiMultiInputDateRangeField-separator': {
                marginLeft: '0',
                margin: '5px'
              }
            }}
            slotProps={{ textField: { size: 'small' } }}
            onChange={(newValue) => setDateRange([dayjs(newValue[0]), dayjs(newValue[1])])}
          />
        </LocalizationProvider>
      </Box>
      <Box textAlign='right'>
        <Button
          variant='text'
          size='small'
          sx={{
            marginTop: '15px',
            marginRight: '10px'
          }}
          onClick={() => resetDateRange()}
        >Reset
        </Button>
        <Button
          variant='contained'
          size='small'
          sx={{
            marginTop: '15px'
          }}
          onClick={() => applyDateFilter(dateRange)}
        >Apply
        </Button>
      </Box>
    </>
  )
}

export default DateSelection
