import { OutlinedInput, styled } from '@mui/material'

const CustomOutlinedInput = styled(OutlinedInput)(({ theme }) => ({
  border: '0.5px solid #6E8195',
  borderRadius: '10px',
  padding: '15px 20px',
  '& .MuiOutlinedInput-input': {
    padding: 0,
    borderRadius: '0 !important'
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  }
}))

export default CustomOutlinedInput
