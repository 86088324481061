import { Box, Button, Typography } from '@mui/material'

const DownloadDocument = ({ selectFileType }) => {
  return (
    <>
      <Typography sx={{
        fontWeight: '500',
        fontSize: '20px',
        color: '#34475A',
        fontFamily: 'Open Sans !important',
        textAlign: 'center'
      }}
      >Select document
      </Typography>
      <Box display='flex' justifyContent='center' pt={2}>
        <Button variant='contained' onClick={() => selectFileType('ORIGINAL')} sx={{ mr: 2 }}>Download original</Button>
        <Button variant='contained' onClick={() => selectFileType('LATEST')}>Download signed</Button>
      </Box>
    </>
  )
}

export default DownloadDocument
