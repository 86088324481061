import React, { useEffect, useRef, useState } from 'react'
import { Checkbox, FormControlLabel, FormGroup, Grid } from '@mui/material'
import { Chart } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { decTwoPlaces } from '../../../Report/components/dataProcess/DataProcess'
import { formatCurrency, formatCurrencyWithSymbol } from '../../../../utils/FormateCurrenyInMilion'
Chart.register(ChartDataLabels)

let percentageChart = false
let logChart = false
let normalChart = true
let logPercentageChart = false
const convertedDataBubbleChart = (data, portMv = 1, logCheck, taxCheck) => {
  if (!logCheck && !taxCheck) {
    normalChart = true
    logChart = false
    logPercentageChart = false
    percentageChart = false
  } else if (logCheck && !taxCheck) {
    normalChart = false
    logChart = true
    logPercentageChart = false
    percentageChart = false
  } else if (!logCheck && taxCheck) {
    normalChart = false
    logChart = false
    logPercentageChart = false
    percentageChart = true
  } else {
    normalChart = false
    logChart = false
    logPercentageChart = true
    percentageChart = false
  }
  const convertedDataBubble = data?.map((item) => ({
    x: logPercentageChart || percentageChart ? (item?.taxCost / portMv) * 100 : item?.taxCost,
    y: decTwoPlaces((item?.propTe || 0) * 100, 2) >= 10 ? 10 : decTwoPlaces((item?.propTe || 0) * 100, 2),
    r: 18,
    name: item.aggName
  }))

  /// /////////////////////////// Bubble ////////////////////////////
  let minNegativeX = 0
  if (logPercentageChart || percentageChart || logChart) {
    // Find the minimum negative value for x
    for (let i = 0; i < convertedDataBubble?.length; i++) {
      if (convertedDataBubble[i].x < 0 && convertedDataBubble[i].x < minNegativeX) {
        minNegativeX = convertedDataBubble[i].x
      }
    }
  }

  if (logPercentageChart || percentageChart || logChart) {
    // Add the absolute value of the minimum negative value to all x values
    for (let i = 0; i < convertedDataBubble?.length; i++) {
      convertedDataBubble[i].x += Math.abs(minNegativeX)
    }
  }

  if (logPercentageChart || logChart) {
    // Add the absolute value of the minimum negative value to all x values
    for (let i = 0; i < convertedDataBubble?.length; i++) {
      if (convertedDataBubble[i].y === '0.00') {
        convertedDataBubble[i].y = 0.001
      }
    }
  }

  let maxX = 0
  // let maxY = 0
  if (logPercentageChart || percentageChart) {
    for (let i = 0; i < convertedDataBubble?.length; i++) {
      if (convertedDataBubble[i].x > maxX) {
        maxX = convertedDataBubble[i].x
      }
      // if (convertedDataBubble[i].y > maxY) {
      //   maxY = convertedDataBubble[i].y
      // }
    }
    maxX = Math.ceil(maxX + 3)
    // maxY = Math.ceil(maxY + 3)
  }
  return { convertedDataBubble, maxX }
}

const convertedDataLineChart = (data, portMv=1, logCheck, taxCheck) => {
  if (!logCheck && !taxCheck) {
    normalChart = true
    logChart = false
    logPercentageChart = false
    percentageChart = false
  } else if (logCheck && !taxCheck) {
    normalChart = false
    logChart = true
    logPercentageChart = false
    percentageChart = false
  } else if (!logCheck && taxCheck) {
    normalChart = false
    logChart = false
    logPercentageChart = false
    percentageChart = true
  } else {
    normalChart = false
    logChart = false
    logPercentageChart = true
    percentageChart = false
  }
  const convertedDataLine = []
  data?.forEach(item => {
    convertedDataLine.push({
      x: logPercentageChart || percentageChart ? (item?.taxCost / portMv) * 100 : item?.taxCost,
      y: decTwoPlaces((item?.propTe || 0) * 100, 2) >= 10 ? 10.00 : decTwoPlaces((item?.propTe || 0) * 100, 2)
    })
  })

  /// /////////////////////////// Line ////////////////////////////
  let minNegativeX = 0
  if (logPercentageChart || percentageChart || logChart) {
    // Find the minimum negative value for x
    for (let i = 0; i < convertedDataLine?.length; i++) {
      if (convertedDataLine[i].x < 0 && convertedDataLine[i].x < minNegativeX) {
        minNegativeX = convertedDataLine[i].x
      }
    }
  }
  if (logPercentageChart || percentageChart || logChart) {
    // Add the absolute value of the minimum negative value to all x values
    for (let i = 0; i < convertedDataLine?.length; i++) {
      convertedDataLine[i].x += Math.abs(minNegativeX)
    }
  }

  if (logPercentageChart || logChart) {
    // Add the absolute value of the minimum negative value to all x values
    for (let i = 0; i < convertedDataLine?.length; i++) {
      if (convertedDataLine[i].y === '0.00') {
        convertedDataLine[i].y = 0.001
      }
    }
  }
  convertedDataLine.sort((a, b) => parseFloat(a.y) - parseFloat(b.y))
  return { convertedDataLine }
}

const SummaryTaxCostChart = ({ scenarioData }) => {
  const chartRefBub = useRef(null)
  const [taxCheck, setTaxCheck] = useState(false)
  const [logCheck, setLogCheck] = useState(false)

  // summary data fetched from DB
  const bubbleLineData = convertedDataLineChart(scenarioData, (scenarioData?.length && scenarioData[0]?.portMv), logCheck, taxCheck)
  const bubbleChartDataStr = convertedDataBubbleChart(scenarioData, (scenarioData?.length && scenarioData[0]?.portMv), logCheck, taxCheck)
  const bubbleColorChartData = scenarioData?.map((item) => '#8BAEE1')

  useEffect(() => {
    if (!logCheck && !taxCheck) {
      normalChart = true
      logChart = false
      logPercentageChart = false
      percentageChart = false
    } else if (logCheck && !taxCheck) {
      normalChart = false
      logChart = true
      logPercentageChart = false
      percentageChart = false
    } else if (!logCheck && taxCheck) {
      normalChart = false
      logChart = false
      logPercentageChart = false
      percentageChart = true
    } else {
      normalChart = false
      logChart = false
      logPercentageChart = true
      percentageChart = false
    }
    const chartCanvas = chartRefBub.current
    if (logPercentageChart) {
      if (chartCanvas) {
        const myChartBub = new Chart(chartCanvas.getContext('2d'), {
          type: 'bubble',
          data: {
            datasets: [
              {
                label: 'Bubble Dataset',
                data: [...bubbleChartDataStr.convertedDataBubble],
                backgroundColor: bubbleColorChartData
              },
              {
                type: 'line',
                label: 'line',
                data: [...bubbleLineData.convertedDataLine],
                fill: false,
                borderColor: '#8BAEE1',
                pointRadius: 0,
                borderWidth: 2
              }
            ]
          },
          options: {
            responsive: true,
            tension: 0.1, // adjust curve shape
            cubicInterpolationMode: 'monotone', // set interpolation mode
            maintainAspectRatio: false,
            devicePixelRatio: 2,
            animation: { duration: 0 },
            hover: { mode: null },
            plugins: {
              tooltip: {
                enabled: true,
                callbacks: {
                  label: (context) => {
                    const value = context.dataset.data[context.dataIndex]
                    return [`Scenario : ${value.name || 'N/A'}`, `Tax Cost :  ${decTwoPlaces(value.x || 0)}%`, `Tracking Error :  ${value.y}%`]
                  }
                }
              },
              datalabels: {
                color: 'white',
                formatter: (context) => {
                  if (context.r) {
                    return context.y < 10 ? context.y : '>10'
                  } else {
                    return ''
                  }
                },
                font: (context) => {
                  const value = context.dataset.data[context.dataIndex]
                  if (value.r) {
                    if (value.r === 25) {
                      return {
                        size: '18px'
                      }
                    } else {
                      return {
                        size: '12px'
                      }
                    }
                  }
                }
              },
              legend: { display: false }
            },
            elements: {
              point: { radius: 25 }
            },
            scales: {
              x: {
                max: bubbleChartDataStr.maxX,
                type: 'logarithmic',
                ticks: {
                  callback: function (value, index, values) {
                    return logCheck ? value : formatCurrency(value)
                  },
                  maxTicksLimit: 6
                },
                title: {
                  display: true,
                  text: percentageChart || logPercentageChart ? 'Total Taxes (% in respective of total portfolio value)' : 'Total Taxes ($)',
                  padding: {
                    top: 16 // Set the top padding to 16 pixels
                  },
                  font: {
                    size: 13 // Set the font size to 14 pixels
                  }
                }
              },
              y: {
                type: 'logarithmic',
                ticks: {
                  beginAtZero: true,
                  callback: function (value, index, values) {
                    return logCheck ? value : formatCurrency(value)
                  },
                  maxTicksLimit: 6 // Set the maximum number of tick marks to 3
                },
                title: {
                  display: true,
                  text: 'Tracking Error (%)',
                  padding: {
                    bottom: 16 // Set the top padding to 16 pixels
                  },
                  font: {
                    size: 13 // Set the font size to 14 pixels
                  }
                }
              }
            }
          }
        })
        return () => {
          myChartBub?.destroy()
        }
      }
    } else if (normalChart) {
      if (chartCanvas) {
        const myChartBub = new Chart(chartCanvas.getContext('2d'), {
          type: 'bubble',
          data: {
            datasets: [
              {
                label: 'Bubble Dataset',
                data: [...bubbleChartDataStr.convertedDataBubble],
                backgroundColor: bubbleColorChartData
              },
              {
                type: 'line',
                label: 'line',
                data: [...bubbleLineData.convertedDataLine],
                fill: false,
                borderColor: '#8BAEE1',
                pointRadius: 0,
                borderWidth: 2
              }
            ]
          },
          options: {
            responsive: true,
            tension: 0.1, // adjust curve shape
            cubicInterpolationMode: 'monotone', // set interpolation mode
            maintainAspectRatio: false,
            animation: { duration: 0 },
            hover: { mode: null },
            devicePixelRatio: 2,
            plugins: {
              tooltip: {
                enabled: true,
                callbacks: {
                  label: (context) => {
                    const value = context.dataset.data[context.dataIndex]
                    return [`Scenario : ${value.name || 'N/A'}`, `Tax Cost :  ${formatCurrencyWithSymbol(value.x || 0, 2, '$')}`, `Tracking Error :  ${value.y}%`]
                  }
                }
              },
              datalabels: {
                color: 'white',
                formatter: (context) => {
                  if (context.r) {
                    return context.y < 10 ? context.y : '>10'
                  } else {
                    return ''
                  }
                },
                font: (context) => {
                  const value = context.dataset.data[context.dataIndex]
                  if (value.r) {
                    if (value.r === 25) {
                      return {
                        size: '18px'
                      }
                    } else {
                      return {
                        size: '12px'
                      }
                    }
                  }
                }
              },
              legend: { display: false }
            },
            elements: {
              point: { radius: 25 }
            },
            scales: {
              x: {
                ticks: {
                  callback: function (value, index, values) {
                    return logCheck ? value : formatCurrency(value)
                  },
                  maxTicksLimit: 6
                },
                title: {
                  display: true,
                  text: 'Total Taxes ($)',
                  padding: {
                    top: 16 // Set the top padding to 16 pixels
                  },
                  font: {
                    size: 13 // Set the font size to 14 pixels
                  }
                }
              },
              y: {
                ticks: {
                  beginAtZero: true,
                  callback: function (value, index, values) {
                    return logCheck ? value : formatCurrency(value)
                  },
                  maxTicksLimit: 6 // Set the maximum number of tick marks to 3
                },
                title: {
                  display: true,
                  text: 'Tracking Error (%)',
                  padding: {
                    bottom: 16 // Set the top padding to 16 pixels
                  },
                  font: {
                    size: 13 // Set the font size to 14 pixels
                  }
                }
              }
            }
          }
        })
        return () => {
          myChartBub.destroy()
        }
      }
    } else if (percentageChart) {
      if (chartCanvas) {
        const myChartBub = new Chart(chartCanvas.getContext('2d'), {
          type: 'bubble',
          data: {
            datasets: [
              {
                label: 'Bubble Dataset',
                data: [...bubbleChartDataStr.convertedDataBubble],
                backgroundColor: bubbleColorChartData
              },
              {
                type: 'line',
                label: 'line',
                data: [...bubbleLineData.convertedDataLine],
                fill: false,
                borderColor: '#8BAEE1',
                pointRadius: 0,
                borderWidth: 2
              }
            ]
          },
          options: {
            responsive: true,
            tension: 0.1, // adjust curve shape
            cubicInterpolationMode: 'monotone', // set interpolation mode
            maintainAspectRatio: false,
            devicePixelRatio: 2,
            animation: { duration: 0 },
            hover: { mode: null },
            plugins: {
              tooltip: {
                enabled: true,
                callbacks: {
                  label: (context) => {
                    const value = context.dataset.data[context.dataIndex]
                    return [`Scenario : ${value.name || 'N/A'}`, `Tax Cost :  ${decTwoPlaces(value.x || 0)}%`, `Tracking Error :  ${value.y}%`]
                  }
                }
              },
              datalabels: {
                color: 'white',
                formatter: (context) => {
                  if (context.r) {
                    return context.y < 10 ? context.y : '>10'
                  } else {
                    return ''
                  }
                },
                font: (context) => {
                  const value = context.dataset.data[context.dataIndex]
                  if (value.r) {
                    if (value.r === 25) {
                      return {
                        size: '18px'
                      }
                    } else {
                      return {
                        size: '12px'
                      }
                    }
                  }
                }
              },
              legend: { display: false }
            },
            elements: {
              point: { radius: 25 }
            },
            scales: {
              x: {
                max: bubbleChartDataStr.maxX,
                ticks: {
                  callback: function (value, index, values) {
                    return logCheck ? value : formatCurrency(value)
                  },
                  maxTicksLimit: 6
                },
                title: {
                  display: true,
                  text: percentageChart || logPercentageChart ? 'Total Taxes (% in respective of total portfolio value)' : 'Total Taxes ($)',
                  padding: {
                    top: 16 // Set the top padding to 16 pixels
                  },
                  font: {
                    size: 13 // Set the font size to 14 pixels
                  }
                }
              },
              y: {
                // max: bubbleChartDataStr.maxY,
                ticks: {
                  beginAtZero: true,
                  callback: function (value, index, values) {
                    return logCheck ? value : formatCurrency(value)
                  },
                  maxTicksLimit: 6 // Set the maximum number of tick marks to 3
                },
                title: {
                  display: true,
                  text: 'Tracking Error (%)',
                  padding: {
                    bottom: 16 // Set the top padding to 16 pixels
                  },
                  font: {
                    size: 13 // Set the font size to 14 pixels
                  }
                }
              }
            }
          }
        })
        return () => {
          myChartBub?.destroy()
        }
      }
    } else {
      if (chartCanvas) {
        const myChartBub = new Chart(chartCanvas.getContext('2d'), {
          type: 'bubble',
          data: {
            datasets: [
              {
                label: 'Bubble Dataset',
                data: [...bubbleChartDataStr.convertedDataBubble],
                backgroundColor: bubbleColorChartData
              },
              {
                type: 'line',
                label: 'line',
                data: [...bubbleLineData.convertedDataLine],
                fill: false,
                borderColor: '#8BAEE1',
                pointRadius: 0,
                borderWidth: 2
              }
            ]
          },
          options: {
            responsive: true,
            tension: 0.1, // adjust curve shape
            cubicInterpolationMode: 'monotone', // set interpolation mode
            maintainAspectRatio: false,
            devicePixelRatio: 2,
            animation: { duration: 0 },
            hover: { mode: null },
            plugins: {
              tooltip: {
                enabled: true,
                callbacks: {
                  label: (context) => {
                    const value = context.dataset.data[context.dataIndex]
                    return [`Scenario : ${value.name || 'N/A'}`, `Tax Cost :  ${formatCurrencyWithSymbol(value.x || 0, 2, '$')}`, `Tracking Error :  ${value.y}%`]
                  }
                }
              },
              datalabels: {
                color: 'white',
                formatter: (context) => {
                  if (context.r) {
                    return context.y < 10 ? context.y : '>10'
                  } else {
                    return ''
                  }
                },
                font: (context) => {
                  const value = context.dataset.data[context.dataIndex]
                  if (value.r) {
                    if (value.r === 25) {
                      return {
                        size: '18px'
                      }
                    } else {
                      return {
                        size: '12px'
                      }
                    }
                  }
                }
              },
              legend: { display: false }
            },
            elements: {
              point: { radius: 25 }
            },
            scales: {
              x: {
                type: 'logarithmic',
                ticks: {
                  callback: function (value, index, values) {
                    return formatCurrency(value)
                  },
                  maxTicksLimit: 6
                },
                title: {
                  display: true,
                  text: 'Total Taxes ($)',
                  padding: {
                    top: 16 // Set the top padding to 16 pixels
                  },
                  font: {
                    size: 13 // Set the font size to 13 pixels
                  }
                }
              },
              y: {
                ticks: {
                  beginAtZero: true,
                  callback: function (value, index, values) {
                    return value
                  },
                  maxTicksLimit: 6 // Set the maximum number of tick marks to 3
                },
                title: {
                  display: true,
                  text: 'Tracking Error (%)',
                  padding: {
                    bottom: 16 // Set the top padding to 16 pixels
                  },
                  font: {
                    size: 13 // Set the font size to 13 pixels
                  }
                }
              }
            }
          }
        })
        return () => {
          myChartBub.destroy()
        }
      }
    }
  }, [bubbleLineData, bubbleChartDataStr, logCheck, taxCheck])

  const handleTaxCheckChange = (event) => {
    setTaxCheck(event.target.checked)
  }

  const handleLogCheckChangeLog = (event) => {
    setLogCheck(event.target.checked)
  }
  return (
    <>
      <Grid container justifyContent='flex-end'>
        <Grid item>
          <FormGroup>
            <FormControlLabel control={<Checkbox checked={taxCheck} />} sx={{ fontSize: '14px', fontFamily: 'Open Sans' }} onChange={handleTaxCheckChange} label='Tax Cost in %' />
          </FormGroup>
        </Grid>
        <Grid item>
          <FormGroup>
            <FormControlLabel control={<Checkbox checked={logCheck} />} sx={{ fontFamily: 'Open Sans' }} onChange={handleLogCheckChangeLog} label='Logarithmic' />
          </FormGroup>
        </Grid>
      </Grid>
      <Grid container p={2} justifyContent='center'>
        <Grid xs={12} sm={12} lg={9} height={400}>
          <canvas id='myChartBubble' ref={chartRefBub} />
        </Grid>
      </Grid>
    </>
  )
}

export default SummaryTaxCostChart
