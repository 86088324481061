import { getUserRole } from '../../../utils/getUserRole'
import { FormatPayload } from '../components/FormatAPIRequest'
import { UpdateAPI } from '../components/Actions'

export const UpdateMasterData = async (updateRow, oldRow, tabIndex) => {
  switch (tabIndex) {
    case 'account-status-codes':
      return await UpdateAccountStatusCode(updateRow, oldRow)
    case 'restriction-codes':
      return await UpdateRestrictionCode(updateRow, oldRow)
    case 'sell-logic-codes':
      return await UpdateSellLogicCode(updateRow, oldRow)
    case 'tax-states':
      return await UpdateTaxCode(updateRow, oldRow)
    case 'property-codes':
      return await UpdatePropertyCode(updateRow, oldRow)
    default:
      return {}
  }
}

const UpdateAccountStatusCode = async (updateRow, oldRow) => {
  const userRole = getUserRole()
  let payload = {
    accountStatusCode: updateRow.accountStatusCode?.toUpperCase(),
    accountStatusName: updateRow.accountStatusName,
    accountStatusDesc: updateRow.accountStatusDesc
  }

  payload = FormatPayload(payload)
  return UpdateAPI(
    'baseUriMasterData',
    `data-maintenance/v1/${userRole}/account-status-codes/${oldRow?.accountStatusId}`,
    payload)
}

const UpdateRestrictionCode = async (updateRow, oldRow) => {
  const userRole = getUserRole()
  let payload = {
    restrictionCode: updateRow.restrictionCode?.toUpperCase(),
    restrictionDesc: updateRow.restrictionDesc
  }

  payload = FormatPayload(payload)
  return UpdateAPI(
    'baseUriMasterData',
    `data-maintenance/v1/${userRole}/restriction-codes/${oldRow?.restrictionId}`,
    payload)
}

const UpdateSellLogicCode = async (updateRow, oldRow) => {
  const userRole = getUserRole()
  let payload = {
    sellLogicCode: updateRow.sellLogicCode?.toUpperCase(),
    sellLogicDesc: updateRow.sellLogicDesc
  }

  payload = FormatPayload(payload)
  return UpdateAPI(
    'baseUriMasterData',
    `data-maintenance/v1/${userRole}/sell-logic-codes/${oldRow?.sellLogicId}`,
    payload)
}

const UpdateTaxCode = async (updateRow, oldRow) => {
  const userRole = getUserRole()
  let payload = {
    stateName: updateRow.stateName,
    stateAbbr: updateRow.stateAbbr
  }

  payload = FormatPayload(payload)
  return UpdateAPI(
    'baseUriMasterData',
    `data-maintenance/v1/${userRole}/tax-states/${oldRow?.stateId}`,
    payload)
}

const UpdatePropertyCode = async (updateRow, oldRow) => {
  const userRole = getUserRole()
  let payload = {
    propertyCode: updateRow.propertyCode?.toUpperCase(),
    propertyDesc: updateRow.propertyDesc,
    dataType: updateRow.dataType
  }

  payload = FormatPayload(payload)
  return UpdateAPI(
    'baseUriMasterData',
    `data-maintenance/v1/${userRole}/property-codes/${oldRow?.propertyCode}`,
    payload)
}
