import { getUserRole } from '../../../utils/getUserRole'
import { GetAPI } from '../components/Actions'

export const getRestrictedCodes = async () => {
  const userRole = getUserRole()
  return GetAPI(
    'baseUriTransactionalMaster',
    `transactional-master/v1/${userRole}/restriction-codes`
  )
    .then((response) => {
      if (response.data) {
        return [...response.data]
      }
      return []
    })
    .catch(() => {
      return []
    })
}

const strategyAPI = async (id, parameter) => {
  const userRole = getUserRole()
  return GetAPI(
    'baseStrategyMaintainURL',
    `data-maintenance/v1/${userRole}/strategies/${id}/${parameter}`
  )
}

const strategyPropertiesAPI = async (id) => {
  const userRole = getUserRole()
  return GetAPI(
    'baseStrategyMaintainURL',
    `data-maintenance/v1/${userRole}/strategies-properties/${id}`
  )
}

export const GetAllStrategyBenchmark = async () => {
  const userRole = getUserRole()
  return GetAPI(
    'baseStrategyMaintainURL',
    `data-maintenance/v1/${userRole}/strategies/bench-master`
  )
}

export const strategyDetailsAPI = async (id, parameter) => {
  const userRole = getUserRole()
  return GetAPI(
    'baseStrategyMaintainURL',
    `data-maintenance/v1/${userRole}/strategies/${parameter}/${id}`
  )
}

export const GetStrategyData = async (id) => {
  const apiCalls = [
    strategyAPI(id, 'master'),
    strategyDetailsAPI(id, 'strategy-parameter'),
    strategyDetailsAPI(id, 'benchmark-map'),
    strategyDetailsAPI(id, 'instrument-restriction'),
    strategyPropertiesAPI(id),
    GetAllStrategyBenchmark()
  ]
  const responses = await Promise.allSettled(apiCalls)

  const [
    strategyMasterAPIData,
    strategyParameterAPIData,
    strategyBenchmarkMapAPIData,
    strategyInstrumentRestrictionAPIData,
    strategyPropertyMasterAPIData,
    strategyBenchmarkMasterAPIData
  ] = responses

  return [strategyMasterAPIData?.value, strategyParameterAPIData?.value, strategyBenchmarkMapAPIData?.value, strategyInstrumentRestrictionAPIData?.value, strategyPropertyMasterAPIData?.value, strategyBenchmarkMasterAPIData?.value]
}
