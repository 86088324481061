import { useCallback, useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { API } from 'aws-amplify'
import * as Sentry from '@sentry/react'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Autocomplete, Box, Button, Divider, IconButton, Modal, Skeleton, styled, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro'
import { randomId } from '@mui/x-data-grid-generator'
import CircleIcon from '@mui/icons-material/Circle'
import CloseIcon from '@mui/icons-material/Close'
import { useAuth } from '../../../contexts/AuthContext'
import { useErrorToast } from '../../../hooks/useErrorToast'
import { useSuccessToast } from '../../../hooks/useSuccessToast'
import { checkInstrumentSearchQuery } from '../../../utils/searchQueryUtils'
import Loader from '../../Loader'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  px: 2,
  py: 2,
  borderRadius: '4px',
  width: '100%',
  height: 'min(100vh, 800px)',
  outline: 'none',
  width: '1500px',
  maxWidth: '100%'
}

const CustomTab = styled(Tab)(() => ({
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  textTransform: 'capitalize',
  fontSize: '16px',
  color: '#34475A',
  fontWeight: 400,
  '&.Mui-selected': {
    fontWeight: 400,
    color: '#34475A'
  }
}))

const confirmationModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2,
  borderRadius: '4px'
}

const getLivePriceStatusColor = (status) => {
  if (status === 'SUCCESS')
    return '#3BBFA3'
  else if (status === 'FAILED')
    return '#FF6161'
  else if (status === 'IN_PROGRESS')
    return '#D29922'
  return '#c9c9c9'
}

const getLivePriceTooltipText = (status) => {
  if (!status) return ''
  return status
    ?.replace(/_/g, ' ')
    ?.replace(/\b\w/g, (char) => char.toUpperCase())
    ?.replace(/\B\w/g, (char) => char.toLowerCase())
}

const renderTableSkeleton = (header, hiddenColumns, isSelectable) => {
  if (hiddenColumns)
    header = header.filter(col => !hiddenColumns.includes(col.field))
  return (
    <TableContainer mt={5}>
      <Table className='comapct-table-skeleton'>
        <TableHead>
          <TableRow>
            {
              isSelectable ? (
                <TableCell className='skeleton-selection-box'>
                  <Skeleton variant='rectangular' width={18} height={18} />
                </TableCell>
              ) : ''
            }
            {header.map((item, index) => {
              return (
                <TableCell key={index}>{item.headerName}</TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.from({ length: 5 }).map((_, index) => (
            <TableRow key={index}>
              {
                isSelectable ? (
                  <TableCell className='skeleton-selection-box'>
                    <Skeleton variant='rectangular' width={18} height={18} />
                  </TableCell>
                ) : ''
              }
              {Array.from({ length: header.length }).map((_, index) => (
                <TableCell key={index}>
                  <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
                </TableCell>))}
            </TableRow>))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const renderDataGridPro = (rowData, cols, getRowId, columnVisibility, rowSelectionModel, onRowSelectionModelChange) => {
  return (
    <DataGridPro
      density='compact'
      autoHeight
      rows={rowData || []}
      columns={cols}
      checkboxSelection
      rowSelectionModel={rowSelectionModel}
      onRowSelectionModelChange={onRowSelectionModelChange}
      keepNonExistentRowsSelected
      getRowId={getRowId}
      pagination
      pageSizeOptions={[15, 25, 50, 100]}
      initialState={{
        ...rowData?.initialState,
        pagination: { paginationModel: { pageSize: 15 } },
        columns: {
          columnVisibilityModel:
            columnVisibility
              ? columnVisibility
              : {}
        }
      }}
      sx={(theme) => ({
        '& .MuiDataGrid-cell': {
          cursor: 'default'
        },
        '& .MuiDataGrid-cell:focus': {
          outline: 'none'
        },
        [`.${gridClasses.main}`]: {
          height: 'min(100vh - 16px - 45px - 49px - 16px - 53px, 800px - 16px - 45px - 49px - 16px - 53px)',
        },
        [`.${gridClasses.columnHeaders}`]: {
          position: 'sticky',
          backgroundColor: theme.palette.background.paper,
          top: 0,
          zIndex: 1,
        },
        [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
          color: '#74788d',
          fontWeight: 600
        },
        [`.${gridClasses.virtualScroller}`]: {
          overflowY: 'auto !important',
          scrollbarWidth: 'none'
        }
      })}
    />
  )
}

const accountCols = [
  {
    field: 'accountId',
    headerName: 'Account Id',
    flex: 1
  },
  {
    field: 'accountName',
    headerName: 'Account Name',
    flex: 1
  },
  {
    field: 'accountCode',
    headerName: 'Account Code',
    flex: 1
  },
  {
    field: 'accountType',
    headerName: 'Account Type',
    flex: 1
  },
  {
    field: 'isTaxable',
    headerName: 'Taxable',
    valueGetter: (params) => (params.value === 0 ? 'No' : 'Yes')
  }
]

const strategyCols = [
  {
    field: 'strategyId',
    headerName: 'Strategy Id',
    flex: 1
  },
  {
    field: 'strategyName',
    headerName: 'Strategy Name',
    flex: 1
  },
  {
    field: 'strategyCd',
    headerName: 'Strategy Code',
    flex: 1
  },
  {
    field: 'strTypeCode',
    headerName: 'Strategy Type',
    flex: 1
  }
]

const instrumentCols = [
  {
    field: 'instrId',
    headerName: 'Strategy Type',
    flex: 1
  },
  {
    field: 'name',
    headerName: 'Instrument Name',
    flex: 1
  },
  {
    field: 'localSymbol',
    headerName: 'Local Symbol',
    flex: 1
  },
  {
    field: 'isoCountryCode',
    headerName: 'ISO Country Code',
    flex: 1
  }
]

const historyColumns = [
  {
    field: 'snapshotTs',
    headerName: 'Fetch Time',
    flex: 1,
    type: 'dateTime',
    valueGetter: (params) => params?.row?.snapshotTs ? new Date(params?.row?.snapshotTs) : null,
    renderCell: (params) => (
      params?.row?.snapshotTs
        ? dayjs(params?.row?.snapshotTs).format('YYYY-MM-DD, HH:mm:ss')
        : null
    )
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    headerAlign: 'left',
    align: 'left',
    type: 'singleSelect',
    valueOptions: ['SUCCESS', 'IN_PROGRESS', 'FAILED'],
    renderCell: (params) => (
      <Box sx={{ borderRadius: '20px', fontSize: '10px', px: '10px', py: '2px', ...getColorProperties(params?.row?.status), fontWeight: 600 }}>{params?.row?.status}</Box>
    )
  }
]

const detailedHistoryColumns = [
  {
    field: '',
    headerName: ' ',
    width: 50
  },
  {
    field: 'instrId',
    headerName: 'Instr Id',
    flex: 1
  },
  {
    field: 'localSymbol',
    headerName: 'Ticker',
    flex: 1
  },
  {
    field: 'liveMarketPrice',
    headerName: 'Live Price',
    valueGetter: (params) => params?.value ? parseFloat(params?.value?.toFixed(2)) : params?.value,
    renderCell: (params) => (
      <Tooltip title={
        params?.row?.livePriceTs
          ? `last updated: ${params?.row?.livePriceTs?.replace('T', ' ').slice(0, 19)}`
          : ''
      }
        placement='top'
      >
        {
          params?.row?.liveMarketPrice !== undefined
            ? params?.row?.liveMarketPrice !== null
              ? params?.row?.liveMarketPrice < 0
                ? '-$' + Math.abs(parseFloat(params?.value?.toFixed(2)))?.toLocaleString()
                : '$' + parseFloat(params?.value?.toFixed(2))?.toLocaleString()
              : ''
            : ''
        }
      </Tooltip>
    )
  }
]

const getColorProperties = (data) => {
  if (data === 'SUCCESS') {
    return {
      color: '#446b60',
      backgroundColor: '#3BBFA399',
      border: '1px solid #446b6022'
    }
  } else if (data === 'IN_PROGRESS') {
    return {
      color: '#aaab29',
      backgroundColor: '#fbfa6c99',
      border: '1px solid #aaab2922'
    }
  } else if (data === 'FAILED') {
    return {
      color: '#79454f',
      backgroundColor: '#ff616199',
      border: '1px solid #79454f22'
    }
  }
}

const DetailPanelContent = (params) => {
  const securities = (params?.row?.snapDetails || [])?.map(obj => ({ ...obj, id: randomId() })).sort((a, b) => {
    if (a.liveMarketPrice === null && b.liveMarketPrice !== null)
      return -1
    if (a.liveMarketPrice !== null && b.liveMarketPrice === null)
      return 1
    return 0
  })
  return (
    <DataGridPro
      autoHeight
      density='compact'
      rows={securities}
      columns={detailedHistoryColumns}
      pagination
      getRowClassName={(params) => params?.row?.liveMarketPrice === null ? 'error-row' : ''}
      pageSizeOptions={[15, 25, 50, 100]}
      initialState={{
        ...securities?.initialState,
        pagination: { paginationModel: { pageSize: 15 } }
      }}
      sx={{
        '.error-row': {
          '&:hover': {
            backgroundColor: '#f05f5f1a'
          },
          backgroundColor: '#f05f5f1a'
        },
        [`.${gridClasses.main}`]: {
          height: 'unset',
        },
        [`.${gridClasses.columnHeaders}`]: {
          position: 'unset'
        },
        [`.${gridClasses.virtualScroller}`]: {
          height: 'unset'
        }
      }}
    />
  )
}

const LivePriceModal = ({ showLivePricePopup, onClose, livePriceStatusData, getFactsetStatus, getStatusText }) => {
  const { user } = useAuth()
  const { showError } = useErrorToast()
  const { showSuccess } = useSuccessToast()
  const [showLoader, setShowLoader] = useState(false)
  const [selectedTab, setSelectedTab] = useState('Account')
  const [isAccountLoading, setIsAccountLoading] = useState(true)
  const [accountsList, setAccountsList] = useState([])
  const [copyAccountsList, setCopyAccountsList] = useState([])
  const [isStrategyLoading, setIsStrategyLoading] = useState(true)
  const [strategyList, setStrategyList] = useState([])
  const [copyStrategyList, setCopyStrategyList] = useState([])
  const [isInstrumentsLoading, setIsInstrumentsLoading] = useState(false)
  const [instrumentList, setInstrumentList] = useState([])
  const [accountSelectionModel, setAccountSelectionModel] = useState([])
  const [strategySelectionModel, setStrategySelectionModel] = useState([])
  const [selectedInstruments, setSelectedInstruments] = useState([])
  const [isHistoryLoading, setIsHistoryLoading] = useState(true)
  const [historyData, setHistoryData] = useState([])
  const [searchText, setSearchText] = useState('')
  const [instrSearchText, setInstrSearchText] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [snapsDetails, setSnapsDetails] = useState({})
  const tabList = ['Account', 'Strategy', 'Instrument', 'History']

  const fetchInstrumentName = (values) => {
    if (checkInstrumentSearchQuery(values)) {
      setIsInstrumentsLoading(true)
      API.get('baseUriTransactionalMaster', `transactional-master/v1/${user?.userGroup}/instruments`, {
        queryStringParameters: { search: values }
      })
        .then((response) => {
          if (response?.data) {
            setInstrumentList(response.data)
          }
        })
        .catch((error) => {
          showError(error?.response?.data?.errorInfo?.userMessage || error.message)
          Sentry.captureException(error?.response?.data?.errorInfo?.userMessage || error)
        })
        .finally(() => {
          setIsInstrumentsLoading(false)
        })
    } else {
      setIsInstrumentsLoading(false)
    }
  }

  const fetchAccountsData = () => {
    setIsAccountLoading(true)
    API.get('baseURL', `account-master/v1/${user?.userGroup}/accounts/summary/details`, {
      queryStringParameters: {
        page: 1,
        perpage: 200,
        ...user?.userGroup === 'adv-classic'
          ? {
            resources: encodeURIComponent(JSON.stringify({
              serviceId: 'book-of-business',
              resourceId: 'book-of-business'
            }))
          }
          : {}
      }
    })
      .then(response => {
        if (response?.data?.accountsSummaryDetails) {
          const tradingAccs = response?.data?.accountsSummaryDetails.filter(obj => obj?.accountType !== 'TRANSITION')
          setAccountsList(tradingAccs)
          setCopyAccountsList(tradingAccs)
        }
      })
      .catch(error => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => {
        setIsAccountLoading(false)
      })
  }

  const fetchAllStrategy = () => {
    setIsStrategyLoading(true)
    API.get('baseStrategyURL', `strategy/v1/${user.userGroup}/strategy-master`)
      .then((response) => {
        if (response?.data) {
          setStrategyList(response.data)
          setCopyStrategyList(response.data)
        }
      })
      .catch((error) => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => {
        setIsStrategyLoading(false)
      })
  }

  const fetchSnapHistory = () => {
    setIsHistoryLoading(true)
    API.get('baseUriSecurityMaster', `security-master/v1/${user?.userGroup}/factset-snap-history`)
      .then(res => {
        if (res?.data)
          setHistoryData((res?.data || [])?.map(obj => ({ ...obj, id: randomId() })))
      })
      .catch(error => {
        showError(error?.response?.data?.errorInfo?.userMessage || error?.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => {
        setIsHistoryLoading(false)
      })
  }

  const fetchLivePriceForSelectedIds = (validate) => {
    setShowLoader(true)
    const instrIds = selectedInstruments?.map(instrObj => instrObj?.instrId)
    API.post('baseUriSecurityMaster', `security-master/v1/${user?.userGroup}/fetch/factset-live-prices`, {
      queryStringParameters: {
        'validate-only': validate
      },
      body: {
        accountIds: accountSelectionModel,
        strategyIds: strategySelectionModel,
        instrIds
      }
    })
      .then((res) => {
        if (validate) {
          setSnapsDetails(res?.data || {})
          setIsModalOpen(true)
        } else {
          onClose()
          getFactsetStatus()
          showSuccess(res?.message)
          setIsModalOpen(false)
        }
      })
      .catch((error) => {
        showError(error?.response?.data?.errorInfo?.userMessage || error?.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => {
        setShowLoader(false)
      })
  }

  useEffect(() => {
    if (!user) return
    fetchAccountsData()
    fetchAllStrategy()
    fetchSnapHistory()
  }, [user])

  useEffect(() => {
    if (selectedTab === 'Instrument') {
      const id = setTimeout(() => {
        fetchInstrumentName(instrSearchText.trim())
      }, [300])
      return () => clearTimeout(id)
    }
  }, [instrSearchText, selectedTab])

  const getDetailPanelContent = useCallback(({ row }) => <DetailPanelContent row={row} />, [])

  const renderTabPanel = (tab) => {
    switch (tab) {
      case 'Account':
        return (
          isAccountLoading
            ? renderTableSkeleton(accountCols, ['accountId'], true)
            : renderDataGridPro(
              accountsList,
              accountCols,
              (row) => row?.accountId,
              { accountId: false },
              accountSelectionModel,
              setAccountSelectionModel
            )
        )
      case 'Strategy':
        return (
          isStrategyLoading
            ? renderTableSkeleton(strategyCols, ['strategyId'], true)
            : renderDataGridPro(
              strategyList,
              strategyCols,
              (row) => row?.strategyId,
              { strategyId: false },
              strategySelectionModel,
              setStrategySelectionModel
            )
        )
      case 'Instrument':
        return (
          renderDataGridPro(
            selectedInstruments,
            instrumentCols,
            (row) => row?.instrId,
            { instrId: false },
            selectedInstruments.map(instr => instr?.instrId),
            (newModel) => {
              setSelectedInstruments(selectedInstruments.filter(instrObj => newModel.includes(instrObj?.instrId)))
            }
          )
        )
      case 'History':
        return (
          isHistoryLoading
            ? renderTableSkeleton(historyColumns, [], true)
            : <DataGridPro
              density='compact'
              autoHeight
              rows={historyData || []}
              columns={historyColumns}
              getDetailPanelContent={getDetailPanelContent}
              getDetailPanelHeight={() => 'auto'}
              pagination
              pageSizeOptions={[15, 25, 50, 100]}
              initialState={{
                ...historyData?.initialState,
                pagination: { paginationModel: { pageSize: 15 } }
              }}
              sx={(theme) => ({
                '& .MuiDataGrid-cell': {
                  cursor: 'default'
                },
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none'
                },
                [`.${gridClasses.main}`]: {
                  height: 'min(100vh - 16px - 45px - 49px - 16px - 53px, 800px - 16px - 45px - 49px - 16px - 53px)',
                },
                [`.${gridClasses.columnHeaders}`]: {
                  position: 'sticky',
                  backgroundColor: theme.palette.background.paper,
                  top: 0,
                  zIndex: 1,
                },
                [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
                  color: '#74788d',
                  fontWeight: 600
                },
                [`.${gridClasses.virtualScroller}`]: {
                  overflowY: 'auto !important',
                  scrollbarWidth: 'none'
                }
              })}
            />
        )
      default:
        return <></>
    }
  }

  const searchInputChange = (text) => {
    setSearchText(text)
    setAccountsList(!text
      ? copyAccountsList
      : copyAccountsList.filter(obj => obj?.accountName?.toLowerCase()?.includes(text)))
    setStrategyList(!text
      ? copyStrategyList
      : copyStrategyList.filter(obj => obj?.strategyName?.toLowerCase()?.includes(text)))
  }

  const handleSubmitClick = () => {
    fetchLivePriceForSelectedIds(true)
  }

  const handleSelectChange = (e, newValue) => {
    setSelectedInstruments(newValue)
  }

  const closeConfirmationModal = () => {
    setIsModalOpen(false)
  }

  const handleOnConfirm = () => {
    fetchLivePriceForSelectedIds(false)
  }

  const handleTabChange = (_, value) => {
    if (selectedTab === 'Instrument') {
      searchInputChange('')
    }
    setSelectedTab(value)
  }

  return (
    <>
      {showLoader ? <Loader /> : ''}
      <Modal
        onClose={onClose}
        open={showLivePricePopup}
      >
        <Box className='live-price-modal' sx={modalStyle}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: '5px', position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              <Typography variant='h6' sx={{ ml: '5px', fontWeight: 400, color: '#34475A' }}>Live Price</Typography>
              <Box sx={{ borderRadius: '20px', fontSize: '12px', px: '10px', py: '2px', ...getColorProperties(livePriceStatusData?.status), fontWeight: 600 }}>{getStatusText(livePriceStatusData?.status)}</Box>
            </Box>
            <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
              <Box sx={{ fontSize: '14px', color: '#74788D' }}>
                <Tooltip title='Remaining Monthly Snaps' placement='top'>
                  <span>
                    RMS:
                  </span>
                </Tooltip>
                <span style={{ color: '#34475A' }}> {livePriceStatusData?.snapsAvailable || 0} / {livePriceStatusData?.snapsTotal || 0}</span>
              </Box>
              <Typography sx={{ fontSize: '14px', color: '#74788D' }}>
                Snaps Used: <span style={{ color: '#34475A' }}>{livePriceStatusData?.snapsUsed}</span>
              </Typography>
              <Button
                variant='contained'
                disabled={livePriceStatusData?.status === 'IN_PROGRESS' || (!accountSelectionModel.length && !strategySelectionModel.length && !selectedInstruments.length)}
                onClick={handleSubmitClick}
              >
                Apply
              </Button>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <Box>
            <TabContext value={selectedTab}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <TabList
                  onChange={handleTabChange}
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: '#34475A'
                    }
                  }}
                  indicatorColor='#34475A'
                >
                  {tabList.map((tab, index) => (
                    <CustomTab key={index} label={tab} value={tab} />
                  ))}
                </TabList>
                {
                  selectedTab === 'Instrument' ? (
                    <Autocomplete
                      multiple
                      value={selectedInstruments}
                      loading={isInstrumentsLoading}
                      options={instrumentList}
                      inputValue={instrSearchText}
                      onChange={handleSelectChange}
                      onClose={(event, reason) => {
                        if (reason === 'selectOption' || reason === 'blur' || reason === 'escape') {
                          setInstrSearchText('')
                        }
                        setInstrumentList([])
                      }}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option ? `${option?.instrId} (${option?.name})` : ''}
                      size='small'
                      renderTags={() => null}
                      disableClearable
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label='Search by Name'
                          value={searchText}
                          onChange={(e) => setInstrSearchText(e.target.value)}
                        />
                      )}
                      isOptionEqualToValue={(option, value) => option.instrId === value?.instrId}
                      sx={{
                        width: '300px',
                        '& .MuiAutocomplete-root': {
                          fontSize: '12px'
                        }
                      }}
                    />
                  ) : (
                    selectedTab !== 'History' ? (
                      <TextField
                        size='small'
                        label={`Search by Name`}
                        variant='outlined'
                        type='text'
                        value={searchText || ''}
                        autoComplete='off'
                        sx={{ width: '300px' }}
                        onChange={(e) => searchInputChange(e.target.value)}
                      />
                    )
                      : ''
                  )
                }
              </Box>
              {tabList.map((tab, index) => (
                <TabPanel key={index} value={tab} sx={{ px: 0, pt: 0, pb: 0 }}>
                  {renderTabPanel(tab)}
                </TabPanel>
              ))}
            </TabContext>
          </Box>
        </Box>
      </Modal>
      {
        isModalOpen ? (
          <Modal open={isModalOpen} onClose={closeConfirmationModal}>
            <Box sx={confirmationModalStyle}>
              <Typography sx={{ fontSize: '18px', fontWeight: 500, color: '#34475A' }}>Fetch Live Price</Typography>
              <Typography sx={{ fontSize: '16px', textAlign: 'left !important' }}>
                Please review updated snaps details before fetching live price.
              </Typography>
              <Divider sx={{ paddingBlock: '5px' }} />
              <Box sx={{ marginTop: '5px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography sx={{ color: '#74788D', width: '50%' }}>Snaps To Be Used :</Typography>
                  <Typography sx={{ color: '#2F2F2F', textTransform: 'capitalize', width: '50%' }}>{snapsDetails?.snapsToBeUsed || 0}</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography sx={{ color: '#74788D', width: '50%' }}>Remaining Snaps :</Typography>
                  <Typography sx={{ color: '#2F2F2F', textTransform: 'capitalize', width: '50%' }}>{(livePriceStatusData?.snapsAvailable - snapsDetails?.snapsToBeUsed) || 0}</Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
                <Button onClick={closeConfirmationModal} m={1} sx={{ fontWeight: 600, fontSize: '12px' }}>Cancel</Button>
                <Button variant='contained' size='small' onClick={handleOnConfirm} m={1} sx={{ fontSize: '12px' }}>Confirm</Button>
              </Box>
            </Box>
          </Modal>
        ) : ''
      }
    </>
  )
}

const LivePriceButton = () => {
  const { user } = useAuth()
  const { showError } = useErrorToast()
  const [showLivePricePopup, setShowLivePricePopup] = useState(false)
  const [livePriceStatusData, setLivePriceStatusData] = useState({})
  const [intervalId, setIntervalId] = useState()
  const MIN_INTERVAL_COUNT = 0
  const MAX_INTERVAL_COUNT = 10
  const [intervalCounter, setIntervalCounter] = useState(MIN_INTERVAL_COUNT)

  const fetchFactSetStatus = () => {
    clearInterval(intervalId)
    setIntervalCounter(MIN_INTERVAL_COUNT)
    API.get('baseUriSecurityMaster', `security-master/v1/${user?.userGroup}/factset-snap-status`)
      .then(res => {
        if (res?.data?.length) {
          if (res?.data[0]?.status !== 'SUCCESS') {
            setIntervalId(
              setInterval(() => {
                setIntervalCounter(prev => prev + 1)
              }, 1000)
            )
          }
          setLivePriceStatusData(res?.data[0])
        }
      })
      .catch((error) => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
  }

  useEffect(() => {
    fetchFactSetStatus()
  }, [user])

  useEffect(() => {
    if (intervalCounter === MAX_INTERVAL_COUNT) {
      fetchFactSetStatus()
    }
  }, [intervalCounter])

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }} >
        <Tooltip title={getLivePriceTooltipText(livePriceStatusData?.status)} placement='top'>
          <CircleIcon sx={{ color: getLivePriceStatusColor(livePriceStatusData?.status), height: '12px', width: '12px' }} />
        </Tooltip>
        <Button
          onClick={() => setShowLivePricePopup(true)}
        >
          Live Price
        </Button>
      </Box>
      {
        showLivePricePopup ? (
          <LivePriceModal
            onClose={() => setShowLivePricePopup(false)}
            showLivePricePopup
            livePriceStatusData={livePriceStatusData}
            getFactsetStatus={fetchFactSetStatus}
            getStatusText={getLivePriceTooltipText}
          />
        ) : ''
      }
    </>
  )
}

export default LivePriceButton