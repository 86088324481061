import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Bar } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Chart as ChartJS, registerables } from 'chart.js'
import './factorTable.css'
import { fetchDataAndDispatch } from './APIResponseConverts/APIResponseConverts'
import {
  FactorTableIcon,
  factorChart
} from './APIResponseConverts/chartDefaultsData'
import { factorAllocationApi } from '../../../utils/pdf-customizer/_data'
import { FactorTableOptions } from '../common/ChartOptions/options'
import ImageDisplay from '../common/ImageDisplay'
import { useAuth } from '../../../contexts/AuthContext'


ChartJS.register(...registerables)

const FactorTable = ({ data, chartIndex, index, chartHeight, clickEvent }) => {
  const { factorChartResponse, isResponseElements } = useSelector(
    ({ chartState }) => chartState
  )
  const templateData = useSelector(state => state.templateData)
  const dispatch = useDispatch()
  const [isDragging, setIsDragging] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const chartRef = useRef(null)
  const { user } = useAuth()

  useEffect(() => {
    if (isResponseElements && !factorChartResponse && !isLoading) {
      setIsLoading(true)
      fetchDataAndDispatch(() => factorAllocationApi(templateData, user?.userGroup), 'FACTOR_CHART', dispatch)
    }
    if (isResponseElements && factorChartResponse) {
      setIsLoading(false)
    }
  }, [isResponseElements, dispatch, factorChartResponse, templateData])

  const factorData = factorChartResponse || factorChart.data

  const chartStyle = {
    width: '100%',
    opacity: isDragging ? 0.1 : 1
  }

  const handleDragStart = (e) => {
    setIsDragging(true)
    e.dataTransfer.setData('text/plain', JSON.stringify({ chartIndex, index }))
  }

  const handleDragEnd = () => {
    setIsDragging(false)
  }

  const renderBarChart = (label, data, backgroundColor, width) => (
    <div style={{ width }}>
      {
        data.map((entry, index) => (<div key={index} style={{ marginBottom: '10px' }}><p style={{
          fontSize: '8px',
          margin: '0px 3px',
          color: '#2f2f2f',
          textAlign: 'right'
        }}>
          {entry}</p></div>))
      }
      {/* <Bar
        ref={chartRef}
        data={{
          labels:
            Array.isArray(factorData?.data?.factorAllocationData) &&
            factorData?.data?.factorAllocationData.map(
              (_, index) => `Label ${index}`
            ),
          datasets: [
            {
              label,
              backgroundColor,
              data
            }
          ]
        }}
        width='100%'
        plugins={[ChartDataLabels]}
        options={FactorTableOptions(label === 'Portfolio' ? 'start' : 'end')}
      /> */}
    </div>
  )

  const getFactorIcon = (factorName) => {
    const icon = FactorTableIcon[factorName]

    if (icon) {
      return (
        <div className='show-icon-factor-table'>
          <div className='byFactorIcon'>
            <ImageDisplay imageUrl={icon} />
          </div>
          <p className=''>{factorName}</p>
        </div>
      )
    }
    return null
  }

  return (
    <div
      className={`group ${data.isActive ? 'isActive' : ''}`}
      key={chartIndex + index}
      draggable
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <p style={{ margin: '0', fontSize: '10px', fontWeight: 600 }}>
        {data.headingText || data.name}
      </p>
      {isLoading
        ? <span className='loading-text'>Loading...</span>
        : <div
          style={chartStyle}
          className={`margin-top-2 ${data.isActive ? '' : ''}`}
        >
          <table className="factor-table" style={{ borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <th
                  className='factor-table text-header'
                  style={{ textAlign: 'left', width: '30%' }}
                >
                  Security
                </th>
                <th
                  className='factor-table text-header '
                  style={{
                    textAlign: 'right',
                    borderRight: '1px solid #ADC3DB',
                    width: '35%'
                  }}
                >
                  Portfolio
                </th>
                <th
                  className='factor-table text-header '
                  style={{
                    textAlign: 'right',
                    borderRight: '1px solid #ADC3DB',
                    width: '35%'
                  }}
                >
                  Strategy
                </th>
              </tr>
            </thead>
            <tbody className='table-body'>
              <tr>
                <td
                  style={{
                    padding: '1px 8px',
                    color: '#2F2F2F',
                    fontWeight: 400,
                    width: '30%'
                  }}
                >
                  <div className='show-icon-factor'>
                    {Array.isArray(factorData?.data?.factorAllocationData) &&
                      factorData?.data?.factorAllocationData.map((item) =>
                        getFactorIcon(item.factorName)
                      )}
                  </div>
                </td>

                <td
                  style={{
                    padding: '1.5px',
                    borderRight: '1px solid #ADC3DB',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'end'
                  }}
                >
                  {renderBarChart(
                    'Portfolio',
                    Array.isArray(factorData?.data?.factorAllocationData) &&
                    factorData?.data?.factorAllocationData.map(
                      (items) => items.portfolio!==null ? items.portfolio : 'NA'
                    ),
                    Array.isArray(factorData?.data?.factorAllocationData) &&
                    factorData?.data?.factorAllocationData.map((items) =>
                      items.portfolio < 0 ? '#e74c418a' : '#4B8CCA'
                    ),
                    '100%'
                  )}
                </td>

                <td style={{ padding: '1px', width: '100%' }}>
                  {renderBarChart(
                    'strategy',
                    Array.isArray(factorData?.data?.factorAllocationData) &&
                    factorData?.data?.factorAllocationData.map((items) =>
                      items.strategy !== null ? items.strategy  : 'NA'
                    ),
                    Array.isArray(factorData?.data?.factorAllocationData) &&
                    factorData?.data?.factorAllocationData.map((items) =>
                      items.strategy > 0 ? '#e74c418a' : '#6A86A6'
                    ),
                    '100%'
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      }
    </div>
  )
}

export default FactorTable
