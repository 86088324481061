import { TextField } from '@mui/material'

export const FormikDynamicInput = (props) => {
  const { control, label, name, onHandleChange, fieldValues, fieldKey, disabled } = props

  return (
    <>
      <TextField
        margin='dense'
        id={name}
        name={name}
        label={label}
        type={control}
        fullWidth
        variant='standard'
        value={fieldValues[fieldKey]}
        onChange={onHandleChange}
        disabled={disabled || false}
      />
    </>
  )
}
