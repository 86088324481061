import { Link } from 'react-router-dom'
import { Box, Button, Dialog, DialogContent, Grid, Typography } from '@mui/material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import downloadIcon from '../../../assets/images/icon-download-2.svg'
import signIcon from '../../../assets/images/icon-signature.svg'
import uploadIcon from '../../../assets/images/icon-cloud.svg'
import shieldIcon from '../../../assets/images/icon-shield.svg'

const CustomStepBox = ({ icon, stepIndex, text }) => {
  return (
    <Box textAlign='left'>
      <img src={icon} alt={`img-${stepIndex}`} />
      <Typography sx={{
        color: '#727474',
        fontSize: '12px',
        fontWeight: 300,
        mt: 1
      }}
      >Step {stepIndex}
      </Typography>
      <Typography sx={{
        color: '#2F2F2F',
        fontSize: '14px'
      }}
      >{text}
      </Typography>
    </Box>
  )
}

const PolicySubmittedSuccess = ({ open, onClose, title, requestId }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent
        maxWidth='md'
        sx={{
          padding: '30px 50px!important',
          minWidth: '400px',
          maxWidth: title.includes('Approved') ? '600px' : '800px',
          textAlign: 'center'
        }}
      >
        {title?.includes('Approved')
          ? <CheckCircleOutlineIcon
              sx={{
                fontSize: '80px',
                color: 'green',
                marginBottom: 1
              }}
            />
          : ''}
        <Typography sx={{ fontSize: '20px', paddingBottom: '10px', textAlign: 'center', fontWeight: 400 }}>{title}</Typography>
        <Typography sx={{ fontSize: '14px' }}>Request Id: {requestId || '123456'}</Typography>
        <Typography sx={{ fontSize: '14px' }}>Document Id: {'DOCU' + (requestId.slice(4) || '1234')}</Typography>
        {
          !title?.includes('Approved')
            ? <Grid container spacing={3} mt={2} justifyContent='center'>
              <Grid item xs={3}><CustomStepBox icon={downloadIcon} stepIndex={1} text='Go to statement screen and download document' /></Grid>
              <Grid item xs={3}><CustomStepBox icon={signIcon} stepIndex={2} text='Sign document' /></Grid>
              <Grid item xs={3}><CustomStepBox icon={uploadIcon} stepIndex={3} text='Upload document' /></Grid>
              <Grid item xs={3}><CustomStepBox icon={shieldIcon} stepIndex={4} text='ARIS PM to sign and approve' /></Grid>
              </Grid>
            : ''
          }
        {!title?.includes('Approved')
          ? <Link to='/ips/statement'>
            <Button variant='contained' sx={{ mt: 3, borderRadius: '50px', width: '250px' }}>View statement</Button>
            </Link>
          : ''}
      </DialogContent>
    </Dialog>
  )
}

export default PolicySubmittedSuccess
