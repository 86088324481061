import { toPng } from 'html-to-image'

const downloadImageFromHtml = (querySelectorName = 'body', fileName = 'image', format = 'png') => {
  if (format === 'png') {
    toPng(document.querySelector(querySelectorName), {
      pixelRatio: 1
    })
      .then((dataUrl) => {
        const imageLink = document.createElement('a')
        imageLink.download = `${fileName}.png`
        imageLink.href = dataUrl
        imageLink.click()
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

export { downloadImageFromHtml }
