import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth } from '../../../../contexts/AuthContext'
import { sector } from '../../../../utils/pdf-customizer/_data'
import { CASH_FLOW_SUMMARY_TYPE } from '../../../../store/pdf-customizer-reducer/Action/chartState.types'
import { addChartValues } from '../../../../store/pdf-customizer-reducer/Action/chartDropAction'
import { formatNumbersWithSymbol } from '../../../Report/components/dataProcess/DataProcess'
import { activitySummaryData, umaActivitySummaryData } from '../APIResponseConverts/chartDefaultsData'
import { dataTableData, fetchDataAndDispatch } from '../APIResponseConverts/APIResponseConverts'
import './CashFlowSummary.css'

const AggregateActivitySummary = ({ data }) => {
	const [isLoading, setIsLoading] = useState(false)
	const dispatch = useDispatch()
	const { user } = useAuth()
	const templateData = useSelector(state => state.templateData)
	const { isResponseElements, activitySummaryResponse } = useSelector((state) => state.chartState)
	const { datasets = [] } = data?.value || {}

	const { tableRows, headingColumns, tableTextAlign, tableWidth } = dataTableData(umaActivitySummaryData, data?.name)

	const processedColumns = useMemo(() => {
		return Array.isArray(headingColumns)
			? headingColumns
			: Object.keys(headingColumns) || []
	}, [headingColumns])

	useEffect(() => {
		if (data.value || !processedColumns.length) return

		const mappedDatasets = processedColumns.map((label) => ({
			label: label?.field ? label.field : label,
			hidden: false,
			backgroundColor: 'rgba(255, 255, 255  , 1)',
			labelText: headingColumns[label?.field ? label.field : label] || (label?.field ? label.field : label)
		}))

		const chartValues = {
			labels: [],
			datasets: mappedDatasets,
			chartIndex: data?.chartIndex,
			pageIndex: data?.pageIndex
		}

		dispatch(addChartValues(chartValues))
	}, [processedColumns, dispatch, data])

	return (
		<>
			<p style={{ fontSize: '10px', fontWeight: 500, color: '#47546a', margin: 0 }}>{data?.headingText}</p>
			{
				isLoading ?
					<span className='loading-text'>Loading...</span>
					: <div className='activity-summary'>
						<table className='width-100' style={{ borderCollapse: 'collapse' }}>
							<thead>
								<tr className='headingBorder'>
									{datasets?.map((column, columnIndex) => (
										!column?.hidden && <th key={columnIndex} className={`width-${tableWidth[columnIndex]} ${tableTextAlign[columnIndex]} text-header`}>
											{column?.label}
										</th>
									))}
								</tr>
							</thead>
							<tbody>
								{
									tableRows?.map((tableRow, rowIndex) => (
										<tr className={`${rowIndex >= tableRows.length - 2 ? 'total-row' : 'borderBottom'} fontsize8`} key={rowIndex}>
											{
												datasets?.map((element, index) => (
													!element?.hidden && <td
														key={index}
														className={`width-${tableWidth[index]} ${tableTextAlign[index]}`}
														style={{
															background: element?.backgroundColor
														}}
													>
														{tableRow[index]}
													</td>
												))
											}
										</tr>
									))
								}
							</tbody>
						</table>
					</div >
			}
		</>
	)
}

export default AggregateActivitySummary