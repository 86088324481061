import React from 'react'
import { allExpanded, defaultStyles, JsonView } from 'react-json-view-lite'
import { Box, Button, IconButton, Modal, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import 'react-json-view-lite/dist/index.css'

const customStyles = {
  ...defaultStyles,
  container: {
    ...defaultStyles.container,
    backgroundColor: '#ffffff'
  },
  label: {
    ...defaultStyles.label,
    fontWeight: 'normal'
  },
  punctuation: {
    ...defaultStyles.punctuation,
    fontWeight: 'normal'
  },
  quotesForFieldNames: true
}

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  px: 2,
  py: 2,
  borderRadius: '4px',
  outline: 'none',
  width: '100vw',
  height: '100vh'
}

const ViewPolicyJson = ({ policyData, showReportPopup, onClose, title }) => {
  return (
    <Modal
      open={showReportPopup}
      onClose={onClose}
    >
      <Box sx={modalStyle}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: '5px', position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 2 }}>
          <Typography variant='h6' sx={{ ml: '5px', fontWeight: 400, color: '#34475A' }}>Policy Report Details</Typography>
          <Box sx={{ display: 'flex', gap: '10px' }}>
            <Button
              variant='text'
              startIcon={<FileDownloadOutlinedIcon sx={{ color: '#3A76D4', fontSize: '16px' }} />}
              href={URL.createObjectURL(new Blob([JSON.stringify(policyData, null, 2)], { type: 'application/json' }))}
              download={`${title?.split(' ').join('-')?.toLowerCase()}-policy-checks.json`}
              sx={{ textTransform: 'capitalize' }}
            >
              Download Policy
            </Button>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Box sx={{ maxHeight: 'calc(100vh - 16px - 40px - 16px)', border: '1px solid #c9c9c9', borderRadius: '5px', overflowY: 'auto' }}>
          <JsonView data={policyData} shouldExpandNode={allExpanded} style={customStyles} clickToExpandNode />
        </Box>
      </Box>
    </Modal>
  )
}

export default ViewPolicyJson