import * as types from './chartState.types'
const typeMapping = {
  [types.PERFORMANCE_CHART_TYPE]: types.PERFORMANCE_CHART_TYPE,
  [types.MARKET_CHART_TYPE]: types.MARKET_CHART_TYPE,
  [types.FACTOR_CHART_TYPE]: types.FACTOR_CHART_TYPE,
  [types.ACCOUNT_DATA_TYPE]: types.ACCOUNT_DATA_TYPE,
  [types.SECTOR_CONSTRAINTS_TYPE]: types.SECTOR_CONSTRAINTS_TYPE,
  [types.INDUSTRY_CONSTRAINTS_TYPE]: types.INDUSTRY_CONSTRAINTS_TYPE,
  [types.SECURITY_CONSTRAINTS_TYPE]: types.SECURITY_CONSTRAINTS_TYPE,
  [types.FACTOR_TILTS_CONSTRAINSTS_TYPE]: types.FACTOR_TILTS_CONSTRAINSTS_TYPE,
  [types.SUBSTITUTION_CONSTRAINTS_TYPE]: types.SUBSTITUTION_CONSTRAINTS_TYPE,
  [types.SCENARIO_CONSTRAINTS_TYPE]: types.SCENARIO_CONSTRAINTS_TYPE,
  [types.YTD_TAX_DETAILS_CONSTRAINTS_TYPE]: types.YTD_TAX_DETAILS_CONSTRAINTS_TYPE,
  [types.TOP_POSITION_SECURITY_TYPE]: types.TOP_POSITION_SECURITY_TYPE,
  [types.SECTOR_WISE_RISK_TYPE]: types.SECTOR_WISE_RISK_TYPE,
  [types.INITIAL_PORTFOLIO_BLOCK_TYPE]: types.INITIAL_PORTFOLIO_BLOCK_TYPE,
  [types.SECURITY_WISE_RISK_TYPE]: types.SECURITY_WISE_RISK_TYPE,
  [types.ALLOCATION_CHART_TYPE]: types.ALLOCATION_CHART_TYPE,
  [types.IS_API_CALL]: types.IS_API_CALL,
  [types.TAX_SAVING_DATA_TYPE]: types.TAX_SAVING_DATA_TYPE,
  [types.TAX_MANAGEMENT_DATA_TYPE]: types.TAX_MANAGEMENT_DATA_TYPE,
  [types.PERSONALIZATION_DATA_TYPE]: types.PERSONALIZATION_DATA_TYPE,
  [types.SUMMARY_DATA_TYPE]: types.SUMMARY_DATA_TYPE,
  [types.DIVERSIFICATION_STATISTICS_TYPE]: types.DIVERSIFICATION_STATISTICS_TYPE,
  [types.PORTFOLIO_STATISTICS_TYPE]: types.PORTFOLIO_STATISTICS_TYPE,
  [types.SECURITY_BY_WEIGHT_TYPE]: types.SECURITY_BY_WEIGHT_TYPE,
  [types.CUSTOM_TABLE_TYPE]: types.CUSTOM_TABLE_TYPE,
  [types.CASH_FLOW_SUMMARY_TYPE]: types.CASH_FLOW_SUMMARY_TYPE,
  [types.AGGREGATE_MARKET_VALUE]: types.AGGREGATE_MARKET_VALUE,
  [types.AGGREGATE_PERFORMANCE]: types.AGGREGATE_PERFORMANCE,
  [types.ACTIVITY_SUMMARY_TYPE]: types.ACTIVITY_SUMMARY_TYPE,
}
export const chartDataState = (payload = null, type) => {
  return {
    type: typeMapping[type] || types.ACCOUNT_DATA_TYPE,
    payload: payload,
  }
}
