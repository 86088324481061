import React, { useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import { Box, Button, Checkbox, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import Modal from '@mui/material/Modal'
import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro'
import '../../../assets/styles/common.scss'
import { useErrorToast } from '../../../hooks/useErrorToast'
import CloseIcon from '@mui/icons-material/Close'
import { validateExpression } from '../../../utils/ZscoreValidator'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  minWidth: '55%',
  width: '900px',
  height: 'auto',
  padding: '16px',
  borderRadius: '4px'
}

function AddIndustryModal ({ addNewIndustry, allIndustries }) {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const { showError } = useErrorToast()
  const [industryList, setIndustryList] = useState(allIndustries || [])
  const [selectedRows, setSelectedRows] = useState([])

  const columns = [
    { field: 'sectorName', headerName: 'Sector', flex: 1, editable: false },
    { field: 'industry', headerName: 'Industry', flex: 2, editable: false },
    { field: 'count', headerName: 'No. of Securities', flex: 1, editable: false, type: 'number', align: 'right' },
    { field: 'sumWeight', headerName: 'Weight(%)', flex: 1, editable: false, type: 'number', align: 'right' },
    {
      field: 'exclude',
      headerName: 'Exclude',
      flex: 1,
      renderCell: (params) => (
        <Checkbox
          checked={params.value}
          onClick={(event) => event.stopPropagation()}
          disabled={params.row.sectorName === 'All Sectors' || params.row.disabled}
          onChange={() => handleConfirmChange(params.row.industry)}
        />
      )
    },
    {
      field: 'min',
      headerName: 'Min',
      type: 'text',
      flex: 1,
      description: 'Min value',
      renderCell: (params) => (
        <input
          className={'custom-textfield' + (params.row.minError ? ' error-border' : '')}
          style={params.row.minError ? { border: '1px solid red' } : {}}
          placeholder='Weight'
          type='text'
          value={(params.row.exclude) ? '' : (params.value || '')}
          disabled={params.row.exclude || params.row.disabled}
          onChange={(event) => handleCellEditCommit('min', event.target.value, params.row.sectorName, params.row.industry)}
        />
      )
    },
    {
      field: 'max',
      headerName: 'Max',
      type: 'text',
      flex: 1,
      description: 'Max value',
      renderCell: (params) => (
        <input
          className={'custom-textfield' + (params.row.maxError ? ' error-border' : '')}
          style={params.row.maxError ? { border: '1px solid red' } : {}}
          placeholder='Weight'
          type='text'
          value={(params.row.exclude) ? '' : (params.value || '')}
          disabled={params.row.exclude || params.row.disabled}
          onChange={(event) => handleCellEditCommit('max', event.target.value, params.row.sectorName, params.row.industry)}
        />
      )
    }
  ]

  function getRowId (row) {
    return `${row.industry}-${row.sectorName}`
  }

  function handleCellEditCommit (field, value, sectorName, industry) {
    let error = false
    if (value && value?.trim()?.length > 0) {
      error = validateExpression(value)
    }
    if (selectedRows?.filter((row) => row.industry === industry)?.length > 0) {
      const updatedData = industryList.map((row) => {
        if (selectedRows?.filter((item) => item.industry === row.industry)?.length > 0) {
          return field === 'min' ? { ...row, [field]: value, minError: error } : { ...row, [field]: value, maxError: error }
        } else {
          return { ...row }
        }
      })
      setIndustryList(updatedData)
    } else {
      setIndustryList((prevData) => {
        const index = prevData.findIndex((row) => row.sectorName === sectorName && row.industry === industry)
        const updatedRow = field === 'min' ? { ...prevData[index], [field]: value, minError: error } : { ...prevData[index], [field]: value, maxError: error }
        const updatedData = [...prevData]
        updatedData[index] = updatedRow
        return updatedData
      })
    }
  }

  function handleConfirmChange (clickedRow) {
    const updatedData = industryList.map((x) => {
      if (x.industry === clickedRow) {
        return {
          ...x,
          exclude: !x.exclude,
          minError: false,
          maxError: false,
          min: null,
          max: null
        }
      }
      return x
    })
    setIndustryList(updatedData)
  }

  const handleRowSelection = (industry) => {
    const selectedIDs = new Set(industry)
    const selectedRows = industryList.filter((row) =>
      selectedIDs.has(row.industry)
    )
    setSelectedRows(selectedRows)
  }

  const handleSubmit = () => {
    const hasError = industryList && industryList?.filter(industry => industry.minError || industry.maxError)?.length > 0
    if (!hasError) {
      const newIndustryList = industryList?.filter((industry) => !industry.disabled && (industry.exclude || industry.min !== '' || industry.max !== ''))
      if (newIndustryList && newIndustryList?.length) {
        addNewIndustry(newIndustryList)
        handleClose()
        setIndustryList(allIndustries)
      } else {
        handleClose()
      }
    } else {
      showError('Some industries contain error')
    }
  }

  useEffect(() => {
    setIndustryList(allIndustries)
  }, [allIndustries])

  return (
    <Box sx={{ width: '100%' }}>
      <Tooltip
        disableFocusListener
        disableTouchListener
        placement='top'
        title='Restrict industry in the portfolio by excluding industry or restricting their z-score'
      >
        <Button
          variant='outlined'
          color='primary'
          startIcon={<AddIcon />}
          onClick={handleOpen}
          sx={{
            fontFamily: 'Open Sans'
          }}
        >
          Add Industry
        </Button>
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography id='modal-modal-title' variant='h6' sx={{ fontWeight: 400, color: '#34475A' }}>
              Add Industry
            </Typography>
            <IconButton onClick={() => setOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box style={{ width: '100%' }}>
            <Grid container xs={12} justifyContent='space-between' alignItems='center'>
              <DataGridPro
                autoHeight
                rows={industryList}
                columns={columns}
                onRowSelectionModelChange={handleRowSelection}
                getRowId={getRowId}
                disableRowSelectionOnClick
                pagination
                pageSizeOptions={[10]}
                sx={{
                  '& .MuiDataGrid-cell:focus-within': {
                    outline: 'none'
                  },
                  '& .MuiDataGrid-cell:focus': {
                    outline: 'none'
                  },
                  [`.${gridClasses.main}`]: {
                    height: 'min(100vh - 32px - 56px - 53px - 41px, 600px - 32px - 56px - 53px - 41px)',
                    overflow: 'auto'
                  },
                  [`.${gridClasses.columnHeaders}`]: {
                    position: 'sticky',
                    top: 0,
                    zIndex: 1
                  },
                  [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
                    color: '#74788d',
                    fontWeight: 600
                  },
                  [`.${gridClasses.virtualScroller}`]: {
                    overflowY: 'auto !important',
                    scrollbarGutter: 'stable',
                    scrollbarWidth: 'none'
                  }
                }}
                initialState={{
                  ...industryList.initialState,
                  pagination: { paginationModel: { pageSize: 10 } }
                }}
              />
            </Grid>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', float: 'right' }}>
            <Button variant='contained' size='small' onClick={handleSubmit}>Submit</Button>
            <Button
              size='small' variant='text' sx={{ color: '#9e9e9e', mx: 2 }}
              onClick={() => { handleClose(); setIndustryList(allIndustries) }}
            > Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}

export default AddIndustryModal
