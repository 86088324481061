import React, { useEffect, useState } from 'react'
import { Box, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro'
import { randomId } from '@mui/x-data-grid-generator'

const renderTableSkeleton = (header, hiddenColumns) => {
  if (hiddenColumns)
    header = header.filter(col => !hiddenColumns.includes(col.field))
  return (
    <TableContainer mt={5}>
      <Table className='risk-page-table'>
        <TableHead>
          <TableRow>
            {header.map((item, index) => {
              return (
                <TableCell key={index}>{item.headerName}</TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.from({ length: 5 }).map((_, index) => (
            <TableRow key={index}>
              {Array.from({ length: header.length }).map((_, index) => (
                <TableCell key={index}>
                  <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
                </TableCell>))}
            </TableRow>))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const MergerAndAcquisition = ({ rows }) => {
  const [rowData, setRowData] = useState([])

  const [mergerAndAcquisitionColumns] = useState([
    { field: 'instrId', headerName: 'Instrument Id', flex: 1 },
    { field: 'localSymbol', headerName: 'Local Symbol', flex: 1 },
    {
      field: 'effectiveDate',
      headerName: 'Effective Date',
      headerAlign: 'right',
      align: 'right',
      flex: 1,
      type: 'date',
      valueGetter: (params) => {
        if (!params?.value) return params?.value
        const date = new Date(params?.value)
        return new Date(date?.getTime() + date?.getTimezoneOffset() * 1000 * 60)
      },
      renderCell: (params) => params?.row?.effectiveDate ? params?.row?.effectiveDate?.split('T')[0] : ''
    },
    {
      field: 'startDate',
      headerName: 'Start Date',
      headerAlign: 'right',
      align: 'right',
      flex: 1,
      type: 'date',
      valueGetter: (params) => {
        if (!params?.value) return params?.value
        const date = new Date(params?.value)
        return new Date(date?.getTime() + date?.getTimezoneOffset() * 1000 * 60)
      },
      renderCell: (params) => params?.row?.startDate ? params?.row?.startDate?.split('T')[0] : ''
    },
    {
      field: 'endDate',
      headerName: 'End Date',
      headerAlign: 'right',
      align: 'right',
      flex: 1,
      type: 'date',
      valueGetter: (params) => {
        if (!params?.value) return params?.value
        const date = new Date(params?.value)
        return new Date(date?.getTime() + date?.getTimezoneOffset() * 1000 * 60)
      },
      renderCell: (params) => params?.row?.endDate ? params?.row?.endDate?.split('T')[0] : ''
    },
    { field: 'desc', headerName: 'Description', flex: 3 },
  ])

  useEffect(() => {
    if (!rows?.loading && Array.isArray(rows?.data?.mergerAndAcquisition))
      setRowData(rows.data?.mergerAndAcquisition.map(obj => ({ ...obj, id: randomId() })))
  }, [rows])

  return (
    <Box sx={{
      position: 'relative',
      overflowY: 'auto',
      scrollbarWidth: 'none',
      height: 'calc(100vh - 48px - 15px - 39px - 16px - 16px - 48px - 24px - 20px - 10px)'
    }}>
      {rows.loading
        ? renderTableSkeleton(mergerAndAcquisitionColumns, ['localSymbol'])
        : <DataGridPro
          density='compact'
          autoHeight
          getRowHeight={() => 'auto'}
          rows={rowData || []}
          columns={mergerAndAcquisitionColumns}
          disableRowSelectionOnClick
          getRowId={(row) => row?.id}
          pagination
          pageSizeOptions={[20]}
          initialState={{
            ...rowData?.initialState,
            pagination: { paginationModel: { pageSize: 20 } },
            sorting: {
              sortModel: [{ field: 'effectiveDate', sort: 'desc' }],
            },
            columns: {
              columnVisibilityModel: { localSymbol: false }
            }
          }}
          sx={(theme) => ({
            '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
              py: '8px'
            },
            '& .MuiDataGrid-cell:focus': {
              outline: 'none'
            },
            [`.${gridClasses.main}`]: {
              overflow: 'unset'
            },
            [`.${gridClasses.columnHeaders}`]: {
              position: 'sticky',
              backgroundColor: theme.palette.background.paper,
              top: 0,
              zIndex: 1,
            },
            [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
              color: '#74788d',
              fontWeight: 600
            },
            [`.${gridClasses.footerContainer}`]: {
              position: 'sticky',
              bottom: '-1px',
              backgroundColor: theme.palette.background.paper,
              zIndex: 1
            }
          })}
        />
      }
    </Box>
  )
}

export default MergerAndAcquisition