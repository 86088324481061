import { ErrorBoundary } from '@sentry/react'
import { Table, TableBody, TableHead, TableRow } from '@mui/material'
import ErrorFallback from '../../ErrorFallback'
import { TradeListDataTable } from './TradeListDataTable'

const TradePersonalizationTab = (prop) => {
  const { loading, header, tableData, item, type, screen, rowData, showCompact = false } = prop
  if (loading) {
    return (
      <>
        <Table className='security-holdings-table'>
          <TableHead>
            <TableRow>
              {tableData[item]?.tableHeaderHTML}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              {tableData[item]?.rowSkeleton}
            </TableRow>
            <TableRow>
              {tableData[item]?.rowSkeleton}
            </TableRow>
            <TableRow>
              {tableData[item]?.rowSkeleton}
            </TableRow>
          </TableBody>
        </Table>
      </>
    )
  } else {
    return (
      <>
        <ErrorBoundary fallbackRender={(props) => (<ErrorFallback {...props} screen={screen} />)}>
          <TradeListDataTable showCompact={showCompact} tableHeader={header} columns={type?.fieldLabels} rows={rowData} />
        </ErrorBoundary>
      </>
    )
  }
}

export default TradePersonalizationTab
