import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { API } from 'aws-amplify'
import { GridRowModes } from '@mui/x-data-grid-pro'
import * as Sentry from '@sentry/react'
import { useAuth } from '../../contexts/AuthContext'
import { useErrorToast } from '../../hooks/useErrorToast'
import { CustomConstraintField, CustomEditConstraintField } from './components/CustomConstraintField'
import CustomTooltipIcon from './components/CustomTooltipIcon'
import DataTable from './components/DataTable'
import Loader from '../Loader'

const TaxConstraints = ({ data, getUpdatedData }) => {
  const [taxConstraintsData, setTaxConstraintsData] = useState(data?.data || [])
  const [rowModesModel, setRowModesModel] = useState({})

  const { showError } = useErrorToast()

  const { user } = useAuth()
  const params = useParams()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setTaxConstraintsData(data?.data || [])
    setIsLoading(false)
  }, [data])

  const editTaxConstraints = (constraintData) => {
    setIsLoading(true)
    API.patch('baseUriAccountOptimization', `account-customization/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/accounts/tax-constraints/${params.accountId}`, {
      body: {
        [`${constraintData.propertyCode}`]: constraintData.value
      }
    })
      .then((response) => {
        if (response.success) {
          const newConstraintData = taxConstraintsData.map((row) => {
            if (row.id === constraintData.id) {
              return { id: constraintData.id, ...row, value: response.data[`${constraintData.propertyCode}`] }
            }
            return row
          })
          setTaxConstraintsData(newConstraintData)
          getUpdatedData(newConstraintData)
        }
      })
      .catch((error) => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const deleteTaxConstraints = (id) => {
    setIsLoading(true)
    const constraintData = taxConstraintsData?.find((row) => row.value === id)
    API.patch('baseUriAccountOptimization', `account-customization/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/accounts/tax-constraints/${params.accountId}`, {
      body: {
        [`${constraintData.propertyCode}`]: constraintData.propertyCode === 'taxSensitivity' ? 'NA' : 0
      }
    })
      .then((response) => {
        if (response.success) {
          const newConstraintData = taxConstraintsData.map((row) => {
            if (row.id === constraintData.id) {
              return { id: constraintData.id, ...row, value: response.data[`${constraintData.propertyCode}`] }
            }
            return row
          })
          setTaxConstraintsData(newConstraintData)
          getUpdatedData(newConstraintData)
        }
      })
      .catch((error) => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const customValidations = (row) => {
    return (row.value === null || row.value === '' || row.error)
  }

  const columns = [
    {
      field: 'description',
      headerName: 'Name',
      flex: 1,
      editable: false,
      maxWidth: 300,
      renderCell: (params) => (
        <>
          {params.value}
          {params.row.propertyCode !== 'taxSensitivity'
            ? <CustomTooltipIcon text='Value must be greater than 0' action='info' />
            : ''}
        </>
      )
    },
    {
      field: 'value',
      headerName: 'Value',
      flex: 1,
      maxWidth: 300,
      editable: true,
      renderCell: (params) => (
        <CustomConstraintField params={params} rowModesModel={rowModesModel} />
      ),
      renderEditCell: (params) => (
        <CustomEditConstraintField {...params} disabled={rowModesModel[params.row.id]?.mode !== GridRowModes.Edit} />
      )
    }
  ]

  return (
    <>
      {isLoading ? <Loader /> : ''}
      <DataTable data={taxConstraintsData} initialColumns={columns} showPagination={false} deleteKey='value' editData={editTaxConstraints} deleteData={deleteTaxConstraints} setRowModes={setRowModesModel} customValidations={customValidations} />
    </>
  )
}

export default TaxConstraints
