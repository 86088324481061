import { useState } from 'react'
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { MenuItem } from '@mui/material'

const CustomMigrationField = ({ accounts, accountsLoading, params }) => {
    const [selectedAccount, setSelectedAccount] = useState(accounts?.find(account => account?.accountId === params?.row?.targetAccountId))
    const handleAccountChange = (event, newValue) => {
        params.api.setEditCellValue({ id: params.id, field: 'targetAccountName', value: newValue?.accountName })
        params.api.setEditCellValue({ id: params.id, field: 'targetAccountId', value: newValue?.accountId })
        setSelectedAccount(newValue)
    }

    return (
        <Autocomplete
            filterSelectedOptions
            value={selectedAccount || null}
            loading={accountsLoading}
            size='small'
            onChange={handleAccountChange}
            loadingText='Loading...'
            noOptionsText='No accounts found'
            options={accounts}
            getOptionLabel={(option) => `${option.accountName}`}
            renderOption={(props, option) => (
                <MenuItem {...props} sx={{ whiteSpace: 'normal' }} key={option?.accountId}>
                    {option?.accountName}
                </MenuItem>
            )}
            isOptionEqualToValue={(option, value) => option?.accountId === value?.accountId}
            renderInput={(params) => <TextField {...params} />}
            style={params.style || {}}
            sx={{
                width: '400px',
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        border: 'none',
                    },
                    '&:hover fieldset': {
                        border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                        border: 'none',
                    },
                },
                '& .MuiInputBase-root': {
                    padding: 0,
                    fontSize: '14px',
                    fontWeight: 600
                },
                '.MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
                    paddingLeft: '10px'
                },
                '.MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input': {
                    padding: 0
                },
            }}
        />
    );
};

export default CustomMigrationField
