// export const IS_LOADING = 'IS_LOADING'
export const ITEM_DRAGGING = 'ITEM_DRAGGING'
export const NEW_PAGE = 'NEW_PAGE'
export const CONTENT_UPDATE = 'CONTENT_UPDATE'
export const IS_ACTIVE = 'IS_ACTIVE'
export const DELETE_PAGE = 'DELETE_PAGE'
export const BACKGROUND_CHANGE = 'BACKGROUND_CHANGE'
export const BACKGROUND_RESET = 'BACKGROUND_RESET'
export const DRAG_CHANGE = 'DRAG_CHANGE'
export const EDIT_CHARTS = 'EDIT_CHARTS'
export const DELETE_CHART = 'DELETE_CHART'
export const EDIT_LABEL_CHANGE = 'EDIT_LABEL_CHANGE'
export const SET_REPORT = 'SET_REPORT'
export const FONT_SIZE_CHANGE = 'FONT_SIZE_CHANGE'
export const IS_ACTIVE_PAGE = 'IS_ACTIVE_PAGE'
export const ZOOM_PAGE = 'ZOOM_PAGE'
export const EDITOR_NAME_CHANGE = 'EDITOR_NAME_CHANGE'
export const PREVIOUS_EDITOR_NAME_CHANGE = 'PREVIOUS_EDITOR_NAME_CHANGE'
export const CHART_NAME_CHANGE = 'CHART_NAME_CHANGE'
export const COVER_PAGE_DRAGGING = 'COVER_PAGE_DRAGGING'
export const CHART_VALUES_ADD = 'CHART_VALUES_ADD'
export const REORDER_LABELS = 'REORDER_LABELS'
export const MIDDLE_PAGE = 'MIDDLE_PAGE'
export const COVER_NAME_CHANGE = 'COVER_NAME_CHANGE'
export const UPDATE_HEADER_OR_FOOTER_CHANGE = 'UPDATE_HEADER_OR_FOOTER_CHANGE'
export const RE_SET_DATA = 'RE_SET_DATA'
export const CHANGE_TEXT_UPDATE = 'CHANGE_TEXT_UPDATE'
export const SELECTED_TEMPLATES = 'SELECTED_TEMPLATES'
export const UPDATE_ELEMENT_OBJECT = 'UPDATE_ELEMENT_OBJECT'
export const PDF_TO_IMAGES = 'PDF_TO_IMAGES'
export const ADD_COVER_PAGE_DATA = 'ADD_COVER_PAGE_DATA'
export const CLOSE_PDF_MODAL = 'CLOSE_PDF_MODAL'
export const IS_ACTIVE_REMOVE = 'IS_ACTIVE_REMOVE'
export const STORE_SVG_CODE = 'STORE_SVG_CODE'
export const CHANGE_PAGE_LAYOUT = 'CHANGE_PAGE_LAYOUT'
export const STORE_CUSTOM_TABLE_ROWS = 'STORE_CUSTOM_TABLE_ROWS'
export const CHANGE_CUSTOM_TABLE_OVERFLOW = 'CHANGE_CUSTOM_TABLE_OVERFLOW'
export const HANDLE_TABLE_OVERFLOW = 'HANDLE_TABLE_OVERFLOW'
// export const CREATE_PAGE_FOR_TABLE_OVERFLOW = 'CREATE_PAGE_FOR_TABLE_OVERFLOW'
export const CREATE_PAGE_FOR_OVERFLOWING_ELEMENTS = 'CREATE_PAGE_FOR_OVERFLOWING_ELEMENTS'
export const QUEUE_CHANGE = 'QUEUE_CHANGE'