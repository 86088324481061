import { Skeleton, TableBody, TableCell, TableRow } from '@mui/material'

export const RiskTableloader = ({ headers }) => {
  return (
    <>
      <TableBody>
        {headers === 'Security'
          ? <>
            {[...Array(4)]?.map((e, i) => (
              <TableRow>
                <TableCell>
                  <Skeleton
                    variant='text'
                    sx={{ fontSize: '1rem' }}
                    width={100}
                  />
                </TableCell>
                <TableCell>
                  <Skeleton
                    variant='text'
                    sx={{ fontSize: '1rem' }}
                    width={100}
                  />
                </TableCell>
                <TableCell>
                  <Skeleton
                    variant='text'
                    sx={{ fontSize: '1rem' }}
                    width={100}
                  />
                </TableCell>
                <TableCell>
                  <Skeleton
                    variant='text'
                    sx={{ fontSize: '1rem' }}
                    width={100}
                  />
                </TableCell>
                <TableCell />
                <TableCell>
                  <Skeleton
                    variant='text'
                    sx={{ fontSize: '1rem' }}
                    width={100}
                  />
                </TableCell>
              </TableRow>
            ))}
            </>

          : <>
            {[...Array(4)]?.map((e, i) => (
              <TableRow>
                <TableCell>
                  <Skeleton
                    variant='text'
                    sx={{ fontSize: '1rem' }}
                    width={100}
                  />
                </TableCell>
                <TableCell>
                  <Skeleton
                    variant='text'
                    sx={{ fontSize: '1rem' }}
                    width={100}
                  />
                </TableCell>
                <TableCell>
                  <Skeleton
                    variant='text'
                    sx={{ fontSize: '1rem' }}
                    width={100}
                  />
                </TableCell>
                <TableCell />
                <TableCell>
                  <Skeleton
                    variant='text'
                    sx={{ fontSize: '1rem' }}
                    width={100}
                  />
                </TableCell>
              </TableRow>
            ))}
            </>}
      </TableBody>
    </>
  )
}
