import React, { useState } from 'react'
import { Box, IconButton, Modal } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import StyledTooltip from './StyledTooltip'
import refreshIcon from '../../../../assets/images/refreshIcon.svg'
import { tradeModalStyle } from './tradeModalStyle'
import UpdatePropertiesModal from '../UpdatePropertiesModal'

const CustomUpdatePropertiesCell = (params) => {
  const [editIndex, setEditIndex] = useState()
  const [isUPShown, setIsUPShown] = useState(false)

  const viewUpdatePropertiesModalShow = (index, itemAP) => {
    const approvalPropertiesParameter = [index, itemAP]
    setEditIndex(approvalPropertiesParameter)
    setIsUPShown(true)
  }

  return (
    <>
      <StyledTooltip title='Adhoc Optimization & Update Properties'>
        <IconButton
          style={{
            marginLeft: '1.7rem'
          }}
          className='action-clock-btn'
          onClick={() => {
            viewUpdatePropertiesModalShow(
              params.row.account.accountId,
              params.row.account
            )
          }}
        >
          <img
            src={refreshIcon}
            alt=''
          />
        </IconButton>
      </StyledTooltip>
      <Modal
        open={isUPShown}
        onClose={() => setIsUPShown(false)}
      >
        <Box sx={{ ...tradeModalStyle, height: 'min(100vh, 600px)' }}>
          <IconButton aria-label='close' edge='end' style={{ float: 'right', marginTop: '5px' }} onClick={() => setIsUPShown(false)}>
            <CloseIcon />
          </IconButton>
          <UpdatePropertiesModal
            index={editIndex}
            selectedItem={params?.data}
            allScenarioList={params?.allScenarioList}
          />
        </Box>
      </Modal>
    </>
  )
}

export default CustomUpdatePropertiesCell
