import React, { useEffect, useState } from 'react'
import './taxManagementChart.css'
import { useDispatch, useSelector } from 'react-redux'
import { fetchDataAndDispatch, formatCurrencyWithSymbol } from '../APIResponseConverts/APIResponseConverts'
import { taSavingsData } from '../APIResponseConverts/chartDefaultsData'
import { taxSavingsData } from '../../../../utils/pdf-customizer/_data'
import { addChartValues } from '../../../../store/pdf-customizer-reducer/Action/chartDropAction'
import { useAuth } from '../../../../contexts/AuthContext'

const TaxManagementChart = ({
  data,
  chartIndex,
  index,
  chartHeight,
  chartWidth,
  clickEvent
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isDragging, setIsDragging] = useState(false)
  const { taxSavingDataResponse, isResponseElements } = useSelector(
    (state) => state.chartState
  )
  const templateData = useSelector(state => state.templateData)
  const dispatch = useDispatch()
  const { user } = useAuth()

  useEffect(() => {
    if (isResponseElements && !taxSavingDataResponse && !isLoading) {
      setIsLoading(true)
      fetchDataAndDispatch(() => taxSavingsData(templateData, user?.userGroup), 'TAX_SAVING_DATA', dispatch)
    }
    if (isResponseElements && taxSavingDataResponse) {
      setIsLoading(false)
    }
  }, [isResponseElements, taxSavingDataResponse, dispatch])

  const itemsResponse = taxSavingDataResponse?.data || taSavingsData?.data

  const chartStyle = {
    width: '100%',
    padding: '4px'
  }

  useEffect(() => {
    if (data.value || !itemsResponse) return

    const mappedDatasets = [
      itemsResponse?.totalTaxSaved,
      itemsResponse?.ytdEstimatedTax,
      itemsResponse?.ytdEstimatedTaxWithoutTaxSaving
    ].map((label, index) => ({
      label: index + 1,
      hidden: false,
      backgroundColor:
        index === 2 ? 'rgba(98,184,168,1)' : 'rgba(230, 236, 243, 1)'
    }))

    const chartValues = {
      labels: [],
      datasets: mappedDatasets,
      chartIndex: data?.chartIndex,
      pageIndex: data?.pageIndex
    }

    dispatch(addChartValues(chartValues))
  }, [itemsResponse, dispatch, data])

  const renderLightBox = (amount, label, defaultAmount, background) => (
    <div
      className='light-box width-25'
      style={background ? { background } : {}}
    >
      <p className='margin-bottom-0 text-align-center header'>
        $&nbsp;
        {itemsResponse?.[amount] !== undefined
          ? formatCurrencyWithSymbol(itemsResponse?.[amount] || 0, 2, '')
          : defaultAmount}
      </p>
      <p className='margin-bottom-0'>{label}</p>
    </div>
  )
  return (
    <div
      className={'group'}
      key={data?.chartIndex + data?.pageIndex}
      style={chartStyle}
    >
      {isLoading
        ? <span className='loading-text'>Loading...</span>
        : <div className='flexOrAlignOrBetWeen directionColumn height-100  tax-management-comparison-chart height-100 '>
          <div className='flexOrAlign evenly'>
            {renderLightBox(
              'ytdEstimatedTax',
              'Est. Tax Bill without Tax Management',
              '19712',
              Array.isArray(data?.value?.datasets)
                ? data?.value.datasets[0]?.backgroundColor
                : undefined
            )}
            <div>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='17'
                height='2'
                viewBox='0 0 17 2'
                fill='none'
              >
                <path d='M0.5 1H16.5' stroke='#2F2F2F' />
              </svg>
            </div>
            {renderLightBox(
              'ytdEstimatedTaxWithoutTaxSaving',
              'Est. Tax Bill without Tax Management',
              '3912',
              Array.isArray(data?.value?.datasets)
                ? data?.value.datasets[1]?.backgroundColor
                : undefined
            )}
          </div>
          <div>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='17'
              height='7'
              viewBox='0 0 17 7'
              fill='none'
            >
              <path d='M0.5 1H16.5' stroke='#2F2F2F' />
              <path d='M0.5 6H16.5' stroke='#2F2F2F' />
            </svg>
          </div>
          <div
            className='text-align-center light-box bg-green width-75'
            style={{
              background: Array.isArray(data?.value?.datasets)
                ? data?.value.datasets[2]?.backgroundColor
                : '#33a68dbd'
            }}
          >
            <p className='margin-bottom-0 header'>
              $&nbsp;
              {itemsResponse?.totalTaxSaved !== undefined
                ? formatCurrencyWithSymbol(itemsResponse?.totalTaxSaved || 0, 2, '')
                : '15,800'}
            </p>
            <p className='margin-bottom-0'>Estimated Tax Savings for {new Date(itemsResponse?.runDate).getFullYear()}(YTD)</p>
          </div>
        </div>}
    </div>
  )
}

export default TaxManagementChart
