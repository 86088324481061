import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, List } from '@mui/material'
import 'rc-slider/assets/index.css'
import arisLogoA from '../assets/images/ArisLogoA.svg'
import NewArisLogoWhite from '../assets/images/arisNewLogoWhite.svg'
import { useAuth } from '../contexts/AuthContext'
import { useMenuConfig } from '../hooks/useMenuConfig'
import NonSubmenuItems from './NonSubmenuItems'
import SubMenuItems from './SubMenuItems'

const PreviewLeftSidebar = ({ leftSidebarProps }) => {
  const { user } = useAuth()
  const sidebarMenuList = useMenuConfig()
  const { userGroup } = useSelector((state) => state.user)
  const [userGrp, setUserGrp] = useState(user?.userGroup || userGroup)

  return (
    <Box
      className='sidebar'
      sx={{
        backgroundColor: userGrp === 'admin' && leftSidebarProps ? leftSidebarProps?.primaryColor : '#002A59',
        overflowX: 'hidden'
      }}
      id='sidebar'
    >
      <Box
        className='logo' sx={{
          width: '100%',
          backgroundColor: userGrp === 'admin' && leftSidebarProps ? leftSidebarProps?.primaryColor : '#002A59',
          position: 'sticky',
          top: 0,
          zIndex: 1
        }}
      >
        {(
              userGrp === 'admin' && leftSidebarProps
                ? (
                  <img
                    src={leftSidebarProps?.mainLightLogoUrl || NewArisLogoWhite} alt='sponsor-logo' height={40} width={180} style={{ margin: '16px 0 8px 0' }} loading='eager'
                  />
                  )
                : (
                  <img src={NewArisLogoWhite} alt='aris logo' height={40} width={180} style={{ margin: '16px 0 8px 0' }} loading='eager' />
                  )
            )}
      </Box>
      <Box
        className='logo-tablet'
        sx={{ backgroundColor: userGrp === 'admin' && leftSidebarProps ? leftSidebarProps?.primaryColor : '#002A59' }}
      >
        {(
              userGrp === 'admin' && leftSidebarProps
                ? (
                  <img
                    src={leftSidebarProps?.mobLightLogoUrl || arisLogoA} alt='sponsor-logo' height={40} width={180} loading='eager'
                  />
                  )
                : (
                  <img src={arisLogoA} alt='aris-logo' loading='eager' />
                  )
            )}
      </Box>
      <List
        className='menu-list'
        sx={{
          '& .MuiListItemIcon-root': {
            minWidth: '30px',
            marginTop: '4px',
            marginBottom: '4px',
            lineHeight: '21px'
          },
          pointerEvents: 'none'
        }}
        disablePadding
      >
        <>
          {sidebarMenuList?.slice(0, 5)?.map((menuItem, index) => (
            menuItem.hasSubMenu
              ? (
                <SubMenuItems
                  key={index}
                  id={menuItem.id}
                  menuId={menuItem.menuId}
                  paths={menuItem.paths}
                  activePath='/account-review/account-dashboard/'
                  icon={menuItem.icon}
                  name={menuItem.name}
                  leftSidebarProps={leftSidebarProps}
                  isOpen={menuItem.moduleCd === 'account-review'}
                  parentModule={menuItem.moduleCd}
                />
                )
              : (
                <NonSubmenuItems
                  key={index}
                  icon={menuItem.icon}
                  linkTo={menuItem.path}
                  leftSidebarProps={leftSidebarProps}
                  label={menuItem.name}
                  id={menuItem.id}
                />
                )
          )
          )}
        </>
      </List>
    </Box>
  )
}

export default PreviewLeftSidebar
