import React, { useCallback, useContext, useState, useEffect } from 'react'
import { Box, IconButton, Tooltip, Typography } from '@mui/material'
import eyefill from '../../../assets/images/eye-fill.svg'
import { useAuth } from '../../../contexts/AuthContext'
import { IPSTable } from '../components/IPSTable'
import { policyDataContext } from '../Monitoring/index'

const Policy = ({ onCellClick }) => {
  const { policyData, showTradeData } = useContext(policyDataContext)
  const { user } = useAuth()
  const [rows, setRows] = useState((policyData?.rows || []).map((item, index) => ({
    ...item,
    pId: index + 1
  }
  )))

  const [columns, setColumns] = useState(user?.userGroup === 'pm'
    ? [...(policyData?.columns || []).map((item) => {
        if (item.field === 'policyApplied') {
          return {
            ...item,
            renderCell: (params) => {
              return (
                <Typography sx={{
                  color: '#0066C7',
                  fontWeight: 600,
                  fontFamily: 'Open Sans',
                  textDecoration: 'underline',
                  cursor: 'pointer'
                }}
                >
                  {
                  params.value
                }
                </Typography>
              )
            }
          }
        } else if (item.field === 'actionRequired') {
          return {
            ...item,
            renderCell: (params) => {
              return (
                <Typography sx={{
                  color: '#0066C7',
                  fontWeight: 600,
                  fontFamily: 'Open Sans',
                  textDecoration: 'underline',
                  cursor: 'pointer'
                }}
                >
                  {
                params.value
              }
                </Typography>
              )
            }
          }
        }
        return item
      }),
      {
        field: 'action',
        headerName: '',
        flex: 1,
        sortable: false,
        disableColumnMenu: true,
        maxWidth: 50,
        align: 'center',
        renderCell: (params) => {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Tooltip title='View Trade'>
                <IconButton
              disabled={user?.userGroup !== 'pm'}
              onClick={() => {
                handleViewTradeClick(params)
              }}
              sx={{ opacity: (user?.userGroup !== 'pm') ? 0.4 : 1 }}
            >
              <img src={eyefill} alt='eyefill' />
            </IconButton>
              </Tooltip>
            </div>
          )
        }

      }]
    : [...(policyData?.columns || []).map((item) => {
        if (item.field === 'policyApplied') {
          return {
            ...item,
            renderCell: (params) => {
              return (
                <Typography sx={{
                  color: '#0066C7',
                  fontWeight: 600,
                  fontFamily: 'Open Sans',
                  textDecoration: 'underline',
                  cursor: 'pointer'
                }}
                >
                  {
                  params.value
                }
                </Typography>
              )
            }
          }
        } else if (item.field === 'actionRequired') {
          return {
            ...item,
            renderCell: (params) => {
              return (
                <Typography sx={{
                  color: '#0066C7',
                  fontWeight: 600,
                  fontFamily: 'Open Sans',
                  textDecoration: 'underline',
                  cursor: 'pointer'
                }}
                >
                  {
                  params.value
                }
                </Typography>
              )
            }
          }
        }
        return item
      })]
  )

  const handleViewTradeClick = (params) => {
    if (showTradeData) {
      showTradeData(params.row.policyName)
    }
  }

  useEffect(() => {
    setRows((policyData?.rows || []).map((item, index) => ({
      ...item,
      pId: index + 1
    }
    )))
  }, [policyData])

  const handleCellClick = useCallback((params, event) => {
    onCellClick(params)
  }, [])

  return (
    <Box px={4} pb={3}>
      <IPSTable columns={columns} rows={rows} onCellClick={handleCellClick} />
    </Box>
  )
}

export default Policy
