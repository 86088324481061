import React, { useEffect, useState } from 'react'
import { QRCodeCanvas } from 'qrcode.react'
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined'
import { Box, Drawer, Grid, IconButton, Stack, Typography } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { useErrorToast } from '../../../hooks/useErrorToast'
import { CopyToClipboardSecret } from './CopyToClipboardSecret'
import { MfaVerificationCodeLogin } from './MfaVerificationCodeLogin'
import { SignInMfa } from './SignInMfa'


const SCANNER_STEPS_LIST=[
  "Open the authenticator app on your device. Use Google Authenticator",
  "Tap on a plus sign",
  "Select the option to scan a QR code",
  "Point your device's camera at the QR code displayed on the screen",
  "Your authenticator app should automatically recognize the QR code and add the account. The app will generate a one-time code that you can use to verify your identity when prompted."
]

const SECRET_KEY_STEPS_LIST=[
  "Open your authenticator app and tap on the option to add a new account.",
  "Select the option to manually enter a key or code.",
  "Type or paste the secret key into the appropriate field.",
  "Choose the type of account you are adding, such as Time-based One-Time Password (TOTP) or HMAC-based One-Time Password (HOTP).",
  "Click the save or done button to complete the process. Your authenticator app will now generate a one-time code that you can use to verify your identity when prompted."
]

export const MfaSetup = (props) => {
  const [mfaCodeURL, setMfaCodeURL] = useState('')
  const [mfaCode, setMfaCode] = useState('')
  const { showError } = useErrorToast()
  const [showHelpMenu,setShowHelpMenu]=useState(false);

  useEffect(() => {
    const getQRCode = async () => {
      const signInDetails = await SignInMfa({ user: props?.user, action: props?.action })
      if (signInDetails?.type === 'SUCCESS') {
        setMfaCodeURL(`otpauth://totp/${props?.user?.username}?secret=${signInDetails?.value}&issuer=ARIS%20Investing`)
        setMfaCode(signInDetails?.value)
      } else {
        showError(signInDetails?.value?.message)
      }
    }
    getQRCode()
  }, [])


  return (
    <Stack>
      <Grid container mt={4}>
        {!mfaCode
          ? <CircularProgress sx={{ margin: 'auto', mt: 24, mb: 24 }} />
          : <>
            <Grid xs={12} sm={12}>
              <Typography gutterBottom sx={{
                color: '#74788D',
                fontSize:'14px'
              }}>
                Scan this QR code using an authenticator app, or manually enter the secret key.
                 <span
                 className='help-link'
                 onClick={()=>setShowHelpMenu(true)}
                 >
                  Need Help?
                </span>
              </Typography>
              <Drawer
                  anchor={'right'}
                  open={showHelpMenu}
                  onClose={()=>setShowHelpMenu(false)} 
                >
                  <Box sx={{textAlign:'right',margin:2,marginBottom:0}}>
                    <IconButton onClick={()=>setShowHelpMenu(false)}>
                    <HighlightOffOutlinedIcon sx={{
                      fontSize:'22px',
                    }} />
                    </IconButton>
                  </Box>
                  <Box sx={{margin:2,marginTop:0,width:'400px',paddingBottom:2}}>
                    <Typography>
                      <b>
                      To scan a QR code using an authenticator app:
                      </b>
                    </Typography>
                      {/* <List component='ol'>
                        {
                          (SCANNER_STEPS_LIST || []).map((step,index)=>(
                            <ListItem disablePadding key={index}>
                              <ListItemButton sx={{padding:0}}>
                                {index+1}.&nbsp;
                                <ListItemText primary={step} />
                              </ListItemButton>
                            </ListItem>
                          ))
                        }
                      </List> */}
                      <ol className='ps-4'>
                        {
                          (SCANNER_STEPS_LIST || []).map((step,index)=>(
                            <li key={index} className='my-2'>
                              {step} 
                            </li>
                          ))
                        }
                      </ol>
                      <hr style={{margin:'16px 0'}}/>
                      <Typography>
                      <b>
                      To manually enter a secret key:
                      </b>
                    </Typography>
                      <ol className='ps-4'>
                        {
                          (SECRET_KEY_STEPS_LIST || []).map((step,index)=>(
                            <li key={index} className='my-2'>
                              {step} 
                            </li>
                          ))
                        }
                      </ol>
                  </Box>
              </Drawer>
            </Grid>
            <Box sx={{ margin: 'auto',marginY:2 }}>
              <Grid xs={12} sm={12} justifyContent={'center'}>
                <QRCodeCanvas
                  value={mfaCodeURL}
                />
              </Grid>
            </Box>
            <Grid xs={12} sm={12}>
              <CopyToClipboardSecret mfaCode={mfaCode} />
            </Grid>
            <Grid xs={12} sm={12}>
              <MfaVerificationCodeLogin user={props?.user} action={props?.action} optProps={props?.optProps} />
            </Grid>
          </>}
      </Grid>
    </Stack>
  )
}
