import { Box, Button, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import SaveIcon from '@mui/icons-material/Save'
const DeleteDocument = ({ handleCloseDeleteModal, deleteStatement, loading }) => {
  return (
    <>
      <Typography sx={{
        fontWeight: '500',
        fontSize: '20px',
        color: '#34475A',
        fontFamily: 'Open Sans !important',
        textAlign: 'center'
      }}
      >Are you sure you want to cancel this policy statement?
      </Typography>
      <Box display='flex' justifyContent='center' pt={2}>
        <Button autoFocus onClick={handleCloseDeleteModal} disabled={loading}>
          Cancel
        </Button>
        {loading
          ? <LoadingButton
              loading
              loadingPosition='start'
              startIcon={<SaveIcon />}
              variant='outlined'
              sx={{ mx: '10px' }}
            >
            Confirm
            </LoadingButton>
          : <Button variant='contained' onClick={deleteStatement}>Confirm</Button>}
      </Box>
    </>
  )
}

export default DeleteDocument
