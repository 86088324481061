import { useState } from 'react'
import { Box, IconButton, Modal, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import TradeSummaryIcon from '../../../../assets/images/Trade-chart-summary.svg'
import StyledTooltip from './StyledTooltip'
import { tradeModalStyle } from './tradeModalStyle'
import ViewTradeSummaryChart from '../ViewTradeSummaryChart'

const CustomTradeSummaryChartCell = (params) => {
  const [editIndex, setEditIndex] = useState()
  const [isSummaryChartShown, setIsSummaryChartShown] = useState(false)

  const viewSummaryChartModal = (accOptMapId, scDesc, accountIndex, accountId) => {
    const sumParameter = [accOptMapId, scDesc, accountIndex, accountId]
    setEditIndex(sumParameter)
    setIsSummaryChartShown(true)
  }

  return (
    <>
      <IconButton
        size='small'
        className={params.row?.failedScenario ? 'disabled-action-btn action-clock-btn' : 'action-clock-btn'}
        onClick={() => {
          !params.row?.failedScenario && viewSummaryChartModal(
            params.row?.accOptMapId,
            params.row?.scenarioDesc,
            params.row?.dIndex,
            params.row?.accountId
          )
        }}
      >
        <StyledTooltip title='Trade Summary'>
          <img
            src={TradeSummaryIcon}
            alt='trade-summary'
            height={16}
            width={16}
          />
        </StyledTooltip>
      </IconButton>

      <Modal
        open={isSummaryChartShown}
        onClose={() => setIsSummaryChartShown(false)}
      >
        <Box sx={{
          ...tradeModalStyle,
          maxWidth: '1000px'
        }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography style={{ fontSize: '22px', fontWeight: 400, fontFamily: 'Open Sans' }}>Trade Summary</Typography>
            <IconButton aria-label='close' onClick={() => setIsSummaryChartShown(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
          <ViewTradeSummaryChart
            accountData={editIndex}
            fArray={params?.data}
          />
        </Box>
      </Modal>
    </>
  )
}

export default CustomTradeSummaryChartCell
