import React from 'react'
import { CircleSpinnerOverlay } from 'react-spinner-overlay'
import { Box } from '@mui/material'

export default function Loader () {
  return (
    <Box>
      <CircleSpinnerOverlay
        overlayColor='rgba(0,153,255,0.2)'
        color='#175CC2'
        zIndex={1400}
      />

    </Box>
  )
}
