import ImageDisplay from '../../common/ImageDisplay'

const ImageHolder = ({ data, chartIndex, index, chartHeight, clickEvent }) => {


  return (
    <div
      className={'group'}
      key={data?.chartIndex + data?.pageIndex}
      style={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      {data?.value
        ? (
          <ImageDisplay
            imageUrl={
              data?.value?.image ||
              'https://aris-public-assets-dev.s3.us-east-2.amazonaws.com/ARIS+Logo/aris-investing-logo.svg'
            }
            width={'100%'}
            height={'100%'}
            style={{ objectFit: 'contain' }}
            alt='Logo'
            className='upload-image'
            pageIdx={index}
            chartIdx={chartIndex}
            svgContent={data?.value?.svgCode || null}
            name={data.name}
          />
        )
        : (
          <div className='upload-image-box'>
            <svg>
              <line x1='0' y1='100%' x2='100%' y2='0' />
              <line x1='0' y1='0' x2='100%' y2='100%' />
            </svg>
          </div>
        )}
    </div>
  )
}

export default ImageHolder
