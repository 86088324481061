import React, { useState } from 'react'
import { API } from 'aws-amplify'
import { Cached } from '@mui/icons-material'
import { Box, Tooltip } from '@mui/material'
import * as Sentry from '@sentry/react'
import dayjs from 'dayjs'
import { useAuth } from '../../../../contexts/AuthContext'
import { useErrorToast } from '../../../../hooks/useErrorToast'
import { getColorProperties } from '../../TrdaeApproved/getColorProperties'

const TradeStatusColumnCell = (params) => {
    const [syncIconClick, setSyncIconClick] = useState(false)
    const { showError } = useErrorToast()
    const { user } = useAuth()

    const getLatestFixTradeStatus = (orderId) => {
        API.get(
            'baseUriTrade',
            `trade/v1/${user?.userGroup}/adhoc/trades/${orderId}`
        )
            .then((response) => {
                if (response?.data?.fixTradeStatus) {
                    const data = response.data
                    if (params?.updateAdhocTradeData) {
                        params?.updateAdhocTradeData(data, orderId)
                    }
                }
                setSyncIconClick(false)
            })
            .catch((error) => {
                setSyncIconClick(false)
                showError(error?.response?.data?.errorInfo?.userMessage || error?.message)
                Sentry.captureException(error?.response?.data?.errorInfo?.userMessage || error?.message)
            })
    }

    const handleSyncClick = (e, orderId) => {
        setSyncIconClick(true)
        getLatestFixTradeStatus(orderId)
    }

    const id = params?.row?.orderId
    const currentDate = dayjs().format('YYYY-MM-DD')
    const date = params?.row?.trdDate?.split('T')[0]
    const data = params?.row?.fixTradeStatus || 'N/A'
    const syncStatusIcon = (!(data === 'TRADE_COMPLETED' || data === 'TRADE_FAILED') && currentDate === date) ? <Tooltip title='Refresh trade status'><Cached sx={{ width: '16px', height: '16px', ml: '5px', cursor: 'pointer' }} className={`sync-icon-rotate ${syncIconClick ? 'rotate' : ''}`} fontSize='small' onClick={(e) => handleSyncClick(e, id)} /></Tooltip> : ''
    return data !== 'N/A'
        ? <><Box sx={{ borderRadius: '20px', fontSize: '10px', px: '10px', py: '2px', ...getColorProperties(data), fontWeight: 600 }}>{data}</Box>{syncStatusIcon}</>
        : <>{data}</>
}

export default TradeStatusColumnCell