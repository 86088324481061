import { FormatDateAPI, FormatPayload } from '../components/FormatAPIRequest'
import { CreateAPI } from '../components/Actions'
import { getUserRole } from '../../../utils/getUserRole'

export const CreateSponsorData = async (newRow, tabIndex) => {
  switch (tabIndex) {
    case 'sponsor':
      return await CreateSponsor(newRow)
    case 'strategy-owner':
      return await CreateStrategyOwner(newRow)
    case 'sponsor-financial-advisors':
      return await CreateSponsorFinancialAdvisors(newRow)
    default:
      return {}
  }
}

const CreateSponsor = async (newRow) => {
  const userRole = getUserRole()
  const startDateValue = newRow.startDate ? FormatDateAPI(newRow.startDate) : null
  const endDateValue = newRow?.endDate ? FormatDateAPI(newRow.endDate) : null
  let payload = {
    sponsorCd: newRow?.sponsorCd?.toUpperCase(),
    sponsorName: newRow?.sponsorName,
    startDate: startDateValue,
    endDate: endDateValue
  }

  payload = FormatPayload(payload)

  return CreateAPI(
    'baseSponserURL',
    `data-maintenance/v1/${userRole}/sponsors`,
    payload)
}

const CreateStrategyOwner = async (newRow) => {
  const userRole = getUserRole()
  const startDateValue = newRow.startDate ? FormatDateAPI(newRow.startDate) : null
  const endDateValue = newRow?.endDate ? FormatDateAPI(newRow.endDate) : null
  let payload = {
    strategyOwnerCd: newRow?.strategyOwnerCd?.toUpperCase(),
    strategyOwnerName: newRow?.strategyOwnerName,
    startDate: startDateValue,
    endDate: endDateValue
  }

  payload = FormatPayload(payload)
  return CreateAPI(
    'baseSponserURL',
    `data-maintenance/v1/${userRole}/strategy-owners`,
    payload)
}

const CreateSponsorFinancialAdvisors = async (newRow) => {
  const userRole = getUserRole()
  const startDateValue = newRow.startDate ? FormatDateAPI(newRow.startDate) : null
  const endDateValue = newRow?.endDate ? FormatDateAPI(newRow.endDate) : null
  let payload = {
    sponsorId: newRow?.sponsorId,
    faName: newRow?.faName,
    faShortName: newRow?.faShortName,
    faEmail: newRow?.faEmail,
    startDate: startDateValue,
    endDate: endDateValue
  }

  payload = FormatPayload(payload)
  return CreateAPI(
    'baseSponserURL',
    `data-maintenance/v1/${userRole}/sponsors/financial-advisor`,
    payload)
}
