import React, { useContext } from 'react'
import { Box, Card, CardContent, Divider, Grid, Typography } from '@mui/material'
import taxIcon from '../../../../src/assets/images/tax-icon.png'
import taxGrowth from '../../../../src/assets/images/taxGrowth.png'
import { accountDataContext } from '../Index'
import { capitalized, formatCurrencyWithSymbol } from '../components/dataProcess/DataProcess'

export const TaxInformation = () => {
  const taxData = useContext(accountDataContext)
  return (
    <Grid xs={12} sm={12}>
      <Card elevation={6} sx={{ minWidth: '100%' }} className='report-card'>
        <CardContent sx={{ p: 0 }}>
          <Grid xs={12} sm={12}>
            <Box sx={{ px: 2, pt: 2, pb: 1 }}>
              <Typography variant='outlined' sx={{ fontSize: 16, color: '#34475A', fontFamily: 'Open Sans' }}>
                Tax Information
              </Typography>
            </Box>
          </Grid>
          <Divider
            sx={{
              bgcolor: 'rgba(116, 120, 141, 0.15)'
            }}
          />
          <Box ml={2} sx={{ color: '#34475A' }}>
            <Grid xs={12} sm={12} mt={2} container className='tax-state-container'>
              <Grid xs={4} sm={3}>
                <div className='image-container'>
                  <img src={taxIcon} alt='tax state' />
                </div>
              </Grid>
              <Grid xs={8} sm={9}>
                <Box>
                  <Typography className='reportCardSub'>
                    Tax State: {taxData?.stateName || 'N/A'}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid xs={12} sm={12} mt={2} container className='tax-state-container'>
              <Grid xs={4} sm={3}>
                <div className='image-container'>
                  <img src={taxGrowth} alt='tax growth' />
                </div>
              </Grid>
              <Grid xs={8} sm={9}>
                <Box>
                  <Typography className='reportCardSub'>
                    Tax Sensitivity: {taxData?.taxSensitivity ? capitalized(taxData?.taxSensitivity) : 'N/A'}
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            <Grid container mt={2}>
              <Grid xs={6}>
                <Typography sx={{ fontSize: 22, fontWeight: 400, fontFamily: 'Open Sans' }}>
                  {(taxData?.stateShortTax || 0) + '%'}
                </Typography>
                <Typography sx={{ fontSize: 14, fontFamily: 'Open Sans' }}>
                  Short term tax rate
                </Typography>
              </Grid>
              <Grid xs={6}>
                <Typography sx={{ fontSize: 22, fontWeight: 400, fontFamily: 'Open Sans' }}>
                  {(taxData?.stateLongTax || 0) + '%'}
                </Typography>
                <Typography sx={{ fontSize: 14, fontFamily: 'Open Sans' }}>
                  Long term tax rate
                </Typography>
              </Grid>
            </Grid>
            <Grid xs={12} sm={12} mt={2} style={{ color: '#34475A' }}>
              <Box>
                <Typography sx={{ fontSize: 22, fontWeight: 400, fontFamily: 'Open Sans' }}>
                  {formatCurrencyWithSymbol(taxData?.totalRealLongGains || 0, 2, '$')}
                </Typography>
                <Typography sx={{ fontSize: 14, fontFamily: 'Open Sans' }}>
                  External Realized Long Gain/Loss
                </Typography>
              </Box>
            </Grid>
            <Grid xs={12} sm={12} mt={2} style={{ color: '#34475A' }}>
              <Box>
                <Typography sx={{ fontSize: 22, fontWeight: 400, fontFamily: 'Open Sans' }}>
                  {formatCurrencyWithSymbol(taxData?.totalRealShortGains || 0, 2, '$')}
                </Typography>
                <Typography sx={{ fontSize: 14, fontFamily: 'Open Sans' }}>
                  External Realized Short Gain/Loss
                </Typography>
              </Box>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  )
}
