import Chart from 'react-apexcharts'
import { Skeleton, Typography } from '@mui/material'

const AllocationChart = ({ data = [], loading }) => {
  const options = {
    chart: {
      type: 'bar',
      stacked: true,
      stackType: '100%',
      toolbar: {
        show: false
      },
      sparkline: {
        enabled: true
      }
    },
    colors: ['#002A59', '#3369A6', '#3B79BF', '#6A86A6', '#6E9FC0', '#95CEEC', '#BCBEBE', '#9DDFD0', '#93DAC0', '#AEC7E2', '#B3D1F2', '#CFE5FF', '#E0E8EF', '#DBE9F9', '#C6F7FA'],
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    legend: {
      show: false,
    },
    xaxis: {
      show: false,
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
      }
    },
    yaxis: {
      show: false,
      axisBorder: {
        show: false
      }
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      x: {
        show: false,
      },
      y: {
        show: true,
        formatter: (data) => data.toFixed(2) + '%',
      },
    },
    grid: {
      show: false,
    },
  }

  return (
    <>
      <Typography component='h3' className='text-title'>Asset Allocation</Typography>
      {
        loading
          ? <Skeleton variant='rectangular' sx={{ height: '40px', mt: '10px' }} />
          : <Chart options={options} series={data} type='bar' height={60} />
      }
    </>
  )
}

export default AllocationChart