import { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box } from '@mui/material'
import ReactGridLayout from 'react-grid-layout'
import 'react-grid-layout/css/styles.css'
import { RiFileAddLine } from 'react-icons/ri'
import 'react-resizable/css/styles.css'
import { ReactComponent as DeleteSvg } from '../../../assets/images/pdf-customizer/svg/delete.svg'
import { changeDraggableElement } from '../../../store/pdf-customizer-reducer/Action/allPageAction'
import { changePageLayout, deletePageWithAllData, handleAddMidPage, isActiveHandle, itemDragging } from '../../../store/pdf-customizer-reducer/Action/chartDropAction'
import PDFHolder from '../droppableElements/PDFHolder/PDFHolder'
import { MAX_GRID_COUNT } from '../PDFLayout'
import NewElementsComponent from './NewElementComponent'

const PageComponent = ({ pageKey, page, pageIndex }) => {
  const { color, fontFamily, pageSize, padding, currentDraggable } = useSelector(
    (state) => state.allPageStyle
  )
  const isCoverPageOrPDFPage = Boolean(page?.elements?.find(element => element.coverPage || element.name === 'PDF Uploads'))
  const { pageHeadingFooter, activeElement } = useSelector((state) => state.elementStates)
  const headingSizeRemove = pageHeadingFooter?.headingElement ? pageHeadingFooter?.headingElement?.name === 'Page Heading 2' ? 33 : pageHeadingFooter?.headingElement?.name === 'Page Heading 1' ? 40 : 0 : 0
  const footerSizeRemove = pageHeadingFooter?.footerElement ? pageHeadingFooter?.footerElement ? (27 - padding) : 0 : 0
  const pageHeight = isCoverPageOrPDFPage ? pageSize?.height - padding * 2 : pageSize?.height - headingSizeRemove - footerSizeRemove - padding * 2
  const pageWidth = pageSize?.width - padding * 2
  const dispatch = useDispatch()
  const pageRef = useRef()
  const [showGrid, setShowGrid] = useState(false)

  // adds dragged element in template
  const addElement = (layout, item, e) => {
    const data = currentDraggable
    if (data) {
      // check if element is causing overflow
      if (item.h + item.y > MAX_GRID_COUNT) {
        dispatch(itemDragging({ element: data, position: item, pageIndex, isOverFlowing: true }))
      } else {
        dispatch(itemDragging({ element: data, position: item, pageIndex }))
      }
    }
    dispatch(changeDraggableElement(null))
  }
  return (
    <>
      <div
        key={pageIndex}
        className='padding-right-left-1 font-open-sans page-number-class'
        style={{
          zIndex: 98,
          width: `${pageSize?.width}px`
        }}
      >
        <div className='flexOrAlignOrBetWeen'>
          <div className='common-text-data'>{`Page ${pageIndex + 1}`}</div>
          <div className='flexOrAlign'>
            <div
              className='margin-x-1 cursor-pointer'
              onClick={() => dispatch(handleAddMidPage(pageIndex))}
            >
              <RiFileAddLine color='#727474' size={17} />
            </div>
            <div
              className='margin-x-1 cursor-pointer'
              onClick={() => dispatch(deletePageWithAllData(pageIndex))}
            >
              <DeleteSvg />
            </div>
          </div>
        </div>
      </div>
      <div
        className={'page container-' + pageKey}
        style={{
          width: `${pageSize?.width}px`,
          height: `${pageSize?.height}px`,
          padding: `${padding}px`,
          color,
          fontFamily,
          position: 'relative'
        }}
        onClick={(e) => {
          if ((e?.target?.className === e?.currentTarget?.className || e?.currentTarget === null || e?.target?.className === pageRef?.current?.className) && activeElement) {
            dispatch(isActiveHandle({ clearActiveElement: true }))
          }
        }}
      >
        {
          pageHeadingFooter?.headingElement && !page?.elements?.find(element => element.coverPage) && !page?.elements?.find(element => element.name === 'PDF Uploads')
            ? <NewElementsComponent
              elementData={pageHeadingFooter?.headingElement}
              elementId={pageHeadingFooter?.headingElement?.id}
              pageIndex={pageIndex}
            />
            : ''
        }
        <Box
          id='placeholder-grid'
          sx={{
          display: showGrid ? 'block' : 'none',
          position: 'absolute',
          top: padding + headingSizeRemove,
          left: padding,
          right: padding,
          bottom: padding + footerSizeRemove
        }}
        >
          {
            Array.from({ length: MAX_GRID_COUNT }, (_, index) => {
              return (
                <Box key={`row-${index}`} sx={{ display: 'grid', gridTemplateColumns: `repeat(${MAX_GRID_COUNT}, 1fr)` }}>
                  {
                  Array.from({ length: MAX_GRID_COUNT }, (_, index) => {
                    return (
                      <Box key={`column-${index}`} sx={{ height: pageHeight / MAX_GRID_COUNT, width: pageWidth / MAX_GRID_COUNT, display: 'inline-block', background: '#e0e0e0', opacity: 0.5, border: '1px solid white' }} />
                    )
                  })
               }
                </Box>
              )
            })
          }
        </Box>
        {/* add header footer */}
        <ReactGridLayout
          name='page'
          innerRef={pageRef}
          style={{
            width: `${pageWidth}px`,
            height: `${pageHeight}px`,
            color,
            fontFamily
          }}
          className={'pdf-customizer container-' + pageIndex}
          cols={MAX_GRID_COUNT}
          autoSize={false}
          rowHeight={pageHeight / MAX_GRID_COUNT}
          width={pageWidth}
          compactType={null} // free movement in container
          preventCollision // inorder to prevent resizing if it is resized over other elements
          containerPadding={[0, 0]}
          margin={[0, 0]}
          maxRows={MAX_GRID_COUNT} // prevents height overflow of element in resizing by
          isDroppable
          resizeHandles={['sw', 'nw', 'se', 'ne', 's', 'w', 'e', 'n']} // add resize arrow at all edge
          // resizeHandle={CustomResizeHandler}
          onResizeStart={(e) => setShowGrid(true)}
          onResizeStop={(e) => setShowGrid(false)}
          onDrop={addElement}
          onDropDragOver={() => {
            return { w: Number(currentDraggable?.width || '1'), h: Number(currentDraggable?.height || '1') }
          }}
          draggableHandle='.drag-handle'
          onLayoutChange={(layout) => {
            // react grid executes on layout change on every new component addition. which will have dropping-item as id.
            // react grid executes this on resizing or changing position of element within page. we need to store new x,y,w,h of the elements
            // filter the elements without dropping item id since this element is added twice
            if (layout.length === page?.elements?.length) {
              const newElementsList = layout.filter(element => element.i !== '__dropping-elem__').map(element => ({ ...page?.elements?.find(elm => elm.id === element.i), position: element }))
              dispatch(changePageLayout({ elements: newElementsList, pageIndex }))
            }
          }}
        >
          {
            page?.elements?.map(elementObj => {
              return elementObj.name === 'PDF Holder'
                ? (
                  <div data-grid={{ ...elementObj.position, static: true, isResizable: false, isDraggable: false }} key={elementObj.id} id={elementObj.id}>
                    <PDFHolder />
                  </div>
                )
                : (
                  <div style={{ background: showGrid ? 'white' : 'transparent' }} data-grid={{ ...elementObj.position, isResizable: activeElement === elementObj.id && !elementObj.coverPage && elementObj?.name !== 'PDF Uploads' }} key={elementObj.id} id={elementObj.id}>
                    <NewElementsComponent
                      elementData={elementObj}
                      elementPosition={elementObj.position}
                      pageIndex={pageIndex}
                      elementId={elementObj.id}
                    />
                  </div>
                )
            })
          }
        </ReactGridLayout>
        {
          pageHeadingFooter?.footerElement && !page?.elements?.find(element => element.coverPage) && !page?.elements?.find(element => element.name === 'PDF Uploads')
            ? <NewElementsComponent
              elementData={pageHeadingFooter?.footerElement}
              elementId={pageHeadingFooter?.footerElement?.id}
              pageIndex={pageIndex}
            />
            : ''
        }
      </div>
    </>
  )
}

export default PageComponent
