import React, { useEffect, useState } from 'react'
import { Formik } from 'formik'
import { Button, DialogContent, DialogTitle } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { FormikControl } from '../../../components/Formik/FormikControl'
import { FundingSource, TaxSensitivity, WithdrawalFrequency } from '../../../contstants/constants'
import { optimizationAPI } from '../Account/GetAccountData'
import { getRestrictedCodes } from '../Strategy/GetStrategyData'
import { GetTradeStatusMasterDataResponse } from '../Trade/GetTradeData'
import { getIdentifierListApi } from '../GetKeyData'

// List of fields with autocomplete dropdown
export const selectWithSearchFields = [
  { id: 'strategyId', value: 'strategyName', excludeTab: [] },
  { id: 'benchmarkId', value: 'longName', excludeTab: [] },
  { id: 'instrId', value: 'name', excludeTab: [] },
  { id: 'sourceInstrId', value: 'name', excludeTab: [], accessKey: 'instrId' }, // use accessKey when field id is not present in object response
  { id: 'targetInstrId', value: 'name', excludeTab: [], accessKey: 'instrId' },
  { id: 'accountId', value: 'accountName', excludeTab: [] },
  { id: 'sponsorId', value: 'sponsorName', excludeTab: [] },
  { id: 'scenarioId', value: 'scenarioCode', excludeTab: [] }
]

// List of fields with custom options except ['Yes','No']
export const selectFields = [
  { id: 'restrictionId', value: 'restrictionDesc', excludeTab: [] },
  { id: 'tradeStatusCode', value: 'tradeStatusCode', excludeTab: ['trade-status-master'] },
  { id: 'optStatusCode', value: 'optStatusCode', excludeTab: ['opt-status-master'] },
  { id: 'fundingSource', value: 'fundingSource', excludeTab: [] },
  { id: 'taxSensitivity', value: 'taxSensitivity', excludeTab: [] },
  { id: 'withdrawalFrequency', value: 'withdrawalFrequency', excludeTab: [] },
  { id: 'identifierType', value: 'identifierType', excludeTab: [] }
]

export const nonInsertableFields = ['insTs', 'insId', 'updId', 'updTs'] // prevent input creation for these fields for all module

const onSubmitCallMain = (values, savedData, data, setLoading) => {
  for (const column of data.tableStructure) {
    if (column.dataType === 'date' && values[column.columnName]) {
      values[column.columnName] = new Date(values[column.columnName])
    }
  }
  setLoading(true)
  savedData(values)
}
export const InputForm = ({ savedData, loading, setLoading, data, tabName, defaultFieldValue, excludeFields, moduleName }) => {
  const [initialValues, setInitialValues] = useState({})
  const [selectFieldOptions, setSelectFieldOptions] = useState({})
  useEffect(() => {
    const result = {}
    for (const key in data.fieldEditAllowed) {
      // check common non insertable fields and module specific exclude fields
      if (!nonInsertableFields.includes(key) && !excludeFields.includes(key)) {
        result[key] = ''
        // Fetch options for custom select fields
        // check for exclude tab before fetching options
        const singleSelectField = selectFields.find((field) => field.id === key)
        if (singleSelectField && !singleSelectField.excludeTab?.includes(tabName)) {
          getSelectFieldOptions(key)
        }
        if (key in defaultFieldValue) {
          result[key] = defaultFieldValue[key]
        }
      }
    }
    setInitialValues(result)
  }, [defaultFieldValue])

  const getSelectFieldOptions = async (fieldName) => {
    switch (fieldName) {
      case 'restrictionId': {
        const selectDataResponse = await getRestrictedCodes()
        if (selectDataResponse) {
          setSelectFieldOptions(prevState => ({ ...prevState, [fieldName]: selectDataResponse }))
        }
        break
      }
      case 'tradeStatusCode': {
        const selectTradeDataResponse = await GetTradeStatusMasterDataResponse('status-master')
        const tradeStatusCodes = selectTradeDataResponse.data.data.map(item => ({ tradeStatusCode: item.tradeStatusCode }))
        setSelectFieldOptions(prevState => ({
          ...prevState,
          [fieldName]: tradeStatusCodes
        }))
        break
      }
      case 'fundingSource':
        setSelectFieldOptions(prevState => ({
          ...prevState,
          [fieldName]: Object.keys(FundingSource).map(source => ({ fundingSource: source }))
        }))
        break
      case 'taxSensitivity':
        setSelectFieldOptions(prevState => ({
          ...prevState,
          [fieldName]: Object.keys(TaxSensitivity).map(sensitivity => ({ taxSensitivity: sensitivity }))
        }))
        break
      case 'withdrawalFrequency':
        setSelectFieldOptions(prevState => ({
          ...prevState,
          [fieldName]: Object.keys(WithdrawalFrequency).map(frequency => ({ withdrawalFrequency: frequency }))
        }))
        break
      case 'optStatusCode': {
        const selectDataResponse = await optimizationAPI()
        const optStatusCodes = selectDataResponse.data.data.map(item => ({ optStatusCode: item.optStatusCode }))
        setSelectFieldOptions(prevState => ({
          ...prevState,
          [fieldName]: optStatusCodes
        }))
        break
      }
      case 'identifierType' : {
        const identifierType = await getIdentifierListApi()
        setSelectFieldOptions(prevState => ({
          ...prevState,
          [fieldName]: identifierType?.data?.map(identifier => ({ identifierType: identifier.identifierType }))
        }))
        break
      }
      default:
        break
    }
  }

  if (!Object.keys(initialValues).length) {
    return <div>Loading...</div>
  }

  return (
    <>
      <DialogTitle>Add Data</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={initialValues}
          enableReinitialize={false}
          onSubmit={(values) => {
            onSubmitCallMain(values, savedData, data, setLoading)
          }}
        >
          {props => (
            <form onSubmit={props.handleSubmit}>
              {Object.keys(props?.values).map((key, index) => {
                const singleSelectField = selectFields.find(field => field.id === key)
                const selectWithAutocompleteField = selectWithSearchFields.find(field => field.id === key)
                let columnType = data?.tableStructure.find(column => column.columnName === key)?.dataType
                // check for exclude tab and display autocomplete and select field as default column type
                columnType = (selectWithAutocompleteField && !selectWithAutocompleteField.excludeTab.includes(tabName))
                  ? 'autocomplete'
                  : (singleSelectField && !singleSelectField.excludeTab.includes(tabName))
                      ? 'select'
                      : columnType
                return (
                  <FormikControl
                    key={index}
                    control={columnType}
                    name={key}
                    label={data?.fieldLabels[key]}
                    onHandleChange={props.handleChange}
                    fieldValues={props.values[key]}
                    disabled={key in defaultFieldValue}
                    moduleName={moduleName}
                    {...singleSelectField
                      ? {
                          options: selectFieldOptions[key] || [],
                          optionValueKey: singleSelectField.value
                        }
                      : {
                          ...selectWithAutocompleteField
                            ? {
                                optionKey: selectWithAutocompleteField.id,
                                optionValueKey: selectWithAutocompleteField.value,
                                accessKey: selectWithAutocompleteField.accessKey || selectWithAutocompleteField.id
                              }
                            : {}
                        }}
                  />
                )
              })}
              {
                loading
                  ? (
                    <LoadingButton
                      loading
                      loadingPosition='start'
                      variant='contained'
                      fullWidth
                      type='submit'
                      sx={{ mt: 2 }}
                    >
                      Submit
                    </LoadingButton>
                    )
                  : (
                    <Button color='primary' variant='contained' fullWidth type='submit' sx={{ mt: 2 }}>
                      Submit
                    </Button>
                    )
              }
            </form>
          )}
        </Formik>
      </DialogContent>
    </>
  )
}
