import { getUserRole } from '../../../utils/getUserRole'
import { FormatPayload } from '../components/FormatAPIRequest'
import { CreateAPI } from '../components/Actions'

export const CreateMasterData = async (newRow, tabIndex) => {
  switch (tabIndex) {
    case 'account-status-codes':
      return await CreateAccountStatusCode(newRow)
    case 'restriction-codes':
      return await CreateRestrictionCode(newRow)
    case 'sell-logic-codes':
      return await CreateSellLogicCode(newRow)
    case 'tax-states':
      return await CreateTaxCode(newRow)
    case 'property-codes':
      return await CreatePropertyCode(newRow)
    default:
      return {}
  }
}

const CreateAccountStatusCode = async (newRow) => {
  const userRole = getUserRole()
  let payload = {
    accountStatusCode: newRow.accountStatusCode?.toUpperCase(),
    accountStatusName: newRow.accountStatusName,
    accountStatusDesc: newRow.accountStatusDesc
  }

  payload = FormatPayload(payload)
  return CreateAPI(
    'baseUriMasterData',
    `data-maintenance/v1/${userRole}/account-status-codes`,
    payload)
}

const CreateRestrictionCode = async (newRow) => {
  const userRole = getUserRole()
  let payload = {
    restrictionCode: newRow.restrictionCode?.toUpperCase(),
    restrictionDesc: newRow.restrictionDesc
  }

  payload = FormatPayload(payload)
  return CreateAPI(
    'baseUriMasterData',
    `data-maintenance/v1/${userRole}/restriction-codes`,
    payload)
}

const CreateSellLogicCode = async (newRow) => {
  const userRole = getUserRole()
  let payload = {
    sellLogicCode: newRow.sellLogicCode?.toUpperCase(),
    sellLogicDesc: newRow.sellLogicDesc
  }

  payload = FormatPayload(payload)
  return CreateAPI(
    'baseUriMasterData',
    `data-maintenance/v1/${userRole}/sell-logic-codes`,
    payload)
}

const CreateTaxCode = async (newRow) => {
  const userRole = getUserRole()
  let payload = {
    stateName: newRow.stateName,
    stateAbbr: newRow.stateAbbr
  }

  payload = FormatPayload(payload)
  return CreateAPI(
    'baseUriMasterData',
    `data-maintenance/v1/${userRole}/tax-states`,
    payload)
}

const CreatePropertyCode = async (newRow) => {
  const userRole = getUserRole()
  let payload = {
    propertyCode: newRow.propertyCode?.toUpperCase(),
    propertyDesc: newRow.propertyDesc,
    dataType: newRow.dataType
  }

  payload = FormatPayload(payload)
  return CreateAPI(
    'baseUriMasterData',
    `data-maintenance/v1/${userRole}/property-codes`,
    payload)
}
