import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  changeBackground,
  editLabelChart,
  reorderLabels
} from '../../../../store/pdf-customizer-reducer/Action/chartDropAction'
import { RiDragMove2Line } from 'react-icons/ri'

import { rgbaToHex } from '../../droppableElements/APIResponseConverts/APIResponseConverts'

const SummaryEditTable = ({ activeChart }) => {
  const dispatch = useDispatch()
  const [draggedIndex, setDraggedIndex] = useState(null)
  const datasets = activeChart?.value?.datasets || []

  const handleDragStart = (index) => {
    setDraggedIndex(index)
  }

  const handleDrop = (e, index) => {
    e.preventDefault()
    if (draggedIndex !== null && draggedIndex !== index) {
      // Reorder labels
      const newLabelOrder = [...datasets]
      const [draggedItem] = newLabelOrder.splice(draggedIndex, 1)
      newLabelOrder.splice(index, 0, draggedItem)

      // Update Redux store with the new label order
      dispatch(reorderLabels(newLabelOrder))
    }

    setDraggedIndex(null)
  }

  const handleDragOver = (e, index) => {
    e.preventDefault()
    if (draggedIndex !== null && draggedIndex !== index) {
      // Add any visual indication based on your design
    }
  }

  const handleBackground = (e, chartIndex) => {
    const newColor = e.target.value
    dispatch(changeBackground({ value: newColor, index: chartIndex }))
  }
  const renderColorPickerOrName = (data, index) => (
    <div
      className='flexOrAlignStartOrBetWeen width-100 margin-y-1'
      draggable
      onDragStart={() => handleDragStart(index)}
      onTouchStart={() => handleDragStart(index)}
    >
      <div>
        <label className='cl-checkbox width-100'>
          <input
            type='checkbox'
            name={data.label || ''}
            checked={!data.hidden || false}
            onChange={(e) =>
              dispatch(
                editLabelChart({
                  label: data.label,
                  index,
                  checked: e.target.checked
                })
              )}
          />

          <div className='box-main'>
            <div />
          </div>
          <div className='checkbox-padding'>
            <span className='common-text-data'>{data.labelText || data.label}</span>
          </div>
        </label>
      </div>

      <div className='flexOrAlign'>
        <input
          type='color'
          id='color-input'
          className='color-picker-input'
          value={rgbaToHex(data.backgroundColor)}
          onChange={(e) => handleBackground(e, index)}
        />
        <RiDragMove2Line color='#727474' size={20} />
      </div>
    </div>
  )

  return (
    activeChart &&
    activeChart.value &&
    datasets.length > 0 && (
      <>
        <div>
          {datasets.map((data, index) => (
            <div
              key={index}
              onDrop={(e) => handleDrop(e, index)}
              onDragOver={(e) => handleDragOver(e, index)}
              onTouchMove={() => handleDragOver(index)}
              tabIndex={0}
            >
              {data.label !== 'noNameShow' && data?.label !== '' ? renderColorPickerOrName(data, index) : null}
            </div>
          ))}
        </div>
      </>
    )
  )
}

export default SummaryEditTable
