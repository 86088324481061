
import { Close } from '@mui/icons-material'
import { Box, IconButton, Modal, Typography } from '@mui/material'
import { randomId } from '@mui/x-data-grid-generator'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { getStatusColor } from './getStatusColor'

const ImportResultModal = ({ isImportResultModalOpen, handleModalClose, importResults }) => {

    // Merge success and failed arrays into a single array with added status field
    const successRows = Array.isArray(importResults?.success) ? importResults?.success?.map(item => ({ id: randomId(), ...item, status: 'success' })) : []
    const failedRows = Array.isArray(importResults?.failed) ? importResults?.failed?.map(item => ({ id: randomId(), ...item, status: 'failed' })) : []
    const rowsRes = [...successRows, ...failedRows]

    // Define the columns for the DataGridPro component
    const columns = [
        { field: 'accountId', headerName: 'Account ID', flex: 1 },
        { field: 'accountName', headerName: 'Account Name', flex: 1 },
        { field: 'accountCd', headerName: 'Account Code', flex: 1 },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            // Custom cell rendering to apply status-specific styling
            renderCell: (params) => {
                const style = getStatusColor(params?.value);
                return (
                    <Box style={style}>
                        {params.value}
                    </Box>
                )
            }
        },
        {
            field: 'errors',
            headerName: 'Error Message',
            flex: 1,
            // Custom cell rendering to display errors for failed imports
            renderCell: (params) => {
                if (params?.row?.status === 'failed' && params?.row?.errors) {
                    return (
                        <div style={{ padding: '0 1rem' }}>
                            <ul style={{ padding: 0, marginLeft: '1rem' }}>
                                {params?.row?.errors?.map((error, index) => (
                                    <li style={{ marginBottom: index < params?.row?.errors?.length - 1 ? '10px' : '0px' }}
                                        key={index}>{error}</li>
                                ))}
                            </ul>
                        </div>
                    )
                } else {
                    return ''
                }
            },
        }
    ]

    return (
        <Modal open={isImportResultModalOpen}>
            <Box
                sx={{
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100vh',
                    padding: '14px'
                }}
            >

                {/* Header section with title and close button */}
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant='h6' style={{ fontWeight: 400, color: '#34475A', padding: '8px' }}>Import Results</Typography>
                    {/* Close button */}
                    <IconButton
                        onClick={handleModalClose}
                        sx={{ display: 'flex', alignItems: 'end', justifyContent: 'end', marginLeft: 'auto' }}
                    >
                        <Close />
                    </IconButton>
                </Box>

                {/* DataGridPro to display import results */}
                <Box
                    sx={{
                        position: 'relative',
                        overflowY: 'auto',
                        scrollbarWidth: 'none',
                        height: 'calc(100vh - 64px - 16px - 16px)'
                    }}
                >
                    <DataGridPro
                        rows={rowsRes}
                        columns={columns}
                        density='compact'
                        getRowId={(row) => row.id}
                        autoHeight
                        getRowHeight={() => 'auto'}
                        pagination
                        pageSizeOptions={[20]}
                        initialState={{
                            pagination: { paginationModel: { pageSize: 20 } }
                        }}
                        sx={(theme) => ({
                            '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                                py: '8px'
                            }
                        })}
                    />
                </Box>
            </Box>
        </Modal>
    )
}

export default ImportResultModal
