import { Typography } from '@mui/material'

const CustomNoRowsOverlay = ({ message }) => {
  return (
    <Typography sx={{ textAlign: 'center', margin: '16px auto' }}>
      {message}
    </Typography>
  )
}

export default CustomNoRowsOverlay
