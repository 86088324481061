import React, { useContext, useEffect, useState } from 'react'
import { Divider } from '@mui/material'
import { accountDataContext, taxManagementContext, taxSavingDataContext } from '../../Index'
import { formatCurrency } from '../dataProcess/DataProcess'
import { PerformanceTable } from './PerformanceTable'

export const TaxAlphaDetails = () => {
  const [taxData, setTaxData] = useState({ column: [], row: [] })
  const accountData = useContext(accountDataContext)
  const taxManagementData = useContext(taxManagementContext)
  const taxsavingData = useContext(taxSavingDataContext)

  const performancePreTax = taxManagementData?.performanceData?.preTaxMV
  const performancePostTax = taxManagementData?.performanceData?.postTaxMV

  const taxManagementCol = [
    { name: 'Tax Management', align: 'left', fieldLabel: 'taxManagement', width: '45%'},
    { name: '1 Month (%)', align: 'right', fieldLabel: 'performanceData1M', width: '15%'},
    { name: '3 Month (%)', align: 'right', fieldLabel: 'performanceData3M', width: '15%'},
    { name: '', align: 'right', fieldLabel: 'blank', width: '25%'},
  ]

  const taxManagementRow = [
    { taxManagement: 'Without Tax Management', 
      performanceData1M: performancePreTax?.['1M'] ? (performancePreTax['1M'] * 100).toFixed(2) : '-',
      performanceData3M: performancePreTax?.['3M'] ? (performancePreTax['3M'] * 100).toFixed(2) : '-', blank: ''
    },
    { taxManagement: 'With Tax Management',
      performanceData1M: performancePostTax?.['1M'] ? (performancePostTax['1M'] * 100).toFixed(2) : '-',
      performanceData3M: performancePostTax?.['3M'] ? (performancePostTax['3M'] * 100).toFixed(2) : '-', blank: ''
    }
  ]

  const alphaTaxCol = [
    { name: 'Tax Savings', width: '45%', align: 'left', fieldLabel: 'taxSavings' },
    { name: 'Value ($)', width: '15%', type: 'number', align: 'right', fieldLabel: 'price' },
    { name: '', width: '40%', align: 'right', fieldLabel: 'blank' }
  ]

  const alphaTaxRows = [
    { taxSavings: 'YTD Tax Savings from Unrealized Gains', price: formatCurrency(taxsavingData?.taxSavedBasedOnOverWeightedSecurities || 0, 2), blank: '' },
    { taxSavings: 'YTD Tax Savings from Short-Term to Long-Term Events', price: formatCurrency(taxsavingData?.taxSavedBasedOnSortTermToLongTermConvertedHoldings || 0, 2), blank: '' },
    { taxSavings: 'YTD Tax Savings from Net Loss Harvesting', price: formatCurrency(taxsavingData?.taxSavedBasedOnYTDGainLossRecognized || 0, 2), blank: '' },
    { taxSavings: 'Total YTD Estimated Tax Savings', price: formatCurrency(taxsavingData?.totalTaxSaved || 0, 2), blank: '' }
  ]

  const estimateTaxCol = [
    { name: 'Estimated Tax', width: '45%', fieldLabel: 'estimatedTax', align: 'left' },
    { name: 'Value ($)', width: '15%', type: 'number', align: 'right', fieldLabel: 'price' },
    { name: '', width: '40%', align: 'right' }
  ]

  const estimateTaxRows = [
    { estimatedTax: 'Est. Tax Bill Without Tax Management', price: formatCurrency(taxsavingData?.ytdEstimatedTaxWithoutTaxSaving || 0, 2), blank: '' },
    { estimatedTax: 'Current Estimated Tax', price: formatCurrency(taxsavingData?.ytdEstimatedTax || 0, 2), blank: '' },
    { estimatedTax: 'Estimated Tax Savings (YTD)', price: formatCurrency(taxsavingData?.totalTaxSaved || 0, 2), blank: '' }
  ]

  const externalGainLossCol = [
    { name: 'Estimated Gain Loss', width: '45%', align: 'left', fieldLabel: 'estimatedGL' },
    { name: 'Value ($)', width: '15%', type: 'number', align: 'right', fieldLabel: 'price' },
    { name: '', width: '40%', fieldLabel: 'blank', align: 'right' }
  ]

  const externalGainLossRows = [
    { estimatedGL: 'YTD Short Term Gain Loss', price: formatCurrency(taxsavingData?.externalShortGainLoss || 0, 2), blank: '' },
    { estimatedGL: 'YTD Long Term Gain Loss', price: formatCurrency(taxsavingData?.externalLongGainLoss || 0, 2), blank: '' },
    { estimatedGL: 'Total YTD External Gain Loss', price: formatCurrency(taxsavingData?.totalExternalGainLoss || 0, 2), blank: '' }
  ]

  return (
    <>
      {accountData?.accountType === 'TRADING'
        ? <>
          <PerformanceTable tableHeader='' rows={taxManagementRow} columns={taxManagementCol} total />
          <Divider sx={{ bgcolor: 'rgba(116, 120, 141, 0.3)', marginTop: '22px', mx: -2 }} />
          <PerformanceTable tableHeader='YTD Estimated Tax Savings Details' rows={alphaTaxRows || []} columns={alphaTaxCol} />
          <Divider sx={{ bgcolor: 'rgba(116, 120, 141, 0.3)', marginTop: '22px', mx: -2 }} />
          <PerformanceTable tableHeader='YTD Estimated Tax Details' rows={estimateTaxRows || []} columns={estimateTaxCol} />
          <Divider sx={{ bgcolor: 'rgba(116, 120, 141, 0.3)', marginTop: '22px', mx: -2 }} />
          <PerformanceTable tableHeader='YTD External Gain Loss' rows={externalGainLossRows || []} columns={externalGainLossCol} />
        </>
        : ' '}
    </>
  )
}
