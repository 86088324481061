import React, { useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Chart as ChartJS, registerables } from 'chart.js'
import { InitialPortfolioChartOptions } from '../common/ChartOptions/options'
import { useDispatch, useSelector } from 'react-redux'
import { initialPortfolioBlockApi } from '../../../utils/pdf-customizer/_data'
import { useAuth } from '../../../contexts/AuthContext'
import { fetchDataAndDispatch, formatCurrencyWithSymbol } from './APIResponseConverts/APIResponseConverts'
import { initialPortfolioBlockData } from './APIResponseConverts/chartDefaultsData'
import './initialPortfolio.css'
ChartJS.register(...registerables)

const MyChartComponent = ({
  data,
  chartIndex,
  index,
  chartHeight,
  clickEvent
}) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const { isResponseElements, initialPortfolioBlockResponse } = useSelector(state => state.chartState)
  const templateData = useSelector(state => state.templateData)
  const [isDragging, setIsDragging] = useState(false)
  const { user } = useAuth()
  useEffect(() => {
    if (isResponseElements && !initialPortfolioBlockResponse && !isLoading) {
      setIsLoading(true)
      fetchDataAndDispatch(() => initialPortfolioBlockApi(templateData, user?.userGroup), 'INITIAL_PORTFOLIO_BLOCK_DATA', dispatch)
    }

    if (isResponseElements && initialPortfolioBlockResponse) {
      setIsLoading(false)
    }

  }, [dispatch, isResponseElements, initialPortfolioBlockResponse])

  const itemsResponse = initialPortfolioBlockResponse?.data || initialPortfolioBlockData.data.data
  const initPortMv = itemsResponse?.portfolioMarketValue
  const costBasis = itemsResponse?.costBasis
  const numHoldings = itemsResponse?.noOfHoldings
  const unrealizedGain = itemsResponse?.unrealizedGain
  const unrealizedLoss = itemsResponse?.unrealizedLoss
  const ltUrg = itemsResponse?.ltUrg
  const stUrg = itemsResponse?.stUrg
  const ltUrl = itemsResponse?.ltUrl
  const stUrl = itemsResponse?.stUrl
  const totalPercentPfValue = itemsResponse?.totalPercentOfPortfolioValue
  const liquidationTaxCost = itemsResponse?.fullLiquidationTaxCost

  const height = parseInt(chartHeight, 10) - 80

  const chartData = {
    labels: ['Long Term', 'Short Term'],
    datasets: [
      {
        axis: 'y',
        data: [171830, 15600],
        backgroundColor: ['#6A86A6', 'rgba(164, 164, 164, 0.5)'],
        barThickness: 12,
        categoryPercentage: 1,
        barPercentage: 1
      },
    ]
  }

  return (
    <div
      className={'group'}
      key={data?.chartIndex + data?.pageIndex}
    >
      {
        isLoading
        ? <span className='loading-text'>Loading...</span>
        : <>
            <div className='initial-portfolio-box'>
              <p className='portfolio-heading common-text-header'>
                {data.headingText || data.name}
              </p>
              <div className='portfolio-info-box'>
                <div className='chart-section'>
                  <p className='section-heading common-text-paragraph'>
                    Initial Portfolio Market Value
                  </p>
                  <p className='value-font common-text-header'>{initPortMv}</p>
                </div>
                <div className='chart-section'>
                  <p className='section-heading common-text-paragraph'>Cost Basis</p>
                  <p className='value-font common-text-header'>{costBasis}</p>
                </div>
                <div className='chart-section'>
                  <p className='section-heading common-text-paragraph'>
                    Number of Holdings
                  </p>
                  <p className='value-font common-text-header'>{numHoldings}</p>
                </div>
              </div>
              <div className='portfolio-info-box'>
                <div className='chart-section'>
                  <p className='section-heading common-text-paragraph'>
                    Unrealized Gain
                  </p>
                  <p className='value-font common-text-header'>{unrealizedGain}</p>
                </div>
                <div className='chart-section'>
                  <p className='section-heading common-text-paragraph'>Long Term</p>
                  <p className='value-font common-text-header'>{typeof ltUrg !== 'undefined' ? formatCurrencyWithSymbol(ltUrg, 1, '$') : 'NA'}</p>
                </div>
                <div className='chart-section'>
                  <p className='section-heading common-text-paragraph'>
                    Short Term
                  </p>
                  <p className='value-font common-text-header'>{typeof stUrg !== 'undefined' ? formatCurrencyWithSymbol(stUrg, 1, '$') : 'NA'}</p>
                </div>
              </div>
              <div className='portfolio-info-box'>
                <div className='chart-section'>
                  <p className='section-heading common-text-paragraph'>
                    Unrealized Loss
                  </p>
                  <p className='value-font common-text-header'>{unrealizedLoss}</p>
                </div>
                <div className='chart-section'>
                  <p className='section-heading common-text-paragraph'>Long Term</p>
                  <p className='value-font common-text-header'>{typeof ltUrl !== 'undefined' ? formatCurrencyWithSymbol(ltUrl, 1, '$') : 'NA'}</p>
                </div>
                <div className='chart-section'>
                  <p className='section-heading common-text-paragraph'>
                    Short Term
                  </p>
                  <p className='value-font common-text-header'>{typeof stUrl !== 'undefined' ? formatCurrencyWithSymbol(stUrl, 1, '$') : 'NA'}</p>
                </div>
              </div>
            </div>
            <div className='bottom-box'>
              <div className='bottom-value-box'>
                <p
                  className='value-font common-text-header'
                  style={{ margin: '0', color: '#FFFFFF' }}
                >
                  {liquidationTaxCost}
                </p>
                <p
                  className='value-font common-text-paragraph'
                  style={{ color: '#99A6BC' }}
                >
                  Full Liquidation Tax Cost
                </p>
              </div>
              <div className='percentage-box'>
                <p
                  className='value-font common-text-header'
                  style={{ margin: '0', color: '#34475A' }}
                >
                  {totalPercentPfValue}
                </p>
                <p
                  className='value-font common-text-paragraph'
                  style={{ color: '#202A34' }}
                >
                  Total % of Portfolio Value
                </p>
              </div>
            </div>
          </>
      }
    </div>
  )
}

export default MyChartComponent
