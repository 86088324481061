import React from 'react'
import { Box, Card, CardContent, Grid, Skeleton, Stack, Tooltip, Typography } from '@mui/material'
import { formatCurrency } from '../../../utils/FormateCurrenyInMilion'

const AccountInfo = ({ headers, values, loading }) => {
  return (
    <Card className='report-card'>
      <CardContent sx={{ height: '90px', display: 'flex', px: '16px', py: '0px !important' }}>
        <Grid className='report-card-border' container alignItems='center'>
          {
            loading ? (
              Array.from({ length: 4 }).map((_, i) => (
                <Grid key={i} xs={2} sm={3} item>
                  <Skeleton variant='text' sx={{ fontSize: '1rem', width: 20 }} />
                  <Skeleton variant='text' sx={{ fontSize: '1rem', width: 50 }} />
                </Grid>
              ))
            ) : (
              headers.map((field, index) => (
                <Grid key={index} xs={2} sm={3} item>
                  <Stack>
                    <Box mb={1}>
                      <Tooltip title={(values[index] && values[index].value !== null && values[index].value !== undefined) ? field === 'Tracking Error' ? (values[index].value * 100).toFixed(2) + '%' : values[index].value >= 0 ? '$' + values[index].value.toFixed(2) : '-$' + Math.abs(values[index].value.toFixed(2)) : ''}>
                        <Typography className='card-heading' sx={{ color: '#4B8CCA', display: 'inline-flex', alignItems: 'center' }}>
                          {
                            values[index] && values[index].value !== undefined ? field === 'Tracking Error' ? (values[index].value * 100).toFixed(2) + '%' : values[index].value >= 0 ? '$' + formatCurrency(values[index].value) : '-$' + formatCurrency(values[index].value) : '-'
                          }
                        </Typography>
                      </Tooltip>
                      {
                        values[index] && values[index].percentChange
                          ? <Box sx={{ color: values[index].percentChange > 0 ? '#33A68D' : '#FF6A6A', fontSize: '14px', margin: '2px 0 0 5px', alignItems: 'center', display: 'inline-flex' }}>
                            ({values[index].percentChange > 0 && field !== 'Tax Cost' ? '+' : ''}{parseFloat(values[index].percentChange.toFixed(2))}%)
                          </Box>
                          : ''
                      }
                    </Box>
                    <Typography variant='subtitle1' className='card-subheading'>
                      {field}
                    </Typography>
                  </Stack>
                </Grid>
              ))
            )
          }
        </Grid>
      </CardContent>
    </Card>
  )
}

export default AccountInfo
