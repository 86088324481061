import React from 'react'
import { TableBody, TableCell, TableHead, TableRow, Table, Skeleton, Box } from '@mui/material'

export const ACLLoadingTable = ({ headers }) => {
  return (
    <>
      <Box className='table-responsive acl-loader'>
        <Table>
          <TableHead>
            <TableRow>
              {headers?.map((item, index) => {
                return (
                  <TableCell key={index}>{item.headerName}</TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.from({ length: headers?.length }).map((_, index) => (
              <TableRow key={index}>
                {headers?.map((header, index) => (
                  <TableCell key={index} className={header?.headerName === 'Action' ? 'action-loader' : ''}>
                    {header?.headerName !== 'Action'
                      ? (
                        <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
                        )
                      : (
                        <Skeleton variant='circular' width={20} height={20} />
                        )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </>
  )
}
