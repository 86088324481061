import { useState } from 'react'
import { Box, IconButton, Modal, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser'
import ViewPolicyBreakCheckModal from '../ViewPolicyBreakCheckModal'
import StyledTooltip from './StyledTooltip'
import { tradeModalStyle } from './tradeModalStyle'

const CustomPolicyBreakCell = (params) => {
  const [editIndex, setEditIndex] = useState()
  const [isPBCMShown, setIsPBCMShown] = useState(false)

  const viewHandlePolicyBreakModalShow = (index, polDesc) => {
    const polParameter = [index, polDesc]
    setEditIndex(polParameter)
    setIsPBCMShown(true)
  }

  return (
    <>
      <StyledTooltip title='Policy Check'>
        <IconButton
          size='small'
          className={params.row?.failedScenario ? 'disabled-action-btn action-clock-btn' : 'action-clock-btn'}
          onClick={() => {
            !params.row?.failedScenario && viewHandlePolicyBreakModalShow(
              params.row?.accOptMapId,
              params.row?.scenarioDesc
            )
          }}
        >
          <VerifiedUserIcon
            color='primary'
            fontSize='small'
          />
        </IconButton>
      </StyledTooltip>

      <Modal
        open={isPBCMShown}
        onClose={() => setIsPBCMShown(false)}
      >
        <Box sx={tradeModalStyle}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography style={{ fontSize: '22px', fontWeight: 400, fontFamily: 'Open Sans' }}>Policy Check</Typography>
            <IconButton aria-label='close' onClick={() => setIsPBCMShown(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
          <ViewPolicyBreakCheckModal
            index={editIndex}
          />
        </Box>

      </Modal>
    </>
  )
}

export default CustomPolicyBreakCell
