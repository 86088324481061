import { Link, useParams } from 'react-router-dom'
import { Card, CardContent, Table, TableBody, TableCell, TableContainer, TableRow, Typography, IconButton, Box } from '@mui/material'
import redirect from '../../../assets/images/pop-out-line.svg'

export const RealativeVolatility = ({ riskAttributes }) => {
  const params = useParams()

  const riskAttri = {}
  if (riskAttributes) {
    riskAttributes.forEach((obj) => {
      riskAttri[obj.statsCode] = obj.statsValue
    })
  }

  return (
    <>
      <Card sx={{ height: '100%' }}>
        <CardContent>
          <Typography className='text-title header-text' sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <span>Portfolio Risk</span>
            <Link to={`/account-review/risk-dashboard/${params.accountId}`} className='linkId' style={{ marginLeft: '8px' }}>
              <Box sx={{ display: 'flex', alignItems: 'end', justifyContent: 'end' }}>
                <IconButton
                  className='tooltip-trade'
                  sx={{ marginTop: '-3px' }}
                >
                  <img src={redirect} alt='' />
                  <span
                    className='tooltiptext'
                  >
                    Portfolio Risk
                  </span>
                </IconButton>
              </Box>
            </Link>
          </Typography>
          <TableContainer mt={5}>
            <Table className='risk-page-table'>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ fontSize: '14px', color: '#74788d', fontWeight: '600' }}>Relative Volatility v/s Target Model</TableCell>
                  <TableCell>
                    {riskAttri.PORT_REL_VOL === undefined
                      ? (
                          'N/A'
                        )
                      : (
                        <>{Number(riskAttri.PORT_REL_VOL).toFixed(2)}</>
                        )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontSize: '14px', color: '#74788d', fontWeight: '600' }}>Tracking Error (%)</TableCell>
                  <TableCell>
                    {riskAttri.PORT_TRACKING_ERROR === undefined
                      ? (
                          'N/A'
                        )
                      : (
                        <>
                          {(Number(riskAttri.PORT_TRACKING_ERROR) * 100)
                            .toFixed(2)
                            .toString() + '%'}
                        </>
                        )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontSize: '14px', color: '#74788d', fontWeight: '600' }}>Market Beta w.r.t US Total EQ Market</TableCell>
                  <TableCell>
                    {riskAttri.PORT_BETA === undefined
                      ? (
                          'N/A'
                        )
                      : (
                        <>{Number(riskAttri.PORT_BETA).toFixed(2)}</>
                        )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </>
  )
}
