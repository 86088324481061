import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  headingChangeText,
  hadingFontSize
} from '../../../../store/pdf-customizer-reducer/Action/chartDropAction'
import ChartLabel from './ChartLabel'

const EditHeading = ({ activeChart }) => {
  const dispatch = useDispatch()
  const [editModes, setEditModes] = useState({
    textShow: false,
    heading1: false,
    heading2: false,
    definition1: false,
    definition2: false,
    sectionTitle: false,
    sectionSubTitle: false,
    sectionUserName: false
  })

  const handleToggleEditMode = (label) => {
    setEditModes((prevEditModes) => ({
      ...prevEditModes,
      [label]: !prevEditModes[label]
    }))
  }

  const handleToggleSaveMode = (value, label) => {
    setEditModes((prevEditModes) => ({
      ...prevEditModes,
      [label]: !prevEditModes[label]
    }))
    dispatch(headingChangeText({ name: label, value }))
  }

  const renderChartLabels = (labels) => {
    return labels.map((label) => (
      <ChartLabel
        key={label}
        value={activeChart[label] || (activeChart?.value && activeChart?.value[label])}
        isEditing={editModes[label]}
        onSave={(value) => handleToggleSaveMode(value, label)}
        onEdit={() => handleToggleEditMode(label)}
        onCancel={() => handleToggleEditMode(label)}
      />
    ))
  }

  const isHeadingChart = activeChart && activeChart.value
  const fontSizes = Array.from({ length: 42 - 10 + 1 }, (_, index) => `${10 + index}px`)
  const alignOptions = ['left', 'center', 'right']
  const fontWeightOptions = [
    '100',
    '200',
    '300',
    '400',
    '500',
    '600',
    '700',
    '800',
    '900',
    'bold',
    'bolder',
    'lighter',
    'normal',
    'inherit',
    'revert',
    'revert-layer',
    'unset'
  ]

  const handleChangeHading = (e) => {
    if (activeChart) {
      dispatch(
        hadingFontSize({
          name: e.target.name,
          value: e.target.value
        })
      )
    }
  }

  if (!isHeadingChart && activeChart?.name !== 'Section Page') {
    return null
  }
  return (
    <>
      {activeChart?.name === 'Definitions'
        ? renderChartLabels([
          'heading1',
          'definition1',
          'heading2',
          'definition2'
        ])
        : activeChart?.name === 'Section Page'
          ? renderChartLabels([
            'sectionUserName',
            'sectionTitle',
            'sectionSubTitle'
          ])
          : ''}

      {activeChart?.name === 'Title Text' && (
        <>
          <div className='flexOrAlignOrBetWeen'>
            <label className='margin-top-3 text_heading_lable'>
              Font Size
            </label>
            <select
              name='fontSize'
              className='form-select form-select-sm font-select-box margin-top-3'
              aria-label='Default select example'
              onChange={handleChangeHading}
              value={activeChart?.value?.fontSize || '10px'}
            >
              {fontSizes.map((size) => (
                <option
                  key={size}
                  value={size}
                >
                  {parseInt(size)}
                </option>
              ))}
            </select>
          </div>

          <div className='flexOrAlignOrBetWeen'>
            <label className='margin-top-3 text_heading_lable'>
              Text Align
            </label>
            <select
              name='justifyContent'
              className='form-select form-select-sm font-select-box margin-top-3'
              aria-label='Default select example'
              onChange={handleChangeHading}
              value={activeChart?.value?.justifyContent || 'left'}
            >
              {alignOptions.map((align) => (
                <option
                  key={align}
                  value={align}
                >
                  {align.charAt(0).toUpperCase() + align.slice(1)}
                </option>
              ))}
            </select>
          </div>

          <div className='flexOrAlignOrBetWeen'>
            <label className='margin-top-3 text_heading_lable'>
              Font Weight
            </label>
            <select
              name='fontWeight'
              className='form-select form-select-sm font-select-box margin-top-3'
              aria-label='Default select example'
              onChange={handleChangeHading}
              value={activeChart?.value?.fontWeight || '700'}
            >
              {fontWeightOptions.map((weight) => (
                <option
                  key={weight}
                  value={weight}
                >
                  {weight.charAt(0).toUpperCase() + weight.slice(1)}
                </option>
              ))}
            </select>
          </div>
        </>
      )}
    </>
  )
}

export default EditHeading
