import { Fragment } from 'react'
import { Skeleton } from '@mui/material'
export const SelectListOfAccountLoader = (props) => {
  return (
    [...Array(4)].map((e, i) => (
      <Fragment key={i}>
        {props.colNum === 12
          ? <tr>
            <td>
              <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
            </td>
            <td>
              <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
            </td>
            <td>
              <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
            </td>
            <td>
              <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
            </td>
            <td>
              <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
            </td>
            <td>
              <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
            </td>
            <td>
              <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
            </td>
            <td>
              <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
            </td>
            <td>
              <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
            </td>
            <td>
              <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
            </td>
            <td>
              <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
            </td>
            <td>
              <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
            </td>
          </tr>
          : <tr>
            <td>
              <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
            </td>
            <td>
              <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
            </td>
            <td>
              <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
            </td>
            <td>
              <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
            </td>
            <td>
              <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
            </td>
            <td>
              <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
            </td>
            <td>
              <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
            </td>
            <td>
              <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
            </td>
            <td>
              <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
            </td>
            </tr>}
      </Fragment>
    ))
  )
}
