export const customTheme = {
  typography: {
    fontFamily: 'Open Sans'
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          border: '1px solid rgba(116, 120, 141, 0.3)',
          boxShadow: '0px 7px 29px 0px rgba(100, 100, 111, 0.2)',
          borderRadius: '10px'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          fontFamily: 'Open Sans',
          backgroundColor: '#3A76D4',
          borderRadius: '10px',
          '&:hover': {
            backgroundColor: '#275cb1'
          }
        },
        outlined: {
          fontFamily: 'Open Sans',
          borderRadius: '10px'
        },
        text: {
          fontFamily: 'Open Sans',
          borderRadius: '10px'
        }
      }
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: 'none'
        },
        columnHeaderTitle: {
          fontFamily: 'Open Sans',
          color: '#74788d !important',
          fontSize: '14px',
          fontWeight: 600
        },
        cellContent: {
          fontFamily: 'Open Sans',
          color: '#34475a',
          fontSize: '14px',
          fontWeight: '600'
        },
        cell: {
          fontFamily: 'Open Sans',
          color: '#34475a',
          fontSize: '14px',
          fontWeight: '600'
        },
        editInputCell: {
          fontFamily: 'Open Sans',
          color: '#34475a',
          fontSize: '14px',
          fontWeight: '600'
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: 'Open Sans',
          color: '#34475a',
          fontSize: '14px',
          fontWeight: '600'
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontFamily: 'Open Sans',
          backgroundColor: 'rgba(23, 92, 194, 0.8)',
          fontSize: '12px',
          color: 'white',
          fontWeight: '400'
        }
      }
    }
  }
}
