import React from 'react'
import ChartLabelEditor from '../ChartLabelEditor'
import { ReactComponent as EditIcon } from '../../../../assets/images/pdf-customizer/svg/Edit.svg'

const ChartLabel = ({ value, isEditing, onSave, onCancel, onEdit }) => {
  return (
    <div className='margin-y-2 flexOrAlignStartOrBetWeen'>
      {isEditing
        ? (
          <ChartLabelEditor
            headingText={value}
            onSave={onSave}
            textarea
            onCancel={onCancel}
          />
          )
        : (
          <>
            <label className='common-text-data line-clamp-overflow' title={value}>
              {value}
            </label>
            <div className='showInRow'>
              <div className='edit-button margin-x-1' onClick={onEdit}>
                <EditIcon />
              </div>
            </div>
          </>
          )}
    </div>
  )
}

export default ChartLabel
