import { getUserRole } from '../../../utils/getUserRole'
import { DeleteAPI } from '../components/Actions'

export const DeleteRequestData = async (deleteRow, tabIndex) => {
  switch (tabIndex) {
    case 'type-code':
      return await DeleteRequestTypeCode(deleteRow.typeCd)
    case 'status-code':
      return await DeleteRequestStatusCode(deleteRow.statusCd)
    default:
      return {}
  }
}

const DeleteRequestTypeCode = async (typeCd) => {
  const userRole = getUserRole()
  return DeleteAPI(
    'baseRequestMaintainUrl',
    `data-maintenance/v1/${userRole}/requests/type-code/${typeCd}`
  )
}

const DeleteRequestStatusCode = async (statusCd) => {
  const userRole = getUserRole()
  return DeleteAPI(
    'baseRequestMaintainUrl',
    `data-maintenance/v1/${userRole}/requests/status-code/${statusCd}`
  )
}
