export const getColorProperties = (data) => {
    if (data === 'TRADE_COMPLETED') {
        return {
            color: '#446b60',
            backgroundColor: '#3BBFA399',
            border: '1px solid #446b6022'
        }
    } else if (data === 'TRADE_IN_PROGRESS') {
        return {
            color: '#aaab29',
            backgroundColor: '#fbfa6c99',
            border: '1px solid #aaab2922'
        }
    } else if (data === 'TRADE_FAILED') {
        return {
            color: '#79454f',
            backgroundColor: '#ff616199',
            border: '1px solid #79454f22'
        }
    } else if (data === 'TRADE_STOPPED') {
        return {
            color: '#969696',
            backgroundColor: '#C0C0C099',
            border: '1px solid #96969622'
        }
    } else if (data === 'TRADE_PARTIAL_FILLED') {
        return {
            color: '#816f36',
            backgroundColor: '#d2992299',
            border: '1px solid #816f3622'
        }
    }
}