import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { API } from 'aws-amplify'
import { GridRowModes } from '@mui/x-data-grid-pro'
import * as Sentry from '@sentry/react'
import { Divider, Stack, Box } from '@mui/material'
import { useAuth } from '../../contexts/AuthContext'
import { useErrorToast } from '../../hooks/useErrorToast'
import DataTable from './components/DataTable'
import { CustomEditConstraintField, CustomConstraintField } from './components/CustomConstraintField'
import CustomTooltipIcon from './components/CustomTooltipIcon'
import Loader from '../Loader'

const OtherConstraints = ({ data, objectiveData, getUpdatedData, getUpdatedObjectiveData, allScenarioList }) => {
  const params = useParams()
  const [otherConstraintData, setOtherConstraintsData] = useState(data?.data || [])
  const [objectiveFunctionData, setObjectiveFunctionData] = useState(objectiveData?.data || [])

  const dataSecure = localStorage.getItem('object')
  const getStoreData = JSON.parse(dataSecure)
  const filterSecureData = getStoreData?.filter(
    (item) => item?.accountId === params?.accountId
  ) || []

  const filterScenarios = () => {
    const account = filterSecureData[0]
    let filteredScenarios = []
    if (allScenarioList?.length > 0) {
      if (account?.isTaxable && (account?.strTypeCode === 'SIMPLE' || account?.strTypeCode === 'DERIVED')) {
        // For taxable accounts, filter scenarios with group ID 1
        filteredScenarios = allScenarioList?.filter(scenario => scenario?.scenarioGroupId === 1)
      } else {
        // For non-taxable accounts and account with managed strategy, filter scenarios with group ID 2
        filteredScenarios = allScenarioList?.filter(scenario => scenario.scenarioGroupId === 2)
      }
    }
    return { filteredScenarios }
  }

  const { filteredScenarios } = filterScenarios()

  const [rowModesModel, setRowModesModel] = useState({})

  const { showError } = useErrorToast()

  const { user } = useAuth()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (objectiveData) {
      setObjectiveFunctionData(objectiveData?.data || [])
      setIsLoading(false)
    }
    if (data) {
      setOtherConstraintsData(data?.data || [])
    }
  }, [data, objectiveData])

  const handleEditConstraints = (constraintData, selectedData, res, getUpdatedDataFunction) => {
    setIsLoading(true)

    API.post(
      'baseUriAccountOptimization', `account-customization/v1/${user?.userGroup}/accounts/cash-and-other-constraints/${params.accountId}`,
      {
        body: [
          {
            propertyCode: constraintData.propertyCode,
            propertyValue: constraintData.propertyValue
          }
        ]
      }
    )
      .then((response) => {
        if (response.success) {
          const newConstraintData = res?.map((row) => {
            if (row.id === constraintData.id) {
              return { id: constraintData.id, ...row, ...response.data[0] }
            }
            return row
          })
          selectedData(newConstraintData)
          getUpdatedDataFunction(newConstraintData)
        }
      })
      .catch((error) => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const editOtherConstraints = (constraintData) => {
    handleEditConstraints(constraintData, setOtherConstraintsData, otherConstraintData, getUpdatedData)
  }

  const editObjectiveData = (constraintData) => {
    handleEditConstraints(constraintData, setObjectiveFunctionData, objectiveFunctionData, getUpdatedObjectiveData)
  }

  const handleDeleteConstraint = (id, selectedData, res, getUpdatedDataFunction) => {
    setIsLoading(true)
    API.del('baseUriAccountOptimization', `account-customization/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/accounts/cash-and-other-constraints/${params.accountId}`, {
      body: {
        accountPropertiesIds: [id]
      }
    })
      .then((response) => {
        if (response.success) {
          const newConstraintData = res?.map((row) => {
            if (row.accountPropertiesId === id) {
              return { ...row, accountPropertiesId: null, propertyValue: null }
            }
            return row
          })
          selectedData(newConstraintData)
          getUpdatedDataFunction(newConstraintData)
        }
      })
      .catch((error) => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const deleteOtherConstraints = (id) => {
    handleDeleteConstraint(id, setOtherConstraintsData, otherConstraintData, getUpdatedData)
  }

  const deleteObjectiveData = (id) => {
    handleDeleteConstraint(id, setObjectiveFunctionData, objectiveFunctionData, getUpdatedObjectiveData)
  }

  const customValidations = (row) => {
    return (row.propertyValue === null || row.propertyValue === '' || row.error)
  }

  const columns = [
    {
      field: 'propertyDesc',
      headerName: 'Name',
      flex: 1,
      editable: data.fieldEditAllowed?.propertyDesc,
      maxWidth: 300,
      renderCell: (params) => (
        <>
          {params.value}
          <CustomTooltipIcon
            text={
              (() => {
                switch (params.row.propertyCode) {
                  case 'MIN_BUY_SIZE':
                    return 'Value must be greater than 0'
                  case 'MIN_BUY_WEIGHT':
                    return 'Value must be between 0 to 100'
                  case 'MAX_BETA':
                    return 'Value must be between -2 to 2'
                  case 'MIN_BETA':
                    return 'Value must be between -2 to 2'
                  default:
                    return params.row.propertyLongText
                }
              })()
            } action='info'
          />
        </>
      )
    },
    {
      field: 'propertyValue',
      headerName: 'Value',
      flex: 1,
      sortable: false,
      maxWidth: 300,
      editable: data?.fieldEditAllowed?.propertyValue,
      renderCell: (params) => (
        <CustomConstraintField
          params={params} rowModesModel={rowModesModel} filteredScenarios={filteredScenarios}
        />
      ),
      renderEditCell: (params) => (
        <CustomEditConstraintField
          {...params} disabled={params.row.propertyType !== 'OTHER_CONSTRAINT' ?? rowModesModel[params.row.id]?.mode !== GridRowModes.Edit} filteredScenarios={filteredScenarios}
        />
      )
    }
  ]

  return (
    <>
      {isLoading ? <Loader /> : ''}
      <Stack>
        <DataTable data={otherConstraintData} initialColumns={columns} showPagination={false} deleteKey='accountPropertiesId' editData={editOtherConstraints} deleteData={deleteOtherConstraints} setRowModes={setRowModesModel} customValidations={customValidations} />
        <Divider sx={{ bgcolor: 'rgba(116, 120, 141, 0.3)', marginTop: '5px', mx: -2 }} />
        <Box className='trade-table-list-header' mb={0}>Objective Function Priorities</Box>
        <DataTable data={objectiveFunctionData} initialColumns={columns} showPagination={false} deleteKey='accountPropertiesId' editData={editObjectiveData} deleteData={deleteObjectiveData} setRowModes={setRowModesModel} customValidations={customValidations} />
      </Stack>
    </>
  )
}

export default OtherConstraints
