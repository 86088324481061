import React, { useContext } from 'react'
import { Box, Grid } from '@mui/material'
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Bar } from 'react-chartjs-2'
import ChartLabel from './ChartLabel'
import { summaryDataContext } from '../Index'
import { useAuth } from '../../../contexts/AuthContext'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
)

export function AllocationChart (props) {
  const summaryData = useContext(summaryDataContext)
  const { user } = useAuth()
  const showOptReport = (user?.userGroup === 'adv-classic' || user?.userGroup === 'spn-pm') ? summaryData?.data?.showOptReport : true
  return (
    <>
      <Grid xs={12} sm={12}>
        <Box className='trade-table-list-header'>{props.chartHeader}</Box>
        <ChartLabel label={props.labels} backgroundColor={showOptReport === false && props.backgroundColor?.length ? [props.backgroundColor[0], '#34C38F'] : props.backgroundColor} />
        <Bar options={props.options} data={showOptReport === false && props.data && props.data?.datasets?.length ? { ...props.data, datasets: [props.data?.datasets[0], { ...props.data?.datasets[1], backgroundColor: '#34C38F' }] } : props.data} width={100} height={50} />
      </Grid>
    </>
  )
}
