import { Skeleton } from '@mui/material'
import '../../pages/AccountReview/import-portfolio.scss'
import '../../pages/AccountOnboarding/AccountOnboarding.scss'
const StrategyPlaceholder = () => {
  return (
    <table>
      <thead>
        <tr>
          <th> Name</th>
          <th> Symbol</th>
          <th> Weight %</th>
          <th> ISO Country Code</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
          </td>
          <td>
            <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
          </td>
          <td>
            <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
          </td>
          <td>
            <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
          </td>
          <td>
            <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
          </td>
          <td>
            <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
          </td>
          <td>
            <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
          </td>
          <td>
            <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
          </td>
          <td>
            <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
          </td>
          <td>
            <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
          </td>
          <td>
            <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
          </td>
          <td>
            <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
          </td>
          <td>
            <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export default StrategyPlaceholder
