import React from 'react'
import { Box, Fade, Modal } from '@mui/material'
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: 500,
  maxHeight: 700,
  height: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '4px'
}

const ActionModal = ({ openModal, handleCloseModal, children, width = 500 }) => {
  return (
    <Modal
      open={openModal}
      onClose={handleCloseModal}
      closeAfterTransition
    >
      <Fade in={openModal}>
        <Box sx={style} width={width}>
          {children}
        </Box>
      </Fade>
    </Modal>
  )
}

export default ActionModal
