import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  sponsorCd: null,
  sponsorName: null,
  primaryColor: null,
  secondaryColor: null,
  brandTextColor: null,
  mainLightLogoUrl: null,
  mainDarkLogoUrl: null,
  mobLightLogoUrl: null,
  mobDarkLogoUrl: null,
  favIconUrl: null,
  opacity: 0.5

}

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setThemeSettings (state, action) {
      return {
        ...state,
        sponsorCd: action?.payload?.sponsorCd,
        sponsorName: action?.payload?.sponsorName,
        primaryColor: action?.payload?.primaryColor,
        secondaryColor: action?.payload?.secondaryColor,
        brandTextColor: action?.payload?.brandTextColor,
        mainLightLogoUrl: action?.payload?.mainLightLogoUrl,
        mainDarkLogoUrl: action?.payload?.mainDarkLogoUrl,
        mobLightLogoUrl: action?.payload?.mobLightLogoUrl,
        mobDarkLogoUrl: action?.payload?.mobDarkLogoUrl,
        favIconUrl: action?.payload?.favIconUrl,
        opacity: action?.payload?.opacity
      }
    },
    resetThemeSettings (state) {
      return initialState
    }
  }
})

export const { setThemeSettings, resetThemeSettings } = themeSlice.actions
export default themeSlice.reducer
