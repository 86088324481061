import AnnotationCheckboxList from './AnnotationCheckboxList'
import BenchmarkCustomization from './BenchmakrCheckboxList'

const CustomizationTab = ({ chartName, chartData, applyBenchmark, applySelectedAnnotations, isTransitionAccount }) => {
  if (chartName === 'BENCHMARK_RETURNS') {
    return <BenchmarkCustomization chartData={chartData} applyBenchmark={applyBenchmark} isTransitionAccount={isTransitionAccount} />
  } else if (chartName === 'MV') {
    return <AnnotationCheckboxList chartData={chartData} applySelectedAnnotations={applySelectedAnnotations} />
  }
  return <></>
}

export default CustomizationTab
