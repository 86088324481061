import React, { useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import { API } from 'aws-amplify'
import { Grid, Typography } from '@mui/material'
import { randomId } from '@mui/x-data-grid-generator'
import { useAuth } from '../../contexts/AuthContext'
import { useErrorToast } from '../../hooks/useErrorToast'
import PermissionTable from './PermissionTable'
import PmUserTable from './PmUserTable'
import UserTable from './UserTable'
import SponsorAccountsTable from './SponsorAccountsTable'
import './acl.css'

export const capitalizeName = (name) => {
  name = name?.toLowerCase()
  const words = name?.split(' ')
  for (let i = 0; i < words?.length; i++) {
    words[i] = words[i]?.charAt(0)?.toUpperCase() + words[i]?.slice(1)
  }
  return words?.join(' ')
}

const SponsorTable = () => {
  const { user } = useAuth()
  const { showError } = useErrorToast()
  const [showAdminPanel, setShowAdminPanel] = useState(user?.userGroup === 'adv-classic' ? false : true)
  const [showEditTab, setShowEditTab] = useState(false)
  const [rows, setRows] = useState([])
  const [selectedRow, setSelectedRow] = useState([])
  const [PMUserRows, setPMUserRows] = useState([])
  const [type, setType] = useState('')
  const [activeTab, setActiveTab] = useState('')
  const [userRows, setUserRows] = useState([])
  const [roleRows, setRoleRows] = useState([])
  const [loading, setLoading] = useState(true)
  const [PMUserLoading, setPMUserLoading] = useState(true)
  const [updatedRole, setUpdatedRole] = useState('')
  const [spanLoading, setSpnLoading] = useState(user?.userGroup === 'adv-classic' ? true : false)
  const [spnParams, setSpnParams] = useState({})
  const [storedUserPageState, setStoreUserPageState] = useState({ page: 0, pageSize: 10 })
  const [storedRolePageState, setStoredRolePageState] = useState({ page: 0, pageSize: 10 })
  const [storedSponsorPageState, setStoredSponsorPageState] = useState({ page: 0, pageSize: 10 })
  const [storedSponsorUserPageState, setStoredSponsorUserPageState] = useState({ page: 0, pageSize: 10 })

  useEffect(() => {
    if (user && ((Object.keys(selectedRow).length > 0 && selectedRow?.sponsorName) || updatedRole !== '')) {
      getSponsorDetails()
    }
  }, [user, selectedRow, updatedRole])

  useEffect(() => {
    if (user) {
      fetchSponsorData()
      if (user?.userGroup === 'admin') {
        fetchPmUsersData()
      }
      if (user?.userGroup === 'adv-classic') {
        setSpnLoading(true)
        setPMUserLoading(false)
        fetchCurrentSponsor()
        setActiveTab('')
        setShowAdminPanel(false)
      }
    }
  }, [user])

  const fetchCurrentSponsor = () => {
    API.get('baseSponserURL', `data-maintenance/v1/${user?.userGroup}/sponsorIds`)
      .then(response => {
        if (user?.userGroup === 'adv-classic' && response?.data?.data?.length) {
          setSelectedRow(response?.data?.data[0])
        }
      })
      .catch(error => {
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
        setSpnLoading(false)
      })
  }

  const fetchSponsorData = () => {
    setLoading(true)
    API.get('baseAclURL', `user-access-control/v1/${user?.userGroup}/sponsors`)
      .then((res) => {
        if (res && res?.data) {
          setRows(res.data)
        }
      }).catch((error) => {
        setLoading(false)
        showError(error?.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error?.response?.data?.errorInfo?.userMessage || error)
      }).finally(() => {
        setLoading(false)
      })
  }

  const fetchPmUsersData = () => {
    API.get('baseAclURL', `user-access-control/v1/${user?.userGroup}/user/pm`)
      .then((res) => {
        if (res && res?.data) {
          setPMUserRows(res.data)
        }
      })
      .catch((error) => {
        setPMUserLoading(false)
        showError(error?.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error?.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => {
        setPMUserLoading(false)
      })
  }

  const getSponsorDetails = () => {
    setSpnLoading(true)
    API.get('baseAclURL', `user-access-control/v1/${user?.userGroup}/sponsor/${selectedRow?.sponsorId || spnParams?.sponsorId}`)
      .then((resData) => {
        if (resData && resData.data) {
          setUserRows(
            resData?.data?.users?.map((data) => {
              return data
            }) || []
          )
          setRoleRows(
            resData?.data?.roles?.map((data) => {
              return { ...data, id: randomId() }
            }) || []
          )
        }
      }).catch((error) => {
        setSpnLoading(false)
        showError(error?.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error?.response?.data?.errorInfo?.userMessage || error)
      }).finally(() => {
        setSpnLoading(false)
      })
  }

  return (
    <>
      {showAdminPanel || (activeTab === 'spn-tab') || (activeTab === 'aris-user-tab')
        ? (
          <>
            <Typography
              component='h3' className='page-title' sx={{ marginBottom: '10px', marginLeft: '5px' }}
            >
              Sponsor Access Controller
            </Typography>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid item xs={12}>
                <SponsorAccountsTable
                  storedSponsorPageState={storedSponsorPageState}
                  loading={loading}
                  rows={rows}
                  setRows={setRows}
                  spnParams={spnParams}
                  setSpnParams={setSpnParams}
                  setShowAdminPanel={setShowAdminPanel}
                  setStoredSponsorPageState={setStoredSponsorPageState}
                  setActiveTab={setActiveTab}
                  setType={setType}
                  setShowEditTab={setShowEditTab}
                  setSelectedRow={setSelectedRow}
                />
              </Grid>
              <Typography
                component='h3' className='page-title' sx={{ marginTop: '35px', marginBottom: '3px', marginLeft: '28px' }}
              >
                ARIS User Access Controller
              </Typography>
              <Grid item xs={12}>
                <PmUserTable
                  setShowAdminPanel={setShowAdminPanel}
                  setActiveTab={setActiveTab}
                  type={type}
                  setSelectedRow={setSelectedRow}
                  setType={setType}
                  setShowEditTab={setShowEditTab}
                  storedUserPageState={storedUserPageState}
                  setStoreUserPageState={setStoreUserPageState}
                  PMUserRows={PMUserRows}
                  PMUserLoading={PMUserLoading}
                  selectedRow={selectedRow}
                  setPMUserRows={setPMUserRows}
                />
              </Grid>
            </Grid>
          </>
        )
        : (
          showEditTab
            ? (
              <PermissionTable
                type={type}
                setActiveTab={setActiveTab}
                setType={setType}
                roleRows={roleRows}
                selectedRow={selectedRow}
              />
            )
            : (
              <UserTable
                selectedRow={selectedRow}
                activeTab={activeTab}
                setUserRows={setUserRows}
                setRoleRows={setRoleRows}
                setUpdatedRole={setUpdatedRole}
                updatedRole={updatedRole}
                userRows={userRows}
                roleRows={roleRows}
                storedRolePageState={storedRolePageState}
                setStoredRolePageState={setStoredRolePageState}
                storedSponsorUserPageState={storedSponsorUserPageState}
                setStoredSponsorUserPageState={setStoredSponsorUserPageState}
                type={type}
                setType={setType}
                spanLoading={spanLoading}
                setActiveTab={setActiveTab}
              />
            )
        )}
    </>
  )
}

export default SponsorTable