import { Tab, createTheme, styled } from '@mui/material'

const CustomTab = styled(Tab)(({ theme, selected }) => ({
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  textTransform: 'capitalize',
  fontSize: '16px',
  color: '#34475A',
  fontWeight: 400,
  '&.Mui-selected': {
    fontWeight: 400,
    color: '#34475A'
  }
}))
const Tabtheme = createTheme({
  components: {
    '& .MuiTab-root': {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '16px',
      color: 'red'
    },
    '& .Mui-selected': {
      color: '#34475A'
    }
  },
  tabList: {
    backgroundColor: 'yellow',
    borderRadius: 16,
    display: 'flex',
    justifyContent: 'space-between',
    '& .Mui-selected': {
      backgroundColor: '#FFFFFF',
      color: '#1A73E8',
      fontWeight: 'bold',
      border: 'none'
    },
    '& .MuiTab-root': {
      minWidth: 0,
      textTransform: 'none',
      fontSize: 14,
      fontWeight: 'normal',
      borderRadius: 16,
      border: 'none',
      color: '#5F6368',
      '&:hover': {
        backgroundColor: '#E8F0FE'
      }
    }
  }
})

const CustomSponsorTabTheme = createTheme({
  typography: {
    fontFamily: 'Open Sans'
  },
  overrides: {
    MuiCardHeader: {
      title: {
        fontSize: '18px',
        fontWeight: 400,
        textAlign: 'center'
      }
    },
    MuiCardContent: {
      root: {
        '& p': {
          fontSize: '17.7075px',
          lineHeight: '23px',
          color: '#848484'
        }
      },
      MuiBox: {
        root: {
          borderRadius: 10
        }
      }
    }
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontFamily: 'Open Sans',
          backgroundColor: 'rgba(23, 92, 194, 0.8)',
          color: 'white',
          fontSize: '12px',
          fontWeight: '400'
        }
      }
    }
  }
})

export { CustomTab, Tabtheme, CustomSponsorTabTheme }
