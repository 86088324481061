// Function to get the styling properties for a given status.
export const getStatusColor = (status) => {

    // Define common styles that will be applied to both 'success' and 'failed' statuses.
    const commonStyles = {
        borderRadius: '20px',
        fontSize: '14px',
        fontWeight: 600,
        padding: '2px 10px',
        textTransform: 'uppercase'
    }

    // Define specific styles for 'success' status and 'failed' status
    if (status === 'success') {
        return {
            ...commonStyles,
            color: '#446b60',
            backgroundColor: '#3BBFA399',
            border: '1px solid #446b6022'
        }
    } else if (status === 'failed') {
        return {
            ...commonStyles,
            color: '#79454f',
            backgroundColor: '#ff616199',
            border: '1px solid #79454f22'
        }
    }
    return {}
}