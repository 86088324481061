import React from 'react'
import { Box, Chip, Typography } from '@mui/material'
import { DataGridPro } from '@mui/x-data-grid-pro'

const TableData = ({ chartName, tradeDetails }) => {
  const buyTrades = tradeDetails?.allBuyTrades || []
  const sellTrades = tradeDetails?.allSellTrades || []
  const tradeDate = tradeDetails?.date || ''
  const formattedDate = tradeDate ? new Date(tradeDate).toISOString().split('T')[0] : ''
  const height = sellTrades?.length > 0 && buyTrades?.length > 0 ? '40vh' : '85vh'

  const buyRows = buyTrades?.map((trade, index) => ({
    id: index,
    localSymbol: trade?.localSymbol,
    tradedShares: trade?.tradedShares
  }))

  const sellRows = sellTrades?.map((trade, index) => ({
    id: index,
    localSymbol: trade?.localSymbol,
    tradedShares: trade?.tradedShares
  }))

  const columns = [
    { field: 'localSymbol', headerName: 'Local Symbol', flex: 1 },
    { field: 'tradedShares', headerName: 'Traded Shares', flex: 1, align: 'right', headerAlign: 'right' }
  ]

  const buyRowsWithColor = buyRows?.map((row) => ({ ...row, tradedSharesColor: '#33A68D' }))
  const sellRowsWithColor = sellRows?.map((row) => ({ ...row, tradedSharesColor: '#d32f2f' }))

  const renderCell = (params) => {
    const { value } = params
    const color = params.row.tradedSharesColor || 'inherit'
    return <div style={{ color }}>{value}</div>
  }

  return (
    <>
      {chartName === 'MV'
        ? <Box sx={{ maxHeight: '900px', overflowY: 'auto', height: '100%' }}>
          <Box sx={{ display: 'flex', justifyContent: 'end', marginBottom: '2px' }}>
            <Chip
              label={formattedDate}
              variant='outlined'
              sx={{
                position: 'absolute',
                borderRadius: '5px',
                fontSize: '11px',
                height: 'auto',
                background: '#3A76D426',
                border: 0,
                '.MuiChip-label': {
                  padding: '3px'
                }
              }}
            />
          </Box>
          {buyTrades?.length > 0
            ? <>
              <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Buy Trades</Typography>
              <Box style={{ height, width: '100%', position: 'relative' }}>
                <DataGridPro
                  density='compact'
                  rows={buyRowsWithColor}
                  columns={columns.map((column) => ({
                    ...column,
                    renderCell: column.field === 'tradedShares' ? renderCell : undefined
                  }))}
                  headerHeight={48}
                />
              </Box>
              </>

            : ''}
          {sellTrades?.length > 0
            ? <>
              <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Sell Trades</Typography>
              <Box style={{ height, width: '100%', position: 'relative' }}>
                <DataGridPro
                  density='compact'
                  rows={sellRowsWithColor}
                  columns={columns.map((column) => ({
                    ...column,
                    renderCell: column.field === 'tradedShares' ? renderCell : undefined
                  }))}
                  headerHeight={48}
                />
              </Box>
              </>

            : ''}
        </Box>

        : ''}
    </>
  )
}
export default TableData
