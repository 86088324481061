import { Card, CardContent } from '@mui/material'

export const CardBodyRisk = ({ children }) => {
  return (
    <>
      <Card sx={{height: '100%'}}>
        <CardContent>
          {children}
        </CardContent>
      </Card>
    </>
  )
}
