import React, { useEffect, useState } from 'react'
import { InputLabel, MenuItem, Select } from '@mui/material'

export const FormikSelect = (props) => {
  const { label, name, onHandleChange, fieldValues, options, optionValueKey } = props
  const [selectedOptions, setSelectedOptions] = useState(options ? [...options] : [])

  useEffect(() => {
    setSelectedOptions(options || [])
  }, [options])

  return (
    <>
      <InputLabel id='label-id' sx={{ mt: 2 }}>{label}</InputLabel>
      <Select
        labelId='label-id'
        margin='dense'
        size='small'
        id={name}
        name={name}
        value={fieldValues}
        fullWidth
        onChange={onHandleChange}
      >
        {
          (selectedOptions && selectedOptions.length)
            ? (selectedOptions.map((option, index) => (<MenuItem value={option[name]} key={index}>{option[optionValueKey]}</MenuItem>)))
            : ([
              <MenuItem value='-1' key='-1'>
                Select
              </MenuItem>,
              <MenuItem value={1} key={1}>
                Yes
              </MenuItem>,
              <MenuItem value={0} key={0}>
                No
              </MenuItem>
              ])
    }
      </Select>
    </>
  )
}
