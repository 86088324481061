export const arrowHorizontal = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4 26" fill="none">
    <path
      d="M2.17678 0.823223C2.07915 0.725592 1.92085 0.725592 1.82322 0.823223L0.232233 2.41421C0.134602 2.51184 0.134602 2.67014 0.232233 2.76777C0.329864 2.8654 0.488155 2.8654 0.585786 2.76777L2 1.35355L3.41421 2.76777C3.51184 2.8654 3.67014 2.8654 3.76777 2.76777C3.8654 2.67014 3.8654 2.51184 3.76777 2.41421L2.17678 0.823223ZM1.75 1L1.75 8.33333H2.25L2.25 1H1.75Z"
      fill="#727474"
    />
    <path
      d="M1.82322 25.1768C1.92085 25.2744 2.07915 25.2744 2.17678 25.1768L3.76777 23.5858C3.8654 23.4882 3.8654 23.3299 3.76777 23.2322C3.67014 23.1346 3.51184 23.1346 3.41421 23.2322L2 24.6464L0.585786 23.2322C0.488155 23.1346 0.329864 23.1346 0.232233 23.2322C0.134602 23.3299 0.134602 23.4882 0.232233 23.5858L1.82322 25.1768ZM1.75 18L1.75 25H2.25L2.25 18H1.75Z"
      fill="#727474"
    />
  </svg>
)
export const arrowVertical = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="4"
    viewBox="0 0 27 4"
    fill="none"
  >
    <path
      d="M0.823223 1.82322C0.725592 1.92085 0.725592 2.07915 0.823223 2.17678L2.41421 3.76777C2.51184 3.8654 2.67014 3.8654 2.76777 3.76777C2.8654 3.67014 2.8654 3.51184 2.76777 3.41421L1.35355 2L2.76777 0.585786C2.8654 0.488155 2.8654 0.329864 2.76777 0.232233C2.67014 0.134602 2.51184 0.134602 2.41421 0.232233L0.823223 1.82322ZM1 2.25L7 2.25V1.75L1 1.75L1 2.25Z"
      fill="#727474"
    />
    <path
      d="M26.1768 2.17678C26.2744 2.07915 26.2744 1.92085 26.1768 1.82322L24.5858 0.232233C24.4882 0.134602 24.3299 0.134602 24.2322 0.232233C24.1346 0.329864 24.1346 0.488155 24.2322 0.585786L25.6464 2L24.2322 3.41421C24.1346 3.51184 24.1346 3.67014 24.2322 3.76777C24.3299 3.8654 24.4882 3.8654 24.5858 3.76777L26.1768 2.17678ZM20 2.25L26 2.25V1.75L20 1.75V2.25Z"
      fill="#727474"
    />
  </svg>
)
