import { Box, Grid, Paper, Typography } from '@mui/material'

const StatsWidget = ({ title, stats, imgSrc, position, tooltipValue, subStats }) => {
  return (
    <Paper
      sx={{
        background: 'rgba(23, 92, 194, 0.08)',
        boxShadow: '0px 4px 4px rgba(116, 120, 141, 0.5)',
        borderRadius: '10px',
        paddingY: '18px',
        paddingX: position === 'Dashboard' ? '20px' : '30px',
        height: '100%',
        '@media (max-width:600px)': {
          padding: '15px'
        }
      }}
    >
      <Grid container wrap='nowrap' alignItems='center'>
        <Grid item xs zeroMinWidth order={position === 'Dashboard' ? 2 : 1}>
          <Typography component='p' className='text-title'>{title}</Typography>
          <Box className='tooltip-statWidget'>
            <Typography
              component='h1' sx={{
                fontWeight: 700,
                fontSize: position === 'Dashboard' ? '20px' : '26px',
                lineHeight: position === 'Dashboard' ? '24px' : '34px',
                color: '#34475A',
                textOverflow: 'ellipsis',
                maxWidth: '21ch',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                '@media (max-width:600px)': {
                  fontSize: position === 'Dashboard' ? '17px' : '24px',
                  lineHeight: position === 'Dashboard' ? '24px' : '28px'
                }
              }}
            >
              {stats}
              {subStats && (
                <span style={{ fontSize: '16px' }}>{subStats}</span>
              )}
            </Typography>
            {(position === 'Dashboard' || tooltipValue) && <span className='toolTipText'>{tooltipValue || stats}</span>}
          </Box>
        </Grid>
        <Grid
          order={position === 'Dashboard' ? 1 : 2}
          item sx={{
            width: position === 'Dashboard' ? '50px' : '30px',
            height: position === 'Dashboard' ? '50px' : '30px',
            background: position === 'Dashboard' ? '#34475A26' : 'transparent',
            borderRadius: position === 'Dashboard' ? '25px' : 0,
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: position === 'Dashboard' ? 1 : 0,
            '@media (max-width:600px)': {
              display: 'none'
            }
          }}
        >
          <img src={imgSrc} alt='' width='100%' height='100%' style={{ maxWidth: '30px', maxHeight: '30px' }} />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default StatsWidget
