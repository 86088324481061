import { FormatPayload } from '../../components/FormatAPIRequest'
import { UpdateAPI } from '../../components/Actions'
import { getUserRole } from '../../../../utils/getUserRole'

const UpdateDirectIndexingData = async (updatedRow, tabIndex) => {
  switch (tabIndex) {
    case 'type-code':
      return await UpdateStrategyTypeCode(updatedRow)
    default:
      return {}
  }
}

const UpdateStrategyTypeCode = async (updatedRow) => {
  const userRole = getUserRole()
  let payload = {
    strTypeCode: updatedRow?.strTypeCode?.toUpperCase(),
    desc: updatedRow?.desc
  }

  payload = FormatPayload(payload)
  return UpdateAPI(
    'baseStrategyMaintainURL',
    `data-maintenance/v1/${userRole}/strategies/type-codes/${updatedRow?.strTypeId}`,
    payload
  )
}

export { UpdateDirectIndexingData }
