import { useSelector } from 'react-redux'
import './pageFooter3.css'

const PageFooter3 = ({
  data,
  chartIndex,
  pageIndex,
  pagesCount,
  chartHeight,
  chartWidth,
  clickEvent
}) => {
  const { pages } = useSelector(state => state.elementStates)

  return (
    <div>
      <div
        className='footer_3'
        style={{
          padding: '6px 0.9rem',
          textAlign: 'right',
          fontSize: '7px',
          color: '#000'
        }}
      >
        Page  {pageIndex + 1} of {pages?.length}
      </div>
    </div>
  )
}

export default PageFooter3
