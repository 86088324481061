import { useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import dayjs from 'dayjs'
import * as Sentry from '@sentry/react'
import {
  Avatar,
  Box,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  MenuItem,
  Select,
  Skeleton,
  Typography
} from '@mui/material'
import { TbArrowsSort } from 'react-icons/tb'
import CloseIcon from '@mui/icons-material/Close'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import TextAreaEditor from '../../../components/TextAreaEditor'
import sendIcon from '../../../assets/images/sendIcon.svg'
import { useAuth } from '../../../contexts/AuthContext'
import { useErrorToast } from '../../../hooks/useErrorToast'
import Loader from '../../Loader'

const LoadingSkeletonViewReq = () => {
  return (
    <Box className='skel'>
      <Skeleton height={60} animation='wave' className='py-3' />
      <Skeleton height={60} animation='wave' className='py-3' />
      <Skeleton height={60} animation='wave' className='py-3' />
      <Skeleton height={60} animation='wave' className='py-3' />
      <Skeleton height={60} animation='wave' className='py-3' />
    </Box>
  )
}

export const ViewTradeComments = ({ accountId, accountName, onClose }) => {
  const { user } = useAuth()
  const { showError } = useErrorToast()
  const [commentSortValue, setCommentSortValue] = useState('DESC')
  const [commentValue, setCommentValue] = useState('')
  const [commentRequests, setCommentRequests] = useState([])
  const [loading, setLoading] = useState(true)
  const [sendCommentLoading, setSendCommentLoading] = useState(false)
  const [replyToComment, setReplyToComment] = useState(null)
  // const [replyToReply, setReplyToReply] = useState(null)

  // const commentRequests = [
  //   {
  //     id: 1,
  //     commenterName: 'Alice',
  //     insTs: '2024-11-14T12:00:00Z',
  //     comment: 'This is the main comment by Alice. I am adding some more text to make it longer.',
  //     // replies: [
  //     //   {
  //     //     commenterName: 'Bob',
  //     //     insTs: '2024-11-14T13:00:00Z',
  //     //     comment: 'This is a reply to Alice by Bob. I agree with the comment!'
  //     //   },
  //     //   {
  //     //     commenterName: 'Charlie',
  //     //     insTs: '2024-11-14T14:00:00Z',
  //     //     comment: 'Another reply to Alice by Charlie. Thanks for sharing this Alice!'
  //     //   }
  //     // ]
  //   },
  //   {
  //     id: 2,
  //     commenterName: 'Dave',
  //     insTs: '2024-11-14T12:30:00Z',
  //     comment: 'This is the main comment by Dave. I have a question about your idea, Alice.',
  //     parentCommentId: null
  //   },
  //   {
  //     id: 3,
  //     commenterName: 'ABC',
  //     insTs: '2024-11-14T12:30:00Z',
  //     comment: 'comment 1',
  //     parentCommentId: 2
  //   }
  // ]

  const handleReplyClick = (e, commenterName, commentId) => {
    setReplyToComment(commentId)
    setCommentValue('') // Reset the comment value when starting a reply
  }

  // Handle the delete icon click (to hide the editor and show the reply button)
  const handleDeleteClick = (commentId) => {
    if (replyToComment === commentId) {
      setReplyToComment(null) // Hide the editor
    }
  }

  const sortCommentsByTime = (order) => {
    setCommentRequests(commentRequests.sort((a, b) => order === 'ASC' ? new Date(a.insTs) - new Date(b.insTs) : new Date(b.insTs) - new Date(a.insTs)))
  }

  const getCommentRequests = () => {
    setLoading(true)
    API.get('baseOptimizationURL', `optimization/v1/${user.userGroup}/account-comments/${accountId}`)
      .then(response => {
        if (response?.data) {
          setCommentRequests(response.data)
        }
      })
      .catch(error => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error)
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    if (user) {
      getCommentRequests()
    }
  }, [user])

  const handleInputChange = (event) => {
    const sanitizedComment = event.target.value.replace(/(<([^>]+)>)/gi, '')
    setCommentValue(sanitizedComment)
  }

  const handlePostComment = (event) => {
    event.preventDefault()
    const trimmedComment = commentValue.trim()
    if (trimmedComment?.length > 0) {
      setSendCommentLoading(true)
      API.post(
        'baseOptimizationURL',
        `optimization/v1/${user.userGroup}/account-comments/${accountId}`,
        { body: { comment: trimmedComment } }
      )
        .then((response) => {
          if (response?.data?.length) {
            const newComment = response.data[0]
            setCommentRequests((prevComments) => [newComment, ...prevComments])
          }
          setCommentValue('')
        })
        .catch((error) => {
          showError(error.response?.data?.errorInfo?.userMessage || error.message)
          Sentry.captureException(error)
        })
        .finally(() => setSendCommentLoading(false))
    } else {
      showError('Comment must contain at least one character.')
    }
  }

  if (loading) {
    return <LoadingSkeletonViewReq />
  }

  const SendPostIcon = () => (<img
    src={sendIcon}
    alt='send'
    onClick={handlePostComment}
    style={{
      width: '28px',
      background: '#F0F0F0',
      padding: '6px',
      cursor: 'pointer',
      margin: '4px',
      borderRadius: '5px'
    }}
  />)

  return (
    <>
      {sendCommentLoading ? <Loader /> : ''}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant='h6' style={{ width: '100%', fontWeight: 400, fontFamily: 'Open Sans', color: '#34475A' }}>
          {accountName}
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <FormLabel sx={{ fontSize: '16px', fontWeight: 600, color: '#74788D' }}>
          Comments ({commentRequests?.length})
        </FormLabel>
        {
          commentRequests?.length ? (
            <Select
              value={commentSortValue}
              onChange={(e) => {
                setCommentSortValue(e.target.value)
                sortCommentsByTime(e.target.value)
              }}
              startAdornment={<TbArrowsSort style={{ color: 'rgba(103, 103, 103)', height: '24px', width: '24px', marginRight: '5px' }} />}
              IconComponent={(props) => <KeyboardArrowDownIcon fontSize='small' />}
              prop
              sx={{
                '.MuiOutlinedInput-notchedOutline': {
                  border: 'none'
                },
                '.MuiSelect-select': {
                  p: '4px 0 4px 0 !important'
                }
              }}
              size='small'
            >
              <MenuItem value={'DESC'}>
                Most Recent
              </MenuItem>
              <MenuItem value={'ASC'}>
                Most Oldest
              </MenuItem>
            </Select>
          ) : ''
        }
      </Box>

      {commentRequests?.length
        ? <Box
            className='account-request'
            sx={{
              marginTop: '6px',
              marginBottom: '14px',
              width: '100% !important',
              marginLeft: '0px !important',
              maxHeight: 'calc(100vh - 32px - 32px - 16px - 16px - 40px - 32px - 150px - 6px - 14px)',
              overflow: 'auto'
          }}>
          {(commentRequests || []).map((item, index) => (
            <Box sx={{ p: 1, pt: index === 0 ? 1 : 0 }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Avatar sx={{ width: '24px', height: '24px', fontSize: '14px', backgroundColor: '#3bbfa3' }}>{item?.commenterName?.[0]?.toUpperCase()}</Avatar>
                <Typography
                  sx={{
                    fontFamily: 'Open Sans',
                    fontSize: '14px',
                    fontWeight: 600,
                    paddingLeft: '8px',
                    display: 'inline-block',
                    color: '#34475a !important',
                    marginRight: '8px'
                  }}
                >
                  {item?.commenterName}
                </Typography>
                <Typography className='time' sx={{ fontSize: '12px !important' }}>
                  {dayjs(item?.insTs).format('YYYY-MM-DD HH:mm')}
                </Typography>
              </Box>
              <Box className='trade-comment-area'>
                <div className='comment-text' dangerouslySetInnerHTML={{ __html: item.comment || '' }}></div>
              </Box>
              {index !== commentRequests?.length - 1 && (
                <Divider sx={{ background: '#CACACA', marginTop: '8px' }} />
              )}
            </Box>
          ))}
        </Box>
        : (
          <Box sx={{ borderRadius: '10px', mt: '4px', mb: 1, py: '4px', border: '1px solid #74788d4d' }}>
            <Typography sx={{ fontFamily: 'Open Sans', fontSize: '14px !important', p: 1 }}>No comments available.</Typography>
          </Box>
        )
      }
      {/* {replyToComment === item.id && (
                        <Grid container sx={{ paddingLeft: '28px', marginTop: '8px' }}>
                          <Grid item sx={{ width: '100%' }}>
                            <Box className='strategy-message-area'>
                              <EditorProvider>
                                <Editor
                                  value={commentValue}
                                  placeholder='Write a Comment here'
                                  containerProps={{
                                    style: { minHeight: '100px', height: 'auto', overflowY: 'auto', maxWidth: '100%' }
                                  }}
                                  onChange={(e) => setCommentValue(e.target.value)}
                                  onPaste={(e) => {
                                    e.preventDefault()
                                    const clipboardData = e.clipboardData || window.clipboardData
                                    const pastedData = clipboardData.getData('text/html') || clipboardData.getData('text/plain')
                                    const cleanHtml = sanitize(pastedData, {
                                      allowedTags: ['b', 'i', 'u', 'br', 'ul', 'ol', 'li'],
                                      exclusiveFilter: (frame) => !frame.text?.trim()
                                    })
                                    setCommentValue((prev) => prev + cleanHtml)
                                  }}
                                >
                                  <Toolbar style={{ position: 'sticky', top: 0, backgroundColor: 'white', borderBottom: 'none' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                                      <BtnBold />
                                      <BtnItalic />
                                      <BtnUnderline />
                                      <BtnNumberedList />
                                      <BtnBulletList />
                                      <BtnClearFormatting />
                                    </div>

                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                      <DeleteIcon
                                        onClick={() => handleDeleteClick(item.id)}
                                        style={{
                                          cursor: 'pointer',
                                          fontSize: '18px',
                                          marginRight: '8px',
                                          color: '#74788D'
                                        }}
                                      />
                                      <img
                                        className='sendIcon'
                                        src={sendIcon}
                                        alt='send'
                                        onClick={handlePostComment}
                                        style={{
                                          width: '28px',
                                          background: '#F0F0F0',
                                          padding: '6px',
                                          cursor: 'pointer',
                                          margin: '4px',
                                          borderRadius: '5px'
                                        }}
                                      />
                                    </div>
                                  </Toolbar>
                                </Editor>
                              </EditorProvider>
                            </Box>
                          </Grid>
                        </Grid>
                      )} */}

      {/* {replyToComment !== item.id && (
                        <Grid container sx={{ paddingLeft: '28px', marginTop: '4px' }}>
                          <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                            <ReplyIcon
                              onClick={(e) => handleReplyClick(e, item.commenterName, item.id)}
                              sx={{ cursor: 'pointer', fontSize: '12px', color: '#74788D', marginLeft: '4px' }}
                            />
                            <Typography
                              sx={{ fontSize: '12px', color: '#74788D', cursor: 'pointer', marginLeft: '4px', fontFamily: 'Open Sans' }}
                              onClick={(e) => handleReplyClick(e, item.commenterName, item.id)}
                            >
                              Reply
                            </Typography>
                          </Grid>
                        </Grid>
                      )}
                      {item?.replies?.map((reply, replyIndex) => (
                        <Grid item xs={12} key={`reply-${replyIndex}`} sx={{ paddingLeft: '40px', paddingTop: '12px' }}>
                          <Grid container alignItems='baseline'>
                            <Grid item>
                              <Box
                                sx={{
                                  width: 24,
                                  height: 24,
                                  borderRadius: '50%',
                                  backgroundColor: '#3BBFA3',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  textAlign: 'center',
                                  color: '#fff',
                                  fontWeight: 500,
                                  fontFamily: 'Open Sans',
                                  fontSize: '14px',
                                  lineHeight: '16px'
                                }}
                              >
                                {reply?.commenterName?.[0]?.toUpperCase()}
                              </Box>
                            </Grid>
                            <Grid item sx={{ display: 'flex', alignItems: 'baseline' }}>
                              <Typography
                                sx={{
                                  fontFamily: 'Open Sans',
                                  fontSize: '16px !important',
                                  fontWeight: 600,
                                  paddingLeft: '8px',
                                  display: 'inline-block',
                                  color: '#34475a !important',
                                  marginRight: '8px'
                                }}
                              >
                                {reply?.commenterName}
                              </Typography>
                              <Typography className='time' sx={{ fontSize: '12px !important' }}>
                                {dayjs().to(dayjs(item?.insTs))}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container sx={{ paddingLeft: reply?.comment?.match(/<ul>|<ol>/) ? '42px' : '24px' }}>
                            <Typography className='reqComment' sx={{ fontFamily: 'Open Sans', fontSize: '14px', marginLeft: '8px', display: 'inline-block' }}>
                              <span dangerouslySetInnerHTML={{ __html: sanitizeComment(reply.comment) }} />
                            </Typography>
                          </Grid>
                          {replyToReply === reply.id && (
                            <Grid container sx={{ paddingLeft: '28px', marginTop: '8px' }}>
                              <Grid item sx={{ width: '100%' }}>
                                <Box className='strategy-message-area'>
                                  <EditorProvider>
                                    <Editor
                                      value={commentValue}
                                      placeholder='Write a Reply here'
                                      containerProps={{
                                        style: { minHeight: '100px', height: 'auto', overflowY: 'auto', maxWidth: '100%' }
                                      }}
                                      onChange={(e) => setCommentValue(e.target.value)}
                                      onPaste={(e) => {
                                        e.preventDefault()
                                        const clipboardData = e.clipboardData || window.clipboardData
                                        const pastedData = clipboardData.getData('text/html') || clipboardData.getData('text/plain')
                                        const cleanHtml = sanitize(pastedData, {
                                          allowedTags: ['b', 'i', 'u', 'br', 'ul', 'ol', 'li'],
                                          exclusiveFilter: (frame) => !frame.text?.trim()
                                        })
                                        setCommentValue((prev) => prev + cleanHtml)
                                      }}
                                    >
                                      <Toolbar style={{ position: 'sticky', top: 0, backgroundColor: 'white', borderBottom: 'none' }}>
                                        <div style={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                                          <BtnBold />
                                          <BtnItalic />
                                          <BtnUnderline />
                                          <BtnNumberedList />
                                          <BtnBulletList />
                                          <BtnClearFormatting />
                                        </div>
                                        <img
                                          className='sendIcon'
                                          src={sendIcon}
                                          alt='send'
                                          onClick={() => handlePostComment()}
                                          style={{
                                            width: '28px',
                                            background: '#F0F0F0',
                                            padding: '6px',
                                            cursor: 'pointer',
                                            margin: '4px',
                                            borderRadius: '5px'
                                          }}
                                        />
                                      </Toolbar>
                                    </Editor>
                                  </EditorProvider>
                                </Box>
                              </Grid>
                            </Grid>
                          )}

                          <Grid container sx={{ paddingLeft: '28px', marginTop: '4px' }}>
                            <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                              <ReplyIcon
                                onClick={() => handleReplyClick(null, reply.commenterName, item.id, true, reply.id)}
                                sx={{ cursor: 'pointer', fontSize: '12px', color: '#74788D', marginLeft: '4px' }}
                              />
                              <Typography
                                sx={{ fontSize: '12px', color: '#74788D', cursor: 'pointer', marginLeft: '4px', fontFamily: 'Open Sans' }}
                                onClick={() => handleReplyClick(null, reply.commenterName, item.id, true, reply.id)}
                              >
                                Reply
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      ))} */}
      <Box className='trade-message-area'>
        <FormControl fullWidth>
          <TextAreaEditor
            message={commentValue}
            setMessage={setCommentValue}
            placeholder='Write a Comment here'
            containerProps={{
              style: {
                maxHeight: '150px',
                overflowY: 'auto'
              }
            }}
            toolbarStyle={{
                position: 'sticky',
                top: 0,
                backgroundColor: 'white',
                borderBottom: 'none',
                paddingLeft: '4px'
            }}
            rightHeaderComponent={<SendPostIcon />}
          />
        </FormControl>
      </Box>
    </>
  )
}
