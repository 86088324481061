import { FormatPayload } from '../components/FormatAPIRequest'
import { CreateAPI } from '../components/Actions'
import { getUserRole } from '../../../utils/getUserRole'
import { GetNextTradeStatusId } from './GetTradeData'

export const CreateTradeData = async (newRow, tabIndex) => {
  switch (tabIndex) {
    case 'trade-status':
      return await CreateTradeStatus(newRow)
    case 'trade-status-master':
      return await CreateTradeStatusMaster(newRow)
    default:
      return {}
  }
}

const CreateTradeStatus = async (newRow) => {
  const userRole = getUserRole()
  let payload = {
    accountId: newRow.accountId,
    tradeStatusCode: newRow.tradeStatusCode?.toUpperCase(),
    comments: newRow.comments
  }

  payload = FormatPayload(payload)

  return CreateAPI(
    'tradeMaintainURL',
    `data-maintenance/v1/${userRole}/trading/trade-status/${newRow?.tradeId}`,
    payload
  )
}

const CreateTradeStatusMaster = async (newRow) => {
  const userRole = getUserRole()
  const tradeStatusId = await GetNextTradeStatusId()

  let payload = {
    tradeStatusCode: newRow.tradeStatusCode?.toUpperCase(),
    tradeStatusName: newRow.tradeStatusName,
    tradeStatusDesc: newRow.tradeStatusDesc
  }

  payload = FormatPayload(payload)

  return CreateAPI(
    'tradeMaintainURL',
    `data-maintenance/v1/${userRole}/trading/status-master/${tradeStatusId}`,
    payload
  )
}
