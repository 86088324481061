import React from 'react'
import ReactApexChart from 'react-apexcharts'
import { formatCurrency } from '../../utils/FormateCurrenyInMilion'

const DonutChart = ({ chartData: { labels, series, colors } }) => {
  const options = {
    chart: {
      type: 'donut'
    },
    labels,
    states: {
      active: {
        filter: {
          type: 'none'
        }
      },
      hover: {
        filter: {
          type: 'none'
        }
      }
    },
    plotOptions: {
      pie: {
        activeShadow: {
          enabled: false
        },
        donut: {
          size: '75%',
          labels: {
            labels,
            show: true,
            name: {
              show: true,
              offsetY: 15,
              color: '#323232',
              formatter: function (labels) {
                if (labels.length > 25) {
                  return labels.substring(0, 20) + '...'
                } else {
                  return labels
                }
              }
            },
            value: {
              offsetY: -20,
              fontFamily: 'Open Sans',
              fontSize: '24px',
              fontWeight: 600,
              formatter: function (val) {
                return `$${formatCurrency(val)}`
              }
            },
            total: {
              show: true,
              showAlways: false,
              label: 'Total Portfolio Value',
              fontSize: '10px',
              fontFamily: 'Open Sans',
              fontWeight: 600,
              color: '#323232',
              formatter: function (w) {
                const totalValue = w.globals.seriesTotals.reduce((a, b) => {
                  return a + b
                }, 0)

                return `$${formatCurrency(totalValue)}`
              }
            }
          }
        }
      }
    },
    legend: {
      show: false
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            show: false
          }
        }
      }
    ],
    dataLabels: {
      enabled: false
    },
    fill: {
      colors
    },
    tooltip: {
      enabled: false,
      fillSeriesColor: true
    },
    colors

  }

  return (
    <ReactApexChart
      options={options}
      series={series}
      type='donut'
      height='210'
      width='210'
    />
  )
}

export default DonutChart
