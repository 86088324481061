// import axios from 'axios'
import utils from './index'
import { toastError } from './common'
import { API } from 'aws-amplify'

const buildURL = (path, queryParams = {}) => {
  const queryString = new URLSearchParams(queryParams)
  return `${path}${queryString ? `?${queryString}` : ''}`
}

const handleAmplifyAPIResponse = async (response, formData = null) => {
  try {
    const responseData = response
    if (responseData.success) {
      return { ...responseData }
    } else {
      toastError(responseData?.message || 'Something went wrong')
      return {
        success: false,
        msg: responseData?.message || 'Something went wrong'
      }
    }
  } catch (error) {
    toastError(error?.message || 'Something went wrong')
    return { success: false, msg: 'Failed to parse response' }
  }
}

const handleResponse = async (response, formData) => {
  try {
    const responseData = formData ? await response : await response.json()
    if (response.ok) {
      return { success: true, data: responseData }
    } else {
      toastError(responseData?.message || 'Something went wrong')
      return {
        success: false,
        msg: responseData?.message || 'Something went wrong'
      }
    }
  } catch (error) {
    toastError(error?.message || 'Something went wrong')
    return { success: false, msg: 'Failed to parse response' }
  }
}

const apiRequest = async (method, url, data, formData) => {
  try {
    const response = await fetch(url, {
      method,
      body: data ? (formData ? data : JSON.stringify(data)) : undefined
    })

    return handleResponse(response, formData)
  } catch (error) {
    toastError(error?.message || 'Something went wrong')
    return { success: false, msg: 'Something went wrong' }
  }
}

const amplifyApiRequest = async (method, baseUrl, endPoint, payload, queryParams = {}) => {
  try {
    const response = method === 'POST'
      ? await API.post(
        baseUrl,
        endPoint,
        {
          body: { ...payload },
          queryStringParameters: queryParams
        }
      )
      : method === 'DELETE'
        ? await API.del(
          baseUrl,
          endPoint,
          { queryStringParameters: payload }
        )
        : await API.get(
          baseUrl,
          endPoint,
          { queryStringParameters: payload }
        )

    return handleAmplifyAPIResponse(response)
  } catch (error) {
    toastError(error?.response?.data?.errorInfo?.userMessage || 'Something went wrong')
    // return { success: false, msg: 'Something went wrong' }
    return error
  }
}

const createApiRequest = (key, data) => async () => {
  // const url = buildURL('getPDFData', { key })
  // return apiRequest('GET', url)
  return amplifyApiRequest('GET', 'baseUriReportBuilder', `report-builder/v1/pm/component/${key}/${data.accountId}`)
}

const createApiRequestWithKey = (key, data) => createApiRequest(key, data)
export const accountDataApi = (data, userGroup) => amplifyApiRequest('GET', 'baseUriReportBuilder', `report-builder/v1/${userGroup}/element/cover-page/${data?.reportType === 'Aggregate Report' ? data?.apiData?.aggregateGroupId : data?.accountId}`,
  { ...(data?.reportType === 'Aggregate Report') ? { type: 'GROUP' } : '' }
)
export const assetAllocationChartApi = (data, userGroup) => amplifyApiRequest('GET', 'baseUriReportBuilder', `report-builder/v1/${userGroup}/component/asset-allocation/${data.accountId}`)
export const marketCapAllocationApi = (data, userGroup) => amplifyApiRequest('GET', 'baseUriReportBuilder', `report-builder/v1/${userGroup}/component/market-cap-allocation/${data?.accountId}/${data?.optId}`)
export const factorAllocationApi = (data, userGroup) => amplifyApiRequest('GET', 'baseUriReportBuilder', `report-builder/v1/${userGroup}/component/factor-allocation/${data?.accountId}/${data?.optId}`)
export const performanceApi = (data, userGroup) => amplifyApiRequest('GET', 'baseUriReportBuilder', `report-builder/v1/${userGroup}/components/performance/performance-chart/${data?.accountId}`)
export const miscDataApi = (data) => createApiRequestWithKey('miscData', data)
export const portfolioStatisticsApi = (data, userGroup) => amplifyApiRequest('GET', 'baseUriReportBuilder', `report-builder/v1/${userGroup}/component/portfolio/${data?.accountId}`)
export const diversificationDataApi = (data, userGroup) => amplifyApiRequest('GET', 'baseUriReportBuilder', `report-builder/v1/${userGroup}/component/diversification/${data?.accountId}`)
export const summaryDataApi = (data, userGroup) => amplifyApiRequest('GET', 'baseUriReportBuilder', `report-builder/v1/${userGroup}/component/summary/${data?.accountId}/${data?.optId}`)
export const taxSavingsData = (data, userGroup) => amplifyApiRequest('GET', 'baseUriReportBuilder', `report-builder/v1/${userGroup}/components/performance/tax-saving-details/${data?.accountId}`)
export const taxManagementDataApi = (data, userGroup) => amplifyApiRequest('GET', 'baseUriReportBuilder', `report-builder/v1/${userGroup}/components/performance/tax-management/${data?.accountId}`)
export const sector = (data, userGroup) => amplifyApiRequest('GET', 'baseUriReportBuilder', `report-builder/v1/${userGroup}/components/constraints/sector/${data?.optId}`)
export const industry = (data, userGroup) => amplifyApiRequest('GET', 'baseUriReportBuilder', `report-builder/v1/${userGroup}/components/constraints/industry/${data?.optId}`)
export const security = (data, userGroup) => amplifyApiRequest('GET', 'baseUriReportBuilder', `report-builder/v1/${userGroup}/components/constraints/restriction/${data?.optId}`)
export const substitution = (data, userGroup) => amplifyApiRequest('GET', 'baseUriReportBuilder', `report-builder/v1/${userGroup}/components/constraints/substitution/${data?.optId}`)
export const factorTilts = (data, userGroup) => amplifyApiRequest('GET', 'baseUriReportBuilder', `report-builder/v1/${userGroup}/components/constraints/factor-tilts/${data?.optId}`)
export const scenarioDataApi = (data, userGroup) => amplifyApiRequest('GET', 'baseUriReportBuilder', `report-builder/v1/${userGroup}/components/constraints/scenario/${data?.optId}`)
export const policyConstraintsApi =
  createApiRequestWithKey('policy-constraints')
export const topPositionSecurityApi = (data, userGroup) => amplifyApiRequest('GET', 'baseUriReportBuilder', `report-builder/v1/${userGroup}/components/performance/position-security/${data?.accountId}`)
export const topSecurityApi = (data, userGroup) => amplifyApiRequest('GET', 'baseUriReportBuilder', `report-builder/v1/${userGroup}/components/performance/weight-security/${data?.accountId}`)
export const sectorWiseRiskApi = (data, userGroup) => amplifyApiRequest('GET', 'baseUriReportBuilder', `report-builder/v1/${userGroup}/component/sector-wise-risk/${data?.accountId}`)
export const securityWiseRiskApi = (data, userGroup) => amplifyApiRequest('GET', 'baseUriReportBuilder', `report-builder/v1/${userGroup}/component/security-wise-risk/${data?.accountId}`)
export const securityByWeightApi = createApiRequestWithKey('security-by-weight')
export const personalizationApi = (data, userGroup) => amplifyApiRequest('GET', 'baseUriReportBuilder', `report-builder/v1/${userGroup}/components/constraints/personalized/${data?.accountId}`)
export const initialPortfolioBlockApi = (data, userGroup) => amplifyApiRequest('GET', 'baseUriReportBuilder', `report-builder/v1/${userGroup}/component/initial-portfolio-block/${data?.accountId}/${data?.optId}`)
export const customTableData = (data, userGroup) => amplifyApiRequest('POST', 'baseUriReportBuilder', `report-builder/v1/${userGroup}/custom-table/components`, data)
export const aggregateMarketValue = (data, userGroup) => amplifyApiRequest('GET', 'baseUriReportBuilder2', `report-builder/v1/${userGroup}/group-details/${data?.apiData?.aggregateGroupId}`)
export const cashflowSummaryApi = (data, userGroup) => amplifyApiRequest('GET', 'baseUriReportBuilder2', `report-builder/v1/${userGroup}/aggregate/account/activity-summary/${data?.aggregateGroupId}`)
export const aggregatePerformance = (data, userGroup) => amplifyApiRequest('GET', 'baseUriReportBuilder2', `report-builder/v1/${userGroup}/agg-group/performance/${data?.apiData?.aggregateGroupId}`)
export const activitySummaryApi = (data, userGroup) => amplifyApiRequest('GET', 'baseUriReport', `reporting/v1/${userGroup}/activity-summary/${data?.accountId}`)

export const saveElements = async (data, userGroup) => {
  return amplifyApiRequest('POST', 'baseUriReportBuilder', `report-builder/v1/${userGroup}/push-template/pdf-customization`, data)
}

export const saveAsElements = async (data, userGroup) => {
  return amplifyApiRequest('POST', 'baseUriReportBuilder', `report-builder/v1/${userGroup}/push-template/pdf-customization`, data)
}

export const getElements = async (name, identifier, userGroup) => {
  // const url = utils.hostStoreURL('/fetchTemplate', {
  //   identifier: 'testidentifier',
  //   report_name: name
  // })
  // return apiRequest('GET', url)
  // const url = buildURL('fetchTemplate', {
  //   identifier: 'testidentifier',
  //   report_name: name
  // })
  // return amplifyApiRequest('GET', 'baseUriTemplates', 'fetchTemplate', {
  //   identifier: 'testidentifier',
  //   report_name: name
  // })

  return amplifyApiRequest('GET', 'baseUriReportBuilder', `report-builder/v1/${userGroup}/templates`, {
    identifier,
    report_name: name
  })
}

export const fetchAllTemplates = async (userGroup) => {
  return amplifyApiRequest('GET', 'baseUriReportBuilder', `report-builder/v1/${userGroup}/templates/all-templates`)
}

export const deleteTemplates = async (name, identifier, userGroup) => {
  // const url = utils.hostStoreURL('/deleteTemplate', {
  //   identifier,
  //   report_name: name
  // })
  // return apiRequest('GET', url)
  // const url = buildURL('deleteTemplate', {
  //   identifier,
  //   report_name: name
  // })
  // return amplifyApiRequest('GET', 'baseUriTemplates', 'deleteTemplate', {
  //   identifier,
  //   report_name: name
  // })

  return amplifyApiRequest('DELETE', 'baseUriReportBuilder', `report-builder/v1/${userGroup}/templates`, {
    identifier,
    report_name: name
  })
}

export const fetchPresignedUrlDataWithKey = async (userGroup, filename) => {
  return amplifyApiRequest('GET', 'baseUriReportBuilder', `report-builder/v1/${userGroup}/pdf/custom/import/${filename}`)
}

export const pushDocumentInTemplateS3 = async (response, file) => {
  const formData = new FormData()
  const keys = Object.keys(response.fields)
  keys.map((key) => {
    return formData.append([key], response.fields[key])
  })
  formData.append('file', file)

  return apiRequest('POST', response.url, formData, true)
}

export const pushDocumentInS3 = async (response, file) => {
  const responseData = response?.data
  const formData = new FormData()
  const keys = Object.keys(responseData?.fields)
  keys.map((key) => {
    return formData.append([key], responseData?.fields[key])
  })
  formData.append('file', file)
  return apiRequest('POST', responseData?.url, formData, true)
}

export const getPDFtoImageAPI = async (userGroup, filename, queryParams = {}) => {
  return amplifyApiRequest('GET', 'baseUriReportBuilder', `report-builder/v1/${userGroup}/pdf/custom/export/image-format/${filename}`, {
    ...queryParams
  })
}