import { Box, Button, Divider, Modal, Stack, Typography } from '@mui/material'

const confirmationPopupStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2,
  borderRadius: '4px',
  outline: 'none'
}

const getCountColor = (status) => {
  if (status === 'completed') { return '#3BBFA3' } else if (status === 'failed') { return '#FF6161' } else if (status === 'inprogress') { return '#D29922' } else { return '#2F2F2F' }
}

const ConfirmOnResumePopup = ({ showConfirmationPopup, closeConfirmationPopup, tradeRow, steps = [], resumeTrade }) => {
  return (
    <Modal
      open={showConfirmationPopup}
      onClose={() => closeConfirmationPopup()}
    >
      <Box sx={confirmationPopupStyle}>
        <Typography sx={{ textAlign: 'left', fontSize: '20px', fontWeight: 400, color: '#34475A' }}>Resume Trade</Typography>
        <Typography sx={{ mb: 1 }}>Please review the account details before resuming trade.</Typography>
        <Divider />
        <Box sx={{ my: 2 }}>
          <Stack direction='row' gap={1}>
            <Typography sx={{ color: '#74788D', flexBasis: '50%' }}>Account Name:</Typography>
            <Typography sx={{ color: '#2F2F2F' }}>
              {tradeRow?.accountName || '-'}
            </Typography>
          </Stack>
          <Stack direction='row' gap={1}>
            <Typography sx={{ color: '#74788D', flexBasis: '50%' }}>Account Code:</Typography>
            <Typography sx={{ color: '#2F2F2F' }}>
              {tradeRow?.accountCd || '-'}
            </Typography>
          </Stack>
          {
            steps?.map((tradeObj) => (
              <Stack direction='row' gap={1} key={tradeObj.id}>
                <Typography sx={{ color: '#74788D', flexBasis: '50%' }}>{tradeObj.stepLabel}</Typography>
                <Typography sx={{ color: getCountColor(tradeObj.status) }}>
                  {tradeObj?.totalTradeCompletedCount}
                </Typography>
              </Stack>
            ))
          }
        </Box>
        <Divider />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
          <Button variant='contained' onClick={resumeTrade}>Confirm</Button>
          <Button onClick={closeConfirmationPopup} variant='text'>Cancel</Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default ConfirmOnResumePopup
