import { useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import * as Sentry from '@sentry/react'
import dayjs from 'dayjs'
import CancelIcon from '@mui/icons-material/Close'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import { Box } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import { useGridApiContext } from '@mui/x-data-grid'
import { DataGridPro, GridActionsCellItem, GridRowModes, useGridApiRef } from '@mui/x-data-grid-pro'
import { useAuth } from '../../contexts/AuthContext'
import { moduleConfig } from '../../contexts/data'
import { ACCESS_LEVEL } from '../../contstants/constants'
import { useErrorToast } from '../../hooks/useErrorToast'
import Loader from '../Loader'

const RestrictionTable = ({ data, onRestrictionUpdate }) => {
  const { showError } = useErrorToast()
  const [isLoading, setIsLoading] = useState(false)
  const [rowModesModel, setRowModesModel] = useState({})
  const apiRef = useGridApiRef()
  const { checkAccess } = useAuth()
  const { user } = useAuth()

  const handleConfirmEdit = async (id, row) => {
    setIsLoading(true)
    if (row.endDate) {
      const payload = {
        endDate: row.endDate
      }
      try {
        const response = await API.patch('baseUriAccountOptimization', `account-customization/v1/${user?.userGroup}/active-manager/instr-restrictions/${row.strategyInstrRestrId}`, { body: { ...payload } })
        if (response?.data && response?.success) {
          const updatedList = rows.map(item => {
            if (item.accountId === row.accountId && item.instrId === row.instrId && item.restrictionId === row.restrictionId) {
              return { ...row }
            }
            return item
          })
          setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
          setRows(updatedList)
          onRestrictionUpdate && onRestrictionUpdate(updatedList)
          setIsLoading(false)
          return 'SUCCESS'
        }
      } catch (error) {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
        return 'FAIL'
      } finally {
        setIsLoading(false)
      }
    } else {
      setIsLoading(false)
    }
  }

  const handleDeleteClick = (id, row) => {
    setIsLoading(true)
    API.del('baseUriAccountOptimization', `account-customization/v1/${user?.userGroup}/active-manager/instr-restrictions/${row.strategyInstrRestrId}`)
      .then(response => {
        if (response?.success) {
          const updatedList = rows.filter(item => item.strategyInstrRestrId !== row.strategyInstrRestrId)
          setRows(updatedList)
          onRestrictionUpdate && onRestrictionUpdate(updatedList)
        }
      })
      .catch(error => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const columns = [
    {
      field: 'instrId',
      headerName: 'Instrument Id',
      flex: 1,
      editable: false
    },
    {
      field: 'localSymbol',
      headerName: 'Local Symbol',
      flex: 1,
      editable: false
    },
    {
      field: 'securityName',
      headerName: 'Security Name',
      flex: 1,
      editable: false
    },
    {
      field: 'restrictionDesc',
      headerName: 'Restriction Type',
      flex: 1,
      editable: false
    },
    {
      field: 'startDate',
      headerName: 'Start Date',
      flex: 1,
      editable: false,
      type: 'date',
      valueGetter: (params) => {
        if (!params?.value) return params?.value
        const date = new Date(params.value)
        return new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000)
      },
      valueFormatter: (params) =>
        params.value ? dayjs(params.value).format('ddd MMM DD YYYY') : '--------'
    },
    {
      field: 'endDate',
      headerName: 'End Date',
      flex: 1,
      editable: true,
      type: 'date',
      valueFormatter: (params) =>
        params.value ? dayjs(params.value).format('ddd MMM DD YYYY') : '--------',
      renderEditCell: (params) => <CustomEditDate {...params} minDate={dayjs.utc().format('YYYY-MM-DD')} />,
      preProcessEditCellProps: (params) => {
        const currentDate = dayjs().startOf('day').unix() * 1000
        const startDate = dayjs(params.row.startDate).startOf('day').unix() * 1000
        const endDate = dayjs(params.props.value).startOf('day').unix() * 1000
        const isEndDateValid = (endDate >= currentDate) && (endDate >= startDate)
        const hasError = !isEndDateValid
        const style = hasError
          ? {
              border: '2px solid red',
              height: '50px'
            }
          : {}
        return { ...params.props, error: hasError, style }
      }
    },
    ...checkAccess(moduleConfig.MODEL, ACCESS_LEVEL.COMPONENT_ACCESS,
      { subModuleName: moduleConfig.STRATEGY_PORTFOLIO, component_name: moduleConfig.MANAGE_RESTRICTION })
      ? [
          {
            field: 'actions',
            type: 'actions',
            headerName: (
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                Action
              </Box>
            ),
            width: 100,
            getActions: ({ id, row }) => {
              const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit
              const hasEditAccess = true
              if (isInEditMode) {
                const actions = [
                  <GridActionsCellItem
                    key='save'
                    disabled={row.error || row.minError || row.maxError}
                    icon={<SaveIcon />}
                    label='Save'
                    onClick={handleSaveClick(id, row)}
                  />,
                  <GridActionsCellItem
                    key='cancel'
                    icon={<CancelIcon />}
                    label='Cancel'
                    className='textPrimary'
                    onClick={handleCancelClick(id, row)}
                    color='inherit'
                  />
                ]
                return actions
              }
              return [
                <Tooltip title='Edit End Date' key='edit'>
                  <GridActionsCellItem
                    icon={<EditIcon />}
                    key='edit'
                    label='Edit'
                    disabled={!hasEditAccess || row.disableAction}
                    className='textPrimary'
                    onClick={handleEditClick(id, row)}
                    color='inherit'
                  />
                </Tooltip>,
                <Tooltip title='Delete Security' key='delete'>
                  <GridActionsCellItem
                    key='delete'
                    icon={<DeleteOutlineIcon />}
                    label='Delete'
                    onClick={() => handleDeleteClick(row.id, row)}
                    color='inherit'
                  />
                </Tooltip>
              ]
            }
          }
        ]
      : []]

  const [rows, setRows] = useState(data)

  useEffect(() => {
    setRows(data)
    const updatedData = {}
    rows.forEach((d) => {
      updatedData[d?.id] = apiRef.current.getRowWithUpdatedValues(d?.id)
    })
    setRows(prevData => prevData.map((row, index) => (
      updatedData[row?.id] &&
      row?.id === updatedData[row?.id]?.id)
      ? {
          ...row
        }
      : row))
  }, [data])
  const tempData = {}

  useEffect(() => {
    Object.keys(rowModesModel).forEach((d) => {
      tempData[d] = apiRef.current.getRowWithUpdatedValues(d)
    })
  }, [rowModesModel])

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true
  }

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true
  }

  const handleCellEditStart = (params, event) => {
    event.defaultMuiPrevented = true
  }

  const handleCellEditStop = (params, event) => {
    event.defaultMuiPrevented = true
  }

  const handleEditClick = (id, row) => () => {
    const updatedData = {}
    rows.forEach((d) => {
      updatedData[d?.id] = apiRef.current.getRowWithUpdatedValues(d?.id)
    })
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
    setRows(prevData => prevData.map((row, index) => (
      updatedData[row?.id] &&
      row?.id === updatedData[row?.id]?.id && row?.id !== id)
      ? {
          ...row
        }
      : (row?.id === id)
          ? { ...row }
          : row))
  }

  const handleSaveClick = (id, row) => () => {
    if (!row.error) {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
    }
  }

  const handleCancelClick = (id, row) => () => {
    const updatedData = {}
    rows.forEach((d) => {
      updatedData[d?.id] = apiRef.current.getRowWithUpdatedValues(d?.id)
    })
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    })
    const editedRow = rows.find((row) => row?.id === id)
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row?.id !== id))
    }
    setRows(prevData => prevData.map((row, index) => (
      (updatedData[row?.id]?.error) &&
      row?.id === updatedData[row?.id]?.id && row?.id !== id
    )
      ? {
          ...row
        }
      : (row?.id === id)
          ? { ...row }
          : row))
  }

  const processRowUpdate = async (newRow) => {
    const resp = await handleConfirmEdit(newRow.id, newRow)
    if (resp === 'SUCCESS') {
      return newRow
    } else {
      return rows
    }
  }

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel)
  }

  function getRowId (row) {
    return row?.id
  }

  return (
    <>
      {isLoading ? <Loader /> : ''}
      <Box
        sx={{
          '.MuiDataGrid-footerContainer': {
            border: 'none'
          },
          '.MuiDataGrid-root': {
            border: 'none'
          },
          '.MuiDataGrid-row--editing': {
            background: 'rgba(25, 118, 210, 0.08) !important'
          },
          '.MuiDataGrid-row--editing .MuiDataGrid-cell': {
            backgroundColor: 'inherit !important'
          },
          '.MuiDataGrid-cell--editing': {
            padding: '0 10px !important'
          }
        }}
      >
        <div style={{ width: '100%' }}>
          <DataGridPro
            autoHeight
            rows={rows}
            columns={columns}
            getRowId={getRowId}
            pageSizeOptions={[10]}
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } }
            }}
            pagination
            disableRowSelectionOnClick
            editMode='row'
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            apiRef={apiRef}
            onCellEditStart={handleCellEditStart}
            onCellEditStop={handleCellEditStop}
            processRowUpdate={processRowUpdate}
            getRowClassName={(params) => rowModesModel[params.row?.id]?.mode === GridRowModes.Edit ? 'MuiDataGrid-row--editing' : ''}
            sx={{
              fontFamily: 'Open Sans',
              '& .MuiDataGrid-columnHeaderTitle': {
                fontWeight: 600,
                fontFamily: 'Open Sans',
                color: '#34475A'
              },
              '& .MuiDataGrid-columnHeaderTitleContainerContent': {
                fontWeight: 600,
                fontFamily: 'Open Sans',
                color: '#74788D'
              }
            }}
          />
        </div>
      </Box>
    </>
  )
}

const CustomEditDate = (props) => {
  const { id, field, value: valueProp, style, minDate } = props
  const apiRef = useGridApiContext()
  const [value, setValue] = useState(valueProp)

  const formatDate = (date) => {
    const parsedDate = date ? date?.split('T')[0] : null
    return parsedDate
  }

  const handleDateChange = (e) => {
    const newValue = e.target.value
    apiRef.current.setEditCellValue({ id, field, value: newValue, debounceMs: 200 })
    setValue(newValue)
  }

  useEffect(() => { setValue(valueProp) }, [valueProp])

  return (
    <input
      type='date'
      value={formatDate(value)}
      onChange={handleDateChange}
      min={minDate}
      style={{ height: '100%', width: '100%', background: '#1976d214', ...style, outline: 'none' }}
    />
  )
}

export default RestrictionTable
