import React, { useRef, useState } from 'react'
import { pdfToImages } from '../../../../store/pdf-customizer-reducer/Action/chartDropAction'
import { toastError } from '../../../../utils/pdf-customizer/common'
import { LinearProgress, Typography } from '@mui/material'
import { BsImages } from 'react-icons/bs'
import { useDispatch } from 'react-redux'
import { IoMdClose, IoIosCloudDone } from 'react-icons/io'
import './FileUpload.css'
import { fetchPresignedUrlDataWithKey, getPDFtoImageAPI, pushDocumentInS3, pushDocumentInTemplateS3 } from '../../../../utils/pdf-customizer/_data'
import { useAuth } from '../../../../contexts/AuthContext'

const FileUpload = () => {
  const dispatch = useDispatch()
  const inputRef = useRef()

  const [selectedFile, setSelectedFile] = useState(null)
  const [progress, setProgress] = useState(0)
  const [uploadStatus, setUploadStatus] = useState('select')
  const { user } = useAuth()

  const handleFileChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0])
    }
  }

  const onChooseFile = () => {
    inputRef.current.click()
  }

  const clearFileInput = () => {
    inputRef.current.value = ''
    setSelectedFile(null)
    setProgress(0)
    setUploadStatus('select')
  }


  // const renderPage = async (PDF_DOC, file) => {
  //   const imagesList = []
  //   const values = PDF_DOC
  //   const numPages = values.numPages
  //   const numPagesByProcess = Math.floor(65 / numPages)
  //   const promises = []

  //   for (let i = 1; i <= numPages; i++) {
  //     promises.push(
  //       renderPageToImage(values, i, numPagesByProcess * i, imagesList)
  //     )
  //   }

  //   try {
  //     await Promise.all(promises)
  //     dispatch(pdfToImages(imagesList))
  //     setProgress(100)
  //     // imagesList.splice(0, imagesList.length)
  //     setUploadStatus('done')
  //   } catch (error) {
  //     console.error('Error rendering PDF pages:', error)
  //   }
  // }

  const getPDFtoImages = async (filename, queryParams = {}, fileKey) => {
    try {
      const response = await getPDFtoImageAPI(user?.userGroup, filename, queryParams)
      if (response && response.success) {
        if (response?.data?.timeout) {
          getPDFtoImages(filename, { ...queryParams, jobId: response?.data?.jobId }, fileKey)
        }
        else {
          if (response?.data?.s3ObjectUrls) {
            dispatch(pdfToImages({ imagesList: response?.data?.s3ObjectUrls, key: fileKey }))
          }
        }
      }
      else if (response.message === 'Network Error' || !response?.success) {
        dispatch(pdfToImages({ processing: 'error', key: fileKey }))
      }
    } catch (error) {
      console.log(error)
      dispatch(pdfToImages({ processing: 'error', key: fileKey }))
    }
  }

  const renderPage = async (file) => {
    try {
      const presignedAPIResponse = await fetchPresignedUrlDataWithKey(user?.userGroup, file?.name?.split('.')[0]?.replaceAll(/[^a-zA-Z0-9]/g, '_'))
      if (presignedAPIResponse && presignedAPIResponse.success) {
        const resJson = presignedAPIResponse.data
        if (resJson) {
          const preSignedUrl = resJson
          pushDocumentInTemplateS3(presignedAPIResponse.data, file)
            .then((res) => {
              if (res.success) {
                const queryParams = {}
                if (process.env.REACT_APP_USE_SANDBOX) {
                  queryParams['sandbox'] = process.env.REACT_APP_USE_SANDBOX
                }
                setProgress(100)
                setUploadStatus('done')
                dispatch(pdfToImages({ processing: true, key: preSignedUrl?.fields?.key }))
                getPDFtoImages(file?.name?.split('.')[0]?.replaceAll(/[^a-zA-Z0-9]/g, '_'), queryParams, preSignedUrl?.fields?.key)
              }
            })
            .catch((error) => {
              console.log(error)
              clearFileInput()
            })
        }
      }
      else {
        if (presignedAPIResponse.message === 'Network Error') {
          clearFileInput()
        }
      }

    } catch (error) {
      console.error('Error rendering PDF pages:', error)
    }
  }

  const renderPageToImage = async (
    pdfDoc,
    pageNumber,
    numPagesByProcess,
    imagesList
  ) => {
    const page = await pdfDoc.getPage(pageNumber)
    const viewport = page.getViewport({ scale: 3 })

    const canvas = document.createElement('canvas')
    canvas.height = viewport.height
    canvas.width = viewport.width
    const renderContext = {
      canvasContext: canvas.getContext('2d'),
      viewport: viewport,
    }

    await page.render(renderContext).promise
    const img = canvas.toDataURL('image/png')
    imagesList.push(img)
    if (50 + numPagesByProcess < 100 && 50 + numPagesByProcess > progress) {
      setProgress((prev) => {
        const newProgress = prev + numPagesByProcess

        if (newProgress <= 100 && newProgress > progress) {
          return newProgress
        } else {
          return prev
        }
      })
    }
  }

  const handleUpload = async () => {
    setProgress(0)
    if (uploadStatus === 'done') {
      clearFileInput()
      return
    }

    try {
      setUploadStatus('uploading')
      setProgress(10)
      const file = selectedFile
      const uri = URL.createObjectURL(file)
      setProgress(20)
      const options = {
        url: uri,
      }
      // const _PDF_DOC = await PDFJS.getDocument(options)
      setProgress(30)

      // setSelectedFile(_PDF_DOC)
      renderPage(file)
    } catch (error) {
      setUploadStatus('select')
    }
  }
  const handleDrop = (e) => {
    e.preventDefault()

    const droppedFile = e.dataTransfer.files[0]

    if (droppedFile && droppedFile.type === 'application/pdf') {
      setSelectedFile(droppedFile)
    } else {
      toastError('Please drop a valid PDF file.')
    }
  }
  return (
    <div>
      <input
        ref={inputRef}
        accept="application/pdf"
        type="file"
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />

      {/* Button to trigger the file input dialog */}
      {!selectedFile && (
        <button
          className="file-btn drag-area"
          onClick={onChooseFile}
          onDrop={(e) => handleDrop(e)}
        >
          <span className="material-symbols-outlined">
            <BsImages />
          </span>
          <Typography className="header">
            Drag & Drop
            <Typography className="header" component="div">
              or{' '}
              <Typography className="button" component="span">
                browse
              </Typography>
            </Typography>
          </Typography>
        </button>
      )}

      {selectedFile && (
        <>
          <div className="file-card">
            <span className="material-symbols-outlined icon-file-upload ">
              <svg
                className="icon-file-upload  file-icon file-icon--pdf"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 19.607 24"
                width="40px"
                height="40px"
              >
                <path
                  className="file-icon__shadow"
                  d="M19.592 7.219v-.004c0-.014-.006-.026-.008-.039-.004-.03-.006-.06-.018-.089a.318.318 0 0 0-.055-.085c-.006-.008-.009-.017-.016-.025l-.002-.003-.003-.003-5.451-5.599-.001-.001a.338.338 0 0 0-.238-.102h-.001l-.005-.001H2.947a1.71 1.71 0 0 0-1.708 1.708v19.331a1.71 1.71 0 0 0 1.708 1.708h14.937a1.71 1.71 0 0 0 1.708-1.707V7.221v-.002z"
                />
                <path
                  className="file-icon__outline"
                  d="M18.354 5.951v-.004c0-.014-.006-.026-.008-.039-.004-.03-.006-.06-.018-.089a.318.318 0 0 0-.055-.085c-.006-.008-.009-.017-.016-.025l-.002-.003-.003-.003L12.801.104 12.8.103a.338.338 0 0 0-.238-.102h-.001L12.556 0H1.708A1.71 1.71 0 0 0 0 1.708v19.331a1.71 1.71 0 0 0 1.708 1.708h14.937a1.71 1.71 0 0 0 1.708-1.707V5.953l.001-.002zm-5.457-4.768l4.305 4.422h-4.305V1.183zm3.749 20.881H1.708c-.565 0-1.025-.46-1.025-1.025V1.708c0-.565.46-1.025 1.025-1.025h10.506v5.264c0 .189.153.342.342.342h5.115v14.75a1.027 1.027 0 0 1-1.025 1.025z"
                />
                <path
                  className="file-icon__type"
                  d="M6.083 15.424h-.992v1.031h-.66v-3.504c.551 0 1.101-.005 1.652-.005 1.711 0 1.716 2.478 0 2.478zm-.992-.606h.991c.846 0 .841-1.241 0-1.241h-.991v1.241zM10.944 14.674c.015.886-.525 1.781-1.751 1.781H7.817v-3.504h1.376c1.201 0 1.736.857 1.751 1.723zm-2.472 1.145h.721c.796 0 1.111-.58 1.096-1.151-.015-.545-.335-1.091-1.096-1.091h-.721v2.242zM11.531 16.455v-3.498h2.518v.635h-1.857v.956h1.757v.611h-1.757v1.296h-.661z"
                />
              </svg>
            </span>

            <div className="file-info-div">
              <div style={{ flex: 1 }}>
                <h6>{selectedFile?.name}</h6>
                {
                  progress === 0 || progress === 100
                    ? <div className="progress-bg">
                      <div className="progress" style={{ width: `${progress}%` }} />
                    </div>
                    : <LinearProgress />
                }
              </div>

              {uploadStatus === 'select' ? (
                <button onClick={clearFileInput}>
                  <span className="material-symbols-outlined close-icon">
                    <IoMdClose />
                  </span>
                </button>
              ) : (
                uploadStatus === 'uploading' ? (
                  ``
                ) : uploadStatus === 'done' ? (
                  <div className="check-circle">
                    <span
                      className="material-symbols-outlined"
                      style={{ fontSize: '20px' }}
                    >
                      <IoIosCloudDone />
                    </span>
                  </div>
                ) : null
              )}
            </div>
          </div>
          <button
            className="upload-btn"
            disabled={uploadStatus === 'uploading'}
            onClick={() => uploadStatus !== 'uploading' && handleUpload()}
          >
            {uploadStatus === 'select' || uploadStatus === 'uploading'
              ? uploadStatus === 'uploading'
                ? 'Uploading'
                : 'Upload'
              : 'Done'}
          </button>
        </>
      )}
    </div>
  )
}

export default FileUpload