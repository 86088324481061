import React, { useState } from 'react'
import { Dialog, DialogContent, IconButton, Tooltip } from '@mui/material'
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined'
import { ViewTradeComments } from '../ViewTradeComments'

const TradeCommentColumn = (params) => {
  const [isCommentShown, setIsCommentShown] = useState(false)

  const handleShowCommentsModal = () => {
    setIsCommentShown(true)
  }

  const handleCloseCommentsModal = () => {
    setIsCommentShown(false)
  }

  return (
    <>
      <Tooltip title='View Comments'>
        <IconButton onClick={() => handleShowCommentsModal()}>
          <ChatOutlinedIcon sx={{ fontSize: 18, color: '#74788D' }} />
        </IconButton>
      </Tooltip>
      {
        isCommentShown ? (
          <Dialog
            open={isCommentShown}
            onClose={handleCloseCommentsModal}
            PaperProps={{
              sx: {
                width: '1100px',
                maxWidth: '100%'
              }
            }}>
            <DialogContent
              sx={{
                padding: '16px'
              }}>
              <ViewTradeComments accountId={params?.row?.accountId} accountName={params?.row?.accountName} onClose={handleCloseCommentsModal} />
            </DialogContent>
          </Dialog>
        ) : ''
      }
    </>
  )
}

export default TradeCommentColumn