import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { taxManagementDataApi, taxSavingsData } from '../../../../utils/pdf-customizer/_data'
import { useAuth } from '../../../../contexts/AuthContext'
import {
  fetchDataAndDispatch,
  formatCurrencyWithSymbol
} from '../APIResponseConverts/APIResponseConverts'
import { taSavingsData, taxManagementData } from '../APIResponseConverts/chartDefaultsData'
import './taxDetails.css'

const YTDTaxDetails = ({
  data,
  chartIndex,
  index,
  chartHeight,
  chartWidth,
  clickEvent
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const { isResponseElements, taxSavingDataResponse, taxManagementDataResponse } = useSelector(
    (state) => state.chartState
  )
  const templateData = useSelector(state => state.templateData)
  const { user } = useAuth()
  useEffect(() => {
    const fetchData = (api, type, responseData) => {
      if (isResponseElements && !responseData && !isLoading) {
        setIsLoading(true)
        fetchDataAndDispatch(api, type, dispatch)
      }
      if (isResponseElements && responseData) {
        setIsLoading(false)
      }
    }
    // if (isResponseElements && !taxSavingDataResponse) {
    //   setIsLoading(true)
    //   fetchDataAndDispatch(() => taxSavingsData(templateData), 'TAX_SAVING_DATA', dispatch)
    // }
    // if (isResponseElements && taxSavingDataResponse) {
    //   setIsLoading(false)
    // }

    switch (data.name) {
      case 'YTD tax details':
        return fetchData(() => taxSavingsData(templateData, user?.userGroup), 'TAX_SAVING_DATA', taxSavingDataResponse)
      case 'Tax Management Comparison Table':
        return fetchData(() => taxManagementDataApi(templateData, user?.userGroup), 'TAX_MANAGEMENT_DATA', taxManagementDataResponse)

      default:
        return null
    }
  }, [taxSavingDataResponse, taxManagementDataResponse, isResponseElements, dispatch, data])
  const getDataTable = (name) => {
    switch (name) {
      case 'YTD tax details':
        return taxSavingDataResponse?.data || taSavingsData?.data
      case 'Tax Management Comparison Table':
        return taxManagementDataResponse || taxManagementData?.data

      default:
        return null
    }
  }
  const result = getDataTable(data.name) || []
  const dataTable = []

  const columns = []
  let tableTextAlign = []
  let tableWidth = []

  if (data.name === 'YTD tax details') {
    columns.push('Tax Savings')
    columns.push('Value (%)')
    dataTable.push(['YTD Tax Savings from Unrealized Gains', formatCurrencyWithSymbol(result?.taxSavedBasedOnOverWeightedSecurities || 0, 2, '')])
    dataTable.push(['YTD Tax Savings from Short-Term to Long-Term Events', formatCurrencyWithSymbol(result?.taxSavedBasedOnSortTermToLongTermConvertedHoldings || 0, 2, '')])
    dataTable.push(['YTD Tax Savings from Net Loss Harvesting', formatCurrencyWithSymbol(result?.taxSavedBasedOnYTDGainLossRecognized || 0, 2, '')])
    dataTable.push(['Total YTD Estimated Tax Savings', formatCurrencyWithSymbol(result?.totalTaxSaved || 0, 2, '')])
    tableWidth = [75, 25]
    tableTextAlign = ['text-left', 'text-right']
  } else {
    columns.push('Tax Management')
    columns.push('1 Month (%)')
    columns.push('3 Month (%)')
    dataTable.push([
      'Without Tax Management',
      result?.performanceData?.preTaxMV['1M'] ? (result?.performanceData?.preTaxMV['1M'] * 100).toFixed(2) : '-',
      result?.performanceData?.preTaxMV['3M'] ? (result?.performanceData?.preTaxMV['3M'] * 100).toFixed(2) : '-'
    ])
    dataTable.push([
      'With Tax Management',
      result?.performanceData?.postTaxMV['1M'] ? (result?.performanceData?.postTaxMV['1M'] * 100).toFixed(2) : '-',
      result?.performanceData?.postTaxMV['3M'] ? (result?.performanceData?.postTaxMV['3M'] * 100).toFixed(2) : '-'
    ])
    tableWidth = [60, 20, 20]
    tableTextAlign = ['text-left', 'text-right', 'text-right']
  }

  const chartStyle = {
  }

  return (
    <div
      className={'group'}
      style={chartStyle}
    >
      <p style={{ margin: '0', fontSize: '10px', fontWeight: 500, textTransform: 'capitalize' }}>
        {data.headingText || data.name}
      </p>
      {isLoading
        ? <span className='loading-text'>Loading...</span>
        : <div className='ytdtaxsaving'>
          <div className='tableborder'>
            <table className='width-100' style={{ borderCollapse: 'collapse' }}>
              <tr
                className='headingBorder' style={{
                  // backgroundImage: 'linear-gradient(to bottom, #FFFFFF, #FFFFFF 50%, #E9E9E9 50% 100%)',
                  // backgroundPosition: '0 100%',
                  // backgroundRepeat: 'no-repeat',
                  // backgroundSize: '100% 1px'
                }}
              >
                {columns.map((column, columnIndex) => (
                  <th key={columnIndex} className={`width-${tableWidth[columnIndex]} ${tableTextAlign[columnIndex]}`}>
                    {column}
                  </th>
                ))}
              </tr>
              {dataTable.map((row, rowIndex) => (
                <tr
                  key={rowIndex}
                  className='borderbottom fontsize8'
                >
                  {columns.map((column, columnIndex) => (
                    <td
                      key={columnIndex}
                      className={`width-${tableWidth[columnIndex]} ${tableTextAlign[columnIndex]}`}
                    >
                      {row[columnIndex]}
                    </td>
                  ))}
                </tr>
              ))}
            </table>
          </div>
        </div>}
    </div>
  )
}

export default YTDTaxDetails
