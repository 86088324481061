import {toast} from 'react-toastify';

export function useSuccessToast() {
    const showSuccess=(success) => {
        toast.success(success,{
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover:false,
            draggable: false,
            progress: false,
            theme: "colored",
            pauseOnFocusLoss: false
        });
    }
    return {showSuccess};
}