import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Box, Card, CardContent, Grid, Stack } from '@mui/material'
import { DataGridPro } from '@mui/x-data-grid-pro'
import CustomKeyFieldSearch from '../../components/CustomKeyFieldSearch'
import { formatCurrency } from '../../utils/FormateCurrenyInMilion'
import { SelectListOfAccountLoader } from '../SelectListofAccount/SelectListAccountLoader'
import { useAccountListContext } from '.'

const AccountListTable = ({ isLoading }) => {
  const { accounts } = useAccountListContext()
  const [accountList, setAccountList] = useState(accounts)
  const [currentPage, setCurrentPage] = useState(0)
  const [columns] = useState([
    { field: 'priority', headerName: 'Priority', flex: 1, minWidth: 80, renderCell: (params) => <Box className={'priority-' + params.value} /> },
    {
      field: 'accountName',
      headerName: 'Name',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <Link className='linkId' id='account-name' to={`/account-review/account-dashboard/${params.row.accountId}`} state={{ strategyId: params.row.strategyId, accountName: params.row.accountName, strategyName: params.row.strategyName, accountType: params.row.accountType }}>
          {params?.value || 'N/A'}
        </Link>)
    },
    { field: 'accountType', headerName: 'Account Type', flex: 1, minWidth: 150, valueFormatter: (params) => params.value?.toLowerCase().charAt(0)?.toUpperCase() + params.value?.slice(1).toLowerCase() },
    { field: 'isTaxable', headerName: 'Taxable', minWidth: 100, flex: 1, valueFormatter: (params) => params.value === 0 ? 'No' : 'Yes' },
    { field: 'accountCode', headerName: 'Account Code', minWidth: 150, flex: 1 },
    { field: 'custodianAccountNumber', headerName: 'Custodian Account Number', minWidth: 100, flex: 1, valueFormatter: (params) => params.value || 'N/A' },
    { field: 'masterAccountId', headerName: 'Master Account Number', minWidth: 100, flex: 1, valueFormatter: (params) => params.value || 'N/A' },
    { field: 'portSpecName', headerName: 'Model', minWidth: 200, flex: 1 },
    { field: 'totalMarketValue', headerName: 'Total Market Value', align: 'right', minWidth: 150, flex: 1, valueFormatter: (params) => `$${formatCurrency(params.value || 0)}` },
    {
      field: 'unrealizedGainLoss',
      headerName: 'Unrealized Gain/Loss',
      align: 'right',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => params.row.unrealizedGainLoss < 0 ? `-$${formatCurrency(params.row.unrealizedGainLoss || 0)} (${(Number(params.row.unrealizedGainLossWeight || 0) * 100).toFixed(2)}%)` : `${formatCurrency(params.row.unrealizedGainLoss || 0)} (${(Number(params.row.unrealizedGainLossWeight || 0) * 100).toFixed(2)}%)`
    },
    {
      field: 'assetAllocationDrift',
      headerName: 'Asset Allocation Drift',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <Grid container justifyContent='center' alignItems='center' spacing={0.5} flexWrap='nowrap'>
          <Grid item><Box className={Number(params.value) < 0.6666 ? 'progressbar bg-blue' : 'progressbar'} /></Grid>
          <Grid item><Box className={(Number(params.value) > 0.6666 && Number(params.value) < 1.3333) ? 'progressbar bg-blue' : 'progressbar'} /></Grid>
          <Grid item><Box className={Number(params.value) > 1.3333 ? 'progressbar bg-blue' : 'progressbar'} /></Grid>
        </Grid>
      )
    },
    { field: 'trackingError', headerName: 'Tracking Error', minWidth: 100, flex: 1, align: 'right', valueFormatter: (params) => (Number(params.value || 0) * 100).toFixed(2) || '0' },
    { field: 'incomeDivYield', headerName: 'Income/Div Yield', flex: 1, minWidth: 100, align: 'right', valueFormatter: (params) => (Number(params.value || 0) * 100).toFixed(2) || '0' },
    { field: 'cashLevel', headerName: 'Available Cash', flex: 1, minWidth: 100, align: 'right', valueFormatter: (params) => (Number(params.value || 0) * 100).toFixed(2) || '0' }
  ])

  useEffect(() => {
    setAccountList(accounts)
  }, [accounts])

  return (
    <Card id='list-of-accounts' variant='outlined'>
      <CardContent>
        {isLoading
          ? <div className='table-responsive'>
            <table>
              <thead>
                <tr>
                  <th>Priority</th>
                  <th>Name</th>
                  <th>Account Type</th>
                  <th>Taxable</th>
                  <th>Account Code</th>
                  <th>Model</th>
                  <th>Total Market Value</th>
                  <th>Unrealized Gain/Loss</th>
                  <th>Asset Allocation Drift</th>
                  <th>Tracking Error</th>
                  <th>Income/ Div Yield</th>
                  <th>Available Cash</th>
                </tr>
              </thead>
              <SelectListOfAccountLoader colNum={12} />
            </table>
            </div>
          : <DataGridPro
              autoHeight
              rows={accountList}
              columns={columns}
              getRowId={(row) => row.accountId}
              pageSizeOptions={[10]}
              onPaginationModelChange={(event) => setCurrentPage(event.page)}
              paginationModel={{ pageSize: 10, page: currentPage }}
              initialState={{
                pagination: { paginationModel: { pageSize: 10, page: currentPage } },
                columns: {
                  columnVisibilityModel: {
                    masterAccountId: false,
                    custodianAccountNumber: false
                  }
                }
              }}
              pagination
              pinnedColumns={{
                left: ['priority', 'accountName']
              }}
              slots={{
                toolbar: CustomKeyFieldSearch
              }}
              slotProps={{
                toolbar: {
                  filterData: (data) => { setAccountList(data); setCurrentPage(0) },
                  fieldName: 'accountName',
                  tableData: accounts,
                  fieldLabel: 'Account Name'
                }
              }}
              sx={{
                border: 'none',
                fontFamily: 'Open Sans'
              }}
            />}
      </CardContent>
    </Card>
  )
}

export default AccountListTable
