import React from 'react'
import { useDispatch } from 'react-redux'
import {
  changeBackground,
  editLabelChart,
  onEditChart,
  resetBackground
} from '../../../../store/pdf-customizer-reducer/Action/chartDropAction'
import { BiReset } from 'react-icons/bi'
import './editChart.css'
import { rgbaToHex } from '../../droppableElements/APIResponseConverts/APIResponseConverts'

const EditChart = ({ activeChart }) => {
  const dispatch = useDispatch()

  const toggleDatasetVisibility = (label, index, checked) => {
    dispatch(onEditChart({ label, index, check: checked }))
  }

  const handleBackground = (e, chartIndex) => {
    const newColor = e.target.value
    dispatch(changeBackground({ value: newColor, index: chartIndex }))
  }

  const bgReset = () => {
    dispatch(resetBackground())
  }

  const renderCheckbox = (data, index) => (
    <div className='flexOrAlign margin-y-1' key={index}>
      <label className='cl-checkbox'>
        <input
          type='checkbox'
          name={data.name || ''}
          checked={!data.hidden}
          onChange={(e) => {
            toggleDatasetVisibility(data.name, index, e.target.checked)
          }}
        />
        <div className='box-main'>
          <div />
        </div>
        <div className='checkbox-padding'>
          <span className='common-text-data'>{data.name}</span>
        </div>
      </label>
    </div>
  )

  const renderColorPicker = (data, index) => (
    <div className='flexOrAlignStartOrBetWeen width-100 margin-y-1' key={index}>
      <label className='cl-checkbox'>
        {(activeChart?.name !== 'Tax Management Summary Chart' && activeChart?.name !== 'Tax Management Comparison Block' && activeChart?.name !== 'Performance line chart' && activeChart.name !== 'Asset allocation chart') && (
          <>
            <input
              type='checkbox'
              name={data.label || ''}
              checked={!data.hidden || false}
              onChange={(e) =>
                dispatch(
                  editLabelChart({
                    label: data.label,
                    index,
                    checked: e.target.checked
                  })
                )}
            />
            <div className='box-main'>
              <div />
            </div>
          </>
        )}

        <div className='checkbox-padding' style={{ paddingLeft: activeChart?.name !== 'Tax Management Summary Chart' && activeChart?.name !== 'Tax Management Comparison Block' && activeChart?.name !== 'Performance line chart' && activeChart.name !== 'Asset allocation chart' ? '5px' : 0 }}>
          <span className='common-text-data'>{data.label}</span>
        </div>
      </label>
      <div>
        <input
          type='color'
          id='color-input'
          className='color-picker-input'
          value={rgbaToHex(data.backgroundColor)}
          onChange={(e) => handleBackground(e, index)}
        />
      </div>
    </div>
  )
  return (
    <>
      {Array.isArray(activeChart?.value?.labels) &&
        (activeChart.name !== 'Performance line chart' && activeChart.name !== 'Asset allocation chart') &&
        activeChart?.value?.labels?.map(renderCheckbox)}

      {activeChart && activeChart.value && activeChart.value.datasets && (
        <>
          <div className='flexOrAlignOrBetWeen color_picker_spacing'>
            <label className='chart-label'>Change color</label>
            <div onClick={bgReset}>
              <BiReset color='#727474' size={20} />
            </div>
          </div>
          {Array.isArray(activeChart?.value?.datasets) &&
            activeChart?.value?.datasets.map(renderColorPicker)}
        </>
      )}
    </>
  )
}

export default EditChart
