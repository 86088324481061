import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchDataAndDispatch } from '../APIResponseConverts/APIResponseConverts'
import { accountDataApi } from '../../../../utils/pdf-customizer/_data'
import { accountData } from '../APIResponseConverts/chartDefaultsData'
import { upDatePageHeadingFooter } from '../../../../store/pdf-customizer-reducer/Action/chartDropAction'
import ImageDisplay from '../../common/ImageDisplay'
import { useAuth } from '../../../../contexts/AuthContext'
import dayjs from 'dayjs'

const PageHeading = ({
  data,
  chartIndex,
  chartHeight,
  chartWidth,
  clickEvent
}) => {
  const { accountDataResponse, isResponseElements } = useSelector(
    (state) => state.chartState
  )
  const templateData = useSelector(state => state.templateData)
  const { pageHeadingFooter } = useSelector((state) => state.elementStates)
  const { value } = pageHeadingFooter
  const [isLoading, setIsLoading] = useState(false)
  const { user } = useAuth()

  const dispatch = useDispatch()
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isResponseElements && !accountDataResponse && !isLoading) {
          setIsLoading(true)
          await fetchDataAndDispatch(
            () => accountDataApi(templateData, user?.userGroup),
            'ACCOUNT_DATA',
            dispatch,
            true
          )
        } else {
          if (!value) {
            setIsLoading(false)
            const data = { ...accountData?.data, allUpdate: true }
            dispatch(upDatePageHeadingFooter(data))
          }
        }
      } catch (error) {
        console.error('Error fetching and dispatching data:', error)
      }
    }

    fetchData()
  }, [isResponseElements, accountDataResponse, dispatch, value])

  return (
    <>
      <div
        key={data?.chartIndex + data?.pageIndex}
        className='flexOrAlign width-100 margin-bottom-1 '
        style={{ justifyContent: 'space-between' }}
      >
        <div style={{ paddingInline: '5px' }}>
          {data.name === 'Page Heading 1' && (
            <p
              style={{
                margin: '0',
                fontSize: '6px',
                fontWeight: 500,
                // fontFamily: 'Open Sans',
                color: '#002A59'
              }}
            >
              {value?.templateType || 'Transition Report'} For
            </p>
          )}
          <p style={{ margin: '0', fontSize: '9px', fontWeight: 400, ...{ ...data.name === 'Page Heading 2' ? { color: '#BFA981' } : {} } }}>
            {value?.accountName || 'Sarah Davis TM'}
          </p>
          {data.name === 'Page Heading 1' && (
            <p
              style={{
                margin: '0',
                fontSize: '6px',
                fontWeight: 500,
                // fontFamily: 'Open Sans',
                color: '#002A59'
              }}
            >
              Prepared for {value?.accountCd}  on{' '}
              {dayjs().format('MMMM DD, YYYY') || 'OCTOBER 16, 2023'}
            </p>
          )}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', width: '50px' }}>
          <ImageDisplay
            imageUrl={
            value.companyLogoUrl ||
            'https://aris-public-assets-dev.s3.us-east-2.amazonaws.com/ARIS+Logo/aris-investing-logo.svg'
          }
            style={{ objectFit: 'contain' }}
            height={25}
            alt='Logo'
            width='100%'
            name={data.name}
            pageIdx={data?.pageIndex}
            chartIdx={data?.chartIndex}
            svgContent={value.svgCode || null}
          />
        </div>
      </div>
      <div style={{ height: '1px', width: '100%', borderBottom: '1px solid #DFDFDF' }} />
    </>
  )
}

export default PageHeading
