import React, { useEffect, useRef, useState } from 'react'
import { Bubble } from 'react-chartjs-2'
import {
  bubbleResponse,
  decTwoPercentage,
  fetchDataAndDispatch,
  formatCurrencyWithSymbol
} from '../APIResponseConverts/APIResponseConverts'
import { useDispatch, useSelector } from 'react-redux'
import { SummaryTable } from '../APIResponseConverts/chartDefaultsData'
import { summaryDataApi } from '../../../../utils/pdf-customizer/_data'
import { addChartValues } from '../../../../store/pdf-customizer-reducer/Action/chartDropAction'
import { Chart as ChartJS, registerables } from 'chart.js'
ChartJS.register(...registerables)

const BubbleChart = ({
  data,
  chartIndex,
  index,
  chartHeight,
  chartWidth,
  clickEvent
}) => {
  const { summaryResponse, isResponseElements } = useSelector(
    (state) => state.chartState
  )
  const templateData = useSelector(state => state.templateData)
  const chartRef = useRef(null)
  const dispatch = useDispatch()
  const [isDragging, setIsDragging] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (isResponseElements && !summaryResponse && !isLoading) {
      setIsLoading(true)
      fetchDataAndDispatch(() => summaryDataApi(templateData), 'SUMMARY_DATA', dispatch)
    }
    if (isResponseElements && summaryResponse) {
      setIsLoading(false)
    }
  }, [isResponseElements, summaryResponse, dispatch, templateData])

  const optimizationData =
    summaryResponse?.data?.optimizationData ||
    SummaryTable?.data?.optimizationData
  const accountData =
    summaryResponse?.data?.accountData || SummaryTable.data.accountData
  const selectedScenario = 'Balanced'
  const scenarioSensitivity = {
    HIGH: 'Min Tax',
    MEDIUM: 'Balanced',
    LOW: 'Model'
  }

  const convertedDataBubbleChart = (
    data,
    selectedScenario,
    highlighIntialScenario,
    name
  ) => {
    return data?.map((item) => {
      const isScenarioSelected = selectedScenario === item.scenarioCode
      const isInitialHighlightedScenario =
        (!selectedScenario || selectedScenario === '') &&
        item.scenarioCode === highlighIntialScenario

      const rValue =
        isScenarioSelected || isInitialHighlightedScenario ? 15 : 10

      return {
        x: item.taxCost,
        y:
          decTwoPercentage(item.te, 2) >= 10
            ? 10
            : decTwoPercentage(item.te, 2) * 1,
        r: rValue,
        label: item.scenarioCode
      }
    })
  }

  const convertedDataLineChart = (data, name) => {
    const convertedDataLine = []
    const convertedDataLineExcluded = []

    data?.forEach((item) => {
      let lineChartData = false
      data?.forEach((element) => {
        if (item.te > element.te && item.taxCost > element.taxCost) {
          lineChartData = true
          convertedDataLineExcluded.push(item.scenarioCode)
          return false
        }
      })

      if (!lineChartData) {
        convertedDataLine.push({
          x: item.taxCost,
          y:
            decTwoPercentage(item.te, 2) >= 10
              ? 10.0
              : decTwoPercentage(item.te, 2) * 1
        })
      }
    })

    convertedDataLine.sort((a, b) => parseFloat(a.y) - parseFloat(b.y))

    return name === 'line' ? convertedDataLine : convertedDataLineExcluded
  }

  const bubbleColorChart = (
    data,
    selectedScenario,
    convertedDataLineExcluded
  ) => {
    if (selectedScenario) {
      scenarioSensitivity[accountData?.taxSensitivity] = ''
    }

    return data?.map((item) => {
      if (
        selectedScenario === item.scenarioCode ||
        scenarioSensitivity[accountData?.taxSensitivity] === item.scenarioCode
      ) {
        return 'rgba(52,195,143, 1)'
      } else if (convertedDataLineExcluded.includes(item.scenarioCode)) {
        return 'rgba(116,120,141, 1)'
      } else {
        return 'rgba(139,174,225, 1)'
      }
    })
  }

  const convertedDataBubble = convertedDataBubbleChart(
    optimizationData,
    selectedScenario,
    scenarioSensitivity[accountData?.taxSensitivity],
    'Bubble Dataset'
  )

  const convertedDataLine = convertedDataLineChart(optimizationData, 'line')

  const bubbleColorChartData = bubbleColorChart(
    optimizationData,
    selectedScenario,
    convertedDataLine
  )
  const summaryData = bubbleResponse(
    convertedDataBubble,
    bubbleColorChartData,
    convertedDataLine,
    data.value || null
  )

  const chartStyle = {
    height: '100%',
    width: '100%',
  }

  useEffect(() => {
    if (data?.value || !summaryData) return

    const { datasets } = summaryData
    const [bubbleDataset, lineChartData] = datasets

    const mappedBubble = Array.isArray(bubbleDataset.data)
      ? bubbleDataset.data.map((items, index) => ({
        backgroundColor: bubbleDataset.backgroundColor?.[index],
        label: items?.label,
        hidden: false
      }))
      : []

    const mappedLine = {
      hidden: lineChartData.hidden || false,
      borderColor: lineChartData.borderColor,
      backgroundColor: lineChartData.backgroundColor
    }

    const chartValues = {
      labels: mappedLine,
      datasets: mappedBubble,
      chartIndex: data?.chartIndex,
      pageIndex: data?.pageIndex
    }

    dispatch(addChartValues(chartValues))
  }, [summaryData, dispatch, data])

  useEffect(() => {
    const { current: chartInstance } = chartRef

    if (
      !data?.value ||
      data?.value?.datasets?.length === 0 ||
      data?.value?.labels?.length === 0 ||
      !chartInstance ||
      !summaryData
    ) {
      return
    }

    try {
      const { datasets } = data?.value
      const bubbleDataset = chartInstance.data.datasets[0]

      bubbleDataset.backgroundColor = datasets.map(
        (items) => items.backgroundColor
      )

      datasets.forEach((dataset, index) => {
        if (!dataset.hidden) {
          const chartDataset = bubbleDataset?.data[index]

          if (chartDataset) {
            Object.assign(chartDataset, {
              hidden: dataset.hidden || false,
              backgroundColor: dataset.backgroundColor,
              borderColor: dataset.borderColor
            })
          }
        } else {
          const getIndex = bubbleDataset?.data.findIndex(
            (item) => item.label === dataset.label
          )

          if (getIndex !== -1) {
            bubbleDataset?.data.splice(getIndex, 1)
            bubbleDataset.backgroundColor.splice(getIndex, 1)
          }
        }
      })

      chartInstance.update()
    } catch (error) {
      console.error('Error in useEffect:', error)
    }
  }, [clickEvent, data, summaryData])

  const options = {
    devicePixelRatio: 10,
    responsive: true,
    tension: 0.1,
    cubicInterpolationMode: 'monotone',
    maintainAspectRatio: false,
    animation: { duration: 0 },
    hover: { mode: null },
    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          label: (context) => {
            const value = context.dataset.data[context.dataIndex]
            return [
              `Scenario: ${value.name || 'N/A'}`,
              `Tax Cost: ${formatCurrencyWithSymbol(value.x || 0, 2, '$')}`,
              `Tracking Error: ${value.y}%`
            ]
          }
        }
      },
      datalabels: {
        display: false,
        align: 'top',
        anchor: 'end',
        formatter: (value, context) => {
          return formatCurrencyWithSymbol(
            context?.dataset?.data
              ? context?.dataset?.data[context?.dataIndex]?.x || 0
              : 0,
            2,
            '$'
          )
        },
        font: { size: 8 },
        color: 'black'
      },
      legend: { display: false }
    },
    elements: {
      point: { radius: 25 }
    },
    scales: {
      x: {
        ticks: {
          callback: (value, index, values) =>
            formatCurrencyWithSymbol(value, 2, ''),
          maxTicksLimit: 6,
          color: '#727474',
          font: { size: 6 }
        },
        title: {
          display: true,
          text: 'Total Taxes ($)',
          padding: { top: 0 },
          font: { size: 6 }
        }
      },
      y: {
        ticks: {
          beginAtZero: true,
          callback: (value, index, values) =>
            formatCurrencyWithSymbol(value, 0, ''),
          maxTicksLimit: 6,
          color: '#727474',
          font: { size: 6 }
        },
        title: {
          display: true,
          text: 'Tracking Error (%)',
          padding: { bottom: 5 },
          font: { size: 6 }
        }
      }
    }
  }

  const chartPlugins = [
    {
      afterDatasetDraw: (chart) => {
        const ctx = chart.ctx

        chart.data.datasets.forEach((dataset, datasetIndex) => {
          const meta = chart.getDatasetMeta(datasetIndex)

          if (!meta.hidden) {
            meta.data.forEach((element, index) => {
              const data = dataset.data[index]
              const text = `${data.y}`
              ctx.fillStyle = 'white'
              ctx.font = '6px Arial'
              ctx.textAlign = 'center'
              ctx.textBaseline = 'middle'
              ctx.fillText(text, element.x, element.y)
            })
          }
        })
      }
    }
  ]

  return (
    <div
      className={'group'}
      key={data?.chartIndex + data?.pageIndex}
      style={chartStyle}
    >
      {isLoading
      ? <span className='loading-text'>Loading...</span>
      : <Bubble
          ref={chartRef}
          // data={combinedChartData}
          data={summaryData || {}}
          width='100%'
          options={options}
          plugins={chartPlugins}
        />
      }
    </div>
  )
}

export default BubbleChart
