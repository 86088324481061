import CloseIcon from '@mui/icons-material/Close'
import { IconButton, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import 'rc-slider/assets/index.css'
import '../assets/styles/topbar.scss'
import ContactsTable from '../layout/ContactsTable'

export const ViewPolicyCheck = ({ showModal, closeModal }) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '950px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 3,
    borderRadius: '4px',
    '.MuiDataGrid-root': {
      border: 'none'
    },
    '.MuiDataGrid-main': {
      overflow: 'unset'
    },
    '.MuiDataGrid-columnHeaders': {
      position: 'sticky',
      top: '-4px',
      zIndex: 99,
      background: 'white'
    },
    '.MuiDataGrid-virtualScroller': {
      marginTop: '-4px!important'
    }
  }

  return (
    <Modal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      open={showModal}
      onClose={closeModal}
      disableScrollLock
    >
      <Box sx={style}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant='h6' style={{ fontWeight: 400, color: '#34475A' }}>Policy Break Check</Typography>
          <IconButton aria-label='close' style={{ marginRight: '-10px' }} onClick={() => closeModal()}>
            <CloseIcon />
          </IconButton>
        </Box>
        <ContactsTable />
      </Box>
    </Modal>
  )
}
