import React, { useEffect, useMemo, useState } from 'react'
import './element-panel.css'
import BackArrow from '../../../assets/images/pdf-customizer/svg/back-arrow.svg'
import { handleChangeSidebar } from '../../../store/pdf-customizer-reducer/Action/uiAction'
import ElementList from '../common/ElementList/ElementList'
import { useDispatch, useSelector } from 'react-redux'
import jsonData from '../../../contstants/pdf-customizer/sideBar.json'
import { Close, FilterAlt, FilterAltOutlined } from '@mui/icons-material';
import { Box, ClickAwayListener, IconButton, Typography } from '@mui/material'

const ElementPanel = ({ name }) => {
  const dispatch = useDispatch()
  const [isFilterIconClicked, setIsFilterIconClicked] = useState(false)
  const [sidebarData, setSidebarData] = useState([])
  const [filter, setFilter] = useState({ width: [], height: [] })
  const { showSidebar = false } = useSelector((state) => state.uiReducer)
  const { templateName } = useSelector((state) => state.elementStates)

  const [dropdownItems, setDropdownItems]=useState(
    jsonData[templateName?.name] || jsonData['Default Report']
  )

  const {height, width} = useMemo(() => {
    const uniqueHeightSet = new Set(), uniqueWidthSet = new Set()
    // get unique width and height
    dropdownItems?.map(components => {
      components?.elements?.map(element => {
          if(element.text)
          uniqueHeightSet.add('T')
          else if(element.height)
            uniqueHeightSet.add(element.height)
          if (element.width)
            uniqueWidthSet.add(element.width)
      })
    })
    const height = [...Array.from(uniqueHeightSet)]
    const width = [...Array.from(uniqueWidthSet)]

    uniqueHeightSet.clear()
    uniqueWidthSet.clear()

    return { height, width }
  }, [dropdownItems])
  
  useEffect(() => {
    if (templateName?.name) {
      setDropdownItems(jsonData[templateName?.name])
      setSidebarData(jsonData[templateName?.name])
    }
  },[templateName])

  const handleChange = (label, value, doesFilterContain, index) => {
    if(doesFilterContain) {
      // clicked on same filter remove that filter
      filter[label].splice(index, 1)
      setFilter(prev => ({
          height : prev.height,
          width: prev.width
      }))
    }
    else {
      setFilter(prev => ({
        height: label === 'height' ? [...prev.height, value] : prev.height,
        width: label === 'width' ? [...prev.width, value] : prev.width
      }))
    }
  }

  const applyFilter = () => {
    let filteredData = []
    const heightLength = filter.height.length
    const widthLength = filter.width.length
    if (widthLength === 0) {
      // only height is selected
      filteredData = dropdownItems?.map(section => ({
        height: section.height,
        elements: section.elements.filter(element => {
          const heightMatch = (filter.height.includes(element.height) && element.text !== true) || (filter.height.includes('T') && element.text === true)
          return heightMatch
        })
      })).filter(section => section.elements.length > 0)
    }
    else if (heightLength === 0) {
      // only width is selected
      filteredData = dropdownItems?.map(section => ({
        height: section.height,
        elements: section.elements.filter(element => {
          const widthMatch = filter.width.includes(element.width)
          return widthMatch
        })
      })).filter(section => section.elements.length > 0)
    }
    else {
      // both width and height are selected
      filteredData = dropdownItems?.map(section => ({
        height: section.height,
        elements: section.elements.filter(element => {
          const widthHeightMatch = filter.width.includes(element.width) && (
            (filter.height.includes(element.height) && element.text !== true) ||
            (filter.height.includes('T') && element.text === true)
          )
          return widthHeightMatch
        })
      })).filter(section => section.elements.length > 0)
    }
    setSidebarData(filteredData)
  }

  useEffect(() => {
    if (filter.height.length === 0 && filter.width.length === 0) {
      setSidebarData(dropdownItems)
    }
    else {
      applyFilter()
    }
  }, [filter])

  const handleShow = (e) => {
    setIsFilterIconClicked(prev => !prev)
  }

  return (
    <>
      <div className={`relative ${showSidebar ? 'sidebar_root' : 'sidebar_root_mini'}`}>
        <div
          onClick={() => {
            dispatch(handleChangeSidebar(!showSidebar))
            setIsFilterIconClicked(false)}}
          className={`back-arrow bottom-back ${showSidebar ? 'open' : 'close'}`}
        >
          <img
            src={BackArrow}
            alt='backArrowIcon'
            className={showSidebar ? '' : 'open-arrow'}
          />
        </div>
        <div className='padding-3'>
          <ul className='links'>
            <div className='sidebar-heading'>
              <h4>Menu</h4>
              <IconButton onClick={handleShow}>
                {filter?.width?.length || filter?.height?.length ? <FilterAlt fontSize='small' /> : <FilterAltOutlined fontSize='small' />}
              </IconButton>
            </div>
            <ElementList dropItem={sidebarData} open />
          </ul>
        </div>
      </div>
      {isFilterIconClicked 
      &&  <ClickAwayListener onClickAway={() => setIsFilterIconClicked(false)}>
            <Box sx={{ position: 'absolute', left: '325px', height: '350px', width: '250px', zIndex: 99, padding: '20px', backgroundColor: 'white', borderRadius: '5px', border: '1px solid #74788d' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: '20px' }}>
                <Box sx={{ cursor: 'pointer', fontSize: '14px' }} onClick={() => setFilter({ width: [], height: [] })}>Reset</Box>
                <Close fontSize='small' sx={{ height: '20px', width: '20px', cursor: 'pointer' }} onClick={() => setIsFilterIconClicked(false)} />
              </Box>
              <Box>
                <Box sx={{ mb: '10px' }}>
                  <Typography sx={{ mb: '5px', fontSize: '14px' }}>Height</Typography>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'left', gap: '10px' }}>
                    {height?.map((h, i) => {
                      const index = filter?.height?.indexOf(h)
                      const heightIncludes = index > -1
                      return <Box key={i} sx={{ width: '30px', height: '30px', border: !heightIncludes ? '2px solid #74788d' : 'none', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', backgroundColor: heightIncludes ? '#74788d' : 'white', borderRadius: '3px', color: heightIncludes ? 'white' : 'black' }}
                        onClick={() => handleChange('height', h, heightIncludes, index)}>
                        <Typography sx={{ fontSize: '12px', userSelect: 'none' }}>{h}</Typography>
                      </Box>
                    })}
                  </Box>
                </Box>
                <Box sx={{ mb: '10px' }}>
                  <Typography sx={{ mb: '5px', fontSize: '14px' }}>Width</Typography>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'left', gap: '10px' }}>
                    {width?.map((w, i) => {
                      const index = filter?.width?.indexOf(w)
                      const widthIncludes = index > -1
                      return <Box key={i} sx={{ width: '30px', height: '30px', border: !widthIncludes ? '2px solid #74788d' : 'none', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', backgroundColor: widthIncludes ? '#74788d' : 'white', borderRadius: '3px', color: widthIncludes ? 'white' : 'black' }}
                        onClick={() => handleChange('width', w, widthIncludes, index)}>
                        <Typography sx={{ fontSize: '12px', userSelect: 'none' }}>{w.includes('/') || w === '1' ? w : `1/${w}`}</Typography>
                      </Box>
                    })}
                  </Box>
                </Box>
              </Box>
            </Box>
          </ClickAwayListener>
      }
    </>
  )
}

export default ElementPanel
