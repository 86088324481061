import * as types from '../Action/chartState.types'

const initialState = {
  isResponseElements: false,
  performanceChartResponse: null,
  marketCapChartResponse: null,
  factorChartResponse: null,
  sectorWiseChartResponse: null,
  sectorConstraintsResponse: null,
  industryConstraintsResponse: null,
  securityConstraintsResponse: null,
  substitutionConstraintsResponse: null,
  scenarioConstraintsResponse: null,
  factorTiltsConstraintsResponse: null,
  summaryResponse: null,
  allocationChartResponse: null,
  performanceTableResponse: null,
  policyConstraintsResponse: null,
  topPositionSecurityResponse: null,
  sectorWiseRiskResponse: null,
  taxSavingDataResponse: null,
  taxManagementDataResponse: null,
  securityWiseRiskResponse: null,
  securityByWeightResponse: null,
  personalizationResponse: null,
  accountDataResponse: null,
  customTableResponse: {},
  cashFlowSummaryResponse: null,
  aggregateMarketValueResponse: {},
  aggregatePerformanceResponse: {},
  activitySummaryResponse: null
}

const previewTemplate = (state = initialState, action) => {
  const actionTypeToStateProperty = {
    [types.PERFORMANCE_CHART_TYPE]: 'performanceChartResponse',
    [types.MARKET_CHART_TYPE]: 'marketCapChartResponse',
    [types.FACTOR_CHART_TYPE]: 'factorChartResponse',
    [types.ACCOUNT_DATA_TYPE]: 'accountDataResponse',
    [types.SECTOR_CONSTRAINTS_TYPE]: 'sectorConstraintsResponse',
    [types.INDUSTRY_CONSTRAINTS_TYPE]: 'industryConstraintsResponse',
    [types.SECURITY_CONSTRAINTS_TYPE]: 'securityConstraintsResponse',
    [types.SUBSTITUTION_CONSTRAINTS_TYPE]: 'substitutionConstraintsResponse',
    [types.SCENARIO_CONSTRAINTS_TYPE]: 'scenarioConstraintsResponse',
    [types.FACTOR_TILTS_CONSTRAINSTS_TYPE]: 'factorTiltsConstraintsResponse',
    [types.PERSONALIZATION_DATA_TYPE]: 'personalizationResponse',
    [types.SECURITY_BY_WEIGHT_TYPE]: 'securityByWeightResponse',
    [types.INITIAL_PORTFOLIO_BLOCK_TYPE]: 'initialPortfolioBlockResponse',
    [types.SECTOR_WISE_RISK_TYPE]: 'sectorWiseRiskResponse',
    [types.SECURITY_WISE_RISK_TYPE]: 'securityWiseRiskResponse',
    [types.TOP_POSITION_SECURITY_TYPE]: 'topPositionSecurityResponse',
    [types.POLICY_CONSTRAINTS_TYPE]: 'policyConstraintsResponse',
    [types.ALLOCATION_CHART_TYPE]: 'allocationChartResponse',
    [types.IS_API_CALL]: 'isResponseElements',
    [types.TAX_SAVING_DATA_TYPE]: 'taxSavingDataResponse',
    [types.TAX_MANAGEMENT_DATA_TYPE]: 'taxManagementDataResponse',
    [types.SUMMARY_DATA_TYPE]: 'summaryResponse',
    [types.DIVERSIFICATION_STATISTICS_TYPE]: 'diversificationResponse',
    [types.PORTFOLIO_STATISTICS_TYPE]: 'portfolioResponse',
    [types.CUSTOM_TABLE_TYPE]: 'customTableResponse',
    [types.CASH_FLOW_SUMMARY_TYPE]: 'cashFlowSummaryResponse',
    [types.AGGREGATE_MARKET_VALUE]: 'aggregateMarketValueResponse',
    [types.AGGREGATE_PERFORMANCE]: 'aggregatePerformanceResponse',
    [types.ACTIVITY_SUMMARY_TYPE]: 'activitySummaryResponse'
  }
  const updatedProperty = actionTypeToStateProperty[action.type]
  return updatedProperty
    ? updatedProperty === 'isResponseElements' && !action.payload
      ? {
        ...state,
        isResponseElements: false,
        performanceChartResponse: null,
        marketCapChartResponse: null,
        factorChartResponse: null,
        sectorWiseChartResponse: null,
        sectorConstraintsResponse: null,
        industryConstraintsResponse: null,
        securityConstraintsResponse: null,
        initialPortfolioBlockResponse: null,
        substitutionConstraintsResponse: null,
        scenarioConstraintsResponse: null,
        factorTiltsConstraintsResponse: null,
        summaryResponse: null,
        allocationChartResponse: null,
        performanceTableResponse: null,
        policyConstraintsResponse: null,
        topPositionSecurityResponse: null,
        sectorWiseRiskResponse: null,
        taxSavingDataResponse: null,
        taxManagementDataResponse: null,
        securityWiseRiskResponse: null,
        securityByWeightResponse: null,
        personalizationResponse: null,
        diversificationResponse: null,
        portfolioResponse: null,
        accountDataResponse: null,
        customTableResponse: {},
        cashFlowSummaryResponse: null,
        aggregateMarketValueResponse: {},
        aggregatePerformanceResponse: {},
        activitySummaryResponse: null
      }
      : updatedProperty === 'customTableResponse' ?
        { ...state, [updatedProperty]: { ...state[updatedProperty], [action.payload.dataSource]: { loading: action.payload.loading, data: action.payload.data } } }
        : updatedProperty === 'aggregateMarketValueResponse' || updatedProperty === 'aggregatePerformanceResponse' ?
          { ...state, [updatedProperty]: { loading: action.payload.loading, data: action.payload.data } }
          : { ...state, [updatedProperty]: action.payload }
    : state
}

export default previewTemplate
